import React, { useState } from 'react';
import { StyleSheet } from 'react-native';
import { Box, Button, Stack, SxProps, Theme, Typography } from '@mui/material';
import { useThemeContext } from '../../hook/useThemeManager';
import { ColorScheme, theme } from '../../constants/theme';
import { FONT_ENUM } from '../../types/global/fonts.types';
import { IActCard } from '../EsCards/ActCard';
import { VerticalProject } from '../EsCards/VerticalProject';
import { IProject, UserSummary } from '../../types/global/helper';
import { isEmpty } from '../../utils/lodash';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import CloseIcon from '../CustomSVGIcons/CloseIcon';

export interface IActCarousel extends Partial<Omit<IActCard, 'act'>> {
    actsData?: IProject[];
    title?: string;
    isUpNextMode?: boolean;
    mainContainerStyle?: React.CSSProperties;
    sx?: SxProps<Theme>;
    imageStyle?: React.CSSProperties;
    titleStyle?: React.CSSProperties;
    onClosePressed?: () => void;
    enableClose?: boolean;
}

const SHOW_LIMIT = 50;
const ActCarousel: React.FC<IActCarousel> = props => {
    const {
        actsData,
        title,
        onActPressed,
        onClickActUserIcon,
        isUpNextMode,
        mainContainerStyle,
        imageStyle,
        sx,
        onClosePressed,
        enableClose,
        titleStyle,
    } = props;
    const { themeStyle, device } = useThemeContext();
    const styles = dynamicStyles(themeStyle);
    const [showMore, setShowMore] = useState(false);

    const toggleShowMore = () => {
        setShowMore(!showMore);
    };

    return (
        <Box
            sx={{
                px: 2,
            }}
            style={{
                width: '100%',
                height: 'auto',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                justifyContent: 'flex-start',
                gap: '36px',
                ...mainContainerStyle,
            }}>
            <Stack direction="row" justifyContent="space-between" alignItems="center" width={'100%'}>
                <Typography style={{ ...styles.text, ...titleStyle }}>{title}</Typography>
                {enableClose && <CloseIcon onClick={onClosePressed} />}
            </Stack>
            {!device.isDesktop ? (
                <>
                    <Box
                        sx={{
                            gridTemplateColumns: {
                                xs: 'repeat(2, 1fr)',
                                sm: 'repeat(3, 1fr)',
                            }, // Account for iPads
                            width: '100%',
                            height: 'auto',
                            display: 'grid',
                            gap: {
                                xs: '15px',
                                sm: '10px',
                            },
                            gridAutoRows: '1fr',
                        }}>
                        {actsData &&
                            !isEmpty(actsData) &&
                            actsData
                                .slice(0, showMore ? actsData.length : SHOW_LIMIT)
                                .map((item, index) => (
                                    <VerticalProject
                                        project={item}
                                        key={index.toString()}
                                        onClickPlay={onActPressed}
                                        isUpNextMode={isUpNextMode}
                                        onClickHandle={user =>
                                            onClickActUserIcon && onClickActUserIcon(user as UserSummary)
                                        }
                                        sx={sx}
                                        imageStyle={imageStyle}
                                        {...props}
                                    />
                                ))}
                    </Box>
                    {actsData && actsData.length > 4 && (
                        <Button
                            onClick={toggleShowMore}
                            sx={{
                                backgroundColor: theme[themeStyle].deep_grey,
                                width: '100%',
                                borderRadius: 2,
                            }}>
                            {showMore ? (
                                <ExpandLess
                                    sx={{
                                        color: theme[themeStyle].highlight,
                                    }}
                                />
                            ) : (
                                <ExpandMore
                                    sx={{
                                        color: theme[themeStyle].highlight,
                                    }}
                                />
                            )}
                        </Button>
                    )}
                </>
            ) : (
                <Box
                    style={{
                        width: '100%',
                        height: 'fit-content',
                        display: 'flex',
                        overflowX: 'scroll',
                        gap: '10px',
                    }}
                    sx={{ flexDirection: { xs: 'column', sm: 'row' } }}>
                    {actsData &&
                        !isEmpty(actsData) &&
                        actsData.map((item, index) => (
                            <VerticalProject
                                project={item}
                                key={index.toString()}
                                onClickPlay={onActPressed}
                                onClickHandle={user => onClickActUserIcon && onClickActUserIcon(user as UserSummary)}
                                sx={sx}
                                imageStyle={imageStyle}
                                {...props}
                            />
                        ))}
                </Box>
            )}
        </Box>
    );
};

ActCarousel.defaultProps = {
    title: 'Acts',
};

const dynamicStyles = (colorScheme: ColorScheme) =>
    StyleSheet.create({
        text: {
            textAlign: 'left',
            fontSize: 24,
            fontStyle: 'normal',
            fontWeight: '600',
            color: theme[colorScheme].true_white,
            fontFamily: FONT_ENUM.MAIN_BOLD,
            textTransform: 'capitalize',
        },
    });

export { ActCarousel };
