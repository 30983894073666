import { StyleSheet, View } from 'react-native';
import { EsLoginContainer } from '../../components/ScreenContainer/EsLoginContainer';
import { useDispatchPromise } from '../../utils/AppUtils';
import { get2FATokenForLogin } from '../../redux/authentication/action';
import { useLocation } from 'react-router-dom';
import { ROUTES } from '../../types/global/routes.types';
import { login } from '../../redux/authentication/action';
import { TUsernamePassword } from '../../components/EsLoginForm/EsSigninData';
import ReCAPTCHA from 'react-google-recaptcha';
import { navigate } from '../../redux/navigator/action';
import { useRef, useState } from 'react';
import { SentryCapture } from '../../analytics/Sentry';

const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
});

const EsLogin = () => {
    const dispatch = useDispatchPromise();
    const location = useLocation();
    const redirect = new URLSearchParams(location.search).get('redirect');
    const [loading, setLoading] = useState(false);
    const [otpLoading, setOtpLoading] = useState(false);
    const [resendInProgress, setResendInProgress] = useState(false);
    const ref = useRef<ReCAPTCHA>();

    const handleOnSubmit = async (
        data: TUsernamePassword,
        next: (() => void) | undefined,
        isLastStep?: boolean | undefined,
    ) => {
        ref.current?.reset();
        try {
            if (!isLastStep) {
                setLoading(true);
                const token = await ref.current?.executeAsync();
                data.token = token as unknown as string;
                dispatch(get2FATokenForLogin(data))
                    .then(({ message, user }: any) => {
                        setLoading(false);
                        if (message && message === 'done') {
                            if (redirect) {
                                dispatch(
                                    navigate({
                                        routes: redirect === '/profile' ? `/${user.userName}` : redirect,
                                        isRefreshed: true,
                                    }),
                                );
                            } else {
                                setLoading(true);
                                dispatch(
                                    navigate({
                                        routes: ROUTES.ESDISCOVERY,
                                        isRefreshed: true,
                                        skipAuthentication: true,
                                    }),
                                );
                            }
                        } else if (next) {
                            setLoading(false);
                            next();
                        }
                    })
                    .catch(e => {
                        setLoading(false);
                    });
            } else {
                setOtpLoading(true);
                dispatch(login(data))
                    .then(({ user }: any) => {
                        setOtpLoading(false);
                        if (redirect) {
                            dispatch(
                                navigate({
                                    routes: redirect === '/profile' ? `/${user.userName}` : redirect,
                                    isRefreshed: true,
                                    skipAuthentication: true,
                                }),
                            );
                        } else {
                            setOtpLoading(false);
                            dispatch(
                                navigate({
                                    routes: ROUTES.ESDISCOVERY,
                                    isRefreshed: true,
                                    skipAuthentication: true,
                                }),
                            );
                        }
                    })
                    .catch(e => {
                        setOtpLoading(false);
                        SentryCapture(e);
                    });
            }
        } catch (error) {
            setOtpLoading(false);
        }
    };

    const handleResendOTP = (data: TUsernamePassword) => {
        setResendInProgress(true);
        return dispatch(get2FATokenForLogin({ ...data, resendToken: true }))
            .then(() => {
                setResendInProgress(false);
            })
            .catch(e => {
                console.log(e);
                setResendInProgress(false);
            });
    };

    return (
        <View style={styles.container}>
            <ReCAPTCHA sitekey={process.env.REACT_APP_CAPTCHA_KEY as string} size="invisible" ref={ref as any} />
            <EsLoginContainer
                onSubmit={handleOnSubmit}
                onResendOtp={handleResendOTP}
                loading={loading}
                otpLoading={otpLoading}
                resendInProgress={resendInProgress}
            />
        </View>
    );
};

export { EsLogin };
