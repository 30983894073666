import produce from 'immer';
import { strings } from '../../constants';

import { ILikeResponse, ILike, types } from './types';
import { types as projectTypes } from '../project/types';
import { IGetAudmeProfilesResponse } from './actions';
import { IAudition, IDashboardModel, IGenericDocumentModifier } from '../../types/global/helper';
import { CastingDirectorResponse, TalentProfileResponse } from '../../types/global/MoviesDetailsEditor.types';
import {
    parseUserReviews,
    parseUserReviewsAndRoles,
    parseMovieCollection,
    parseDashboardItems,
} from '../../utils/responseProcessor';
import { makeArrayUniqueAndClean, restoreInitialState } from '../../utils/AppUtils';

export interface IAccountInterface {
    userReviews: Array<any>;
    gettingUserReviews: boolean;
    gettingUserReviewErrorMsg: any;
    castingRoles: Array<any>;
    gettingCastingRoles: boolean;
    gettingCastingRolesErrorMsg: any;
    userWatchList: Array<any>;
    gettingUserWatchList: boolean;
    gettingUserWatchListErrorMsg: any;
    userAuditions: Array<IAudition>;
    gettingUserAuditions: boolean;
    gettingUserAuditionsErrMsg: any;
    contributions: Array<any>;
    getContributionsErr: any;
    gettingAccountDetails: boolean;
    gettingUserAudmeProfile: boolean;
    userCastingProfile?: CastingDirectorResponse;
    userTalentProfile?: TalentProfileResponse;
    gettingUserAudmeProfileErrorMsg: any;
    dashboard?: IDashboardModel;
    gettingDashboard?: boolean;
    gettingDashboardErr?: any;
    userLikes: ILike[];
    likeActionInProgress: boolean;
    userLikeErrMsg?: any;
}

const initialState: IAccountInterface = {
    userReviews: [],
    gettingUserReviews: false,
    gettingUserReviewErrorMsg: null,
    castingRoles: [],
    gettingCastingRoles: false,
    gettingCastingRolesErrorMsg: null,
    userWatchList: [],
    gettingUserWatchList: false,
    gettingUserWatchListErrorMsg: null,
    userAuditions: [],
    gettingUserAuditions: false,
    gettingUserAuditionsErrMsg: null,
    contributions: [],
    getContributionsErr: null,
    gettingAccountDetails: false,
    gettingUserAudmeProfile: false,
    gettingUserAudmeProfileErrorMsg: null,
    gettingDashboard: false,
    gettingDashboardErr: null,
    userLikes: [],
    likeActionInProgress: false,
};

const reducer = (state = initialState, action: any) => {
    const { payload, type } = action;
    switch (type) {
        case types.GET_CURRENT_USER_REVIEWS:
            return produce(state, (draftState: any) => {
                draftState.gettingUserReviews = true;
            });
        case types.GET_CURRENT_USER_REVIEWS_SUCCESS:
            return produce(state, (draftState: any) => {
                draftState.gettingUserReviews = false;
                const { data } = payload;
                const parsedReviews = parseUserReviews(data);
                draftState.userReviews = parsedReviews;
            });
        case types.GET_CURRENT_USER_REVIEWS_FAIL:
            return produce(state, (draftState: any) => {
                draftState.gettingUserReviews = false;
                draftState.gettingUserReviewErrorMsg = payload;
            });
        case types.GET_CASTING_ROLES:
            return produce(state, (draftState: any) => {
                draftState.gettingCastingRoles = true;
            });
        case types.GET_CASTING_ROLES_SUCCESS:
            return produce(state, (draftState: any) => {
                draftState.gettingCastingRoles = false;
                const { data } = payload;
                const parsedRoles = parseUserReviewsAndRoles(data);
                draftState.castingRoles = parsedRoles;
            });
        case types.GET_CASTING_ROLES_FAIL:
            return produce(state, (draftState: any) => {
                draftState.gettingCastingRoles = false;
                draftState.gettingCastingRolesErrorMsg = payload;
            });
        case types.GET_CURRENT_USER_WATCHLIST:
            return produce(state, (draftState: any) => {
                draftState.gettingUserWatchList = true;
            });
        case types.GET_CURRENT_USER_WATCHLIST_SUCCESS:
            return produce(state, (draftState: any) => {
                draftState.gettingUserWatchList = false;
                const { data } = payload;
                const parsedMovies = parseMovieCollection(data, strings.watchlist);
                draftState.userWatchList = parsedMovies;
            });
        case types.GET_CURRENT_USER_WATCHLIST_FAILED:
            return produce(state, (draftState: any) => {
                draftState.gettingUserWatchList = false;
                draftState.gettingUserWatchListErrorMsg = payload;
            });
        case types.GET_CURRENT_USER_AUDITIONS:
            return produce(state, (draftState: typeof initialState) => {
                draftState.gettingUserAuditions = true;
            });
        case types.GET_CURRENT_USER_AUDITIONS_SUCCESS:
            return produce(state, (draftState: typeof initialState) => {
                draftState.userAuditions = payload;
                draftState.gettingUserAuditions = false;
            });
        case types.GET_CURRENT_USER_AUDITIONS_FAIL:
            return produce(state, (draftState: typeof initialState) => {
                draftState.gettingUserAuditionsErrMsg = payload;
                draftState.gettingUserAuditions = false;
            });
        case types.GET_CONTRIBUTIONS_SUCCESS:
            return produce(state, (draftState: typeof initialState) => {
                const { festival, biography, movies } = payload;
                draftState.contributions = [{ movies }, { festival }, { biography }] as any;
            });
        case types.GET_CONTRIBUTIONS_FAIL:
            return produce(state, (draftState: typeof initialState) => {
                draftState.getContributionsErr = payload;
            });
        case types.GET_ACCOUNT_DETAILS:
        case types.ACCOUNT_REFRESH:
            return produce(state, (draftState: typeof initialState) => {
                draftState.gettingAccountDetails = true;
            });
        case types.GET_ACCOUNT_DETAILS_SUCCESS:
        case types.GET_ACCOUNT_DETAILS_FAIL:
        case types.ACCOUNT_REFRESH_SUCCESS:
        case types.ACCOUNT_REFRESH_FAIL:
            return produce(state, (draftState: typeof initialState) => {
                draftState.gettingAccountDetails = false;
            });
        case types.GET_USER_AUDME_PROFILES:
            return produce(state, (draftState: typeof initialState) => {
                draftState.gettingUserAudmeProfile = true;
            });
        case types.GET_USER_AUDME_PROFILES_SUCCESS:
            return produce(state, (draftState: typeof initialState) => {
                const { data } = payload as IGetAudmeProfilesResponse;
                draftState.gettingUserAudmeProfile = false;
                draftState.userCastingProfile = data?.castingProfile;
                draftState.userTalentProfile = data?.talentProfile;
            });
        case types.GET_USER_AUDME_PROFILES_FAIL:
            return produce(state, (draftState: typeof initialState) => {
                draftState.gettingUserAudmeProfile = false;
                draftState.userCastingProfile = undefined;
                draftState.userTalentProfile = undefined;
                draftState.gettingUserAudmeProfileErrorMsg = payload;
            });
        case types.GET_USER_DASHBOARD:
            return produce(state, draftState => {
                draftState.gettingDashboard = true;
            });
        case types.GET_USER_DASHBOARD_SUCCESS:
            return produce(state, draftState => {
                draftState.dashboard = parseDashboardItems(payload);
                draftState.gettingDashboard = false;
            });
        case types.GET_USER_DASHBOARD_FAIL:
            return produce(state, draftState => {
                draftState.gettingDashboard = false;
                draftState.gettingDashboardErr = payload;
            });
        case projectTypes.LIKE_A_PROJECT:
            return produce(state, draftState => {
                draftState.likeActionInProgress = true;
                const optimisticLike: any = {
                    optimistic: true,
                };
                draftState.userLikes.push(optimisticLike);
            });
        case projectTypes.LIKE_COMMENT:
            return produce(state, draftState => {
                draftState.likeActionInProgress = true;
                const optimisticLike: any = {
                    optimistic: true,
                };
                draftState.userLikes.push(optimisticLike);
            });
        case projectTypes.DELETE_A_LIKE:
        case types.GET_SINGLE_LIKE:
        case types.GET_USER_LIKES:
            return produce(state, draftState => {
                draftState.likeActionInProgress = true;
            });
        case projectTypes.LIKE_A_PROJECT_SUCCESS:
        case projectTypes.LIKE_COMMENT_SUCCESS:
            return produce(state, draftState => {
                const { data } = payload as ILikeResponse;
                const optimisiticIndex = draftState.userLikes.findIndex(likes => likes.optimistic);
                draftState.userLikes[optimisiticIndex] = data;
                draftState.userLikes = makeArrayUniqueAndClean([...draftState.userLikes, data]);
                draftState.likeActionInProgress = false;
            });
        case types.GET_SINGLE_LIKE_SUCCESS:
            return produce(state, draftState => {
                const { data } = payload as ILikeResponse;
                draftState.userLikes = makeArrayUniqueAndClean([...draftState.userLikes, data]);
                draftState.likeActionInProgress = false;
            });
        case projectTypes.LIKE_A_PROJECT_FAIL:
        case projectTypes.DELETE_A_LIKE_FAIL:
        case types.GET_SINGLE_LIKE_FAIL:
        case types.GET_USER_LIKES_FAIL:
        case projectTypes.LIKE_COMMENT_FAIL:
            return produce(state, draftState => {
                draftState.userLikes.filter(like => !like.optimistic);
                draftState.likeActionInProgress = false;
                draftState.userLikeErrMsg = payload;
            });
        case projectTypes.DELETE_A_LIKE_SUCCESS:
            return produce(state, draftState => {
                const { documentId } = payload as IGenericDocumentModifier;
                draftState.likeActionInProgress = false;
                draftState.userLikes = draftState.userLikes.filter(like => !!like && like._id !== documentId);
            });
        case types.GET_USER_LIKES_SUCCESS:
            return produce(state, draftState => {
                draftState.userLikes = makeArrayUniqueAndClean([...draftState.userLikes, ...payload]);
                draftState.likeActionInProgress = false;
            });
        case types.RESET_AUDME_PROFILE:
            return restoreInitialState(state, initialState);
        default:
            return state;
    }
};

export { reducer };
