import { makeStyles } from '@mui/styles';
import { ColorScheme, theme } from '../../constants/theme';
import { FONT_ENUM } from '../../types/global/fonts.types';

export const useStyles = (shouldDisable: boolean, themeStyle: ColorScheme) =>
    makeStyles({
        root: {
            '& .MuiAutocomplete-popupIndicator': {
                display: 'none',
            },
            '& .MuiChip-root': {
                backgroundColor: theme[themeStyle].highlight,

                '& .MuiChip-deleteIcon': {
                    color: theme[themeStyle].black,
                },
            },
            '& .MuiAutocomplete-option[data-focus="true"]': {
                backgroundColor: 'transparent',
            },
            '& .MuiAutocomplete-input': {
                color: theme[themeStyle].lightWhite, // Change text color to white
                '&::selection': {
                    backgroundColor: 'transparent', // Remove text highlight
                },
            },
            '& .MuiInputBase-root-MuiFilledInput-root': {
                color: 'white',
            },
            '& .MuiChip-label': {
                color: theme[themeStyle].black, // Change the limit tag text color (you can use any color here)
                fontFamily: FONT_ENUM.REGULAR_HEADER, // Change the font family (you can use any desired font family here)
                fontSize: '14px', // Change the font size (you can use any desired font size here)
            },
            '& .MuiAutocomplete-tag': {
                fontSize: '14px', // Change the font size (you can use any desired font size here)
                fontWeight: 'normal', // Change the font weight (you can use 'bold', 'normal', etc.)
                fontStyle: 'normal', // Change the font style (you can use 'normal', 'italic', 'oblique', etc.)
                color: theme[themeStyle].lightWhite,
                padding: '2px 8px',
            },
            '& .MuiAutocomplete-inputRoot': {
                border: `1px solid ${theme[themeStyle].form_gray}`, // Add a default grey border
                borderRadius: '10px',
                // Optional: Add rounded corners to the border
                '&.Mui-focused': {
                    borderColor: theme[themeStyle].highlight, // Change the border color to yellow when focused
                },
            },
            '& .Mui-focused .MuiAutocomplete-inputRoot': {
                borderColor: theme[themeStyle].highlight, // Change the border color to yellow when focused
            },
        },
        inputRoot: {
            padding: '18px',
            // Apply padding inside the TextField for the chips
        },
        noOptions: {
            color: theme[themeStyle].lightWhite,
            backgroundColor: shouldDisable ? theme[themeStyle].form_gray : 'transparent',
            fontFamily: FONT_ENUM.REGULAR_HEADER,
            display: !shouldDisable ? 'none' : 'inherit',
        },
    });
