import { ASW_S3_LINK } from '../configurations/api/url';
import { IUserDetails, UserDashboard } from '../redux/account/accountDetailsResponse.type';
import { WatchContent } from '../redux/project/types';
import { IProject } from '../types/global/helper';
import {
    IActSummary,
    IProjectSummary,
    IProjectsResponseForWatchScreen,
    IWatchResponse,
} from '../types/global/media.types';
import { genericParseSingleDocument, parseGenericCollection } from './responseProcessor';

export const parseWatchContent = (data: IWatchResponse): WatchContent[] => {
    const model: WatchContent[] = [];
    Object.keys(data).forEach(key => {
        switch (key) {
            case 'projects':
                Object.keys(data[key]).forEach(prop => {
                    const p: any = {};
                    const obj = data[key][prop as keyof IProjectsResponseForWatchScreen];
                    if (prop === 'recently_watched') {
                        p.categoryTitle = 'Trending Now';
                        p.categoryContent = parseGenericCollection(
                            obj,
                            genericParseSingleDocument,
                            genericParseSingleDocument,
                            'created_by',
                        );
                    } else if (prop === 'short_films') {
                        p.categoryTitle = 'Short Projects';
                        p.categoryContent = parseGenericCollection(
                            obj,
                            genericParseSingleDocument,
                            genericParseSingleDocument,
                            'created_by',
                        );
                    } else if (prop === 'act_project') {
                        p.categoryTitle = 'Acts';
                        p.categoryContent = parseGenericCollection(
                            obj,
                            genericParseSingleDocument,
                            genericParseSingleDocument,
                            'created_by',
                        );
                    } else {
                        p.categoryTitle = prop;
                        p.categoryContent = parseGenericCollection(
                            obj,
                            genericParseSingleDocument,
                            genericParseSingleDocument,
                            'created_by',
                        );
                    }
                    model.push(p);
                });
                break;
            default:
                if (Array.isArray(data[key as keyof IWatchResponse])) {
                    const actModel = {
                        categoryTitle: key,
                        categoryContent: parseGenericCollection(
                            data[key as keyof IProjectsResponseForWatchScreen],
                            genericParseSingleDocument,
                            genericParseSingleDocument,
                            'created_by',
                        ),
                    };
                    model.push(actModel);
                }
                return;
        }
    });

    return model;
};

export const projectActParse = (data: IProject[]) => {
    const acts: IActSummary[] = [];
    const projects: IProjectSummary[] = [];

    data.forEach(d => {
        if (d.project_type === 'act') {
            const parsedActs = genericParseSingleDocument(d);
            acts.push(parsedActs);
        } else {
            const parsedProject = genericParseSingleDocument(d);
            projects.push(parsedProject);
        }
    });

    return { acts, projects };
};

export const mergeObjectArrays = (obj1: any, obj2: any) => {
    const result: any = {};

    Object.keys(obj1).forEach(key => {
        if (Array.isArray(obj1[key]) && Array.isArray(obj2[key])) {
            result[key] = obj1[key].concat(obj2[key]);
        } else {
            result[key] = obj1[key];
        }
    });

    Object.keys(obj2).forEach(key => {
        if (!obj1.hasOwnProperty(key) && Array.isArray(obj2[key])) {
            result[key] = obj2[key];
        }
    });

    return result;
};

export const parseUserDetails = (obj: IUserDetails) => {
    //@ts-ignore
    const model: IUserDetails = {};
    if (!obj) return model;
    Object.keys(obj).forEach(key => {
        if (key === 'user_dashboard') {
            model[key as keyof IUserDetails] = parseUserDashBoard(obj[key as keyof IUserDetails]);
        } else if (Array.isArray(obj[key as keyof IUserDetails]) && key !== 'user_dashboard') {
            //@ts-ignore
            model[key as keyof IUserDetails] = parseGenericCollection(
                //@ts-ignore
                obj[key as keyof IUserDetails],
                genericParseSingleDocument,
            );
        } else if (key !== 'user_dashboard') {
            model[key as keyof IUserDetails] = genericParseSingleDocument(obj[key as keyof IUserDetails]);
        }
    });

    return model;
};

export const parseUserDashBoard = (userData: any) => {
    const awsParses = ['profile_picture', 'feature_image'];
    /** The below I added to avoid parsing the string, as we need them as a whole */
    const arraysNotToPass = ['permissions'];
    const model: any = {};
    Object.keys(userData).forEach(key => {
        if (awsParses.includes(key)) {
            model[key] = `${ASW_S3_LINK}${userData[key]}`;
        } else if (Array.isArray(userData[key]) && !arraysNotToPass.includes(key)) {
            model[key] = parseGenericCollection(userData[key], genericParseSingleDocument);
        } else {
            model[key] = userData[key];
        }
    });
    parseUserActivity(model);
    return model;
};

export const parseUserActivity = (userDashboard: UserDashboard) => {
    userDashboard.project_activities.forEach(activity => {
        if (!activity.project_activity.likes) {
            activity.project_activity.likes = [];
        }
        activity.project_activity.total_likes = activity.project_activity.likes.length;
    });
    userDashboard.contest_activities.forEach(activity => {
        if (!activity.contest_activity.likes) {
            activity.contest_activity.likes = [];
        }
        activity.contest_activity.total_likes = activity.contest_activity.likes.length;
    });
};
