import React from 'react';
import { StyleSheet } from 'react-native';
import { Box } from '@mui/material';
import { useLottie } from 'lottie-react';
import { arrowDown } from '../../assets/animations';

interface IEmptySearchBlock {
    animation?: any;
    animationStyle?: React.CSSProperties;
    containerStyle?: React.CSSProperties;
}
const ArrowAnimation: React.FC<IEmptySearchBlock> = ({ animation, animationStyle, containerStyle }) => {
    const styles = dynamicStyles();
    const options = {
        animationData: animation,
        loop: true,
    };

    const style = {
        height: 150,
        width: '100%',
        ...animationStyle,
    };
    const { View } = useLottie(options, style);

    return (
        <Box style={{ ...styles.flexColumn, ...containerStyle }} sx={{ gap: '25px' }}>
            <Box>{View}</Box>
        </Box>
    );
};

ArrowAnimation.defaultProps = {
    animation: arrowDown,
};

const dynamicStyles = () =>
    StyleSheet.create({
        flexColumn: {
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
        },
    });

export { ArrowAnimation };
