import React, { useState } from 'react';
import { Box, Card, CardContent, CardHeader, Collapse, IconButton, Typography } from '@mui/material';
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import { FONT_ENUM } from '../../types/global/fonts.types';
import { useThemeContext } from '../../hook/useThemeManager';
import { theme } from '../../constants/theme';

const useStyles = makeStyles(() => ({
    root: {},
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: `transform 100ms`,
    },
    expandOpen: {
        transform: 'rotate(180deg)',
        color: '#fff',
    },
    cardHeader: {
        // Style for the CardHeader
        backgroundColor: 'rbga(0,0,0,0.9)',
        color: '#fff',
        fontFamily: FONT_ENUM.REGULAR_HEADER,
        fontSize: 8,
    },
    cardContent: {
        // Style for the CardContent
        backgroundColor: 'rbga(0,0,0,0.9)',
        color: '#fff',
        fontFamily: FONT_ENUM.REGULAR_HEADER,
    },
}));

interface FAQItemProps {
    question: string;
    answer: string;
    details?: string[];
}

const FAQItem: React.FC<FAQItemProps> = ({ question, answer, details }) => {
    const classes = useStyles();
    const [expanded, setExpanded] = useState(false);
    const { themeStyle } = useThemeContext();

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    return (
        <Box
            onClick={handleExpandClick}
            sx={{
                width: '100%',
                display: 'flex',
                height: '100%',
                py: 1,
            }}>
            <Card
                sx={{
                    width: '100%',
                }}
                style={{
                    marginBottom: 2,
                    backgroundColor: theme[themeStyle].background_gradient.mid,
                    border: `0.3px solid ${theme[themeStyle].highlight}`,
                }}>
                <CardHeader
                    title={question}
                    className={classes.cardHeader}
                    titleTypographyProps={{
                        fontFamily: FONT_ENUM.MAIN_MEDIUM,
                        fontSize: 14,
                        color: theme[themeStyle].tag_white,
                    }}
                    action={
                        <IconButton
                            className={`${classes.expand} ${expanded ? classes.expandOpen : ''}`}
                            onClick={handleExpandClick}
                            aria-expanded={expanded}
                            aria-label="Show more">
                            <ExpandMoreIcon sx={{ color: theme[themeStyle].highlight }} />
                        </IconButton>
                    }
                />
                <Collapse in={expanded} timeout="auto" unmountOnExit>
                    <CardContent className={classes.cardContent}>
                        <Typography
                            style={{
                                fontFamily: FONT_ENUM.MEDIUM_TEXT,
                                fontSize: 12,
                                fontStyle: 'italic',
                                color: theme[themeStyle].tag_white,
                            }}>
                            {answer}
                        </Typography>
                        {details && (
                            <ul
                                style={{
                                    listStyleType: 'disc',
                                    marginLeft: '20px',
                                    color: theme[themeStyle].tag_white,
                                }}>
                                {details.map((line, index) => (
                                    <li key={index}>
                                        <Typography
                                            style={{
                                                fontFamily: FONT_ENUM.MEDIUM_TEXT,
                                                fontSize: 12,
                                                fontStyle: 'italic',
                                                color: theme[themeStyle].tag_white,
                                            }}>
                                            {line}
                                        </Typography>
                                    </li>
                                ))}
                            </ul>
                        )}
                    </CardContent>
                </Collapse>
            </Card>
        </Box>
    );
};

export default FAQItem;
