import * as React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { FONT_ENUM } from '../../types/global/fonts.types';
import { useStyles } from './CustomizedMultipleInput.styles';
import CircularProgress from '@mui/material/CircularProgress';
import { useState } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { FormLabel } from '@mui/material';
import { theme } from '../../constants/theme';
import { useThemeContext } from '../../hook/useThemeManager';

export interface ICustomizedMultipleInput {
    style?: any;
    textInputStyle?: any;
    onFocus?: (e: any) => void;
    onBlur?: (e: any) => void;
    loading?: boolean;
    enableChevron?: boolean;
    maximumSelectable?: number;
    disabled?: boolean;
    autoCompleteOptions?: string[];
    entyLimit?: number;
    onChange?: (args: any) => void;
    initialValue?: any[];
    disableInput?: boolean;
    single?: boolean;
    customizedLabel?: string;
}

export const CustomizedMultipleInput: React.FC<ICustomizedMultipleInput> = ({
    style,
    textInputStyle,
    onFocus,
    onBlur,
    loading,
    enableChevron,
    maximumSelectable = 2,
    disabled,
    autoCompleteOptions,
    entyLimit = 10,
    onChange,
    initialValue,
    disableInput,
    single,
    customizedLabel,
}) => {
    const [inputValue, setInputValue] = React.useState('');
    const [, setIsFocused] = useState(false);
    const [value, setValue] = React.useState<any>([]);
    const shouldShowNoOptionsText = inputValue.length > entyLimit;
    const [open, setOpen] = useState(false);
    const { themeStyle } = useThemeContext();
    const classes = useStyles(shouldShowNoOptionsText, themeStyle)();
    const [optionsToSelect, setOptionsToSelect] = useState<string[]>([]);
    const inputRef = React.useRef<any>(null);

    const handleInputChange = (event: any) => {
        if (event && event.target && event.target.value && !disableInput) {
            setInputValue(event.target.value);
            setOpen(true);
        }
    };

    React.useEffect(() => {
        if (initialValue) {
            setValue(initialValue);
        }
    }, [initialValue]);

    const handleInputKeyPress = (event: any) => {
        if (event.key === 'Enter' && inputValue.length > entyLimit) {
            const newEntry = inputValue;
            setOptionsToSelect(prevValue => [...prevValue, newEntry]);
            if (single) {
                setValue([value]);
            } else {
                setValue([...value, newEntry]);
            }
            if (onChange) {
                onChange([...value, newEntry]);
            }
            setInputValue('');
            setOpen(false);
        }
        if (event.key === 'Backspace' && inputValue.length <= 1) {
            setInputValue('');
        }
    };

    const handleOnFocus = (event?: any) => {
        setIsFocused(true);
        onFocus && onFocus(event);
        if (disableInput) {
            setOpen(true);
        }
    };

    const handleOnBlur = (event?: any) => {
        setIsFocused(false);
        onBlur && onBlur(event);
        setOpen(false);
        setInputValue('');
    };

    React.useEffect(() => {
        if (autoCompleteOptions) {
            setOptionsToSelect(autoCompleteOptions);
        }
    }, [autoCompleteOptions]);

    return (
        <>
            {!!customizedLabel && (
                <FormLabel
                    style={{
                        maxWidth: 400,
                        width: '100%',
                        textAlign: 'left',
                        fontSize: 14,
                        fontStyle: 'normal',
                        fontWeight: '400',
                        color: theme[themeStyle].form_label,
                        fontFamily: FONT_ENUM.MAIN_REGULAR,
                        lineHeight: 2.5,
                    }}>
                    {customizedLabel}
                </FormLabel>
            )}
            <Autocomplete
                classes={classes}
                multiple
                limitTags={maximumSelectable}
                id="multiple-limit-tags"
                options={optionsToSelect}
                style={style}
                open={open}
                getOptionLabel={option => {
                    return option;
                }}
                ref={inputRef}
                disabled={disabled}
                draggable
                disableClearable
                loading={loading}
                blurOnSelect
                filterSelectedOptions
                ChipProps={{
                    color: 'primary',
                }}
                value={value}
                onFocus={handleOnFocus}
                autoSelect
                onBlur={handleOnBlur}
                isOptionEqualToValue={(option, value) => {
                    return !!value;
                }}
                onChange={(event, newValue) => {
                    setValue(newValue);
                    setInputValue('');
                    if (onChange) {
                        onChange(newValue);
                    }
                }}
                ListboxProps={{
                    sx: {
                        backgroundColor: theme[themeStyle].form_gray,
                        color: theme[themeStyle].lightWhite,
                        fontFamily: FONT_ENUM.REGULAR_HEADER,
                    },
                }}
                inputValue={inputValue}
                onInputChange={handleInputChange}
                noOptionsText={shouldShowNoOptionsText ? 'Press enter to save' : ''}
                renderInput={params => (
                    <TextField
                        {...params}
                        onKeyDown={handleInputKeyPress}
                        style={textInputStyle}
                        variant="filled"
                        onFocus={handleOnFocus}
                        onBlur={handleOnBlur}
                        InputProps={{
                            ...params.InputProps,
                            disableUnderline: true, // Remove the underline in the textfield
                            classes: {
                                root: classes.inputRoot,
                            },
                            endAdornment: (
                                <React.Fragment>
                                    {loading ? <CircularProgress color="primary" size={20} /> : null}
                                    {enableChevron ? <KeyboardArrowDownIcon color="primary" /> : null}
                                    {params.InputProps.endAdornment}
                                </React.Fragment>
                            ),
                        }}
                    />
                )}
                sx={{
                    width: '100%',
                }}
            />
        </>
    );
};
