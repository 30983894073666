export enum FONT_ENUM {
    BOLD_HEADER = 'MonteserratBold',
    BOLD_TEXT = 'MonteserratBold',
    REGULAR_HEADER = 'RalewayBold',
    REGULAR_TEXT = 'RalewayRegularRalewayRegular',
    MEDIUM_HEADER = 'PoppinsBold',
    MEDIUM_TEXT = 'PoppinsRegular',

    MAIN_BOLD = 'InterBold',
    MAIN_MEDIUM = 'InterMedium',
    MAIN_REGULAR = 'InterRegular',
}
