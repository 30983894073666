import { Box, Typography } from '@mui/material';
import React from 'react';
import { FONT_ENUM } from '../../types/global/fonts.types';

interface IDisplayChipGroup {
    items: string[];
    maxChips?: number;
    fontSize?: number;
    style?: React.CSSProperties;
}
const DisplayChipGroup: React.FC<IDisplayChipGroup> = ({ items, maxChips = 3, fontSize, style }) => {
    if (!items || !items.length) {
        return null;
    }

    const renderedChips = items.slice(0, maxChips).map((item, index) => (
        <Typography
            key={index}
            style={{
                backgroundColor: 'rgb(77, 108, 120)',
                width: 'fit-content',
                color: '#fff',
                borderRadius: 2,
                fontSize: 8,
                fontFamily: FONT_ENUM.REGULAR_HEADER,
            }}
            sx={{
                px: 1,
                py: 0.5,
            }}>
            {item}
        </Typography>
    ));

    const remaining = items.length - maxChips;

    if (remaining > 0) {
        renderedChips.push(
            <Typography
                key={`remaining-chips`}
                style={{
                    backgroundColor: 'rgb(77, 108, 120)',
                    width: 'fit-content',
                    color: '#fff',
                    borderRadius: 2,
                    fontSize: 8,
                    fontFamily: FONT_ENUM.REGULAR_HEADER,
                }}
                sx={{
                    px: 1,
                    py: 0.5,
                }}>
                +{`${remaining}`}
            </Typography>,
        );
    }

    return (
        <Box
            sx={{
                display: 'flex',
                gap: '5px', // Adjust the gap between chips
                maxHeight: '30px',
                flexDirection: 'row',
                overflowX: 'auto',
                mt: 0.5,
            }}>
            {renderedChips}
        </Box>
    );
};

export { DisplayChipGroup };
