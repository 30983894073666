export const types = {
    GET_ALL_AUDITIONS: 'GET_ALL_AUDITIONS',
    GET_ALL_AUDITION_SUCCESS: 'GET_ALL_AUDITION_SUCCESS',
    GET_ALL_AUDITION_FAILED: 'GET_ALL_AUDITION_FAILED',

    GET_AUDITION_DETAIL: 'GET_AUDITION_DETAIL',
    GET_AUDITION_DETAIL_SUCCESS: 'GET_AUDITION_DETAIL_SUCCESS',
    GET_AUDITION_DETAIL_FAILED: 'GET_AUDITION_DETAIL_FAILED',

    APPLY_TO_AUDITION: 'APPLY_TO_AUDITION',
    APPLY_TO_AUDITION_SUCCESS: 'APPLY_TO_AUDITION_SUCCESS',
    APPLY_TO_AUDITION_FAILED: 'APPLY_TO_AUDITION_FAILED',
    ADD_AUDITION_TO_WATCHLIST: 'ADD_AUDITION_TO_WATCHLIST',
    ADD_AUDITION_TO_WATCHLIST_SUCCESS: 'ADD_AUDITION_TO_WATCHLIST_SUCCESS',
    ADD_AUDITION_TO_WATCHLIST_FAILED: 'ADD_AUDITION_TO_WATCHLIST_FAILED',
    GET_ROLES: 'GET_ROLES',
    GET_ROLES_SUCCESS: 'GET_ROLES_SUCCESS',
    GET_ROLES_FAILED: 'GET_ROLES_FAILED',
    GET_MY_APPLICATIONS: 'GET_MY_APPLICATIONS',
    GET_MY_APPLICATIONS_SUCCESS: 'GET_MY_APPLICATIONS_SUCCESS',
    GET_MY_APPLICATIONS_FAILED: 'GET_MY_APPLICATIONS_FAILED',
    TOGGLE_FILTER: 'SHOW_FILTER',
    ADD_FILTER: 'ADD_FILTER',
    REMOVE_FILTER: 'REMOVE_FILTER',
    SAVE_COLLECTION: 'SAVE_COLLECTION',
    SAVE_COLLECTION_SUCCESS: 'SAVE_COLLECTION_SUCCESS',
    SAVE_COLLECTION_FAIL: 'SAVE_COLLECTION_FAIL',
    GET_SAVED_COLLECTION: 'GET_SAVED_COLLECTION',
    GET_SAVED_COLLECTION_SUCCESS: 'GET_SAVED_COLLECTION_SUCCESS',
    GET_SAVED_COLLECTION_FAIL: 'GET_SAVED_COLLECTION_FAIL',
    DELETE_SAVED_COLLECTION: 'DELETE_SAVED_COLLECTION',
    DELETE_SAVED_COLLECTION_SUCCESS: 'DELETE_SAVED_COLLECTION_SUCCESS',
    DELETE_SAVED_COLLECTION_FAIL: 'DELETE_SAVED_COLLECTION_FAIL',
    UPDATE_SAVED_COLLECTION: 'UPDATE_SAVED_COLLECTION',
    UPDATE_SAVED_COLLECTION_SUCCESS: 'UPDATE_SAVED_COLLECTION_SUCCESS',
    UPDATE_SAVED_COLLECTION_FAIL: 'UPDATE_SAVED_COLLECTION_FAIL',

    CREATE_AUDITION: 'CREATE_AUDITION',
    CREATE_AUDITION_SUCCESS: 'CREATE_AUDITION_SUCCESS',
    CREATE_AUDITION_FAIL: 'CREATE_AUDITION_FAIL',

    CREATE_ROLE: 'CREATE_ROLE',
    CREATE_ROLE_SUCCESS: 'CREATE_ROLE_SUCCESS',
    CREATE_ROLE_FAIL: 'CREATE_ROLE_FAIL',

    UPDATE_AUDITION: 'UPDATE_AUDITION',
    UPDATE_AUDITION_SUCCESS: 'UPDATE_AUDITION_SUCCESS',
    UPDATE_AUDITION_FAIL: 'UPDATE_AUDITION_FAIL',

    SET_AUDITION_FOR_EDIT: 'SET_AUDITION_FOR_EDIT',

    DELETE_ROLE: 'DELETE_ROLE',
    DELETE_ROLE_SUCCESS: 'DELETE_ROLE_SUCCESS',
    DELETE_ROLE_FAIL: 'DELETE_ROLE_FAIL',

    PUBLISH_AUDITION: 'PUBLISH_AUDITION',
    PUBLISH_AUDITION_SUCCESS: 'PUBLISH_AUDITION_SUCCESS',
    PUBLISH_AUDITION_FAIL: 'PUBLISH_AUDITION_FAIL',

    UNPUBLISH_AUDITION: 'UNPUBLISH_AUDITION',
    UNPUBLISH_AUDITION_SUCCESS: 'UNPUBLISH_AUDITION_SUCCESS',
    UNPUBLISH_AUDITION_FAIL: 'UNPUBLISH_AUDITION_FAIL',

    DELETE_AUDITION: 'DELETE_AUDITION',
    DELETE_AUDITION_SUCCESS: 'DELETE_AUDITION_SUCCESS',
    DELETE_AUDITION_FAIL: 'DELETE_AUDITION_FAIL',

    UPLOAD_PROGRESS: 'UPLOAD_PROGRESS',

    SET_AUDITION_TO_MANAGE: 'SET_AUDITION_TO_MANAGE',
    SET_APPLICATIONS_TO_MANAGE: 'SET_APPLICATIONS_TO_MANAGE',

    GET_ALL_APPLICATION_FOR_AUDITION: 'GET_ALL_APPLICATION_FOR_AUDITION',
    GET_ALL_APPLICATION_FOR_AUDITION_SUCCESS: 'GET_ALL_APPLICATION_FOR_AUDITION_SUCCESS',
    GET_ALL_APPLICATION_FOR_AUDITION_FAIL: 'GET_ALL_APPLICATION_FOR_AUDITION_FAIL',

    SORT_APPLICATION: 'SORT_APPLICATION',
    SORT_APPLICATION_SUCCESS: 'SORT_APPLICATION_SUCCESS',
    SORT_APPLICATION_FAIL: 'SORT_APPLICATION_FAIL',

    SET_AUDITION_DETAILS: 'SET_AUDITION_DETAILS',

    FILTER_AUDITION: 'FILTER_AUDITION',
    FILTER_AUDITION_SUCCESS: 'FILTER_AUDITION_SUCCESS',
    FILTER_AUDITION_FAIL: 'FILTER_AUDITION_FAIL',

    ADVANCED_AUDITION_SEARCH: 'ADVANCED_AUDITION_SEARCH',
    ADVANCED_AUDITION_SEARCH_SUCCESS: 'ADVANCED_AUDITION_SEARCH_SUCCESS',
    ADVANCED_AUDITION_SEARCH_FAIL: 'ADVANCED_AUDITION_SEARCH_FAIL',

    LOAD_MORE_AUDITIONS: 'LOAD_MORE_AUDITIONS',
    LOAD_MORE_AUDITIONS_SUCCESS: 'LOAD_MORE_AUDITIONS_SUCCESS',
    LOAD_MORE_AUDITIONS_FAIL: 'LOAD_MORE_AUDITIONS_FAIL',

    WITHDRAW_APPLICATION: 'WITHDRAW_APPLICATION',
    WITHDRAW_APPLICATION_SUCCESS: 'WITHDRAW_APPLICATION_SUCCESS',
    WITHDRAW_APPLICATION_FAIL: 'WITHDRAW_APPLICATION_FAIL',
    RESET_AUDTION_DATA: 'RESET_AUDTION_DATA',
};
