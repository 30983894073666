import produce from 'immer';
import {
    IAudienceInitialState,
    IFollowThspian,
    IFollowThspianModel,
    IGetAudience,
    IGetAudienceResponse,
    IGetWatchlistResponse,
} from './model';
import { types } from './types';
import { IErrorType } from '../../constants/types';
import { generateTemporaryFollowObject } from './action';
import { IGenericDocumentModifier } from '../../types/global/helper';
import { isEmpty } from '../../utils/lodash';
import { WritableDraft } from 'immer/dist/internal.js';

const initialState: IAudienceInitialState = {
    blockingWatcher: false,
    errMsg: null,
    userAudience: [],
    userFollowing: [],
    gettingAudience: false,
    gettingUserFollowing: false,
    suggestedThspians: [],
    currentPage: 0,
    gettingSuggestedThspians: false,
    followingInProgress: false,
    loadMore: false,
    loadingMore: false,
};

const reducer = (state = initialState, action: any): IAudienceInitialState => {
    const { type, payload } = action;

    switch (type) {
        case types.BLOCK_A_FOLLOWER:
            return produce(state, draft => {
                draft.blockingWatcher = true;
            });
        case types.BLOCK_A_FOLLOWER_SUCCESS:
            return produce(state, draft => {
                const thspianIndex = draft.userAudience.findIndex(thspian => thspian._id === payload._id);
                draft.userAudience[thspianIndex] = payload;
                draft.blockingWatcher = false;
            });
        case types.BLOCK_A_FOLLOWER_FAIL:
            return produce(state, draft => {
                draft.blockingWatcher = false;
                draft.errMsg = payload;
            });
        case types.GET_MY_AUDIENCE:
        case types.GET_EXTERNAL_FOLLOWERS:
            return produce(state, draft => {
                const { page, isLoadMore } = payload as IGetAudience;
                draft.currentPage = page;
                if (page < 2) {
                    draft.gettingAudience = true;
                }

                if (isLoadMore) {
                    draft.loadingMore = isLoadMore;
                }
            });
        case types.GET_MY_AUDIENCE_SUCCESS:
        case types.GET_EXTERNAL_FOLLOWERS_SUCCESS:
            return produce(state, draft => {
                const { data, page, loadMore } = payload as IGetAudienceResponse;
                if (page && page > 1) {
                    draft.userAudience = [...draft.userAudience, ...data];
                } else {
                    draft.userAudience = data;
                }
                draft.currentPage = page;
                draft.gettingAudience = false;
                draft.loadMore = loadMore;
                draft.loadingMore = false;
            });
        case types.GET_MY_AUDIENCE_FAIL:
        case types.GET_EXTERNAL_FOLLOWERS_FAIL:
            return produce(state, draft => {
                draft.gettingAudience = false;
                draft.errMsg = payload;
                draft.loadingMore = false;
            });
        case types.GET_MY_FOLLOWING:
        case types.GET_EXTERNAL_FOLLOWING:
            return produce(state, draft => {
                const { page, isLoadMore } = payload as IGetAudience;
                if (page < 2) {
                    draft.gettingUserFollowing = true;
                    draft.currentPage = page;
                }
                if (isLoadMore) {
                    draft.loadingMore = isLoadMore;
                }
            });
        case types.GET_MY_FOLLOWING_SUCCESS:
        case types.GET_EXTERNAL_FOLLOWING_SUCCESS:
            return produce(state, draft => {
                const { data, page, loadMore } = payload as IGetWatchlistResponse;
                if (page && page > 1) {
                    draft.userFollowing = [...draft.userFollowing, ...data];
                } else {
                    draft.userFollowing = data;
                }
                draft.currentPage = page;
                draft.loadMore = loadMore;
                draft.gettingUserFollowing = false;
                draft.loadingMore = false;
            });
        case types.GET_MY_FOLLOWING_FAIL:
        case types.GET_EXTERNAL_FOLLOWING_FAIL:
            return produce(state, draft => {
                draft.errMsg = payload;
                draft.loadingMore = false;
            });
        case types.GET_SUGGESTED_THSPIANS:
            return produce(state, draft => {
                draft.gettingSuggestedThspians = true;
            });
        case types.GET_SUGGESTED_THSPIANS_SUCCESS:
            return produce(state, draft => {
                draft.suggestedThspians = payload;
                draft.gettingSuggestedThspians = false;
            });
        case types.GET_SUGGESTED_THSPIANS_FAIL:
            return produce(state, draft => {
                draft.errMsg = payload;
                draft.gettingSuggestedThspians = false;
            });
        case types.FOLLOW_A_THSPIAN:
            return produce(state, draft => {
                const { following } = payload as IFollowThspian;
                const temporaryFolloingObject: IFollowThspianModel = generateTemporaryFollowObject(following);
                draft.userFollowing.push(temporaryFolloingObject);
                draft.followingInProgress = true;
            });
        case types.FOLLOW_A_THSPIAN_SUCCESS:
            return produce(state, draft => {
                draft.followingInProgress = false;
                const index = draft.userFollowing.findIndex(
                    following =>
                        !!following && following.optimistic && following.following._id === payload.following._id,
                );
                if (index > -1) {
                    draft.userFollowing[index] = payload;
                    // draft.userFollowing.push(payload);
                }
            });
        case types.FOLLOW_A_THSPIAN_FAIL:
            return produce(state, draft => {
                draft.followingInProgress = false;
                const { data, documentId } = payload as IErrorType;
                draft.errMsg = data;
                draft.userFollowing = draft.userFollowing.filter(following => following.following._id !== documentId);
                draft.userFollowing.filter(following => !isEmpty(following) && !following.optimistic);
            });
        case types.UNFOLLOW_A_THSPIAN:
            return produce(state, draft => {
                draft.followingInProgress = true;
                const { documentId } = payload as IGenericDocumentModifier;
                draft.userFollowing.filter(
                    following => !!following && !!following.following && following.following._id !== documentId,
                );
            });
        case types.UNFOLLOW_A_THSPIAN_SUCCESS:
            return produce(state, draft => {
                draft.followingInProgress = false;
            });
        case types.UNFOLLOW_A_THSPIAN_FAIL:
            return produce(state, draft => {
                draft.followingInProgress = false;
                const { additionalPayload } = payload as IGenericDocumentModifier;
                draft.userFollowing.push(additionalPayload);
            });
        case types.RESET_FIELD:
            return produce(state, draft => {
                const fields = Object.keys(payload);
                fields.forEach(field => {
                    draft[field as keyof WritableDraft<IAudienceInitialState>] = payload[field];
                });
            });
        default:
            return state;
    }
};

export { reducer };
