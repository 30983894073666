import { ReactNode } from 'react';
import { Box, Typography } from '@mui/material';
import { StyleSheet } from 'react-native';
import { useThemeContext } from '../../hook/useThemeManager';
import { ColorScheme, theme } from '../../constants/theme';
import { FONT_ENUM } from '../../types/global/fonts.types';

type FormWrapperProps = {
    title: string;
    subtitle: string;
    children: ReactNode;
};

export function EsResetWrapper({ title, subtitle, children }: FormWrapperProps) {
    const { themeStyle } = useThemeContext();
    const styles = dynamicStyles(themeStyle);

    return (
        <Box>
            <Typography variant="h2" style={styles.title}>
                {title}
            </Typography>
            <Typography style={styles.subtitle}>{subtitle}</Typography>
            {children}
        </Box>
    );
}

const dynamicStyles = (colorScheme: ColorScheme) =>
    StyleSheet.create({
        title: {
            maxWidth: 400,
            width: '100%',
            textAlign: 'left',
            fontSize: 24,
            fontStyle: 'normal',
            fontWeight: '600',
            lineHeight: '32px',
            marginBottom: '10px',
            marginTop: '8px',
            color: theme[colorScheme].primary_white,
            fontFamily: FONT_ENUM.MAIN_BOLD,
        },
        subtitle: {
            maxWidth: 400,
            width: '100%',
            textAlign: 'left',
            fontSize: 14,
            fontStyle: 'normal',
            fontWeight: '500',
            color: theme[colorScheme].form_label,
            fontFamily: FONT_ENUM.MAIN_REGULAR,
        },
    });
