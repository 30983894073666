import { acting, contribute, documentary, family, reviewer } from '../asset/images/index';

export interface CategoryItem {
    category_name: string;
    category_icon: string;
    category_description: string;
    background_image: string;
}

export const category: CategoryItem[] = [
    {
        category_name: 'Short Films',
        category_icon: '',
        category_description: 'Films under 15 minutes',
        background_image: reviewer,
    },
    {
        category_name: 'Feature Films',
        category_icon: '',
        category_description: 'Films above 45 minutes',
        background_image: family,
    },
    {
        category_name: 'Scripts',
        category_icon: '',
        category_description: 'Feature and short scripts',
        background_image: contribute,
    },
    {
        category_name: 'Acting Reel',
        category_icon: '',
        category_description: 'Short clips',
        background_image: acting,
    },
    {
        category_name: 'Documentary',
        category_icon: '',
        category_description: 'Documetary films',
        background_image: documentary,
    },
    {
        category_name: 'Documentary',
        category_icon: '',
        category_description: 'Documetary films',
        background_image: documentary,
    },
    {
        category_name: 'Documentary',
        category_icon: '',
        category_description: 'Documetary films',
        background_image: documentary,
    },
    {
        category_name: 'Documentary',
        category_icon: '',
        category_description: 'Documetary films',
        background_image: documentary,
    },
    {
        category_name: 'Documentary',
        category_icon: '',
        category_description: 'Documetary films',
        background_image: documentary,
    },
];
