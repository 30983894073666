import { createAction } from 'redux-act';
import { Discovery, IFilterResponse, ISearchPayload, ISearchResponse, ISetFilter, types } from './types';
import { IErrorType } from '../../constants/types';

export const getDiscovery = createAction(types.GET_DISCOVERY);
export const getDiscoverySuccess = createAction<Discovery>(types.GET_DISCOVERY_SUCCESS);
export const getDiscoveryFail = createAction<IErrorType>(types.GET_DISCOVERY_FAIL);

export const searchDiscovery = createAction<ISearchPayload>(types.SEARCH_DISCOVERY);
export const searchDiscoverySuccess = createAction<ISearchResponse>(types.SEARCH_DISCOVERY_SUCCESS); // API Support In Progress
export const searchDiscoveryFail = createAction<IErrorType>(types.SEARCH_DISCOVERY_FAIL);

export const filter = createAction<ISetFilter>(types.FILTER);
export const filterSuccess = createAction<IFilterResponse>(types.FILTER_SUCCESS);
export const filterFail = createAction<IErrorType>(types.FILTER_FAIL);

export const setViewAll = createAction(types.SET_VIEW_ALL);
