import { call, put, all, takeLatest } from 'redux-saga/effects';
import { ILinkPayload, types } from './types';
import {
    createLinkFail,
    createLinkSuccess,
    deleteLinkFail,
    deleteLinkSuccess,
    getLinkFail,
    getLinkSuccess,
    getLinksFail,
    getLinksSuccess,
    updateLinkFail,
    updateLinkSuccess,
} from './action';
import { globalErrorHandler } from '../error/saga';
import { api } from '../../configurations/api';
import { linkUrl, myLinksUrl } from '../../configurations/api/url';
import { httpRequest } from '../types';
import { SentryCapture } from '../../analytics/Sentry';
import { Action } from 'redux';

function* createLink({ payload, resolve, reject }: { payload: ILinkPayload; resolve: any; reject: any }): any {
    try {
        const response = yield call(api, linkUrl, httpRequest.POST, payload, 2, 1000);
        const { data } = response.data;
        yield put(createLinkSuccess(data));
        if (resolve) {
            resolve(data);
        }
    } catch (error: any) {
        SentryCapture(error, 'error');
        const { data } = error || {};
        yield put(createLinkFail(error));
        yield call(globalErrorHandler, data.message);
        if (reject) {
            reject();
        }
    }
}

function* updateLink({ payload }: { payload: ILinkPayload }): any {
    const request = `${linkUrl}/${payload.linkId}`;
    try {
        const response = yield call(api, request, httpRequest.PATCH, payload, 5, 0);
        const { data } = response.data;
        yield put(updateLinkSuccess(data));
    } catch (error: any) {
        SentryCapture(error, 'error');
        yield put(updateLinkFail(error));
        yield call(globalErrorHandler, error);
    }
}

function* deleteLink({ payload }: { payload: ILinkPayload }): any {
    try {
        const request = `${linkUrl}/${payload.linkId}`;
        yield call(api, request, httpRequest.DELETE, {}, 3, 0);
        yield put(deleteLinkSuccess(payload));
    } catch (error: any) {
        SentryCapture(error, 'error');
        yield put(deleteLinkFail(error));
        yield call(globalErrorHandler, error);
    }
}

function* getMyLinks(): any {
    try {
        const response = yield call(api, `${myLinksUrl}`, httpRequest.GET, null, 2, 3000);
        const { data } = response.data;
        yield put(getLinksSuccess(data));
    } catch (error: any) {
        console.log(error, 'WHAT ERR');
        SentryCapture(error, 'error');
        yield put(getLinksFail(error));
        yield call(globalErrorHandler, error);
    }
}

function* getLink({ payload }: { payload: string }): any {
    try {
        const request = `${linkUrl}/${payload}`;
        const response = yield call(api, request, httpRequest.GET, {}, 3, 0);
        const { data } = response.data;
        yield put(getLinkSuccess(data));
    } catch (error: any) {
        SentryCapture(error, 'error');
        yield put(getLinkFail(error));
        yield call(globalErrorHandler, error);
    }
}

interface ITaskAction extends Action {
    payload: ILinkPayload;
    resolve: any;
    reject: any;
}

function* createLinkWatcher() {
    yield takeLatest<ITaskAction>(types.CREATE_LINK, createLink);
}

function* updateLinkWatcher() {
    yield takeLatest<any>(types.UPDATE_LINK, updateLink);
}

function* deleteLinkWatcher() {
    yield takeLatest<ITaskAction>(types.DELETE_LINK, deleteLink);
}

function* getMyLinksWatcher() {
    yield takeLatest(types.GET_MY_LINKS, getMyLinks);
}

function* getLinkWatcher() {
    yield takeLatest<any>(types.GET_LINK, getLink);
}

export default function* linksSaga() {
    yield all([createLinkWatcher(), updateLinkWatcher(), deleteLinkWatcher(), getMyLinksWatcher(), getLinkWatcher()]);
}
