import React from 'react';
import { ThemeContext } from '../../module/ThemeManager';
import { Container } from '@mui/material';
import { TrendingCardShimmer } from '../TrendingCardShimmer';

const TrendingVideosShimmer = () => {
    return (
        <ThemeContext.Consumer>
            {() => {
                return (
                    <Container
                        disableGutters
                        sx={{
                            width: '100%',
                            marginTop: { xs: '0px', sm: '30px', lg: '30px' },
                            marginLeft: { xs: '15px', sm: '65px' },
                            marginRight: { xs: '15px' },
                            paddingLeft: { xs: '0px', sm: '40px' },
                            paddingRight: { xs: '0px', sm: '40px' },
                            overflowX: 'hidden',
                        }}
                        maxWidth={false}>
                        <TrendingCardShimmer />
                    </Container>
                );
            }}
        </ThemeContext.Consumer>
    );
};

export { TrendingVideosShimmer };
