export const types = {
    TRACK_AD: 'TRACK_AD',
    TRACK_AD_SUCCESS: 'TRACK_AD_SUCCESS',
    TRACK_AD_FAIL: 'TRACK_AD_FAIL',

    SET_PLAYED_ADS: 'SET_PLAYED_ADS',
    SET_PLAYED_VIDEO_COUNT: 'SET_PLAYED_VIDEO_COUNT',

    GET_AD: 'GET_AD',
    GET_AD_SUCCESS: 'GET_AD_SUCCESS',
    GET_AD_FAIL: 'GET_AD_FAIL',

    HIDE_AD_BANNER: 'HIDE_AD_BANNER',
};
