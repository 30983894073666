import { combineReducers } from 'redux';

import { reducer as AppConfiguratorReducer } from './appConfigurator/reducer';
import { authentication as AuthenticationReducer } from './authentication/reducer';
import { reducer as ConfigReducer } from './configuration/reducer';
import { reducer as ErrorReducer } from './error/reducer';
import { reducer as FestivalReducer } from './festival/reducer';
import { reducer as RefreshTokenReducer } from './refreshToken/reducer';
import { reducer as UserReducer } from './user/reducers';
import { reducer as SplashReducer } from './splash/reducer';
import { reducer as AdopterReducer } from './adopter/reducer';
import { reducer as AccountReducer } from './account/reducer';
import { reducer as AuditionReducer } from './audition/reducer';
import { reducer as NotificationsReducer } from './notifications/reducer';
import { reducer as SubscriptionsReducer } from './subscription/reducer';
import { reducer as TalentProfileReducer } from './talentProfile/reducer';
import { reducer as ContestReducer } from './contest/reducer';
import { reducer as ProjectReducer } from './project/reducer';
import { reducer as DiscoveryReducer } from './discovery/reducer';
import { reducer as ActivityReducer } from './activity/reducer';
import { reducer as ComingSoonReducer } from './comingSoon/reducer';
import { reducer as CacheReducer } from './cache/reducer';
import { reducer as LiveReducer } from './live/reducer';
import { reducer as JuryReducer } from './jury/reducer';
import { reducer as ReportReducer } from './report/reducer';
import { reducer as ViewsReducer } from './view/reducer';
import { reducer as InAppNotification } from './in-app-notification/reducer';
import { reducer as VotesReducer } from './vote/reducer';
import { reducer as AudienceReducer } from './audience/reducer';
import { reducer as PlayerReducer } from './player/reducer';
import { reducer as RequestReducer } from './requests/reducer';
import { reducer as LinkReducer } from './links/reducer';
import { reducer as TalentReducer } from './talent/reducer';
import { reducer as AttendanceReducer } from './attendance/reducer';
import { reducer as AdvertReducer } from './adverts/reducer';
import { reducer as UploadReducer } from './upload/reducer';

const appReducer = combineReducers({
    account: AccountReducer,
    activity: ActivityReducer,
    adopter: AdopterReducer,
    adverts: AdvertReducer,
    appConfigurator: AppConfiguratorReducer,
    attendance: AttendanceReducer,
    audience: AudienceReducer,
    audition: AuditionReducer,
    authentication: AuthenticationReducer,
    cache: CacheReducer,
    comingSoon: ComingSoonReducer,
    configuration: ConfigReducer,
    contest: ContestReducer,
    discovery: DiscoveryReducer,
    error: ErrorReducer,
    festivals: FestivalReducer,
    jury: JuryReducer,
    links: LinkReducer,
    live: LiveReducer,
    nebulaPlayer: PlayerReducer,
    notification: NotificationsReducer,
    project: ProjectReducer,
    report: ReportReducer,
    requests: RequestReducer,
    splash: SplashReducer,
    subscription: SubscriptionsReducer,
    talent: TalentProfileReducer,
    tokenRefresh: RefreshTokenReducer,
    upload: UploadReducer,
    user: UserReducer,
    views: ViewsReducer,
    inAppNotification: InAppNotification,
    vote: VotesReducer,
    userTalent: TalentReducer,
});

export type RootState = ReturnType<typeof appReducer>;

const reducer = (state: any, action: any) => {
    if (action.type === 'PURGE') {
        return appReducer(undefined, action);
    }

    return appReducer(state, action);
};

export { reducer };
