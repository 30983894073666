//@ts-ignore
import io, { Socket } from 'socket.io-client';
import { eventChannel } from 'redux-saga';
import { fork, take, call, put, cancel, takeLatest } from 'redux-saga/effects';
import {
    sendMessage,
    sendMessageSuccess,
    retrieveMessages,
    userTyping,
    joinChatRoom,
    typingStatusSocket,
    userHasReadMessage,
    getMessagesForSelectedRoom,
    joinAllChatRooms,
    setOnlineStatus,
    setOnlineUsers,
    setOfflineUsers,
    newContent,
    NewContentType,
    setNewContentAvailable,
    setRoomMessages,
    disconnect,
    disconnectSuccess,
} from './action';
import { retrieveJwt } from '../authentication';
import { types } from './types';
import { SOCKET } from '../../configurations/api/url';
import { SOCKET_REQUESTS } from './messages';
import { IProject } from '../../types/global/helper';
import { WebNotification } from '../account/accountDetailsResponse.type';
import { recieveNotification } from '../notifications/action';
import { mediaForgeWorker, updateProjectSuccess } from '../project/actions';
import { genericParseSingleDocument } from '../../utils/responseProcessor';
import { nuke } from '../cache/action';
import { getJWtDetails } from '../../utils/AppUtils';
import { annouceNewFollower } from '../audience/action';
import { Follower } from '../user/model';
import { refreshUserDashboard } from '../account/actions';

function connect(jwt: any, userID: string) {
    const socket = io(SOCKET, {
        forceNew: true,
        transports: ['polling', 'websocket'],
        secure: true,
        transportOptions: {
            polling: {
                extraHeaders: {
                    Authorization: `Bearer ${jwt}`,
                    'X-User': userID,
                },
            },
        },
        withCredentials: true,
    });
    socket.emit(SOCKET_REQUESTS.ONLINE, { status: 'online' });
    return new Promise(resolve => {
        socket.on('connect', () => {
            resolve(socket);
        });
        socket.on('connect_error', () => {
            socket.io.opts.transports = ['polling', 'websocket'];
        });
    });
}

function subscribe(socket: Socket) {
    return eventChannel(emit => {
        /** The below shoudl get all messages and we then sort them by RoomID */
        socket.on(SOCKET_REQUESTS.GET_ALL_MESAGES, ({ rooms }) => {
            /** Dispatch the success here */
            // emit(getAllMessagesSuccess({ rooms }));
        });

        socket.on(SOCKET_REQUESTS.NEW_MESSAGE, ({ newMessage, modifiedRoom }) => {
            const payload = newMessage;
            payload.newRoom = modifiedRoom;
            emit(sendMessageSuccess(payload));
        });
        socket.on(SOCKET_REQUESTS.DISCONNECT, e => {
            // TODO: handle
            emit(disconnectSuccess({ connected: false }));
        });

        socket.on(SOCKET_REQUESTS.USER_TYPING, ({ status, roomID }) => {
            // emit an action
            emit(userTyping({ status, roomID }));
        });

        socket.on(SOCKET_REQUESTS.ROOM_MESSAGES, ({ messages }) => {
            emit(setRoomMessages(messages));
        });

        socket.on(SOCKET_REQUESTS.SET_ONLINE_USERS, ({ user }) => {
            emit(setOnlineUsers(user));
        });

        socket.on(SOCKET_REQUESTS.SET_OFFLINE_USERS, ({ user }) => {
            emit(setOfflineUsers(user));
        });

        socket.on(SOCKET_REQUESTS.NEW_CONTENT_AVAILABLE, ({ content_type }: { content_type: NewContentType }) => {
            emit(setNewContentAvailable(content_type));
        });

        socket.on(SOCKET_REQUESTS.CLEAR_CACHE, ({ message }: { message: string }) => {
            /** The server dispatches request to clear cache every 12 hours.
             * This listens for that. Trigger the nuke cache here to clear all users cache
             */
            emit(nuke());
        });

        socket.on(
            SOCKET_REQUESTS.NEW_FOLLOWER,
            ({ details }: { follower: Follower; type: 'user' | 'project'; details: WebNotification }) => {
                emit(recieveNotification(details));
                /** New Follower. Refresh User dashboard to show nfollowing number */
                emit(refreshUserDashboard());
            },
        );

        /** Preparing this for User Feed */
        socket.on(SOCKET_REQUESTS.NEW_ACTIVITY, ({ type, payload }: { type: 'user' | 'project'; payload: any }) => {
            /** New Follower. Refresh User dashboard to show nfollowing number */
        });

        socket.on(
            SOCKET_REQUESTS.MEDIA_PROCESSING_COMPLETE,
            ({
                project,
                project_id,
                details,
                type,
            }: {
                project: IProject;
                project_id: string;
                details: WebNotification;
                type: 'thumbnail' | 'quality';
            }) => {
                /** The server dispatches request to clear cache every 12 hours.
                 * This listens for that. Trigger the nuke cache here to clear all users cache
                 */
                if (type === 'thumbnail') {
                    emit(
                        mediaForgeWorker({
                            status: 'awaiting processing',
                            mediaForgeMessage: 'Thumbnail Processing Completed...🥳',
                            project: project,
                        }),
                    );
                }

                if (type === 'quality') {
                    emit(
                        mediaForgeWorker({
                            status: 'completed',
                            mediaForgeMessage: 'Project Processing Completed...🥳',
                            project: project,
                        }),
                    );
                    const parsedProject = genericParseSingleDocument(project);
                    emit(updateProjectSuccess(parsedProject));
                    emit(recieveNotification(details));
                }
            },
        );

        return () => {};
    });
}

function* read(socket: Socket): any {
    const channel = yield call(subscribe, socket);
    while (true) {
        const action = yield take(channel);
        yield put(action);
    }
}

function* write(socket: Socket) {
    while (true) {
        const { payload } = yield take(`${sendMessage}`);
        socket.emit('message', payload);
        socket.emit('typingStatus', { status: 'no', roomID: payload.roomID });
    }
}

/** This Actually gets Rooms and not messages */
function* getAllMessagesAction(socket: Socket) {
    while (true) {
        const { payload } = yield take(`${retrieveMessages}`);
        /** Dispatch the initial action here */
        socket.emit(SOCKET_REQUESTS.RETRIEVE_ROOMS, payload);
    }
}

function* userHasRead(socket: Socket) {
    while (true) {
        const { payload } = yield take(`${userHasReadMessage}`);
        socket.emit(SOCKET_REQUESTS.USER_HAS_READ, payload);
    }
}

function* joinRoom(socket: Socket) {
    while (true) {
        const { payload } = yield take(`${joinChatRoom}`);
        socket.emit(SOCKET_REQUESTS.JOIN_ROOM, payload);
    }
}

function* followUser(socket: Socket) {
    while (true) {
        const { payload } = yield take(`${annouceNewFollower}`);
        socket.emit(SOCKET_REQUESTS.FOLLOW_THSPIAN, payload);
    }
}

function* joinAllRooms(socket: Socket) {
    while (true) {
        const { payload } = yield take(`${joinAllChatRooms}`);
        socket.emit(SOCKET_REQUESTS.JOIN_ALL_ROOMS, payload);
    }
}

/** Get the messages for a particular room */
function* getRoomMessages(socket: Socket) {
    while (true) {
        const { payload } = yield take(`${getMessagesForSelectedRoom}`);
        socket.emit(SOCKET_REQUESTS.GET_ROOM_MESSAGES, payload);
    }
}

/** Typing status for user */
function* typingStatus(socket: Socket) {
    while (true) {
        const { payload } = yield take(`${typingStatusSocket}`);
        socket.emit(SOCKET_REQUESTS.USER_TYPING, payload);
    }
}

/** Set whether a user is online or offline */
function* setUserOnlineStatus(socket: Socket) {
    while (true) {
        const { payload } = yield take(`${setOnlineStatus}`);
        socket.emit(SOCKET_REQUESTS.ONLINE, { status: payload });
    }
}

function* newContentDispatcher(socket: Socket) {
    while (true) {
        const { payload } = yield take(`${newContent}`);
        socket.emit(SOCKET_REQUESTS.NEW_CONTENT, { content_type: payload });
    }
}

function* handleIO(socket: Socket) {
    yield fork(newContentDispatcher, socket);
    yield fork(setUserOnlineStatus, socket);
    yield fork(read, socket);
    yield fork(write, socket);
    yield fork(getAllMessagesAction, socket);
    yield fork(joinRoom, socket);
    yield fork(joinAllRooms, socket);
    yield fork(typingStatus, socket);
    yield fork(userHasRead, socket);
    yield fork(getRoomMessages, socket);
    yield fork(followUser, socket);
}

function* flow(): any {
    while (true) {
        const jwt = yield retrieveJwt();
        const { isSignedIn, user_id } = getJWtDetails(jwt);
        if (isSignedIn) {
            const socket = yield call(connect, jwt, user_id);
            const task = yield fork(handleIO, socket);
            yield take(`${disconnect}`);
            yield cancel(task);
            socket.emit(SOCKET_REQUESTS.DISCONNECT);
        }
    }
}

function* flowWatcher() {
    yield takeLatest(types.CONNECT, flow);
}

export default function* socketSaga() {
    yield fork(flowWatcher);
}
