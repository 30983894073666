import React from 'react';
import styled from 'styled-components';

type Hue = '240' | '340';

interface ILinearGradient {
    children: JSX.Element;
    gradientColor: string[];
    style?: any;
    hue?: Hue;
    isFocused?: boolean;
}
const LinearGradient = ({ children, gradientColor, style, hue, isFocused }: ILinearGradient) => {
    const SelectedGradient = isFocused ? Gradient : GradientErrorState;
    return (
        <SelectedGradient hue={hue} colors={gradientColor}>
            {children}
        </SelectedGradient>
    );
};

LinearGradient.defaultProps = {
    hue: '340',
};

const Gradient = styled.div<any>`
    background: linear-gradient(
        20deg,
        hsl(${props => props.hue}, 60%, 65%),
        hsl(${props => props.hue - 305}, 64%, 60%)
    );
    height: 100%;
    width: 100%;
    border-radius: 0.5rem;
    overflow: 'auto';
`;

const GradientErrorState = styled.div<any>`
    background: linear-gradient(20deg, ${({ colors }: { colors: Array<string> }) => colors.join(',')});
    height: 100%;
    width: 100%;
    border-radius: 0.5rem;
`;

export { LinearGradient };
