import React from 'react';
import { ThemeContext } from '../../module/ThemeManager';
import { Box, Stack, Skeleton } from '@mui/material';
import { theme } from '../../constants/theme';

const HeroDetailsShimmer = () => {
    return (
        <ThemeContext.Consumer>
            {({ themeStyle }) => {
                return (
                    <Stack marginBottom={6}>
                        <Box position={'relative'} overflow={'clip'}>
                            <Skeleton
                                variant="rounded"
                                width="100%"
                                sx={{
                                    backgroundColor: theme[themeStyle].gray_text,
                                    borderRadius: 5,
                                    height: { xs: '450px', sm: '365px', md: '371px', lg: '371px' },
                                }}
                            />
                            <Skeleton
                                variant="text"
                                sx={{
                                    fontSize: '1rem',
                                    width: { xs: '200px', sm: '150px', md: '200px', lg: '200px' },
                                    backgroundColor: theme[themeStyle].darkGray60,
                                    position: 'absolute',
                                    bottom: { xs: 160, sm: 85, md: 85, lg: 85 },
                                    left: { xs: 20, sm: 35, md: 40, lg: 40 },
                                }}
                            />
                            <Skeleton
                                variant="text"
                                sx={{
                                    fontSize: '1rem',
                                    width: { xs: '170px', sm: '130px', md: '170px', lg: '170px' },
                                    backgroundColor: theme[themeStyle].darkGray60,
                                    position: 'absolute',
                                    bottom: { xs: 140, sm: 65, md: 65, lg: 65 },
                                    left: { xs: 20, sm: 35, md: 40, lg: 40 },
                                }}
                            />
                            <Skeleton
                                variant="text"
                                sx={{
                                    fontSize: '1rem',
                                    width: { xs: '120px', sm: '100px', md: '120px', lg: '120px' },
                                    backgroundColor: theme[themeStyle].darkGray60,
                                    position: 'absolute',
                                    bottom: { xs: 120, sm: 45, md: 45, lg: 45 },
                                    left: { xs: 20, sm: 35, md: 40, lg: 40 },
                                }}
                            />
                            <Box position={'absolute'} display={'flex'} flex={1} bottom={30} right={20} gap={2}>
                                <Skeleton
                                    variant="rounded"
                                    sx={{
                                        backgroundColor: theme[themeStyle].black,
                                        width: { xs: 150, sm: 140, md: 180, lg: 180 },
                                        height: 38,
                                        borderRadius: 100,
                                    }}
                                />
                                <Skeleton
                                    variant="rounded"
                                    sx={{
                                        backgroundColor: theme[themeStyle].transparent,
                                        border: 'solid 1px white',
                                        width: { xs: 110, sm: 90, lg: 110 },
                                        height: 38,
                                        borderRadius: 100,
                                    }}
                                />
                            </Box>
                        </Box>
                    </Stack>
                );
            }}
        </ThemeContext.Consumer>
    );
};

export { HeroDetailsShimmer };
