import React, { FormEvent, useState } from 'react';
import Button from '@mui/material/Button';
import { Link, Typography } from '@mui/material';
import { StyleSheet } from 'react-native';
import { useThemeContext } from '../../hook/useThemeManager';
import { ColorScheme, theme } from '../../constants/theme';
import { useMultistepForm } from '../EsSignupForm/useMultistepForm';
import { useEsHover } from '../Animation/useEsHover';
import { EsVerifyCode } from '../EsVerify/EsVerifyCode';
import { EsSigninData, TUsernamePassword } from './EsSigninData';
import { FONT_ENUM } from '../../types/global/fonts.types';
import { ScreenTypes } from '../../module/ThemeManager';
import { validate } from '../../utils/validators';
import { strings } from '../../constants/strings';
import { isEmpty } from '../../utils/lodash';
import { Spinner } from '@chakra-ui/react';
import CustomButton from '../Button/CustomButton';
import { MixPanel, MIXPANEL_EVENT } from '../../analytics/Mixpanel';

const LOGIN_DATA: TUsernamePassword = {
    email: '',
    password: '',
};

interface ILoginProps {
    onSubmit: (data: TUsernamePassword, callBack?: () => void, isLastStep?: boolean) => void;
    onResendOtp: (data: TUsernamePassword) => Promise<unknown>;
    loading?: boolean;
    otpLoading?: boolean;
    resendInProgress?: boolean;
}

const EsLoginForm: React.FC<ILoginProps> = ({ onSubmit, onResendOtp, loading, otpLoading, resendInProgress }) => {
    const [data, setData] = useState(LOGIN_DATA);
    const [otp, setOtp] = useState<string[]>();
    const { themeStyle, device } = useThemeContext();
    const { handleMouseEnter, handleMouseLeave, isHover } = useEsHover();

    const styles = dynamicStyles(themeStyle, isHover, device);

    function updateFields(fields: Partial<TUsernamePassword>) {
        setData(prev => {
            return { ...prev, ...fields };
        });
    }

    const enableButton = () => {
        return !isEmpty(data.email) && validate.validateEmail(data.email) && !isEmpty(data.password);
    };

    const { step, isFirstStep, isLastStep, next, back } = useMultistepForm([
        <EsSigninData {...data} updateFields={updateFields} />,
        <EsVerifyCode
            {...data}
            onClickBack={() => back()}
            onChange={(otp: string[]) => setOtp(otp)}
            onResendOtp={() => onResendOtp(data)}
            loading={otpLoading}
            resendInProgress={resendInProgress}
        />,
    ]);

    const handleSubmitProps = () => {
        const payload = { ...data, code: otp?.join('') };
        onSubmit(payload, next, isLastStep);
        MixPanel.track(MIXPANEL_EVENT.LOGIN, { buttonAction: 'LOGIN', buttonName: 'SIGN IN' });
    };

    function onSubmitHandler(e: FormEvent) {
        e.preventDefault();
        handleSubmitProps();
    }

    return (
        <form style={styles.form} onSubmit={onSubmitHandler}>
            {step}
            {isFirstStep && (
                <>
                    <Button
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                        style={styles.button}
                        onClick={() => {
                            handleMouseLeave();
                        }}
                        disabled={!enableButton() || loading}
                        type="submit">
                        {loading ? (
                            <Spinner h={4} w={4} color={theme[themeStyle].tag_white} />
                        ) : (
                            <Typography style={styles.signup}>{strings.sign_in}</Typography>
                        )}
                    </Button>
                    <Link
                        href="/signup"
                        sx={{
                            '&:hover': { cursor: 'pointer' },
                            textDecoration: 'none',
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}>
                        <CustomButton
                            onClick={() => {}}
                            label="Sign Up"
                            sx={{
                                fontFamily: FONT_ENUM.MAIN_BOLD,
                                fontSize: 12,
                                color: theme[themeStyle].tag_white,
                                textAlign: 'center',
                                marginTop: 2,
                                width: '100%',
                                border: `0.5px solid ${theme[themeStyle].highlight}`,
                                backgroundColor: 'transparent',
                                maxWidth: 400,
                                borderRadius: 30,
                                padding: 12,
                                cursor: 'pointer',
                                '&:hover': { backgroundColor: 'transparent' },
                            }}
                            textStyle={{
                                color: theme[themeStyle].tag_white,
                            }}
                            textSx={{
                                color: theme[themeStyle].tag_white,
                                fontSize: 14,
                                '&:hover': { color: theme[themeStyle].black },
                            }}
                        />
                    </Link>
                </>
            )}
            {isFirstStep && (
                <Typography style={{ ...styles.text, alignSelf: 'center' }}>
                    {strings.protected_page}
                    <a
                        href="https://www.google.com/recaptcha/about/"
                        target="_blank"
                        rel="noreferrer"
                        style={{
                            ...styles.text,
                            color: theme[themeStyle].main_blue,
                            cursor: 'pointer',
                            marginLeft: '8px',
                        }}>
                        {strings.learn_more}
                    </a>
                </Typography>
            )}
        </form>
    );
};

const dynamicStyles = (colorScheme: ColorScheme, isHover: boolean, device: ScreenTypes, disabled?: boolean) =>
    StyleSheet.create({
        form: {
            width: '100%',
            padding: device.isMobile ? 30 : 50,
            maxWidth: 500,
            height: 'auto',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            backgroundColor: theme[colorScheme].form_black,
            borderRadius: 20,
            borderWidth: 1,
            borderColor: theme[colorScheme].pure_black,
        },
        signup: {
            fontSize: 14,
            fontWeight: '600',
            fontFamily: FONT_ENUM.MAIN_BOLD,
            color: isHover && !disabled ? theme[colorScheme].gray_text : theme[colorScheme].primary_orange,
        },

        button: {
            width: '100%',
            height: 44,
            paddingHorizontal: 20,
            paddingVertical: 12,
            borderRadius: 100,
            maxWidth: 400,
            backgroundColor: isHover && !disabled ? theme[colorScheme].primary_orange : theme[colorScheme].gray_text,
            textTransform: 'none',
            alignSelf: 'center',
        },
        text: {
            maxWidth: 354,
            width: '100%',
            fontSize: 14,
            fontStyle: 'normal',
            fontWeight: '400',
            color: theme[colorScheme].primary_white,
            marginTop: 40,
            fontFamily: FONT_ENUM.MAIN_MEDIUM,
        },
    });

export { EsLoginForm };
