import { createAction } from 'redux-act';
import { IAttendance, IGetUserAttendance, ISubmitAttendanceForm, types } from './types';
import { IErrorType } from '../../constants/types';

export const submitAttendanceFrom = createAction<ISubmitAttendanceForm>(types.SUBMIT_ATTENDANCE_FORM);
export const submitAttendanceFromSuccess = createAction<IAttendance>(types.SUBMIT_ATTENDANCE_FORM_SUCCESS);
export const submitAttendanceFromFail = createAction<IErrorType>(types.SUBMIT_ATTENDANCE_FORM_FAIL);

export const getUserAttendance = createAction<IGetUserAttendance>(types.GET_USER_ATTENDANCE);
export const getUserAttendanceSuccess = createAction<IAttendance[]>(types.GET_USER_ATTENDANCE_SUCCESS);
export const getUserAttendanceFail = createAction<IErrorType>(types.GET_USER_ATTENDANCE_FAIL);
