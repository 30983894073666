import { useState } from 'react';
import { ThemeContext } from '../../module/ThemeManager';
import { Box, Select, MenuItem } from '@mui/material';
import { ColorScheme, theme } from '../../constants/theme';
import { FONT_ENUM } from '../../types/global/fonts.types';
import { StyleSheet } from 'react-native';
import chevron from '../../asset/images/icons/es_chevron_down.svg';

interface ISelectProps {
    onFocus?: (e: any) => void;
    onBlur?: (e: any) => void;
    onChange?: (e: any) => void;
    options: string[];
    value?: string;
}

export const CustomSelect = (props: ISelectProps) => {
    const { onFocus, onBlur, onChange, options, value } = props;
    const [isFocused, setIsFocused] = useState(false);

    const handleOnFocus = (e: any) => {
        setIsFocused(true);
        onFocus && onFocus(e);
    };
    const handleOnBlur = (e: any) => {
        setIsFocused(false);
        onBlur && onBlur(e);
    };
    return (
        <ThemeContext.Consumer>
            {({ themeStyle }) => {
                const styles = dynamicStyles(themeStyle);
                return (
                    <Box
                        sx={{
                            width: '100%',
                            height: 'fit-content',
                            paddingRight: '2%',
                            backgroundColor: theme[themeStyle].form_gray,
                            boxShadow:
                                '0px 1px 1px 0px rgba(0, 0, 0, 0.12), 0px 0px 0px 1px rgba(103, 110, 118, 0.16), 0px 2px 5px 0px rgba(103, 110, 118, 0.08)',
                            borderRadius: '8px',
                            border: `1px solid ${
                                isFocused ? theme[themeStyle].highlight : theme[themeStyle].placeholderGray50
                            }`,
                        }}>
                        <Select
                            variant="standard"
                            disableUnderline
                            inputProps={{ IconComponent: () => null }}
                            name="gender"
                            style={styles.datalist}
                            sx={{
                                '&:hover': { cursor: 'pointer' },
                                height: '50px',
                                width: '100%',
                                paddingLeft: '14px',
                                marginTop: '0px',
                            }}
                            MenuProps={{
                                PaperProps: {
                                    sx: {
                                        bgcolor: theme[themeStyle].gray_text,
                                        marginTop: 0,
                                        '& .MuiMenuItem-root': {
                                            padding: 2,
                                        },
                                    },
                                },
                            }}
                            onFocus={handleOnFocus}
                            onBlur={handleOnBlur}
                            onChange={onChange}
                            value={value}>
                            {options.map((option, i) => (
                                <MenuItem key={i} value={option.toLowerCase()} style={styles.option}>
                                    {option}
                                </MenuItem>
                            ))}
                        </Select>
                    </Box>
                );
            }}
        </ThemeContext.Consumer>
    );
};

const dynamicStyles = (colorScheme: ColorScheme) =>
    StyleSheet.create({
        datalist: {
            backgroundColor: theme[colorScheme].form_gray,
            border: 'none',
            outlineStyle: 'none',
            borderRadius: 8,
            WebkitCalendarPickerIndicator: 'none',
            color: theme[colorScheme].lightWhite,
            backgroundImage: `url(${chevron})`,
            backgroundSize: '20px',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'right',
            position: 'relative',
        },
        option: {
            color: theme[colorScheme].lightWhite,
            fontSize: 16,
            cursor: 'pointer',
            fontFamily: FONT_ENUM.MAIN_REGULAR,
            fontWeight: '500',
            textTransform: 'capitalize',
        },
        flexColumn: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'center',
            height: 'fit-content',
        },
    });
