import React from 'react';
import { ThemeContext } from '../../module/ThemeManager';
import { theme } from '../../constants/theme';
import { Box, Grid, Skeleton, Stack } from '@mui/material';

const SuggestedProjectsShimmer = () => {
    return (
        <ThemeContext.Consumer>
            {({ themeStyle }) => {
                return (
                    <Grid container height={'fit-content'} item md={4} sm={4} lg={4} overflow={'hidden'}>
                        <Box
                            sx={{
                                width: '100%',
                                overflowY: 'scroll',
                            }}>
                            <Box position={'relative'}>
                                <Stack direction={'column'}>
                                    <Skeleton
                                        variant="rounded"
                                        height="60px"
                                        sx={{
                                            width: '100%',
                                            marginLeft: { sm: '0px', md: '0px', lg: '50px' },
                                            backgroundColor: theme[themeStyle].gray_text,
                                        }}
                                    />

                                    <Skeleton
                                        variant="text"
                                        sx={{
                                            fontSize: '1rem',
                                            width: '120px',
                                            backgroundColor: theme[themeStyle].darkGray60,
                                            position: 'absolute',
                                            top: '20px',

                                            left: { md: '0px', lg: '50px' },
                                            marginLeft: { sm: '15px', md: '20px', lg: '20px' },
                                        }}
                                    />
                                    <Skeleton
                                        variant="rounded"
                                        height="100vh"
                                        sx={{
                                            width: '100%',
                                            marginLeft: { sm: '0px', md: '0px', lg: '50px' },
                                        }}
                                    />
                                    <Box
                                        sx={{
                                            position: 'absolute',
                                            top: '100px',
                                            left: { sm: '20px', md: '20px', lg: '80px' },
                                        }}>
                                        <Grid container spacing={2}>
                                            {Array.from(new Array(4)).map((_, index) => (
                                                <Grid item xs={'auto'} md={'auto'} lg={'auto'} key={index}>
                                                    <Stack
                                                        key={index}
                                                        direction={{ xs: 'row', sm: 'column', md: 'column', lg: 'row' }}
                                                        spacing={2}>
                                                        <Skeleton
                                                            variant="rounded"
                                                            height={100}
                                                            sx={{
                                                                width: {
                                                                    xs: '100%',
                                                                    md: '180px',
                                                                    sm: '180px',
                                                                    lg: '180px',
                                                                },
                                                                backgroundColor: theme[themeStyle].gray_text,
                                                                borderRadius: '10px',
                                                            }}
                                                        />

                                                        <Stack direction="column">
                                                            <Skeleton
                                                                variant="text"
                                                                sx={{
                                                                    fontSize: '1rem',
                                                                    width: '100px',
                                                                    backgroundColor: theme[themeStyle].darkGray60,
                                                                    height: '20px',
                                                                }}
                                                            />
                                                            <Skeleton
                                                                variant="text"
                                                                sx={{
                                                                    fontSize: '1rem',
                                                                    width: '100px',
                                                                    backgroundColor: theme[themeStyle].darkGray60,
                                                                    height: '20px',
                                                                }}
                                                            />
                                                        </Stack>
                                                    </Stack>
                                                </Grid>
                                            ))}
                                        </Grid>
                                    </Box>
                                </Stack>
                            </Box>
                        </Box>
                    </Grid>
                );
            }}
        </ThemeContext.Consumer>
    );
};
export { SuggestedProjectsShimmer };
