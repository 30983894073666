import React from 'react';
import { Badge } from '@mui/material';
import verificationBadge from '../../asset/images/icons/verified_badge.svg';
import Avatar from '@mui/material/Avatar';
import { SxProps, Theme, styled } from '@mui/material/styles';
import { ITopUser, IUserSummary } from '../../types/global/helper';
import { replaceRouteParam } from '../../utils/routesProcessor';
import { ROUTES } from '../../types/global/routes.types';
import { useDispatch } from 'react-redux';
import { navigate } from '../../redux/navigator/action';

const SmallAvatar = styled(Avatar)(({ theme }) => ({
    width: 20,
    height: 20,
    zIndex: 2,
}));

interface IAvatar {
    user: IUserSummary | ITopUser;
    sx?: SxProps<Theme>;
    onClick?: (user?: IUserSummary | ITopUser) => void;
    enableDefaultNavigation?: boolean;
    badgeSx?: SxProps<Theme>;
}

function stringToColor(string: string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
}

function stringAvatar(name: string, sx?: SxProps<Theme>) {
    return {
        sx: {
            bgcolor: stringToColor(name),
            fontSize: 12,
            ...sx,
        },
        children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
    };
}

/** Use the below as user Profile in all places, adjusting the size as needed */
const ThspianAvatar: React.FC<IAvatar> = ({ user, sx, onClick, enableDefaultNavigation, badgeSx }) => {
    const upperCaseFirstName = user && user.firstName && user.firstName.toUpperCase();
    const upperCaseLastName = user && user.lastName && user.lastName.toUpperCase();
    const dispatch = useDispatch();

    const handleUserIconClicked = () => {
        if (user && user.userName) {
            const profileRoute = replaceRouteParam(ROUTES.ESMY_PROFILE, 'profileId?', user.userName);
            dispatch(navigate({ routes: profileRoute }));
        }
    };

    const handleOnClick = () => {
        if (enableDefaultNavigation) {
            handleUserIconClicked();
        } else {
            onClick && onClick(user);
        }
    };

    return (
        <Badge
            overlap="circular"
            sx={badgeSx}
            onClick={handleOnClick}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            badgeContent={user && user?.verified && <SmallAvatar alt="verification" src={verificationBadge} />}>
            <Avatar
                alt={`${upperCaseFirstName} ${upperCaseLastName}`}
                src={user && user.profile_picture ? user.profile_picture : ''}
                {...stringAvatar(`${upperCaseFirstName} ${upperCaseLastName}`, sx)}>
                {user && user.firstName && upperCaseFirstName.charAt(0)}{' '}
                {user && user.lastName && upperCaseLastName.charAt(0)}
            </Avatar>
        </Badge>
    );
};

export { ThspianAvatar };
