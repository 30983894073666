import React from 'react';
import Lottie, { Options, LottieProps } from 'react-lottie';

interface IAnimation extends LottieProps {
    height?: number;
    width?: number;
    customOptions?: Options;
    animationData?: any;
    options: any;
    segments?: number[];
    animationDirection?: 'up' | 'down' | 'left' | 'right'; // New prop for animationDirection customization
}

/**
 *
 * @param height `height of the json data`
 * @param width  `width of the json data`
 * @returns
 */
const LottieAnimation = ({
    height,
    width,
    animationData,
    options,
    customOptions,
    segments,
    animationDirection = 'right',
}: IAnimation) => {
    const defaultOptions: Options = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
            hideOnTransparent: true,
            progressiveLoad: true,
        },
        ...options,
        ...customOptions,
    };

    // Map direction prop to rotation angles
    const rotationMap: { [key in 'up' | 'down' | 'left' | 'right']: number } = {
        up: -90,
        down: 90,
        left: 180,
        right: 0,
    };

    return (
        <div style={{ transform: `rotate(${rotationMap[animationDirection]}deg)` }}>
            <Lottie options={defaultOptions} height={width || 50} width={height || 50} segments={segments} />
        </div>
    );
};

LottieAnimation.defaultProps = {
    options: {
        loop: true,
        autoplay: true,
        // animationData: arrowAnimation,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
            hideOnTransparent: true,
            progressiveLoad: true,
        },
    },
};

export { LottieAnimation };
