import RNSecureStorage from 'react-secure-storage';

export const storeJwt = (jwt: string) => RNSecureStorage.setItem('jwt', jwt);

export const storeRefreshToken = (refreshToken: any) => RNSecureStorage.setItem('jwt-refresh-token', refreshToken);

export const retrieveJwt = () => RNSecureStorage.getItem('jwt');

export const retrieveRefreshToken = () => RNSecureStorage.getItem('jwt-refresh-token');

export const removeJwt = () => RNSecureStorage.removeItem('jwt');

export const removeRefreshToken = () => RNSecureStorage.removeItem('jwt-refresh-token');

export const clearStorage = () => RNSecureStorage.clear();
