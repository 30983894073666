import { FlatList, ListRenderItem, StyleSheet, Text, View } from 'react-native';
import React, { useEffect, useState } from 'react';
import { Country, SelectionType } from '../../types/global/generic.types';
import { countries } from '../../utils/countriesList';
import { useThemeContext } from '../../hook/useThemeManager';
import { Fonts } from '../../module/types';
import { GenericSize } from '../../module/types';
import { Button, Pressable, TextInput } from '..';
import { ExpandMore, Check } from '@mui/icons-material';
import Modal from '@mui/material/Modal';
import { ColorScheme, theme } from '../../constants/theme';
import { ScreenTypes } from '../../module/ThemeManager';
import DialogContent from '@mui/material/DialogContent';
import { FONT_ENUM } from '../../types/global/fonts.types';

interface ICountrySelector {
    onCountrySelect?: (country: Country) => void;
    selectionType?: SelectionType;
    selectedCountry: Country;
}

interface ICountryCell {
    country: Country;
    style?: any;
    isSelected?: boolean;
    onPress: (country: Country) => void;
}

const CountryCell = ({ country, style, isSelected, onPress }: ICountryCell) => {
    return (
        <Pressable defaultOpacity={isSelected ? 1 : 0.5} onPress={() => onPress(country)}>
            <View style={style.cell}>
                <View style={{ flex: 0.1 }}>
                    <Text style={style.flagDetail}>{country.flag}</Text>
                </View>
                <View style={{ flex: 0.3 }}>
                    <Text style={style.flagDetail}>{country.name}</Text>
                </View>
                <View style={{ flex: 0.2 }}>
                    <Text style={style.flagDetail}>{country.dial_code}</Text>
                </View>
                <View style={{ flex: 0.2 }}>
                    <Text style={style.flagDetail}>{country.code}</Text>
                </View>
                {isSelected && (
                    <View style={{ position: 'absolute', top: 0, right: 5 }}>
                        <Check />
                    </View>
                )}
            </View>
        </Pressable>
    );
};

const CountrySelector = ({ onCountrySelect, selectionType, selectedCountry }: ICountrySelector) => {
    const [country, setCountry] = useState<Country>(selectedCountry);
    const [allCountries, setAllCountries] = useState<Country[]>([]);
    const [searchTerm, setSearchTerm] = useState('');
    const { sizes, themeStyle, device } = useThemeContext();
    const { font, genericSize } = sizes;
    const styles = dynamicStyles(font, genericSize, themeStyle, device);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const handleModal = () => setIsModalOpen(!isModalOpen);

    const handleSelectCountry = (country: Country) => {
        setCountry(country);
    };

    const renderItem: ListRenderItem<Country> = ({ item, index }) => {
        if (!!country) {
            return (
                <CountryCell
                    country={item}
                    style={styles}
                    isSelected={country.name === item.name}
                    onPress={handleSelectCountry}
                />
            );
        }
        return null;
    };

    useEffect(() => {
        setAllCountries(countries);
    }, []);

    const handleSearch = (text: string) => {
        setSearchTerm(text);
        if (text.length > 3) {
            const filterCountries = countries.filter(c =>
                c.name.toLowerCase().startsWith(searchTerm.toLocaleLowerCase()),
            );
            setAllCountries(filterCountries);
        } else {
            setAllCountries(countries);
        }
    };

    const handleCloseModal = () => {
        onCountrySelect && country && onCountrySelect(country);
        handleModal();
    };

    const renderModal = (
        <Modal
            open={isModalOpen}
            onClose={handleModal}
            style={styles.modal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description">
            <DialogContent style={styles.modal}>
                <View style={styles.countryModal}>
                    <TextInput placeholder="Search country..." onChangeText={handleSearch} value={searchTerm} />
                    <View style={styles.flat}>
                        <FlatList data={allCountries} renderItem={renderItem} keyExtractor={(_, i) => i.toString()} />
                    </View>
                    <Button
                        buttonText="Select"
                        buttonColor="rgba(299,156,40,1)"
                        textStyle={{ color: '#000' }}
                        disableGradiant
                        onPress={handleCloseModal}
                        isDisabled={!country}
                    />
                </View>
            </DialogContent>
        </Modal>
    );

    return (
        <View>
            {renderModal}
            <Pressable defaultOpacity={1} onPress={handleModal}>
                <View style={styles.container}>
                    {!!country && <Text style={styles.flag}>{country.flag}</Text>}
                    <ExpandMore />
                </View>
            </Pressable>
        </View>
    );
};

CountrySelector.defaultProps = {
    selectedCountry: countries[1],
};

export { CountrySelector };

const dynamicStyles = (font: Fonts, genericSize: GenericSize, colorScheme: ColorScheme, device: ScreenTypes) =>
    StyleSheet.create({
        container: {
            flexDirection: 'row',
            marginHorizontal: genericSize._size10,
            alignItems: 'center',
        },
        flag: {
            fontSize: font._fontSize32,
        },
        countryModal: {
            backgroundColor: '#fff',
            alignSelf: 'center',
            borderRadius: genericSize._size10,
            padding: genericSize._size10,
            width: device.isMobile ? '80%' : '50%',
            maxWidth: 800,
        },
        modal: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        cell: {
            flexDirection: 'row',
            alignItems: 'center',
            backgroundColor: theme.light.tertiary_white,
            borderBottomWidth: 1,
            justifyContent: 'space-between',
            marginVertical: genericSize._size5,
        },
        flat: {
            height: genericSize._size300,
        },
        flagDetail: {
            fontSize: font._fontSize18,
            fontFamily: FONT_ENUM.REGULAR_HEADER,
        },
    });
