import React from 'react';
import { StyleSheet } from 'react-native';
import { ThemeContext } from '../../../module/ThemeManager';
import { Container } from '@mui/material';
import { EsMyProfileHeroLoadingState } from '../../EsHero/EsMyProfileHeroLoadingState';
import { EsProfileIntroCardLoadingState } from '../../EsCards/EsProfileIntroCardLoadingState';
import { EsProfileMyProjectsGridLoadingState } from '../../EsCarousels/EsProfileMyVideosGridLoadingState';
import { ProjectActivityCardLoadingState } from '../../EsCards/EsProfileRecentActivitiesLoadingState';

const EsMyProfileContainerLoadingState = () => {
    // Handle Media queries
    const [innerWidth, setInnerWidth] = React.useState<number>(window.innerWidth);

    const getSize = () => {
        setInnerWidth(window.innerWidth);
    };

    React.useEffect(() => {
        window.addEventListener('resize', getSize);

        return () => {
            window.removeEventListener('resize', getSize);
        };
    }, [innerWidth]);

    return (
        <ThemeContext.Consumer>
            {() => {
                const styles = dynamicStyles();

                return (
                    <Container
                        disableGutters
                        style={styles.container}
                        sx={{
                            width: '100%',
                            marginLeft: { xs: '0px', sm: '65px' },
                            paddingLeft: { xs: '10px', sm: '40px' },
                            paddingRight: { xs: '10px', sm: '40px' },
                            paddingTop: { xs: '20px', sm: '40px' },
                            overflowX: 'hidden',
                            minHeight: '70vh',
                        }}
                        maxWidth={false}>
                        <EsMyProfileHeroLoadingState />
                        <Container
                            maxWidth={false}
                            disableGutters
                            sx={{
                                zIndex: 1,
                                display: 'flex',
                                flexDirection: { xs: 'column', lg: 'row' },
                                alignItems: 'flex-start',
                                justifyContent: 'center',
                                gap: '30px',
                                height: '100%',
                                width: '100%',
                                overflowX: 'hidden',
                                marginTop: '30px',
                            }}>
                            <Container
                                maxWidth={false}
                                disableGutters
                                sx={{
                                    zIndex: 1,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'flex-start',
                                    gap: '30px',
                                    height: '100%',
                                    width: '100%',
                                    overflowX: 'hidden',
                                    maxWidth: { xs: '1525px', lg: '525px' },
                                }}>
                                <EsProfileIntroCardLoadingState />
                                <EsProfileMyProjectsGridLoadingState />
                            </Container>
                            <Container
                                maxWidth={false}
                                disableGutters
                                sx={{
                                    zIndex: 1,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'flex-start',
                                    gap: '30px',
                                    height: '100%',
                                    width: '100%',
                                    overflowX: 'hidden',
                                    maxWidth: '1525px',
                                }}>
                                <ProjectActivityCardLoadingState />
                            </Container>
                        </Container>
                    </Container>
                );
            }}
        </ThemeContext.Consumer>
    );
};

const dynamicStyles = () =>
    StyleSheet.create({
        container: {
            height: 'auto',
            justifyContent: 'flex-start',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
        },
    });
export { EsMyProfileContainerLoadingState };
