import { createAction } from 'redux-act';
import { types } from './types';
import { IGetAds, ITrackVrollAds } from './model';
import { IErrorType } from '../../constants/types';
import { IAdvertisement } from '../../types/global/media.types';

export const trackAd = createAction<ITrackVrollAds>(types.TRACK_AD);
export const trackAdSuccess = createAction(types.TRACK_AD_SUCCESS);
export const trackAdFail = createAction<IErrorType>(types.TRACK_AD_FAIL);

export const setVideoViewCount = createAction<number>(types.SET_PLAYED_VIDEO_COUNT);

export const getAd = createAction<IGetAds>(types.GET_AD);
export const getAdSuccess = createAction<IAdvertisement[]>(types.GET_AD_SUCCESS);
export const getAdFail = createAction<IErrorType>(types.GET_AD_FAIL);

export const hideAdBanner = createAction(types.HIDE_AD_BANNER);
