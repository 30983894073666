import produce from 'immer';
import { IVoteInititalState, types } from './types';
import { makeArrayUniqueAndClean } from '../../utils/AppUtils';
import { types as contestTypes } from '../contest/types';

const initialState: IVoteInititalState = {
    gettingVotes: false,
    userVoterId: undefined,
    userVotes: [],
    gettingVoterId: false,
};

const reducer = (state = initialState, action: any) => {
    const { payload, type } = action;
    switch (type) {
        case types.GET_USER_VOTER_ID:
            return produce(state, draft => {
                draft.gettingVoterId = true;
            });
        case types.GET_USER_VOTER_ID_SUCCESS:
            return produce(state, draft => {
                draft.gettingVoterId = false;
                draft.userVoterId = payload;
            });
        case types.GET_USER_VOTER_ID_FAIL:
            return produce(state, draft => {
                draft.gettingVoterId = false;
            });
        case types.GET_USER_VOTES:
            return produce(state, draft => {
                draft.gettingVotes = true;
            });
        case types.GET_USER_VOTES_SUCCESS:
            return produce(state, draft => {
                draft.gettingVotes = false;
                draft.userVotes = makeArrayUniqueAndClean([...draft.userVotes, ...payload]);
            });
        case types.GET_USER_VOTES_FAIL:
            return produce(state, draft => {
                draft.gettingVotes = false;
            });
        case contestTypes.VOTE_FOR_SUBMISSION_SUCCESS:
            return produce(state, draft => {
                draft.userVotes.push(payload);
            });
        default:
            return state;
    }
};

export { reducer };
