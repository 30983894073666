import React from 'react';
import { ThemeContext } from '../../module/ThemeManager';
import { Box, Stack, Skeleton } from '@mui/material';
import { theme } from '../../constants/theme';

const VerticalCardShimmer = () => {
    return (
        <ThemeContext.Consumer>
            {({ themeStyle, device }) => {
                const value = device.isDesktop ? 5 : 3;

                return (
                    <Box sx={{ overflowX: 'scroll' }}>
                        <Stack marginBottom={2} marginTop={{ xs: 4, sm: 0, lg: 4 }}>
                            <Skeleton
                                variant="text"
                                sx={{
                                    fontSize: '2rem',
                                    width: '100px',
                                    backgroundColor: theme[themeStyle].gray_text,
                                }}
                            />
                        </Stack>

                        <Stack
                            direction={{ xs: 'column', sm: 'row', md: 'row', lg: 'row' }}
                            marginBottom={{ xs: 6, sm: 2, lg: 6 }}
                            gap={2}>
                            {Array.from(new Array(value)).map((_, index) => (
                                <Box key={index}>
                                    <Skeleton
                                        variant="rounded"
                                        height={458}
                                        sx={{
                                            borderRadius: 5,
                                            width: {
                                                xs: '100%',
                                                sm: '284px',
                                                md: '284px',
                                                lg: '284px',
                                            },
                                            backgroundColor: theme[themeStyle].gray_text,
                                        }}
                                    />
                                    <Box position="relative">
                                        <Skeleton
                                            variant="circular"
                                            width={50}
                                            height={50}
                                            sx={{
                                                backgroundColor: theme[themeStyle].darkGray60,
                                                position: 'absolute',
                                                bottom: 15,
                                                left: 15,
                                            }}
                                        />
                                        <Skeleton
                                            variant="text"
                                            sx={{
                                                fontSize: '1rem',
                                                width: '150px',
                                                backgroundColor: theme[themeStyle].darkGray60,
                                                position: 'absolute',
                                                bottom: 100,
                                                left: 15,
                                            }}
                                        />
                                        <Skeleton
                                            variant="text"
                                            sx={{
                                                fontSize: '1rem',
                                                width: '120px',
                                                backgroundColor: theme[themeStyle].darkGray60,
                                                position: 'absolute',
                                                bottom: 80,
                                                left: 15,
                                            }}
                                        />
                                    </Box>
                                </Box>
                            ))}
                            <Skeleton />
                        </Stack>
                    </Box>
                );
            }}
        </ThemeContext.Consumer>
    );
};

export { VerticalCardShimmer };
