import React from 'react';
import { StyleSheet, View } from 'react-native';
import { ThemeContext } from '../../../module/ThemeManager';
import { EsBackground } from '../../EsBackground/EsBackground';
import { Container } from '@mui/material';
import { EsLoginHeader } from '../../Header/EsLoginHeader';
import { ColorScheme } from '../../../constants/theme';
import { EsLoginForm } from '../../EsLoginForm/EsLoginForm';
import { TUsernamePassword } from '../../EsLoginForm/EsSigninData';

interface ILogin {
    onSubmit: (data: TUsernamePassword, callBack?: () => void, isLastStep?: boolean) => void;
    onResendOtp: (data: TUsernamePassword) => Promise<unknown>;
    loading?: boolean;
    otpLoading?: boolean;
    resendInProgress?: boolean;
}

const EsLoginContainer: React.FC<ILogin> = ({ onSubmit, onResendOtp, loading, otpLoading, resendInProgress }) => {
    return (
        <ThemeContext.Consumer>
            {({ themeStyle }) => {
                const styles = dynamicStyles(themeStyle);

                return (
                    <View style={styles.container}>
                        <EsBackground />
                        <EsLoginHeader />
                        <Container
                            sx={{
                                zIndex: 1,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                marginTop: '104px',
                            }}>
                            <EsLoginForm
                                onSubmit={(data, cb, isLastStep) => onSubmit(data, cb, isLastStep)}
                                onResendOtp={onResendOtp}
                                loading={loading}
                                otpLoading={otpLoading}
                                resendInProgress={resendInProgress}
                            />
                        </Container>
                    </View>
                );
            }}
        </ThemeContext.Consumer>
    );
};

const dynamicStyles = (colorScheme: ColorScheme) =>
    StyleSheet.create({
        container: {
            width: '100vw',
            height: 'auto',
            minHeight: '100vh',
            justifyContent: 'center',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
        },
    });
export { EsLoginContainer };
