export const types = {
    GET_ALL_USERS: 'GET_ALL_USERS',
    GET_ALL_USERS_SUCCESS: 'GET_ALL_USERS_SUCCESS',
    GET_ALL_USERS_FAIL: 'GET_ALL_USERS_FAIL',

    SEND_MESSAGE: 'SEND_MESSAGE',
    SEND_MESSAGE_SUCCESS: 'SEND_MESSAGE_SUCCESS',
    SEND_MESSAGE_FAIL: 'SEND_MESSAGE_FAIL',

    DELETE_MESSAGE: 'DELETE_MESSAGE',
    DELETE_MESSAGE_SUCCESS: 'DELETE_MESSAGE_SUCCESS',
    DELETE_MESSAGE_FAIL: 'DELETE_MESSAGE_FAIL',

    GET_ALL_MESSAGES: 'GET_ALL_MESSAGES',
    GET_ALL_MESSAGES_SUCCESS: 'GET_ALL_MESSAGES_SUCCESS',
    GET_ALL_MESSAGES_FAIL: 'GET_ALL_MESSAGES_FAIL',

    CONNECT: 'CONNECT',
    CONNECT_SUCCESS: 'CONNECT_SUCCESS',
    CONNECT_FAIL: 'CONNECT_FAIL',

    DISCONNECT: 'DISCONNECT',
    DISCONNECT_SUCCESS: 'DISCONNECT_SUCCESS',
    DISCONNECT_FAIL: 'DISCONNECT_FAIL',

    RETRIEVE_MESSAGES: 'RETRIEVE_MESSAGES',
    RETRIEVE_ROOMS: 'RETRIEVE_ROOMS',

    GET_MY_ROOMS: 'GET_MY_ROOMS',
    GET_MY_ROOMS_SUCCESS: 'GET_MY_ROOMS_SUCCESS',
    GET_MY_ROOMS_FAIL: 'GET_MY_ROOMS_FAIL',

    USER_TYPING: 'USER_TYPING',
    USER_STOP_TYPING: 'USER_STOP_TYPING',

    TYPING_STATUS: 'TYPING_STATUS',

    USER_HAS_READ: 'USER_HAS_READ',

    JOIN_ALL_CHAT_ROOMS: 'JOIN_ALL_CHAT_ROOMS',

    SET_ONLINE_STATUS: 'SET_ONLINE_STATUS',

    SET_ONLINE_USERS: 'SET_ONLINE_USERS',

    SET_OFFLINE_USER: 'SET_OFFLINE_USER',

    NEW_CONTENT: 'NEW_CONTENT',
    NEW_CONTENT_AVAILABLE: 'NEW_CONTENT_AVAILABLE',
    SET_NEW_CONTENT: 'SET_NEW_CONTENT',
    RESET_NEW_CONTENT: 'RESET_NEW_CONTENT',

    INITIATE_MESSAGE: 'INITIATE_MESSAGE',
    INITIATE_MESSAGE_SUCCESS: 'INITIATE_MESSAGE_SUCCESS',
    INITIATE_MESSAGE_FAIL: 'INITIATE_MESSAGE_FAIL',

    SET_ROOM_MESSAGES: 'SET_ROOM_MESSAGES',
    SET_ROOM_MESSAGES_SUCCESS: 'SET_ROOM_MESSAGES_SUCCESS',
    SET_ROOM_MESSAGES_FAIL: 'SET_ROOM_MESSAGES_FAIL',
};

export interface ILiveInitialState {
    connected: boolean;
    socket: any;
}
