export const types = {
    NAVIGATE: 'NAVIGATE',
};

export interface INavigate {
    routes: any;
    isRefreshed?: boolean;
    params?: any;
    skipAuthentication?: boolean;
    /** The current path to redirect to incase not logged in */
    from?: string;
}
