import { IMetaData, TResolutions } from '../types/global/helper';
import { bytesToMB } from './AppUtils';

export const base64Converter = (base64String: string) => {
    const binaryString = atob(base64String.split(',')[1]); // Binary data string
    const blob = new Blob([binaryString], { type: 'image/jpeg' }); // Create a BLOB object
    return blob;
};

export const dataURIToBlob = (dataURI: string) => {
    const splitDataURI = dataURI.split(',');
    const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1]);
    const mimeString = splitDataURI[0].split(':')[1].split(';')[0];

    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) ia[i] = byteString.charCodeAt(i);

    return new Blob([ia], { type: mimeString });
};

export const formatDuration = (durationInSeconds?: number): string => {
    if (durationInSeconds) {
        const hours = Math.floor(durationInSeconds / 3600);
        const minutes = Math.floor((durationInSeconds % 3600) / 60);
        const seconds = Math.floor(durationInSeconds % 60);

        const formattedHours = hours.toString().padStart(2, '0');
        const formattedMinutes = minutes.toString().padStart(2, '0');
        const formattedSeconds = seconds.toString().padStart(2, '0');

        if (hours > 0) {
            return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
        } else {
            return `${formattedMinutes}:${formattedSeconds}`;
        }
    }
    return '-';
};

enum VideoOrientation {
    Horizontal = 'horizontal',
    Vertical = 'vertical',
    Square = 'square',
}

/** Detect video aspect ratio */
export function determineVideoOrientation(dimensions: IMetaData): VideoOrientation {
    if (!dimensions) {
        return VideoOrientation.Vertical;
    }
    const { width, height } = dimensions;

    if (width === height) {
        return VideoOrientation.Square;
    } else if (width > height) {
        return VideoOrientation.Horizontal;
    } else {
        return VideoOrientation.Vertical;
    }
}

export function getResolutionBasedOnNetwork(resolutions: TResolutions): string {
    const connection =
        (navigator as any).connection || (navigator as any).mozConnection || (navigator as any).webkitConnection;

    if (connection && connection.downlink) {
        const downlink = connection.downlink; // downlink speed in Mbps

        if (downlink >= 5) {
            // High speed internet
            return resolutions['1080p'];
        } else if (downlink >= 2.5) {
            // Medium speed internet
            return resolutions['720p'];
        } else if (downlink >= 1) {
            // Lower speed internet
            return resolutions['480p'];
        } else {
            // Very low speed internet
            return resolutions['360p'];
        }
    }

    // Default resolution if connection API is unavailable
    return resolutions['720p'];
}

export function base64ToFileWithDetails(base64String: string): { file: File; fileName: string; fileType: string } {
    // Extract mime-type from the base64 string
    const mimeTypeMatch = base64String.match(/data:(.*?);base64,/);
    if (!mimeTypeMatch) {
        throw new Error('Invalid base64 string');
    }

    const fileType = mimeTypeMatch[1]; // Get the mime-type
    const base64Data = base64String.split(',')[1]; // Get the base64 data

    // Decode the base64 data
    const byteString = atob(base64Data);
    const arrayBuffer = new Uint8Array(byteString.length);

    for (let i = 0; i < byteString.length; i++) {
        arrayBuffer[i] = byteString.charCodeAt(i);
    }

    // Create a Blob from the array buffer and specify the type
    const blob = new Blob([arrayBuffer], { type: fileType });

    // Generate a file extension from the mime-type
    const extension = fileType.split('/')[1];

    // Create a filename using the extension
    const fileName = `file.${extension}`;

    // Return a File object from the Blob
    const file = new File([blob], fileName, { type: fileType });

    return { file, fileName, fileType };
}

// Example usage:

/**
 * Utility function to check if a file (image or video) is valid by ensuring it is playable or viewable.
 *
 * @param {File} file - The file to validate (either image or video).
 * @returns {Promise<{isValid: boolean, message: string}>} - Returns a Promise with `isValid` and `message`.
 */
export const checkFileValidity = (file: File): Promise<{ isValid: boolean; message: string }> => {
    return new Promise(resolve => {
        const fileType = file.type;

        // Check if it's an image
        if (fileType.startsWith('image/')) {
            const img = new Image();
            img.onload = () => resolve({ isValid: true, message: 'Valid image file' });
            img.onerror = () => resolve({ isValid: false, message: 'Invalid image file' });
            img.src = URL.createObjectURL(file);
            return;
        }

        // Check if it's a video
        if (fileType.startsWith('video/')) {
            const video = document.createElement('video');
            video.oncanplay = () => resolve({ isValid: true, message: 'Valid video file' });
            video.onerror = () => resolve({ isValid: false, message: 'Invalid video file' });
            video.src = URL.createObjectURL(file);
            return;
        }

        // If it's neither an image nor a video
        resolve({ isValid: false, message: 'Unsupported file type' });
    });
};

export const validateFile = async (file: File, imageFileLimit: number, deviceUploadLimit: number) => {
    const maxSizeForImage = imageFileLimit; // 5 MB
    const maxSizeForVideo = deviceUploadLimit; // 50 MB

    /** Check if the file is actually valid */
    const validViability = await checkFileValidity(file);
    if (!validViability.isValid) {
        return { valid: validViability.isValid, error: validViability.message };
    }

    if (file.type.startsWith('image/')) {
        if (file.size > maxSizeForImage) {
            return { valid: false, error: `Image size should not exceed ${bytesToMB(imageFileLimit!)} MB. File:` };
        }
    } else if (file.type.startsWith('video/')) {
        if (file.size > maxSizeForVideo) {
            return {
                valid: false,
                error: `Video size should not exceed ${bytesToMB(deviceUploadLimit!)} MB. File`,
            };
        }
    } else {
        return { valid: false, error: `Unsupported file type: ${file.type}` };
    }

    return { valid: true, error: null };
};
