import * as Sentry from '@sentry/react';

export const SentryCapture = (error: any, captureContext: Sentry.SeverityLevel = 'debug') => {
    Sentry.withScope(scope => {
        if (process.env.NODE_ENV === 'production') {
            scope.setLevel(captureContext);
            scope.setContext(captureContext, error);
            const errMsg =
                error && error.data && error.data.err && error.data.err.message ? error.data.err.message : error;
            Sentry.captureException(JSON.stringify(errMsg));
        } else {
            console.log(error);
        }
    });
};
