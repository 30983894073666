import mixpanel from 'mixpanel-browser';
import { IUserProfile } from '../redux/user/actions';

let env_check = process.env.NODE_ENV === 'development';

export type TMixPanelTrackEvent =
    | 'VIDEO_WATCH'
    | 'BUTTON_CLICK'
    | 'PAGE_NAVIGATION'
    | 'VIDEO_PAUSE'
    | 'CONTEST_CLICK'
    | 'FOLLOWED_A_THSPIAN'
    | 'UNFOLLOWED_A_THSPIAN'
    | 'ADD_BOOKMARK'
    | 'DELETE_BOOKMARK'
    | 'LOGOUT'
    | 'LOGIN'
    | 'SETTINGS_CLICK'
    | 'ACTIVE_SUBMISSIONS_CLICK'
    | 'PROCEED_WITH_RESCIND_SUBMISSION'
    | 'RESCIND_SUBMISSION_CLICK'
    | 'USER_PROJECT_CLICK'
    | 'USER_PROJECT_CLOSE'
    | 'USER_PROJECT_DELETE'
    | 'MAIN_CATEGORY_CLICK'
    | 'SUB_CATEGORY_CLICK'
    | 'APPLY_FILTER_CLICK'
    | 'CLEAR_FILTER_CLICK'
    | 'LIKE_COMMENT'
    | 'DISLIKE_COMMENT'
    | 'NOTIFICATION_OPEN'
    | ' NOTIFICATION_CLOSE'
    | 'ACCOUNT_MODAL_OPEN'
    | 'ACCOUNT_MODAL_CLOSE'
    | 'PROJECT_CREATOR_CLICK'
    | 'CONTEST_DETAILS_CLICK'
    | 'SHARE_CONTEST'
    | 'CLOSE_SHARE_CONTEST_MODAL'
    | 'OPEN_SCORE_BOARD'
    | 'ALL_RESULTS_CLICK'
    | 'SORT_BY_VOTES_CLICK';

export enum MIXPANEL_EVENT {
    VIDEO_WATCH = 'VIDEO_WATCH',
    VIDEO_PAUSE = 'VIDEO_PAUSE',
    BUTTON_CLICK = 'BUTTON_CLICK',
    PAGE_NAVIGATION = 'PAGE_NAVIGATION',
    CONTEST_CLICK = 'CONTEST_CLICK',
    ADD_BOOKMARK = 'ADD_BOOKMARK',
    DELETE_BOOKMARK = 'DELETE_BOOKMARK',
    LOGOUT = 'LOGOUT',
    LOGIN = 'LOGIN',
    FOLLOWED_A_THSPIAN = 'FOLLOWED_A_THSPIAN',
    UNFOLLOWED_A_THSPIAN = 'UNFOLLOWED_A_THSPIAN',
    SETTINGS_CLICK = 'SETTINGS_CLICK',
    ACTIVE_SUBMISSIONS_CLICK = 'ACTIVE_SUBMISSIONS_CLICK',
    PROCEED_WITH_RESCIND_SUBMISSION = 'PROCEED_WITH_RESCIND_SUBMISSION',
    RESCIND_SUBMISSION_CLICK = 'RESCIND_SUBMISSION_CLICK',
    USER_PROJECT_CLICK = 'USER_PROJECT_CLICK',
    USER_PROJECT_CLOSE = 'USER_PROJECT_CLOSE',
    USER_PROJECT_DELETE = 'USER_PROJECT_DELETE',
    MAIN_CATEGORY_CLICK = 'MAIN_CATEGORY_CLICK',
    SUB_CATEGORY_CLICK = 'SUB_CATEGORY_CLICK',
    APPLY_FILTER_CLICK = 'APPLY_FILTER_CLICK',
    CLEAR_FILTER_CLICK = 'CLEAR_FILTER_CLICK',
    LIKE_COMMENT = 'LIKE_COMMENT',
    DISLIKE_COMMENT = 'DISLIKE_COMMENT',
    NOTIFICATION_OPEN = 'NOTIFICATION_OPEN',
    NOTIFICATION_CLOSE = ' NOTIFICATION_CLOSE',
    ACCOUNT_MODAL_OPEN = 'ACCOUNT_MODAL_OPEN',
    ACCOUNT_MODAL_CLOSE = 'ACCOUNT_MODAL_CLOSE',
    PROJECT_CREATOR_CLICK = 'PROJECT_CREATOR_CLICK',
    CONTEST_DETAILS_CLICK = 'CONTEST_DETAILS_CLICK',
    SHARE_CONTEST = 'SHARE_CONTEST',
    CLOSE_SHARE_CONTEST_MODAL = 'CLOSE_SHARE_CONTEST_MODAL',
    OPEN_SCORE_BOARD = 'OPEN_SCORE_BOARD',
}

/**
 * Contest Name
 * Contest Id
 */

export type TMixPanelProps<T extends TMixPanelTrackEvent> = T extends MIXPANEL_EVENT.BUTTON_CLICK
    ? { buttonName: string; buttonAction: string } // Required props for BUTTON Click event
    : Record<string, any>; // Default props for other events

let MixPanel = {
    identify: (id: string) => {
        if (env_check) mixpanel.identify(id);
    },
    /** Take a user object and passes user details for identification */
    loggedInIdentify: (user: IUserProfile) => {
        mixpanel.identify(user._id);
        mixpanel.people.set({
            firstName: user.firstName,
            lastName: user.lastName,
            userName: user.userName,
            userType: user.user_type,
            location: user.location,
        });
    },
    alias: (id: string) => {
        if (env_check) mixpanel.alias(id);
    },
    track: (name: TMixPanelTrackEvent, props: TMixPanelProps<'BUTTON_CLICK'>) => {
        if (env_check) mixpanel.track(name, props);
    },
    people: {
        /** Adds an additonal property to the person e.g => {first_login: Date} */
        set: (props: TMixPanelProps<'BUTTON_CLICK'>) => {
            if (env_check) mixpanel.people.set(props);
        },
        setOnce: (props: TMixPanelProps<'BUTTON_CLICK'>) => {
            if (env_check) mixpanel.people.set_once(props);
        },
        /** Ideally you sent this flag with ('tag', 'tag value') */
        append: (...args: any) => {
            if (env_check) mixpanel.people.append(args);
        },
    },
    reset: () => {
        if (env_check) mixpanel.reset();
    },
};

export { MixPanel };
