import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
    deleteTalentFail,
    deleteTalentSuccess,
    getSavedTalentsFail,
    getSavedTalentsSuccess,
    saveTalentFail,
    saveTalentSuccess,
    searchTalentFail,
    searchTalentSuccess,
    updateTalentFail,
    updateTalentSuccess,
} from './actions';
import { searchUserTalentUrl, talentUrl } from '../../configurations/api/url';
import { api } from '../../configurations/api';
import { destroyOneCache, setCache } from '../cache/action';
import { isUseCacheEnabled, getCacheDuration, getCacheByType } from '../cache/saga';
import { CacheValue, CACHE_TYPE } from '../cache/types';
import { globalErrorHandler } from '../error/saga';
import { httpRequest } from '../types';
import { ISaveTalent, ITalentData, types } from './types';
import { SentryCapture } from '../../analytics/Sentry';
import { showToastMessage } from '../../utils/AppUtils';
import { IGenericDocumentModifier } from '../../types/global/helper';
import { ISearchPayload } from '../contest/types';
import { Action } from 'redux';
import { setInAppNotification } from '../in-app-notification/actions';

function* getTalentSaga(): any {
    const defaultUseCache = yield* isUseCacheEnabled();
    const defaultCacheDuration = yield* getCacheDuration();
    let initialResult: any = null;
    const cache: CacheValue = yield* getCacheByType(CACHE_TYPE.TALENT);
    if (cache && cache.key) {
        initialResult = cache.value;
    }

    if (
        !!initialResult &&
        defaultUseCache &&
        ((Date.now() - Number(cache.key)) as unknown as number) < defaultCacheDuration
    ) {
        yield put(getSavedTalentsSuccess(initialResult));
    } else {
        try {
            const response = yield call(api, talentUrl, httpRequest.GET, null, 0, 0);
            const { data } = response.data;
            yield put(getSavedTalentsSuccess(data));
            yield put(
                setCache({
                    key: Date.now(),
                    value: data,
                    type: CACHE_TYPE.TALENT,
                    isUnique: true,
                }),
            );
        } catch (error: any) {
            SentryCapture(error, 'error');
            yield put(getSavedTalentsFail(error));
            yield call(globalErrorHandler, error);
        }
    }
}
function* saveTalentSaga({ payload }: { payload: ISaveTalent }): any {
    try {
        const response = yield call(api, talentUrl, httpRequest.POST, payload, 1, 2000);

        const { data } = response.data;
        yield put(saveTalentSuccess(data));
        yield put(
            destroyOneCache({
                cacheType: CACHE_TYPE.TALENT,
            }),
        );
        yield put(
            setInAppNotification({
                message: 'Talent Saved Successfully',
                title: 'Talent Saved Successfully',
            }),
        );
    } catch (error: any) {
        SentryCapture(error, 'error');
        yield put(saveTalentFail(error));
        yield call(globalErrorHandler, error);
    }
}

function* updateTalentSaga({ payload }: { payload: IGenericDocumentModifier }): any {
    try {
        const response = yield call(
            api,
            `${talentUrl}/${payload.documentId}`,
            httpRequest.PATCH,
            payload.payload,
            1,
            2000,
        );
        const { data } = response.data;
        yield put(updateTalentSuccess(data));
        yield put(
            destroyOneCache({
                cacheType: CACHE_TYPE.TALENT,
            }),
        );
        yield put(
            setInAppNotification({
                message: 'Talent Updated Successfully',
                title: 'Talent Updated Successfully',
            }),
        );
    } catch (error: any) {
        SentryCapture(error, 'error');
        yield put(updateTalentFail(error));
        yield call(showToastMessage, 'Update Error!', 'error');
    }
}

function* searchTalentSaga({ payload }: { payload: ISearchPayload }): any {
    try {
        const response = yield call(api, searchUserTalentUrl, httpRequest.POST, payload, 2);
        const { data }: { data: ITalentData[] } = response;
        yield put(searchTalentSuccess(data));
    } catch (error: any) {
        SentryCapture(error, 'error');
        yield put(searchTalentFail(error));
    }
}

function* deleteTalentSaga({ payload }: { payload: IGenericDocumentModifier }): any {
    try {
        yield call(api, `${talentUrl}/${payload.documentId}`, httpRequest.DELETE, {}, 0, undefined, false);
        yield put(deleteTalentSuccess(payload));
        yield put(
            setInAppNotification({
                message: 'Talent Removed Successfully',
                title: 'Talent Removed Successfully',
            }),
        );
    } catch (error: any) {
        SentryCapture(error, 'error');
        yield put(deleteTalentFail(error));
    }
}

interface TaskAction extends Action {
    payload: ISaveTalent;
}
interface UpdateTalentTaskAction extends Action {
    payload: IGenericDocumentModifier;
}
interface SearchTalentTaskAction extends Action {
    payload: ISearchPayload;
}
interface DeleteTalentTaskAction extends Action {
    payload: IGenericDocumentModifier;
}

function* getTalentWatcher() {
    yield takeLatest(types.GET_SAVED_TALENTS, getTalentSaga);
}

function* saveTalentWatcher() {
    yield takeLatest<TaskAction>(types.SAVE_TALENT, saveTalentSaga);
}

function* updateTalentWatcher() {
    yield takeLatest<UpdateTalentTaskAction>(types.UPDATE_TALENT, updateTalentSaga);
}

function* searchTalentWatcher() {
    yield takeLatest<SearchTalentTaskAction>(types.SEARCH_TALENT, searchTalentSaga);
}

function* deleteTalentWatcher() {
    yield takeLatest<DeleteTalentTaskAction>(types.DELETE_TALENT, deleteTalentSaga);
}

export default function* talentSaga() {
    yield all([
        getTalentWatcher(),
        saveTalentWatcher(),
        updateTalentWatcher(),
        searchTalentWatcher(),
        deleteTalentWatcher(),
    ]);
}
