import React from 'react';
import { StyleSheet } from 'react-native';
import { Container } from '@mui/system';
import { FONT_ENUM } from '../../types/global/fonts.types';
import { FooterContainer } from '../../components/ScreenContainer/FooterScreenContainer/FooterContainer';
import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer';
import { Spinner } from '@chakra-ui/react';

const styles = StyleSheet.create({
    container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
    },
    header: {
        fontFamily: FONT_ENUM.BOLD_HEADER,
        fontSize: 20,
        color: 'red',
    },
    paragraphs: {
        fontFamily: FONT_ENUM.MEDIUM_TEXT,
    },
});

const TermsOfUse = () => {
    return (
        <Container disableGutters style={styles.container} maxWidth={false}>
            <FooterContainer noScroll>
                <DocViewer
                    prefetchMethod="GET"
                    config={{
                        header: {
                            disableHeader: true,
                            disableFileName: true,
                            retainURLParams: false,
                        },
                        csvDelimiter: ',', // "," as default,
                        pdfZoom: {
                            defaultZoom: 1.1, // 1 as default,
                            zoomJump: 0.2, // 0.1 as default,
                        },
                        pdfVerticalScrollByDefault: true, // false as default
                        loadingRenderer: {
                            overrideComponent: () => <Spinner h={20} w={20} />,
                        },
                    }}
                    documents={[
                        {
                            uri: 'https://thspian-seo-media.s3.us-east-2.amazonaws.com/legal/terms_of_use_2.24.24.docx',
                            fileType: 'docx',
                        },
                    ]}
                    pluginRenderers={DocViewerRenderers}
                    theme={{
                        primary: '#5296d8',
                        secondary: '#000',
                        tertiary: '#5296d899',
                        textPrimary: '#000',
                        textSecondary: '#5296d8',
                        textTertiary: '#00000099',
                        disableThemeScrollbar: true,
                    }}
                    style={{
                        height: '80vh',
                        backgroundColor: 'black',
                    }}
                />
            </FooterContainer>
        </Container>
    );
};

export { TermsOfUse };
