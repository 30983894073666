import React from 'react';
import { ThemeContext } from '../../module/ThemeManager';
import { Container } from '@mui/material';
import { HeroDetailsShimmer } from '../HeroDetailsShimmer';
import { VerticalCardShimmer } from '../VerticalCardShimmer';
import { SquareCardShimmer } from '../SquareCardShimmer';
import { RequirementsShimmer } from '../RequirementsShimmer';

const ViewContestShimmer = () => {
    return (
        <ThemeContext.Consumer>
            {() => {
                return (
                    <Container
                        disableGutters
                        sx={{
                            width: '100%',
                            minHeight: '100vh',
                            marginTop: { xs: '30px', sm: '40px' },
                            marginLeft: { xs: '0px', sm: '65px' },
                            paddingLeft: { xs: '15px', sm: '40px' },
                            paddingRight: { xs: '15px', sm: '40px' },
                            overflowX: 'hidden',
                        }}
                        maxWidth={false}>
                        <HeroDetailsShimmer />
                        <RequirementsShimmer />
                        <VerticalCardShimmer />
                        <SquareCardShimmer />
                    </Container>
                );
            }}
        </ThemeContext.Consumer>
    );
};

export { ViewContestShimmer };
