import React from 'react';
import { StyleSheet, View } from 'react-native';
import { ThemeContext } from '../../../module/ThemeManager';
import { EsBackground } from '../../EsBackground/EsBackground';
import { Container } from '@mui/material';
import { EsSignupHeader } from '../../Header/EsSignupHeader';

import { ColorScheme } from '../../../constants/theme';
import { EsSignupForm, SignUpdata } from '../../EsSignupForm/EsSignupForm';
import { TUserField } from '../../../redux/user/actions';
import { ROUTES } from '../../../types/global/routes.types';

export interface ISignUpProps {
    onSubmitSignUpOTP: (data: SignUpdata, code?: string[]) => void;
    checkUserDataAvailability: (data: SignUpdata, callBack: () => void, field: TUserField) => void;
    userNameAvailable: (userName: string) => void;
    getSignUpOTP: (data: SignUpdata, callBack: () => void, resend?: boolean) => void | Promise<unknown>;
    loading?: boolean;
    otpLoading?: boolean;
    resendInProgress?: boolean;
}

const EsSignupContainer: React.FC<ISignUpProps> = ({
    onSubmitSignUpOTP,
    checkUserDataAvailability,
    userNameAvailable,
    getSignUpOTP,
    loading,
    otpLoading,
    resendInProgress,
}) => {
    return (
        <ThemeContext.Consumer>
            {({ themeStyle }) => {
                const styles = dynamicStyles(themeStyle);

                return (
                    <View style={styles.container}>
                        <EsBackground />
                        <EsSignupHeader route={ROUTES.ESLOGIN} />
                        <Container
                            sx={{
                                zIndex: 1,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                marginTop: '104px',
                            }}>
                            <EsSignupForm
                                getSignUpOTP={getSignUpOTP}
                                userNameAvailable={userNameAvailable}
                                onSubmitSignUpOTP={onSubmitSignUpOTP}
                                checkUserDataAvailability={checkUserDataAvailability}
                                loading={loading}
                                otpLoading={otpLoading}
                                resendInProgress={resendInProgress}
                            />
                        </Container>
                    </View>
                );
            }}
        </ThemeContext.Consumer>
    );
};

const dynamicStyles = (colorScheme: ColorScheme) =>
    StyleSheet.create({
        container: {
            width: '100vw',
            height: 'auto',
            minHeight: '100vh',
            justifyContent: 'center',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
        },
    });
export { EsSignupContainer };
