import { IUserSummary } from '../../types/global/helper';

export const types = {
    GET_MY_REQUESTS: 'GET_MY_REQUESTS',
    GET_MY_REQUESTS_SUCCESS: 'GET_MY_REQUESTS_SUCCESS',
    GET_MY_REQUESTS_FAIL: 'GET_MY_REQUESTS_FAIL',

    CREATE_REQUEST: 'CREATE_REQUEST',
    CREATE_REQUEST_SUCCESS: 'CREATE_REQUEST_SUCCESS',
    CREATE_REQUEST_FAIL: 'CREATE_REQUEST_FAIL',

    RESOLVE_REQUEST: 'RESOLVE_REQUEST',
    RESOLVE_REQUEST_SUCCESS: 'RESOLVE_REQUEST_SUCCESS',
    RESOLVE_REQUEST_FAIL: 'RESOLVE_REQUEST_FAIL',

    GET_REQUESTS: 'GET_REQUESTS',
    GET_REQUESTS_SUCCESS: 'GET_REQUESTS_SUCCESS',
    GET_REQUESTS_FAIL: 'GET_REQUESTS_FAIL',
};

export type TRequestType = 'account_verification' | 'contest_code' | 'account_deletion' | 'others';
export type TRequestStatus = 'pending' | 'approved' | 'denied';

export type TRequestMetadata = {
    contestId: string;
};

export interface ICreateContestRequest {
    request_type: TRequestType;
    metadata: TRequestMetadata;
}

export interface IResolveRequests {
    request_status: TRequestStatus;
    reason: string;
    requestId: string;
}

export interface IContestRequest {
    request_type: TRequestType;
    request_status: TRequestStatus;
    resolved: boolean;
    _id: string;
    metadata: TRequestMetadata;
    created_by: string;
    user: IUserSummary;
    createdAt: string;
    updatedAt: string;
    reason: string;
    resolved_by: string;
    id: string;
}

export interface IInitialRequestState {
    requests: IContestRequest[];
    gettingRequests?: boolean;
    resolvingRequests?: boolean;
    userRequests: IContestRequest[];
    creatingRequest?: boolean;
    requestErrMsg?: any;
}

export interface IGetRequests {
    contestId?: string;
    page?: number;
    limit?: number;
}
