import { StyleProp, StyleSheet, Text, TextStyle, View, ViewStyle } from 'react-native';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import React from 'react';
import { useThemeContext } from '../../hook/useThemeManager';
import { ColorScheme, theme } from '../../constants/theme';
import { FONT_ENUM } from '../../types/global/fonts.types';

interface ICustomText {
    defaultValue?: string;
    inputProps?: TextFieldProps;
    isReadOnlyMode?: boolean;
    textStyle?: StyleProp<TextStyle>;
    disableLabel?: boolean;
    containerStyle?: StyleProp<ViewStyle>;
    customInput?: JSX.Element;
    label?: string;
}

const CustomTextField: React.FC<ICustomText> = ({
    defaultValue,
    inputProps,
    isReadOnlyMode,
    textStyle,
    disableLabel,
    containerStyle,
    customInput,
    label,
}) => {
    const { themeStyle } = useThemeContext();
    const styles = dynamicStyle(themeStyle, isReadOnlyMode);
    const render = !!customInput ? (
        <View>
            {!disableLabel && <Text style={[styles.label]}>{label}</Text>}
            {customInput ?? <Text style={[styles.text, textStyle]}>{defaultValue}</Text>}
        </View>
    ) : (
        <TextField defaultValue={defaultValue} {...inputProps} label={label} />
    );

    return (
        <View style={[styles.container, containerStyle]}>
            {!isReadOnlyMode ? (
                render
            ) : (
                <View>
                    {!disableLabel && <Text style={[styles.label]}>{label}</Text>}
                    {customInput ?? <Text style={[styles.text, textStyle]}>{defaultValue}</Text>}
                </View>
            )}
        </View>
    );
};

CustomTextField.defaultProps = {
    inputProps: {
        variant: 'standard',
        color: 'secondary',
        label: 'Last Name',
        inputProps: {
            style: {
                color: '#fff',
                fontFamily: FONT_ENUM.REGULAR_HEADER,
                fontWeight: '300',
            },
        },
        InputLabelProps: {
            style: {
                color: theme.dark.darkGray,
            },
        },
        sx: {
            input: {
                color: '#fff',
                borderBottom: '1px solid #fff',
            },
        },
    },
    disableLabel: true,
};

export { CustomTextField as TextField };

const dynamicStyle = (colorScheme: ColorScheme, isReadOnlyMode?: boolean) =>
    StyleSheet.create({
        container: {
            marginHorizontal: isReadOnlyMode ? 4 : 10,
        },
        text: {
            fontFamily: FONT_ENUM.REGULAR_HEADER,
            fontSize: 16,
            color: theme[colorScheme].lightWhite,
            flex: 1,
        },
        label: {
            fontFamily: FONT_ENUM.MEDIUM_TEXT,
            fontSize: 12,
            color: theme[colorScheme].darkGray,
            flex: 1,
        },
    });
