import React from 'react';

export default function ArrowRightIcon({ fill = '#FBBC55', stroke = '#FFF', ...rest }: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M10.125 3.875L15.75 9.5M15.75 9.5L10.125 15.125M15.75 9.5H2.25"
                stroke={fill}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
