import { useContext } from 'react';
import { ThemeContext } from '../module/ThemeManager';

export const useThemeContext = () => {
    const {
        darkModeEnabled,
        theme,
        themeStyle,
        toggleTheme,
        osTheme,
        flexStyle,
        rowStyle,
        device,
        sizes,
        width,
        height,
    } = useContext(ThemeContext);
    return {
        darkModeEnabled,
        theme,
        themeStyle,
        toggleTheme,
        osTheme,
        flexStyle,
        rowStyle,
        device,
        sizes,
        width,
        height,
    };
};
