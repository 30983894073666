import React from 'react';
import { StyleSheet, View } from 'react-native';
import { EsBackground } from '../../components/EsBackground/EsBackground';
import { Container } from '@mui/material';

import { ThemeContext } from '../../module/ThemeManager';
import { ColorScheme } from '../../constants/theme';
import { EsSignupHeader } from '../../components/Header/EsSignupHeader';
import { PageNotFound } from '../../components/PageNotFound/PageNotFound';
import { ROUTES } from '../../types/global/routes.types';
import { useNavigate } from 'react-router-dom';

export interface ISignUpProps {}

const ModernPageNotFound: React.FC<ISignUpProps> = () => {
    const navigate = useNavigate();
    return (
        <ThemeContext.Consumer>
            {({ themeStyle }) => {
                const styles = dynamicStyles(themeStyle);

                return (
                    <View style={styles.container}>
                        <EsBackground />
                        <EsSignupHeader label="Back to Discovery" route={ROUTES.ESDISCOVERY} />
                        <Container
                            sx={{
                                zIndex: 1,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}>
                            <PageNotFound onClick={() => navigate(ROUTES.ESDISCOVERY)} />
                        </Container>
                    </View>
                );
            }}
        </ThemeContext.Consumer>
    );
};

const dynamicStyles = (colorScheme: ColorScheme) =>
    StyleSheet.create({
        container: {
            width: '100vw',
            height: 'auto',
            minHeight: '100vh',
            justifyContent: 'center',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
        },
    });
export { ModernPageNotFound };
