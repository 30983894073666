import { createContext } from 'react';
import { getJWtDetails } from '../utils/AppUtils';

export type LoggedInState = {
    tokenExists: boolean;
    user_id: string;
    isSignedIn: boolean;
    token?: string;
    setLoginStatus?: (token: string, isSignedIn: boolean, tokenExists: boolean, user_id: string) => void;
};

export const authenticationModule = (): LoggedInState => {
    const { isSignedIn, token, tokenExists, user_id } = getJWtDetails();
    return { tokenExists: tokenExists, user_id, isSignedIn, token };
};

export const AuthContext = createContext({
    tokenExists: false,
    user_id: '',
    isSignedIn: false,
    token: '',
    setLoginStatus: () => {},
} as LoggedInState);

export const AuthProvider = ({ children }: any) => {
    const auth = authenticationModule();
    return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};
