import RNSecureStorage from 'react-secure-storage';
import { ICreateProject } from './types';

export const setProjectPayload = (payload: ICreateProject) =>
    RNSecureStorage.setItem('projectPayload', JSON.stringify(payload));
export const retrieveProjectPayload = () => RNSecureStorage.getItem('projectPayload');
export const removeProjectPayload = () => RNSecureStorage.removeItem('projectPayload');

export const setProjectGuide = () => RNSecureStorage.setItem('projectGuide', true);
export const getProjectGuide = () => RNSecureStorage.getItem('projectGuide');
