import { createGlobalStyle } from 'styled-components';

import LatoRegular from './Lato-Regular.woff';
import MonteserratBold from './Montserrat-Bold.woff';
import MonteserratRegular from './Montserrat-Regular.woff';
import PoppinsBold from './Poppins-Bold.woff';
import PoppinsRegular from './Poppins-Regular.woff';
import RalewayBold from './Raleway-Bold.woff';
import RalewayRegular from './Raleway-Regular.woff';
import InterRegular from './Inter-Regular.woff';
import InterMedium from './Inter-Medium.woff';
import InterBold from './Inter-Bold.woff';
import '@fontsource/public-sans';

export default createGlobalStyle`
    @font-face {
        font-family: 'LatoRegular';
        src: local('LatoRegular'), local('LatoRegular'),
        url(${LatoRegular}) format('truetype');
        font-weight: 300;
        font-style: normal;
        font-display: swap;
    }
    @font-face {
        font-family: 'MonteserratBold';
        src: local('MonteserratBold'), local('MonteserratBold'),
        url(${MonteserratBold}) format('truetype');
        font-weight: 700;
        font-style: normal;
        font-display: swap;
    }
    @font-face {
        font-family: 'MonteserratRegular';
        src: local('MonteserratRegular'), local('MonteserratRegular'),
        url(${MonteserratRegular}) format('truetype');
        font-weight: 700;
        font-style: normal;
        font-display: swap;
    }
    @font-face {
        font-family: 'PoppinsBold';
        src: local('PoppinsBold'), local('PoppinsBold'),
        url(${PoppinsBold}) format('truetype');
        font-weight: 700;
        font-style: normal;
        font-display: swap;
    }
    @font-face {
        font-family: 'PoppinsRegular';
        src: local('PoppinsRegular'), local('PoppinsRegular'),
        url(${PoppinsRegular}) format('truetype');
        font-weight: 700;
        font-style: normal;
        font-display: swap;
    }
    @font-face {
        font-family: 'RalewayBold';
        src: local('RalewayBold'), local('RalewayBold'),
        url(${RalewayBold}) format('truetype');
        font-weight: 700;
        font-style: normal;
        font-display: swap;
    }
    @font-face {
        font-family: 'RalewayRegular';
        src: local('RalewayRegular'), local('RalewayRegular'),
        url(${RalewayRegular}) format('truetype');
        font-weight: 700;
        font-style: normal;
        font-display: swap;
    }
    @font-face {
        font-family: 'InterRegular';
        src: local('InterRegular'), local('InterRegular'),
        url(${InterRegular}) format('truetype');
        font-weight: 700;
        font-style: normal;
        font-display: swap;
    }
    @font-face {
        font-family: 'InterMedium';
        src: local('InterMedium'), local('InterMedium'),
        url(${InterMedium}) format('truetype');
        font-weight: 700;
        font-style: normal;
        font-display: swap;
    }
    @font-face {
        font-family: 'InterBold';
        src: local('InterBold'), local('InterBold'),
        url(${InterBold}) format('truetype');
        font-weight: 700;
        font-style: normal;
        font-display: swap;
    }
    body {
        background: black;
    }
`;

// Background
