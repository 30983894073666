import produce from 'immer';
import { ISetConfirmationNotification } from './actions';
import { ConfirmationType, types } from './types';

export type InAppNotification = {
    title: string;
    message: string;
};

export type ConfirmationNotification = {
    confirmationType: ConfirmationType;
    confirmationMessage?: string | null;
};

interface InitialProps {
    inAppNotification?: InAppNotification | null;
    inAppNotificationShowing: boolean;
    inAppNotificationList: Array<InAppNotification>;
    confirmationNotification: ConfirmationNotification;
}

const initialState: InitialProps = {
    inAppNotification: null,
    inAppNotificationShowing: false,
    inAppNotificationList: [],
    confirmationNotification: {
        confirmationType: undefined,
        confirmationMessage: null,
    },
};

const reducer = (state = initialState, action: any) => {
    const { type, payload } = action;
    switch (type) {
        case types.SET_IN_APP_NOTIFICATION:
            return produce(state, draftState => {
                draftState.inAppNotification = payload;
                draftState.inAppNotificationList.push(payload);
                draftState.inAppNotificationShowing = true;
            });
        case types.CLEAR_IN_APP_NOTIFICATION:
            return produce(state, draftState => {
                draftState.inAppNotification = null;
                draftState.inAppNotificationShowing = false;
            });
        case types.SET_CONFIRMATION_NOTIFICATION:
            return produce(state, draftState => {
                const { confirmationMessage, confirmationType } = payload as ISetConfirmationNotification;
                draftState.confirmationNotification.confirmationMessage = confirmationMessage;
                draftState.confirmationNotification.confirmationType = confirmationType;
            });
        case types.CLEAR_CONFIRMATION_NOTIFICATION:
            return produce(state, draftState => {
                draftState.confirmationNotification.confirmationMessage = null;
                draftState.confirmationNotification.confirmationType = undefined;
            });
        default:
            return state;
    }
};

export { reducer };
