import React from 'react';
import { ThemeContext } from '../../module/ThemeManager';
import { Container } from '@mui/material';
import { HeroShimmer } from '../HeroShimmer';
import { GenreTagShimmer } from '../GenreTagShimmer';
import { VerticalCardShimmer } from '../VerticalCardShimmer';
import { SquareCardShimmer } from '../SquareCardShimmer';

const DiscoveryShimmer = () => {
    return (
        <ThemeContext.Consumer>
            {() => {
                return (
                    <Container
                        disableGutters
                        sx={{
                            width: '100%',
                            marginTop: { xs: '16px', sm: '40px' }, // Changed this to reduce space atop, switch back to 16 and 40 if it breaks anything
                            marginLeft: { xs: '0px', sm: '65px' },
                            paddingLeft: { xs: '15px', sm: '40px' },
                            paddingRight: { xs: '15px', sm: '40px' },
                            overflowX: 'hidden',
                        }}
                        maxWidth={false}>
                        <HeroShimmer />
                        <GenreTagShimmer />
                        <VerticalCardShimmer />
                        <SquareCardShimmer />
                    </Container>
                );
            }}
        </ThemeContext.Consumer>
    );
};

export { DiscoveryShimmer };
