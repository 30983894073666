export const icon_curved_mail = require('./systemIcons/001-mail_inbox_app.png');
export const icon_curved_search = require('./systemIcons/044-search.png');
export const icon_curved_add = require('./systemIcons/080-add.png');
export const icon_curved_magnifier = require('./systemIcons/081-magnifier.png');
export const icon_curved_aud_search = require('./systemIcons/083-search.png');
export const icon_curved_house = require('./systemIcons/011-house.png');
export const icon_curved_bookmark = require('./systemIcons/084-bookmark.png');
export const icon_curved_bookmark_filled = require('./systemIcons/085-bookmark_filled.png');
export const icon_curved_map = require('./systemIcons/008-maps.png');
export const icon_curved_folder = require('./systemIcons/015-folder.png');
export const icon_curved_contract = require('./systemIcons/087-writing.png');
export const icon_curved_location = require('./systemIcons/088-location.png');
export const icon_curved_user = require('./systemIcons/089-user.png');
export const icon_curved_star = require('./systemIcons/093-star.png');
export const icon_curved_jobs = require('./systemIcons/094-suitcase.png');
export const icon_curved_notification = require('./systemIcons/098-notification-bell.png');
export const icon_curved_hamburger_menu = require('./systemIcons/097-menu.png');
export const icon_curved_filter = require('./systemIcons/095-filter.png');
export const icon_curved_plain_search = require('./systemIcons/082-search.png');
export const icon_curved_man = require('./systemIcons/100-man.png');
export const icon_curved_woman = require('./systemIcons/099-woman.png');
export const icon_curved_clock = require('./systemIcons/007-clock.png');
export const icon_curved_calendar = require('./systemIcons/018-calendar.png');
export const icon_curved_person = require('./systemIcons/111-user.png');
export const icon_thin_close = require('./thin/002-cross_button.png');
export const icon_thin_back_button = require('./thin/033-left-arrow.png');
export const icon_thin_download = require('./thin/023-download.png');
export const icon_thin_search = require('./thin/017-magnifying_glass.png');
export const icon_curved_discovery = require('./systemIcons/101-discovery.png');
export const icon_curved_heart_empty = require('./systemIcons/102-heart.png');
export const icon_curved_heart_fill = require('./systemIcons/103-heart.png');
export const icon_thin_chevron_down = require('./thin/036-chevron.png');
export const icon_thin_chevron_right = require('./thin/035-right-chevron.png');
export const icon_curved_director_chair = require('./systemIcons/104-director-chair.png');
export const icon_thin_director_chair_colored = require('./thin/060-director-chair-alternate-III.png');
export const icon_thin_director_chair_colored_alternate = require('./thin/061-director-chair-alternate-IV.png');
export const icon_curved_megaphone = require('./systemIcons/105-megaphone.png');
export const icon_curved_two_dots = require('./systemIcons/106-two_dots.png');
export const icon_curved_three_dots = require('./systemIcons/107-three-vertical-dots.png');
export const icon_normal_pdf = require('./pdf-file.png');
export const icon_thin_send = require('./thin/037-send.png');
export const icon_emoji_smile = require('./thin/041-smile.png');
export const icon_emoji_heart = require('./thin/040-heart.png');
export const icon_emoji_angry = require('./thin/038-angry.png');
export const icon_emoji_like = require('./thin/039-like.png');
export const icon_thin_minimize = require('./thin/006-minimize.png');
export const icon_thin_maximize = require('./thin/005-maximize_size.png');
export const icon_thin_view = require('./thin/042-view.png');
export const icon_thin_hide = require('./thin/043-hidden.png');
export const icon_thin_forum = require('./thin/027-speech_bubbles.png');
export const icon_thin_share = require('./thin/044-share.png');
export const icon_curved_share = require('./systemIcons/115-send.png');
export const icon_thin_clapping = require('./thin/046-clapping.png');
export const icon_thin_clapping_filled = require('./thin/047-clapping-filled.png');
export const icon_thin_comment = require('./thin/048-comment.png');
export const icon_curved_comment = require('./systemIcons/002-speech_bubble.png');
export const icon_thin_arrow_front = require('./thin/050-right-arrow.png');
export const icon_thin_setting = require('./thin/016-settings_gear_icon.png');
export const icon_curved_setting = require('./systemIcons/025-settings.png');
export const icon_thin_notification = require('./thin/015-notification.png');
export const icon_curved_edit = require('./systemIcons/087-writing.png');
export const icon_curved_delete = require('./systemIcons/093-star.png');
export const icon_curved_manage = require('./systemIcons/070-file_hosting.png');
export const icon_curved_check = require('./systemIcons/109-check.png');
export const icon_curved_cancel = require('./systemIcons/110-cancel.png');
export const icon_curved_alternate_cancel = require('./systemIcons/112-alt_cancel.png');
export const icon_curved_blank_check_box = require('./systemIcons/113-blank-check-box.png');
export const icon_curved_checked_check_box = require('./systemIcons/114-checked-box.png');
export const icon_curved_camera = require('./systemIcons/004-camera.png');
export const icon_curved_image = require('./systemIcons/005-image.png');
export const icon_thin_upload = require('./thin/022-upload_button.png');
export const icon_thin_trash = require('./thin/052-trash.png');
export const icon_thin_remove = require('./thin/053-remove-doc.png');
export const icon_curve_replace = require('./thin/051-exchange.png');
export const icon_thin_face_id = require('./thin/054-face-id.png');
export const icon_curved_music_wave = require('./thin/055-sound-waves.png');
export const icon_curved_play = require('./systemIcons/116-play-button.png');
export const icon_curved_pause = require('./systemIcons/118-pause_alternate.png');
export const icon_curved_more = require('./systemIcons/119-more.png');
export const icon_curved_share_content = require('./systemIcons/060-telegram.png');
export const icon_curved_save = require('./systemIcons/122-disk.png');
export const icon_curved_thrash = require('./systemIcons/121-remove.png');
export const icon_curved_info = require('./systemIcons/123-info.png');
export const icon_curved_act = require('./systemIcons/125-act.png');
export const icon_curved_chat = require('./systemIcons/124-chat.png');
export const icon_curved_submit = require('./systemIcons/127-checklist.png');
export const icon_curved_gif = require('./systemIcons/128-gif.png');
export const icon_curved_mic = require('./systemIcons/129-microphone.png');
export const icon_curved_send = require('./systemIcons/130-send.png');
export const icon_curved_attachment = require('./systemIcons/132-attach.png');
export const icon_curved_keyboard = require('./systemIcons/131-keyboard.png');
export const icon_curved_headshot = require('./systemIcons/136-headshot_alt_II.png');
export const icon_curved_casting_director_chair_color = require('./systemIcons/138-casting_director_chair.png');
export const icon_curved_talent_icon = require('./systemIcons/137-talent_icon.png');
export const icon_curved_theatre = require('./systemIcons/139-theatre.png');
export const icon_curved_hour_glass = require('./systemIcons/140-hourglass.png');
export const icon_curved_barred = require('./systemIcons/141-minus.png');
export const icon_curved_film_contest = require('./video.png');
export const icon_curved_poster_contest = require('./systemIcons/005-image.png');
export const icon_curved_audio_contest = require('./musical-note.png');
export const icon_curved_acting_contest = require('./theater.png');


/** prizes */
export const icon_gift_prize = require('./gift_color.png')
export const icon_first_prize = require('./first.png')
export const icon_medal_prize = require('./medal.png')
export const icon_gift_outline_prize = require('./gift_outline.png')
export const icon_curved_pencil = require('./pencil.png');
