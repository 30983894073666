import styled from 'styled-components';
import { colors } from '../../constants/theme';

export interface IIcon {
    active?: boolean;
    height?: any;
    width?: any;
    borderRadius?: number;
    color?: string;
    colorDisabled?: boolean;
    onClick?: () => void;
    enableBackground?: boolean;
    customBackgroundColor?: string;
}

export const IconStyled = styled.img<IIcon>`
    height: ${({ height }) => height};
    width: ${({ width }) => width};
    border-radius: ${({ borderRadius }) => borderRadius};
    background-image: ${({ enableBackground, customBackgroundColor }) => enableBackground && customBackgroundColor};
    filter: invert(100%) sepia(1%) saturate(0%) hue-rotate(204deg) brightness(112%) contrast(100%);
`;

IconStyled.defaultProps = {
    active: true,
    customBackgroundColor: colors.primary,
    color: '#fff',
    height: 'auto',
    width: 24,
    colorDisabled: true,
};
