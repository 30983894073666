import { StyleSheet } from 'react-native';
import React from 'react';
import { ColorScheme, theme } from '../../constants/theme';
import { useThemeContext } from '../../hook/useThemeManager';
import { Button, SxProps, Theme, Typography } from '@mui/material';
import { FONT_ENUM } from '../../types/global/fonts.types';
import { Spinner } from '@chakra-ui/react';

export interface ICustomButton {
    label: string;
    onClick?: () => void;
    disabled?: boolean;
    customStyle?: React.CSSProperties;
    textStyle?: React.CSSProperties;
    sx?: SxProps<Theme>;
    textSx?: SxProps<Theme>;
    loading?: boolean;
}
const CustomButton: React.FC<ICustomButton> = ({
    label,
    onClick,
    disabled,
    customStyle,
    textStyle,
    sx,
    loading,
    textSx,
}) => {
    const { themeStyle } = useThemeContext();
    const styles = dynamicStyles(themeStyle);
    return (
        <Button
            style={{ ...styles.mainButton, ...customStyle }}
            onClick={onClick}
            disabled={disabled || loading}
            sx={{
                backgroundColor: disabled ? theme[themeStyle].darkGray : theme[themeStyle].primary_orange,
                '&:hover': { backgroundColor: theme[themeStyle].primary_white },
                ...sx,
            }}>
            {loading ? (
                <Spinner height={5} width={5} color={theme[themeStyle].black} />
            ) : (
                <Typography variant="body1" style={{ ...styles.mainText, ...textStyle }} sx={textSx}>
                    {label}
                </Typography>
            )}
        </Button>
    );
};

CustomButton.defaultProps = {
    label: 'Button Text',
    onClick: () => {},
};

const dynamicStyles = (colorScheme: ColorScheme) =>
    StyleSheet.create({
        mainButton: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: 100,
            height: '40px',
            paddingBottom: 10,
            paddingTop: 10,
            paddingLeft: 10,
            paddingRight: 10,
            textTransform: 'none',
        },
        text: {
            width: 'fit-content',
            textAlign: 'left',
            fontSize: 14,
            fontWeight: '600',
            fontFamily: FONT_ENUM.MAIN_BOLD,
        },
        mainText: {
            width: 'fit-content',
            textAlign: 'left',
            fontSize: 14,
            fontWeight: '600',
            color: theme[colorScheme].pure_black,
            fontFamily: FONT_ENUM.MAIN_BOLD,
        },
    });
export default CustomButton;
