import React from 'react';
import { ThemeContext } from '../../module/ThemeManager';
import { Container } from '@mui/material';
import { GenreTagShimmer } from '../GenreTagShimmer';
import { SquareContestsShimmer } from '../SquareContestsShimmer';

const ActiveContestShimmer = () => {
    return (
        <ThemeContext.Consumer>
            {() => {
                return (
                    <Container
                        disableGutters
                        sx={{
                            width: '100%',
                            minHeight: '100vh',
                            marginTop: { xs: '16px', sm: '40px' },
                            marginLeft: { xs: '0px', sm: '65px' },
                            paddingLeft: { xs: '20px', sm: '40px' },
                            paddingRight: { xs: '20px', sm: '40px' },
                            overflowX: 'hidden',
                        }}
                        maxWidth={false}>
                        <GenreTagShimmer />
                        <SquareContestsShimmer />
                    </Container>
                );
            }}
        </ThemeContext.Consumer>
    );
};

export { ActiveContestShimmer };
