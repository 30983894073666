import React from 'react';
import { Box } from '@mui/material';
import background_dark from '../../asset/images/image/background_dark.png';
import background_light from '../../asset/images/image/background_light.png';
import { StyleSheet } from 'react-native';
import { themeSelector } from '../../selectors/selectors';
import { useSelector } from 'react-redux';

const EsBackground = () => {
    const currentTheme = useSelector(themeSelector);
    const styles = dynamicStyles(currentTheme);

    return <Box style={styles.container} sx={{ position: 'fixed', top: 0 }}></Box>;
};

const dynamicStyles = (currentTheme?: string) =>
    StyleSheet.create({
        container: {
            backgroundImage: `url(${currentTheme === 'dark' ? background_dark : background_light})`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            width: '100vw',
            height: '100vh',
            zIndex: 0,
        },
    });

export { EsBackground };
