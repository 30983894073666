import { SxProps, Theme, Typography } from '@mui/material';
import React from 'react';
import { useThemeContext } from '../../hook/useThemeManager';
import { theme } from '../../constants/theme';
import { FONT_ENUM } from '../../types/global/fonts.types';

interface ISituation {
    label: string;
    sx?: SxProps<Theme>;
}

const Situation: React.FC<ISituation> = ({ label, sx }) => {
    const { themeStyle } = useThemeContext();
    return (
        <Typography
            sx={sx}
            style={{
                fontSize: 12,
                color: theme[themeStyle].tag_white,
                fontFamily: FONT_ENUM.MEDIUM_HEADER,
                textTransform: 'uppercase',
            }}>
            {label}
        </Typography>
    );
};

export { Situation };
