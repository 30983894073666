import { call, put, all, takeLatest } from 'redux-saga/effects';
import { types } from './types';
import {
    createTalentProfileSuccess,
    createTalentProfileFail,
    deleteTalentProfileFail,
    deleteTalentProfileSuccess,
    getAllTalentProfileFail,
    getAllTalentProfileSuccess,
    getCurrentUserTalentProfileSuccess,
    getCurrentUserTalentProfileFail,
    getTalentProfileDetailsFail,
    getTalentProfileDetailsSuccess,
    publishTalentProfileSuccess,
    publishTalentProfileFail,
    unPublishTalentProfileFail,
    unPublishTalentProfileSuccess,
    updateTalentProfileSuccess,
    updateTalentProfileFail,
    deleteSingleImageFromGallerySuccess,
    deleteSingleImageFromGalleryFail,
    deleteSingleImageSuccess,
    deleteSingleImageFail,
    uploadImageSuccess,
    uploadImageFail,
    getTalentPastWorkSuccess,
    getTalentPastWorkFail,
    getDiscoverSuccess,
    getDiscoverFail,
    getMyActsSuccess,
    getMyActsFail,
    getSingleActSuccess,
    getSingleActFail,
    getActsSuccess,
    getActsFail,
    createActSuccess,
    createActFail,
    deleteActSuccess,
    deleteActFail,
} from './action';
import { globalErrorHandler } from '../error/saga';
import { api } from '../../configurations/api';
import {
    filterTalentUrl,
    biographyUrl,
    getMyTalentProfileurl,
    talentProfileBaseUrl,
    deleteTalentSingleImageUrl,
    uploadTalentImagesUrl,
    discoverUrl,
    myActsUrl,
    actsUrl,
} from '../../configurations/api/url';
import { httpRequest } from '../types';
import { SentryCapture } from '../../analytics/Sentry';

function* filterTalent({ payload, resolve, reject }: any): any {
    try {
        const response = yield call(api, filterTalentUrl, httpRequest.POST, payload, 0, 0);
        const { data } = response.data;
        yield put(getAllTalentProfileSuccess(data));
        resolve(data);
    } catch (error) {
        SentryCapture(error, 'error');
        reject(error);
        yield put(getAllTalentProfileFail(error));
    }
}

function* getCurrentTalentSelectionBioInformation(payload: string): any {
    const requestUrl = `${biographyUrl}/${payload}`;
    try {
        const response = yield call(api, requestUrl, httpRequest.GET, null, 0, 0);
        const { data } = response.data;
        yield put(getTalentPastWorkSuccess(data));
    } catch (error) {
        SentryCapture(error, 'error');
        yield put(getTalentPastWorkFail(error));
    }
}

function* getCurrentTalentProfile({ resolve, reject }: any): any {
    try {
        const response = yield call(api, getMyTalentProfileurl, httpRequest.GET, null, 0, 0);
        const { data } = response.data;
        yield put(getCurrentUserTalentProfileSuccess(data));
        resolve(data);
    } catch (error: any) {
        SentryCapture(error, 'error');
        yield put(getCurrentUserTalentProfileFail(error));
        reject(error);
    }
}

/** The below can also be used for search by conc ?title=arg&age= ...etc */
function* getAllTalentProfile({ payload, resolve, reject }: any): any {
    const { url } = payload || {};
    try {
        const request = `${talentProfileBaseUrl}/${url || ''}`;
        const response = yield call(api, request, httpRequest.GET, null, 0, 0);
        const { data } = response.data;
        yield put(getAllTalentProfileSuccess(data));
        resolve(data);
    } catch (error: any) {
        SentryCapture(error, 'error');
        yield put(getAllTalentProfileFail(error));
        reject(error);
    }
}

function* getTalentProfileDetail({ payload, resolve, reject }: any): any {
    try {
        const request = `${talentProfileBaseUrl}/${payload}`;
        const response = yield call(api, request, httpRequest.GET, null, 0, 0);
        const { data } = response.data;
        yield put(getTalentProfileDetailsSuccess(data));
        yield* getCurrentTalentSelectionBioInformation(data.socials.boxofix._id);
        resolve(data);
    } catch (error: any) {
        SentryCapture(error, 'error');
        yield put(getTalentProfileDetailsFail(error));
        reject(error);
    }
}

function* deleteTalentProfile({ payload, resolve, reject }: any): any {
    try {
        const request = `${talentProfileBaseUrl}/${payload}`;
        const response = yield call(api, request, httpRequest.DELETE, null, 0, 0);
        const { data } = response.data;
        yield put(deleteTalentProfileSuccess(data));
        resolve(data);
    } catch (error: any) {
        SentryCapture(error, 'error');
        yield put(deleteTalentProfileFail(error));
        reject(error);
    }
}

function* createTalentProfile({ payload, resolve, reject }: any): any {
    try {
        const response = yield call(api, talentProfileBaseUrl, httpRequest.POST, payload, 0, 0);
        const { data } = response.data;
        yield put(createTalentProfileSuccess(data));
        resolve(data);
    } catch (error: any) {
        SentryCapture(error, 'error');
        yield call(globalErrorHandler, error);
        yield put(createTalentProfileFail(error));
        reject(error);
    }
}

function* updateTalentProfile({ payload, resolve, reject }: any): any {
    const { id, body, isImageUpload } = payload;
    const request = `${talentProfileBaseUrl}/${id}`;
    try {
        const response = yield call(api, request, httpRequest.PATCH, body, 0, 0, isImageUpload);
        const { data } = response.data;
        yield put(updateTalentProfileSuccess(data));
        resolve(data);
    } catch (error: any) {
        SentryCapture(error, 'error');
        yield call(globalErrorHandler, error);
        yield put(updateTalentProfileFail(error));
        reject(error);
    }
}

function* publishProfile({ payload, resolve, reject }: any): any {
    const request = `${talentProfileBaseUrl}/publish/${payload}`;
    try {
        const response = yield call(api, request, httpRequest.PATCH, {}, 0, 0);
        const { data } = response.data;
        yield put(publishTalentProfileSuccess(data));
        resolve(data);
    } catch (error: any) {
        SentryCapture(error, 'error');
        yield call(globalErrorHandler, error);
        yield put(publishTalentProfileFail(error));
        reject(error);
    }
}

function* unPublishProfile({ payload, resolve, reject }: any): any {
    const request = `${talentProfileBaseUrl}/unpublish/${payload}`;
    try {
        const response = yield call(api, request, httpRequest.PATCH, {}, 0, 0);
        const { data } = response.data;
        yield put(unPublishTalentProfileSuccess(data));
        resolve(data);
    } catch (error: any) {
        SentryCapture(error, 'error');
        yield call(globalErrorHandler, error);
        yield put(unPublishTalentProfileFail(error));
        reject(error);
    }
}

function* deleteSingleImage({ payload, resolve, reject }: any): any {
    const { id, body } = payload;
    const request = `${deleteTalentSingleImageUrl}/${id}`;

    try {
        const response = yield call(api, request, httpRequest.PATCH, body, 0, 0);
        const { data } = response.data;
        yield put(deleteSingleImageSuccess(data));
        resolve(data);
    } catch (error: any) {
        SentryCapture(error, 'error');
        yield call(globalErrorHandler, error);
        reject(error);
        yield put(deleteSingleImageFail(error));
    }
}

/** Specifically for deleting images from gallery */
function* deleteSingleImageFromGallery({ payload, resolve, reject }: any): any {
    const { id, body } = payload;
    const request = `${uploadTalentImagesUrl}/${id}`;
    try {
        const response = yield call(api, request, httpRequest.DELETE, body, 0, 0);
        const { data } = response.data;
        yield put(deleteSingleImageFromGallerySuccess(data));
        resolve(data);
    } catch (error: any) {
        SentryCapture(error, 'error');
        yield call(globalErrorHandler, error);
        reject(error);
        yield put(deleteSingleImageFromGalleryFail(error));
    }
}

function* uploadSingleImageToGallery({ payload, resolve, reject }: any): any {
    const { id, body, isImageUpload } = payload;
    const request = `${uploadTalentImagesUrl}/${id}`;
    try {
        const response = yield call(api, request, httpRequest.PATCH, body, 0, 0, isImageUpload);
        const { data } = response.data;
        yield put(uploadImageSuccess(data));
        resolve(data);
    } catch (error: any) {
        SentryCapture(error, 'error');
        yield call(globalErrorHandler, error);
        reject(error);
        yield put(uploadImageFail(error));
    }
}

function* getDiscoverWorker({ payload }: any): any {
    const discoverPayload = payload ? payload : { age_from: 1, age_to: 100, lng: 0, lat: 0, radius: 0 };
    try {
        const response = yield call(api, discoverUrl, httpRequest.POST, discoverPayload, 0, 0);
        const { data } = response;
        yield put(getDiscoverSuccess(data));
    } catch (error: any) {
        SentryCapture(error, 'error');
        yield call(globalErrorHandler, error);
        yield put(getDiscoverFail(error));
    }
}

function* getMyActsWorker(): any {
    try {
        const response = yield call(api, myActsUrl, httpRequest.GET, null, 0, 0);
        const { data } = response;
        yield put(getMyActsSuccess(data));
    } catch (error: any) {
        SentryCapture(error, 'error');
        yield call(globalErrorHandler, error);
        yield put(getMyActsFail(error));
    }
}

function* getSingleAct({ payload }: any): any {
    const request = `${actsUrl}/${payload}`;
    try {
        const response = yield call(api, request, httpRequest.GET, null, 0, 0);
        const { data } = response;
        yield put(getSingleActSuccess(data));
    } catch (error: any) {
        SentryCapture(error, 'error');
        yield call(globalErrorHandler, error);
        yield put(getSingleActFail(error));
    }
}

function* getAllActs(): any {
    try {
        const response = yield call(api, actsUrl, httpRequest.GET, null, 0, 0);
        const { data } = response;
        yield put(getActsSuccess(data));
    } catch (error: any) {
        SentryCapture(error, 'error');
        yield call(globalErrorHandler, error);
        yield put(getActsFail(error));
    }
}

function* createAct({ payload }: any): any {
    try {
        const response = yield call(api, actsUrl, httpRequest.POST, payload, 0, 0);
        const { data } = response;
        yield put(createActSuccess(data));
    } catch (error: any) {
        SentryCapture(error, 'error');
        yield call(globalErrorHandler, error);
        yield put(createActFail(error));
    }
}

function* deleteAct({ payload }: any): any {
    try {
        yield call(api, `${actsUrl}/${payload}`, httpRequest.DELETE, payload, 0, 0);
        yield put(deleteActSuccess(payload));
    } catch (error: any) {
        SentryCapture(error, 'error');
        yield call(globalErrorHandler, error);
        yield put(deleteActFail(error));
    }
}

/********** WATCHERS *************************** */

function* createTalentProfileWatcher() {
    yield takeLatest(types.CREATE_TALENT_PROFILE, createTalentProfile);
}

function* updateTalentProfileWatcher() {
    yield takeLatest(types.UPDATE_TALENT_PROFILE, updateTalentProfile);
}

function* publishTalentProfileWatcher() {
    yield takeLatest(types.PUBLISH_TALENT_PROFILE, publishProfile);
}

function* unPublishTalentProfileWatcher() {
    yield takeLatest(types.UNPUBLISH_TALENT_PROFILE, unPublishProfile);
}

function* deleteSingleImageWatcher() {
    yield takeLatest(types.DELETE_SINGLE_TALENT_IMAGE, deleteSingleImage);
}

function* deleteSingleImageFromGalleryWatcher() {
    yield takeLatest(types.DELETE_SINGLE_IMAGE_FROM_TALENT__GALLERY, deleteSingleImageFromGallery);
}

function* uploadSingleImageToGalleryWatcher() {
    yield takeLatest(types.UPLOAD_IMAGE, uploadSingleImageToGallery);
}

function* getCurrentTalentProfileWatcher() {
    yield takeLatest(types.GET_CURRENT_USER_TALENT_PROFILE, getCurrentTalentProfile);
}

function* getAllTalentProfileWatcher() {
    yield takeLatest(types.GET_ALL_TALENT_PROFILES, getAllTalentProfile);
}

function* getTalentProfileDetailWatcher() {
    yield takeLatest(types.GET_TALENT_PROFILE_DETAILS, getTalentProfileDetail);
}

function* deleteTalentProfileWatcher() {
    yield takeLatest(types.DELETE_TALENT_PROFILE, deleteTalentProfile);
}

function* filterTalentWatcher() {
    yield takeLatest(types.FILTER_TALENT, filterTalent);
}

function* getDiscoverWatcher() {
    yield takeLatest(types.GET_DISCOVER, getDiscoverWorker);
}

function* getMyActsWatcher() {
    yield takeLatest(types.GET_MY_ACTS, getMyActsWorker);
}

function* getSingleActWatcher() {
    yield takeLatest(types.GET_SINGLE_ACT, getSingleAct);
}

function* getAllActsWacher() {
    yield takeLatest(types.GET_ACTS, getAllActs);
}

function* createActWatcher() {
    yield takeLatest(types.CREATE_ACT, createAct);
}

function* deleteActWatcher() {
    yield takeLatest(types.DELETE_ACT, deleteAct);
}

export default function* talentProfileSaga() {
    yield all([
        createTalentProfileWatcher(),
        updateTalentProfileWatcher(),
        publishTalentProfileWatcher(),
        unPublishTalentProfileWatcher(),
        deleteSingleImageWatcher(),
        deleteSingleImageFromGalleryWatcher(),
        uploadSingleImageToGalleryWatcher(),
        getCurrentTalentProfileWatcher(),
        getAllTalentProfileWatcher(),
        getTalentProfileDetailWatcher(),
        deleteTalentProfileWatcher(),
        filterTalentWatcher(),
        getDiscoverWatcher(),
        getMyActsWatcher(),
        getSingleActWatcher(),
        getAllActsWacher(),
        createActWatcher(),
        deleteActWatcher(),
    ]);
}
