import React from 'react';
import { StyleSheet } from 'react-native';
import { Container } from '@mui/system';
import { Skeleton, Stack, Typography } from '@mui/material';
import { theme } from '../../constants/theme';
import { useThemeContext } from '../../hook/useThemeManager';
import { FONT_ENUM } from '../../types/global/fonts.types';
import { FooterContainer } from '../../components/ScreenContainer/FooterScreenContainer/FooterContainer';

const styles = StyleSheet.create({
    container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
    },
    header: {
        fontFamily: FONT_ENUM.BOLD_HEADER,
        fontSize: 20,
        color: 'red',
    },
    paragraphs: {
        fontFamily: FONT_ENUM.MEDIUM_TEXT,
    },
});

const FooterPageShimmer = () => {
    const { themeStyle } = useThemeContext();
    return (
        <Container disableGutters style={styles.container} maxWidth={false}>
            <FooterContainer>
                <Typography
                    sx={{
                        mb: 2,
                        fontSize: {
                            sm: 24,
                            xs: 16,
                            lg: 24,
                        },
                    }}
                    style={{
                        fontFamily: FONT_ENUM.REGULAR_HEADER,
                        paddingTop: '1px',
                        paddingLeft: '30px',
                        paddingRight: '30px',
                        paddingBottom: '30px',
                        color: theme[themeStyle].lightWhite,
                    }}>
                    <Stack>
                        <Skeleton
                            variant="text"
                            sx={{
                                fontSize: '2rem',
                                width: '150px',
                                backgroundColor: theme[themeStyle].gray_text,
                                marginBottom: '40px',
                            }}
                        />
                        <Skeleton
                            variant="text"
                            sx={{
                                fontSize: '1rem',
                                width: '150px',
                                backgroundColor: theme[themeStyle].darkGray60,
                                marginBottom: '40px',
                            }}
                        />
                        <Skeleton
                            variant="text"
                            sx={{
                                fontSize: '1rem',
                                width: '500px',
                                backgroundColor: theme[themeStyle].darkGray60,
                            }}
                        />
                        <Skeleton
                            variant="text"
                            sx={{
                                fontSize: '1rem',
                                width: '550px',
                                backgroundColor: theme[themeStyle].darkGray60,
                            }}
                        />
                        <Skeleton
                            variant="text"
                            sx={{
                                fontSize: '1rem',
                                width: '600px',
                                backgroundColor: theme[themeStyle].darkGray60,
                            }}
                        />
                    </Stack>
                </Typography>
            </FooterContainer>
        </Container>
    );
};

export { FooterPageShimmer };
