import React from 'react';

export default function CloseIcon({ fill = '#9A9FA5', stroke = '#FFF', ...rest }: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width="30px"
            height="30px"
            viewBox="0 0 64 64"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            stroke={stroke}
            {...rest}>
            <line x1="16" y1="16" x2="48" y2="48" />
            <line x1="48" y1="16" x2="16" y2="48" />
        </svg>
    );
}
