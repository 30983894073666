import * as React from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { TimePicker as MuiTimePicker } from '@mui/x-date-pickers/TimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Box, SxProps, Theme, styled } from '@mui/material';
import { useThemeContext } from '../../hook/useThemeManager';
import { theme } from '../../constants/theme';
import { useRef } from 'react';

export interface ICustomDatePicker {
    label?: string;
    value?: string;
    onChange?: (e: any) => void;
    minDate?: any;
    maxDate?: any;
    defaultValue?: any;
    ageLimit?: number;
    sx?: SxProps<Theme>;
    withTime?: boolean;
    disabled?: boolean;
}

const StyledBox = styled(Box)(({ theme }) => ({
    width: '100%',
    border: '1px solid grey',
    borderRadius: '10px',
    overflow: 'hidden',
    color: 'white',
    backgroundColor: 'rgba(19, 21, 23, 1)',
    '&:focus-within': {
        border: `1px solid rgba(251, 188, 85, 1)`,
    },
}));

export const CustomDatePicker: React.FC<ICustomDatePicker> = ({
    label,
    value,
    onChange,
    minDate,
    maxDate,
    defaultValue,
    sx,
    withTime,
    disabled,
}) => {
    const { themeStyle } = useThemeContext();
    const timerRef = useRef<Dayjs | null>(
        dayjs().startOf('day').add(dayjs().hour(), 'hour').add(dayjs().minute(), 'minute'),
    );
    const dateRef = useRef<Dayjs | null>(value ? dayjs(value) : null);

    return (
        <StyledBox
            sx={{
                '&:focus-within': { border: `1px solid ${theme[themeStyle].highlight}` },
                backgroundColor: theme[themeStyle].tag_white_light,
                ...sx,
            }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                    defaultValue={defaultValue}
                    minDate={minDate}
                    maxDate={maxDate}
                    disabled={disabled}
                    timezone="system"
                    onChange={newDate => {
                        if (!withTime && newDate) {
                            dateRef.current = newDate;
                            onChange && onChange(newDate);
                        } else {
                            if (newDate && timerRef.current) {
                                const selectedDateTime = newDate
                                    .hour(timerRef.current.hour())
                                    .minute(timerRef.current.minute());
                                onChange && onChange(selectedDateTime.toISOString());
                            }
                        }
                    }}
                    value={dateRef.current}
                    slotProps={{
                        textField: {
                            variant: 'filled',
                            inputProps: {
                                style: {
                                    color: theme[themeStyle].lightWhite,
                                    backgroundColor: theme[themeStyle].gray_text,
                                    paddingTop: 0,
                                    paddingBottom: 0,
                                    height: '50px',
                                },
                            },
                            InputProps: {
                                disableUnderline: true,
                            },
                        },
                        layout: {
                            sx: {
                                backgroundColor: theme[themeStyle].gray_text,
                                color: theme[themeStyle].lightWhite,
                                '& .MuiDayCalendar-weekDayLabel': {
                                    color: `${theme[themeStyle].light_gray_text}`,
                                },
                            },
                        },
                        nextIconButton: {
                            sx: {
                                color: theme[themeStyle].primary_orange,
                                ':disabled': {
                                    color: `${theme[themeStyle].light_gray_text} !important`,
                                },
                            },
                        },
                        previousIconButton: {
                            sx: {
                                color: theme[themeStyle].primary_orange,
                                ':disabled': {
                                    color: `${theme[themeStyle].light_gray_text} !important`,
                                },
                            },
                        },
                        day: {
                            sx: {
                                color: theme[themeStyle].lightWhite,
                                ':disabled': {
                                    color: `${theme[themeStyle].light_gray_text} !important`,
                                },
                                headerColor: 'white !important',
                            },
                        },
                        switchViewButton: {
                            sx: {
                                color: theme[themeStyle].primary_orange,
                            },
                        },
                        openPickerButton: {
                            sx: {
                                color: theme[themeStyle].lightWhite,
                            },
                        },
                    }}
                />
                {withTime && (
                    <MuiTimePicker
                        value={timerRef.current}
                        disabled={disabled}
                        onChange={newTime => {
                            timerRef.current = newTime;
                            if (newTime && dateRef.current) {
                                const selectedDateTime = dateRef.current.hour(newTime.hour()).minute(newTime.minute());
                                onChange && onChange(selectedDateTime.toISOString());
                            }
                        }}
                        slotProps={{
                            textField: {
                                variant: 'filled',
                                inputProps: {
                                    style: {
                                        color: theme[themeStyle].lightWhite,
                                        backgroundColor: theme[themeStyle].gray_text,
                                        paddingTop: 0,
                                        paddingBottom: 0,
                                        height: '50px',
                                    },
                                },
                                InputProps: {
                                    disableUnderline: true,
                                },
                            },
                            layout: {
                                sx: {
                                    backgroundColor: theme[themeStyle].gray_text,
                                    color: theme[themeStyle].lightWhite,
                                    '& .MuiDayCalendar-weekDayLabel': {
                                        color: `${theme[themeStyle].light_gray_text}`,
                                    },
                                },
                            },
                            openPickerButton: {
                                sx: {
                                    color: theme[themeStyle].lightWhite,
                                },
                            },
                        }}
                    />
                )}
            </LocalizationProvider>
        </StyledBox>
    );
};
