export const types = {
    GET_MY_AUDIENCE: 'GET_MY_AUDIENCE',
    GET_MY_AUDIENCE_SUCCESS: 'GET_MY_AUDIENCE_SUCCESS',
    GET_MY_AUDIENCE_FAIL: 'GET_MY_AUDIENCE_FAIL',

    BLOCK_A_FOLLOWER: 'BLOCK_A_FOLLOWER',
    BLOCK_A_FOLLOWER_SUCCESS: 'BLOCK_A_FOLLOWER_SUCCESS',
    BLOCK_A_FOLLOWER_FAIL: 'BLOCK_A_FOLLOWER_FAIL',

    FOLLOW_A_THSPIAN: 'FOLLOW_A_THSPIAN',
    FOLLOW_A_THSPIAN_SUCCESS: 'FOLLOW_A_THSPIAN_SUCCESS',
    FOLLOW_A_THSPIAN_FAIL: 'FOLLOW_A_THSPIAN_FAIL',

    UNFOLLOW_A_THSPIAN: 'UNFOLLOW_A_THSPIAN',
    UNFOLLOW_A_THSPIAN_SUCCESS: 'UNFOLLOW_A_THSPIAN_SUCCESS',
    UNFOLLOW_A_THSPIAN_FAIL: 'UNFOLLOW_A_THSPIAN_FAIL',

    GET_SUGGESTED_THSPIANS: 'GET_SUGGESTED_THSPIANS',
    GET_SUGGESTED_THSPIANS_SUCCESS: 'GET_SUGGESTED_THSPIANS_SUCCESS',
    GET_SUGGESTED_THSPIANS_FAIL: 'GET_SUGGESTED_THSPIANS_FAIL',

    GET_MY_FOLLOWING: 'GET_MY_FOLLOWING',
    GET_MY_FOLLOWING_SUCCESS: 'GET_MY_FOLLOWING_SUCCESS',
    GET_MY_FOLLOWING_FAIL: 'GET_MY_FOLLOWING_FAIL',

    ANNOUCE_NEW_FOLLOWER: 'ANNOUCE_NEW_FOLLOWER',

    GET_EXTERNAL_FOLLOWING: 'GET_EXTERNAL_FOLLOWING',
    GET_EXTERNAL_FOLLOWING_SUCCESS: 'GET_EXTERNAL_FOLLOWING_SUCCESS',
    GET_EXTERNAL_FOLLOWING_FAIL: 'GET_EXTERNAL_FOLLOWING_FAIL',

    GET_EXTERNAL_FOLLOWERS: 'GET_EXTERNAL_FOLLOWERS',
    GET_EXTERNAL_FOLLOWERS_SUCCESS: 'GET_EXTERNAL_FOLLOWERS_SUCCESS',
    GET_EXTERNAL_FOLLOWERS_FAIL: 'GET_EXTERNAL_FOLLOWERS_FAIL',

    RESET_FIELD: 'RESET_FIELD',
};
