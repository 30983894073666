import React, { useState } from 'react';
import { Typography, TypographyProps } from '@mui/material';
import { styled } from '@mui/system';
import { useThemeContext } from '../../hook/useThemeManager';
import { theme } from '../../constants/theme';

interface ReadMoreProps extends TypographyProps {
    text: string;
    maxLines?: number;
    maxChars?: number;
    lineHeight?: number; // Optional prop for line height
}

const ReadMoreWrapper = styled('div')<{ collapsed: boolean; maxLines: number; lineHeight: number }>(
    ({ collapsed, maxLines, lineHeight }) => ({
        overflow: 'hidden',
        display: '-webkit-box',
        WebkitLineClamp: collapsed ? maxLines : 'unset',
        WebkitBoxOrient: 'vertical',
        lineHeight: `${lineHeight}em`,
        maxHeight: collapsed ? `${maxLines * lineHeight}em` : 'unset',
        textOverflow: 'ellipsis',
    }),
);

const CutomReadMore: React.FC<ReadMoreProps> = ({
    text,
    maxLines = 3,
    maxChars = 90,
    lineHeight = 1.5,
    ...typographyProps
}) => {
    const [collapsed, setCollapsed] = useState(true);
    const { themeStyle } = useThemeContext();

    const toggleCollapse = () => setCollapsed(!collapsed);

    const isTextOverflowing = (): boolean => {
        if (maxChars && text.length > maxChars) return true;
        return false;
    };

    const displayText = collapsed && maxChars ? text.slice(0, maxChars) : text;

    // If the text is short enough, we don't need to show the "Read more" button
    const shouldShowToggle = isTextOverflowing();

    return (
        <div>
            <Typography component={'span'} {...typographyProps}>
                <ReadMoreWrapper collapsed={collapsed} maxLines={maxLines} lineHeight={lineHeight}>
                    {displayText}
                    {shouldShowToggle && collapsed && '... '}
                    {shouldShowToggle && (
                        <span
                            style={{
                                color: theme[themeStyle].main_blue,
                                cursor: 'pointer',
                                fontWeight: 'bold',
                            }}
                            onClick={toggleCollapse}>
                            {collapsed ? 'Read more' : ' Read less'}
                        </span>
                    )}
                </ReadMoreWrapper>
            </Typography>
        </div>
    );
};

export { CutomReadMore };
