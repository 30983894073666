import React from 'react';
import { LazyLoadImage as OriginalLazyLoadImage, LazyLoadImageProps } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { Box, SxProps, Theme } from '@mui/material';

interface CustomLazyLoadImageProps extends LazyLoadImageProps {
    showGradient?: boolean;
    gradientStyle?: SxProps<Theme>;
}

const CustomLazyLoadImage: React.FC<CustomLazyLoadImageProps> = ({ showGradient, gradientStyle, ...props }) => {
    return (
        <>
            <OriginalLazyLoadImage {...props} />
            {showGradient && (
                <Box
                    position="absolute"
                    bottom={0}
                    left={0}
                    width="100%"
                    height="80%"
                    sx={{
                        background: 'linear-gradient(to top, rgba(0, 0, 0, 0.8), transparent)',
                        pointerEvents: 'none',
                        ...gradientStyle,
                    }}
                />
            )}
        </>
    );
};

export default CustomLazyLoadImage;
