import React from 'react';
import { Box, Grid, Skeleton, Stack } from '@mui/material';
import { useThemeContext } from '../../hook/useThemeManager';
import { theme } from '../../constants/theme';

const UserCarouselShimmer = () => {
    const { themeStyle } = useThemeContext();

    return (
        <Box>
            <Skeleton
                variant="text"
                sx={{
                    width: '150px',
                    fontSize: '2rem',
                    backgroundColor: theme[themeStyle].gray_text,
                    marginBottom: '30px',
                }}
            />
            <Box>
                <Grid container spacing={2}>
                    {Array.from(new Array(12)).map((_, index) => (
                        <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                            <Stack direction={{ xs: 'column', sm: 'row', md: 'row', lg: 'row' }} position={'relative'}>
                                <Skeleton
                                    variant="rounded"
                                    height={'220px'}
                                    sx={{
                                        width: {
                                            xs: '100%',
                                            sm: '230px',
                                            md: '230px',
                                            lg: '230px',
                                        },
                                        backgroundColor: theme[themeStyle].gray_text,
                                        borderRadius: '6px',
                                        marginBottom: '5px',
                                    }}
                                />
                                <Skeleton
                                    variant="text"
                                    sx={{
                                        fontSize: '1rem',
                                        width: '50px',
                                        backgroundColor: theme[themeStyle].darkGray60,
                                        position: 'absolute',
                                        left: { xs: 30, sm: 20, md: 20, lg: 20 },
                                        bottom: 40,
                                    }}
                                />
                                <Skeleton
                                    variant="text"
                                    sx={{
                                        fontSize: '1rem',
                                        width: '120px',
                                        backgroundColor: theme[themeStyle].darkGray60,
                                        position: 'absolute',
                                        left: { xs: 30, sm: 20, md: 20, lg: 20 },
                                        bottom: 20,
                                    }}
                                />
                            </Stack>
                        </Grid>
                    ))}
                </Grid>
            </Box>
        </Box>
    );
};

export { UserCarouselShimmer };
