import { StyleSheet } from 'react-native';
import { Typography, Box, Skeleton } from '@mui/material';
import { useThemeContext } from '../../hook/useThemeManager';
import { ColorScheme, theme } from '../../constants/theme';
import { FONT_ENUM } from '../../types/global/fonts.types';

const EsProfileIntroCardLoadingState = () => {
    const { themeStyle } = useThemeContext();
    const styles = dynamicStyles(themeStyle);

    return (
        <Box
            style={styles.flexColumn}
            sx={{
                width: '100%',
                gap: '22px',
                backgroundColor: theme[themeStyle].gray_text,
                height: 'fit-content',
                padding: '30px',
                borderRadius: '10px',
            }}>
            <Box style={styles.flexColumn} sx={{ gap: '12px' }}>
                <Typography style={styles.title}>Intro</Typography>
            </Box>

            <Box sx={{ display: 'block', width: '100%' }}>
                <Skeleton
                    variant="text"
                    sx={{ fontSize: '1rem', backgroundColor: theme[themeStyle].darkGray60, width: '80%' }}
                />
                <Skeleton
                    variant="text"
                    sx={{ fontSize: '1rem', backgroundColor: theme[themeStyle].darkGray60, width: '50%' }}
                />
                <Skeleton
                    variant="text"
                    sx={{ fontSize: '1rem', backgroundColor: theme[themeStyle].darkGray60, width: '40%' }}
                />
            </Box>
        </Box>
    );
};

const dynamicStyles = (colorScheme: ColorScheme) =>
    StyleSheet.create({
        flexColumn: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'center',
        },
        title: {
            fontSize: 20,
            fontWeight: '600',
            fontFamily: FONT_ENUM.MAIN_BOLD,
            textAlign: 'left',
            color: theme[colorScheme].dropdown_text,
        },
    });

export { EsProfileIntroCardLoadingState };
