import { call, put, all, takeLatest } from 'redux-saga/effects';
import { types } from './types';
import {
    blockAFollowerFail,
    blockAFollowerSuccess,
    getMyFollowersFail,
    getMyFollowersSuccess,
    getMyFollowingFail,
    getMyFollowingSuccess,
    getSuggestedThspiansFail,
    getSuggestedThspiansSuccess,
    followAThspianFail,
    followAThspianSuccess,
    unfollowThspianSuccess,
    unfollowThspianFail,
    annouceNewFollower,
    getExtrenalUserFollowersSuccess,
    getExtrenalUserFollowersFail,
    getExtrnalUserFollowingSuccess,
    getExtrnalUserFollowingFail,
} from './action';
import { IGetAudience, IGetTopUsers, IFollowThspian } from './model';
import { Action } from 'redux';
import { api } from '../../configurations/api';
import { audienceUrl, userBaseUrl } from '../../configurations/api/url';
import { httpRequest } from '../types';
import { genericParseSingleDocument, parseGenericCollection } from '../../utils/responseProcessor';
import { IGenericDocumentModifier } from '../../types/global/helper';
import { destroyOneCache, setCache } from '../cache/action';
import { CACHE_TYPE, NotUniqueCacheValue } from '../cache/types';
import { getNotUniqueCacheByKey, isUseCacheEnabled } from '../cache/saga';
import { en_config } from '../../config';
import { isEmpty } from '../../utils/lodash';
import { SentryCapture } from '../../analytics/Sentry';

/**
 * 
 * Generator<ReturnType, YieldType, InputType>
 * ReturnType represents the type of value returned by the generator.
    YieldType represents the type of values yielded by the generator.
    InputType represents the type of the parameter passed to the generator when calling next().
 */
function* followAThspian({ payload }: { payload: IFollowThspian }): Generator<any, any, any> {
    try {
        const response = yield call(api, audienceUrl, httpRequest.POST, payload, 0, 0);
        const { data } = response.data;
        const parsedResponse = genericParseSingleDocument(data);
        yield put(followAThspianSuccess({ from: payload.from, ...parsedResponse }));
        // Trigger nitification sequence
        yield put(annouceNewFollower(parsedResponse));
        yield put(
            destroyOneCache({
                cacheType: CACHE_TYPE.USER_FOLLOWING,
            }),
        );
    } catch (error: any) {
        console.log(error, 'THE ERROR');
        SentryCapture(error, 'error');
        yield put(followAThspianFail({ documentId: payload.following, ...error }));
    }
}

function* unfollowThspian({ payload }: { payload: IGenericDocumentModifier }): Generator<any, any, any> {
    try {
        yield call(api, `${audienceUrl}/${payload.documentId}`, httpRequest.DELETE, payload, 0, 0);
        yield put(unfollowThspianSuccess(payload));
        yield put(
            destroyOneCache({
                cacheType: CACHE_TYPE.USER_FOLLOWING,
            }),
        );
    } catch (error: any) {
        SentryCapture(error, 'error');
        yield put(unfollowThspianFail({ documentId: payload.documentId, ...error }));
    }
}

function* blockAFollower({ payload }: { payload: IGenericDocumentModifier }): Generator<any, any, any> {
    try {
        const response = yield call(
            api,
            `${audienceUrl}/${payload.documentId}`,
            httpRequest.PATCH,
            payload.payload,
            2,
            2000,
        );
        const { data } = response.data;
        const parsedResponse = genericParseSingleDocument(data);
        yield put(blockAFollowerSuccess(parsedResponse));
        yield put(
            destroyOneCache({
                cacheType: CACHE_TYPE.USER_AUDIENCE,
            }),
        );
    } catch (error: any) {
        SentryCapture(error, 'error');
        yield put(blockAFollowerFail(error));
    }
}
function* getExternalUserFollowers({ payload }: { payload: IGetAudience }): Generator<any, any, any> {
    const defaultUseCache = yield* isUseCacheEnabled();
    let initialResult: any = null;

    const cache: NotUniqueCacheValue = yield* getNotUniqueCacheByKey(
        CACHE_TYPE.USER_AUDIENCE,
        payload.externalUserId as string,
    );
    /** Check if it has been 30 minutes since the last pull. TODO: */
    if (cache && cache.key) {
        initialResult = cache.value;
    }

    if (initialResult && !isEmpty(initialResult) && defaultUseCache) {
        yield put(
            getExtrenalUserFollowersSuccess({
                data: initialResult,
                limit: payload.limit,
                page: payload.page,
                loadMore: initialResult.length === en_config.RESULT_LIMIT,
            }),
        );
    } else {
        try {
            const response = yield call(
                api,
                `${audienceUrl}?following=${payload.externalUserId}&limit=${payload.limit}&page=${payload.page}${
                    payload.params ? payload.params : ''
                }`,
                httpRequest.GET,
                null,
                2,
                2000,
            );
            const { data } = response.data;
            const parsedCollection = parseGenericCollection(data, genericParseSingleDocument);
            yield put(
                getExtrenalUserFollowersSuccess({
                    data: parsedCollection,
                    limit: payload.limit,
                    page: payload.page,
                    loadMore: parsedCollection.length === en_config.RESULT_LIMIT,
                }),
            );
            yield setCache({
                key: payload.externalUserId as string,
                type: CACHE_TYPE.USER_AUDIENCE,
                value: parsedCollection,
                isUnique: false,
            });
        } catch (error: any) {
            SentryCapture(error, 'error');
            yield put(getExtrenalUserFollowersFail(error));
        }
    }
}

function* getExternalUserFollowing({ payload }: { payload: IGetAudience }): Generator<any, any, any> {
    const defaultUseCache = yield* isUseCacheEnabled();
    let initialResult: any = null;
    const cache: NotUniqueCacheValue = yield* getNotUniqueCacheByKey(
        CACHE_TYPE.USER_FOLLOWING,
        payload.externalUserId as string,
    );
    if (cache && cache.key) {
        initialResult = cache.value;
    }

    if (initialResult && !isEmpty(initialResult) && defaultUseCache) {
        yield put(
            getExtrnalUserFollowingSuccess({
                data: initialResult,
                limit: payload.limit,
                page: payload.page,
                loadMore: initialResult.length === en_config.RESULT_LIMIT,
            }),
        );
    } else {
        try {
            const response = yield call(
                api,
                `${audienceUrl}?follower=${payload.externalUserId}&limit=${payload.limit}&page=${payload.page}${
                    payload.params ? payload.params : ''
                }`,
                httpRequest.GET,
                null,
                2,
                2000,
            );
            const { data } = response.data;
            const parsedCollection = parseGenericCollection(data, genericParseSingleDocument);
            yield put(
                getExtrnalUserFollowingSuccess({
                    data: parsedCollection,
                    isFiltered: payload.isFiltered,
                    limit: payload.limit,
                    page: payload.page,
                    loadMore: parsedCollection.length === en_config.RESULT_LIMIT,
                }),
            );

            yield setCache({
                key: payload.externalUserId as string,
                type: CACHE_TYPE.USER_FOLLOWING,
                value: parsedCollection,
                isUnique: false,
            });
        } catch (error: any) {
            SentryCapture(error, 'error');
            yield put(getExtrnalUserFollowingFail(error));
        }
    }
}

function* getMyFollowers({ payload }: { payload: IGetAudience }): Generator<any, any, any> {
    const defaultUseCache = yield* isUseCacheEnabled();
    let initialResult: any = null;
    const cache: NotUniqueCacheValue = yield* getNotUniqueCacheByKey(
        CACHE_TYPE.USER_AUDIENCE,
        `limit=${payload.limit || en_config.RESULT_LIMIT}&page=${payload.page || 0}`,
    );
    /** Check if it has been 30 minutes since the last pull. TODO: */
    if (cache && cache.key) {
        initialResult = cache.value;
    }

    if (initialResult && !isEmpty(initialResult) && defaultUseCache) {
        yield put(
            getMyFollowersSuccess({
                data: initialResult,
                limit: payload.limit,
                page: payload.page,
                loadMore: initialResult.length === en_config.RESULT_LIMIT,
            }),
        );
    } else {
        try {
            const response = yield call(
                api,
                `${audienceUrl}/followers?limit=${payload.limit}&page=${payload.page}${
                    payload.params ? payload.params : ''
                }`,
                httpRequest.GET,
                null,
                2,
                2000,
            );
            const { data } = response.data;
            const parsedCollection = parseGenericCollection(data, genericParseSingleDocument);
            yield put(
                getMyFollowersSuccess({
                    data: parsedCollection,
                    limit: payload.limit,
                    page: payload.page,
                    loadMore: parsedCollection.length === en_config.RESULT_LIMIT,
                }),
            );
            yield setCache({
                key: `limit=${payload.limit || en_config.RESULT_LIMIT}&page=${payload.page || 0}`,
                type: CACHE_TYPE.USER_AUDIENCE,
                value: parsedCollection,
                isUnique: false,
            });
        } catch (error: any) {
            SentryCapture(error, 'error');
            yield put(getMyFollowersFail(error));
        }
    }
}

function* getMyFollowing({ payload }: { payload: IGetAudience }): Generator<any, any, any> {
    const defaultUseCache = yield* isUseCacheEnabled();
    let initialResult: any = null;
    const cache: NotUniqueCacheValue = yield* getNotUniqueCacheByKey(
        CACHE_TYPE.USER_FOLLOWING,
        `limit=${payload.limit || en_config.RESULT_LIMIT}&page=${payload.page || 0}`,
    );
    if (cache && cache.key) {
        initialResult = cache.value;
    }

    if (initialResult && !isEmpty(initialResult) && defaultUseCache) {
        yield put(
            getMyFollowingSuccess({
                data: initialResult,
                limit: payload.limit,
                page: payload.page,
                loadMore: initialResult.length === en_config.RESULT_LIMIT,
            }),
        );
    } else {
        try {
            const response = yield call(
                api,
                `${audienceUrl}/following?limit=${payload.limit}&page=${payload.page}${
                    payload.params ? payload.params : ''
                }`,
                httpRequest.GET,
                null,
                2,
                2000,
            );
            const { data } = response.data;
            const parsedCollection = parseGenericCollection(data, genericParseSingleDocument);
            yield put(
                getMyFollowingSuccess({
                    data: parsedCollection,
                    isFiltered: payload.isFiltered,
                    limit: payload.limit,
                    page: payload.page,
                    loadMore: parsedCollection.length === en_config.RESULT_LIMIT,
                }),
            );

            yield setCache({
                key: `limit=${payload.limit || en_config.RESULT_LIMIT}&page=${payload.page || 0}`,
                type: CACHE_TYPE.USER_FOLLOWING,
                value: parsedCollection,
                isUnique: false,
            });
        } catch (error: any) {
            SentryCapture(error, 'error');
            yield put(getMyFollowingFail(error));
        }
    }
}

function* getSuggestedThspian({ payload }: { payload: IGetTopUsers }): Generator<any, any, any> {
    try {
        const response = yield call(api, `${userBaseUrl}/top-user`, httpRequest.POST, payload, 2, 1000);
        const { data } = response;
        const parsedUsers = parseGenericCollection(data, genericParseSingleDocument);
        yield put(getSuggestedThspiansSuccess(parsedUsers));
    } catch (error: any) {
        SentryCapture(error, 'error');
        yield put(getSuggestedThspiansFail(error));
    }
}

interface TaskAction extends Action {
    payload: IFollowThspian;
}

interface BlockTaskAction extends Action {
    payload: IGenericDocumentModifier;
}

interface GetAudienceTaskAction extends Action {
    payload: IGetAudience;
}

interface GetTopuserTaskAction extends Action {
    payload: IGetTopUsers;
}

function* followAThspianWatcher() {
    yield takeLatest<TaskAction>(types.FOLLOW_A_THSPIAN, followAThspian);
}

function* blockAFollowerWatcher() {
    yield takeLatest<BlockTaskAction>(types.BLOCK_A_FOLLOWER, blockAFollower);
}

function* getMyFollowersWatcher() {
    yield takeLatest<GetAudienceTaskAction>(types.GET_MY_AUDIENCE, getMyFollowers);
}

function* getMyFollowingWatcher() {
    yield takeLatest<GetAudienceTaskAction>(types.GET_MY_FOLLOWING, getMyFollowing);
}

function* getExternalUserFollowersWatcher() {
    yield takeLatest<GetAudienceTaskAction>(types.GET_EXTERNAL_FOLLOWERS, getExternalUserFollowers);
}

function* getExternalFollowingWatcher() {
    yield takeLatest<GetAudienceTaskAction>(types.GET_EXTERNAL_FOLLOWING, getExternalUserFollowing);
}

function* getSuggestedThspianWatcher() {
    yield takeLatest<GetTopuserTaskAction>(types.GET_SUGGESTED_THSPIANS, getSuggestedThspian);
}

function* unfollowThspianWatcher() {
    yield takeLatest<BlockTaskAction>(types.UNFOLLOW_A_THSPIAN, unfollowThspian);
}

export default function* audienceSaga() {
    yield all([
        followAThspianWatcher(),
        blockAFollowerWatcher(),
        getMyFollowersWatcher(),
        getMyFollowingWatcher(),
        getSuggestedThspianWatcher(),
        unfollowThspianWatcher(),
        getExternalFollowingWatcher(),
        getExternalUserFollowersWatcher(),
    ]);
}
