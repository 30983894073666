import { useSpring, animated } from '@react-spring/web';
import React, { useState } from 'react';
import { StyleSheet, Pressable as Press, PressableProps, StyleProp, ViewStyle } from 'react-native';

export interface IPressableProps extends PressableProps {
    children: any;
    style?: StyleProp<ViewStyle>;
    isClicked?: boolean;
    defaultOpacity?: number;
}

const Pressable = (props: IPressableProps) => {
    const { style, isClicked, defaultOpacity } = props;
    const [isHover, setIsHover] = useState(false);
    const fade = useSpring({
        opacity: isHover || isClicked ? 1 : defaultOpacity ?? 0.5,
    });
    const handleEvents = (eventName: string, e: any) => {
        if (eventName === 'hoverIn') {
            setIsHover(true);
            if (props.onHoverIn) {
                props.onHoverIn(eventName as any);
            }
        }
        if (eventName === 'hoverOut') {
            setIsHover(false);
            if (props.onHoverOut) {
                props.onHoverOut(eventName as any);
            }
        }
    };
    return (
        <animated.div style={fade}>
            <Press
                {...props}
                onHoverIn={event => handleEvents('hoverIn', event)}
                onHoverOut={event => handleEvents('hoverOut', event)}
                style={[styles.container, style]}>
                {props.children}
            </Press>
        </animated.div>
    );
};

export { Pressable };

const styles = StyleSheet.create({
    container: {
        opacity: 1,
    },
});
