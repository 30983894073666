import LocalizedStrings from 'react-localization';

import { af_strings } from './afrikaans/strings';
import { en_strings } from './english/strings';
import { es_strings } from './espanol/strings';
import { fr_string } from './french/strings';
import { pt_strings } from './portugese/strings';

export const strings = new LocalizedStrings({
    en: en_strings,
    es: es_strings,
    fr: fr_string,
    pt: pt_strings,
    af: af_strings,
});
