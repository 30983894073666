import React from 'react';
import { Stack, Typography } from '@mui/material';
import { IProject, IUserSummary } from '../../types/global/helper';
import { animated } from '@react-spring/web';
import { theme } from '../../constants/theme';
import { useThemeContext } from '../../hook/useThemeManager';
import { FONT_ENUM } from '../../types/global/fonts.types';
import { useEsHover } from '../Animation/useEsHover';
import { ThspianAvatar } from '../Avatar/ThspianAvatar';
import { convertMinuteSecondsDays, formatNumberWithK } from '../../utils/AppUtils';
import CustomLazyLoadImage from '../Image/CustomLazyLoadImage';
import { HamburgerIcon } from '@chakra-ui/icons';
import { FullScreenMoreMenu } from '../More/FullScreenMoreMenu';

export interface IVideoThumbnail {
    project: IProject;
    onClick?: () => void;
    isPlayingIconEnabled?: boolean;
    style?: React.CSSProperties;
    disable?: boolean;
    /** This mode below denotes whether we render the hamburger icon in the playlist mode to enable users drag
     * the thumbnail. Consider, renaming this in the future.
     */
    dragableMode?: boolean;
    isEditMode?: boolean;
    isFirstItemInList?: boolean;
    isLastItemInList?: boolean;
    onRemovePlaylistItem?: (project: IProject) => void;
    onAdjustPlaylistPosition?: (position: 'up' | 'down') => void;
}

const VideoThumbnail: React.FC<IVideoThumbnail> = ({
    project,
    onClick,
    isPlayingIconEnabled,
    style,
    disable,
    dragableMode,
    isEditMode,
    isFirstItemInList,
    isLastItemInList,
    onRemovePlaylistItem,
    onAdjustPlaylistPosition,
}) => {
    const { themeStyle } = useThemeContext();
    const { handleMouseEnter, handleMouseLeave, isHover } = useEsHover();
    const handleDisabeld = () => {
        if (disable) return;
        onClick && onClick();
    };

    const menu = [
        {
            id: 'remove',
            title: 'Remove from playlist',
        },
    ];

    if (!isLastItemInList) {
        menu.push({
            id: 'down',
            title: 'Move down',
        });
    }

    if (!isFirstItemInList) {
        menu.push({
            id: 'up',
            title: 'Move Up',
        });
    }

    const handleMoreItemClick = (moreType: string) => {
        switch (moreType) {
            case 'up':
                onAdjustPlaylistPosition && onAdjustPlaylistPosition(moreType);
                break;
            case 'down':
                onAdjustPlaylistPosition && onAdjustPlaylistPosition(moreType);
                break;
            case 'remove':
                onRemovePlaylistItem && onRemovePlaylistItem(project);
                break;
        }
    };

    return (
        <animated.div
            style={{ cursor: disable ? 'default' : 'pointer', position: 'relative', ...style }}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}>
            <Stack
                flexDirection="row"
                sx={{
                    width: 'fit-content',
                    marginBottom: 2,
                    borderRadius: 2,
                    overflow: 'hidden',
                    flexDirection: 'row',
                    minWidth: '400px',
                }}>
                {dragableMode && (
                    <Stack display="flex" justifyContent="center" alignItems="center" mr={1}>
                        <HamburgerIcon sx={{ h: '50px' }} color={theme[themeStyle].tag_white} />
                    </Stack>
                )}
                <Stack
                    direction="row"
                    display="flex"
                    overflow="hidden"
                    borderRadius={2}
                    minWidth={'200px'}
                    onClick={handleDisabeld}
                    maxWidth={'200px'}
                    minHeight={'100px'}
                    maxHeight={'100px'}
                    sx={{
                        backgroundColor: theme[themeStyle].zeus_dark,
                        width: '200px',
                        height: '100px',
                    }}>
                    <Stack style={{ width: '200px', height: '100px', objectFit: 'cover', position: 'relative' }}>
                        {isPlayingIconEnabled && (
                            <Typography
                                sx={{
                                    position: 'absolute',
                                    top: 2,
                                    right: 2,
                                    backgroundColor: theme[themeStyle].switchGreen,
                                    fontFamily: FONT_ENUM.REGULAR_HEADER,
                                    fontSize: 8,
                                    px: 1,
                                    borderRadius: 2,
                                }}>
                                PLAYING
                            </Typography>
                        )}
                        {project && (project.thumbnail || project.feature_image) && (
                            <CustomLazyLoadImage
                                src={project.thumbnail ?? project.feature_image}
                                alt="video thumbnail"
                                style={{
                                    height: 'auto',
                                    width: '100%',
                                    overflow: 'hidden',
                                    objectFit: project.thumbnail && isHover ? 'contain' : 'cover',
                                }}
                            />
                        )}
                    </Stack>
                </Stack>
                <Stack pl={2} onClick={handleDisabeld} width={'100%'}>
                    {project && (
                        <Typography
                            style={{
                                fontFamily: FONT_ENUM.MAIN_BOLD,
                                color: theme[themeStyle].lightWhite,
                                fontSize: 12,
                                display: '-webkit-box',
                                overflow: 'hidden',
                                WebkitBoxOrient: 'vertical',
                                WebkitLineClamp: 3,
                                fontWeight: 'bold',
                                maxWidth: '180px',
                            }}>
                            {project.title}
                        </Typography>
                    )}
                    <Stack direction="row" alignItems="center">
                        {project && (
                            <Typography
                                style={{
                                    fontFamily: FONT_ENUM.MAIN_REGULAR,
                                    color: theme[themeStyle].darkGray,
                                    fontSize: 9,
                                }}>
                                {formatNumberWithK(project.view_count!)} View
                                {`${project.view_count && project.view_count > 1 ? 's' : ''}`}
                            </Typography>
                        )}
                        <hr
                            style={{
                                width: '4px',
                                height: '4px',
                                borderRadius: 100,
                                marginRight: '4px',
                                marginLeft: '4px',
                                backgroundColor: theme[themeStyle].form_label,
                            }}
                        />
                        {project && (
                            <Typography
                                px={0.5}
                                style={{
                                    fontFamily: FONT_ENUM.MAIN_REGULAR,
                                    color: theme[themeStyle].darkGray,
                                    fontSize: 9,
                                }}>
                                {convertMinuteSecondsDays(project.createdAt)} ago
                            </Typography>
                        )}
                    </Stack>
                    <Stack direction="row" alignItems="center" py={1}>
                        {!!project && (
                            <ThspianAvatar
                                user={project.created_by as unknown as IUserSummary}
                                sx={{ height: '25px', width: '25px' }}
                                badgeSx={{
                                    border: `1px solid ${theme[themeStyle].tag_white}`,
                                    borderRadius: '50%',
                                }}
                            />
                        )}
                        {!!project && (
                            <Typography
                                px={1}
                                style={{
                                    fontFamily: FONT_ENUM.MAIN_MEDIUM,
                                    color: theme[themeStyle].tag_white,
                                    fontSize: 8,
                                    textTransform: 'capitalize',
                                }}>
                                {project.created_by?.firstName} {project.created_by?.lastName}
                            </Typography>
                        )}
                    </Stack>
                    {project && project.description && (
                        <Typography
                            style={{
                                fontFamily: FONT_ENUM.MAIN_REGULAR,
                                color: theme[themeStyle].darkGray,
                                fontSize: 10,
                                wordWrap: 'break-word',
                            }}
                            sx={{
                                display: '-webkit-box',
                                overflow: 'hidden',
                                WebkitBoxOrient: 'vertical',
                                WebkitLineClamp: 2,
                                lineHeight: 1.2,
                                maxWidth: '140px',
                                whiteSpace: 'normal',
                            }}>
                            {project.description}
                        </Typography>
                    )}
                </Stack>
                {isEditMode && (
                    <FullScreenMoreMenu
                        menu={menu}
                        onClick={handleMoreItemClick}
                        title={''}
                        customIconSx={{
                            top: 0,
                            right: 0,
                            position: 'absolute',
                            zIndex: 12,
                        }}
                    />
                )}
            </Stack>
        </animated.div>
    );
};

export { VideoThumbnail };
