export const types = {
    CREATE_LINK: 'CREATE_LINK',
    CREATE_LINK_SUCCESS: 'CREATE_LINK_SUCCESS',
    CREATE_LINK_FAIL: 'CREATE_LINK_FAIL',

    DELETE_LINK: 'DELETE_LINK',
    DELETE_LINK_SUCCESS: 'DELETE_LINK_SUCCESS',
    DELETE_LINK_FAIL: 'DELETE_LINK_FAIL',

    UPDATE_LINK: 'UPDATE_LINK',
    UPDATE_LINK_SUCCESS: 'UPDATE_LINK_SUCCESS',
    UPDATE_LINK_FAIL: 'UPDATE_LINK_FAIL',

    GET_MY_LINKS: 'GET_MY_LINKS',
    GET_MY_LINKS_SUCCESS: 'GET_MY_LINKS_SUCCESS',
    GET_MY_LINKS_FAIL: 'GET_MY_LINKS_FAIL',

    GET_LINK: 'GET_LINK',
    GET_LINK_SUCCESS: 'GET_LINK_SUCCESS',
    GET_LINK_FAIL: 'GET_LINK_FAIL',
};

export type TLink = {
    title: string;
    url: string;
    isDone?: boolean;
    isSocial?: boolean;
};

export interface ILink {
    _id: string;
    links: TLink[];
    unique_id: string;
    deleted: boolean;
    created_by: string;
    createdAt: string;
    updatedAt: string;
    background_image?: string;
    identifier?: string;
}

export interface ILinkPayload {
    links: TLink[];
    /** Used for update */
    linkId?: string;
    identifier?: string;
}

export interface ILinkInitialState {
    links: ILink[];
    linkIncreation?: ILink;
    loading?: boolean;
    errMsg?: any;
    selectedLink?: ILink;
}
