import { ROUTES } from '../types/global/routes.types';

export type Menu = {
    title: string;
    conditional: boolean;
    route: string;
};

export const header_menu: Menu[] = [
    {
        title: 'Home',
        conditional: false,
        route: '/',
    },
    {
        title: 'winners',
        conditional: false,
        route: ROUTES.WINNERS,
    },
    {
        title: 'about',
        conditional: false,
        route: ROUTES.ABOUT,
    },
    {
        title: 'coming soon',
        conditional: false,
        route: ROUTES.COMING_SOON,
    },
    {
        title: 'discover',
        conditional: false,
        route: ROUTES.DISCOVER,
    },
    {
        title: 'submit a film',
        conditional: false,
        route: ROUTES.SUBMIT,
    },
];
