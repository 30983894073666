import { IUserLocationFromCloudFront } from '../../constants/types/configuration/userGlobalConfig.types';
import { IUpdateUserConfig, IUserConfiguration, types, IShowProjectGuide } from './types';
import { createAction } from 'redux-act';

export const getUserConfig = () => ({
    type: types.GET_CONFIGURATION,
});

export const getUserConfigSuccess = (payload: IUserConfiguration) => ({
    type: types.GET_CONFIGURATION_SUCCESS,
    payload,
});

export const getUserConfigFailed = (error: any) => ({
    type: types.GET_CONFIGURATION_FAILED,
    payload: error,
});

export const createUserConfig = (payload: any) => ({
    type: types.CREATE_CONFIGURATION,
    payload,
});

export const createUserConfigSuccess = (payload: IUserConfiguration) => ({
    type: types.CREATE_CONFIGURATION_SUCCESS,
    payload,
});

export const createUserConfigFailed = (error: any) => ({
    type: types.CREATE_CONFIGURATION_FAILED,
    payload: error,
});

export const updateUserConfig = (payload: IUpdateUserConfig) => ({
    type: types.UPDATE_USER_CONFIG,
    payload,
});

export const updateUserConfigSuccess = (payload: Partial<IUserConfiguration>) => ({
    type: types.UPDATE_USER_CONFIG_SUCCESS,
    payload,
});

export const updateUserConfigFailed = (error: any) => ({
    type: types.UPDATE_USER_CONFIG_FAILED,
    payload: error,
});

export const getGlobalConfig = () => ({
    type: types.GET_GLOBAL_CONFIGURATION,
});

export const resetUserConfig = () => ({
    type: types.RESET_USER_CONFIG,
});

export const getGlobalConfigSuccess = (payload: any) => ({
    type: types.GET_GLOBAL_CONFIGURATION_SUCCESS,
    payload,
});

export const getGlobalConfigFail = (payload: any) => ({
    type: types.GET_GLOBAL_CONFIGURATION_FAIL,
    error: payload,
});

export const getUserLocation = () => ({
    type: types.GET_USER_LOCATION,
});

export const getUserLocationSucess = (payload: any) => ({
    type: types.GET_USER_LOCATION_SUCCESS,
    payload,
});

export const getUserLocationFail = (payload: any) => ({
    type: types.GET_USER_LOCATION_FAIL,
    error: payload,
});

export const setGuidedTourIndex = (payload: number) => ({
    type: types.SET_GUIDED_TOUR_INDEX,
    payload,
});

export const toggleProjectGuide = createAction<IShowProjectGuide>(types.SHOW_PROJECT_GUIDE);

export const setUserCurrentLocation = createAction<IUserLocationFromCloudFront>(types.SET_USER_LOCATION);
