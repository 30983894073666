import produce from 'immer';

import {
    IContestInitialState,
    IGetContestResponse,
    IGetContestsResponse,
    ISetVotingFlow,
    ISubmisisonModalPayload,
    IContestScoreResponse,
    types,
    IGetUnsubmittedProjectsResponse,
    IGetUnsubmittedProjects,
} from './types';
import { IGenericDocumentModifier, ISubmission } from '../../types/global/helper';
import { restoreInitialState } from '../../utils/AppUtils';
import { types as projectTypes } from '../project/types';

const initialState: IContestInitialState = {
    contestErr: null,
    contests: [],
    gettingContests: false,
    gettingRecommendations: false,
    recommendations: [],
    gettingSubmissions: false,
    similarContest: [],
    userContests: [],
    userSubmissions: [],
    isSubmisisonModalOpen: false,
    submittingProject: false,
    rescindingSubmission: false,
    votes: [],
    castingVote: false,
    initializingVote: false,
    loading: false,
    gettingBookmarks: false,
    userBookmarks: [],
    creatingBookmark: false,
    deletingBookmark: false,
    markedForDelete: [],
    redeemableCodes: [],
    startingIndex: 0,
    selectedContestAwardCategories: [],
    selectedContestPrizeWinners: [],
    assigningPrize: false,
    scoreBoard: [],
    unsubmittedProjects: [],
    gettingUnSubmittedProjects: false,
    currentUnsubmittedProjectsPage: 1,
    gettingS3Images: false,
    s3Images: [],
};

const reducer = (state = initialState, action: any) => {
    const { type, payload } = action;
    switch (type) {
        case types.GET_RECOMMENDATION:
            return produce(state, draftState => {
                draftState.gettingRecommendations = true;
            });
        case types.GET_RECOMMENDATION_SUCCESS:
            return produce(state, draftState => {
                draftState.gettingRecommendations = false;
                draftState.recommendations = payload;
            });
        case types.GET_RECOMMENDATION_FAIL:
            return produce(state, draftState => {
                draftState.gettingRecommendations = false;
                draftState.contestErr = payload;
            });
        case types.GET_BOOKMARK:
            return produce(state, draftState => {
                draftState.gettingBookmarks = true;
            });
        case types.GET_BOOKMARK_SUCCESS:
            return produce(state, draftState => {
                draftState.gettingBookmarks = false;
                draftState.userBookmarks = payload;
            });
        case types.GET_BOOKMARK_FAIL:
            return produce(state, draftState => {
                draftState.gettingBookmarks = false;
                draftState.contestErr = payload;
            });
        case types.GET_MY_SUBMISSIONS:
            return produce(state, draftState => {
                draftState.gettingSubmissions = true;
            });
        case types.GET_MY_SUBMISSIONS_SUCCESS:
            return produce(state, draftState => {
                draftState.gettingSubmissions = false;
                draftState.userSubmissions = payload;
            });
        case types.GET_MY_SUBMISSIONS_FAIL:
            return produce(state, draftState => {
                draftState.gettingSubmissions = false;
                draftState.contestErr = payload;
            });
        case types.GET_CONTEST:
            return produce(state, draftState => {
                draftState.loading = true;
                draftState.selectedContest = undefined;
            });
        case types.GET_CONTEST_SUCCESS:
            return produce(state, draftState => {
                const { data } = payload as IGetContestResponse;
                draftState.loading = false;
                draftState.selectedContest = data;
            });
        case types.GET_CONTEST_FAIL:
            return produce(state, draftState => {
                draftState.loading = false;
                draftState.contestErr = payload;
            });
        case types.GET_CONTESTS:
            return produce(state, draftState => {
                draftState.loading = true;
                draftState.references = undefined;
            });
        case types.GET_CONTESTS_SUCCESS:
            return produce(state, draftState => {
                const { data, isFilitered } = payload as IGetContestsResponse;
                draftState.loading = false;
                if (isFilitered) {
                    draftState.similarContest = data;
                } else {
                    draftState.contests = data;
                }
            });
        case types.GET_CONTESTS_FAIL:
            return produce(state, draftState => {
                draftState.loading = false;
                draftState.contestErr = payload;
            });
        case types.SET_SUBMISSION_MODAL_STATE:
            return produce(state, draftState => {
                const { open } = payload as ISubmisisonModalPayload;
                draftState.isSubmisisonModalOpen = open;
            });
        case types.SET_CONTEST_FOR_SUBMISSION:
            return produce(state, draftState => {
                if (!draftState.autoPublishAndSubmit) {
                    draftState.selectedContestForSubmisison = payload;
                }
            });
        case types.SUBMIT_PROJECT:
            return produce(state, draftState => {
                draftState.submittingProject = true;
            });
        case types.SUBMIT_PROJECT_SUCCESS:
            return produce(state, draftState => {
                draftState.userSubmissions = [...draftState.userSubmissions, payload];
                draftState.submittingProject = false;
                /** Reset auto submit */
                draftState.autoPublishAndSubmit = false;
                draftState.selectedContestForSubmisison = undefined;
                /** Remove the unsubmitted project from the list for user */
                draftState.unsubmittedProjects = draftState.unsubmittedProjects.filter(
                    project => project._id !== payload.project._id,
                );
            });
        case types.SUBMIT_PROJECT_FAIL:
            return produce(state, draftState => {
                draftState.submittingProject = false;
                draftState.contestErr = payload;
                draftState.autoPublishAndSubmit = false;
                draftState.selectedContestForSubmisison = undefined;
            });
        case types.RESCIND_SUBMISSION:
            return produce(state, draftState => {
                draftState.rescindingSubmission = true;
            });
        case types.RESCIND_SUBMISSION_SUCCESS:
            return produce(state, draftState => {
                const { _id } = payload as ISubmission;
                draftState.userSubmissions = draftState.userSubmissions.filter(submission => submission._id !== _id);
                draftState.rescindingSubmission = false;
            });
        case types.RESCIND_SUBMISSION_FAIL:
            return produce(state, draftState => {
                draftState.contestErr = payload;
                draftState.rescindingSubmission = false;
            });
        case types.RESET_CONTEST_DATA:
            return restoreInitialState(state, initialState) as IContestInitialState;
        case types.INITIATE_2FA_FOR_VOTE:
            return produce(state, draftState => {
                draftState.initializingVote = true;
            });
        case types.RESET_VOTE:
            return produce(state, draftState => {
                draftState.initializingVote = false;
            });
        case types.INITIATE_2FA_FOR_VOTE_SUCCESS:
            return produce(state, draftState => {
                draftState.initializingVote = false;
            });
        case types.INITIATE_2FA_FOR_VOTE_FAIL:
            return produce(state, draftState => {
                draftState.voteErrMsg = payload;
                draftState.initializingVote = false;
            });
        case types.VOTE_FOR_SUBMISSION:
            return produce(state, draftState => {
                draftState.castingVote = true;
            });
        case types.VOTE_FOR_SUBMISSION_SUCCESS:
            return produce(state, draftState => {
                draftState.votes?.push(payload);
                draftState.castingVote = false;
            });
        case types.VOTE_FOR_SUBMISSION_FAIL:
            return produce(state, draftState => {
                draftState.voteErrMsg = payload;
                draftState.castingVote = false;
            });
        case types.CREATE_BOOKMARK:
            return produce(state, draftState => {
                const { details } = payload;
                const tempPayload = {
                    id: 'temporary',
                    details,
                    deleted: false,
                };
                draftState.userBookmarks.push(tempPayload as any);
                draftState.creatingBookmark = true;
            });
        case types.CREATE_BOOKMARK_SUCCESS:
            return produce(state, draftState => {
                draftState.creatingBookmark = false;
                const index = draftState.userBookmarks.findIndex(
                    e => e.id === 'temporary' && payload.details.model === e.details.model,
                );
                draftState.userBookmarks[index] = payload;
            });
        case types.CREATE_BOOKMARK_FAIL:
            return produce(state, draftState => {
                /** Passing documentId from SAGA as the model being saved */
                const { documentId } = payload;
                draftState.creatingBookmark = false;
                draftState.userBookmarks = draftState.userBookmarks.filter(
                    e => e.id !== 'temporary' && e.details.model !== documentId,
                );
                draftState.contestErr = payload;
            });
        case 'REFRESH_TOKEN':
        case 'ENQUEUE_FAILED_ACTIONS':
            return produce(state, draftState => {
                draftState.creatingBookmark = false;
            });
        case types.DELETE_BOOKMARK:
            return produce(state, draftState => {
                const { documentId } = payload;
                const selectedBookMarkForDelete = draftState.userBookmarks.find(e => e._id === documentId);
                if (selectedBookMarkForDelete && draftState.markedForDelete) {
                    draftState.markedForDelete.push(selectedBookMarkForDelete);
                }
                draftState.userBookmarks = draftState.userBookmarks.filter(e => e._id !== documentId);
                draftState.deletingBookmark = true;
            });
        case types.DELETE_BOOKMARK_SUCCESS:
            return produce(state, draftState => {
                draftState.deletingBookmark = false;
            });
        case types.DELETE_BOOKMARK_FAIL:
            return produce(state, draftState => {
                const { documentId } = payload;
                const selectedDocument = draftState.markedForDelete.find(e => e._id === documentId);
                if (selectedDocument) {
                    draftState.userBookmarks.push(selectedDocument);
                }
                draftState.deletingBookmark = false;
                draftState.contestErr = payload;
            });
        case 'LOGOUT_SUCCESS':
            return produce(state, draftState => {
                draftState.userBookmarks = [];
            });
        case types.GET_USER_CONTEST:
            return produce(state, draftState => {
                if (!payload.skipRefresh) {
                    draftState.loading = true;
                }
            });
        case types.GET_USER_CONTEST_SUCCESS:
            return produce(state, draftState => {
                draftState.loading = false;
                draftState.selectedContestForMetrics = payload;
            });
        case types.GET_USER_CONTEST_FAIL:
            return produce(state, draftState => {
                draftState.loading = false;
            });
        case types.SET_CONTEST_FOR_EDIT:
            return produce(state, draftState => {
                draftState.contestInCreation = payload;
            });
        case types.GET_USER_CONTESTS:
            return produce(state, draftState => {
                draftState.gettingContests = true;
            });
        case types.GET_USER_CONTESTS_SUCCESS:
            return produce(state, draftState => {
                draftState.gettingContests = false;
                draftState.userContests = payload;
            });
        case types.GET_USER_CONTESTS_FAIL:
            return produce(state, draftState => {
                draftState.gettingContests = false;
                draftState.contestErr = payload;
            });
        case types.CREATE_CONTEST:
            return produce(state, draftState => {
                draftState.creatingContest = true;
            });
        case types.CREATE_CONTEST_SUCCESS:
            return produce(state, draftState => {
                draftState.creatingContest = false;
                draftState.userContests = [payload, ...draftState.userContests];
            });
        case types.CREATE_CONTEST_FAIL:
            return produce(state, draftState => {
                draftState.creatingContest = false;
                draftState.contestErr = payload;
            });
        case types.UPDATE_CONTEST:
            return produce(state, draftState => {
                draftState.creatingContest = true;
            });
        case types.UPDATE_CONTEST_SUCCESS:
        case types.PUBLISH_CONTEST_SUCCESS:
        case types.UNPUBLISH_CONTEST_SUCCESS:
            return produce(state, draftState => {
                draftState.creatingContest = false;
                draftState.loading = false;
                const contestInEdit = draftState.userContests.findIndex(contest => contest._id === payload._id);
                if (contestInEdit > -1) {
                    draftState.userContests[contestInEdit] = payload;
                }
                if (draftState.selectedContestForMetrics) {
                    draftState.selectedContestForMetrics.status = payload.status;
                }
            });
        case types.UPDATE_CONTEST_FAIL:
            return produce(state, draftState => {
                draftState.creatingContest = false;
                draftState.contestErr = payload;
            });
        case types.DELETE_CONTEST:
            return produce(state, draftState => {
                draftState.deletingContest = true;
            });
        case types.DELETE_CONTEST_SUCCESS:
            return produce(state, draftState => {
                draftState.deletingContest = false;
                const { documentId } = payload as IGenericDocumentModifier;
                draftState.userContests = draftState.userContests.filter(contest => contest._id !== documentId);
            });
        case types.DELETE_CONTEST_FAIL:
            return produce(state, draftState => {
                draftState.deletingContest = false;
            });
        case types.PUBLISH_CONTEST:
        case types.UNPUBLISH_CONTEST:
            return produce(state, draftState => {
                draftState.loading = true;
            });
        case types.PUBLISH_CONTEST_FAIL:
        case types.UNPUBLISH_CONTEST_FAIL:
            return produce(state, draftState => {
                draftState.loading = false;
            });
        case types.GET_HOST:
            return produce(state, draftState => {});
        case types.GET_HOST_SUCCESS:
            return produce(state, draftState => {});
        case types.GET_HOST_FAIL:
            return produce(state, draftState => {});
        case types.GET_ALL_HOSTS:
            return produce(state, draftState => {});
        case types.GET_ALL_HOSTS_SUCCESS:
            return produce(state, draftState => {});
        case types.GET_ALL_HOSTS_FAIL:
            return produce(state, draftState => {});
        case types.CREATE_HOST:
            return produce(state, draftState => {});
        case types.CREATE_HOST_SUCCESS:
            return produce(state, draftState => {});
        case types.CREATE_HOST_FAIL:
            return produce(state, draftState => {});
        case types.CREATE_PARTNER:
            return produce(state, draftState => {});
        case types.CREATE_PARTNER_SUCCESS:
            return produce(state, draftState => {});
        case types.CREATE_PARTNER_FAIL:
            return produce(state, draftState => {});
        case types.UPDATE_HOST:
            return produce(state, draftState => {});
        case types.UPDATE_HOST_SUCCESS:
            return produce(state, draftState => {});
        case types.UPDATE_HOST_FAIL:
            return produce(state, draftState => {});
        case types.DELETE_HOST:
            return produce(state, draftState => {});
        case types.DELETE_HOST_SUCCESS:
            return produce(state, draftState => {});
        case types.DELETE_HOST_FAIL:
            return produce(state, draftState => {});
        case types.GET_PARTNER:
            return produce(state, draftState => {});
        case types.GET_PARTNER_SUCCESS:
            return produce(state, draftState => {});
        case types.GET_PARTNER_FAIL:
            return produce(state, draftState => {});
        case types.DELETE_PARTNER:
            return produce(state, draftState => {});
        case types.DELETE_PARTNER_SUCCESS:
            return produce(state, draftState => {});
        case types.DELETE_PARTNER_FAIL:
            return produce(state, draftState => {});
        case types.SEARCH_HOST:
            return produce(state, draftState => {
                draftState.searchingHost = true;
                draftState.hostSearchResult = [];
            });
        case types.SEARCH_HOST_SUCCESS:
            return produce(state, draftState => {
                draftState.searchingHost = false;
                draftState.hostSearchResult = payload;
            });
        case types.SEARCH_HOST_FAIL:
            return produce(state, draftState => {
                draftState.searchingHost = false;
            });
        case types.SEARCH_PARTNER:
            return produce(state, draftState => {
                draftState.searchingPartner = true;
                draftState.partnerSearchResult = [];
            });
        case types.SEARCH_PARTNER_SUCCESS:
            return produce(state, draftState => {
                draftState.searchingPartner = false;
                draftState.partnerSearchResult = payload;
            });
        case types.SEARCH_PARTNER_FAIL:
            return produce(state, draftState => {
                draftState.searchingPartner = false;
            });
        case types.ASSIGN_REDEEMABLE_CODE:
            return produce(state, draftState => {
                draftState.assigningCode = true;
            });
        case types.ASSIGN_REDEEMABLE_CODE_SUCCESS:
            return produce(state, draftState => {
                draftState.redeemableCodes?.push(payload);
                draftState.assigningCode = false;
            });
        case types.ASSIGN_REDEEMABLE_CODE_FAIL:
            return produce(state, draftState => {
                draftState.assigningCode = false;
                draftState.contestErr = payload;
            });
        case types.GET_REDEEMABLE_CODE:
            return produce(state, draftState => {
                draftState.fetchingAssignedCodes = true;
            });
        case types.GET_REDEEMABLE_CODE_SUCCESS:
            return produce(state, draftState => {
                draftState.redeemableCodes = payload;
                draftState.fetchingAssignedCodes = false;
            });
        case types.GET_REDEEMABLE_CODE_FAIL:
            return produce(state, draftState => {
                draftState.contestErr = payload;
                draftState.fetchingAssignedCodes = false;
            });
        case types.SET_VOTING_FLOW:
            return produce(state, draftState => {
                const { submissions, showVotingModal } = payload as ISetVotingFlow;
                draftState.submissionsToVoteFor = submissions;
                draftState.showVotingBooth = showVotingModal;
            });
        case types.SET_VOTING_2FA:
            return produce(state, draftState => {
                draftState.showVoting2Fa = payload;
            });
        case types.SET_VOTING_2FA_STARTING_INDEX:
            return produce(state, draftState => {
                draftState.startingIndex = payload;
            });
        case types.GET_AWARD_CATEGORIES_FOR_CONTEST:
        case types.GET_AWARDED_PRIZES_FOR_CONTEST:
            return produce(state, draftState => {
                draftState.gettingContestAwardCategories = true;
            });
        case types.GET_AWARD_CATEGORIES_FOR_CONTEST_SUCCESS:
            return produce(state, draftState => {
                draftState.gettingContestAwardCategories = false;
                draftState.selectedContestAwardCategories = payload;
            });
        case types.GET_AWARD_CATEGORIES_FOR_CONTEST_FAIL:
        case types.GET_AWARDED_PRIZES_FOR_CONTEST_FAIL:
            return produce(state, draftState => {
                draftState.gettingContestAwardCategories = false;
                draftState.contestErr = payload;
            });
        case types.GET_AWARDED_PRIZES_FOR_CONTEST_SUCCESS:
            return produce(state, draftState => {
                draftState.gettingContestAwardCategories = true;
                draftState.selectedContestPrizeWinners = payload;
            });
        case types.AWARD_PRIZE:
            return produce(state, draftState => {
                draftState.assigningPrize = true;
            });
        case types.AWARD_PRIZE_SUCCESS:
            return produce(state, draftState => {
                draftState.assigningPrize = false;
                draftState.selectedContestPrizeWinners.push(payload);
            });
        case types.AWARD_PRIZE_FAIL:
            return produce(state, draftState => {
                draftState.assigningPrize = false;
                draftState.contestErr = payload;
            });
        case types.SET_SUBMISSION_PROMPT:
            return produce(state, draftState => {
                draftState.promptSubmission = payload;
            });
        case types.SET_AUTO_PUBLISH_AND_SUBMIT:
            return produce(state, draftState => {
                draftState.promptSubmission = false;
                draftState.autoPublishAndSubmit = payload;
            });
        case types.GET_ALL_SUBMISSIONS:
            return produce(state, draftState => {
                draftState.fetchingScoreBoard = true;
            });
        case types.GET_ALL_SUBMISSIONS_SUCCESS:
            return produce(state, draftState => {
                const { details, submissions } = payload as IContestScoreResponse;
                const { page } = details;
                if (page && page > 1) {
                    draftState.scoreBoard = [...draftState.scoreBoard, ...submissions];
                } else {
                    draftState.scoreBoard = submissions;
                }
                draftState.fetchingScoreBoard = false;
            });
        case types.GET_ALL_SUBMISSIONS_FAIL:
            return produce(state, draftState => {
                draftState.fetchingScoreBoard = false;
                draftState.contestErr = payload;
            });
        case types.CREATE_SCORE_CATEGORY:
            return produce(state, draftState => {
                draftState.creatingScorecategory = true;
            });
        case types.CREATE_SCORE_CATEGORY_SUCCESS:
            return produce(state, draftState => {
                draftState.creatingScorecategory = false;
                if (draftState.contestInCreation) {
                    draftState.contestInCreation?.score_criteria!.push(payload);
                }
            });
        case types.CREATE_SCORE_CATEGORY_FAIL:
            return produce(state, draftState => {
                draftState.creatingScorecategory = false;
                draftState.contestErr = payload;
            });
        case types.SET_FOCUS_CAMPAIGN:
            return produce(state, draftState => {
                draftState.focusCampaign = payload;
            });
        case types.GET_S3_IMAGES_SUCCESS:
            return produce(state, draftState => {
                draftState.s3Images = payload;
                draftState.gettingS3Images = false;
            });
        case types.SET_EMAIL_IMAGE:
            return produce(state, draftState => {
                draftState.emailImage = payload;
            });
        case types.GET_S3_IMAGES_FAIL:
            return produce(state, draftState => {
                draftState.gettingS3Images = false;
            });
        case types.GET_S3_IMAGES:
            return produce(state, draftState => {
                draftState.gettingS3Images = true;
            });
        case types.GET_CONTEST_REFERENCES:
            return produce(state, draftState => {
                draftState.gettingReferences = true;
            });
        case types.GET_CONTEST_REFERENCES_SUCCESS:
            return produce(state, draftState => {
                draftState.references = payload;
                draftState.gettingReferences = false;
            });
        case types.GET_CONTEST_REFERENCES_FAIL:
            return produce(state, draftState => {
                draftState.gettingReferences = false;
            });
        case types.GET_USERS_UNSUBMITTED_PROJECTS:
            return produce(state, draftState => {
                const { page } = payload as IGetUnsubmittedProjects;
                /** This means we are fetching more */
                if (page && page < 1) {
                    draftState.gettingUnSubmittedProjects = true;
                }
            });
        case types.GET_USERS_UNSUBMITTED_PROJECTS_SUCCESSS:
            return produce(state, draftState => {
                draftState.gettingUnSubmittedProjects = false;
                const { current_page, results } = payload as IGetUnsubmittedProjectsResponse;
                if (current_page && current_page > 1) {
                    draftState.unsubmittedProjects = [...draftState.unsubmittedProjects, ...results];
                } else {
                    draftState.unsubmittedProjects = results;
                }
                draftState.fetchingScoreBoard = false;
                draftState.currentUnsubmittedProjectsPage = current_page;
            });
        case types.GET_USERS_UNSUBMITTED_PROJECTS_FAIL:
            return produce(state, draftState => {
                draftState.gettingUnSubmittedProjects = false;
                draftState.unsubmittedProjectErrMsg = payload;
            });
        case types.SET_CATEGORY_FOR_SUBMISSION:
            return produce(state, draftState => {
                draftState.selectedCategoryForSubmission = payload;
            });
        case projectTypes.GET_PROJECT:
        case projectTypes.GET_PROJECT_DETAILS:
            return produce(state, draftState => {
                draftState.submissionsToVoteFor = [];
            });
        default:
            return state;
    }
};

export { reducer };
