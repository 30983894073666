import { IProject } from '../../types/global/helper';

export const types = {
    GET_CASTING_ROLES: 'GET_CASTING_ROLES',
    GET_CASTING_ROLES_SUCCESS: 'GET_CASTING_ROLES_SUCCESS',
    GET_CASTING_ROLES_FAIL: 'GET_CASTING_ROLES_FAIL',
    GET_CURRENT_USER_REVIEWS: 'GET_CURRENT_USER_REVIEWS',
    GET_CURRENT_USER_REVIEWS_SUCCESS: 'GET_CURRENT_USER_REVIEWS_SUCCESS',
    GET_CURRENT_USER_REVIEWS_FAIL: 'GET_CURRENT_USER_REVIEWS_FAIL',
    GET_CURRENT_USER_WATCHLIST: 'GET_CURRENT_USER_WATCHLIST',
    GET_CURRENT_USER_WATCHLIST_SUCCESS: 'GET_CURRENT_USER_WATCHLIST_SUCCESS',
    GET_CURRENT_USER_WATCHLIST_FAILED: 'GET_CURRENT_USER_WATCHLIST_FAILED',
    GET_CURRENT_USER_RECENTLY_VIEWED: 'GET_CURRENT_USER_RECENTLY_VIEWED',
    GET_CURRENT_USER_RECENTLY_VIEWED_SUCCESS: 'GET_CURRENT_USER_RECENTLY_VIEWED_SUCCESS',
    GET_CURRENT_USER_RECENTLY_VIEWED_FAIL: 'GET_CURRENT_USER_RECENTLY_VIEWED_FAIL',
    GET_CURRENT_USER_AUDITIONS: 'GET_MY_AUDITIONS',
    GET_CURRENT_USER_AUDITIONS_SUCCESS: 'GET_MY_AUDITIONS_SUCCESS',
    GET_CURRENT_USER_AUDITIONS_FAIL: 'GET_MY_AUDITIONS_FAIL',
    GET_CONTRIBUTIONS: 'GET_CONTRIBUTIONS',
    GET_CONTRIBUTIONS_SUCCESS: 'GET_CONTRIBUTIONS_SUCCESS',
    GET_CONTRIBUTIONS_FAIL: 'GET_CONTRIBUTIONS_FAIL',

    GET_ACCOUNT_DETAILS: 'GET_ACCOUNT_DETAILS',
    GET_ACCOUNT_DETAILS_SUCCESS: 'GET_ACCOUNT_DETAILS_SUCCESS',
    GET_ACCOUNT_DETAILS_FAIL: 'GET_ACCOUNT_DETAILS_FAIL',

    ACCOUNT_REFRESH: 'ACCOUNT_REFRESH',
    ACCOUNT_REFRESH_SUCCESS: 'ACCOUNT_REFRESH_SUCCESS',
    ACCOUNT_REFRESH_FAIL: 'ACCOUNT_REFRESH_FAIL',

    GET_USER_AUDME_PROFILES: 'GET_USER_AUDME_PROFILES',
    GET_USER_AUDME_PROFILES_SUCCESS: 'GET_USER_AUDME_PROFILES_SUCCESS',
    GET_USER_AUDME_PROFILES_FAIL: 'GET_USER_AUDME_PROFILES_FAIL',

    GET_USER_DASHBOARD: 'GET_USER_DASHBOARD',
    GET_USER_DASHBOARD_SUCCESS: 'GET_USER_DASHBOARD_SUCCESS',
    GET_USER_DASHBOARD_FAIL: 'GET_USER_DASHBOARD_FAIL',

    GET_USER_LIKES: 'GET_USER_LIKES',
    GET_USER_LIKES_SUCCESS: 'GET_USER_LIKES_SUCCESS',
    GET_USER_LIKES_FAIL: 'GET_USER_LIKES_FAIL',

    GET_SINGLE_LIKE: 'GET_SINGLE_LIKE',
    GET_SINGLE_LIKE_SUCCESS: 'GET_SINGLE_LIKE_SUCCESS',
    GET_SINGLE_LIKE_FAIL: 'GET_SINGLE_LIKE_FAIL',
    RESET_AUDME_PROFILE: 'RESET_AUDME_PROFILE',

    REFRESH_USER_DASHBOARD: 'REFRESH_USER_DASHBOARD',
};

export type LikeCategory = 'movie' | 'review' | 'act' | 'project' | 'contest' | 'comment';

export interface ILike {
    created_at: string;
    like_type: string;
    _id: string;
    user: string;
    created_by: string;
    project: IProject;
    like_category: LikeCategory;
    comment?: string;
    /** The below allows us to make real time interaction */
    optimistic?: boolean;
}

export interface IGetUserLikes {
    /** Pass the below as `&project=projectId` to work */
    param: string;
}

export type TLikeOrigin = 'home' | 'details' | 'profile' | 'watch';

export interface ILikeResponse {
    data: ILike;
    like_origin?: TLikeOrigin;
    documentId?: string;
}
