export const types = {
    GET_SUBSCRIPTION_PLANS: 'GET_SUBSCRIPTION_PLANS',
    GET_SUBSCRIPTION_PLANS_SUCCESS: 'GET_SUBSCRIPTION_PLANS_SUCCESS',
    GET_SUBSCRIPTION_PLANS_FAIL: 'GET_SUBSCRIPTION_PLANS_FAIL',
    SUBSCRIBE: 'SUBSCRIBE',
    SUBSCRIBE_SUCCESS: 'SUBSCRIBE_SUCCESS',
    SUBSCRIBE_FAIL: 'SUBSCRIBE_FAIL',
    CANCEL_SUBSCRIPTION: 'CANCEL_SUBSCRIPTION',
    CANCEL_SUBSCRIPTION_SUCCESS: 'CANCEL_SUBSCRIPTION_SUCCESS',
    CANCEL_SUBSCRIPTION_FAIL: 'CANCEL_SUBSCRIPTION_FAIL',
    GET_SUBSCRIPTION: 'GET_SUBSCRIPTION',
    GET_SUBSCRIPTION_SUCCESS: 'GET_SUBSCRIPTION_SUCCESS',
    GET_SUBSCRIPTION_FAIL: 'GET_SUBSCRIPTION_FAIL',
    UPDATE_SUBSCRIPTION: 'UPDATE_SUBSCRIPTION',
    UPDATE_SUBSCRIPTION_SUCCESS: 'UPDATE_SUBSCRIPTION_SUCCESS',
    UPDATE_SUBSCRIPTION_FAIL: 'UPDATE_SUBSCRIPTION_FAIL',
    SET_SUBSCRIPTION: 'SET_SUBSCRIPTION',
    SET_SUBSCRIPTION_SUCCESS: 'SET_SUBSCRIPTION_SUCCESS',
    SET_SUBSCRIPTION_FAIL: 'SET_SUBSCRIPTION_FAIL',
    RESET_SUBSCRIPTION_DATA: 'RESET_SUBSCRIPTION_DATA',
}