import React from 'react';
import { ThemeContext } from '../../module/ThemeManager';
import { Box, Stack, Skeleton } from '@mui/material';
import { theme } from '../../constants/theme';

const GenreTagShimmer = () => {
    return (
        <ThemeContext.Consumer>
            {({ themeStyle, device }) => {
                const value = device.isDesktop ? 10 : 5;

                return (
                    <Box>
                        <Stack direction="row" justifyContent="space-between" marginBottom={3}>
                            <Skeleton
                                variant="text"
                                sx={{
                                    fontSize: '2rem',
                                    width: '100px',
                                    backgroundColor: theme[themeStyle].gray_text,
                                }}
                            />
                            <Skeleton
                                variant="text"
                                sx={{ fontSize: '2rem', width: '100px', backgroundColor: theme[themeStyle].gray_text }}
                            />
                        </Stack>

                        <Stack direction="row" spacing={2} marginBottom={5}>
                            {Array.from(new Array(value)).map((_, index) => (
                                <Skeleton
                                    key={index}
                                    variant="rounded"
                                    width={100}
                                    height={35}
                                    sx={{ borderRadius: 100, backgroundColor: theme[themeStyle].gray_text }}
                                />
                            ))}
                        </Stack>
                    </Box>
                );
            }}
        </ThemeContext.Consumer>
    );
};

export { GenreTagShimmer };
