import { useEffect } from 'react';
import { appLaunchFlow } from '../../redux/authentication/action';
import { useDispatch } from 'react-redux';

const APILoader = () => {
    const normalDispatch = useDispatch();
    useEffect(() => {
        normalDispatch(appLaunchFlow());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return null;
};

export { APILoader };
