import React from 'react';
import { ThemeContext } from '../../module/ThemeManager';
import { theme } from '../../constants/theme';
import { Box, Grid, Skeleton, Stack } from '@mui/material';

const SuggestedUsersShimmer = () => {
    return (
        <ThemeContext.Consumer>
            {({ themeStyle }) => {
                return (
                    <Grid item container height={'fit-content'} overflow={'hidden'} md={3} sm={3} lg={4}>
                        <Box
                            sx={{
                                width: '100%',
                                overflowY: 'scroll',
                            }}>
                            <Box position={'relative'}>
                                <Stack direction={'column'}>
                                    <Skeleton
                                        variant="rounded"
                                        height="60px"
                                        sx={{
                                            width: '100%',
                                            marginLeft: { sm: '0px', md: '20px', lg: '50px' },
                                            backgroundColor: theme[themeStyle].gray_text,
                                        }}
                                    />

                                    <Skeleton
                                        variant="text"
                                        sx={{
                                            fontSize: '1rem',
                                            width: '120px',
                                            backgroundColor: theme[themeStyle].darkGray60,
                                            position: 'absolute',
                                            top: '20px',
                                            left: { sm: '0px', md: '20px', lg: '50px' },
                                            marginLeft: { sm: '10px', lg: '20px' },
                                        }}
                                    />
                                    <Box>
                                        <Skeleton
                                            variant="rounded"
                                            height="100vh"
                                            sx={{
                                                width: '100%',
                                                marginLeft: { sm: '20px', lg: '50px' },
                                            }}
                                        />
                                    </Box>
                                    <Box sx={{ position: 'absolute', top: '80px', left: { md: '30px', lg: '80px' } }}>
                                        <Grid container spacing={2}>
                                            {Array.from(new Array(4)).map((_, index) => (
                                                <Grid item xs={'auto'} sm={'auto'} md={'auto'} lg={'auto'} key={index}>
                                                    <Stack
                                                        direction={{
                                                            xs: 'column',
                                                            sm: 'row',
                                                            md: 'row',
                                                            lg: 'row',
                                                        }}
                                                        position={'relative'}>
                                                        <Skeleton
                                                            variant="rounded"
                                                            height={'220px'}
                                                            sx={{
                                                                width: {
                                                                    xs: '100%',
                                                                    sm: '230px',
                                                                    md: '180px',
                                                                    lg: '230px',
                                                                },
                                                                backgroundColor: theme[themeStyle].gray_text,
                                                                borderRadius: '6px',
                                                                marginBottom: '5px',
                                                            }}
                                                        />
                                                        <Skeleton
                                                            variant="text"
                                                            sx={{
                                                                fontSize: '1rem',
                                                                width: '50px',
                                                                backgroundColor: theme[themeStyle].darkGray60,
                                                                position: 'absolute',
                                                                left: { xs: 30, sm: 20, md: 20, lg: 20 },
                                                                bottom: 40,
                                                            }}
                                                        />
                                                        <Skeleton
                                                            variant="text"
                                                            sx={{
                                                                fontSize: '1rem',
                                                                width: '120px',
                                                                backgroundColor: theme[themeStyle].darkGray60,
                                                                position: 'absolute',
                                                                left: { xs: 30, sm: 20, md: 20, lg: 20 },
                                                                bottom: 20,
                                                            }}
                                                        />
                                                    </Stack>
                                                </Grid>
                                            ))}
                                        </Grid>
                                    </Box>
                                </Stack>
                            </Box>
                        </Box>
                    </Grid>
                );
            }}
        </ThemeContext.Consumer>
    );
};
export { SuggestedUsersShimmer };
