import { parsePhoneNumber } from 'awesome-phonenumber';
const phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance();
const valid = require('card-validator');

const MAX_YEAR = 2027;

export const validate = {
    validateEmail: (email: string) => {
        const regExp = new RegExp(
            '[a-zA-Z0-9\\+\\.\\_\\%\\-\\+]{1,256}' +
                '\\@' +
                '[a-zA-Z0-9][a-zA-Z0-9\\-]{0,64}' +
                '(' +
                '\\.' +
                '[a-zA-Z0-9][a-zA-Z0-9\\-]{0,25}' +
                ')+',
        );
        return regExp.test(email);
    },

    validatePasswordLength: (password: string) => {
        const regExp = new RegExp('.{8,}$');
        return regExp.test(password);
    },

    validatePasswordCase: (password: string) => {
        const regExp = new RegExp('^(?=.*?[A-Z])(?=.*?[a-z])');
        return regExp.test(password);
    },

    validatePasswordNumber: (password: string) => {
        const regExp = new RegExp('^(?=.*?[0-9])');
        return regExp.test(password);
    },

    validatePhoneNumber: (rawNumber: any, countryCode?: any) => {
        try {
            const pn = parsePhoneNumber(`+${rawNumber}`);
            return pn.valid;
        } catch (error) {
            return false;
        }
    },

    validateCreditCardNumber: (rawNumber: any) => {
        const numberValidation = valid.number(rawNumber);
        return numberValidation.isValid;
    },

    validateCreditCardMonthYear: (rawMonthYear: any) => {
        const dateValidation = valid.expirationDate(rawMonthYear, MAX_YEAR);
        return dateValidation.isValid;
    },

    validateCVC: (cvc: any) => {
        const cvcValidation = valid.cvv(cvc, 3);
        return cvcValidation.isValid;
    },

    validateCardHolderName: (name: any) => {
        const nameValidation = valid.cardholderName(name);
        return nameValidation.isValid;
    },

    validateUsernameCharacters: (userName: string) => {
        const usernamePattern = /^[a-zA-Z0-9_]+$/;
        return usernamePattern.test(userName);
    },

    validateUsernameLength: (userName: string) => {
        return userName.length > 4;
    },
    validateUrl: (url: string) => {
        const urlRegex =
            //eslint-disable-next-line
            /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;
        return urlRegex.test(url);
    },
};

export { phoneUtil };
