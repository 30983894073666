/* eslint-disable import/no-cycle */
import { takeLatest, call, all, put } from 'redux-saga/effects';
import { types } from './types';
import { getSplashImageSuccess, getSplashImageFailed } from './actions';
import { api } from '../../configurations/api';
import { splashUrl } from '../../configurations/api/url';
import { SentryCapture } from '../../analytics/Sentry';

//* ********************** WORKERS ************************
function* getSplashImage(): any {
    try {
        const response = yield call(api, splashUrl, 'GET', null, 0, 0, false);
        yield put(getSplashImageSuccess(response.data));
    } catch (error) {
        SentryCapture(error, 'error');
        console.log('SPLASH IMAGE ERROR', error);
        yield put(getSplashImageFailed());
    }
}

//* ********************** WATCHERS ************************
function* getSplashImageWatcher() {
    yield takeLatest(types.GET_SPLASH_IMAGES, getSplashImage);
}

export default function* splashSaga() {
    yield all([getSplashImageWatcher()]);
}
