import { IContest } from '../../types/global/helper';
import { SearchResponse } from '../discovery/search.types';

export const types = {
    GET_COMING_SOON: 'GET_COMING_SOON',
    GET_COMING_SOON_SUCCESS: 'GET_COMING_SOON_SUCCESS',
    GET_COMING_SOON_FAIL: 'GET_COMING_SOON_FAIL',

    SEARCH_COMING_SOON: 'SEARCH_COMING_SOON',
    SEARCH_COMING_SOON_SUCCESS: 'SEARCH_COMING_SOON_SUCCESS',
    SEARCH_COMING_SOON_FAIL: 'SEARCH_COMING_SOON_FAIL',
};

export interface IComingSoonInitialState {
    comingSoon: IContest[];
    trending: IContest[];
    loadingComingSoon?: boolean;
    errMsg?: any;
}

export interface IComingSoonResponse {
    coming_soon: IContest[];
    trending: IContest[];
}

export interface IComingSoonServerResponse {
    status: string;
    data: IComingSoonResponse;
}

export interface ISearchResponse {
    searchTerm: string;
    searchResult: SearchResponse;
}

export interface ISearchPayload {
    searchTerm: string;
    page?: number;
    limit?: number;
}
