import React, { useState, useEffect, useRef } from 'react';
import { showToastMessage } from '../../utils/AppUtils';
import { Id, toast } from 'react-toastify';
import WifiConnectivityIcon from '../CustomSVGIcons/WifiConnectivityIcon';

export interface NetworkStatusProps {
    isOnline: boolean;
    toggleOnlineStatus: () => void;
}

interface NetworkInformation extends EventTarget {
    readonly effectiveType: 'slow-2g' | '2g' | '3g' | '4g';
    addEventListener: (type: 'change', listener: () => void) => void;
    removeEventListener: (type: 'change', listener: () => void) => void;
}

interface Navigator {
    connection?: NetworkInformation;
    mozConnection?: NetworkInformation;
    webkitConnection?: NetworkInformation;
}

const withNetworkStatus = <P extends object>(WrappedComponent: React.ComponentType<P & NetworkStatusProps>) => {
    const WithNetworkStatus: React.FC<P> = props => {
        const [isOnline, setIsOnline] = useState<boolean>(navigator.onLine);
        const [isSlowNetwork, setIsSlowNetwork] = useState(false);
        const customNavigator = navigator as Navigator;
        const toastId = useRef<Id>();
        const speedId = useRef<Id>();

        useEffect(() => {
            const updateNetworkStatus = () => {
                const connection =
                    customNavigator.connection || customNavigator.mozConnection || customNavigator.webkitConnection;
                if (connection) {
                    const effectiveType = connection.effectiveType;
                    if (['2g', 'slow-2g', '3g'].includes(effectiveType)) {
                        setIsSlowNetwork(true);
                        const existingToast = speedId.current && toast.isActive(speedId.current);
                        if (!existingToast) {
                            speedId.current = showToastMessage(
                                'Your network strength is less than ideal. You might experience site lags',
                                'warning',
                                false,
                                () => {},
                                undefined,
                                undefined,
                                'bottom-left',
                                true,
                                false,
                                false,
                                false,
                            );
                        }
                    } else {
                        setIsSlowNetwork(false);
                        if (speedId.current) {
                            toast.update(speedId.current, {
                                render: 'Network strength fully restored',
                                type: 'success',
                                autoClose: 5000,
                                hideProgressBar: false,
                                icon: <WifiConnectivityIcon height={20} width={20} />,
                            });
                        }
                    }
                }
            };

            if ('connection' in navigator) {
                const connection =
                    customNavigator.connection || customNavigator.mozConnection || customNavigator.webkitConnection;
                if (connection) {
                    connection.addEventListener('change', updateNetworkStatus);
                    return () => connection.removeEventListener('change', updateNetworkStatus);
                }
            }
        }, [isSlowNetwork, customNavigator]);

        useEffect(() => {
            const handleNetworkChange = () => {
                setIsOnline(navigator.onLine);
                if (!navigator.onLine) {
                    const existingToast = toastId.current && toast.isActive(toastId.current);
                    if (!existingToast) {
                        toastId.current = showToastMessage(
                            'You are currently offline',
                            'warning',
                            false,
                            () => {},
                            undefined,
                            undefined,
                            'bottom-left',
                            true,
                            false,
                            false,
                            false,
                        );
                    }
                } else if (toastId.current) {
                    toast.update(toastId.current, {
                        render: 'You are now online',
                        type: 'success',
                        autoClose: 5000,
                        hideProgressBar: false,
                        icon: <WifiConnectivityIcon height={20} width={20} />,
                    });
                }
            };

            window.addEventListener('online', handleNetworkChange);
            window.addEventListener('offline', handleNetworkChange);

            return () => {
                window.removeEventListener('online', handleNetworkChange);
                window.removeEventListener('offline', handleNetworkChange);
            };
        }, [isOnline]);

        const toggleOnlineStatus = () => {
            setIsOnline(prevIsOnline => !prevIsOnline);
        };

        return <WrappedComponent isOnline={isOnline} toggleOnlineStatus={toggleOnlineStatus} {...(props as P)} />;
    };

    return WithNetworkStatus;
};

export { withNetworkStatus };
