import produce from 'immer';
import { ILink, ILinkInitialState, ILinkPayload, types } from './types';

const initialState: ILinkInitialState = {
    links: [],
    loading: false,
    errMsg: null,
};

const reducer = (state = initialState, action: any) => {
    const { type, payload } = action;

    switch (type) {
        case types.CREATE_LINK:
        case types.UPDATE_LINK:
        case types.GET_MY_LINKS:
        case types.GET_LINK:
            return produce(state, draftState => {
                draftState.loading = true;
                draftState.selectedLink = undefined;
            });
        case types.CREATE_LINK_SUCCESS:
            return produce(state, draftState => {
                draftState.links.unshift(payload);
                draftState.loading = false;
            });
        case types.UPDATE_LINK_SUCCESS:
            return produce(state, draftState => {
                const { _id } = payload as ILink;
                const index = draftState.links.findIndex(link => link._id === _id);
                if (index > -1) {
                    draftState.links[index] = payload;
                }
                draftState.loading = false;
            });
        case types.DELETE_LINK_SUCCESS:
            return produce(state, draftState => {
                const { linkId } = payload as ILinkPayload;
                const links = draftState.links.filter(links => links.unique_id !== linkId);
                draftState.links = links;
                draftState.loading = false;
            });
        case types.GET_MY_LINKS_SUCCESS:
            return produce(state, draftState => {
                draftState.links = payload;
                draftState.loading = false;
            });
        case types.GET_LINK_SUCCESS:
            return produce(state, draftState => {
                draftState.selectedLink = payload;
                draftState.loading = false;
            });
        case types.GET_LINK_FAIL:
            return produce(state, draftState => {
                draftState.selectedLink = undefined;
                draftState.errMsg = payload;
                draftState.loading = false;
            });
        case types.CREATE_LINK_FAIL:
        case types.DELETE_LINK_FAIL:
        case types.GET_MY_LINKS_FAIL:
        case types.UPDATE_LINK_FAIL:
            return produce(state, draftState => {
                draftState.loading = false;
                draftState.errMsg = payload;
            });
        default:
            return state;
    }
};

export { reducer };
