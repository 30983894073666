import produce from 'immer';
import { IPlayerInitialState } from './model';
import { types } from './types';

const initialState: IPlayerInitialState = {
    volume: 0.5,
    quality: 'auto',
    speed: 1,
    mute: true,
};

/** This will in the future allow us maanage player more efficietntly
 * including ad placement e.t.c
 */
const reducer = (state = initialState, action: any) => {
    const { type, payload } = action;
    switch (type) {
        case types.SET_PLAYER_VOLUME:
            return produce(state, draft => {
                draft.volume = payload;
            });
        case types.SET_PLAYER_QUALITY:
            return produce(state, draft => {
                draft.quality = payload;
            });
        case types.SET_PLAYER_SPEED:
            return produce(state, draft => {
                draft.speed = payload;
            });
        case types.SET_MUTE:
            return produce(state, draft => {
                draft.mute = payload;
            });
        default:
            return state;
    }
};

export { reducer };
