import produce from 'immer';
import { IProgressReporter, ISaveUpload, IUploadInitialState, types } from './types';

const initialState: IUploadInitialState = {
    currentChunkIndex: 0,
    isUploadInProgress: false,
    paused: false,
    progress: 0,
    uploadMessage: 'Uploading Project In Progress ... Do NOT refresh page.',
};

const reducer = (state = initialState, action: any) => {
    const { type, payload } = action;
    switch (type) {
        case types.START_UPLOAD:
        case types.UPLOAD_FAILED:
        case types.UPLOAD_COMPLETE:
            return produce(state, draft => {
                draft.paused = false;
                draft.progress = 0;
                draft.isUploadInProgress = true;
                draft.currentChunkIndex = 0;
                draft.uploadParts = [];
                draft.currentUploadPayload = payload;
            });
        case types.PAUSE_UPLOAD:
            return produce(state, draft => {
                draft.paused = true;
                draft.uploadMessage = payload;
            });
        case types.RESUME_UPLOAD:
            return produce(state, draft => {
                draft.paused = false;
                draft.uploadMessage = payload;
            });
        case types.GET_UPLOAD_SESSION:
            return produce(state, draft => {
                draft.currentUploadPayload = payload;
            });
        case types.GET_UPLOAD_SESSION_SUCCESS:
            return produce(state, draft => {
                draft.currentSessionData = payload;
            });
        case types.GET_UPLOAD_SESSION_FAIL:
            return produce(state, draft => {
                draft.errorMsg = payload;
                draft.currentUploadPayload = undefined;
            });
        case types.PROGRESS_REPORT:
            return produce(state, draft => {
                const { chunk, progress, totalChunk } = payload as IProgressReporter;
                draft.progress = progress;
                draft.currentChunkIndex = chunk;
                draft.totalChunk = totalChunk;
            });
        case types.SAVE_UPLOAD:
            return produce(state, draft => {
                const { currentChunkIndex, uploadedParts } = payload as ISaveUpload;
                draft.uploadParts = uploadedParts;
                draft.currentChunkIndex = currentChunkIndex;
            });
        case types.START_UPLOAD_FAIL:
            return produce(state, draft => {
                draft.errorMsg = payload;
                draft.currentUploadPayload = undefined;
            });
        case types.TERMINATE_UPLOAD:
            return produce(state, draft => {
                draft.errorMsg = null;
                draft.uploadParts = [];
                draft.currentChunkIndex = 0;
                draft.uploadMessage = undefined;
                draft.isUploadInProgress = false;
                draft.paused = false;
                draft.totalChunk = 0;
                draft.currentUploadPayload = undefined;
            });
        case types.COMPLETE_MULTIPART_UPLOAD_SUCCESS:
            return produce(state, draft => {
                draft.isUploadInProgress = false;
                draft.progress = 0;
                draft.currentUploadPayload = undefined;
                draft.totalChunk = 0;
                draft.uploadMessage = undefined;
            });
        case types.CLEAR_MESSAGE:
            return produce(state, draft => {
                draft.uploadMessage = undefined;
            });
        default:
            return state;
    }
};

export { reducer };
