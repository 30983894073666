import React, { Suspense, lazy, useCallback, useEffect, useLayoutEffect, useReducer, useRef, useState } from 'react';
import { IAppLayout } from '../../components/EsSidebar/EsSideBarAndHeader';
import { Outlet, useLocation, useSearchParams } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
// import { easings, useSpring } from '@react-spring/web';
import { AnimatedBox } from '../../components/AnimatedComponents/AnimatedView';
import { useThemeContext } from '../../hook/useThemeManager';
import { EsScrollToTop } from '../../Router/EsScrollToTop';
import { batch, useDispatch, useSelector } from 'react-redux';
import { logout } from '../../redux/authentication/action';
import {
    campaignSubmissionModalOpenerSelector,
    contestPhaseDateSelector,
    creatorModalSelector,
    creatorModalVisibleSelector,
    currentFiltersSelector,
    currentProjectInCreationSelector,
    currentSubmissionSelector,
    filterSubCategorySelector,
    gettingNotificationsSelector,
    inAppNotificationSelector,
    initialContestEditingDataSelector,
    isDrawerOpen,
    isProjectProcessingSelector,
    isSearchBoxOpenSelector,
    isUserJurySelector,
    isUsersFirstProjectSelector,
    isVotingEnabledSelector,
    likeActionInProgressSelector,
    mediaControlConfigurationSelector,
    mediaForgeMessageSelector,
    mediaForgeReportSelector,
    nebulaSuggestionSelector,
    playerPlaylistTypeSelector,
    playerQuerySelector,
    projectQuerySeletcor,
    reportModalSelector,
    searchResultSelector,
    searchingStatusSelector,
    selectedCampaignForSubmissionSelector,
    selectedSuggestionSelector,
    showAppGuideSelector,
    showFirstProjectCongratulatorySelector,
    showIntroductionFlowSelector,
    showProjectCreatorGuideSelector,
    showProjectCreatorSuggstionBoxSelector,
    suggestedThspiansSelector,
    toggleAccountModalSelector,
    toggleNotificationSelector,
    toggleCookiesDrawerSelector,
    toggleStateSelector,
    userFollowingSelector,
    userLikesSelector,
    userNotificationCountSelector,
    userNotificationSelector,
    userVotesSelector,
    votingBoothItemsSelector,
    nebulaMuteSelector,
    shareModalStatusSelector,
    sharePayloadSelector,
    systemLoadingSelector,
    isProjectProcessingDoneSelector,
    showProjectSubmissionPromptSelector,
    isUserCastingDirectorSelector,
    liveCampaignsSelector,
    focusCampiagnSelector,
    uploadPayloadSelector,
} from '../../selectors/selectors';
import {
    clearFilter,
    setFilter,
    setFilterSubCategory,
    setShareModalStatus,
    setShowProjectCreatorGuide,
    setShowSearchBox,
    setUsersFirstProjectSuggestionBox,
    toggleAccountModal,
    toggleCookiesModal,
    toggleCreatorModal,
    toggleDrawer,
    toggleFiler,
    toggleNotification,
    toggleToast,
} from '../../redux/appConfigurator/action';
import { FilterItem, FilterTypes, IFilter, filters, mainCategorySelector } from '../../data/filters/discovery.data';
import _, { isArray, isEmpty } from 'lodash';
import {
    canLoadMoreFeedSelector,
    currentSelectedVideoComments,
    currentVideoDetailsSelector,
    currentVideoSelector,
    feedSelector,
    followingSelector,
    horizontalPlayerSelector,
    isSelectedFilterEmptySelector,
    playlistSelector,
    searchSuggestionsSelector,
    selectedActSelector,
    selectedProjectComents,
    toastSelector,
    userProfileSelector,
    verticalPlayerSelector,
} from './selectors';
import { getMyActivities, getSearchSuggestions, hideMyActivity } from '../../redux/activity/actions';
import {
    bytesToMB,
    catchAsync,
    getJWtDetails,
    parseFileFormats,
    replacePageValue,
    showToastMessage,
    useDispatchPromise,
} from '../../utils/AppUtils';
import { filter, searchDiscovery, setViewAll } from '../../redux/discovery/actions';
import { navigate } from '../../redux/navigator/action';
import { ROUTES } from '../../types/global/routes.types';
import { CreatorType } from '../../redux/appConfigurator/types';
import { IContentCreator } from '../../components/ContentCreator/ContentCreator';
import Dropzone, { FileRejection } from 'react-dropzone';
import { FONT_ENUM } from '../../types/global/fonts.types';
import { theme } from '../../constants/theme';
import { strings } from '../../constants';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { IVerticalVideoPlayer } from '../../components/VideoPlayer/VerticalVideoPlayer';
import { IVideoPlayerModal } from '../../components/VideoPlayer/HorizontalVideoPlayer';
import {
    createProject,
    deleteComment,
    deleteLike,
    getFeed,
    getProject,
    getProjectDetails,
    getProjects,
    leaveComment,
    likeComment,
    likeProject,
    mediaForgeWorker,
    publishProject,
    selectNebulaSuggestion,
    setAutoPlay,
    setPlayerQuery,
    setSubmission,
    setmedia,
    updateComment,
} from '../../redux/project/actions';
import {
    IContest,
    IProject,
    ISubmission,
    ITopUser,
    IUserSummary,
    Likes,
    TComment,
    UserSummary,
    CreatorLocation,
} from '../../types/global/helper';
import { INebulaSuggestion, MediaType } from '../../redux/project/types';
import { CellType } from '../../components/EsSidebar/EsSearch/SearchCell';
import { ActivityResponse, SearchSuggestion } from '../../redux/activity/types';
import { replaceRouteParam } from '../../utils/routesProcessor';
import { IAutoSuggestionSearchCell } from '../../types/global/generic.types';
import { connect } from '../../redux/live/action';
import { initialState, reducer } from './applayout.reducer';
import { initialState as contestInitialState, reducer as contestReducer } from './contestCreator.reducer';
import { CustomToast } from '../../components/Toast/CustomToast';
import { Id, toast } from 'react-toastify';
import { Loading } from '../../components/Loading/Loading';
import { ICampaignSubmitterInterface } from '../../components/CampaignSubmitter/CampaignSubmitter';
import {
    castVote,
    createContest,
    getMySubmissions,
    initaiet2FAForVote,
    resetVoteFlow,
    setAutoPublishAndSubmit,
    setCampaignForSubmission,
    setCategoryForSubmission,
    setContestForEdit,
    setFocusCampaign,
    setSubmissionModalState,
    setSubmissionPrompt,
    setVoting2Fa,
    setVoting2FaIndex,
    setVotingFlow,
    submitProject,
    updateContest,
} from '../../redux/contest/actions';
import { userProjectsSelector } from '../UserProjects/selector';
import { ILike } from '../../redux/account/types';
import { IActSummary, IAdvertisement } from '../../types/global/media.types';
import { IReportModal } from '../../components/Report/ReportModal';
import { openReportModal, sendReport } from '../../redux/report/actions';
import { ReportPayload } from '../../redux/report/types';
import { ICreateView } from '../../redux/view/types';
import { createView } from '../../redux/view/action';
import { CustomModalProps } from '../../components/InAppNotification/InAppNotification';
import { clearInAppNotification } from '../../redux/in-app-notification/actions';
import { getVoterId } from '../../redux/vote/action';
import { playlistLoadingContentSelector } from '../EsWatchVideo/selectors';
import {
    deleteNotification,
    getMyNotifications,
    readNotification,
    updateNotification,
} from '../../redux/notifications/action';
import { WebNotification } from '../../redux/account/accountDetailsResponse.type';
import { MediaReportConsole } from '../../components/InAppNotification/MediaReportConsole';
import { getGlobalConfig, updateUserConfig } from '../../redux/configuration/action';
import { useShowFilter } from '../../hook/useHideFilter';
import { en_config } from '../../config';
import { IIntroductionModal } from '../../components/IntroductionModal/IntroductionModal';
import { introductionSteps, projectCreationSeps } from '../../components/IntroductionModal/introductionSteps.data';
import { updateUserProfile } from '../../redux/user/actions';
import { getSuggestedThspians, followAThspian, getMyFollowing, unfollowThspian } from '../../redux/audience/action';
import { ITutorialProps } from '../../components/Tutorial/Tutorial';
import { tutorialSteps } from '../../components/EsSettings/settings.data';

import { MIXPANEL_EVENT, MixPanel } from '../../analytics/Mixpanel';
import { withNetworkStatus } from '../../components/NetworkStatus/NetworkStatus';
import { parseContestPayload, parseProjecPaload } from './helper';
import { IProps } from '../../components/CookieFooter/CookieFooter';
import { IInfoBox } from '../../components/InfoBox/InfoBox';
import { IModalProps } from '../../components/CookiePreferenceDrawer/CookiePreference';
import { IVotingBooth } from '../../components/VotingBooth/VotingBooth';
import { setMute } from '../../redux/player/action';
import { checkAndNuke } from '../../redux/cache/action';
import { IShareModalProps } from '../../components/ShareModal/ShareModal';
import { ProgressBar } from '../../components/ProgressBar/ProgressBar';
import { IProjectSubmissionBox } from '../../components/InfoBox/ProjectSubmissionBox';
import { SentryCapture } from '../../analytics/Sentry';
import { getAd, hideAdBanner, trackAd } from '../../redux/adverts/action';
import { pauseUpload, restartUploadAfterReload, resumeUpload, terminateUplaod } from '../../redux/upload/action';
import { validateFile } from '../../utils/mediaHelper';
import { IFooterProps } from '../../components/Footer/EsFooter';

const EsFooter = lazy<React.FC<IFooterProps>>(() =>
    //@ts-ignore
    import('../../components/Footer/EsFooter').then(module => {
        return { default: module.EsFooter };
    }),
);

const InAppNotification = lazy<React.FC<CustomModalProps>>(() =>
    //@ts-ignore
    import('../../components/InAppNotification/InAppNotification').then(module => {
        return { default: module.InAppNotification };
    }),
);
const IntroductionModal = lazy<React.FC<IIntroductionModal>>(() =>
    //@ts-ignore
    import('../../components/IntroductionModal/IntroductionModal').then(module => {
        return { default: module.IntroductionModal };
    }),
);
const InfoBox = lazy<React.FC<IInfoBox>>(() =>
    //@ts-ignore
    import('../../components/InfoBox/InfoBox').then(module => {
        return { default: module.InfoBox };
    }),
);
const ShareModal = lazy<React.FC<IShareModalProps>>(() =>
    //@ts-ignore
    import('../../components/ShareModal/ShareModal').then(module => {
        return { default: module.ShareModal };
    }),
);
const VotingBooth = lazy<React.FC<IVotingBooth>>(() =>
    //@ts-ignore
    import('../../components/VotingBooth/VotingBooth').then(module => {
        return { default: module.VotingBooth };
    }),
);
const VerticalVideoPlayer = lazy<React.FC<IVerticalVideoPlayer>>(() =>
    //@ts-ignore
    import('../../components/VideoPlayer/VerticalVideoPlayer').then(module => {
        return { default: module.VerticalVideoPlayer };
    }),
);
const ReportModal = lazy<React.FC<IReportModal>>(() =>
    //@ts-ignore
    import('../../components/Report/ReportModal').then(module => {
        return { default: module.ReportModal };
    }),
);

//type CombinedProps = IMediaConsolRef & CustomModalProps;

// const MediaReportConsole = lazy<React.FC<CombinedProps>>(() =>
//     //@ts-ignore
//     import('../../components/InAppNotification/MediaReportConsole').then(module => {
//         return { default: module.MediaReportConsole };
//     }),
// );
const ProjectSubmissionBox = lazy<React.FC<IProjectSubmissionBox>>(() =>
    //@ts-ignore
    import('../../components/InfoBox/ProjectSubmissionBox').then(module => {
        return { default: module.ProjectSubmissionBox };
    }),
);
const CampaignSubmitter = lazy<React.FC<ICampaignSubmitterInterface>>(() =>
    //@ts-ignore
    import('../../components/CampaignSubmitter/CampaignSubmitter').then(module => {
        return { default: module.CampaignSubmitter };
    }),
);
const HorizontalVideoPlayerModal = lazy<React.FC<IVideoPlayerModal>>(() =>
    //@ts-ignore
    import('../../components/VideoPlayer/HorizontalVideoPlayer').then(module => {
        return { default: module.HorizontalVideoPlayerModal };
    }),
);
const ContentCreator = lazy<React.FC<IContentCreator>>(() =>
    //@ts-ignore
    import('../../components/ContentCreator/ContentCreator').then(module => {
        return { default: module.ContentCreator };
    }),
);
const EsSideBarAndHeader = lazy<React.FC<IAppLayout>>(() =>
    //@ts-ignore
    import('../../components/EsSidebar/EsSideBarAndHeader').then(module => {
        return { default: module.EsSideBarAndHeader };
    }),
);
const Tutorial = lazy<React.FC<ITutorialProps>>(() =>
    //@ts-ignore
    import('../../components/Tutorial/Tutorial').then(module => {
        return { default: module.Tutorial };
    }),
);
const CookiePreferenceDrawer = lazy<React.FC<IModalProps>>(() =>
    //@ts-ignore
    import('../../components/CookiePreferenceDrawer/CookiePreference').then(module => {
        return { default: module.CookiePreferenceDrawer };
    }),
);
const CookieFooter = lazy<React.FC<IProps>>(() =>
    //@ts-ignore
    import('../../components/CookieFooter/CookieFooter').then(module => {
        return { default: module.CookieFooter };
    }),
);

/**
 *
 * @returns Outlet.
 *  Wraps around dashboard to render children component
 */

const useStyles = makeStyles(theme => ({
    appLayout: {
        display: 'grid',
        gridTemplateRows: '1fr auto', // Two rows, first row takes the remaining space, second row adjusts to its content (the footer)
        minHeight: '100vh', // Ensure the container takes the full viewport height
        width: '100vw',
    },
    content: {
        // overflowY: 'auto', // Enable vertical scrolling for the content,
    },
    dropBox: {
        border: '2px dashed orange', // Change border color to white
        borderRadius: 10,
        padding: 4,
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        height: '50vh',
        width: '50vw',
        alignItems: 'center',
        cursor: 'pointer',
        transition: 'border-color 0.3s ease-in-out',
        marginLeft: '25%',
        marginTop: '15%',
        '&:hover': {
            borderColor: 'grey',
        },
    },
}));

const AppLayout = () => {
    const classes = useStyles();
    const { isSignedIn, user } = getJWtDetails();
    const [searchTerm, setSearchTerm] = useState('');

    const drawerOpen = useSelector(isDrawerOpen);
    const searchBoxOpen = useSelector(isSearchBoxOpenSelector);
    const drawerStatus = useSelector(toggleStateSelector);
    const currentFilters = useSelector(currentFiltersSelector);
    const selectedFilterSubCategory = useSelector(filterSubCategorySelector);
    const userProjects = useSelector(userProjectsSelector);
    const shoudlOpenSubmissionModal = useSelector(campaignSubmissionModalOpenerSelector);
    const selectedContestForSubmission = useSelector(selectedCampaignForSubmissionSelector);
    const showIntroductionFlow = useSelector(showIntroductionFlowSelector);
    const showAppGuide = useSelector(showAppGuideSelector);
    const { fetchingSuggestedThspians, suggestedThspians } = useSelector(suggestedThspiansSelector);
    const { showVotingBooth, submissions, showVoting2Fa, voteOtpSendingInProgress, startingIndex } =
        useSelector(votingBoothItemsSelector);
    const userFollowing = useSelector(userFollowingSelector);
    const initialContestValues = useSelector(initialContestEditingDataSelector);
    const showProjectSubmissionPrompt = useSelector(showProjectSubmissionPromptSelector);
    const gettingPlaylist = useSelector(playlistLoadingContentSelector);
    const isUserJuryEntitled = useSelector(isUserJurySelector);
    const isUserCastingDirectorEntitled = useSelector(isUserCastingDirectorSelector);
    const userNotificationCount = useSelector(userNotificationCountSelector);
    const notificationStatus = useSelector(toggleNotificationSelector);
    const accountModalStatus = useSelector(toggleAccountModalSelector);
    const systemMute = useSelector(nebulaMuteSelector);

    const cookiesDrawerOpen = useSelector(toggleCookiesDrawerSelector);
    // const projectActivelyProcessing = useSelector(isProjectActivelyProcessing);
    const projectActivelyInCreation = useSelector(isProjectProcessingSelector);
    const projectQuery = useSelector(projectQuerySeletcor);
    const reportModalOpen = useSelector(reportModalSelector);
    const userNotifications = useSelector(userNotificationSelector);

    const searching = useSelector(searchingStatusSelector);
    const searchResults = useSelector(searchResultSelector);
    const gettingNotifications = useSelector(gettingNotificationsSelector);
    const disableFilterButton = useSelector(isSelectedFilterEmptySelector);
    /** The below is used to set the search box suggestions and recent search of the ucrrent user */
    const { myActivity, suggestions } = useSelector(searchSuggestionsSelector);
    const creatorSelector = useSelector(creatorModalSelector);
    const contentCreatorModalVisible = useSelector(creatorModalVisibleSelector);
    const globalMediaControl = useSelector(mediaControlConfigurationSelector);
    const systemLoading = useSelector(systemLoadingSelector);

    /** Campign Upload */
    const liveCampaigns = useSelector(liveCampaignsSelector);
    const focusCampaign = useSelector(focusCampiagnSelector);

    /**Suggestion Box */
    const showProjectSuggestionBox = useSelector(showFirstProjectCongratulatorySelector);
    const shoowProjectCreatorGuide = useSelector(showProjectCreatorGuideSelector);
    const shouldShowSuggestionBox = useSelector(isUsersFirstProjectSelector);
    const currentProjectInCreation = useSelector(currentProjectInCreationSelector) as IProject;
    const userAlreadyCreatedProject = useSelector(showProjectCreatorSuggstionBoxSelector);
    /**
     * The below monitors MediaForge Process
     */
    /** Gets when process report should start */
    const isProjectProcessing = useSelector(isProjectProcessingSelector);
    const currentUploadPayload = useSelector(uploadPayloadSelector);
    const isProjectProcessingDone = useSelector(isProjectProcessingDoneSelector);
    const { progress, uploadMessage, isPaused } = useSelector(mediaForgeMessageSelector);
    const shouldAvoidLoadingPlaylist = useSelector(playerPlaylistTypeSelector);
    const nebulaSuggestion = useSelector(nebulaSuggestionSelector);
    const selectedNebulaSuggestion = useSelector(selectedSuggestionSelector);
    const mediaForge = useSelector(mediaForgeReportSelector);
    /**End of Media Forge Process Monitor */
    const currentVideo = useSelector(currentVideoSelector);
    const currentVideoDetails = useSelector(currentVideoDetailsSelector);
    const currentPlayListSelection = useSelector(playlistSelector);
    const currentFeedSelector = useSelector(feedSelector);
    const { page, canLoadMore, initialProjectId } = useSelector(canLoadMoreFeedSelector);
    const currentVideoComments = useSelector(currentSelectedVideoComments);
    const isVotingEnabled = useSelector(isVotingEnabledSelector);
    const inAppNotification = useSelector(inAppNotificationSelector);
    const votingStartDate = useSelector(contestPhaseDateSelector);
    const currentSubmission = useSelector(currentSubmissionSelector);
    const followingInProgress = useSelector(followingSelector);
    const likingInProgress = useSelector(likeActionInProgressSelector);
    const userProfile = useSelector(userProfileSelector);
    const userVotes = useSelector(userVotesSelector);
    const { isSinglePreview } = useSelector(playerQuerySelector);
    const isShareStatusActive = useSelector(shareModalStatusSelector);
    const { title, url } = useSelector(sharePayloadSelector) || {};
    const dispatch = useDispatch();
    const dispatchPromise = useDispatchPromise();
    const { themeStyle, device } = useThemeContext();
    const [state, formDispatch] = useReducer(reducer, initialState);
    const [contestState, formContestDispatch] = useReducer(contestReducer, contestInitialState);

    const playerOpen = useSelector(horizontalPlayerSelector);
    const actPlayerOpen = useSelector(verticalPlayerSelector);
    const selectedAct = useSelector(selectedActSelector);
    const userLikes = useSelector(userLikesSelector);
    const selectedProjectComments = useSelector(selectedProjectComents);
    const { message, open } = useSelector(toastSelector);
    const { showFilter } = useShowFilter();
    const toastId = useRef<Id>();
    const deviceUploadLimit = device.isMobile
        ? (globalMediaControl.mobile_size_limit as number)
        : (globalMediaControl.file_size_limit as number);
    const imageSizeLimit = globalMediaControl?.image_size_limit ?? en_config.MEDIA_CONFIG.image_size_limit;

    // const { marginLeft, viewWidth } = useSpring({
    //     marginLeft: drawerOpen ? 160 : 0,
    //     viewWidth: drawerOpen ? '90vw' : '100vw',
    //     config: {
    //         duration: 200,
    //         easing: easings.linear,
    //     },
    // });

    const handleDrawerToggle = () => {
        dispatch(setShowSearchBox('off'));
        dispatch(
            toggleFiler({
                filterStatus: 'off',
            }),
        );
        dispatch(toggleDrawer({ status: drawerStatus === 'open' ? 'closed' : 'open' }));
    };

    const updateCreateProjectFields = useCallback((field: string, value: any, mode: CreatorType) => {
        if (mode === 'project') {
            formDispatch({
                type: 'FIELD',
                field,
                value,
            });
        } else {
            // Handles Create Contest Upload
            formContestDispatch({
                type: 'FIELD',
                field,
                value,
            });
        }
    }, []);
    const onDrop = useCallback(
        async (acceptedFiles: File[], fileRejections: FileRejection[]) => {
            if (!isSignedIn) {
                showToastMessage('Login to create a new project', 'error', 5000);
                return;
            }

            const isValid = await validateFile(acceptedFiles[0], imageSizeLimit, deviceUploadLimit);

            if (!isValid.valid) {
                showToastMessage(isValid.error!, 'warning', 5000);
                return;
            }

            if ((fileRejections && fileRejections.length > 0) || acceptedFiles[0].size > deviceUploadLimit) {
                showToastMessage(
                    `File rejected. Max file size must be below ${bytesToMB(
                        deviceUploadLimit,
                    )} - ${imageSizeLimit} MB Max for Images`,
                    'warning',
                    5000,
                );
                return;
            }
            if (isSignedIn && isEmpty(fileRejections)) {
                // Do something with the files
                const reader = new FileReader();
                const file = acceptedFiles[0];
                const isVideoFile = file.type.startsWith('video');
                const isImageFile = file.type.startsWith('image');
                if (isImageFile) {
                    const imageUrl = URL.createObjectURL(file);
                    const img = new Image();
                    img.src = imageUrl;
                    updateCreateProjectFields('project_file', file, 'project');
                    updateCreateProjectFields('feature_image', file, 'project');
                    updateCreateProjectFields('project_preview', file, 'project');
                    img.onload = () => {
                        const pictureMetadata = {
                            duration: 0,
                            width: img.width,
                            height: img.height,
                            type: file.type,
                        };
                        updateCreateProjectFields('metadata', pictureMetadata, 'project');
                        updateCreateProjectFields('media_type', 'picture', 'project');
                        updateCreateProjectFields('processing_complete', true, 'project');

                        // Revoke the object URL after image load to release memory
                        URL.revokeObjectURL(imageUrl);
                    };
                }
                if (isVideoFile) {
                    const videoElement = document.createElement('video');
                    videoElement.preload = 'metadata';
                    dispatch(toggleCreatorModal({ creatorType: 'project' }));
                    videoElement.src = URL.createObjectURL(file);
                    videoElement.addEventListener('loadedmetadata', () => {
                        const videoMetadata = {
                            duration: videoElement.duration,
                            width: videoElement.videoWidth,
                            height: videoElement.videoHeight,
                            type: file.type,
                        };
                        updateCreateProjectFields('metadata', videoMetadata, 'project');
                        /** Sets if a video is played in horizontal or vertical format. Please check API */
                        updateCreateProjectFields(
                            'project_format',
                            videoMetadata.height < videoMetadata.width ? 'video' : 'act',
                            'project',
                        );
                    });

                    reader.onload = event => {
                        if (reader.readyState === 2 && event && event.target) {
                            /** The project seems to be responsible for updating the image hence leaving it here TODO: Fix it and remove it */
                            // updateCreateProjectFields('project', event.target.result, 'project');
                            updateCreateProjectFields('project_file', acceptedFiles[0], 'project');
                        }
                    };

                    reader.onloadend = event => {
                        if (event && event.target) {
                            updateCreateProjectFields('project_preview', acceptedFiles[0], 'project');
                        }
                    };

                    reader.onerror = error => {
                        SentryCapture(error, 'error');
                    };
                    reader.readAsDataURL(file);
                }
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [updateCreateProjectFields],
    );

    // Sets the Main Category
    const handFilterToggle = (filter: FilterItem) => {
        MixPanel.track(MIXPANEL_EVENT.MAIN_CATEGORY_CLICK, {
            buttonAction: 'SELECT_MAIN_CATEGORY',
            categoryName: filter.value as FilterTypes,
        });
        dispatch(setShowSearchBox('off'));
        dispatch(
            setFilter({
                category: filter.value as FilterTypes,
                categoryPayload: filters[filter.value as keyof IFilter],
            }),
        );
    };

    // Sets the Sub Category
    const handleFilterSubCategorySelection = (filter: FilterItem, categoryId: number) => {
        MixPanel.track(MIXPANEL_EVENT.SUB_CATEGORY_CLICK, {
            buttonAction: 'SELECT_SUB_CATEGORY',
            buttonName: 'SELECT_SUB_CATEGORY',
            subCategoryId: categoryId,
            subCategoryValue: filter.value,
            subCategoryName: filter.displayName,
            subCategoryField: filter.field,
        });
        dispatch(setShowSearchBox('off'));
        dispatch(
            setFilterSubCategory({
                id: categoryId,
                value: filter.value,
                displayName: filter.displayName,
                field: filter.field,
            }),
        );
    };

    const handleLogout = () => {
        MixPanel.track(MIXPANEL_EVENT.LOGOUT, {
            buttonAction: 'LOGOUT',
            buttonName: 'LOGOUT',
        });
        dispatch(logout());
    };

    const handleClearFilters = () => {
        MixPanel.track(MIXPANEL_EVENT.CLEAR_FILTER_CLICK, {
            buttonAction: 'CLEAR_FILTER',
            buttonName: 'CLEAR FILTER',
        });
        dispatch(clearFilter());
    };

    useLayoutEffect(() => {
        if (_.isEmpty(currentFilters)) {
            /** set the initial default */
            handFilterToggle(mainCategorySelector[0].data.list[2]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleGetUserVotersCard = useCallback(() => {
        if (isSignedIn) {
            dispatch(getVoterId());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleGetConfig = () => {
        dispatch(checkAndNuke());
        dispatch(getGlobalConfig());
    };

    useLayoutEffect(() => {
        if (isSignedIn) dispatch(connect());
        handleGetUserVotersCard();
        handleGetConfig();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSignedIn]);

    const collapseAll = () => {
        // TODO: Check if the drawer or filter is open before toggling this off

        batch(() => {
            dispatch(setShowSearchBox('off'));
            dispatch(toggleFiler({ filterStatus: 'off', filterId: undefined }));
            dispatch(toggleDrawer({ status: 'closed' }));
            dispatch(toggleNotification({ on: false }));
            dispatch(toggleAccountModal({ on: false }));
            dispatch(
                toggleFiler({
                    filterStatus: 'off',
                }),
            );
        });
    };

    const handleSearchBoxOnClick = () => {
        /** NOTE: We reworked the below to support both logged in and not logged in users */

        batch(() => {
            dispatch(getSearchSuggestions());
            dispatch(getMyActivities());
            dispatch(setShowSearchBox('on'));
            dispatch(toggleAccountModal({ on: false }));
            dispatch(toggleNotification({ on: false }));
            dispatch(toggleDrawer({ status: 'closed' }));
            dispatch(
                toggleFiler({
                    filterStatus: 'off',
                }),
            );
        });
    };

    const handleHomeToggle = () => {
        collapseAll();
        dispatch(navigate({ routes: ROUTES.ESDISCOVERY, skipAuthentication: true }));
    };

    const handleSearch = useRef(
        _.debounce(searchTerm => {
            if (searchTerm.length > 3 && !searching) {
                dispatch(searchDiscovery({ searchTerm }));
            }
        }, 1000),
    ).current;

    const handleChange = (event: any) => {
        const searchTerm = event.target.value;
        setSearchTerm(searchTerm);
        handleSearch(searchTerm); // Call the debounced function
    };

    const handleClearSearch = () => {
        setSearchTerm('');
    };
    //TODO: REWORK THIS FUNCTION
    const mixPanelFunction = () => {
        if (!notificationStatus) {
            MixPanel.track(MIXPANEL_EVENT.NOTIFICATION_OPEN, {
                buttonAction: 'NOTIFICATION OPEN',
                buttonName: 'NOTIFICATION ICON',
            });
        } else {
            MixPanel.track(MIXPANEL_EVENT.NOTIFICATION_CLOSE, {
                buttonAction: 'NOTIFICATION CLOSE',
                buttonName: 'NOTIFICATION ICON',
            });
        }
    };

    const toggleNotificationAction = () => {
        dispatch(setShowSearchBox('off'));
        dispatch(getMyNotifications());
        dispatch(toggleAccountModal({ on: false }));
        dispatch(
            toggleFiler({
                filterStatus: 'off',
            }),
        );
        dispatch(toggleNotification({ on: notificationStatus ? false : true }));
        mixPanelFunction();
        if (userNotificationCount && userNotificationCount > 0) {
            dispatch(
                updateUserConfig({
                    payload: {
                        unread_web_notifications: 0,
                    },
                }),
            );
        }
    };
    const creatorHandler = (creatorType: CreatorType, location?: CreatorLocation) => {
        dispatch(toggleCreatorModal({ creatorType, location }));
        if (creatorType === 'project') {
            dispatch(getMySubmissions());
        }
        handleProjectSubmissionOpener();
        MixPanel.track(MIXPANEL_EVENT.PROJECT_CREATOR_CLICK, {
            buttonAction: creatorType === undefined ? 'CLOSE_PROJECT_CREATOR' : 'OPEN_PROJECT_CREATOR',
            buttonName: creatorType === undefined ? 'CLOSE_CREATOR' : 'CREATE_PROJECT',
        });
        dispatch(setContestForEdit(undefined));
        formContestDispatch({
            type: 'RESET',
        });
        formDispatch({
            type: 'RESET',
        });
    };
    const handleContentCreatorModal = (
        creatorType: CreatorType,
        location?: CreatorLocation,
        deselectContestForSubmission?: boolean,
        shouldAutoSubmit: boolean = false,
    ) => {
        creatorHandler(creatorType, location);
        /** This allows to control when to prompt users to submit a project after it has been created.
         * If a user taps on create project from inside the submitter, we want to prompt them to submit that project
         * after it has been published.
         */
        if (!deselectContestForSubmission) {
            dispatch(setCampaignForSubmission(undefined));
            /** Reset auto publish */
            dispatch(setAutoPublishAndSubmit(false));
        }
        if (creatorType === 'project') {
            /** Determines if a user project */
            dispatch(setAutoPublishAndSubmit(shouldAutoSubmit));
        }
        if (!creatorSelector) {
            dispatch(setFocusCampaign(undefined));
        }
    };
    const clearProject = () => {
        formDispatch({
            type: 'RESET',
        });
    };

    const handleFilterPressed = () => {
        batch(() => {
            dispatch(toggleFiler({ filterStatus: 'off', filterId: undefined }));
            dispatch(filter({ page: 0 }));
            dispatch(navigate({ routes: ROUTES.SEARCH, skipAuthentication: true })); // Keeping isRefreshed as true to prevent app being forced to loginpage for search screen
        });
        MixPanel.track(MIXPANEL_EVENT.APPLY_FILTER_CLICK, {
            buttonAction: 'SELECT_APPLY_FILTER',
            buttonName: 'APPLY FILTER',
        });
    };

    const [searchParams, setSearchParams] = useSearchParams();

    const handleCloseMediaPlayer = (mediaType: MediaType) => {
        dispatch(
            setPlayerQuery({
                isSequential: false,
                isSinglePreview: false,
            }),
        );
        dispatch(
            setmedia({
                media: null,
                mediaType: mediaType,
                submissionObject: undefined,
                playlistType: 'recommendation',
                clearCustomSuggestion: true,
            }),
        );
        setSearchParams({});
        dispatch(setMute(true));
    };

    const handleSelectPlaylistItem = (video: IProject, avoidRefetch?: boolean) => {
        if (video && video.video_id) {
            dispatch(
                getProjectDetails({
                    id: video.video_id as string,
                    noPlaylistRefetch: avoidRefetch ?? shouldAvoidLoadingPlaylist,
                    justFetchDetails: true,
                    projectToSet: video,
                }),
            );
            if (pathname === '/watch') {
                setSearchParams({ id: video.video_id as string });
            }
            dispatch(setMute(false));
            dispatch(setAutoPlay(true));
        } else {
            console.log('Video not found', video);
        }
    };

    const handleAdClick = (ad: IAdvertisement) => {
        if (ad && ad._id && selectedAct) {
            dispatch(
                trackAd({
                    additionalPayload: ad,
                    action: 'clickthrough',
                    id: ad._id,
                    project: selectedAct._id,
                }),
            );
            window.open(ad.click_through, '_blank', 'noopener,noreferrer');
        }
    };

    const closeAd = () => {
        dispatch(hideAdBanner());
    };

    useEffect(() => {
        const params: any = Object.fromEntries([...searchParams]);
        if (isEmpty(params) || (!params && !params['id'])) {
            dispatch(
                setmedia({
                    media: null,
                }),
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleCategorySelections = (doc: any) => {
        const suggestion: SearchSuggestion = doc;
        switch (suggestion.suggestionType) {
            case 'contest':
                const selectedContest: IContest = suggestion.suggestion as IContest;
                let route = replaceRouteParam(ROUTES.ESVIEW_CONTEST, 'contestId', selectedContest._id);
                if (selectedContest && selectedContest.kind === 'physical') {
                    route = replaceRouteParam(ROUTES.EVENT, 'contestId', selectedContest.contest_id);
                    if (selectedContest.preferred_name) {
                        route = replaceRouteParam(ROUTES.EVENT_CUSTOM, 'hostName', selectedContest.preferred_name);
                        route = replaceRouteParam(route, 'contestId', selectedContest.contest_id);
                    }
                }
                dispatch(navigate({ routes: route, skipAuthentication: true }));
                break;
            case 'talent':
                // const selectedUser: IProject = suggestion.suggestion as IUser;
                // Get User Profile here
                const profileRoute = replaceRouteParam(ROUTES.ESMY_PROFILE, 'profileId?', doc.suggestion.userName);
                dispatch(navigate({ routes: profileRoute }));
                break;
            case 'projects':
                const selectedSuggestion: IProject = suggestion.suggestion as IProject;
                dispatch(
                    getProject({
                        id: selectedSuggestion.video_id as string,
                        withNavigate: true,
                        fromAnotherScreen: true,
                    }),
                );
                break;
            default:
                return;
        }
    };

    const handleOnSearchCellClicked = (buttonType?: CellType, doc?: any) => {
        if (buttonType === 'activity' && !!doc && doc.activity) {
            const activity: ActivityResponse = doc;
            switch (activity.activity?.type) {
                case 'Project':
                    const proj = activity.activity.model as IProject;
                    dispatch(getProject({ id: proj.video_id as string, withNavigate: true, fromAnotherScreen: true }));
                    break;
                case 'Contest':
                    const cont = activity.activity.model as unknown as IContest;
                    let route = replaceRouteParam(ROUTES.ESVIEW_CONTEST, 'contestId', cont._id);
                    if (cont && cont.kind === 'physical') {
                        route = replaceRouteParam(ROUTES.EVENT, 'contestId', cont.contest_id);
                        if (cont.preferred_name) {
                            route = replaceRouteParam(ROUTES.EVENT_CUSTOM, 'hostName', cont.preferred_name);
                            route = replaceRouteParam(route, 'contestId', cont.contest_id);
                        }
                    }
                    dispatch(navigate({ routes: route, skipAuthentication: true }));
                    break;
                case 'User':
                case 'Talent':
                    const user = activity.activity.model as unknown as IUserSummary;
                    const profileRoute = replaceRouteParam(ROUTES.ESMY_PROFILE, 'profileId?', user.userName);
                    dispatch(navigate({ routes: profileRoute }));
                    break;
                default:
                    return;
            }
        }

        if (buttonType === 'suggestion') {
            // Navigate to the
            handleCategorySelections(doc);
        }
        // setSearchModalVisible(false);
        dispatch(setShowSearchBox('off'));
        handleClearSearch();
    };

    const handleRightIconClicked = (buttonType?: CellType, doc?: any) => {
        if (buttonType === 'activity') {
            const activity: ActivityResponse = doc;
            dispatch(hideMyActivity({ documentId: activity && activity._id, payload: { hidden: true } }));
        }

        if (buttonType === 'suggestion') {
            handleCategorySelections(doc);
        }
    };

    const renderActivities: any[] = isSignedIn
        ? [
              {
                  label: 'Popular Search',
                  type: 'activity',
                  items: myActivity,
              },
          ]
        : [];

    const onSearchResultClicked = (data: IAutoSuggestionSearchCell) => {
        switch (data.type) {
            case 'contest':
                let route = replaceRouteParam(ROUTES.ESVIEW_CONTEST, 'contestId', data.id);
                if (data && data.isEvent && data.contest_id) {
                    route = replaceRouteParam(ROUTES.EVENT, 'contestId', data.contest_id!);
                    if (data.preferred_name) {
                        route = replaceRouteParam(ROUTES.EVENT_CUSTOM, 'hostName', data.preferred_name);
                        route = replaceRouteParam(route, 'contestId', data.contest_id!);
                    }
                }
                dispatch(
                    navigate({
                        routes: `${route}search=true`,
                        skipAuthentication: true,
                    }),
                );
                break;
            case 'project':
                dispatch(
                    getProject({
                        id: data.id as string,
                        withNavigate: true,
                        params: '?search=true',
                        fromAnotherScreen: true,
                        skipAuthentication: true,
                    }),
                );
                dispatch(setMute(false));
                break;
            case 'talent':
                const profileRoute = replaceRouteParam(ROUTES.ESMY_PROFILE, 'profileId?', data.id);
                dispatch(navigate({ routes: profileRoute }));
                break;
            default:
                return;
        }
        handleClearSearch();
        dispatch(setShowSearchBox('off'));
    };

    const onSeeAllResultClicked = () => {
        batch(() => {
            dispatch(navigate({ routes: ROUTES.SEARCH }));
            dispatch(setViewAll());
            dispatch(setShowSearchBox('off'));
        });
        handleClearSearch();
    };

    const handleUserIconClicked = (mediaType: MediaType, user?: UserSummary) => {
        if (user) {
            handleCloseMediaPlayer(mediaType);
            const profileRoute = replaceRouteParam(ROUTES.ESMY_PROFILE, 'profileId?', user.userName);
            dispatch(navigate({ routes: profileRoute }));
        }
    };

    const handleSubmitContentCreator = () => {
        if (creatorSelector === 'project') {
            const stateClone = parseProjecPaload({ ...state });
            if (stateClone.project_type && isArray(stateClone.project_type)) {
                stateClone['project_type'] = state.project_type[0];
            }

            // /** Force set the mediaType */
            if (!stateClone.media_type) {
                stateClone.media_type = stateClone.media_type === 'picture' ? 'picture' : 'video';
            }
            dispatch(createProject(stateClone));
            handleContentCreatorModal(undefined, 'submissions', true); // Ensured that the submissions location is kept alive after we submit. Allows us to trigger the submisison prompt
            formDispatch(initialState);
            formDispatch({
                type: 'RESET',
            });
        } else {
            const payload = parseContestPayload(contestState, initialContestValues);

            if (initialContestValues && initialContestValues._id) {
                dispatch(
                    updateContest({
                        documentId: initialContestValues._id,
                        payload,
                    }),
                );
            } else {
                dispatch(createContest(payload));
            }
            handleContentCreatorModal(undefined);
            formContestDispatch(contestState);
            formContestDispatch({
                type: 'RESET',
            });
        }
    };

    const handleClearToast = () => dispatch(toggleToast({ message: '', state: false }));

    const handleToastDismissAndNavigate = () => {
        batch(() => {
            toast.dismiss(toastId.current);
            handleClearToast();
            dispatch(navigate({ routes: ROUTES.SEARCH }));
        });
    };

    const handleBottomToast = useCallback(() => {
        toastId.current = showToastMessage(
            <CustomToast message={message} onClick={handleToastDismissAndNavigate} />,
            'success',
            20000,
            handleClearToast,
            undefined,
            undefined,
            'bottom-right',
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleProjectSubmissionOpener = (controlled?: boolean) => {
        /** When closeIcon is clicked */
        dispatch(setSubmissionPrompt(false));
        if (controlled) {
            dispatch(setCampaignForSubmission(undefined));
        }
        dispatch(setSubmissionModalState({ open: false }));
    };
    const handleProjectSubmission = (contest: string, project: string, reedemableCode?: string) => {
        dispatchPromise(submitProject({ contest, project, reedemableCode }))
            .then(() => {
                handleProjectSubmissionOpener();
            })
            .catch(e => {
                handleProjectSubmissionOpener();
            });
    };
    const handleOnActLikePressed = () => {
        if (selectedAct) {
            dispatch(
                likeProject({
                    like_category: 'project',
                    like_type: 'like',
                    projectId: selectedAct?._id as string,
                    like_origin: 'home',
                }),
            );
        }
    };

    const handleOnDeleteLike = (like: ILike) => {
        dispatch(
            deleteLike({
                documentId: like._id,
                /** The below allows us update the like count on the discovery page */
                payload: 'home',
                additionalPayload: like.project,
            }),
        );
    };

    /** This is no longer being used Remove */
    const handleOnCommentSend = (
        text: string,
        comment?: TComment,
        isReply?: boolean,
        project?: IActSummary,
        isEdit?: boolean,
    ) => {
        if (isEdit && comment) {
            dispatch(
                updateComment({
                    documentId: comment?._id,
                    payload: text,
                }),
            );
        } else {
            dispatch(
                leaveComment({
                    comment: text,
                    project: project as any,
                    commentId: comment?._id,
                    commentType: 'project',
                    isReply: isReply,
                    parentComment: comment,
                }),
            );
        }
    };

    const handleCloseSuggestionBox = () => {
        dispatch(setUsersFirstProjectSuggestionBox(false));
    };

    const handleShowProjectCreatorGudie = () => {
        dispatch(setShowProjectCreatorGuide(true));
        handleCloseSuggestionBox();
        dispatch(
            updateUserConfig({
                payload: {
                    already_created_project: true,
                },
            }),
        );
    };

    const handleViewProject = () => {
        if (currentProjectInCreation) {
            dispatch(
                navigate({
                    routes: `${ROUTES.ESMY_VIDEOS}?isNewEdit=true&project=${currentProjectInCreation.video_id}&edit=true&publish_button=true`,
                }),
            );
        }
        handleCloseSuggestionBox();
    };

    useEffect(() => {
        handleProjectUploadCompletion();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isProjectProcessingDone]);

    const handleProjectUploadCompletion = () => {
        if (shouldShowSuggestionBox && !userAlreadyCreatedProject && isSignedIn) {
            dispatch(setUsersFirstProjectSuggestionBox(true));
        }
        //TODO CHECK If needed
        if (!!uploadMessage) {
            showToastMessage(uploadMessage, 'success', 6000, () => {
                dispatch(
                    mediaForgeWorker({
                        status: 'ignore',
                        mediaForgeMessage: '',
                    }),
                );
            });
        }
    };

    const handleOnDeleteComment = (comment: TComment) => {
        dispatch(
            deleteComment({
                documentId: comment._id,
                additionalPayload: comment._id,
                payload: comment,
            }),
        );
    };

    const handleVoteIconClicked = catchAsync(async (submisisons: ISubmission[]) => {
        /** Cast Vote For users that are logged in without the voting modal */
        dispatch(resetVoteFlow());
        dispatch(
            setVotingFlow({
                submissions: submisisons,
                showVotingModal: true,
            }),
        );
    });

    const handleVoting = catchAsync(async (submisison: ISubmission) => {
        dispatch(setSubmission(submisison));
        if (submisison && isSignedIn) {
            dispatchPromise(
                initaiet2FAForVote({
                    submission_id: submisison?._id,
                    phone_number: user?.phoneNumber,
                    email: user?.email,
                }),
            ).then(e => {
                if (typeof e === 'string') {
                    dispatch(setVoting2FaIndex(1));
                    dispatch(setVoting2Fa(true));
                    // Set The starting index
                }
            });
        } else {
            // Handle logged out flow
            dispatch(setVoting2Fa(true));
        }
    });

    const handleDismissVoting2Fa = () => {
        dispatch(setVoting2Fa(false));
    };

    const handleCommentReaction = (like_type: Likes, comment: TComment) => {
        if (!likingInProgress) {
            if (like_type === 'like') {
                dispatch(
                    likeComment({
                        commentId: comment._id,
                        like_category: 'comment',
                        like_type: like_type,
                        like_origin: 'home',
                    }),
                );
            } else {
                const likeToBeDeleted = userLikes.find(
                    like =>
                        like.comment &&
                        like.comment === comment._id &&
                        like.like_type === 'like' &&
                        like.like_category === 'comment',
                );
                if (likeToBeDeleted) {
                    dispatch(
                        deleteLike({
                            documentId: likeToBeDeleted._id,
                            additionalPayload: likeToBeDeleted,
                        }),
                    );
                }
            }
        } else {
            console.log('Liking In Progress');
        }
    };

    const handleReportModal = () => {
        dispatch(openReportModal(!reportModalOpen));
    };

    const handleToggleCookiesDrawer = (on: boolean) => {
        dispatch(toggleCookiesModal({ on }));
    };

    const handleSendReport = (payload: ReportPayload) => {
        dispatch(sendReport(payload));
        handleReportModal();
    };

    const handleViewUpdate = (payload: ICreateView) => {
        dispatch(createView(payload));
    };

    const handleSubmitPhoneNumberForVerification = (next?: () => void, phone?: string, onEnd?: () => void) => {
        if ((phone || isSignedIn) && currentSubmission) {
            dispatchPromise(
                initaiet2FAForVote({
                    phone_number: phone,
                    submission_id: currentSubmission?._id,
                }),
            )
                .then(res => {
                    if (typeof res === 'string') {
                        /** User receives message to verify phone, so go to next modal to enter code */
                        next && next();
                    } else {
                        /** Vote is successful, end the transaction */
                        onEnd && onEnd();
                        dispatch(
                            setVotingFlow({
                                submissions: [],
                                showVotingModal: false,
                            }),
                        );
                    }
                })
                .catch(e => {
                    onEnd && onEnd();
                    dispatch(
                        setVotingFlow({
                            submissions: [],
                            showVotingModal: false,
                        }),
                    );
                });
        }
    };

    const handleClearInAppNotification = () => dispatch(clearInAppNotification());

    const handleCastVote = (otp?: string, phoneNumber?: string, onEnd?: () => void) => {
        if (currentSubmission && otp && phoneNumber) {
            dispatch(
                castVote({
                    code: otp,
                    phone_number: phoneNumber,
                    submission_id: currentSubmission?._id,
                }),
            );
            onEnd && onEnd();
        }
    };

    const handleOnEndReach = () => {
        const { isSubmissionList, parameter } = (selectedNebulaSuggestion as INebulaSuggestion) || {};
        /** Get the current param and replace the value of the page */
        const { currentPage, limit, loading, param, canLoadMore } = projectQuery;
        if (!loading && canLoadMore) {
            const newPage = currentPage ? currentPage + 1 : 1;
            const newParam = isSubmissionList
                ? replacePageValue(`${parameter}?limit=${en_config.RESULT_LIMIT}&page=${newPage}` as string, newPage)
                : replacePageValue(param as string, newPage);
            dispatch(
                getProjects({
                    isFiltered: true,
                    page: newPage,
                    params: newParam,
                    limit,
                    isSubmissionList,
                }),
            );
        }
    };

    const handleReadNotification = (notification: WebNotification) => {
        batch(() => {
            if (!notification.page) {
                /** Only mark notification as Read when it is not linked to a specific page */
                dispatch(readNotification(notification));
            }
            dispatch(toggleNotification({ on: false }));
        });
    };
    const handleHideNotification = (notification: WebNotification) => {
        dispatch(
            updateNotification({
                documentId: notification._id,
                payload: {
                    seen: true,
                    hidden: true,
                },
            }),
        );
    };
    const handleReadDeleteNotification = (notification: WebNotification) => {
        dispatch(
            deleteNotification({
                documentId: notification._id,
            }),
        );
    };

    const handleNotificationSettingsClicked = () => {
        MixPanel.track(MIXPANEL_EVENT.SETTINGS_CLICK, {
            buttonAction: 'OPEN_SETTINGS',
            buttonName: 'SETTINGS',
        });
        dispatch(toggleNotification({ on: false }));
        dispatch(
            navigate({
                routes: ROUTES.ESSETTINGS,
            }),
        );
    };

    const handleOnEndPress = (showGuide?: boolean) => {
        batch(() => {
            dispatch(
                updateUserConfig({
                    payload: {
                        show_introduction: false,
                        show_app_guide: showGuide,
                    },
                }),
            );
            if (showGuide) {
                dispatch(
                    navigate({
                        routes: ROUTES.ESDISCOVERY,
                    }),
                );
            }
        });
    };

    /** This should handle Publishing created project */
    const handleOnFinalStepOnProjectGuide = (showGuide?: boolean) => {
        if (showGuide && currentProjectInCreation) {
            dispatch(
                publishProject({
                    documentId: currentProjectInCreation._id,
                    additionalPayload: 'from guide',
                }),
            );
        }
        dispatch(setShowProjectCreatorGuide(false));
    };

    const handleUserUpdateForIntroduction = catchAsync(async (payload: any) => {
        await dispatchPromise(updateUserProfile(payload));
    });

    const handleGetSuggestedThspians = (userType: string[]) => {
        batch(() => {
            dispatch(
                getMyFollowing({
                    page: 1,
                    limit: en_config.RESULT_LIMIT,
                }),
            );
            dispatch(getSuggestedThspians({ user_type: userType }));
        });
    };

    const handleFollowThspian = (user: ITopUser | IUserSummary) => {
        if (!followingInProgress) {
            dispatch(
                followAThspian({
                    following: user._id,
                }),
            );
        }
    };

    const handleNebularSuggestion = (suggestion: INebulaSuggestion) => {
        dispatch(
            selectNebulaSuggestion({
                ...suggestion,
                isSetType: 'select',
            }),
        );
        dispatch(
            getProjects({
                page: 1,
                isPlaylistFetch: true,
                params: suggestion.parameter,
                playlistType: suggestion.playlistType,
                shouldSetMedia: false,
                isSubmissionList: suggestion.isSubmissionList,
            }),
        );
    };

    useEffect(() => {
        if (open && message) {
            handleBottomToast();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open, message]);

    useEffect(() => {
        /** This Checks is there is an upload still currently in progress then retriggeres it */
        if (isProjectProcessing && currentUploadPayload) {
            dispatch(restartUploadAfterReload(currentUploadPayload));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        dispatch(
            getAd({
                params: '',
            }),
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const { pathname } = useLocation();

    // Handles following and unfollowng for the Horizontal player, possibly can be used for the vertical too
    const handleOnFollowClick = (userToFollow?: UserSummary) => {
        if (!followingInProgress) {
            if (userToFollow && !userToFollow?.user_following && user) {
                dispatch(followAThspian({ following: userToFollow?._id, from: 'profile' }));
                MixPanel.track(MIXPANEL_EVENT.FOLLOWED_A_THSPIAN, {
                    follower: user._id,
                    following: userToFollow.userName,
                });
            } else if (
                user &&
                userToFollow &&
                userToFollow.user_following &&
                userToFollow.user_follow_interaction &&
                !isEmpty(userToFollow.user_follow_interaction)
            ) {
                dispatch(
                    unfollowThspian({
                        documentId: userToFollow.user_follow_interaction[0]._id,
                        additionalPayload: userToFollow.user_follow_interaction[0],
                    }),
                );
                MixPanel.track(MIXPANEL_EVENT.UNFOLLOWED_A_THSPIAN, {
                    follower: user.userName,
                    following: userToFollow.userName,
                });
            }
        } else {
            console.log('Rapid tap detected, Slow down');
        }
    };

    const handleOnSwipeUp = (index: number) => {
        if (currentFeedSelector && !showVotingBooth && !isShareStatusActive) {
            dispatch(
                setmedia({
                    media: currentFeedSelector[index],
                    mediaType: 'act',
                }),
            );
            handleSelectPlaylistItem(currentFeedSelector[index], true);
            dispatch(setMute(systemMute));
            if (pathname === '/watch') {
                setSearchParams({ id: currentFeedSelector[index].video_id as string });
            }
        }
    };

    const handleOnSwipeDown = (index: number) => {
        if (currentFeedSelector && !showVotingBooth && !isShareStatusActive) {
            dispatch(
                setmedia({
                    media: currentFeedSelector[index],
                    mediaType: 'act',
                }),
            );
            handleSelectPlaylistItem(currentFeedSelector[index], true);
            dispatch(setMute(systemMute));
            if (pathname === '/watch') {
                console.log('WHAT DO WE SEEE THERE');
                setSearchParams({ id: currentFeedSelector[index].video_id as string });
            }
        }
    };

    const handleLoadMore = () => {
        if (canLoadMore && initialProjectId) {
            dispatch(
                getFeed({
                    firstProjectId: initialProjectId,
                    page: page + 1,
                }),
            );
        }
    };

    const handleOnUploadCancel = () => {
        dispatch(terminateUplaod());
    };

    const handleOnResumeUpload = () => {
        dispatch(resumeUpload(undefined));
    };

    const handleOnPauseUplaod = () => {
        dispatch(pauseUpload('Upload currently paused.'));
    };

    const handleOnProjectSubmissionBoxClose = () => {
        dispatch(setCampaignForSubmission(undefined));
        dispatch(setSubmissionPrompt(false));
    };
    const handleOnShareModalClose = () => {
        MixPanel.track(MIXPANEL_EVENT.CLOSE_SHARE_CONTEST_MODAL, {
            buttonAction: 'SHARE MODAL CLOSE',
            buttonName: 'SHARE MODAL CLOSE',
        });
        dispatch(setShareModalStatus({ status: false, title: undefined, url: undefined }));
    };

    const handleSetCampaignFocus = (contest?: IContest) => {
        dispatch(setFocusCampaign(contest));
        dispatch(setAutoPublishAndSubmit(!!contest));
    };

    const handleSetCategoryForSubmission = (category: string) => {
        dispatch(setCategoryForSubmission(category));
    };

    const handleFooterLinksPress = (routes: string) => dispatch(navigate({ routes }));

    const handleContactUs = () => {};

    return (
        <>
            {systemLoading && <Loading />}
            <Dropzone
                onDrop={onDrop}
                maxFiles={1}
                disabled={projectActivelyInCreation}
                maxSize={deviceUploadLimit}
                minSize={en_config.MIN_SIZE}
                accept={parseFileFormats(globalMediaControl)}>
                {({ getRootProps, isDragActive, open, isDragReject }) => (
                    <Box className={classes.appLayout} {...getRootProps()} onClick={() => {}}>
                        {isDragActive && device.isDesktop && !contentCreatorModalVisible && (
                            <Box
                                sx={{
                                    position: 'fixed',
                                    background: 'rgba(0,0,0,0.8)',
                                    width: '100vw',
                                    height: '100vh',
                                    zIndex: 999,
                                    top: 0,
                                    right: 0,
                                }}>
                                <Box className={classes.dropBox}>
                                    <CloudUploadIcon fontSize="large" color="primary" />
                                    <Typography
                                        style={{
                                            fontFamily: FONT_ENUM.REGULAR_HEADER,
                                            color: theme[themeStyle].lightWhite,
                                            fontSize: '16px',
                                            alignSelf: 'center',
                                        }}>
                                        {strings.drop_to_create_project}
                                    </Typography>
                                </Box>
                            </Box>
                        )}
                        <Suspense fallback={<Loading />}>
                            <InAppNotification
                                isVisible={!!inAppNotification?.message}
                                text={inAppNotification?.message}
                                onClosePress={handleClearInAppNotification}
                            />
                        </Suspense>
                        {/** Project creation Modal */}
                        <Suspense fallback={<Loading />}>
                            <IntroductionModal
                                open={shoowProjectCreatorGuide as boolean}
                                steps={projectCreationSeps}
                                onEndPressed={handleOnFinalStepOnProjectGuide}
                                onClickFollow={() => {}}
                                userFollowing={[]}
                                duration={1.5}
                            />
                        </Suspense>
                        <Suspense fallback={<Loading />}>
                            <InfoBox
                                onClose={handleCloseSuggestionBox}
                                visible={showProjectSuggestionBox}
                                onPublishProject={handleShowProjectCreatorGudie}
                                onViewProject={handleViewProject}
                            />
                        </Suspense>
                        <Suspense fallback={<Loading />}>
                            <ShareModal
                                isOpen={(isShareStatusActive as boolean) ?? false}
                                onClose={handleOnShareModalClose}
                                shareUrl={url as string}
                                title={title as string}
                            />
                        </Suspense>
                        <Suspense fallback={<Loading />}>
                            <IntroductionModal
                                open={showIntroductionFlow}
                                steps={introductionSteps}
                                onEndPressed={handleOnEndPress}
                                onClickFollow={handleFollowThspian}
                                user={user}
                                updateUser={handleUserUpdateForIntroduction}
                                getSuggestedThspians={handleGetSuggestedThspians}
                                loading={fetchingSuggestedThspians}
                                suggestedThspians={suggestedThspians}
                                userFollowing={userFollowing}
                            />
                        </Suspense>

                        {/** Voting Flow */}
                        <Suspense fallback={<Loading />}>
                            <VotingBooth
                                submissions={submissions}
                                openVotingBooth={showVotingBooth}
                                onVoteClick={handleVoting}
                            />
                        </Suspense>

                        {!!selectedAct && (
                            <Suspense fallback={<Loading />}>
                                <VerticalVideoPlayer
                                    modalOpen={actPlayerOpen}
                                    onHandleVotingModalClose={handleDismissVoting2Fa}
                                    openVotngVerificationModal={showVoting2Fa}
                                    onClose={() => handleCloseMediaPlayer('act')}
                                    project={selectedAct}
                                    comments={selectedProjectComments}
                                    userLikes={userLikes}
                                    onLikePressed={() => handleOnActLikePressed()}
                                    onDeleteLike={handleOnDeleteLike}
                                    onCommentSend={handleOnCommentSend}
                                    onReactionPress={(like_type, comment) => handleCommentReaction(like_type, comment)}
                                    onDeleteComment={handleOnDeleteComment}
                                    playlist={currentPlayListSelection}
                                    onReportClick={handleReportModal}
                                    onCoCreatorClick={() => {}}
                                    onDetailsClick={() => {}}
                                    onViewUpdate={handleViewUpdate}
                                    isVotingEnabled={isVotingEnabled}
                                    votingStartDate={votingStartDate}
                                    isUserJuryEntitled={isUserJuryEntitled}
                                    onSubmitPhonenumberForVerification={handleSubmitPhoneNumberForVerification}
                                    onCastVote={handleCastVote}
                                    onVoteClicked={handleVoteIconClicked}
                                    hasVoted={userVotes.some(vote => vote.submission === currentSubmission?._id)}
                                    onClickUserIcon={user => handleUserIconClicked('act', user)}
                                    feed={currentFeedSelector as unknown as IActSummary[]}
                                    onSwipeDown={handleOnSwipeDown}
                                    onSwipeUp={handleOnSwipeUp}
                                    loadMoreFeed={handleLoadMore}
                                    voteOtpSendingInProgress={voteOtpSendingInProgress}
                                    startingIndex={startingIndex}
                                    currentVideoDetails={currentVideoDetails}
                                    onClickPlaylistItem={handleSelectPlaylistItem}
                                    onAdClick={handleAdClick}
                                    closeAd={closeAd}
                                />
                            </Suspense>
                        )}
                        <Suspense fallback={<Loading />}>
                            <ReportModal
                                open={reportModalOpen}
                                onSubmit={handleSendReport}
                                onClose={handleReportModal}
                                project={selectedAct as unknown as IProject} // Fis this to take type IProject instead of ActSummary
                            />
                        </Suspense>

                        <MediaReportConsole isVisible={false} onClosePress={() => {}} mediaForge={mediaForge} />

                        <Suspense fallback={<Loading />}>
                            <ProjectSubmissionBox
                                visible={showProjectSubmissionPrompt}
                                contest={selectedContestForSubmission!}
                                project={currentProjectInCreation}
                                onClose={handleOnProjectSubmissionBoxClose}
                                onDisagre={handleOnProjectSubmissionBoxClose}
                                onAgree={() =>
                                    handleProjectSubmission(
                                        selectedContestForSubmission?._id!,
                                        currentProjectInCreation._id,
                                    )
                                }
                            />
                        </Suspense>
                        <Suspense fallback={<Loading />}>
                            <CampaignSubmitter
                                open={shoudlOpenSubmissionModal}
                                onClose={controlled => handleProjectSubmissionOpener(controlled)}
                                selectedContest={selectedContestForSubmission}
                                projectList={userProjects}
                                setCategoryForSubmission={handleSetCategoryForSubmission}
                                onSubmit={({ contestId, projectId, reedemableCode }) =>
                                    handleProjectSubmission(contestId, projectId, reedemableCode)
                                }
                                onClickHandle={() => {}}
                                globalMediaControl={globalMediaControl}
                                state={{}}
                                liveCampaigns={liveCampaigns}
                                initialCampaignSelection={focusCampaign}
                                setFocusContest={handleSetCampaignFocus}
                                onCreateNewProjectClick={shouldAutoSubmit => {
                                    handleContentCreatorModal('project', 'submissions', true, shouldAutoSubmit);
                                    if (shouldAutoSubmit) {
                                        /** Set the contest that is currently in focus */
                                        handleSetCampaignFocus(selectedContestForSubmission);
                                    }
                                }}
                            />
                        </Suspense>

                        {!!currentVideo && (
                            <Suspense fallback={<Loading />}>
                                <HorizontalVideoPlayerModal
                                    suggestion={nebulaSuggestion}
                                    selectedNebulaSuggestion={selectedNebulaSuggestion}
                                    open={playerOpen}
                                    onClose={() => handleCloseMediaPlayer('video')}
                                    currentVideo={currentVideo}
                                    playlist={currentPlayListSelection}
                                    comments={currentVideoComments as TComment[]}
                                    onClickPlaylistItem={handleSelectPlaylistItem}
                                    onViewUpdate={handleViewUpdate}
                                    onEndReach={handleOnEndReach}
                                    playlistLoading={gettingPlaylist} // Change this to map directly to a gettingPlaylist value
                                    commentsLoading={false}
                                    onDeleteComment={handleOnDeleteComment}
                                    isSinglePreview={isSinglePreview}
                                    onSelectTag={handleNebularSuggestion}
                                    onClickFollow={handleOnFollowClick}
                                    onClickUserIcon={user => handleUserIconClicked('video', user)}
                                    onSubmitPhonenumberForVerification={handleSubmitPhoneNumberForVerification}
                                    onCastVote={handleCastVote}
                                    voteOtpSendingInProgress={voteOtpSendingInProgress}
                                    startingIndex={startingIndex}
                                    onHandleVotingModalClose={handleDismissVoting2Fa}
                                    openVotngVerificationModal={showVoting2Fa}
                                    onVoteClicked={handleVoteIconClicked}
                                    currentVideoDetails={currentVideoDetails}
                                    onAdClick={handleAdClick}
                                    closeAd={closeAd}
                                />
                            </Suspense>
                        )}
                        {/** Create Contest Modal */}
                        <Suspense fallback={<Loading />}>
                            <ContentCreator
                                onClose={() => handleContentCreatorModal(undefined)}
                                open={contentCreatorModalVisible}
                                contentType={creatorSelector}
                                onSubmit={handleSubmitContentCreator}
                                setCategoryForSubmission={handleSetCategoryForSubmission}
                                onClickHandle={() => {}}
                                onCloseClick={() => handleContentCreatorModal(undefined)}
                                onDrop={onDrop}
                                liveCampaigns={liveCampaigns}
                                initialCampaignSelection={focusCampaign}
                                setFocusContest={handleSetCampaignFocus}
                                state={creatorSelector === 'project' ? state : contestState}
                                updateField={updateCreateProjectFields}
                                globalMediaControl={globalMediaControl}
                                initialContestState={initialContestValues}
                                uploadProps={{
                                    onClick: open,
                                }}
                                onClearProject={clearProject}
                            />
                        </Suspense>

                        {/** Information Box. Congratulations */}
                        <EsSideBarAndHeader
                            onNotificationClick={handleReadNotification}
                            isUserJuryEntitled={isUserJuryEntitled}
                            isUserCastingDirectorEntitled={isUserCastingDirectorEntitled}
                            progress={progress}
                            isProjectProcessing={isProjectProcessing}
                            handleOnClose={handleOnUploadCancel}
                            loadingNotifications={gettingNotifications}
                            disableCreateProject={projectActivelyInCreation}
                            handleNotificationSettingsClicked={handleNotificationSettingsClicked}
                            onNotificationDeleteClick={handleReadDeleteNotification}
                            onNotificationHideClick={handleHideNotification}
                            openNotification={notificationStatus as boolean}
                            handleLogout={handleLogout}
                            onSearchResultClicked={onSearchResultClicked}
                            onSeeAllPressed={onSeeAllResultClicked}
                            onRightIconClicked={handleRightIconClicked}
                            onSearchCellClicked={handleOnSearchCellClicked}
                            disableFilter={disableFilterButton}
                            onApplyFilterPressed={handleFilterPressed}
                            onToggleNotification={toggleNotificationAction}
                            onClickCreateProject={() => handleContentCreatorModal('project')}
                            notifications={userNotifications}
                            notificationCount={userNotificationCount}
                            openNotifications={notificationStatus as boolean}
                            openAccountModal={accountModalStatus as boolean}
                            onLogoClicked={handleHomeToggle}
                            searchItems={[
                                ...renderActivities,
                                {
                                    label: 'Suggestion',
                                    items: suggestions,
                                    type: 'suggestion',
                                },
                            ]}
                            activity={myActivity}
                            isSearch={searchTerm.length > 3}
                            autoSuggestedSearch={searchResults?.item}
                            standardTextFieldProps={{
                                onChange: handleChange,
                                value: searchTerm,
                            }}
                            loading={searching}
                            showCancelButton={!isEmpty(searchTerm)}
                            suggestion={suggestions}
                            uploadMessage={uploadMessage}
                            onResume={handleOnResumeUpload}
                            onPause={handleOnPauseUplaod}
                            isPaused={isPaused}
                            isFocused={searchBoxOpen}
                            onCancelClick={handleClearSearch}
                            onClick={handleSearchBoxOnClick}
                            filterSelections={selectedFilterSubCategory}
                            onTapOutsideOfLayout={collapseAll}
                            onToggleDrawer={handleDrawerToggle}
                            filterList={currentFilters}
                            onChangeFilterCategory={handFilterToggle}
                            onChangeFilterSubCategory={handleFilterSubCategorySelection}
                            onClearFilterPressed={handleClearFilters}
                            isSignedIn={isSignedIn}
                            open={device.isDesktop ? true : drawerOpen}
                            user={userProfile}
                            onClearFilter={handleClearFilters}>
                            <AnimatedBox
                                className={classes.content}
                                style={{
                                    marginLeft: device.isDesktop ? 160 : 0,
                                    maxWidth: device.isDesktop ? '90vw' : '100vw',
                                }}
                                sx={{
                                    paddingLeft: { xs: '0', sm: '0px' },
                                    paddingRight: { xs: '0', sm: '0px' },
                                    // paddingBottom: { xs: '10px', sm: '20px' },
                                    paddingTop: {
                                        lg: !showFilter ? '80px' : '150px',
                                        sm: !showFilter ? '50px' : '150px',
                                        xs: !showFilter ? '50px' : '150px',
                                    },
                                    width: '100%',
                                    maxWidth: '100vw',
                                }}>
                                <Suspense fallback={<Loading />}>
                                    {/* {showAppGuide && !pathname.includes(ROUTES.ESSETTINGS) && (
                                        <Tutorial stepsDetails={tutorialSteps} />
                                    )} */}
                                    {showAppGuide && !pathname.includes(ROUTES.ESSETTINGS) && (
                                        <Tutorial
                                            stepsDetails={{
                                                ...tutorialSteps,
                                                steps: tutorialSteps.steps.slice(0, -1),
                                            }}
                                        />
                                    )}
                                    {isProjectProcessing &&
                                        !pathname.includes(ROUTES.ESMY_VIDEOS) &&
                                        !device.isMobile && (
                                            <ProgressBar
                                                progress={progress}
                                                onCancel={handleOnUploadCancel}
                                                uploadMessage={uploadMessage}
                                                onResume={handleOnResumeUpload}
                                                onPause={handleOnPauseUplaod}
                                                isPaused={isPaused}
                                            />
                                        )}
                                    <Outlet />
                                    {/* Cookies Prefrence Modal */}
                                    <CookiePreferenceDrawer
                                        modalOpen={cookiesDrawerOpen}
                                        toggleDrawer={handleToggleCookiesDrawer}
                                        fullscreen={device.isMobile}
                                    />
                                    <CookieFooter onToggleCookiesDrawer={handleToggleCookiesDrawer} />
                                </Suspense>
                                <AnimatedBox
                                    sx={{
                                        flex: 1,
                                        marginLeft: { xs: '0px', sm: '65px', lg: '65px' },
                                        marginTop: '40px',
                                        width: { xs: '100vw', lg: '85vw' },
                                    }}>
                                    <EsFooter
                                        onClickLink={handleFooterLinksPress}
                                        onContactUsPress={handleContactUs}
                                        isSimpleFooter
                                        onToggleCookiesDrawer={handleToggleCookiesDrawer}
                                    />
                                </AnimatedBox>
                            </AnimatedBox>
                        </EsSideBarAndHeader>
                        {/* Scroll to top functionality */}
                        <EsScrollToTop />
                    </Box>
                )}
            </Dropzone>
        </>
    );
};

const WrappedLayout = withNetworkStatus(AppLayout);

export { WrappedLayout as AppLayout };
