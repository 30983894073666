import { createAction } from 'redux-act';
import { ILink, ILinkPayload, types } from './types';
import { IErrorType } from '../../constants/types';

export const createLink = createAction<ILinkPayload>(types.CREATE_LINK);
export const createLinkSuccess = createAction<ILink>(types.CREATE_LINK_SUCCESS);
export const createLinkFail = createAction<IErrorType>(types.CREATE_LINK_FAIL);

export const getLinks = createAction(types.GET_MY_LINKS);
export const getLinksSuccess = createAction<ILink[]>(types.GET_MY_LINKS_SUCCESS);
export const getLinksFail = createAction<IErrorType>(types.GET_MY_LINKS_FAIL);

export const updateLink = createAction<ILinkPayload>(types.UPDATE_LINK);
export const updateLinkSuccess = createAction<ILink>(types.UPDATE_LINK_SUCCESS);
export const updateLinkFail = createAction<IErrorType>(types.UPDATE_LINK_FAIL);

export const deleteLink = createAction<ILinkPayload>(types.DELETE_LINK);
export const deleteLinkSuccess = createAction<ILinkPayload>(types.DELETE_LINK_SUCCESS);
export const deleteLinkFail = createAction<IErrorType>(types.DELETE_LINK_FAIL);

export const getLink = createAction<string>(types.GET_LINK);
export const getLinkSuccess = createAction<ILink>(types.GET_LINK_SUCCESS);
export const getLinkFail = createAction<IErrorType>(types.GET_LINK_FAIL);
