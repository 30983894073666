import React from 'react';
import { StyleSheet } from 'react-native';
import { Box, Typography, Button } from '@mui/material';
import { useThemeContext } from '../../hook/useThemeManager';
import { ColorScheme, theme } from '../../constants/theme';
import { FONT_ENUM } from '../../types/global/fonts.types';
import { isRouteErrorResponse, useRouteError } from 'react-router-dom';
import { errorMessages } from './error.messages';
import { useLottie } from 'lottie-react';
import { space } from '../../assets/animations/index';
import { useDispatch } from 'react-redux';
import { navigate } from '../../redux/navigator/action';
import { strings } from '../../constants/strings';

const ErrorBoundaryComponent = () => {
    const { themeStyle } = useThemeContext();
    const styles = dynamicStyles(themeStyle);
    const error = useRouteError();
    const dispatch = useDispatch();
    let errroMessage = errorMessages.generic;
    const options = {
        animationData: space,
        loop: true,
    };

    const style = {
        height: 200,
        width: '100%',
        marginTop: 40,
    };
    const { View } = useLottie(options, style);

    if (isRouteErrorResponse(error)) {
        if (error.status === 404) {
            errroMessage = errorMessages.non_existent;
        }

        if (error.status === 401) {
            errroMessage = errorMessages.unauthorized;
        }

        if (error.status === 503) {
            errroMessage = errorMessages.server;
        }

        if (error.status === 418) {
            errroMessage = errorMessages.wrong_param;
        }
    }

    return (
        <Box style={styles.flexColumn} sx={{ gap: '25px' }}>
            {View}
            <Box style={styles.flexColumn} sx={{ gap: '5px' }}>
                <Typography style={styles.title}>{errroMessage.header}</Typography>
                <Typography style={styles.text}>{errroMessage.message}</Typography>
            </Box>
            <Button
                onClick={() => {
                    dispatch(navigate({ routes: -1 }));
                }}
                style={styles.link}
                sx={{ gap: '8px', textDecoration: 'none', '&:hover': { cursor: 'pointer' }, textTransform: 'none' }}>
                <Typography style={styles.trophyText}>{strings.go_back}</Typography>
            </Button>
        </Box>
    );
};

const dynamicStyles = (colorScheme: ColorScheme) =>
    StyleSheet.create({
        flexColumn: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            maxWidth: '344px',
        },
        link: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            width: '168px',
            height: '40px',
            borderRadius: 100,
            border: `2px solid ${theme[colorScheme].primary_orange}`,
        },
        text: {
            fontSize: 14,
            fontWeight: '500',
            fontFamily: FONT_ENUM.MAIN_REGULAR,
            textAlign: 'center',
            color: theme[colorScheme].gray_message,
            maxWidth: '344px',
        },
        title: {
            fontSize: 24,
            fontWeight: '600',
            fontFamily: FONT_ENUM.MAIN_BOLD,
            textAlign: 'center',
            color: theme[colorScheme].primary_white,
        },
        trophyText: {
            fontSize: 14,
            fontWeight: '600',
            fontFamily: FONT_ENUM.MAIN_BOLD,
            textAlign: 'center',
            color: theme[colorScheme].primary_orange,
        },
    });

export { ErrorBoundaryComponent };
