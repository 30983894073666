import { animated } from '@react-spring/web';
import React from 'react';
import { StyleProp, ViewStyle } from 'react-native';

import { theme } from '../../constants/theme';
import { useThemeContext } from '../../hook/useThemeManager';

interface IGradientBackground {
    children: JSX.Element;
    style: StyleProp<ViewStyle>;
}

const GradientBackground = ({ children }: IGradientBackground) => {
    const { themeStyle } = useThemeContext();

    return (
        <animated.div
            style={{
                backgroundImage: theme[themeStyle].black,
                height: '100%',
                width: '100%',
                overflowX: 'hidden',
            }}>
            {children}
        </animated.div>
    );
};

export { GradientBackground };
