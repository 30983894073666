import { createAction } from 'redux-act';
import { types, ITalentData, ISaveTalent } from './types';
import { IErrorType } from '../../constants/types';
import { IGenericDocumentModifier } from '../../types/global/helper';
import { ISearchPayload } from '../contest/types';

export const getSavedTalents = createAction(types.GET_SAVED_TALENTS);
export const getSavedTalentsSuccess = createAction<ITalentData[]>(types.GET_SAVED_TALENT_SUCCESS);
export const getSavedTalentsFail = createAction<IErrorType>(types.GET_SAVED_TALENT_FAIL);

export const saveTalent = createAction<ISaveTalent>(types.SAVE_TALENT);
export const saveTalentSuccess = createAction<ITalentData>(types.SAVE_TALENT_SUCCESS);
export const saveTalentFail = createAction<IErrorType>(types.SAVE_TALENT_FAIL);

export const updateTalent = createAction<IGenericDocumentModifier>(types.UPDATE_TALENT);
export const updateTalentSuccess = createAction<ITalentData>(types.UPDATE_TALENT_SUCCESS);
export const updateTalentFail = createAction<IErrorType>(types.UPDATE_TALENT_FAIL);

export const searchTalent = createAction<ISearchPayload>(types.SEARCH_TALENT);
export const searchTalentSuccess = createAction<ITalentData[]>(types.SEARCH_TALENT_SUCCESS);
export const searchTalentFail = createAction<IErrorType>(types.SEARCH_TALENT_FAIL);

export const deleteTalent = createAction<IGenericDocumentModifier>(types.DELETE_TALENT);
export const deleteTalentSuccess = createAction<IGenericDocumentModifier>(types.DELETE_TALENT_SUCCESS);
export const deleteTalentFail = createAction<IErrorType>(types.DELETE_TALENT_FAIL);
