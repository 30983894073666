export const pt_strings = {
    signin: 'Eu já tenho uma conta',
    signup_text: 'Sou novo aqui',
    create_account: 'Criar Conta',
    sign_in_now: 'Entrar Agora',
    create_an_account: 'Criar uma Conta',
    name: 'Nome (Nome completo)',
    your_email_address: 'Seu endereço de e-mail',
    phone_number: 'Número de telefone',
    create_a_password: 'Criar uma senha',
    already_have_an_account: 'Já tem uma conta?',
    terms: 'Ao entrar, você concorda com',
    conditions: 'Termos de uso do Thspian',
    splashScreenSubText: 'Audição. Filmes. Avaliações',
    boxofix: 'Boxofix',
    sign_in: 'Entrar',
    password: 'Senha',
    new_to_thspian: 'Novo no Thspian?',
    forgot_password: 'Esqueceu a senha?',
    select_country_code: 'Selecione o código do seu país',
    email_address: 'Endereço de e-mail',
    reset_password: 'Redefinir Senha',
    enter_new_password: 'Digite a nova senha',
    confirm_password: 'Confirmar senha',
    submit: 'Enviar',
    reset_successful: 'Link de Redefinição Enviado',
    reset_link: 'Enviamos um e-mail para seu_email com um link para redefinir sua senha',
    if_you_have_not: 'Se você não receber um e-mail após 10 minutos, verifique sua pasta de spam ou tente novamente.',
    confirm: 'Confirmar',
    enter_phone_code: 'Digite o código de verificação de seis (6) dígitos enviado para o seu telefone',
    email_error: 'o e-mail não é válido',
    phone_error: 'número de telefone não é válido',
    name_error: 'por favor, digite seu primeiro e último nome',
    password_confirm_error: 'as senhas não coincidem, por favor, tente novamente',
    existing_account:
        'Já existe uma conta com esse e-mail ou número de telefone, por favor, faça login ou esqueceu sua senha?',
    sign_in_with_email: 'Entrar com e-mail',
    sign_in_with_phone_number: 'Entrar com número de telefone',
    phone_sign_in_error_msg: 'número de telefone não é válido',
    email_sign_in_error_msg: 'e-mail não é válido',
    resend_code: 'Reenviar Código',
    similar_movies: 'Filmes Similares',
    watch: 'Assistir',
    trailer: 'TRAILER',
    summary: 'Resumo',
    release_date: 'Data de lançamento',
    director: 'Diretor',
    writer: 'Escritor',
    budget: 'Orçamento',
    opening_weekend: 'Fim de semana de estreia',
    gross: 'Bilheteria',
    add_review: 'Adicionar uma avaliação',
    like_this: 'Adicionar à lista de assistidos',
    share: 'Compartilhar',
    see_all_reviews: 'Ver todas as avaliações dos usuários',
    cast: 'Elenco',
    user_review: 'Avaliações dos Usuários',
    boxofix_rating: 'Avaliação do Boxofix',
    your_rating: 'Sua avaliação',
    rate_this: 'Avaliar isso',
    liked: 'Adicionado à lista de assistidos',
    search: 'Pesquisar',
    searchPlaceHolder: 'Pesquisar Filmes, Prêmios e Atores',
    movies: 'Filmes',
    actors: 'Atores e Atrizes',
    awards: 'Prêmios e Eventos',
    talent: 'Talentos',
    search_talents: 'Procurar Talentos',
    type_message: 'Digite sua mensagem aqui...',
    contribute: 'Contribuir para esta página',
    edit_page: 'Editar página',
    select_audition_role: 'Selecione um papel de audição, por exemplo, Video Vixen...',
    select_audition_location: 'Selecione uma localização...',
    age: 'Idade',
    required_media: 'Mídia necessária',
    gender: 'Gênero',
    character_description: 'Descrição do Personagem',
    apply_role: 'Aplicar para o Papel',
    profile: 'Perfil',
    watchlist: 'Lista de Assistidos',
    force_signin:
        'Faça login para acessar recursos do BOXOFIX, incluindo papéis de elenco, atualizações de filmes mais recentes, avaliar filmes e mais',
    sign_in_sign_up: 'Entrar / Cadastrar',
    dismiss: 'Dispensar',
    default_button_name: 'Título do Botão',
    account: 'Conta',
    my_profile: 'Perfil de Elenco',
    productions: 'Produções',
    contributions: 'Contribuições',
    trial: '7 dias de teste gratuito com qualquer plano!',
    unlimited: 'Publicação ilimitada de elencos',
    secure: 'Acesso a talentos e elencos',
    t_four: 'Suporte prioritário 24/7',
    no_ad: 'Sem limites de aplicação',
    preference: 'Preferência',
    filter: 'Filtro',
    no_card: 'Ops!!! Nenhum cartão ainda',
    add_card_sub: 'Adicione um novo cartão para habilitar suas transações no Boxofix',
    add_new_card: 'Adicionar novo cartão',
    wallet: 'Carteira',
    delete_card: 'Excluir Cartão',
    new_card: 'Novo Cartão Adicionado',
    card_already_exists: 'Cartão Já Existe',
    action_cancelled: 'Ação foi cancelada',
    next_billing_date: 'Próxima Data de Cobrança',
    payment_method: 'Método de Pagamento',
    last_payment: 'Último Pagamento',
    change: 'Alterar',
    cancel_subscription: 'Cancelar Assinatura',
    cancel_warning: 'Sua assinatura atual será cancelada se você prosseguir',
    go_back: 'Volte',
    proceed: 'Prosseguir',
    subscription_details: 'Detalhes da Assinatura',
    subscription_cancel: 'Sua assinatura #plan foi cancelada com sucesso. Seu plano será válido até #date',
    cancelBackButton: 'Voltar para Contas',
    subscription_action: 'Ação de Assinatura',
    subscription_caution: 'Deseja continuar?',
    subscription_success:
        'Você se inscreveu com sucesso no Boxofix. Quaisquer assinaturas ativas serão desabilitadas e substituídas',
    subscribe: 'Inscrever-se',
    max_application: 'Aplicações esgotadas.',
    max_app_body:
        'Você atingiu o limite máximo de aplicações permitidas com a assinatura Regular, fique à vontade para fazer upgrade.',
    no_subscription: 'Sem Assinatura',
    no_sub_body: 'Você não tem nenhuma assinatura ativa',
    no_actor_profile_body: 'Complete seu perfil de talento para se candidatar a audições',
    no_actor_profile: 'Nenhum Perfil de Talento',
    complete_profile: 'Completar Perfil',
    save_role: 'Salvar Papel',
    apply: 'Aplicar',
    applicants: 'candidatos',
    production_description: 'Descrição da Produção',
    casting_roles: 'Papéis de Elenco',
    job_details: 'Detalhes do Trabalho',
    language: 'Idioma',
    expires: 'Expira em',
    rehearsal_date: 'Data do Ensaio',
    tags: 'Tags',
    compensation: 'Compensação',
    tbd: 'A ser determinado',
    casting_agency: 'Agência de Elenco',
    attachments: 'Anexos',
    similar_roles: 'Papéis Semelhantes',
    saved: 'Salvo',
    applied: 'Aplicado',
    add_new_festival: 'Adicionar novo prêmio / festival',
    search_movies: 'Pesquisar Filmes, Séries de TV ...',
    search_actors: 'Pesquisar Atores, Atrizes, Personalidades da TV ...',
    search_awards: 'Pesquisar Eventos, Festivais, Prêmios ...',
    boxofix_page: 'Página do Boxofix',
    boxofix_page_description:
        'Crie uma biografia de filme, incluindo elenco e equipe, prêmios, problemas e notícias sobre o projeto.',
    casting_call: 'Convocação de Elenco',
    casting_call_description:
        'Publique um aviso de audição. Alcance milhares de novos talentos e dê início ao seu projeto',
    actors_biography: 'Biografia do Ator',
    actors_biography_description:
        'Crie a biografia de um ator ou atriz, incluindo trabalhos anteriores, prêmios, fotos, conquistas, etc.',
    talent_profile: 'Perfil de Talento',
    talent_profile_description:
        'Crie seu perfil de talento para permitir que agências de elenco e diretores o encontrem',
    search_crew: 'Pesquisar pessoas, funções da equipe, por exemplo, iluminador ...',
    search_award: 'Pesquisar categoria de prêmio, pessoas, por exemplo, Melhor filme ...',
    login_or_signup: 'Entrar / Cadastrar',
    firstName: 'Nome',
    lastName: 'Sobrenome',
    upload_video: 'Enviar Vídeo',
    apply_filter: 'Aplicar Filtro',
    clear_filters: 'Limpar Filtros',
    all_filters: 'Todos os Filtros',
    page_not_found: 'Parece que você está perdido no espaço',
    back: 'Voltar',
    my_rating: 'Minha avaliação',
    continue: 'Continuar',
    contest_description: 'Descrição do concurso',
    about_event: 'Sobre o Evento',
    judges: 'Juízes',
    create_contest: 'Criar concurso',
    more: 'Mais',
    no_submissions: 'Nenhuma submissão ainda',
    intro: 'Introdução',
    edit_bio: 'Editar biografia',
    joined_this_contest: 'aderiu a este concurso',
    recent_activities: 'Atividades recentes',
    commented_on_this_video: 'comentou neste vídeo',
    coming_soon: 'Em breve',
    live_contest: 'Concurso ao vivo',
    my_videos: 'Meus vídeos',
    recent_submissions: 'Submissões recentes',
    recommendations: 'Recomendações',
    top_performers: 'Melhores desempenhos',
    recently_watched: 'Assistido recentemente',
    statistics: 'Estatísticas',
    total_no_of_votes: 'Número total de votos',
    total_no_of_registration: 'Total de inscrições',
    submissions: 'Submissões',
    duration: 'Duração',
    edit_profile: 'Editar perfil',
    add_cover: 'Adicionar capa',
    create_project: 'Criar projeto',
    comedy: 'Comédia',
    pg_13: 'PG-13',
    friends: 'amigos',
    protected_page: 'Esta página é protegida pelo Google reCAPTCHA para garantir que você não é um robô.',
    learn_more: 'Saiba mais.',
    set_new_password: 'Definir nova senha',
    password_change_success: 'Mudança de senha bem-sucedida!',
    password_changed: 'Sua senha foi alterada! Você será redirecionado para a tela de login',
    reset_link_sent: 'Enviamos um link de redefinição',
    email_me: 'Me envie um e-mail',
    enter_email_to_reset_password:
        'Digite o endereço de e-mail associado à sua conta e enviaremos um link para redefinir sua senha',
    current_password: 'Senha atual',
    settings: 'Configurações',
    change_password: 'Alterar senha',
    use_secure_password:
        'Ao alterar sua senha, recomendamos fortemente que você use uma senha segura que não usa em nenhum outro lugar.',
    notifications: 'Notificações',
    subscriptions: 'Assinaturas',
    recommended_videos: 'Vídeos recomendados',
    mentions: 'Menções',
    shared_content: 'Conteúdo compartilhado',
    filter_by: 'Filtrar por',
    reset: 'Redefinir',
    show_results: 'Mostrar resultados',
    see_all: 'Ver tudo',
    menu: 'MENU',
    personal: 'PESSOAL',
    username: 'Nome de usuário',
    date_of_birth: 'Data de nascimento',
    sign_up: 'Inscrever-se',
    otp_verify_vote: 'Enviaremos um OTP para o seu número para verificar seu voto',
    questions_contact_us: 'Dúvidas? Entre em contato conosco',
    playlist: 'Lista de reprodução',
    cookie_preference: 'Preferência de cookies',
    drop_to_create_project: 'Solte aqui para criar projeto',
    faq: 'Perguntas frequentes',
    help_center: 'Centro de ajuda',
    privacy_policy: 'Política de privacidade',
    shop: 'Loja',
    terms_of_use: 'Termos de uso',
    recent_projects: 'projetos recentes',
    submission_status: 'status de submissão',
    my_projects: 'Meus Projetos',
    select_judges: 'Selecionar Juízes',
    requirements: 'Requisitos',
    prize: 'Prêmio',
    drop_file_to_upload: 'Solte o arquivo aqui para enviar',
    trending: 'Tendências',
};
