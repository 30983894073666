import produce from 'immer';
import { ReportResponse } from './actions';
import { types } from './types';

interface IInitialState {
    sendingReport: boolean;
    sendingReportError: any;
    report: Array<ReportResponse>;
    reportModalOpen: boolean;
}

const initialState: IInitialState = {
    sendingReport: false,
    sendingReportError: null,
    report: [],
    reportModalOpen: false,
};

const reducer = (state = initialState, action: any) => {
    const { type, payload } = action;
    switch (type) {
        case types.SEND_REPORT:
            return produce(state, draftState => {
                draftState.sendingReport = true;
            });
        case types.SEND_REPORT_SUCCESS:
            return produce(state, draftState => {
                draftState.sendingReport = false;
                draftState.report.push(payload);
            });
        case types.SEND_REPORT_FAIL:
            return produce(state, draftState => {
                draftState.sendingReport = false;
                draftState.sendingReportError = payload;
            });
        case types.OPEN_REPORT_MODAL:
            return produce(state, draftState => {
                draftState.reportModalOpen = payload;
            });
        default:
            return state;
    }
};

export { reducer };
