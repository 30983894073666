// Utility to detect network status
export function isOnline() {
    return window.navigator.onLine;
}

export function watchNetworkStatus(onOnline: () => void, onOffline: () => void) {
    window.addEventListener('online', onOnline);
    window.addEventListener('offline', onOffline);

    return () => {
        window.removeEventListener('online', onOnline);
        window.removeEventListener('offline', onOffline);
    };
}
