import { isEmpty } from 'lodash';
import { IHost } from '../../redux/discovery/types';
import { IUserSummary } from '../../types/global/helper';
import isEqual from 'lodash/isEqual';
import { ICreateProject } from '../../redux/project/types';
// import { dataURIToBlob } from '../../utils/mediaHelper';
// import Compressor from 'compressorjs';
// import { dataURIToBlob } from '../../utils/mediaHelper';
// import { blobToDataURL } from '../../components/ProfileHeaderUpload/helpers';

const EXCLUDED = ['jury', 'partner', 'host'];
export const parseContestPayload = (state: any, initialState: any = {}): any => {
    const payload: any = Object.keys(state)
        .filter(key => !isEqual(state[key], initialState[key]))
        .reduce((acc: any, key) => {
            acc[key] = state[key];
            return acc;
        }, {});

    EXCLUDED.forEach((element: string) => {
        if (state[element] !== undefined && !isEqual(state[element], initialState[element])) {
            if (element === 'jury') {
                payload[element] = state[element].map((el: IUserSummary) => el._id);
            }
            if (element === 'partner') {
                payload[element] = state[element].map((el: IHost) => el._id);
            }

            if (element === 'host') {
                payload[element] = state[element]._id;
            }
        }
    });

    return payload;
};

export const parseProjecPaload = (state: Record<string, any>): ICreateProject => {
    //@ts-expect-error
    const finalPayload: ICreateProject = {};
    Object.keys(state).forEach(key => {
        if (!isEmpty(state[key])) {
            if (key === 'feature_image' && !isEmpty(state[key])) {
                /** The below I commented out because we handle the compression inside the thumbnail */
                // new Compressor(state[key], {
                //     quality: 0.4, // 0.6 can also be used, but its not recommended to go below.
                //     success: async (compressedResult: File) => {
                //         // Use the compressed file to upload the images to your server.
                //         const file = new File([compressedResult], compressedResult.name as any);
                //         //@ts-expect-error
                //         finalPayload[key as unknown as keyof ICreateProject] = compressedResult;
                //     },
                // });
            } else {
            }
            finalPayload[key as keyof ICreateProject] = state[key];
        }
    });
    return finalPayload;
};
