import React from 'react';
import { Text, View, StyleSheet, StyleProp, ViewStyle } from 'react-native';
import { ColorScheme, theme } from '../../constants/theme';
import { ThemeContext } from '../../module/ThemeManager';
import { Fonts, GenericSize } from '../../module/types';
import { FONT_ENUM } from '../../types/global/fonts.types';

interface Props {
    text: string;
    style?: StyleProp<ViewStyle>;
}

const Divider = ({ text, style }: Props) => (
    <ThemeContext.Consumer>
        {({ themeStyle, sizes, device }) => {
            const { genericSize, font } = sizes;
            const styles = dynamicStyle(themeStyle, font, genericSize);
            return (
                <View style={[styles.container, style]}>
                    <View style={styles.divider} />
                    <View>
                        <Text style={styles.textStyle}>{text}</Text>
                    </View>
                    <View style={styles.divider} />
                </View>
            );
        }}
    </ThemeContext.Consumer>
);

export { Divider };

const dynamicStyle = (colorScheme: ColorScheme, font: Fonts, genericSize: GenericSize) =>
    StyleSheet.create({
        container: {
            flexDirection: 'row',
            alignItems: 'center',
            width: '100%',
            marginVertical: 15,
            alignSelf: 'center',
        },
        divider: {
            flex: 1,
            height: 0.1,
            backgroundColor: theme[colorScheme].light_gray_text,
        },
        textStyle: {
            flex: 1,
            textAlign: 'center',
            fontFamily: FONT_ENUM.MAIN_MEDIUM,
            color: theme[colorScheme].light_blue,
            fontSize: font._fontSize12,
            paddingHorizontal: genericSize._size16,
            fontWeight: 'bold',
        },
    });
