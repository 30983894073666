import { createBrowserRouter } from 'react-router-dom';
import { all, getContext, put, takeLatest } from 'redux-saga/effects';
import { INavigate, types } from './types';
import { Action } from 'redux';
import { getJWtDetails } from '../../utils/AppUtils';
import { refreshToken } from '../refreshToken/action';

function* navigator({ payload }: { payload: INavigate }): any {
    const router: ReturnType<typeof createBrowserRouter> = yield getContext('router');
    const { isSignedIn } = getJWtDetails();
    if (!isSignedIn && !payload.isRefreshed && !payload.skipAuthentication) {
        yield put(refreshToken({ isNavigate: true, payload: payload.routes }));
    } else {
        /**
         * When Navigating with Params, use the param and triger the necessary action
         * Then call the navigate object after that.
         * Example
         * navigating to a contest detail, comes with it's contestId, call get contest details first
         * Then navigate to the page
         */
        yield router.navigate(payload.routes, {
            preventScrollReset: true,
            state: {
                contestId: payload.params,
            },
        });
    }
}

interface TaskAction extends Action, INavigate {
    type: string;
    payload: INavigate;
}

function* navigatorWatcher() {
    yield takeLatest<TaskAction>(types.NAVIGATE, navigator);
}

export default function* navigationSaga() {
    yield all([navigatorWatcher()]);
}
