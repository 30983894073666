import produce from 'immer';
import { types, IComingSoonInitialState, IComingSoonResponse } from './types';

const initialState: IComingSoonInitialState = {
    loadingComingSoon: false,
    comingSoon: [],
    trending: [],
    errMsg: null,
};

const reducer = (state = initialState, action: any) => {
    const { type, payload } = action;
    switch (type) {
        case types.GET_COMING_SOON:
            return produce(state, draftState => {
                draftState.loadingComingSoon = true;
            });
        case types.GET_COMING_SOON_SUCCESS:
            return produce(state, draftState => {
                const { coming_soon, trending } = payload as IComingSoonResponse;
                draftState.loadingComingSoon = false;
                draftState.comingSoon = coming_soon;
                draftState.trending = trending;
            });
        case types.GET_COMING_SOON_FAIL:
            return produce(state, draftState => {
                draftState.errMsg = payload;
                draftState.loadingComingSoon = false;
            });

        default:
            return state;
    }
};

export { reducer };
