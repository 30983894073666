import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { StyleSheet } from 'react-native';
import Typography from '@mui/material/Typography';
import { UserSummary } from '../../types/global/helper';
import { useThemeContext } from '../../hook/useThemeManager';
import { ColorScheme, theme } from '../../constants/theme';
import VerificationBadge from '../CustomSVGIcons/VerificationBadge';
import { createFullName } from '../../utils/AppUtils';
import { FONT_ENUM } from '../../types/global/fonts.types';
import { DisplayChipGroup } from '../Chip/DisplayChipGroup';
import { isEmpty } from '../../utils/lodash';
import { avatar_female, avatar_male } from '../../asset/images';
import CustomLazyLoadImage from '../Image/CustomLazyLoadImage';

export interface IUserCardProps {
    user: UserSummary;
    onClickUserCard?: (user: UserSummary) => void;
}

export const UserCard: React.FC<IUserCardProps> = ({ user, onClickUserCard }) => {
    const { themeStyle } = useThemeContext();
    const styles = dynamicStyles(themeStyle);
    const selectedAvatar = user.gender === 'female' ? avatar_female : avatar_male;
    return (
        <Card
            onClick={() => onClickUserCard && onClickUserCard(user)}
            sx={{
                maxWidth: {
                    xs: '100%',
                    sm: 345,
                },
                height: 280,
                backgroundColor: theme[themeStyle].deep_grey_lighter,
                position: 'relative',
                cursor: 'pointer',
                overflow: 'hidden',
            }}>
            <CustomLazyLoadImage
                effect="blur"
                src={user.profile_picture ?? selectedAvatar}
                alt={`${user.firstName}_image`}
                showGradient
                width={'100%'}
                height={'100%'}
                style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    objectFit: 'cover',
                    height: '100%',
                    zIndex: -1,
                }}
            />
            <CardContent
                sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-end',
                    background: 'linear-gradient(transparent, black)',
                }}>
                <Typography
                    variant="h5"
                    component="span"
                    color="white"
                    style={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        ...styles.textHandle,
                    }}>
                    {createFullName(user)}
                </Typography>

                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Typography style={styles.share}>@{user.userName}</Typography>
                    {user.verified && <VerificationBadge />}
                </div>
                {user.user_type && !isEmpty(user.user_type) && (
                    <DisplayChipGroup
                        items={user.user_type}
                        maxChips={1}
                        fontSize={8}
                        style={{
                            marginTop: '5px',
                        }}
                    />
                )}
            </CardContent>
        </Card>
    );
};

const dynamicStyles = (colorScheme: ColorScheme) =>
    StyleSheet.create({
        shareBox: {
            width: 'fit-content',
            height: 'full',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
        },
        flexRow: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
        },
        flexColumn: {
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'center',
        },
        share: {
            fontSize: 14,
            fontWeight: '500',
            fontFamily: FONT_ENUM.MAIN_REGULAR,
            color: theme[colorScheme].highlight,
        },
        textHandle: {
            fontSize: 16,
            fontWeight: '500',
            fontFamily: FONT_ENUM.REGULAR_HEADER,
        },
    });
