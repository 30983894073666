import { StyleSheet, View } from 'react-native';
import React from 'react';
import { Pressable } from '../Pressable/Pressable';
import { colors } from '../../constants/theme';
import { FONT_ENUM } from '../../types/global/fonts.types';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { AccountMenu } from './AccountMenu';

interface ILoginOrSignUpProps {
    onPress: () => void;
    isMobile?: boolean;
    onSignInPress: () => void;
}

const LoginOrSignUpHolder = ({ onPress, isMobile, onSignInPress }: ILoginOrSignUpProps) => {
    return (
        <ClickAwayListener onClickAway={() => {}}>
            <Pressable onPress={onPress} defaultOpacity={1}>
                <View style={styles.textWrapper}>
                    <AccountMenu
                        style={styles.headerText}
                        isMobile={isMobile}
                        onPress={onPress}
                        onSignInPress={onSignInPress}
                    />
                </View>
            </Pressable>
        </ClickAwayListener>
    );
};

export { LoginOrSignUpHolder };

const styles = StyleSheet.create({
    login_signup: {
        fontFamily: FONT_ENUM.REGULAR_HEADER,
        fontSize: 12,
        color: colors.primary,
        textTransform: 'uppercase',
    },
    textWrapper: { paddingHorizontal: 20, alignItems: 'center' },
    headerText: {
        fontFamily: FONT_ENUM.REGULAR_HEADER,
        fontSize: 14,
        color: colors.dark_grey,
        textTransform: 'uppercase',
        textDecorationLine: 'none',
        flex: 1,
    },
});
