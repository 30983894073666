/* eslint-disable no-unused-vars */
export enum SOCKET_REQUESTS {
    CONNECTION = 'connection',
    SEND_MESSAGE = 'sendMessage',
    GET_ALL_MESAGES = 'getMessages',
    GET_SINGLE_MESSAGE = 'getSingleMessage',
    BROADCAST = 'broadcast',
    DISCONNECT = 'disconnect',
    CONNECT = 'connect',
    MESSAGE = 'messaage',
    NEW_MESSAGE = 'newMessage',
    RETRIEVE_MESSAGES = 'retrieveMessages',
    RETRIEVE_ROOMS = 'retrieveRooms',
    USER_TYPING = 'userTyping',
    JOIN_ROOM = 'joinRoom',
    JOIN_ALL_ROOMS = 'joinAllRooms',
    USER_HAS_READ = 'userHasRead',
    ROOM_MESSAGES = 'roomMessages',
    GET_ROOM_MESSAGES = 'getRoomMessages',
    SET_ONLINE_USERS = 'setOnlineUsers',
    SET_OFFLINE_USERS = 'setOfflineUsers',
    ONLINE = 'online',
    OFFLINE = 'offline',
    NEW_CONTENT_AVAILABLE = 'newContentAvailable',
    NEW_CONTENT = 'newContent',
    CLEAR_CACHE = 'clearCache',
    MEDIA_PROCESSING_COMPLETE = 'mediaProcessingComplete',
    FOLLOW_THSPIAN = 'followThspian',
    NEW_FOLLOWER = 'newFollower',
    TEST = 'test',
    NEW_ACTIVITY = 'newActivity',
}
