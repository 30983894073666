import { StyleSheet } from 'react-native';
import { Box, Typography, Skeleton } from '@mui/material';
import { useThemeContext } from '../../hook/useThemeManager';
import { ColorScheme, theme } from '../../constants/theme';
import { FONT_ENUM } from '../../types/global/fonts.types';

const EsProfileMyProjectsGridLoadingState = () => {
    const { themeStyle } = useThemeContext();
    const styles = dynamicStyles(themeStyle);

    return (
        <Box
            style={{
                width: '100%',
                height: 'auto',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                justifyContent: 'center',
                gap: '22px',
                backgroundColor: theme[themeStyle].gray_text,
                borderRadius: '10px',
                padding: '30px',
            }}>
            <Typography style={styles.text}>Projects</Typography>
            <Box sx={{ display: 'block', width: '100%' }}>
                <Skeleton
                    variant="rounded"
                    sx={{
                        fontSize: '1rem',
                        backgroundColor: theme[themeStyle].darkGray60,
                        width: '100%',
                        height: 300,
                        marginBottom: 2,
                    }}
                />
                <Skeleton
                    variant="text"
                    sx={{ fontSize: '1rem', backgroundColor: theme[themeStyle].darkGray60, width: '100%' }}
                />
                <Skeleton
                    variant="text"
                    sx={{ fontSize: '1rem', backgroundColor: theme[themeStyle].darkGray60, width: '90%' }}
                />
                <Skeleton
                    variant="text"
                    sx={{ fontSize: '1rem', backgroundColor: theme[themeStyle].darkGray60, width: '40%' }}
                />
            </Box>
        </Box>
    );
};

const dynamicStyles = (colorScheme: ColorScheme) =>
    StyleSheet.create({
        text: {
            textAlign: 'left',
            fontSize: 24,
            fontStyle: 'normal',
            fontWeight: '600',
            color: theme[colorScheme].filters_white,
            fontFamily: FONT_ENUM.MAIN_BOLD,
        },
    });

export { EsProfileMyProjectsGridLoadingState };
