import React, { useState } from 'react';
import { StyleSheet, View, Image } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { Container } from '@mui/material';

import { currentPageSelector } from './selector';
import { header_menu, Menu } from '../../configurations/headerConfiguration';
import { colors } from '../../constants/theme';
import { ThemeContext } from '../../module/ThemeManager';
import { setPage } from '../../redux/appConfigurator/action';
import { FONT_ENUM } from '../../types/global/fonts.types';
import { ROUTES } from '../../types/global/routes.types';
import { Pressable } from '../Pressable/Pressable';
import { logoWithIcon } from '../../asset/images/index';
import { LoginOrSignUpHolder } from './LoginOrSignUpHolder';

interface IHeaderProps {
    disableInteraction?: boolean;
}

const Header = ({ disableInteraction }: IHeaderProps) => {
    const currentPage = useSelector(currentPageSelector);
    const [activeTab, setActiveTab] = useState(currentPage);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const handleOnCick = (menu: Menu, index: number) => {
        setActiveTab(index);
        dispatch(setPage({ pageIndex: index }));
    };
    return (
        <ThemeContext.Consumer>
            {({ device, sizes }) => {
                return (
                    <View style={styles.container}>
                        <Container
                            sx={{
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                display: 'flex',
                            }}>
                            <View style={styles.textWrapper}>
                                <Link style={styles.logo} to={ROUTES.HOME}>
                                    <Image
                                        source={logoWithIcon}
                                        style={{ width: sizes.genericSize._size30, height: sizes.genericSize._size30 }}
                                    />
                                </Link>
                            </View>
                            <View style={styles.headerWrapper}>
                                {header_menu.map((menu, index) => (
                                    <Pressable
                                        style={styles.textSpacer}
                                        onPress={() => handleOnCick(menu, index)}
                                        key={menu.title}
                                        disabled={disableInteraction}
                                        isClicked={activeTab === index}>
                                        <Link style={styles.headerText} to={menu.route}>
                                            {menu.title}
                                        </Link>
                                    </Pressable>
                                ))}
                            </View>
                            <LoginOrSignUpHolder onPress={() => {}} onSignInPress={() => navigate(ROUTES.LOGIN_IN)} />
                        </Container>
                    </View>
                );
            }}
        </ThemeContext.Consumer>
    );
};

export { Header };

const styles = StyleSheet.create({
    container: {
        backgroundColor: 'rgba(0,0,0,0.8)',
        height: 80,
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'space-between',
        zIndex: 999,
        width: '100%',
        borderBottomRightRadius: 20,
        borderBottomLeftRadius: 20,
    },
    headerWrapper: {
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    headerText: {
        fontFamily: FONT_ENUM.REGULAR_HEADER,
        fontSize: 12,
        color: colors.primary,
        textTransform: 'uppercase',
        textDecorationLine: 'none',
    },
    textSpacer: {
        marginHorizontal: 10,
    },
    login_signup: {
        fontFamily: FONT_ENUM.REGULAR_HEADER,
        fontSize: 12,
        color: colors.primary,
        textTransform: 'uppercase',
    },
    logo: {
        fontFamily: FONT_ENUM.REGULAR_HEADER,
        fontSize: 12,
        color: colors.primary,
        textTransform: 'uppercase',
        textDecorationLine: 'none',
    },
    textWrapper: { paddingHorizontal: 20, alignItems: 'center' },
});
