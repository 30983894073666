export const ThspianFullLogo = ({ fill = '#e68411', ...rest }: React.SVGProps<SVGSVGElement>) => (
    <svg id="a" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 4" {...rest}>
        <path
            d="m9.53.57h1.1v.22s.02,0,.03-.01c.28-.18.58-.23.9-.21.37.03.69.17.96.43.25.24.41.53.47.86.11.54.05,1.05-.26,1.51-.28.41-.67.64-1.17.69-.33.03-.64-.03-.91-.21,0,0,0,0-.02,0v.95h-1.1V.57h0Zm1.78,1.1c-.36,0-.67.29-.69.62-.02.39.34.68.68.69.46,0,.65-.36.65-.65,0-.38-.27-.65-.64-.66h0Z"
            fill={fill}
            strokeWidth="0"
        />
        <path
            d="m16.72,4.2c-.32-.01-.65-.03-.97-.05-.17-.01-.34-.06-.49-.15-.2-.14-.3-.34-.32-.57-.03-.23-.04-.45-.04-.68,0-.35,0-.71,0-1.06,0-.19.02-.38.07-.57.09-.3.28-.48.59-.54.21-.05.43-.06.65-.06.4,0,.8,0,1.21,0,.18,0,.37.03.55.07.31.07.49.28.56.59.06.26.06.52.06.77,0,.31,0,.61,0,.92,0,.22-.01.45-.07.67-.07.32-.28.52-.6.59-.21.05-.43.06-.65.06-.18,0-.36,0-.54,0-.12,0-.12,0,0,0h0Zm.35-1.38s.02.03.03.04c.1.15.24.23.42.21s.31-.12.38-.29c.13-.37.1-.72-.1-1.06-.17-.28-.41-.47-.72-.55-.36-.1-.71-.05-1.02.15-.22.14-.38.33-.48.57-.09.21-.11.43-.08.66.05.31.2.57.44.77.22.18.48.28.76.28.09,0,.16-.05.17-.13,0-.08-.05-.14-.15-.15-.06,0-.12,0-.17-.02-.39-.1-.64-.33-.74-.72-.13-.48.17-1.02.66-1.15.37-.1.7-.02.97.25.28.28.34.62.23.99-.03.09-.12.14-.21.13-.08-.01-.13-.08-.13-.18,0-.09,0-.18,0-.27,0-.28-.21-.52-.47-.56-.28-.05-.54.09-.65.36-.15.37.13.79.53.79.13,0,.24-.03.35-.11h0Z"
            fill={fill}
            strokeWidth="0"
        />
        <path
            d="m2.25,0h1.09v1.06s.04-.03.05-.05c.3-.28.65-.45,1.07-.44.48,0,.81.25,1.01.68.11.24.17.49.17.75,0,.68,0,1.36,0,2.05,0,0,0,0,0,.02h-1.09v-1.73c0-.1,0-.21-.05-.31-.12-.3-.41-.43-.73-.33-.26.09-.44.34-.44.62v1.75h-1.1c0-1.35,0-2.7,0-4.06h0Z"
            fill={fill}
            strokeWidth="0"
        />
        <path
            d="m19.98.57v.49s.04-.03.05-.05c.3-.29.66-.45,1.07-.44.48,0,.81.25,1,.68.11.24.17.49.17.76,0,.68,0,1.36,0,2.04,0,0,0,0,0,.02h-1.09v-1.73c0-.1,0-.21-.05-.31-.12-.3-.41-.44-.73-.33-.26.09-.44.34-.44.62v1.75h-1.09V.57h1.1,0Z"
            fill={fill}
            strokeWidth="0"
        />
        <path
            d="m5.98,3.68c.24-.3.48-.59.72-.9.23.15.48.22.75.26.13.02.26.03.39,0,.02,0,.04-.01.07-.02.04-.02.07-.05.06-.09,0-.03-.03-.07-.06-.08-.09-.04-.17-.07-.27-.09-.26-.07-.52-.14-.78-.21-.22-.07-.42-.17-.6-.32-.41-.34-.35-.99.03-1.3.2-.15.42-.24.66-.29.7-.12,1.38-.05,2.03.23.05.02.1.05.15.08,0,0,.02.01.03.02-.24.3-.48.59-.72.89-.29-.18-.6-.26-.93-.27-.08,0-.16.01-.24.02-.05,0-.09.04-.09.1,0,.05.04.08.08.09.14.04.29.08.43.12.28.07.56.13.83.25.15.07.28.16.41.28.21.2.27.45.24.72-.04.38-.28.6-.61.74-.21.09-.44.12-.66.14-.24.03-.49.03-.74.01-.39-.03-.77-.13-1.11-.33-.01,0-.03-.02-.04-.03,0,0,0-.01-.01-.02h0Z"
            fill={fill}
            strokeWidth="0"
        />
        <path
            d="m1.86,3.03v1.03s0,0-.01,0c-.31,0-.62,0-.92-.07-.26-.06-.49-.16-.66-.38-.14-.18-.21-.38-.24-.6-.02-.14-.03-.28-.03-.43C0,1.75,0,.91,0,.07v-.07h1.1v.56h.76v.79h-.76v1.22c0,.25.1.37.34.41.11.02.23.02.34.03.02,0,.04,0,.07,0h0Z"
            fill={fill}
            strokeWidth="0"
        />
        <path d="m14.5,4.07h-1.08V1.01c.14.17.32.25.55.25.22,0,.4-.09.54-.27v3.07Z" fill={fill} strokeWidth="0" />
        <path
            d="m13.95,1.12c-.3,0-.55-.24-.55-.53s.24-.55.54-.55.55.25.55.55c0,.29-.24.53-.55.53Z"
            fill={fill}
            strokeWidth="0"
        />
        <path
            d="m17.04,2.35c0,.17-.14.31-.3.31s-.31-.14-.31-.3.14-.31.3-.31c.16,0,.31.14.31.3Z"
            fill={fill}
            strokeWidth="0"
        />
    </svg>
);
