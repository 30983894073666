import React from 'react';
import { StyleSheet } from 'react-native';
import { Box, Typography } from '@mui/material';
import { useThemeContext } from '../../hook/useThemeManager';
import { ColorScheme, theme } from '../../constants/theme';
import { FONT_ENUM } from '../../types/global/fonts.types';
import { UserSummary } from '../../types/global/helper';
import { IUserCardProps, UserCard } from '../EsCards/NewUserCard';
import { isEmpty } from '../../utils/lodash';

export interface IUserCarousel extends Partial<Omit<IUserCardProps, 'user'>> {
    userData?: UserSummary[];
    title?: string;
}

const UserCarousel: React.FC<IUserCarousel> = props => {
    const { userData, title } = props;
    const { themeStyle } = useThemeContext();
    const styles = dynamicStyles(themeStyle);

    return (
        <Box
            style={{
                width: '100%',
                height: 'auto',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                justifyContent: 'flex-start',
                gap: '36px',
            }}>
            <Typography style={styles.text}>{title}</Typography>
            <Box
                style={{ width: '100%', height: 'fit-content', display: 'grid', gap: '15px', gridAutoRows: '1fr' }}
                sx={{
                    gridTemplateColumns: {
                        xs: '100%',
                        sm: 'repeat(2, 1fr)',
                        md: 'repeat(3, 1fr)',
                        lg: 'repeat(4, 1fr)',
                    },
                }}>
                {userData &&
                    !isEmpty(userData) &&
                    userData.map(item => <UserCard user={item} key={item._id} {...props} />)}
            </Box>
        </Box>
    );
};

UserCarousel.defaultProps = {
    title: 'Talent',
};

const dynamicStyles = (colorScheme: ColorScheme) =>
    StyleSheet.create({
        text: {
            textAlign: 'left',
            fontSize: 24,
            fontStyle: 'normal',
            fontWeight: '600',
            color: theme[colorScheme].filters_white,
            fontFamily: FONT_ENUM.MAIN_BOLD,
        },
    });

export { UserCarousel };
