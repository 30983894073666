export const fr_string = {
    signin: "J'ai déjà un compte",
    signup_text: 'Je suis nouveau ici',
    create_account: 'Créer un compte',
    sign_in_now: 'Se connecter maintenant',
    create_an_account: 'Créer un compte',
    name: 'Nom (prénom et nom)',
    your_email_address: 'Votre adresse e-mail',
    phone_number: 'Numéro de téléphone',
    create_a_password: 'Créer un mot de passe',
    already_have_an_account: 'Vous avez déjà un compte?',
    terms: 'En vous connectant, vous acceptez',
    conditions: "Conditions d'utilisation de Thspian",
    splashScreenSubText: 'Auditions. Films. Critiques',
    boxofix: 'Boxofix',
    sign_in: 'Se connecter',
    password: 'Mot de passe',
    new_to_thspian: 'Nouveau sur Thspian?',
    forgot_password: 'Mot de passe oublié?',
    select_country_code: 'Sélectionnez votre indicatif de pays',
    email_address: 'Adresse e-mail',
    reset_password: 'Réinitialiser le mot de passe',
    enter_new_password: 'Saisissez le nouveau mot de passe',
    confirm_password: 'Confirmez le mot de passe',
    submit: 'Soumettre',
    reset_successful: 'Lien de réinitialisation envoyé',
    reset_link: 'Nous avons envoyé un e-mail à votre adresse e-mail avec un lien pour réinitialiser votre mot de passe',
    if_you_have_not:
        "Si vous n'avez pas reçu d'e-mail après 10 minutes, veuillez vérifier votre dossier de courrier indésirable ou réessayer.",
    confirm: 'Confirmer',
    enter_phone_code: 'Entrez le code de vérification à six (6) chiffres envoyé à votre téléphone',
    email_error: "l'e-mail n'est pas valide",
    phone_error: 'numéro de téléphone non valide',
    name_error: 'veuillez entrer votre prénom et votre nom de famille',
    password_confirm_error: 'les mots de passe ne correspondent pas, veuillez réessayer',
    existing_account:
        'Un compte existe déjà avec cette adresse e-mail ou ce numéro de téléphone, veuillez vous connecter ou avez-vous oublié votre mot de passe?',
    sign_in_with_email: "Se connecter avec l'e-mail",
    sign_in_with_phone_number: 'Se connecter avec le numéro de téléphone',
    phone_sign_in_error_msg: 'numéro de téléphone non valide',
    email_sign_in_error_msg: 'e-mail non valide',
    resend_code: 'Renvoyer le code',
    similar_movies: 'Films similaires',
    watch: 'Regarder',
    trailer: 'BANDE ANNONCE',
    summary: 'Résumé',
    release_date: 'Date de sortie',
    director: 'Réalisateur',
    writer: 'Scénariste',
    budget: 'Budget',
    opening_weekend: "Weekend d'ouverture",
    gross: 'Recettes brutes',
    add_review: 'Ajouter une critique',
    like_this: 'Ajouter à la liste de suivi',
    share: 'Partager',
    see_all_reviews: 'Voir toutes les critiques des utilisateurs',
    cast: 'Distribution',
    user_review: 'Critiques des utilisateurs',
    boxofix_rating: 'Note Boxofix',
    your_rating: 'Votre note',
    rate_this: 'Évaluez ceci',
    liked: 'Ajouté à la liste de suivi',
    search: 'Rechercher',
    searchPlaceHolder: 'Rechercher des films, des prix et des acteurs',
    movies: 'Films',
    actors: 'Acteurs et actrices',
    awards: 'Prix et événements',
    talent: 'Talents',
    search_talents: 'Rechercher des talents',
    type_message: 'Tapez votre message ici...',
    contribute: 'Contribuer à cette page',
    edit_page: 'Modifier la page',
    select_audition_role: "Sélectionnez un rôle d'audition, par exemple Video Vixen...",
    select_audition_location: 'Sélectionnez un lieu...',
    age: 'Âge',
    required_media: 'Médias requis',
    gender: 'Genre',
    character_description: 'Description du personnage',
    apply_role: 'Postuler pour le rôle',
    profile: 'Profil',
    watchlist: 'Liste de suivi',
    force_signin:
        'Connectez-vous pour accéder aux fonctionnalités de BOXOFIX, y compris les rôles de casting, les dernières mises à jour de films, les critiques de films, et plus encore',
    sign_in_sign_up: "Se connecter / S'inscrire",
    dismiss: 'Fermer',
    default_button_name: 'Titre du bouton',
    account: 'Compte',
    my_profile: 'Profil de casting',
    productions: 'Productions',
    contributions: 'Contributions',
    trial: "Essai gratuit de 7 jours avec n'importe quel abonnement !",
    unlimited: 'Publication illimitée de castings',
    secure: 'Accès aux talents et aux castings',
    t_four: 'Support prioritaire 24/7',
    no_ad: "Pas de limites d'application",
    preference: 'Préférence',
    filter: 'Filtre',
    no_card: 'Oops !!! Pas encore de carte',
    add_card_sub: 'Ajoutez une nouvelle carte pour pouvoir effectuer des transactions sur Boxofix',
    add_new_card: 'Ajouter une nouvelle carte',
    wallet: 'Portefeuille',
    delete_card: 'Supprimer la carte',
    new_card: 'Nouvelle carte ajoutée',
    card_already_exists: 'La carte existe déjà',
    action_cancelled: 'Action annulée',
    next_billing_date: 'Prochaine date de facturation',
    payment_method: 'Méthode de paiement',
    last_payment: 'Dernier paiement',
    change: 'Modifier',
    cancel_subscription: "Annuler l'abonnement",
    cancel_warning: 'Votre abonnement actuel sera annulé si vous continuez',
    go_back: 'Retourner',
    proceed: 'Continuer',
    subscription_details: "Détails de l'abonnement",
    subscription_cancel: "Votre abonnement #plan a été annulé avec succès. Votre plan sera actif jusqu'au #date",
    cancelBackButton: 'Retour aux comptes',
    subscription_action: "Action d'abonnement",
    subscription_caution: 'Souhaitez-vous continuer?',
    subscription_success:
        'Vous vous êtes abonné avec succès à Boxofix. Toutes les abonnements actifs seront désactivées et remplacées',
    subscribe: "S'abonner",
    max_application: 'Applications épuisées',
    max_app_body:
        "Vous avez atteint le nombre maximum d'applications autorisées avec l'abonnement régulier, n'hésitez pas à passer à un abonnement supérieur",
    no_subscription: 'Aucun abonnement',
    no_sub_body: "Vous n'avez aucun abonnement actif",
    no_actor_profile_body: 'Veuillez compléter votre profil de talent pour postuler aux auditions',
    no_actor_profile: 'Aucun profil de talent',
    complete_profile: 'Compléter le profil',
    save_role: 'Enregistrer le rôle',
    apply: 'Postuler',
    applicants: 'candidats',
    production_description: 'Description de la production',
    casting_roles: 'Rôles de casting',
    job_details: 'Détails du poste',
    language: 'Langue',
    expires: 'Expire',
    rehearsal_date: 'Date de répétition',
    tags: 'Tags',
    compensation: 'Rémunération',
    tbd: 'À déterminer',
    casting_agency: 'Agence de casting',
    attachments: 'Pièces jointes',
    similar_roles: 'Rôles similaires',
    saved: 'Enregistré',
    applied: 'Postulé',
    add_new_festival: 'Ajouter un nouveau prix / festival',
    search_movies: 'Rechercher des films, des séries TV...',
    search_actors: 'Rechercher des acteurs, des actrices, des personnalités de la télévision...',
    search_awards: 'Rechercher des événements, des festivals, des prix...',
    boxofix_page: 'Page Boxofix',
    boxofix_page_description:
        "Créez une biographie de film, comprenant la distribution et l'équipe, les récompenses, les problèmes et les actualités autour du projet.",
    casting_call: 'Casting',
    casting_call_description:
        'Publiez une annonce de casting. Atteignez des milliers de nouveaux talents et lancez votre projet',
    actors_biography: "Biographie de l'acteur",
    actors_biography_description:
        "Créez une biographie d'acteur ou d'actrice, comprenant les travaux passés, les récompenses, les photos, les réalisations, etc.",
    talent_profile: 'Profil de talent',
    talent_profile_description:
        'Créez votre profil de talent pour permettre aux agences de casting et aux réalisateurs de vous trouver',
    search_crew: "Rechercher des personnes, des postes d'équipe, par exemple éclairagiste...",
    search_award: 'Rechercher des catégories de prix, des personnes, par exemple Meilleur film...',
    login_or_signup: 'Connexion / Inscription',
    firstName: 'Prénom',
    lastName: 'Nom de famille',
    upload_video: 'Télécharger la vidéo',
    apply_filter: 'Appliquer le filtre',
    clear_filters: 'Effacer les filtres',
    all_filters: 'Tous les filtres',
    page_not_found: "On dirait que tu es perdu dans l'espace",
    back: 'Retour',
    my_rating: 'Ma note',
    continue: 'Continuer',
    contest_description: 'Description du concours',
    about_event: "À propos de l'événement",
    judges: 'Juges',
    create_contest: 'Créer un concours',
    more: 'Plus',
    no_submissions: 'Aucune soumission pour le moment',
    intro: 'Introduction',
    edit_bio: 'Modifier la biographie',
    joined_this_contest: 'a rejoint ce concours',
    recent_activities: 'Activités récentes',
    commented_on_this_video: 'a commenté cette vidéo',
    coming_soon: 'Prochainement',
    live_contest: 'Concours en direct',
    my_videos: 'Mes vidéos',
    recent_submissions: 'Soumissions récentes',
    recommendations: 'Recommandations',
    top_performers: 'Meilleurs participants',
    recently_watched: 'Récemment visionné',
    statistics: 'Statistiques',
    total_no_of_votes: 'Nombre total de votes',
    total_no_of_registration: 'Total des inscriptions',
    submissions: 'Soumissions',
    duration: 'Durée',
    edit_profile: 'Modifier le profil',
    add_cover: 'Ajouter une couverture',
    create_project: 'Créer un projet',
    comedy: 'Comédie',
    pg_13: 'PG-13',
    friends: 'amis',
    protected_page: "Cette page est protégée par Google reCAPTCHA pour vous assurer que vous n'êtes pas un robot.",
    learn_more: ' En savoir plus.',
    set_new_password: 'Définir un nouveau mot de passe',
    password_change_success: 'Changement de mot de passe réussi !',
    password_changed: "Votre mot de passe a été changé ! Vous serez redirigé vers l'écran de connexion",
    reset_link_sent: 'Nous vous avons envoyé un lien de réinitialisation',
    email_me: "M'envoyer un e-mail",
    enter_email_to_reset_password:
        "Entrez l'adresse e-mail associée à votre compte et nous vous enverrons un lien pour réinitialiser votre mot de passe",
    current_password: 'Mot de passe actuel',
    settings: 'Paramètres',
    change_password: 'Changer le mot de passe',
    use_secure_password:
        "Lorsque vous changez votre mot de passe, nous vous recommandons vivement d'utiliser un mot de passe sécurisé que vous n'utilisez nulle part ailleurs.",
    notifications: 'Notifications',
    subscriptions: 'Abonnements',
    recommended_videos: 'Vidéos recommandées',
    mentions: 'Menções',
    shared_content: 'Contenu partagé',
    filter_by: 'Filtrer par',
    reset: 'Réinitialiser',
    show_results: 'Afficher les résultats',
    see_all: 'Voir tout',
    menu: 'MENU',
    personal: 'PERSONNEL',
    username: "Nom d'utilisateur",
    date_of_birth: 'Date de naissance',
    sign_up: "S'inscrire",
    otp_verify_vote: 'Nous enverrons un OTP à votre numéro pour vérifier votre vote',
    questions_contact_us: 'Des questions ? Contactez-nous',
    playlist: 'Playlist',
    cookie_preference: 'Préférence de cookies',
    drop_to_create_project: 'Déposez ici pour créer un projet',
    faq: 'Foire aux questions',
    help_center: "Centre d'aide",
    privacy_policy: 'Politique de confidentialité',
    shop: 'Boutique',
    terms_of_use: "Conditions d'utilisation",
    recent_projects: 'projets récents',
    submission_status: 'statut de soumission',
    my_projects: 'Mes Projets',
    select_judges: 'Sélectionner les Juges',
    requirements: 'Exigences',
    prize: 'Prix',
    drop_file_to_upload: 'Déposez le fichier ici pour le télécharger',
    trending: 'Tendance',
};
