import { call, put, all, takeLatest } from 'redux-saga/effects';
import { getComingSoonSuccess, getComingSoonFail } from './actions';
import { globalErrorHandler } from '../error/saga';
import { types } from './types';
import { api } from '../../configurations/api';
import { httpRequest } from '../types';
import { getComingSoonUrl } from '../../configurations/api/url';
import { IContest } from '../../types/global/helper';
import { parseGenericCollection, genericParseSingleDocument } from '../../utils/responseProcessor';
import { setCache } from '../cache/action';
import { getCacheByType, getCacheDuration, isUseCacheEnabled } from '../cache/saga';
import { CacheValue, CACHE_TYPE } from '../cache/types';
import { SentryCapture } from '../../analytics/Sentry';

function* getComingSoon(): any {
    const defaultUseCache = yield* isUseCacheEnabled();
    const defaultCacheDuration = yield* getCacheDuration();
    let initialResult: any = null;
    const cache: CacheValue = yield* getCacheByType(CACHE_TYPE.COMING_SOON);

    if (cache && cache.key) {
        initialResult = cache.value;
    }
    if (
        !!initialResult &&
        defaultUseCache &&
        ((Date.now() - Number(cache.key)) as unknown as number) < defaultCacheDuration
    ) {
        yield put(getComingSoonSuccess(initialResult));
    } else {
        try {
            const response = yield call(api, getComingSoonUrl, httpRequest.GET, {}, 2, 2000, false);
            const { coming_soon, trending }: { coming_soon: IContest[]; trending: IContest[] } = response.data;
            const comingSoon = parseGenericCollection(coming_soon, genericParseSingleDocument);
            const trendingData = parseGenericCollection(trending, genericParseSingleDocument);

            const payload = {
                coming_soon: comingSoon,
                trending: trendingData,
            };
            yield put(getComingSoonSuccess(payload));
            yield put(
                setCache({
                    key: Date.now(),
                    value: payload,
                    type: CACHE_TYPE.COMING_SOON,
                    isUnique: true,
                }),
            );
        } catch (error: any) {
            SentryCapture(error, 'error');
            yield put(getComingSoonFail(error));
            yield call(globalErrorHandler, error);
        }
    }
}

function* getComingSoonWatcher() {
    yield takeLatest(types.GET_COMING_SOON, getComingSoon);
}

export default function* comingSoonSaga() {
    yield all([getComingSoonWatcher()]);
}
