import { call, put, all, takeLatest, delay } from 'redux-saga/effects';
import { IGetUserLikes, ILike, types } from './types';
import {
    accountRefreshFail,
    accountRefreshSucess,
    getAccountDetailsFail,
    getAccountDetailsSuccess,
    getContributionsFail,
    getContributionsSuccess,
    getCurrentUserCastingRolesFail,
    getCurrentUserCastingRolesSuccess,
    getCurrentUserReviewsFail,
    getCurrentUserReviewsSuccess,
    getCurrentUsersAuditionsFail,
    getCurrentUsersAuditionsSuccess,
    getSingleLikeFail,
    getSingleLikeSuccess,
    getuserAudmeProfileFail,
    getuserAudmeProfileSuccess,
    getUserDashboardFail,
    getUserDashboardSuccess,
    getUserLikesFail,
    getUserLikesSuccess,
    IGetAudmeProfilesResponse,
} from './actions';
import { api } from '../../configurations/api';
import {
    biographyUrl,
    getFestival,
    getMoviesUrl,
    getMyApplicationsUrl,
    getMyAuditionsUrl,
    getMyLikesUrl,
    getMyReviewsUrl,
    getMySubscriptionUrl,
    getUserDashboardUrl,
    getUserProfileUrl,
    userBaseUrl,
    userDetailsUrl,
} from '../../configurations/api/url';
import { ActivityResponse, SearchSuggestion } from '../activity/types';
import { CACHE_TYPE, CacheValue, NotUniqueCacheValue } from '../cache/types';
import { genericParseSingleDocument, parseGenericCollection } from '../../utils/responseProcessor';
import { getCacheByType, getCacheDuration, getNotUniqueCacheByKey, isUseCacheEnabled } from '../cache/saga';
import { getCurrentUserTalentProfileSuccess } from '../talentProfile/action';
import { getGlobalConfigSuccess } from '../configuration/action';
import { getMyActivitiesSuccess, getSearchSuggestionsSuccess } from '../activity/actions';
import { getMyApplicationsSuccess, getSavedCollectionSuccess } from '../audition/actions';
import { getMyNotificationsSuccess } from '../notifications/action';
import { getMyProjectsSuccess } from '../project/actions';
import { getRecommendationSuccess } from '../contest/actions';
import { getCurentUserProfileSuccess, getUserProfile, getUserProfileSuccess } from '../user/actions';
import { globalErrorHandler } from '../error/saga';
import { httpRequest } from '../types';
import { IContest, IDashboardResponse, IProject } from '../../types/global/helper';
import { IUserDetails, IUserDetailsResponse } from './accountDetailsResponse.type';
import { parseUserDashBoard } from '../../utils/reponseProcessorII';
import { destroyOneCache, setCache } from '../cache/action';
import { setSubscriptionSuccess } from '../subscription/actions';
import { SentryCapture } from '../../analytics/Sentry';
import { isEmpty } from '../../utils/lodash';

/** When an acivity is triggered that required updating users dashboard, we call this guy */
function* refreshUserAccount() {
    try {
        yield put(destroyOneCache({ cacheType: CACHE_TYPE.USER_APP_DETAILS }));
        yield delay(1000);
        yield put(getUserProfile({ isRefresh: true }));
        yield* getAcccountDetails();
    } catch (error) {
        console.log(error);
        SentryCapture(error, 'error');
    }
}
function* getUserDashboard(): any {
    try {
        const response: IDashboardResponse = yield call(api, getUserDashboardUrl, httpRequest.GET, null, 0, 0);
        const { data } = response;
        yield put(getUserDashboardSuccess(data));
    } catch (error: any) {
        SentryCapture(error, 'error');
        yield put(getUserDashboardFail(error));
        yield call(globalErrorHandler, error);
    }
}
function* getReviews({ resolve, reject }: any): any {
    try {
        const response = yield call(api, getMyReviewsUrl, httpRequest.GET, null, 0, 0);
        const { data } = response;
        yield put(getCurrentUserReviewsSuccess(data));
        resolve(data);
    } catch (error: any) {
        SentryCapture(error, 'error');
        yield put(getCurrentUserReviewsFail(error));
        yield call(globalErrorHandler, error);
        reject(error);
    }
}

function* getAuditions({ resolve, reject }: any): any {
    try {
        const response = yield call(api, getMyAuditionsUrl, httpRequest.GET, null, 0, 0);
        const { data } = response;
        yield put(getCurrentUsersAuditionsSuccess(data));
        if (resolve) {
            resolve(data);
        }
    } catch (error) {
        SentryCapture(error, 'error');
        yield put(getCurrentUsersAuditionsFail(error));
        if (reject) {
            reject(error);
        }
    }
}

function* getUserLikes({ payload }: { payload: IGetUserLikes }): any {
    const defaultUseCache = yield* isUseCacheEnabled();
    let initialResult: any = null;
    const cache: CacheValue = yield* getCacheByType(CACHE_TYPE.USER_PROJECT_LIKES);
    let param = `?like_category=project`;
    if (payload && payload.param) {
        param = `${payload.param}`;
    }

    if (cache && cache.key) {
        initialResult = cache.value;
    }
    if (initialResult && defaultUseCache) {
        yield put(getUserLikesSuccess(initialResult));
    } else {
        const request = `${getMyLikesUrl}${param}`;
        try {
            const response = yield call(api, request, httpRequest.GET, null, 0, 0);
            const { data } = response.data;
            const parsedLikes = parseGenericCollection(data, genericParseSingleDocument);
            yield put(getUserLikesSuccess(parsedLikes));
            yield put(
                setCache({
                    key: param,
                    value: parsedLikes,
                    type: CACHE_TYPE.USER_PROJECT_LIKES,
                    isUnique: false,
                }),
            );
        } catch (error: any) {
            SentryCapture(error, 'error');
            yield put(getUserLikesFail(error));
        }
    }
}

function* getUserSingleLikeForProject({ payload }: { payload: IGetUserLikes }): any {
    const defaultUseCache = yield* isUseCacheEnabled();
    let initialResult: any = null;
    const cache: NotUniqueCacheValue = yield* getNotUniqueCacheByKey(CACHE_TYPE.CONTEST, payload.param);
    if (cache && cache.key) {
        initialResult = cache.value;
    }
    if (initialResult && defaultUseCache) {
        yield put(getSingleLikeSuccess(initialResult));
    } else {
        const request = `${getMyLikesUrl}?like_category=project&${payload.param}`;
        try {
            const response = yield call(api, request, httpRequest.GET, null, 0, 0);
            const { data } = response.data;
            if (data.length > 0) {
                const parsedLike = genericParseSingleDocument(data[0]);
                yield put(getSingleLikeSuccess(parsedLike));
                yield put(
                    setCache({
                        key: payload.param,
                        value: parsedLike,
                        type: CACHE_TYPE.USER_SINGLE_LIKE,
                        isUnique: false,
                    }),
                );
            }
        } catch (error: any) {
            SentryCapture(error, 'error');
            yield put(getSingleLikeFail(error));
        }
    }
}

function* getCastRoles({ resolve, reject }: any): any {
    try {
        const response = yield call(api, getMyApplicationsUrl, httpRequest.GET, null, 0, 0);
        const { data } = response;
        yield put(getCurrentUserCastingRolesSuccess(data));
        resolve(data);
    } catch (error) {
        SentryCapture(error, 'error');
        yield put(getCurrentUserCastingRolesFail(error));
        reject(error);
    }
}

function* getMyContributions({ resolve, reject }: any): any {
    const festivalRequestUrl = `${getFestival}/contributions`;
    const bioRequestUrl = `${biographyUrl}/contributions`;
    const movieRequestUrl = `${getMoviesUrl}/contributions`;
    try {
        const [festival, biography, movies] = yield all([
            call(api, festivalRequestUrl, httpRequest.GET, null, 2, 2000),
            call(api, bioRequestUrl, httpRequest.GET, null, 2, 2000),
            call(api, movieRequestUrl, httpRequest.GET, null, 2, 2000),
        ]);
        yield put(
            getContributionsSuccess({
                festival: festival.data.data,
                biography: biography.data.data,
                movies: movies.data.data,
            }),
        );
        resolve({ festival, biography, movies });
    } catch (error) {
        SentryCapture(error, 'error');
        yield put(getContributionsFail(error));
        reject(error);
    }
}

/** Custom SAGA called when a user lands on page. To avoid multiple calls and refreshing */
function* accountRefresh(): any {
    try {
        const [applications, userProfile, subscription] = yield all([
            call(api, getMyApplicationsUrl, httpRequest.GET, null, 0, 0),
            call(api, getUserProfileUrl, httpRequest.GET, null, 0, 12),
            call(api, getMySubscriptionUrl, httpRequest.GET, null, 5, 0),
        ]);

        if (applications && userProfile && subscription) {
            yield all([
                put(getUserProfileSuccess(userProfile.data.data)),
                put(getMyApplicationsSuccess(applications.data)),
                put(setSubscriptionSuccess(subscription.data.data[0])),
            ]);
            yield put(
                accountRefreshSucess({
                    applications,
                    userProfile,
                    subscription,
                }),
            );
        }
    } catch (error) {
        SentryCapture(error, 'error');
        yield put(accountRefreshFail(error));
    }
}

function* getUserAudmeProfiles({ resolve, reject }: any): any {
    try {
        const response: IGetAudmeProfilesResponse = yield call(
            api,
            `${userBaseUrl}/profiles`,
            httpRequest.GET,
            null,
            0,
            0,
        );
        const { data } = response || {};
        if (!data) {
            yield put(getuserAudmeProfileFail(response));
            reject(response);
        }
        yield put(getuserAudmeProfileSuccess(response));
        resolve(response);
    } catch (error) {
        SentryCapture(error, 'error');
        yield put(getuserAudmeProfileFail(error));
        reject(error);
    }
}
function* getAcccountDetails(): any {
    const defaultUseCache = yield* isUseCacheEnabled();
    const defaultCacheDuration = yield* getCacheDuration();
    let initialResult: any = null;
    const cache: CacheValue = yield* getCacheByType(CACHE_TYPE.USER_APP_DETAILS);

    if (
        cache &&
        cache.key &&
        !isEmpty(cache.value) &&
        ((Date.now() - Number(cache.key)) as unknown as number) < defaultCacheDuration // To Temporarily fetch from server directly add `&& false` here to override cache
    ) {
        initialResult = cache.value;
    }

    if (!!initialResult && defaultUseCache) {
        const unwrappedRespnse: IUserDetails = initialResult;
        const {
            activities,
            applications,
            auditions,
            configuration,
            profile,
            projects,
            notifications,
            recommendation,
            saved,
            // submissions,
            subscription,
            suggestion,
            user_dashboard,
            likes,
            user,
        } = unwrappedRespnse;

        yield all([
            put(getCurrentUserTalentProfileSuccess(profile)),
            put(getGlobalConfigSuccess(configuration)),
            put(getCurrentUsersAuditionsSuccess(auditions)),
            put(getUserProfileSuccess(user_dashboard)),
            put(getMyApplicationsSuccess(applications)),
            put(setSubscriptionSuccess(subscription)),
            put(getMyNotificationsSuccess(notifications)),
            put(getSavedCollectionSuccess(saved)),
            put(getRecommendationSuccess(recommendation as IContest[])),
            put(getMyProjectsSuccess(projects as unknown as IProject[])),
            // put(getMySubmissionsSuccess(submissions as unknown as ISubmission[])),
            put(getMyActivitiesSuccess(activities as unknown as ActivityResponse[])),
            put(getSearchSuggestionsSuccess(suggestion as unknown as SearchSuggestion[])),
            put(getUserLikesSuccess(likes as unknown as ILike[])),
            put(getCurentUserProfileSuccess(user)),
        ]);
        yield put(getAccountDetailsSuccess(unwrappedRespnse));
    } else {
        try {
            const [userDetails] = yield all([call(api, userDetailsUrl, httpRequest.GET, null, 0, 0)]);
            const userDetailsResponse: IUserDetailsResponse = userDetails;
            // const parsedDetails = parseUserDetails(userDetailsResponse.data); This was introducing an issue with user_type
            const parsedDetails = userDetailsResponse.data;
            const {
                activities,
                applications,
                auditions,
                configuration,
                profile,
                projects,
                notifications,
                recommendation,
                saved,
                // submissions,
                subscription,
                suggestion,
                user_dashboard,
                likes,
                user,
            } = parsedDetails;

            parseUserDashBoard(user_dashboard); // We might not need this here anymore

            yield all([
                put(getCurrentUserTalentProfileSuccess(profile)),
                put(getGlobalConfigSuccess(configuration)),
                put(getCurrentUsersAuditionsSuccess(auditions)),
                put(getUserProfileSuccess(user_dashboard)),
                put(getMyApplicationsSuccess(applications)),
                put(setSubscriptionSuccess(subscription)),
                put(getMyNotificationsSuccess(notifications)),
                put(getSavedCollectionSuccess(saved)), //
                put(getRecommendationSuccess(recommendation as IContest[])),
                put(getMyProjectsSuccess(projects as unknown as IProject[])),
                // put(getMySubmissionsSuccess(submissions as unknown as ISubmission[])),
                put(getMyActivitiesSuccess(activities as unknown as ActivityResponse[])),
                put(getSearchSuggestionsSuccess(suggestion as unknown as SearchSuggestion[])),
                put(getUserLikesSuccess(likes as unknown as ILike[])),
                put(getCurentUserProfileSuccess(user)),
            ]);
            yield put(getAccountDetailsSuccess(parsedDetails));
            yield put(
                setCache({ key: Date.now(), value: parsedDetails, type: CACHE_TYPE.USER_APP_DETAILS, isUnique: true }),
            );
        } catch (error) {
            yield call(SentryCapture, error, 'error');
            yield put(getAccountDetailsFail(error));
        }
    }
}

/** *********************** WATCHERS ******************************************* */

function* getReviewsWatcher() {
    yield takeLatest(types.GET_CURRENT_USER_REVIEWS, getReviews);
}

function* getUserLikesWatcher() {
    yield takeLatest<any>(types.GET_USER_LIKES, getUserLikes);
}

function* getSingleLikeWatcher() {
    yield takeLatest<any>(types.GET_SINGLE_LIKE, getUserSingleLikeForProject);
}

function* getCastRolesWatcher() {
    yield takeLatest(types.GET_CASTING_ROLES, getCastRoles);
}

function* getMyAuditionsWatcher() {
    yield takeLatest(types.GET_CURRENT_USER_AUDITIONS, getAuditions);
}

function* getContributionsWatcher() {
    yield takeLatest(types.GET_CONTRIBUTIONS, getMyContributions);
}

function* getAccountDetailsWatcher() {
    yield takeLatest(types.GET_ACCOUNT_DETAILS, getAcccountDetails);
}

function* accountRefreshWatcher() {
    yield takeLatest(types.ACCOUNT_REFRESH, accountRefresh);
}

function* getUserAudmeProfilesWatcher() {
    yield takeLatest(types.GET_USER_AUDME_PROFILES, getUserAudmeProfiles);
}

function* getUserDashboardWatcher() {
    yield takeLatest(types.GET_USER_DASHBOARD, getUserDashboard);
}

function* refreshUserAccountWatcher() {
    yield takeLatest(types.REFRESH_USER_DASHBOARD, refreshUserAccount);
}
export default function* accountSaga() {
    yield all([
        accountRefreshWatcher(),
        getAccountDetailsWatcher(),
        getCastRolesWatcher(),
        getContributionsWatcher(),
        getMyAuditionsWatcher(),
        getReviewsWatcher(),
        getSingleLikeWatcher(),
        getUserAudmeProfilesWatcher(),
        getUserDashboardWatcher(),
        getUserLikesWatcher(),
        refreshUserAccountWatcher(),
    ]);
}
