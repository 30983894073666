import { createAction } from 'redux-act';
import { types } from './types';
import { IErrorType } from '../../constants/types';
import { IGenericDocumentModifier, ITopUser } from '../../types/global/helper';
import {
    IGetAudience,
    IGetAudienceResponse,
    IGetTopUsers,
    IGetWatchlistResponse,
    IFollowThspian,
    IFollowThspianModel,
} from './model';
import { Follower } from '../user/model';

export const getMyFollowers = createAction<IGetAudience>(types.GET_MY_AUDIENCE);
export const getMyFollowersSuccess = createAction<IGetAudienceResponse>(types.GET_MY_AUDIENCE_SUCCESS);
export const getMyFollowersFail = createAction<IErrorType>(types.GET_MY_AUDIENCE_FAIL);

export const getExtrenalUserFollowers = createAction<IGetAudience>(types.GET_EXTERNAL_FOLLOWERS);
export const getExtrenalUserFollowersSuccess = createAction<IGetAudienceResponse>(types.GET_EXTERNAL_FOLLOWERS_SUCCESS);
export const getExtrenalUserFollowersFail = createAction<IErrorType>(types.GET_EXTERNAL_FOLLOWERS_FAIL);

export const blockAFollower = createAction<IGenericDocumentModifier>(types.BLOCK_A_FOLLOWER);
export const blockAFollowerSuccess = createAction<IFollowThspianModel>(types.BLOCK_A_FOLLOWER_SUCCESS);
export const blockAFollowerFail = createAction<IErrorType>(types.BLOCK_A_FOLLOWER_FAIL);

export const followAThspian = createAction<IFollowThspian>(types.FOLLOW_A_THSPIAN);
export const followAThspianSuccess = createAction<IFollowThspianModel>(types.FOLLOW_A_THSPIAN_SUCCESS);
export const followAThspianFail = createAction<IErrorType>(types.FOLLOW_A_THSPIAN_FAIL);

export const getSuggestedThspians = createAction<IGetTopUsers>(types.GET_SUGGESTED_THSPIANS);
export const getSuggestedThspiansSuccess = createAction<ITopUser[]>(types.GET_SUGGESTED_THSPIANS_SUCCESS);
export const getSuggestedThspiansFail = createAction<IErrorType>(types.GET_SUGGESTED_THSPIANS_FAIL);

/** This gets the users that the current logged in user is watching */
export const getMyFollowing = createAction<IGetAudience>(types.GET_MY_FOLLOWING);
export const getMyFollowingSuccess = createAction<IGetWatchlistResponse>(types.GET_MY_FOLLOWING_SUCCESS);
export const getMyFollowingFail = createAction<IErrorType>(types.GET_MY_FOLLOWING_FAIL);

export const getExtrnalUserFollowing = createAction<IGetAudience>(types.GET_EXTERNAL_FOLLOWING);
export const getExtrnalUserFollowingSuccess = createAction<IGetWatchlistResponse>(types.GET_EXTERNAL_FOLLOWING_SUCCESS);
export const getExtrnalUserFollowingFail = createAction<IErrorType>(types.GET_EXTERNAL_FOLLOWING_FAIL);

export const unfollowThspian = createAction<IGenericDocumentModifier>(types.UNFOLLOW_A_THSPIAN);
export const unfollowThspianSuccess = createAction<IGenericDocumentModifier>(types.UNFOLLOW_A_THSPIAN_SUCCESS);
export const unfollowThspianFail = createAction<IErrorType>(types.UNFOLLOW_A_THSPIAN_FAIL);

export const annouceNewFollower = createAction<Follower>(types.ANNOUCE_NEW_FOLLOWER);

export const resetField = createAction<Record<string, any>>(types.RESET_FIELD);

export const generateTemporaryFollowObject = (following: string) => ({
    _id: 'temporary',
    following: {
        _id: following,
        firstName: '',
        lastName: '',
        userName: '',
        profile_picture: '',
    },
    blocked: false,
    created_at: '',
    created_by: '',
    createdAt: '',
    follower: {
        _id: '',
        firstName: '',
        lastName: '',
        userName: '',
        profile_picture: '',
    },
    id: '',
    updatedAt: '',
    optimistic: true,
});
