import React from 'react';

export default function WifiConnectivityIcon({ fill = '#FFF', ...rest }: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width="800px" height="800px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="none" {...rest}>
            <path
                stroke={fill}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M21 8.437c-5.115-4.583-12.885-4.583-18 0m15.333 2.982a9.501 9.501 0 0 0-12.666 0m10 2.981a5.5 5.5 0 0 0-7.334 0M12 18.5l1-1.118a1.5 1.5 0 0 0-2 0l1 1.118Z"
            />
        </svg>
    );
}
