import React from 'react';

export default function VerificationBadge({
    fill = '#0788F5',
    stroke = '#fff',
    ...rest
}: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width="23" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
            <g filter="url(#filter0_dd_1430_5463)">
                <path
                    d="M11.4735 15C10.9554 14.9926 10.4603 14.7847 10.0923 14.42C9.95112 14.2822 9.82578 14.1277 9.66201 14.0129C9.50097 13.8961 9.30652 13.8345 9.1076 13.8371C8.78006 13.8404 8.45251 13.8371 8.13332 13.7494C7.33283 13.5268 6.71952 12.8049 6.67983 11.9581C6.6698 11.7401 6.66729 11.5221 6.6556 11.3046C6.64306 11.059 6.53026 10.8574 6.35897 10.687C6.10119 10.4298 5.84132 10.1788 5.68131 9.83893C5.34207 9.11864 5.48746 8.1929 6.04562 7.62585C6.15884 7.51061 6.27666 7.39995 6.38696 7.2822C6.56089 7.10638 6.65872 6.86928 6.65936 6.62203C6.65936 6.19654 6.64975 5.76729 6.84068 5.36935C7.17909 4.66033 7.72806 4.25279 8.52186 4.18097C8.75039 4.16051 8.97892 4.16928 9.20704 4.15424C9.4456 4.13879 9.64279 4.02689 9.80949 3.85944C10.0664 3.60139 10.3175 3.34209 10.6572 3.18174C11.3766 2.84226 12.3058 2.98716 12.874 3.54293C12.9855 3.65233 13.0933 3.76549 13.2061 3.87322C13.2957 3.96424 13.4028 4.03628 13.5209 4.08506C13.6389 4.13385 13.7656 4.15837 13.8934 4.15717C14.3145 4.15424 14.7382 4.15383 15.1309 4.33839C15.8436 4.67244 16.2426 5.22988 16.3182 6.01908C16.3404 6.2479 16.3303 6.47839 16.3454 6.70388C16.3608 6.94272 16.4728 7.13981 16.6404 7.30642C16.8985 7.56322 17.1584 7.81417 17.3184 8.15407C17.6577 8.87478 17.5131 9.8001 16.9554 10.3697C16.8785 10.4477 16.8058 10.5308 16.7227 10.601C16.416 10.8611 16.3086 11.2019 16.3182 11.5885C16.327 11.9497 16.3182 12.308 16.1553 12.6404C15.811 13.3364 15.2646 13.7423 14.4783 13.8133C14.2493 13.8337 14.0208 13.825 13.7931 13.84C13.5541 13.8555 13.3578 13.9678 13.1902 14.1344C12.9467 14.3757 12.7169 14.6309 12.3964 14.7837C12.1087 14.9233 11.7934 14.9972 11.4735 15Z"
                    fill={fill}
                    stroke={stroke}
                    strokeWidth=".5"
                />
                <path
                    d="M9.3125 9.21875L11.0625 10.9688L13.6875 7.03125"
                    stroke="white"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <filter
                    id="filter0_dd_1430_5463"
                    x="0.5"
                    y="0"
                    width="22"
                    height="22"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="2" />
                    <feGaussianBlur stdDeviation="2.5" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.403922 0 0 0 0 0.431373 0 0 0 0 0.462745 0 0 0 0.08 0"
                    />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1430_5463" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="1" />
                    <feGaussianBlur stdDeviation="0.5" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0" />
                    <feBlend mode="normal" in2="effect1_dropShadow_1430_5463" result="effect2_dropShadow_1430_5463" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_1430_5463" result="shape" />
                </filter>
            </defs>
        </svg>
    );
}
