import produce from 'immer';
import { types as storeType } from '../refreshToken/types';

import {
    FilterValueSetPayload,
    IAppConfiguratorInitialState,
    IFilterPayload,
    ISetFilterPayload,
    ISetFilterSubCategoryId,
    ISetGuide,
    ISetLoading,
    ISetPage,
    IToastPayload,
    IToggleCookiesDrawer,
    IToggleAccountModal,
    IToggleCreator,
    IToggleDrawer,
    IToggleNotification,
    types,
    IShareLinks,
} from './types';

const initialState: IAppConfiguratorInitialState = {
    currentPage: 0,
    loading: false,
    drawerStatus: 'closed',
    showSearchBox: 'off',
    selectedFilterCategory: 'video',
    setFilters: {},
    selectedFilterSubCatgory: [],
    selectedFiltersValues: {},
    filterStatus: 'off',
    filterSubCategoryId: null,
    notificationOpen: false,
    accountModalOpen: false,
    creatorModal: undefined,
    showGuide: false,
    guide: [],
    showUserFirstProjectSuggestionBox: false,
    showProjectCreatorGuide: false,
    cookiesDrawerOpen: false,
    showProjectGuide: false,
    theme: 'light',
};

const reducer = (state = initialState, action: any) => {
    const { type, payload } = action;
    switch (type) {
        case types.SET_PAGE:
            return produce(state, draft => {
                const { pageIndex } = payload as ISetPage;
                draft.currentPage = pageIndex;
            });
        case types.SET_APP_LOADING:
            return produce(state, draft => {
                const { loading } = payload as ISetLoading;
                draft.loading = loading;
            });
        case types.TOGGLE_APP_DRAWER:
            return produce(state, draft => {
                const { status } = payload as IToggleDrawer;
                draft.drawerStatus = status;
            });
        case types.SET_FILTER:
            return produce(state, draft => {
                const { category, categoryPayload } = payload as ISetFilterPayload;
                draft.selectedFilterCategory = category;
                draft.setFilters = categoryPayload;
                draft.selectedFiltersValues = {};
            });
        case types.SET_FILTER_SUB_SELECTION:
            return produce(state, draft => {
                const { id, value, displayName, field } = payload as FilterValueSetPayload;
                const newObject = { ...draft.selectedFiltersValues };
                newObject[id] = {
                    value,
                    displayName,
                    field,
                };
                draft.selectedFiltersValues = newObject;
            });
        case types.CLEAR_FILTER:
            return produce(state, draft => {
                draft.selectedFiltersValues = {};
            });
        case types.TOGGLE_FILTER:
            return produce(state, draft => {
                const { filterStatus, filterId } = payload as IFilterPayload;
                draft.filterStatus = filterStatus;
                draft.filterSubCategoryId = filterId;
            });
        case types.SET_FILTER_SUB_CATEGORY:
            return produce(state, draft => {
                const { id } = payload as ISetFilterSubCategoryId;
                draft.filterSubCategoryId = id;
            });
        case types.TOGGLE_NOTIFICATION:
            return produce(state, draft => {
                const { on } = payload as IToggleNotification;
                draft.notificationOpen = on;
            });
        case types.TOGGLE_ACCOUNT_MODAL:
            return produce(state, draft => {
                const { on } = payload as IToggleAccountModal;
                draft.accountModalOpen = on;
            });
        case types.TOGGLE_COOKIES_DRAWER:
            return produce(state, draft => {
                const { on } = payload as IToggleCookiesDrawer;
                draft.cookiesDrawerOpen = on;
            });
        case types.TOGGLE_CREATOR_MODAL:
            return produce(state, draft => {
                const { creatorType } = payload as IToggleCreator;
                draft.creatorModal = creatorType;
            });
        case types.TOGGLE_TOAST:
            return produce(state, draft => {
                const { message, state } = payload as IToastPayload;
                draft.showToast = state;
                draft.toastMessage = message;
            });
        case types.SET_SHOW_GUIDE:
            return produce(state, draft => {
                const { showGuide, page } = payload as ISetGuide;
                draft.showGuide = showGuide;
                if (page) {
                    const guideToShow = draft.guide.find(g => g.page === page);
                    if (guideToShow) {
                        draft.selectedPageGuide = guideToShow;
                    } else {
                        draft.showGuide = false;
                    }
                }
            });
        case types.SET_GUIDE_LINK:
            return produce(state, draft => {
                if (payload.page) {
                    const guidepage = draft.guide?.find(g => g.page === payload.page);
                    if (guidepage) {
                        guidepage.details?.steps.push(payload.step as any);
                    } else {
                        draft.guide?.push(payload);
                    }
                }
            });
        case types.SET_SHOW_SEARCH_BOX:
            return produce(state, draft => {
                draft.showSearchBox = payload;
            });
        case types.SET_SHOW_PROJECT_CREATOR_GUIDE:
            return produce(state, draft => {
                draft.showProjectCreatorGuide = payload;
            });
        case types.SET_SHOW_PROJECT_SUGGESTION_BOX:
            return produce(state, draft => {
                draft.showUserFirstProjectSuggestionBox = payload;
            });
        case types.SET_SHARE_MODAL_STATUS:
            return produce(state, draft => {
                const { status } = payload as IShareLinks;
                draft.shareModalStatus = status;
                draft.sharePayload = payload;
            });
        case storeType.ENQUEUE_FAILED_ACTIONS:
            return produce(state, draft => {
                if (payload && payload.payload && payload.payload.redirect) {
                    draft.currentRedirect = payload.payload.redirect;
                }
            });
        case 'LOGOUT_SUCCESS':
        case types.RESET_REDIRECT:
            return produce(state, draft => {
                draft.currentRedirect = undefined;
            });
        case types.SET_PROJECT_GUIDE:
            return produce(state, draft => {
                draft.showProjectGuide = payload;
            });
        case types.TOGGLE_THEME:
            return produce(state, draft => {
                draft.theme = payload;
            });
        default:
            return state;
    }
};

export { reducer };
