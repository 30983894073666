import React from 'react';
import { ThemeContext } from '../../module/ThemeManager';
import { Container } from '@mui/material';
import { VerticalCardShimmer } from '../VerticalCardShimmer';
import { WatchSingleCardShimmer } from '../WatchSingleCardShimmer';

const WatchShimmer = () => {
    return (
        <ThemeContext.Consumer>
            {() => {
                return (
                    <Container
                        disableGutters
                        sx={{
                            width: '100%',
                            marginTop: { xs: '0px', sm: '0px', md: '20px', lg: '0px' },
                            marginLeft: { xs: '0px', sm: '65px', lg: '85px' },
                            paddingLeft: { xs: '15px', sm: '40px' },
                            paddingRight: { xs: '15px', sm: '40px' },
                            overflow: 'hidden',
                        }}
                        maxWidth={false}>
                        <VerticalCardShimmer />
                        <VerticalCardShimmer />
                        <WatchSingleCardShimmer />
                        <WatchSingleCardShimmer />
                    </Container>
                );
            }}
        </ThemeContext.Consumer>
    );
};

export { WatchShimmer };
