import React from 'react';
import { ThemeContext } from '../../module/ThemeManager';
import { Stack, Skeleton, Grid } from '@mui/material';
import { theme } from '../../constants/theme';

const JuryCardShimmer = () => {
    return (
        <ThemeContext.Consumer>
            {({ themeStyle }) => {
                const value = 1;
                return (
                    <Grid container spacing={2} direction={{ xs: 'column', sm: 'row', md: 'row', lg: 'row' }}>
                        {Array.from(new Array(value)).map((_, index) => (
                            <Grid item xs={'auto'} sm={6} md={4} lg={'auto'} key={index}>
                                <Stack direction="column">
                                    <Skeleton
                                        variant="rounded"
                                        height={245}
                                        sx={{
                                            width: {
                                                xs: '100%',
                                                sm: 'auto',
                                                md: 'auto',
                                                lg: '320px',
                                            },
                                            backgroundColor: theme[themeStyle].gray_text,
                                            marginTop: '20px',
                                            borderRadius: '10px',
                                        }}
                                    />
                                    <Stack direction={'column'}>
                                        <Skeleton
                                            variant="text"
                                            sx={{
                                                fontSize: '1rem',
                                                width: '150px',

                                                backgroundColor: theme[themeStyle].darkGray60,
                                            }}
                                        />
                                        <Skeleton
                                            variant="text"
                                            sx={{
                                                fontSize: '1rem',
                                                width: '200px',
                                                backgroundColor: theme[themeStyle].darkGray60,
                                            }}
                                        />
                                        <Skeleton
                                            variant="text"
                                            sx={{
                                                fontSize: '1rem',
                                                width: '230px',
                                                backgroundColor: theme[themeStyle].darkGray60,
                                            }}
                                        />
                                    </Stack>
                                </Stack>
                            </Grid>
                        ))}
                    </Grid>
                );
            }}
        </ThemeContext.Consumer>
    );
};

export { JuryCardShimmer };
