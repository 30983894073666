import { call, all, put, takeLatest, take, cancel } from 'redux-saga/effects';

import {
    createUserProfileFailed,
    createUserProfileSuccess,
    phoneNumberVerificationSuccess,
    phoneNumberVerificationFailed,
    getPhoneVerificationCodeFailed,
    getPhoneVerificationCodeSuccess,
    emailFieldValidationSuccess,
    emailFieldValidationFailed,
    getResetPasswordTokenSuccess,
    getResetPasswordTokenFailed,
    resetPasswordSuccess,
    resetPasswordFailed,
    getUserProfileSuccess,
    getUserProfileFailed,
    IFieldAvailabilityData,
    userDetailValidationSuccess,
    userDetailValidationFailed,
    userNameTaken,
    searchUserSuccess,
    seacrhUserFail,
    getExternalUserProfileFailed,
    getExternalUserProfileSuccess,
    updateUserProfileFailed,
    updateUserProfileSuccess,
    updateActivityLikeSuccess,
    updateActivityLikeFailed,
    removeActivityLikeFailed,
    removeActivityLikeSuccess,
    getTopUserSuccess,
    getTopUserFail,
    changePasswordSuccess,
    changePasswordFailed,
    initiateEmailVerifySuccess,
    initiateEmailVerifyFail,
    verifyEmailSuccess,
    verifyEmailFail,
    disableAccountFail,
    disableAccountSuccess,
} from './actions';
import {
    IDisableAccount,
    IGetTopUserPayload,
    ILikeActivityPayload,
    IRemoveLikeActivity,
    ISearchUserPayload,
    ISearchUserResponse,
    IVerifyEmail,
    types,
} from './types';
import { api } from '../../configurations/api';
import {
    getPhoneVerificationCodeUrl,
    phoneNumberVerificationUrl,
    validateFieldUrl,
    forgotPasswordUrl,
    resetPasswordUrl,
    getUserProfileUrl,
    validateUsernameUrl,
    userBaseUrl,
    updateProfileUrl,
    projectUrl,
    likeUrl,
    changePasswordUrl,
    getContestUrl,
    initiateEmailVerificationUrl,
    verifyEmailUrl,
    userDeleteUrl,
} from '../../configurations/api/url';
import { genericParseSingleDocument, parseGenericCollection, parseUserProfile } from '../../utils/responseProcessor';
import { removeJwt, storeJwt, storeRefreshToken, removeRefreshToken } from '../authentication';
import { setJwt, deleteJwtSuccess, appLaunchFlowComplete, loginSuccess, logout } from '../authentication/action';
import { getJwt } from '../authentication/saga';
import { httpRequest } from '../types';
import { globalErrorHandler } from '../error/saga';
import { getAccountDetails, refreshUserDashboard } from '../account/actions';
import { formQueryParams, getJWtDetails, showToastMessage } from '../../utils/AppUtils';
import { destroyOneCache, setCache } from '../cache/action';
import { CACHE_TYPE, NotUniqueCacheValue } from '../cache/types';
import { getNotUniqueCacheByKey, isUseCacheEnabled } from '../cache/saga';
import { parseUserDashBoard } from '../../utils/reponseProcessorII';
import { Location } from '../../types/global/generic.types';
import { ContestActivity, ProjectActivity } from './model';
import { SentryCapture } from '../../analytics/Sentry';
import { Action } from 'redux';
import { getUserConfig, updateUserConfigSuccess } from '../configuration/action';

function* initiateEmailVerification(): Generator<any, any, any> {
    try {
        yield call(api, `${initiateEmailVerificationUrl}`, httpRequest.GET, null, 2, 2000);
        yield put(initiateEmailVerifySuccess());
        yield call(showToastMessage, 'Check your email for the verification link.', 'success');
    } catch (error: any) {
        yield put(initiateEmailVerifyFail());
        yield call(globalErrorHandler, error);
    }
}

function* verifyEmail({ payload }: { payload: IVerifyEmail }): Generator<any, any, any> {
    const { token } = payload;
    const { isSignedIn } = getJWtDetails();
    try {
        yield call(api, `${verifyEmailUrl}/${token}`, httpRequest.GET, null, 2, 2000);
        yield put(verifyEmailSuccess());
        yield call(showToastMessage, 'Email verification completed', 'success');
        if (isSignedIn) {
            yield put(refreshUserDashboard());
        }
    } catch (error: any) {
        yield put(verifyEmailFail(error.message ? error.message : 'Somethng went wrong with your email verification'));
        yield call(globalErrorHandler, error);
    }
}
function* getTopUser({ payload }: { payload: IGetTopUserPayload }): any {
    try {
        const response = yield call(api, `${userBaseUrl}/top-user`, httpRequest.POST, payload, 2, 2000);
        const { data } = response;
        const parsedResponse = parseGenericCollection(data, genericParseSingleDocument);
        yield put(getTopUserSuccess(parsedResponse));
    } catch (error: any) {
        yield put(getTopUserFail(error));
    }
}

function* searchUser({ payload }: { payload: ISearchUserPayload }): any {
    const defaultUseCache = yield* isUseCacheEnabled();
    const { searchTerm } = payload;
    let initialResult: any = null;

    const cache: NotUniqueCacheValue = yield* getNotUniqueCacheByKey(CACHE_TYPE.USER_SEARCH, searchTerm);
    if (cache && cache.key) {
        initialResult = cache.value;
    }

    if (initialResult && defaultUseCache) {
        yield put(searchUserSuccess(initialResult));
    } else {
        try {
            const response = yield call(api, `${userBaseUrl}/search`, httpRequest.POST, payload, 2);
            const { data }: { data: ISearchUserResponse } = response;
            const parsedUserResult = parseGenericCollection(data.result, genericParseSingleDocument);
            yield put(searchUserSuccess(parsedUserResult));
            yield put(
                setCache({
                    key: searchTerm,
                    value: parsedUserResult,
                    type: CACHE_TYPE.USER_SEARCH,
                    isUnique: false,
                }),
            );
        } catch (error: any) {
            SentryCapture(error, 'error');
            yield put(seacrhUserFail(error));
        }
    }
}

function* getPhoneVerificationCode({ payload, resolve, reject }: any): any {
    const request = payload;
    try {
        const response = yield call(api, getPhoneVerificationCodeUrl, httpRequest.POST, request, 2);
        const { message } = response;
        const medium = message === 'Code sent via email' ? 'email' : 'WhatsApp';
        const pre = medium === 'email' ? 'We see you are having troubles with your Whatsapp. ' : '';
        if (payload.resendToken)
            yield call(showToastMessage, `${pre}A new code has been sent to your ${medium}.`, 'success');
        yield put(getPhoneVerificationCodeSuccess());
        resolve(response);
    } catch (error: any) {
        SentryCapture(error, 'error');
        yield put(getPhoneVerificationCodeFailed(error));
        if (error.data.message) {
            yield call(showToastMessage, error.data.message, 'error');
        }
        reject(error.data.message);
    }
}

function* fieldVerification({ payload, resolve, reject }: any): any {
    const request = payload;
    try {
        const response = yield call(api, validateFieldUrl, 'POST', request);
        const { data } = response;
        if (data.message === 'User exists') {
            yield put(emailFieldValidationFailed(data.message));
            yield call(globalErrorHandler, response);
            reject(data?.message);
        } else {
            yield put(emailFieldValidationSuccess());
            resolve(data?.message);
        }
    } catch (error: any) {
        SentryCapture(error, 'error');
        yield call(globalErrorHandler, error);
        yield put(emailFieldValidationFailed(error));
        reject(error);
    }
}

function* newFieldVerification({ payload, resolve, reject }: any): any {
    const { request, field } = payload as IFieldAvailabilityData;
    const url = field === 'userName' ? validateUsernameUrl : validateFieldUrl;
    try {
        const response = yield call(api, url, 'POST', request);
        const { data } = response;
        if (data.message === 'User exists') {
            //TODO: Move to Localized file
            const errorMsg =
                field === 'email'
                    ? 'This email already exists.'
                    : field === 'phoneNumber'
                    ? 'This phone number already exists.'
                    : 'This userName already exists';
            response.data.message = errorMsg;

            if (field !== 'userName') {
                yield call(globalErrorHandler, response);
                yield put(userDetailValidationFailed(field, errorMsg));
                reject(errorMsg);
            } else {
                yield put(userNameTaken());
                reject(errorMsg);
            }
        } else {
            yield put(userDetailValidationSuccess(field));
            resolve(data?.message);
        }
    } catch (error: any) {
        SentryCapture(error, 'error');
        yield call(globalErrorHandler, error);
        yield put(userDetailValidationFailed(field, error));
        reject(error);
    }
}

function* createUserProfile({ payload, resolve, reject }: any): any {
    const request = payload;
    try {
        const response = yield call(api, phoneNumberVerificationUrl, 'POST', request, 0, 1000);
        const { token, refreshToken, data } = response;
        yield put(phoneNumberVerificationSuccess());
        yield call(storeJwt, token);
        yield call(storeRefreshToken, refreshToken);
        yield put(setJwt(response));
        const parsedProfile = parseUserProfile(data.user);
        yield put(createUserProfileSuccess(parsedProfile as any)); // TODO: fix
        yield put(updateUserConfigSuccess({ show_introduction: true }));
        yield put(getUserConfig());
        yield put(loginSuccess(token));
        yield put(getAccountDetails());
        resolve(response);
    } catch (error: any) {
        yield put(createUserProfileFailed(error));
        yield put(phoneNumberVerificationFailed(error.message));
        yield call(globalErrorHandler, error);
        yield call(SentryCapture, error, 'error');
        reject(error);
    }
}

function* getResetPasswordToken({ payload, resolve, reject }: any): any {
    try {
        const response = yield call(api, forgotPasswordUrl, 'POST', payload);
        yield put(getResetPasswordTokenSuccess());
        resolve(response);
    } catch (error: any) {
        reject(error);
        SentryCapture(error, 'error');
        yield call(globalErrorHandler, error);
        yield put(getResetPasswordTokenFailed(error));
    }
}

function* resetPassword({ payload, resolve, reject }: any): any {
    const resetUrlWithResetToken = `${resetPasswordUrl}/${payload.resetToken}`;
    try {
        const response = yield call(api, resetUrlWithResetToken, 'PATCH', payload.data);
        const { status, message } = response;
        if (status === 'success') {
            yield put(resetPasswordSuccess());
            resolve(message);
        } else {
            reject(message);
            yield put(resetPasswordFailed(message));
        }
    } catch (error: any) {
        SentryCapture(error, 'error');
        yield call(globalErrorHandler, error);
        yield put(resetPasswordFailed(error));
        reject(error);
    }
}

function* changePassword({ payload, resolve, reject }: any): any {
    try {
        const response = yield call(api, changePasswordUrl, 'PATCH', payload);
        const { status, message } = response;
        if (status === 'success') {
            yield put(changePasswordSuccess());
            yield call(showToastMessage, 'Your password has been changed successfully.', 'success');
            resolve(message);
        }
    } catch (error: any) {
        SentryCapture(error, 'error');
        yield call(globalErrorHandler, error);
        yield put(changePasswordFailed(error));
        reject(error);
    }
}

export function* getUserProfile(userId?: any): any {
    try {
        const response = yield call(
            api,
            `${getUserProfileUrl}${userId ? '?userId=' + userId : ''}`,
            httpRequest.GET,
            null,
            0,
            12,
        );
        const { data } = response;
        const parsedUserProfile = data.dashboard[0].result[0];
        yield put(getUserProfileSuccess(parsedUserProfile));
        yield appLaunchFlowComplete({ userIsAuthenticated: true });
    } catch (error) {
        SentryCapture(error, 'error');
        yield put(getUserProfileFailed(error));
        yield appLaunchFlowComplete({ userIsAuthenticated: false });
        yield resetToken();
    }
}

export interface IUpdateUserProfilePayload {
    profile_picture?: string;
    firstName?: string;
    lastName?: string;
    feature_image?: string;
    intro?: string;
    job_description?: string;
    education?: string;
    location?: Partial<Location>;
    home_town?: Partial<Location>;
    social?: { twitter?: string; instagram?: string };
}

function* updateUserProfile({
    payload,
    resolve,
    reject,
}: {
    payload: IUpdateUserProfilePayload;
    resolve: any;
    reject: any;
}): any {
    try {
        const isUpload = payload.hasOwnProperty('profile_picture') || payload.hasOwnProperty('feature_image');
        const response = yield call(api, updateProfileUrl, httpRequest.PATCH, payload, 0, undefined, isUpload);
        const { status, data } = response;
        if (status === 'success') {
            const parsedProfile = genericParseSingleDocument(data.user as IUpdateUserProfilePayload);
            yield put(updateUserProfileSuccess(parsedProfile));
            yield call(showToastMessage, 'Your changes have been saved.', 'success');
            yield put(destroyOneCache({ cacheType: CACHE_TYPE.USER_APP_DETAILS }));
            resolve(response);
        } else {
            reject(response);
        }
    } catch (error: any) {
        SentryCapture(error, 'error');
        yield call(showToastMessage, 'There was a problem saving your changes. Please try again.', 'error');
        yield put(updateUserProfileFailed());
        reject(error);
    }
}

function* getExternalProfile({ payload: username }: any): any {
    const defaultUseCache = yield* isUseCacheEnabled();
    const cache: NotUniqueCacheValue = yield* getNotUniqueCacheByKey(CACHE_TYPE.EXTERNAL_PROFILE, username);
    if (defaultUseCache && cache && cache.key && cache.value) {
        yield put(getExternalUserProfileSuccess(cache.value));
    } else {
        try {
            const response = yield call(api, `${getUserProfileUrl}?userName=${username}`, httpRequest.GET, null, 0, 12);
            const { data, message } = response;

            if (!data) {
                yield put(getExternalUserProfileFailed(message));
            } else {
                const profile = parseUserDashBoard(data.dashboard[0].result[0]);
                if (defaultUseCache) {
                    yield put(
                        setCache({ key: username, value: profile, type: CACHE_TYPE.EXTERNAL_PROFILE, isUnique: false }),
                    );
                }

                yield put(getExternalUserProfileSuccess(profile));
            }
        } catch (error) {
            SentryCapture(error, 'error');
            yield put(getExternalUserProfileFailed(error));
        }
    }
}

function* addActivityLike({ payload }: { payload: ILikeActivityPayload }): any {
    const { activity, isExternalProfile, cache, from } = payload;
    try {
        // const { like_category, like_type, projectId, like_origin } = payload;
        const activityId =
            activity.activity.type === 'Project'
                ? (activity as ProjectActivity).project_activity._id
                : (activity as ContestActivity).contest_activity._id;
        const like_category = activity?.activity?.type?.toLowerCase();
        const like_type = 'like';
        const requestUrl = activity.activity.type === 'Project' ? projectUrl : getContestUrl;
        const request = `${requestUrl}/${activityId}/like${formQueryParams({
            cache: cache as string,
            from: from as string,
        })}`;
        const response = yield call(api, request, httpRequest.POST, { like_category, like_type }, 0, undefined, false);
        const { data } = response.data;
        yield put(updateActivityLikeSuccess({ activity, like: data, isExternalProfile }));
    } catch (error: any) {
        SentryCapture(error, 'error');
        yield put(updateActivityLikeFailed({ activity, isExternalProfile }));
    }
}

function* deleteActivityLike({ payload }: { payload: IRemoveLikeActivity }): any {
    const { activity, isExternalProfile, like, cache, from } = payload;
    try {
        const request = `${likeUrl}/${like._id}${formQueryParams({ cache: cache as string, from: from as string })}`;
        yield call(api, request, httpRequest.DELETE, {}, 0, undefined, false);
        yield put(removeActivityLikeSuccess({ activity, isExternalProfile }));
    } catch (error: any) {
        SentryCapture(error, 'error');
        yield put(removeActivityLikeFailed({ activity, isExternalProfile, like }));
    }
}

function* resetToken() {
    const { token, refreshToken } = yield* getJwt();
    if (token) yield call(removeJwt);
    if (refreshToken) yield call(removeRefreshToken);
    yield put(deleteJwtSuccess());
}

function* disableUserAccount({ payload }: { payload: IDisableAccount }): Generator<any, any, any> {
    try {
        const response = yield call(api, userDeleteUrl, httpRequest.DELETE, payload, 2, 1000);
        const { data } = response;
        yield put(disableAccountSuccess());
        yield put(logout());
        yield call(showToastMessage, data.message, 'success');
    } catch (error: any) {
        yield put(disableAccountFail(error));
    }
}

// ********************************************** WACTHERS ************************************************************** */
interface TaskAction extends Action {
    payload: IGetTopUserPayload;
}

interface IVerifyEmailTaskAction extends Action {
    payload: IVerifyEmail;
}

interface IDisableAccountAction extends Action {
    payload: IDisableAccount;
}
function* resetPasswordWatcher() {
    yield takeLatest(types.RESET_PASSWORD, resetPassword);
}

function* getResetPasswordTokenWatcher() {
    yield takeLatest(types.GET_RESET_PASSWORD_TOKEN, getResetPasswordToken);
}

function* createUserProfileWatcher(): any {
    while (true) {
        const registerTask = yield takeLatest(types.CREATE_USER_PROFILE, createUserProfile);
        yield take(types.REGISTER_CANCEL);
        yield cancel(registerTask);
    }
}

function* fieldValidationWatcher() {
    yield takeLatest(types.START_EMAIL_FIELD_VERIFICATION, fieldVerification);
    yield takeLatest(types.START_PHONE_FIELD_VERIFICATION, fieldVerification);
    yield takeLatest(types.START_EMAIL_FIELD_VERIFICATION2, newFieldVerification);
    yield takeLatest(types.START_PHONE_FIELD_VERIFICATION2, newFieldVerification);
    yield takeLatest(types.START_USERNAME_FIELD_VERIFICATION, newFieldVerification);
}

function* getPhoneNumberVerificationCodeWatcher() {
    yield takeLatest(types.GET_PHONE_VERIFICATION_CODE, getPhoneVerificationCode);
}

function* getUserProfileWatcher() {
    yield takeLatest(types.GET_USER_PROFILE, getUserProfile);
}
function* getExternalUserProfileWatcher() {
    yield takeLatest(types.GET_A_USER_PROFILE, getExternalProfile);
}

function* searchUserWatcher() {
    yield takeLatest<any>(types.SEARCH_USER, searchUser);
}

function* updateUserWatcher() {
    yield takeLatest<any>(types.UPDATE_USER_PROFILE, updateUserProfile);
}

function* likeActivityWatcher() {
    yield takeLatest<any>(types.UPDATE_ACTIVITY_LIKE, addActivityLike);
}
function* removeActivityLikeWatcher() {
    yield takeLatest<any>(types.REMOVE_ACTIVITY_LIKE, deleteActivityLike);
}
function* getTopUserWatcher() {
    yield takeLatest<TaskAction>(types.GET_TOP_USER, getTopUser);
}
function* changePasswordWatcher() {
    yield takeLatest<TaskAction>(types.CHANGE_PASSWORD, changePassword);
}

function* initiateEmailVerificatioWatcher() {
    yield takeLatest<TaskAction>(types.INITIATE_EMAIL_VERIFY, initiateEmailVerification);
}

function* verifyEmailWatcher() {
    yield takeLatest<IVerifyEmailTaskAction>(types.VERIFY_EMAIL, verifyEmail);
}

function* disableUserAccountWatcher() {
    yield takeLatest<IDisableAccountAction>(types.DISABLE_ACCOUNT, disableUserAccount);
}
export default function* userSaga() {
    yield all([
        changePasswordWatcher(),
        createUserProfileWatcher(),
        fieldValidationWatcher(),
        getExternalUserProfileWatcher(),
        getPhoneNumberVerificationCodeWatcher(),
        getResetPasswordTokenWatcher(),
        getTopUserWatcher(),
        getUserProfileWatcher(),
        likeActivityWatcher(),
        removeActivityLikeWatcher(),
        resetPasswordWatcher(),
        searchUserWatcher(),
        updateUserWatcher(),
        initiateEmailVerificatioWatcher(),
        verifyEmailWatcher(),
        disableUserAccountWatcher(),
    ]);
}
