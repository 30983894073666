import { IContest, IProject, UserSummary } from './helper';
import { IActSummary, IProjectSummary } from './media.types';

export type Location = {
    type: string;
    coordinates: number[];
    address?: string;
    description?: string;
};

export type Status = 'published' | 'draft' | 'archived' | string;
export type IContestStatus = 'published' | 'draft';

export type Country = { name: string; flag: string; code: string; dial_code: string };

export type SelectionType = 'single' | 'multiple';

export interface IAutoSuggestionSearchCell {
    type: 'talent' | 'project' | 'contest';
    searchImage: string;
    title: string;
    id: string;
    isEvent?: boolean;
    contest_id?: string;
    preferred_name?: string;
}

export interface DiscoverySearchModel {
    type: string;
    item: IAutoSuggestionSearchCell[];
    label: string;
}

export interface DiscoverySearchUnparsedResponse {
    acts: IActSummary[] | IProject[];
    projects: IProjectSummary[];
    contests: IContest[];
    users: UserSummary[];
    suggestion: IProject[] | IContest[] | UserSummary[];
}

export enum PLAYER_CONTROL {
    ON = 'on',
    OFF = 'off',
}

export type TPlayerControl = 'off' | 'on';

export type ActivityType =
    | 'commented on project'
    | 'commented on contest'
    | 'commented on act'
    | 'joined a contest'
    | 'voted for project'
    | 'joined thspian'
    | 'initiated a search'
    | 'viewed a project'
    | 'created a project'
    | 'created an act'
    | 'added team member'
    | 'liked a project'
    | 'liked a review'
    | 'liked a movie'
    | 'liked an act'
    | 'liked a comment'
    | 'liked a contest'
    | 'initiated a search'
    | 'liked a thougth'
    | 'created a thougth'
    | 'shared a thougth'
    | 'commented on a thougth'
    | 'voted for a submission'
    | 'created a contest'
    | 'won a contest';
