import { IVote } from '../contest/types';

export const types = {
    GET_USER_VOTES: 'GET_USER_VOTES',
    GET_USER_VOTES_SUCCESS: 'GET_USER_VOTES_SUCCESS',
    GET_USER_VOTES_FAIL: 'GET_USER_VOTES_FAIL',

    GET_USER_VOTER_ID: 'GET_USER_VOTER_ID',
    GET_USER_VOTER_ID_SUCCESS: 'GET_USER_VOTER_ID_SUCCESS',
    GET_USER_VOTER_ID_FAIL: 'GET_USER_VOTER_ID_FAIL',
};

export interface IVoteInititalState {
    userVotes: IVote[];
    gettingVotes: boolean;
    userVoterId?: TVoterCard;
    gettingVoterId: boolean;
}

export type TVoterCard = {
    _id: string;
    email?: string;
    phone_number: string;
    verified: boolean;
    created_by: string;
    createdAt: string;
    updatedAt: string;
    verification_count: number;
};

export interface IVoterCard {
    verified: boolean;
    verification_count: number;
    _id: string;
    phone_number: string;
    createdAt: string;
    updatedAt: string;
    __v: number;
    created_by: string;
    id: string;
}
