import { createAction } from 'redux-act';
import { IGetUserLikes, ILike, types } from './types';
import { TalentProfileResponse, CastingDirectorResponse } from '../../types/global/MoviesDetailsEditor.types';
import { IDashboard } from '../../types/global/helper';
import { IErrorType } from '../../constants/types';

export const getCurrentUserReviews = () => ({
    type: types.GET_CURRENT_USER_REVIEWS,
});

export const getCurrentUserReviewsSuccess = (payload: any) => ({
    type: types.GET_CURRENT_USER_REVIEWS_SUCCESS,
    payload,
});

export const getCurrentUserReviewsFail = (error: any) => ({
    type: types.GET_CURRENT_USER_REVIEWS_FAIL,
    payload: error,
});

export const getCurrentUserCastingRoles = () => ({
    type: types.GET_CASTING_ROLES,
});

export const getCurrentUserCastingRolesSuccess = (payload: any) => ({
    type: types.GET_CASTING_ROLES_SUCCESS,
    payload,
});

export const getCurrentUserCastingRolesFail = (error: any) => ({
    type: types.GET_CASTING_ROLES_FAIL,
    payload: error,
});

export const getCurrentUserWatchList = () => ({
    type: types.GET_CURRENT_USER_WATCHLIST,
});

export const getCurrentUserWatchListSuccess = (payload: any) => ({
    type: types.GET_CURRENT_USER_WATCHLIST_SUCCESS,
    payload,
});

export const getCurrentUserWatchListFail = (error: any) => ({
    type: types.GET_CURRENT_USER_WATCHLIST_FAILED,
    payload: error,
});

export const getCurrentUsersAuditions = () => ({
    type: types.GET_CURRENT_USER_AUDITIONS,
});

export const getCurrentUsersAuditionsSuccess = (payload: any) => ({
    type: types.GET_CURRENT_USER_AUDITIONS_SUCCESS,
    payload,
});

export const getCurrentUsersAuditionsFail = (payload: any) => ({
    type: types.GET_CURRENT_USER_AUDITIONS_FAIL,
    error: payload,
});

export const getContributions = () => ({
    type: types.GET_CONTRIBUTIONS,
});

export const getContributionsSuccess = (payload: any) => ({
    type: types.GET_CONTRIBUTIONS_SUCCESS,
    payload,
});

export const getContributionsFail = (payload: any) => ({
    type: types.GET_CONTRIBUTIONS_FAIL,
    error: payload,
});

type AudmeProfileData = {
    talentProfile?: TalentProfileResponse;
    castingProfile?: CastingDirectorResponse;
};

export interface IGetAudmeProfilesResponse {
    status: string;
    message: string;
    data?: AudmeProfileData;
}

export const getAccountDetails = createAction(types.GET_ACCOUNT_DETAILS);
export const getAccountDetailsSuccess = createAction<any>(types.GET_ACCOUNT_DETAILS_SUCCESS);
export const getAccountDetailsFail = createAction<any>(types.GET_ACCOUNT_DETAILS_FAIL);

export const accountRefresh = createAction(types.ACCOUNT_REFRESH);
export const accountRefreshSucess = createAction<any>(types.ACCOUNT_REFRESH_SUCCESS);
export const accountRefreshFail = createAction<any>(types.ACCOUNT_REFRESH_FAIL);

export const getuserAudmeProfile = createAction(types.GET_USER_AUDME_PROFILES);
export const getuserAudmeProfileSuccess = createAction<IGetAudmeProfilesResponse>(
    types.GET_USER_AUDME_PROFILES_SUCCESS,
);
export const getuserAudmeProfileFail = createAction<any>(types.GET_USER_AUDME_PROFILES_FAIL);

export const getUserDashboard = createAction(types.GET_USER_DASHBOARD);
export const getUserDashboardSuccess = createAction<IDashboard>(types.GET_USER_DASHBOARD_SUCCESS);
export const getUserDashboardFail = createAction<IErrorType>(types.GET_USER_DASHBOARD_FAIL);

export const getUserLikes = createAction<IGetUserLikes>(types.GET_USER_LIKES);
export const getUserLikesSuccess = createAction<ILike[]>(types.GET_USER_LIKES_SUCCESS);
export const getUserLikesFail = createAction<IErrorType>(types.GET_USER_LIKES_FAIL);

export const getSingleLike = createAction<IGetUserLikes>(types.GET_SINGLE_LIKE);
export const getSingleLikeSuccess = createAction<ILike>(types.GET_SINGLE_LIKE_SUCCESS);
export const getSingleLikeFail = createAction<IErrorType>(types.GET_SINGLE_LIKE_FAIL);
export const resetAudmeProfile = createAction(types.RESET_AUDME_PROFILE);

/** Update User Dashboard without UI refresh */
export const refreshUserDashboard = createAction(types.REFRESH_USER_DASHBOARD);
