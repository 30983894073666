import { createAction } from 'redux-act';
import { types } from './types';

export const getAllUsers = (payload?: any) => ({
    type: types.GET_ALL_USERS,
    payload,
});

export const getAllUsersSuccess = (payload: any) => ({
    type: types.GET_ALL_USERS_SUCCESS,
    payload,
});

export const getAllUsersFail = (payload: any) => ({
    type: types.GET_ALL_USERS_FAIL,
    error: payload,
});

export const getAllMessages = (payload: any) => ({
    type: types.GET_ALL_MESSAGES,
    payload,
});

export const getAllMessagesSuccess = (payload: any) => ({
    type: types.GET_ALL_MESSAGES_SUCCESS,
    payload,
});

export const getAllMessagesFaail = (payload: any) => ({
    type: types.GET_ALL_MESSAGES_FAIL,
    payload,
});

export const sendMessageSuccess = (payload: any) => ({
    type: types.SEND_MESSAGE_SUCCESS,
    payload,
});

export const sendMessageFail = (payload: any) => ({
    types: types.SEND_MESSAGE_FAIL,
    payload,
});

export const deleteMessage = (payload: any) => ({
    types: types.DELETE_MESSAGE,
    payload,
});

export const deleteMessageSuccess = (payload: any) => ({
    types: types.DELETE_MESSAGE_SUCCESS,
    payload,
});

export const deleteMessageFail = (payload: any) => ({
    type: types.DELETE_MESSAGE_FAIL,
    payload,
});

export const disconnect = () => ({
    type: types.DISCONNECT,
});

export const userTyping = (payload: any) => ({
    type: types.USER_TYPING,
    payload,
});

export const userStopTyping = (payload: any) => ({
    type: types.USER_STOP_TYPING,
    payload,
});

type Payload = {
    data: {
        query: {
            user: string;
        };
    };
};

type JoinChatPayload = {
    roomID: string;
    userID: string;
};

type JoinAllChatPayload = {
    rooms: Array<string>;
    userID?: string;
};

type LastMessageRead = {
    user: string;
    message: string;
};

type ChatRead = {
    roomID: string;
    readBy: Array<string>;
    lastMessageIdRead?: Array<LastMessageRead>;
};

type TypingStatus = {
    status: 'yes' | 'no';
    roomID: string;
};

type OnlineStatus = 'online' | 'offline';

export type NewContentType = 'audition' | 'act' | 'talents';

export const retrieveMessages = createAction<Payload>(types.RETRIEVE_MESSAGES);
export const joinChatRoom = createAction<JoinChatPayload>('JOIN_CHAT');
export const joinAllChatRooms = createAction<JoinAllChatPayload>(types.JOIN_ALL_CHAT_ROOMS);
export const typingStatusSocket = createAction<TypingStatus>(types.TYPING_STATUS);
export const sendMessage = createAction<any>(types.SEND_MESSAGE);
export const userHasReadMessage = createAction<ChatRead>(types.USER_HAS_READ);
export const getMessagesForSelectedRoom = createAction<JoinChatPayload>(types.GET_ALL_MESSAGES);
export const setOnlineStatus = createAction<OnlineStatus>(types.SET_ONLINE_STATUS);
export const setOnlineUsers = createAction<OnlineStatus>(types.SET_ONLINE_USERS);
export const setOfflineUsers = createAction<any>(types.SET_OFFLINE_USER);
export const newContent = createAction<NewContentType>(types.NEW_CONTENT);
export const setNewContentAvailable = createAction<NewContentType>(types.NEW_CONTENT_AVAILABLE);
export const resetNewContent = createAction(types.RESET_NEW_CONTENT);

export const getMyRooms = (payload?: any) => ({
    type: types.GET_MY_ROOMS,
    payload,
});

export const getMyRoomsSuccess = (payload: any) => ({
    type: types.GET_MY_ROOMS_SUCCESS,
    payload,
});

export const getMyRoomsFail = (payload: any) => ({
    type: types.GET_MY_ROOMS_SUCCESS,
    error: payload,
});

export const setRoomMessages = (payload: any) => ({
    type: types.SET_ROOM_MESSAGES,
    payload,
});

export const setRoomMessagesSuccess = (payload: any) => ({
    type: types.SET_ROOM_MESSAGES_SUCCESS,
    payload,
});

export const setRoomMessagesFail = (payload: any) => ({
    type: types.SET_ROOM_MESSAGES_FAIL,
    payload,
});

type InitiateMessage = {
    /** Current logged in User */
    sender: string;
    /** USer message is being sent to */
    receiver: string;
    /** Optional Audition data */
    audition?: string;
    /** Optiuonal Role data */
    role?: string;
    /** Message content */
    message_content: string;
};

export const initiateMessageForRoom = createAction<InitiateMessage>(types.INITIATE_MESSAGE);
export const initiateMessageForRoomSuccess = createAction<any>(types.INITIATE_MESSAGE_SUCCESS);
export const initiateMessageForRoomFail = createAction<any>(types.INITIATE_MESSAGE_FAIL);

export const connect = createAction(types.CONNECT);
export const connectSuccess = createAction<any>(types.CONNECT_SUCCESS);
export const connectFail = createAction<any>(types.CONNECT_FAIL);

export const disconnectSuccess = createAction<any>(types.DISCONNECT_SUCCESS);
