import { Box, Stack, Typography } from '@mui/material';
import React from 'react';
import { useThemeContext } from '../../hook/useThemeManager';
import { FONT_ENUM } from '../../types/global/fonts.types';
import { theme } from '../../constants/theme';

interface IToast {
    message?: string;
    onClick?: () => void;
}
const CustomToast: React.FC<IToast> = ({ message, onClick }) => {
    const { themeStyle } = useThemeContext();
    return (
        <Stack direction="row" alignItems="center">
            <Typography
                style={{ fontFamily: FONT_ENUM.MEDIUM_TEXT, fontSize: 12, color: theme[themeStyle].lightWhite }}>
                {message}
            </Typography>
            <Box
                sx={{
                    borderWidth: 0.5,
                    border: `1px solid ${theme[themeStyle].lightWhite}`,
                    width: 'fit-content',
                    borderRadius: 10,
                    marginLeft: 2,
                }}>
                <Typography
                    style={{
                        fontFamily: FONT_ENUM.MEDIUM_TEXT,
                        fontSize: 12,
                        color: theme[themeStyle].lightWhite,
                        padding: 5,
                    }}
                    onClick={onClick}>
                    View
                </Typography>
            </Box>
        </Stack>
    );
};

CustomToast.defaultProps = {
    message: 'Project upload is complete',
    onClick: () => console.log('Implement OnClick For CustomToast'),
};

export { CustomToast };
