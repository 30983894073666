import React from 'react';
import { StyleProp, StyleSheet, TextInput, TextInputProps, View, ViewStyle } from 'react-native';

import { ColorScheme, colors, theme } from '../../constants/theme';
import { LinearGradient } from '../LinearGradient/LinearGradient';
import { GenericSize, Fonts } from '../../module/types';
import { FONT_ENUM } from '../../types/global/fonts.types';
import { useThemeContext } from '../../hook/useThemeManager';
import { ScreenTypes } from '../../module/ThemeManager';

interface ISingleTextInput extends TextInputProps {
    style?: StyleProp<ViewStyle>;
    mainStyle?: StyleProp<ViewStyle>;
    isFocused?: boolean;
}

const SingleTextInput = React.forwardRef((props: ISingleTextInput, ref: any) => {
    const { isFocused, style, mainStyle } = props;
    const Container = isFocused ? LinearGradient : View;
    const { themeStyle, sizes, device } = useThemeContext();
    const { genericSize, font } = sizes;
    const styles = dynamicStyle(themeStyle, genericSize, font, device);
    return (
        <View style={[styles.topContainer, mainStyle]}>
            <Container
                gradientColor={[colors.button.start, colors.button.mid, colors.button.midII, colors.button.end]}
                isFocused>
                <View style={styles.wrapper}>
                    <View style={[styles.innerContainer, style]}>
                        <TextInput {...props} style={styles.textinput} maxLength={1} textAlign="center" ref={ref} />
                    </View>
                </View>
            </Container>
        </View>
    );
});

SingleTextInput.defaultProps = {
    isFocused: true,
};

export { SingleTextInput };

const dynamicStyle = (coloScheme: ColorScheme, genericSize: GenericSize, font: Fonts, device: ScreenTypes) =>
    StyleSheet.create({
        innerContainer: {
            borderRadius: 8, // <-- Inner Border Radius
            backgroundColor: '#fff',
            height: 'auto',
            width: 'auto',
            margin: 2,
            maxWidth: device.isDesktop ? genericSize._size70 : genericSize._size50,
            maxHeight: device.isDesktop ? genericSize._size70 : genericSize._size50,
        },
        textinput: {
            color: theme[coloScheme].secondary_dark,
            height: genericSize._size100,
            fontFamily: FONT_ENUM.BOLD_HEADER,
            paddingHorizontal: genericSize._size10,
            // @ts-ignore
            outlineStyle: 'none',
            maxWidth: genericSize._size100,
            fontSize: font._fontSize32,
            textAlign: 'center',
        },
        topContainer: {
            height: 'auto',
            width: 'auto',
            justifyContent: 'center',
            alignItems: 'center',
        },
        wrapper: { width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center' },
    });
