import { ReactNode } from 'react';
import { Box, Typography } from '@mui/material';
import { StyleSheet } from 'react-native';
import { useThemeContext } from '../../hook/useThemeManager';
import { ColorScheme, theme } from '../../constants/theme';
import { FONT_ENUM } from '../../types/global/fonts.types';

type FormWrapperProps = {
    title: string;
    children: ReactNode;
};

export function EsLoginWrapper({ title, children }: FormWrapperProps) {
    const { themeStyle } = useThemeContext();
    const styles = dynamicStyles(themeStyle);

    return (
        <Box>
            <Typography style={styles.title}>{title}</Typography>
            {children}
        </Box>
    );
}

const dynamicStyles = (colorScheme: ColorScheme) =>
    StyleSheet.create({
        title: {
            maxWidth: 400,
            width: '100%',
            textAlign: 'left',
            fontSize: 24,
            fontFamily: FONT_ENUM.MAIN_MEDIUM,
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: '32px',
            color: theme[colorScheme].primary_white,
        },
    });
