import { call, put, all, takeLatest } from 'redux-saga/effects';
import { types } from './types';
import {
    setSubscription,
    setSubscriptionSuccess,
    setSubscriptionFail,
    getSubscriptionPlansSuccess,
    getSubscriptionPlansFail,
    cancelSubsciptionSuccess,
    cancelSubsciptionFail,
    updateSubscriptionSuccess,
    updateSubscriptionFail,
    subscribeSuccess,
    subscribeFail,
} from './actions';
import { globalErrorHandler } from '../error/saga';
import { api } from '../../configurations/api';
import {
    getSubscriptionPlansUrl,
    getMySubscriptionUrl,
    cancelSubscriptionUrl,
    createSubscriptionUrl,
    updateSubscriptionUrl,
} from '../../configurations/api/url';
import { httpRequest } from '../types';
import { SentryCapture } from '../../analytics/Sentry';

function* getSubscriptionPlan({ resolve, reject }: any): any {
    try {
        const response = yield call(api, getSubscriptionPlansUrl, httpRequest.GET, null, 3, 0);
        const { data } = response.data;
        yield put(getSubscriptionPlansSuccess(data));
        resolve(data);
    } catch (error: any) {
        SentryCapture(error, 'error');
        const { data } = error || {};
        yield put(getSubscriptionPlansFail(error));
        yield call(globalErrorHandler, data.message);
        reject(data);
    }
}

function* setSubscriptionWorker({ resolve, reject }: any): any {
    try {
        const response = yield call(api, getMySubscriptionUrl, httpRequest.GET, null, 5, 0);
        const { data } = response.data;
        yield put(setSubscriptionSuccess(data[0]));
        resolve(data);
    } catch (error: any) {
        SentryCapture(error, 'error');
        yield put(setSubscriptionFail(error));
        yield call(globalErrorHandler, error);
        reject(error);
    }
}

function* cancelSubscription({ payload, resolve, reject }: any): any {
    try {
        const request = `${cancelSubscriptionUrl}${payload}`;
        const response = yield call(api, request, httpRequest.PATCH, {}, 3, 0);
        const { data } = response;
        yield put(cancelSubsciptionSuccess(data));
        resolve(data);
    } catch (error: any) {
        SentryCapture(error, 'error');
        const { data } = error || {};
        yield put(cancelSubsciptionFail(error));
        yield call(globalErrorHandler, error.message);
        reject(data);
    }
}

function* subscribe({ payload, resolve, reject }: any): any {
    try {
        const response = yield call(api, createSubscriptionUrl, httpRequest.POST, payload, 2, 3000);
        const { data } = response;
        yield put(subscribeSuccess(data));
        resolve(data);
    } catch (error: any) {
        SentryCapture(error, 'error');
        yield put(subscribeFail(error));
        yield call(globalErrorHandler, error);
        reject(error);
    }
}

function* updateSubscription({ payload, resolve, reject }: any): any {
    try {
        const request = `${updateSubscriptionUrl}${payload}`;
        const response = yield call(api, request, httpRequest.PATCH, null, 2, 3000);
        const { data } = response;
        yield put(updateSubscriptionSuccess(data));
        yield put(setSubscription());
        resolve(data);
    } catch (error: any) {
        SentryCapture(error, 'error');
        const { data } = error || {};
        yield put(updateSubscriptionFail(error));
        yield call(globalErrorHandler, data.message);
        reject(data);
    }
}

function* getSubscriptionPlanWatcher() {
    yield takeLatest(types.GET_SUBSCRIPTION_PLANS, getSubscriptionPlan);
}

function* getMySubscriptionWatcher() {
    yield takeLatest(types.SET_SUBSCRIPTION, setSubscriptionWorker);
}

function* cancelSubscriptionWatcher() {
    yield takeLatest(types.CANCEL_SUBSCRIPTION, cancelSubscription);
}

function* subscribeWatcher() {
    yield takeLatest(types.SUBSCRIBE, subscribe);
}

function* updateSubscriptionWatcher() {
    yield takeLatest(types.UPDATE_SUBSCRIPTION, updateSubscription);
}

export default function* subscriptionSaga() {
    yield all([
        getSubscriptionPlanWatcher(),
        getMySubscriptionWatcher(),
        cancelSubscriptionWatcher(),
        subscribeWatcher(),
        updateSubscriptionWatcher(),
    ]);
}
