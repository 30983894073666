import { call, put, all, takeLatest } from 'redux-saga/effects';
import { trackAdSuccess, trackAdFail, getAdSuccess, getAdFail } from './action';
import { types } from './types';
import { api } from '../../configurations/api';
import { httpRequest } from '../types';
import { adsUrl, trackAdUrl } from '../../configurations/api/url';
import { SentryCapture } from '../../analytics/Sentry';
import { IGetAds, ITrackVrollAds } from './model';

function* trackAd({ payload }: { payload: ITrackVrollAds }): any {
    const { action, id } = payload;
    const reequst = `${trackAdUrl}?event=${action}&id=${id}&project=${payload.project}`;
    try {
        yield call(api, reequst, httpRequest.GET, {}, 2, 1000);
        yield put(trackAdSuccess());
    } catch (error: any) {
        SentryCapture(error);
        yield put(trackAdFail(error));
    }
}

function* getAds({ payload }: { payload: IGetAds }): any {
    try {
        const response = yield call(api, adsUrl, httpRequest.GET, {}, 2, 1000);
        const { data } = response.data;
        yield put(getAdSuccess(data));
    } catch (error: any) {
        yield put(getAdFail(error));
    }
}

function* trackAdWatcher() {
    yield takeLatest<any>(types.TRACK_AD, trackAd);
}

function* getAdWatcher() {
    yield takeLatest<any>(types.GET_AD, getAds);
}

export default function* adSaga() {
    yield all([trackAdWatcher(), getAdWatcher()]);
}
