import React from 'react';
import { ThemeContext } from '../../module/ThemeManager';
import { theme } from '../../constants/theme';
import { Box, Grid, Skeleton, Stack } from '@mui/material';

const SuggestedContestsShimmer = () => {
    return (
        <ThemeContext.Consumer>
            {({ themeStyle }) => {
                return (
                    <Grid container xs={4} md={4} sm={4} lg={4} height={'fit-content'} overflow={'hidden'}>
                        <Box
                            sx={{
                                width: '100%',
                                marginLeft: '20px',
                                overflowY: 'scroll',
                            }}>
                            <Box position={'relative'}>
                                <Stack direction={'column'}>
                                    <Skeleton
                                        variant="rounded"
                                        height="70px"
                                        sx={{
                                            width: '100%',
                                            backgroundColor: theme[themeStyle].gray_text,
                                        }}
                                    />

                                    <Skeleton
                                        variant="text"
                                        sx={{
                                            fontSize: '1rem',
                                            width: '120px',
                                            backgroundColor: theme[themeStyle].darkGray60,
                                            position: 'absolute',
                                            top: '20px',
                                            left: '20px',
                                        }}
                                    />
                                    <Skeleton
                                        variant="rounded"
                                        height="100vh"
                                        sx={{
                                            width: '100%',
                                        }}
                                    />
                                    <Box sx={{ position: 'absolute', top: '100px', left: '20px' }}>
                                        <Grid container spacing={2}>
                                            {Array.from(new Array(6)).map((_, index) => (
                                                <Grid item xs={12} md={12} lg={12} key={index}>
                                                    <Stack key={index} direction={'column'} spacing={2}>
                                                        <Skeleton
                                                            variant="rounded"
                                                            height={245}
                                                            sx={{
                                                                width: { sm: '230px', lg: '380px' },
                                                                backgroundColor: theme[themeStyle].gray_text,
                                                                borderRadius: '10px',
                                                            }}
                                                        />

                                                        <Stack direction="column">
                                                            <Skeleton
                                                                variant="text"
                                                                sx={{
                                                                    fontSize: '1rem',
                                                                    width: {
                                                                        xs: '200px',
                                                                        sm: '100px',
                                                                        md: '150px',
                                                                        lg: '200px',
                                                                    },
                                                                    backgroundColor: theme[themeStyle].darkGray60,
                                                                    height: '20px',
                                                                }}
                                                            />
                                                            <Skeleton
                                                                variant="text"
                                                                sx={{
                                                                    fontSize: '1rem',
                                                                    width: {
                                                                        xs: '220px',
                                                                        sm: '120px',
                                                                        md: '170px',
                                                                        lg: '220px',
                                                                    },
                                                                    backgroundColor: theme[themeStyle].darkGray60,
                                                                    height: '20px',
                                                                }}
                                                            />
                                                            <Skeleton
                                                                variant="text"
                                                                sx={{
                                                                    fontSize: '1rem',
                                                                    width: {
                                                                        xs: '230px',
                                                                        sm: '130px',
                                                                        md: '180px',
                                                                        lg: '230px',
                                                                    },
                                                                    backgroundColor: theme[themeStyle].darkGray60,
                                                                }}
                                                            />
                                                        </Stack>
                                                    </Stack>
                                                </Grid>
                                            ))}
                                        </Grid>
                                    </Box>
                                </Stack>
                            </Box>
                        </Box>
                    </Grid>
                );
            }}
        </ThemeContext.Consumer>
    );
};
export { SuggestedContestsShimmer };
