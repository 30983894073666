import produce from 'immer';

import { types } from './types';

const initialState = {
    errorMsg: null,
    errorShown: false,
};

const reducer = (state = initialState, action: any) => {
    const { type, payload } = action;
    switch (type) {
        case types.SET_ERROR:
            return produce(state, draftState => {
                draftState.errorMsg = payload;
            });
        case types.CLEAR_ERROR:
            return produce(state, draftState => {
                draftState.errorMsg = null;
                draftState.errorShown = false;
            });
        default:
            return state;
    }
};

export { reducer };
