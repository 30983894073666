import { StyleSheet } from 'react-native';
import React from 'react';
import { Box, Stack } from '@mui/material';
import CustomButton from '../Button/CustomButton';
import space_walk from '../../asset/images/image/space_walk.svg';
import { useThemeContext } from '../../hook/useThemeManager';
import { strings } from '../../constants';
import { ColorScheme, theme } from '../../constants/theme';
import { Fonts } from '../../module/types';
import { FONT_ENUM } from '../../types/global/fonts.types';
import { ScreenTypes } from '../../module/ThemeManager';
import { useLottie } from 'lottie-react';
import { space } from '../../assets/animations';
import { ReplaceCharacterWithLottie } from './ReplaceCharacterWithLottie';
import CustomLazyLoadImage from '../Image/CustomLazyLoadImage';

interface IPageNotFoundProps {
    onClick: () => void;
}

const PageNotFound: React.FC<IPageNotFoundProps> = ({ onClick }) => {
    const { device, themeStyle, sizes } = useThemeContext();
    const styles = dynamicStyles(themeStyle, sizes.font, device);
    const options = {
        animationData: space,
        loop: true,
    };
    useLottie(options);
    return (
        <Stack
            direction={device.isMobile ? 'column-reverse' : 'row'}
            width={'100%'}
            alignItems="center"
            justifyContent="space-evenly">
            <Box sx={{ width: '100%', flex: 0.5 }}>
                <Stack>
                    {/* <Typography style={styles.text}>{strings.page_not_found}</Typography> */}
                    <ReplaceCharacterWithLottie
                        style={styles.text}
                        animationData={space}
                        charToReplace="a"
                        sentence={strings.page_not_found}
                        targetWord="space"
                        replacementStyle={{ width: 120 }}
                    />
                </Stack>
                <CustomButton
                    label="Go Home"
                    onClick={onClick}
                    customStyle={{ width: device.isMobile ? '100%' : '75%', marginTop: 50 }}
                />
            </Box>
            <Box sx={{ flex: 0.5 }}>
                <CustomLazyLoadImage src={space_walk} alt="page_not_found" style={{ width: '100%' }} />
            </Box>
        </Stack>
    );
};

export { PageNotFound };

const dynamicStyles = (colorScheme: ColorScheme, font: Fonts, device: ScreenTypes) =>
    StyleSheet.create({
        text: {
            fontSize: device.isMobile ? 50 : font._fontSize96,
            fontFamily: FONT_ENUM.MAIN_BOLD,
            color: theme[colorScheme].lightWhite,
            lineHeight: 0.9,
            textAlign: device.isMobile ? 'center' : 'left',
        },
    });
