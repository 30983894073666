import produce from 'immer';
import { IContestRequest, IInitialRequestState, types } from './types';

const initialState: IInitialRequestState = {
    requests: [],
    userRequests: [],
    gettingRequests: false,
    resolvingRequests: false,
    creatingRequest: false,
    requestErrMsg: null,
};

const reducer = (state = initialState, action: any) => {
    const { type, payload } = action;
    switch (type) {
        case types.CREATE_REQUEST:
            return produce(state, draftState => {
                draftState.creatingRequest = true;
            });
        case types.CREATE_REQUEST_SUCCESS:
            return produce(state, draftState => {
                draftState.creatingRequest = false;
                draftState.userRequests.push(payload);
            });
        case types.CREATE_REQUEST_FAIL:
            return produce(state, draftState => {
                draftState.creatingRequest = false;
                draftState.requestErrMsg = payload;
            });
        case types.GET_MY_REQUESTS:
            return produce(state, draftState => {
                draftState.gettingRequests = true;
            });
        case types.GET_MY_REQUESTS_SUCCESS:
            return produce(state, draftState => {
                draftState.gettingRequests = false;
                draftState.userRequests = payload;
            });
        case types.GET_MY_REQUESTS_FAIL:
            return produce(state, draftState => {
                draftState.gettingRequests = false;
                draftState.requestErrMsg = payload;
            });
        case types.RESOLVE_REQUEST:
            return produce(state, draftState => {
                draftState.resolvingRequests = true;
            });
        case types.RESOLVE_REQUEST_FAIL:
            return produce(state, draftState => {
                draftState.resolvingRequests = false;
                draftState.requestErrMsg = payload;
            });
        case types.RESOLVE_REQUEST_SUCCESS:
            return produce(state, draftState => {
                draftState.resolvingRequests = false;
                const { _id } = payload as IContestRequest;
                const index = draftState.requests.findIndex(req => req._id === _id);
                if (index > -1) {
                    draftState.requests[index] = payload;
                }
            });
        case types.GET_REQUESTS:
            return produce(state, draftState => {
                draftState.gettingRequests = true;
            });
        case types.GET_REQUESTS_SUCCESS:
            return produce(state, draftState => {
                draftState.gettingRequests = false;
                draftState.requests = payload;
            });
        case types.GET_REQUESTS_FAIL:
            return produce(state, draftState => {
                draftState.gettingRequests = false;
                draftState.requestErrMsg = payload;
            });
        default:
            return state;
    }
};

export { reducer };
