export enum E_USER_PERMISSIONS {
    CONTENT_CREATOR = 'content-creator',
    HOST = 'host',
    ADMIN = 'admin',
    CRTITIC = 'critic',
    JURY = 'jury',
    TESTER = 'tester',
    USER = 'user',
    CASTING_DIRECTOR = 'casting-director',
    SCREEN_WRITER = 'screen_writer',
    EDITOR = 'editor',
}

export enum E_USER_TYPES {
    ACTOR = 'actor',
    CASTING_DIRECTOR = 'casting_director',
    CONTENT_CREATOR = 'content_creator',
    SKIT_MAKER = 'skit maker',
    SCREEN_WRITER = 'screen_writer',
    ARTIST = 'artist',
    ACTRESS = 'actress',
    CHILD_ACTOR = 'child actor',
    FILMMAKER = 'filmmaker',
    EDITOR = 'editor',
}

export type UserPermissionLevels =
    | 'actor'
    | 'casting-director'
    | 'content-creator'
    | 'screen_writer'
    | 'host'
    | 'admin'
    | 'critic'
    | 'jury'
    | 'tester'
    | 'user'
    | 'editor';
