import { Navigate, useLocation } from 'react-router-dom';
import { ROUTES } from '../types/global/routes.types';
import { getJWtDetails } from '../utils/AppUtils';
import { useSelector } from 'react-redux';
import { isAdminSelector, isUserCastingDirectorSelector, isUserJurySelector } from '../selectors/selectors';
import { E_USER_PERMISSIONS, UserPermissionLevels } from '../types/global/user.models';

interface IProtectedRoute {
    children: JSX.Element;
    accessCheck?: boolean;
    accessType?: UserPermissionLevels; // Add more here
}

export const ProtectedRoute = ({ children, accessCheck, accessType }: IProtectedRoute) => {
    const { isSignedIn } = getJWtDetails();
    const location = useLocation();
    const isJuryEnabled = useSelector(isUserJurySelector);
    const isCastingDirector = useSelector(isUserCastingDirectorSelector);
    const isAdmin = useSelector(isAdminSelector);

    if (!isSignedIn) {
        if (accessCheck) {
            if (accessType === E_USER_PERMISSIONS.JURY && !isJuryEnabled) {
                return <Navigate to={`${ROUTES.ESLOGIN}?redirect=${encodeURIComponent(location.pathname)}`} />;
            }

            if (accessType === E_USER_PERMISSIONS.CASTING_DIRECTOR && !isCastingDirector) {
                return <Navigate to={`${ROUTES.ESLOGIN}?redirect=${encodeURIComponent(location.pathname)}`} />;
            }
            if (accessType === 'admin' && !isAdmin) {
                return <Navigate to={`${ROUTES.ESLOGIN}?redirect=${encodeURIComponent(location.pathname)}`} />;
            }
        }
        return <Navigate to={`${ROUTES.ESLOGIN}?redirect=${encodeURIComponent(location.pathname)}`} />;
    }

    if (isSignedIn) {
        if (accessCheck) {
            if (accessType === E_USER_PERMISSIONS.JURY && !isJuryEnabled) {
                return <Navigate to={ROUTES.ESDISCOVERY} />;
            }

            if (accessType === E_USER_PERMISSIONS.CASTING_DIRECTOR && !isCastingDirector) {
                return <Navigate to={ROUTES.ESDISCOVERY} />;
            }
            if (accessType === 'admin' && !isAdmin) {
                return <Navigate to={ROUTES.ESDISCOVERY} />;
            }
        }
    }

    return children;
};
