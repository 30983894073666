import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { UserGlobalConfiguration } from '../utils/UserGlobalConfig';

/**
 *
 * Used in placs where we wish to have the filter hidden
 */
export const useShowFilter = () => {
    const [showFilter, setShowFiler] = useState(false);
    const location = useLocation();
    useEffect(() => {
        setShowFiler(UserGlobalConfiguration.shouldShowFiler(location));
    }, [location]);

    return {
        showFilter,
    };
};
