export type ConfirmationType = 'success' | 'message' | 'process' | undefined;

export enum ConfirmationMessages {
    INVITATION_SENT = 'Invitation Sent',
    MESSAGE_SENT = 'Message Sent',
    SUCCESS = 'Success',
    ADDED_TO_WATCHLIST = 'Added To WatchList',
    REMOVED_FROM_WATCHLIST = 'Removed from WatchList',
    FAILED = 'Failed',
}

export const types = {
    SET_IN_APP_NOTIFICATION: 'SET_IN_APP_NOTIFICATION',
    CLEAR_IN_APP_NOTIFICATION: 'CLEAR_IN_APP_NOTIFICATION',

    SET_CONFIRMATION_NOTIFICATION: 'SET_CONFIRMATION_NOTIFICATION',
    CLEAR_CONFIRMATION_NOTIFICATION: 'CLEAR_CONFIRMATION_NOTIFICATION',
};
