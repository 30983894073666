import { createAction } from 'redux-act';
import { types } from './types';

export interface EmailPayload {
    email: string;
    phone_number?: string;
    campaign_of_interest?: string;
    additional_info?: string;
}

export interface AdopterResponse {
    _id: string;
    email: string;
}

export interface IInitialAdopterState {
    savingEmail: boolean;
    savingEmailError: any;
}

export const saveEmail = createAction<EmailPayload>(types.SAVE_EMAIL);
export const saveEmailSuccess = createAction<AdopterResponse>(types.SAVE_EMAIL_SUCCESS);
export const saveEmailFail = createAction<any>(types.SAVE_EMAIL_FAIL);
