import React from 'react';
import { ThemeContext } from '../../module/ThemeManager';
import { Box, Stack, Skeleton } from '@mui/material';
import { theme } from '../../constants/theme';

const SquareCardShimmer = () => {
    return (
        <ThemeContext.Consumer>
            {({ themeStyle, device }) => {
                const value = device.isDesktop ? 5 : 2;

                return (
                    <Box overflow={'hidden'}>
                        <Stack direction="row" justifyContent="space-between" marginBottom={5}>
                            <Skeleton
                                variant="text"
                                sx={{ fontSize: '2rem', width: '100px', backgroundColor: theme[themeStyle].gray_text }}
                            />
                            <Skeleton
                                variant="text"
                                sx={{ fontSize: '2rem', width: '100px', backgroundColor: theme[themeStyle].gray_text }}
                            />
                        </Stack>

                        <Stack direction={{ xs: 'row', sm: 'row', md: 'row', lg: 'row' }} spacing={2}>
                            {Array.from(new Array(value)).map((_, index) => (
                                <Box key={index}>
                                    <Skeleton
                                        variant="rounded"
                                        height={284}
                                        sx={{
                                            borderRadius: 5,
                                            width: {
                                                xs: '284px',
                                                sm: '284px',
                                                md: '284px',
                                                lg: '284px',
                                            },
                                            backgroundColor: theme[themeStyle].gray_text,
                                        }}
                                    />
                                    <Box position="relative">
                                        <Skeleton
                                            variant="circular"
                                            width={50}
                                            height={50}
                                            sx={{
                                                backgroundColor: theme[themeStyle].darkGray60,
                                                position: 'absolute',
                                                bottom: 15,
                                                left: 15,
                                            }}
                                        />
                                        <Skeleton
                                            variant="text"
                                            sx={{
                                                fontSize: '1rem',
                                                width: '150px',
                                                backgroundColor: theme[themeStyle].darkGray60,
                                                position: 'absolute',
                                                bottom: 40,
                                                left: 80,
                                            }}
                                        />
                                        <Skeleton
                                            variant="text"
                                            sx={{
                                                fontSize: '1rem',
                                                width: '120px',
                                                backgroundColor: theme[themeStyle].darkGray60,
                                                position: 'absolute',
                                                bottom: 20,
                                                left: 80,
                                            }}
                                        />
                                    </Box>
                                </Box>
                            ))}
                        </Stack>
                    </Box>
                );
            }}
        </ThemeContext.Consumer>
    );
};

export { SquareCardShimmer };
