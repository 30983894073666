import { useRef, useEffect, useState, useCallback } from 'react';

interface ScrollDetectionResult {
    canScroll: boolean;
    onScrollEndReached: boolean;
    containerRef: React.RefObject<HTMLDivElement>;
}

const useScrollDetection = (): ScrollDetectionResult => {
    const containerRef = useRef<HTMLDivElement>(null);
    const [canScroll, setCanScroll] = useState<boolean>(false);
    const [onScrollEndReached, setOnScrollEndReached] = useState<boolean>(false);

    const handleScroll = useCallback(() => {
        const container = containerRef.current;

        if (container) {
            const scrollTop = container.scrollTop;
            const scrollHeight = container.scrollHeight;
            const clientHeight = container.clientHeight;

            setCanScroll(scrollHeight > clientHeight);
            setOnScrollEndReached(scrollTop + clientHeight === scrollHeight);
        }
    }, []);

    useEffect(() => {
        const container = containerRef.current;
        if (container) {
            container.addEventListener('scroll', handleScroll);
            container.addEventListener('onLoad', handleScroll);
            return () => {
                container.removeEventListener('scroll', handleScroll);
                container.removeEventListener('onLoad', handleScroll);
            };
        }
    }, [handleScroll]);

    return { canScroll, onScrollEndReached, containerRef };
};

export { useScrollDetection };
