import React from 'react';
import { ThemeContext } from '../../module/ThemeManager';
import { Box, Stack, Skeleton } from '@mui/material';
import { theme } from '../../constants/theme';

const HeroShimmer = () => {
    return (
        <ThemeContext.Consumer>
            {({ themeStyle }) => {
                return (
                    <Stack marginBottom={6}>
                        <Box position="relative">
                            <Skeleton
                                variant="rounded"
                                width="100%"
                                sx={{
                                    backgroundColor: theme[themeStyle].gray_text,
                                    borderRadius: 5,
                                    height: { xs: '440px', md: '371px', lg: '371px' },
                                }}
                            />
                            <Skeleton
                                variant="text"
                                sx={{
                                    fontSize: '1rem',
                                    width: '200px',
                                    backgroundColor: theme[themeStyle].darkGray60,
                                    position: 'absolute',
                                    bottom: { xs: '35%', md: '40%', lg: '40%' },
                                    left: 40,
                                }}
                            />
                            <Skeleton
                                variant="text"
                                sx={{
                                    fontSize: '1rem',
                                    width: '170px',
                                    backgroundColor: theme[themeStyle].darkGray60,
                                    position: 'absolute',
                                    bottom: { xs: '30%', md: '35%', lg: '35%' },
                                    left: 40,
                                }}
                            />
                            <Skeleton
                                variant="text"
                                sx={{
                                    fontSize: '1rem',
                                    width: '120px',
                                    backgroundColor: theme[themeStyle].darkGray60,
                                    position: 'absolute',
                                    bottom: { xs: '25%', md: '30%', lg: '30%' },
                                    left: 40,
                                }}
                            />
                            <Skeleton
                                variant="rounded"
                                sx={{
                                    backgroundColor: theme[themeStyle].primary_orange,
                                    width: { xs: '80px', md: '110px', lg: '110px' },
                                    height: 38,
                                    position: 'absolute',
                                    bottom: 45,
                                    left: 40,
                                    borderRadius: 100,
                                }}
                            />
                            <Skeleton
                                variant="rounded"
                                sx={{
                                    backgroundColor: theme[themeStyle].darkGray60,
                                    width: { xs: '80px', md: '110px', lg: '110px' },
                                    height: 38,
                                    position: 'absolute',
                                    bottom: 45,
                                    left: { xs: 130, md: 160, lg: 160 },
                                    borderRadius: 100,
                                }}
                            />
                        </Box>
                    </Stack>
                );
            }}
        </ThemeContext.Consumer>
    );
};

export { HeroShimmer };
