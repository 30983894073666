import { createSelector, Selector } from 'reselect';
import { RootState } from '../redux/root-reducer';
import { IUserInitialState, IUserProfile } from '../redux/user/actions';
import {
    CONTEST_PHASE,
    IAdvancedContest,
    IContest,
    IContestWatchList,
    IGlobalConfiguration,
    IGlobalConfigurationMediaControl,
    IProject,
    ISubmission as IGlobalSubmission,
    ITopUser,
    IThspianRenderedPlaylist,
} from '../types/global/helper';
import { IDiscoveryItem, ISubmission } from '../redux/discovery/types';
import {
    CREATOR_MODAL,
    CreatorType,
    DRAWER_STATE,
    DrawerStatus,
    FILTER_SWITCH,
    FilterValueSet,
    IAppConfiguratorInitialState,
    SwitchStatus,
    TGuide,
    TSwitch,
} from '../redux/appConfigurator/types';
import { DiscoveryFilter, FilterTypes } from '../data/filters/discovery.data';
import { DiscoverySearchModel } from '../types/global/generic.types';
import { ILike } from '../redux/account/types';
import { isEmpty } from '../utils/lodash';
import { InAppNotification } from '../redux/in-app-notification/reducer';
import { IBookmark, IContestInitialState, IVote } from '../redux/contest/types';
import { en_config } from '../config';
import { IUserConfiguration } from '../redux/configuration/types';
import { WebNotification } from '../redux/account/accountDetailsResponse.type';
import {
    INebulaSuggestion,
    IProjectInitialState,
    ISetPlayerQuery,
    MediaForgeReport,
    TUserIntraction,
} from '../redux/project/types';
import { E_USER_PERMISSIONS } from '../types/global/user.models';
import { Audience } from '../redux/user/model';
import { IPlayerInitialState } from '../redux/player/model';
import { IAudienceInitialState } from '../redux/audience/model';
import { projectSelector, userProjectsSelector } from '../pages/UserProjects/selector';
import { ILink, ILinkInitialState } from '../redux/links/types';
import { ITalentData, ITalentInitialState } from '../redux/talent/types';
import { getIdForIbjects } from '../utils/AppUtils';
import { IUploadInitialState, IUploadPayload } from '../redux/upload/types';
import { IView, IViewInitialState } from '../redux/view/types';

export const userProfileSelector: Selector<RootState, IUserProfile> = createSelector(
    (state: RootState) => state.user,
    user => user.userProfile,
);

export const projectsSelector: Selector<RootState, IProjectInitialState> = createSelector(
    (state: RootState) => state.project,
    project => project,
);

export const downloadingProjectSelector: Selector<RootState, boolean | undefined> = createSelector(
    [projectsSelector],
    project => project.downloading,
);
//TODO: update this
export const userActionSelector: Selector<RootState, IUserInitialState> = createSelector(
    (state: RootState) => state.user,
    user => user,
);

export const discoverySelector: Selector<RootState, IDiscoveryItem[] | undefined> = createSelector(
    (state: RootState) => state.discovery,
    discovery => discovery.discovery,
);
export const loadingDiscoverySelector: Selector<RootState, boolean | undefined> = createSelector(
    (state: RootState) => state.discovery,
    discovery => discovery.loadingDiscovery,
);

export const comingSoonAndTrendingSelector: Selector<RootState, IContest[]> = createSelector(
    (state: RootState) => state.comingSoon,
    comingSoon => comingSoon.comingSoon,
);

export const trendingSelector: Selector<RootState, IContest[]> = createSelector(
    (state: RootState) => state.comingSoon,
    comingSoon => comingSoon.trending,
);
export const loadingComingSoonSelector: Selector<RootState, boolean | undefined> = createSelector(
    (state: RootState) => state.comingSoon,
    comingSoon => comingSoon.loadingComingSoon,
);
export const bookmarkSelector: Selector<RootState, IBookmark[]> = createSelector(
    (state: RootState) => state.contest,
    contest => contest.userBookmarks,
);

export const deletingBookMarkSelector: Selector<RootState, boolean | undefined> = createSelector(
    (state: RootState) => state.contest,
    contest => contest.deletingBookmark,
);

export const creatingBookMarkSelector: Selector<RootState, boolean | undefined> = createSelector(
    (state: RootState) => state.contest,
    contest => contest.creatingBookmark,
);

export const contestWatchListSelector: Selector<RootState, IContestWatchList[]> = createSelector(
    (state: RootState) => state.audition,
    auditions => auditions.savedContests,
);

export const toggleStateSelector: Selector<RootState, DrawerStatus> = createSelector(
    (state: RootState) => state.appConfigurator,
    appConfig => appConfig.drawerStatus,
);

export const searchBoxStateSelector: Selector<RootState, TSwitch> = createSelector(
    (state: RootState) => state.appConfigurator,
    appConfig => appConfig.showSearchBox,
);

export const toggleNotificationSelector: Selector<RootState, boolean | undefined> = createSelector(
    (state: RootState) => state.appConfigurator,
    appConfig => appConfig.notificationOpen,
);
export const toggleAccountModalSelector: Selector<RootState, boolean | undefined> = createSelector(
    (state: RootState) => state.appConfigurator,
    appConfig => appConfig.accountModalOpen,
);

export const toggleCookiesDrawerSelector: Selector<RootState, boolean> = createSelector(
    (state: RootState) => state.appConfigurator,
    appConfig => appConfig.cookiesDrawerOpen,
);

export const isDrawerOpen: Selector<RootState, boolean> = createSelector([toggleStateSelector], toogleState => {
    return toogleState === DRAWER_STATE.OPEN;
});

export const isSearchBoxOpenSelector: Selector<RootState, boolean> = createSelector(
    [searchBoxStateSelector],
    toogleState => {
        return toogleState === FILTER_SWITCH.ON;
    },
);

export const isDrawerClosed: Selector<RootState, boolean> = createSelector([toggleStateSelector], toogleState => {
    return toogleState === DRAWER_STATE.CLOSED;
});

export const currentFiltersSelector: Selector<RootState, DiscoveryFilter[]> = createSelector(
    (state: RootState) => state.appConfigurator,
    appConfig => appConfig.setFilters,
);

export const currentFilterCategorySelector: Selector<RootState, FilterTypes> = createSelector(
    (state: RootState) => state.appConfigurator,
    appConfig => appConfig.selectedFilterCategory,
);

export const filterSubCategorySelector: Selector<RootState, FilterValueSet> = createSelector(
    (state: RootState) => state.appConfigurator,
    appConfig => appConfig.selectedFiltersValues,
);

export const filterStatusSelector: Selector<RootState, SwitchStatus> = createSelector(
    (state: RootState) => state.appConfigurator,
    appConfig => appConfig.filterStatus,
);

export const filterSubCategoryIdSelector: Selector<RootState, number | null | undefined> = createSelector(
    (state: RootState) => state.appConfigurator,
    appConfig => appConfig.filterSubCategoryId,
);

export const searchResultSelector: Selector<RootState, DiscoverySearchModel | undefined> = createSelector(
    (state: RootState) => state.discovery,
    discovery => discovery.searchResults,
);

export const searchingStatusSelector: Selector<RootState, boolean | undefined> = createSelector(
    (state: RootState) => state.discovery,
    discovery => discovery.searching,
);

export const creatorModalSelector: Selector<RootState, CreatorType> = createSelector(
    (state: RootState) => state.appConfigurator,
    activeModal => activeModal.creatorModal,
);

export const contestModalSelector: Selector<RootState, boolean> = createSelector(
    [creatorModalSelector],
    activeModal => activeModal === CREATOR_MODAL.CONTEST,
);
export const projectModalSelector: Selector<RootState, boolean> = createSelector(
    [creatorModalSelector],
    activeModal => activeModal === CREATOR_MODAL.PROJECT,
);

export const creatorModalVisibleSelector: Selector<RootState, boolean> = createSelector(
    [creatorModalSelector],
    activeModal => activeModal !== undefined,
);

export const campaignSubmissionModalOpenerSelector: Selector<RootState, boolean> = createSelector(
    (state: RootState) => state.contest,
    contest => contest.isSubmisisonModalOpen,
);

export const selectedCampaignForSubmissionSelector: Selector<RootState, IContest | undefined> = createSelector(
    (state: RootState) => state.contest,
    contest => contest.selectedContestForSubmisison,
);

export const globalConfigurationSelector: Selector<RootState, IGlobalConfiguration | undefined> = createSelector(
    (state: RootState) => state.configuration,
    config => config.globalConfiguration,
);

export const userLikesSelector: Selector<RootState, ILike[]> = createSelector(
    (state: RootState) => state.account,
    account => account.userLikes,
);

export const hasUserLikedProjectSelector = (currentProject: IProject): Selector<RootState, boolean> =>
    createSelector(
        [userLikesSelector],
        likes => !isEmpty(likes) && currentProject && likes.some(like => like.project._id === currentProject._id),
    );

export const reportModalSelector: Selector<RootState, boolean> = createSelector(
    (state: RootState) => state.report,
    report => report.reportModalOpen,
);

export const currentSubmissionSelector: Selector<RootState, ISubmission | undefined> = createSelector(
    (state: RootState) => state.project,
    project => project.selectedSubmission,
);

export const isVotingEnabledSelector: Selector<RootState, boolean> = createSelector(
    [currentSubmissionSelector],
    submission =>
        !isEmpty(submission) &&
        !!submission?.contest &&
        submission.contest.contest_phase === CONTEST_PHASE.AUDIENCE_VOTE &&
        submission.contest.voting_required,
);

export const contestPhaseDateSelector: Selector<RootState, string> = createSelector(
    [currentSubmissionSelector],
    submission =>
        (submission &&
            submission.contest &&
            submission.contest.contest_phase_dates &&
            submission?.contest.contest_phase_dates.find(phase => phase.phase === CONTEST_PHASE.AUDIENCE_VOTE)
                ?.date_started) ||
        'none',
);

export const inAppNotificationSelector: Selector<RootState, InAppNotification | undefined | null> = createSelector(
    (state: RootState) => state.inAppNotification,
    notification => notification.inAppNotification,
);

export const userVotesSelector: Selector<RootState, IVote[]> = createSelector(
    (state: RootState) => state.vote,
    vote => vote.userVotes,
);
/**
 *
 */

interface IProjectQuerySelector {
    currentPage: number;
    param?: string;
    limit?: number;
    loading?: boolean;
    canLoadMore?: boolean;
}
export const projectQuerySeletcor: Selector<RootState, IProjectQuerySelector> = createSelector(
    (state: RootState) => state.project,
    project => ({
        currentPage: project.currentPage || 1,
        param: project.currentParam,
        limit: en_config.RESULT_LIMIT || 0,
        loading: project.gettingMoreProjects,
        canLoadMore: project.loadMore,
    }),
);

export const userConfigurationSelector: Selector<RootState, IUserConfiguration | undefined> = createSelector(
    (state: RootState) => state.configuration,
    config => config.userSpecificConfiguration,
);

export const contestSelector: Selector<RootState, IContestInitialState> = createSelector(
    (state: RootState) => state.contest,
    contest => contest,
);

export const showIntroductionFlowSelector: Selector<RootState, boolean> = createSelector(
    [userConfigurationSelector],
    config => config?.show_introduction || false,
);
export const showAppGuideSelector: Selector<RootState, boolean> = createSelector(
    [userConfigurationSelector],
    config => config?.show_app_guide || false,
);
export const showProjectCreatorSuggstionBoxSelector: Selector<RootState, boolean | undefined> = createSelector(
    [userConfigurationSelector],
    config => config?.already_created_project,
);

/** Fetches the number of unread notifications a user has */
export const userNotificationCountSelector: Selector<RootState, number | undefined> = createSelector(
    (state: RootState) => state.notification,
    notification => notification.notificationCount,
);

export const userNotificationSelector: Selector<RootState, WebNotification[]> = createSelector(
    (state: RootState) => state.notification,
    notification => notification.notifications,
);

/**
 * Get status initiated to show.
 * Trigger closure when completed or failed
 * Also get Message body
 */

export const mediaForgeReportSelector: Selector<RootState, MediaForgeReport> = createSelector(
    (state: RootState) => state.project,
    project => project.mediaForge,
);

export const isProjectProcessingDoneSelector: Selector<RootState, boolean> = createSelector(
    [mediaForgeReportSelector],
    mediaForge => (mediaForge && mediaForge.status === 'completed') || (mediaForge && mediaForge.status === 'failed'),
);

export const uploadSelector: Selector<RootState, IUploadInitialState> = createSelector(
    (state: RootState) => state.upload,
    upload => upload,
);

export const uploadPayloadSelector: Selector<RootState, IUploadPayload | undefined> = createSelector(
    [uploadSelector],
    upload => upload.currentUploadPayload,
);

export const isProjectProcessingSelector: Selector<RootState, boolean> = createSelector(
    [uploadSelector],
    upload => upload.isUploadInProgress,
);

export const mediaForgeMessageSelector: Selector<
    RootState,
    { progress: number | undefined; uploadMessage: string | undefined; isPaused: boolean | undefined }
> = createSelector([mediaForgeReportSelector, uploadSelector], (mediaForge, upload) => ({
    progress: !!upload && upload.progress ? upload.progress : 0,
    uploadMessage: !!mediaForge && mediaForge.mediaForgeMessage ? mediaForge.mediaForgeMessage : '',
    isPaused: upload.paused,
}));

export const isUserJurySelector: Selector<RootState, boolean> = createSelector(
    [userProfileSelector],
    user => user && user.permissions && user.permissions.includes(E_USER_PERMISSIONS.JURY),
);
export const isAdminSelector: Selector<RootState, boolean> = createSelector(
    [userProfileSelector],
    user => (user && user.role === 'admin') || (user && user.role === 'host'),
);

export const isUserCastingDirectorSelector: Selector<RootState, boolean> = createSelector(
    [userProfileSelector],
    user => user && user.permissions && user.permissions.includes(E_USER_PERMISSIONS.CASTING_DIRECTOR),
);
export const isUserEditorSelector: Selector<RootState, boolean> = createSelector(
    [userProfileSelector],
    user => user && user.permissions && user.permissions.includes(E_USER_PERMISSIONS.EDITOR),
);

export const suggestedThspiansSelector: Selector<
    RootState,
    { suggestedThspians: ITopUser[]; fetchingSuggestedThspians: boolean }
> = createSelector(
    (state: RootState) => state.audience,
    audience => ({
        suggestedThspians: audience.suggestedThspians,
        fetchingSuggestedThspians: audience.gettingSuggestedThspians,
    }),
);

export const userFollowingSelector: Selector<RootState, Audience[]> = createSelector(
    (state: RootState) => state.audience,
    audience => audience.userFollowing,
);

export const userProjectInteractionSelector: Selector<RootState, TUserIntraction> = createSelector(
    (state: RootState) => state.project,
    project => ({
        is_user_liked: (project && project.userInteraction && project.userInteraction.is_user_liked) || false,
        optimistic: (project && project.userInteraction && project.userInteraction.optimistic) || false,
        userLike: (project && project.userInteraction && project.userInteraction.userLike) || [],
    }),
);

export const playerQuerySelector: Selector<RootState, ISetPlayerQuery> = createSelector(
    (state: RootState) => state.project,
    project => ({
        isSequential: project.isSequential,
        isSinglePreview: project.isSinglePreview,
    }),
);

export const playerPlaylistTypeSelector: Selector<RootState, boolean> = createSelector(
    (state: RootState) => state.project,
    project => project.playlistType === 'playlist',
);

export const nebulaSuggestionSelector: Selector<RootState, INebulaSuggestion[] | undefined> = createSelector(
    [projectsSelector],
    project => project.nebulaSuggestion,
);

export const selectedSuggestionSelector: Selector<RootState, INebulaSuggestion | undefined> = createSelector(
    [projectsSelector],
    project => project.selectedSuggestion,
);

export const gettingNotificationsSelector: Selector<RootState, boolean | undefined> = createSelector(
    (state: RootState) => state.notification,
    notif => notif.gettingNotifications,
);

export const mediaControlConfigurationSelector: Selector<RootState, IGlobalConfigurationMediaControl> = createSelector(
    [globalConfigurationSelector],
    config => config?.media_control || (en_config.MEDIA_CONFIG as IGlobalConfigurationMediaControl),
);

export const appGuideSelector: Selector<RootState, TGuide | undefined> = createSelector(
    (state: RootState) => state.appConfigurator,
    config => config.selectedPageGuide,
);

export const likeActionInProgressSelector: Selector<RootState, boolean | undefined> = createSelector(
    (state: RootState) => state.project,
    project => project.liking,
);

export const nebulaPlayerSelector: Selector<RootState, IPlayerInitialState> = createSelector(
    (state: RootState) => state.nebulaPlayer,
    player => player,
);

export const nebulaVolumeSelector: Selector<RootState, number> = createSelector(
    [nebulaPlayerSelector],
    player => player.volume,
);
export const nebulaQualitySelector: Selector<RootState, string> = createSelector(
    [nebulaPlayerSelector],
    player => player.quality ?? 'auto',
);
export const nebulaSpeedSelector: Selector<RootState, number> = createSelector(
    [nebulaPlayerSelector],
    player => player.speed ?? 1,
);

export const nebulaMuteSelector: Selector<RootState, boolean> = createSelector(
    [nebulaPlayerSelector],
    player => player.mute ?? true,
);

export const audienceSelector: Selector<RootState, IAudienceInitialState> = createSelector(
    (state: RootState) => state.audience,
    audience => audience,
);
export const userFollowersSelector: Selector<RootState, Audience[]> = createSelector(
    [audienceSelector],
    audience => audience.userAudience,
);
export const userFollowingsSelector: Selector<RootState, Audience[]> = createSelector(
    [audienceSelector],
    audience => audience.userFollowing,
);
export const audienceQuerySelector: Selector<
    RootState,
    { page: number; loadMore: boolean; gettingUserFollowing: boolean; gettingAudience: boolean; loadingMore: boolean }
> = createSelector([audienceSelector], audience => {
    return {
        page: audience.currentPage || 0,
        loadMore: audience.loadMore || false,
        gettingUserFollowing: audience.gettingUserFollowing,
        gettingAudience: audience.gettingAudience,
        loadingMore: audience.loadingMore || false,
    };
});

export const initialContestEditingDataSelector: Selector<RootState, IContest | undefined> = createSelector(
    [contestSelector],
    contestState => contestState.contestInCreation,
);

export const selectedContestMetricsSelector: Selector<RootState, IAdvancedContest | undefined> = createSelector(
    [contestSelector],
    contestState => contestState.selectedContestForMetrics,
);

export const loadingSelectedContestMetricsSelector: Selector<RootState, boolean | undefined> = createSelector(
    [contestSelector],
    contestState => contestState.loading,
);

export const appConfiguratorStateSelector: Selector<RootState, IAppConfiguratorInitialState> = createSelector(
    (state: RootState) => state.appConfigurator,
    appConfig => appConfig,
);

export const showProjectCreatorGuideSelector: Selector<RootState, boolean | undefined> = createSelector(
    [appConfiguratorStateSelector],
    appConfig => appConfig.showProjectCreatorGuide,
);

export const isUsersFirstProjectSelector: Selector<RootState, boolean> = createSelector(
    [userProjectsSelector],
    userProjects => userProjects.length === 1,
);

export const showFirstProjectCongratulatorySelector: Selector<RootState, boolean | undefined> = createSelector(
    [appConfiguratorStateSelector],
    appConfig => appConfig.showUserFirstProjectSuggestionBox,
);

export const currentProjectInCreationSelector: Selector<RootState, boolean | IProject> = createSelector(
    (state: RootState) => state.project,
    projects => projects.currentProjectInCreation,
);

export const votingBoothItemsSelector: Selector<
    RootState,
    {
        showVotingBooth: boolean;
        submissions: IGlobalSubmission[];
        showVoting2Fa: boolean;
        voteOtpSendingInProgress: boolean;
        startingIndex: number;
    }
> = createSelector([contestSelector], contest => ({
    showVotingBooth: contest.showVotingBooth || false,
    submissions: contest.submissionsToVoteFor || [],
    showVoting2Fa: contest.showVoting2Fa || false,
    voteOtpSendingInProgress: contest.initializingVote || false,
    startingIndex: contest.startingIndex || 0,
}));
export const shareModalStatusSelector: Selector<RootState, boolean | undefined> = createSelector(
    [appConfiguratorStateSelector],
    app => app.shareModalStatus || false,
);

export const sharePayloadSelector: Selector<RootState, { title: string; url: string } | undefined> = createSelector(
    [appConfiguratorStateSelector],
    app => app.sharePayload,
);

export const systemLoadingSelector: Selector<RootState, boolean> = createSelector(
    [appConfiguratorStateSelector],
    appConfig => appConfig.loading,
);

export const projectGuideStatusSelector: Selector<RootState, boolean> = createSelector(
    [userConfigurationSelector],
    config => config?.show_project_guide || false,
);

export const projectIndexSelector: Selector<RootState, boolean> = createSelector([userProjectsSelector], projects =>
    projects?.some((value, index) => {
        return value.processing_complete && !isEmpty(value.resolutions) && !!value.download_link && index === 0;
    }),
);
export const showProjectGuideSelector: Selector<RootState, boolean> = createSelector(
    [projectGuideStatusSelector, projectIndexSelector],
    (projectGuideStatus, projectIndex) => projectGuideStatus && projectIndex,
);

export const showProjectSubmissionPromptSelector: Selector<RootState, boolean | undefined> = createSelector(
    [contestSelector],
    contest => contest.promptSubmission,
);

export const linksStateSelector: Selector<RootState, ILinkInitialState> = createSelector(
    (state: RootState) => state.links,
    links => links,
);
export const userLinksSelector: Selector<RootState, ILink[]> = createSelector(
    [linksStateSelector],
    links => links.links,
);

export const loadingLinksSelector: Selector<RootState, boolean> = createSelector(
    [linksStateSelector],
    links => links.loading ?? false,
);

export const selectedLinkSelector: Selector<RootState, ILink | undefined> = createSelector(
    [linksStateSelector],
    links => links.selectedLink,
);
export const talentStateSelector: Selector<RootState, ITalentInitialState> = createSelector(
    (state: RootState) => state.userTalent,
    talent => talent,
);

export const talentSelector: Selector<RootState, ITalentData[]> = createSelector(
    [talentStateSelector],
    talent => talent.talents,
);

export const talentSearchSelector: Selector<RootState, ITalentData[] | undefined> = createSelector(
    [talentStateSelector],
    talent => talent.talentSearchData,
);
export const savingTalentSelector: Selector<RootState, boolean> = createSelector(
    [talentStateSelector],
    talent => talent.savingTalent,
);
export const searchingTalentSelector: Selector<RootState, boolean> = createSelector(
    [talentStateSelector],
    talent => talent.searchingTalent,
);
export const deletingTalentSelector: Selector<RootState, boolean> = createSelector(
    [talentStateSelector],
    talent => talent.deletingTalent,
);

export const liveCampaignsSelector: Selector<RootState, IContest[] | undefined> = createSelector(
    [discoverySelector, contestSelector],
    (discovery, contestSate) => {
        const live = discovery?.find(category => category.categoryTitle.toLowerCase() === 'live');
        const contest: IContest[] | undefined = live?.categoryDetails as unknown as IContest[];
        const userSubmissions = contestSate.userSubmissions;
        return contest?.filter(
            cts =>
                cts.contest_phase === CONTEST_PHASE.SUBMISSION &&
                userSubmissions.every(sub => getIdForIbjects(sub.contest) !== cts._id),
        );
    },
);

export const focusCampiagnSelector: Selector<RootState, IContest | undefined> = createSelector(
    [contestSelector],
    state => state.focusCampaign,
);

export const autoPlaySelector: Selector<RootState, boolean | undefined> = createSelector(
    [projectsSelector],
    state => state.autoplay,
);

export const redirectSelector: Selector<RootState, string | undefined> = createSelector(
    [appConfiguratorStateSelector],
    appConfig => appConfig.currentRedirect,
);

export const showProjectSwipeGuideSelector: Selector<RootState, boolean> = createSelector(
    [appConfiguratorStateSelector],
    appConfig => appConfig.showProjectGuide ?? true,
);

export const usersUnsubmittedProjectSelector: Selector<
    RootState,
    { projects: IProject[]; currentPage: number; loading?: boolean }
> = createSelector([contestSelector], contest => ({
    projects: contest.unsubmittedProjects,
    currentPage: contest.currentUnsubmittedProjectsPage ?? 1,
    loading: contest.gettingUnSubmittedProjects,
}));

export const gettingProjectSelector: Selector<RootState, boolean> = createSelector(
    [projectsSelector],
    project => project.gettingSelectedProjectDetails ?? false,
);

export const viewsSelector: Selector<RootState, IViewInitialState> = createSelector(
    (state: RootState) => state.views,
    views => views,
);

export const currentVideoViewSelector: Selector<RootState, IView | undefined> = createSelector(
    [viewsSelector],
    view => view.viewsForSelectedProject,
);

export const creatingViewSelector: Selector<RootState, boolean> = createSelector(
    [viewsSelector],
    view => view.creatingView ?? false,
);

export const s3ImageSelector: Selector<RootState, string[] | undefined> = createSelector(
    [contestSelector],
    state => state.s3Images,
);

export const focusEmailImageSelector: Selector<RootState, string | undefined> = createSelector(
    [contestSelector],
    state => state.emailImage,
);

export const isPlaylisModeSelector: Selector<RootState, boolean> = createSelector(
    [projectSelector],
    proj => proj.isPlaylistCollection ?? false,
);

export const isPlaylistEditModeSelector: Selector<RootState, boolean> = createSelector(
    [projectSelector],
    proj => proj.playlistEditMode ?? false,
);

export const currentPlaylistSelector: Selector<RootState, IThspianRenderedPlaylist | undefined> = createSelector(
    [projectSelector],
    proj => proj.currentPlaylist,
);

export const themeSelector: Selector<RootState, 'dark' | 'light'> = createSelector(
    [appConfiguratorStateSelector],
    config => config.theme ?? 'dark',
);
