import { createAction } from 'redux-act';

import {
    IAppLaunchFlowComplete,
    IGenericAuthPayload,
    ILoginPayload,
    ILoginResponse,
    ISendPasswordReserEmail,
    ISendPasswordResetEmailMessage,
    ISetJwt,
    types,
} from './types';
import { IErrorType } from '../../constants/types';

export const appLaunchFlow = createAction(types.APP_LAUNCH_FLOW);
export const appLaunchFlowComplete = createAction<IAppLaunchFlowComplete>(types.APP_LAUNCH_FLOW_COMPLETE);
export const deleteJwt = createAction(types.DELETE_JWT);
export const deleteJwtSuccess = createAction(types.DELETE_JWT_SUCCESS);
export const deleteJwtFailed = createAction<IErrorType>(types.DELETE_JWT_FAILED);
export const getJwt = createAction(types.GET_JWT);
export const setJwt = createAction<ISetJwt>(types.SET_JWT);
export const getJwtSuccess = createAction(types.GET_JWT_SUCCESS);
export const getJwtFailed = createAction<IErrorType>(types.GET_JWT_FAILED);
export const get2FATokenForLogin = createAction<ILoginPayload>(types.GET_2FA_CODE_FOR_LOGIN);
export const get2FACodeSuccess = createAction(types.GET_2FA_CODE_SUCCESS);
export const get2FACodeFailed = createAction<IErrorType>(types.GET_2FA_CODE_FAILED);
export const login = createAction<ILoginPayload>(types.LOGIN);
export const loginSuccess = createAction<ILoginResponse>(types.LOGIN_SUCCESS);
export const loginFailed = createAction<IErrorType>(types.LOGIN_FAILED);
export const loginCancelled = createAction(types.LOGIN_CANCELLED);
export const logout = createAction(types.LOGOUT);
export const logoutSuccess = createAction(types.LOGOUT_SUCCESS);
export const logoutFailed = createAction<IGenericAuthPayload>(types.LOGOUT_FAILED);
export const sendPasswordResetEmail = createAction<ISendPasswordReserEmail>(types.SEND_PASSWORD_RESET_EMAIL);
export const sendPasswordResetEmailSuccess = createAction(types.SEND_PASSWORD_RESET_EMAIL_SUCCESS);
export const sendPasswordResetEmailFailed = createAction<IErrorType>(types.SEND_PASSWORD_RESET_EMAIL_FAILED);
export const sendPasswordResetEmailMessage = createAction<ISendPasswordResetEmailMessage>(
    types.SEND_PASSWORD_RESET_EMAIL_MESSAGE,
);
export const getGuestToken = createAction(types.GET_GUEST_TOKEN);
export const getGuestTokenSuccess = createAction(types.GET_GUEST_TOKEN_SUCCESS);
export const getGuestTokenFailed = createAction(types.GET_GUEST_TOKEN_FAILED);
export const resetToGuest = createAction(types.RESET_TO_GUEST);
