export const page_not_found = require('./85004-error-404.json');
export const loading_animation = require('./loading.json');
/* eslint-disable global-require */
export const approved = require('./Check_Without_Numbers__II.json');
export const denied = require('./no_number_deny.json');
export const progress = require('./Percentage__.json');
export const alternateCheck = require('./alternateCheck.json');
export const playAnimation = require('./playme.json');
export const ccAnimation = require('./cc.json');
export const emptyState = require('./not-found.json');
export const masterCard = require('./masterCard.json');
export const visaCard = require('./visacard.json');
export const success = require('./success_animation.json');
export const done = require('./done.json');
export const loading = require('./loading.json');
export const loading_light = require('./loading_light.json');
export const typing = require('./typing_blue.json');
export const arrowDown = require('./downArrow.json');

export const aud_file = require('./people_anim.json');
export const girl_on_phone = require('./girl_phone_dark_two.json');
export const girl_on_phone_dark = require('./girl_phone_dark.json');
export const man_on_screen = require('./63228-man-watching-a-movie.json');
export const film_camera = require('./cameraII.json');
export const film_camera_search = require('./camera_search.json');
export const rocket = require('./rocketflying.json');
export const rocket_color = require('./rocket_color.json');
export const confetti_I = require('./confetti_from_top.json');
export const confetti_II = require('./confetti_two.json');
export const quick_camera = require('./quick_camera.json');
export const profile = require('./profile.json');
export const clap = require('./clapp.json');
export const like = require('./like.json');
export const love = require('./love.json');
export const angry = require('./angry.json');
export const smiley = require('./smiley-emoji.json');

export const wave_form = require('./playing-audio.json');
export const optional_loading = require('./optional_loading.json');

export const checkmark = require('./checkmark.json');
export const work_in_progress = require('./work-in-progress.json');

export const lockAnimation = require('./locktext.json');
export const space = require('./spaceanimation.json');

export const swipeUpAnimation = require('./upSwipe.json');
export const leftTapAnimation = require('./leftTap.json');
export const rightTapAnimation = require('./rightTap.json');
export const centerTapAnimation = require('./center.json');

export const arrowAnimation = require('./arrow.json');
export const arrowAnimationBlack = require('./arrow_white.json');
