import React from 'react';
import { ThemeContext } from '../../module/ThemeManager';
import { Box, Skeleton, Grid } from '@mui/material';
import { theme } from '../../constants/theme';

const SquareContestsShimmer = () => {
    return (
        <ThemeContext.Consumer>
            {({ themeStyle, device }) => {
                const value = device.isDesktop ? 5 : 2;

                return (
                    <Box>
                        <Grid container direction={{ xs: 'column', sm: 'row', md: 'row', lg: 'row' }} spacing={2}>
                            {Array.from(new Array(value)).map((_, index) => (
                                <Grid item xs={'auto'} sm={'auto'} md={'auto'} lg={'auto'} key={index}>
                                    <Skeleton
                                        variant="rounded"
                                        sx={{
                                            borderRadius: 5,
                                            width: {
                                                xs: '240px',
                                                sm: '240px',
                                                md: '240px',
                                                lg: '240px',
                                            },

                                            height: { xs: '240px', sm: '240px', md: '240px', lg: '240px' },
                                            backgroundColor: theme[themeStyle].gray_text,
                                        }}
                                    />
                                    <Box position="relative">
                                        <Skeleton
                                            variant="circular"
                                            width={50}
                                            height={50}
                                            sx={{
                                                backgroundColor: theme[themeStyle].darkGray60,
                                                position: 'absolute',
                                                bottom: 15,
                                                left: 10,
                                            }}
                                        />
                                        <Skeleton
                                            variant="text"
                                            sx={{
                                                fontSize: '1rem',
                                                width: '130px',
                                                backgroundColor: theme[themeStyle].darkGray60,
                                                position: 'absolute',
                                                bottom: 40,
                                                left: 65,
                                            }}
                                        />
                                        <Skeleton
                                            variant="text"
                                            sx={{
                                                fontSize: '1rem',
                                                width: '100px',
                                                backgroundColor: theme[themeStyle].darkGray60,
                                                position: 'absolute',
                                                bottom: 20,
                                                left: 65,
                                            }}
                                        />
                                    </Box>
                                </Grid>
                            ))}
                        </Grid>
                    </Box>
                );
            }}
        </ThemeContext.Consumer>
    );
};

export { SquareContestsShimmer };
