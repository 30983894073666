import { call, put, all, takeLatest } from 'redux-saga/effects';
import { ReportPayload, types } from './types';
import { sendReportSuccess, sendReportFail, ISendReport, ReportResponse } from './actions';
import { globalErrorHandler } from '../error/saga';
import { Action } from 'redux';
import { api } from '../../configurations/api';
import { actsUrlbase, getAuditionUrl, talentProfileBaseUrl, userBaseUrl } from '../../configurations/api/url';
import { httpRequest } from '../types';
import { SentryCapture } from '../../analytics/Sentry';

function* sendReport({ payload }: { payload: ReportPayload }): any {
    try {
        const { content } = payload;
        let url = `${actsUrlbase}/${content.model}/report`;
        switch (content.type) {
            case 'Acts':
                url = `${actsUrlbase}/${content.model}/report`;
                break;
            case 'Audition':
                url = `${getAuditionUrl}/${content.model}/report`;
                break;
            case 'Profile':
                url = `${talentProfileBaseUrl}/${content.model}/report`;
                break;
            case 'User':
                url = `${userBaseUrl}/${content.model}/report`;
                break;
            default:
                break;
        }

        const response = yield call(api, url, httpRequest.POST, payload, 0, 0);
        const { data }: { data: ReportResponse } = response.data;
        yield put(sendReportSuccess(data));
    } catch (error: any) {
        SentryCapture(error, 'error');
        yield put(sendReportFail(error));
        yield call(globalErrorHandler, error);
    }
}

interface TaskAction extends Action, ISendReport {
    type: string;
    payload: any;
    reject: any;
    resolve: any;
}

function* sendReportWatcher() {
    yield takeLatest<TaskAction>(types.SEND_REPORT, sendReport);
}

export default function* soundSaga() {
    yield all([sendReportWatcher()]);
}
