import { useState } from "react";

export function useEsHover () {
    const [isHover, setIsHover] = useState(false);

   const handleMouseEnter = () => {
      setIsHover(true);
   };

   const handleMouseLeave = () => {
      setIsHover(false);
   };

   return {
    handleMouseEnter,
    handleMouseLeave,
    isHover
}
}