import { createAction } from 'redux-act';
import { ConfirmationType, types } from './types';

export interface ISetInAppNotification {
    title: string;
    message: string;
}

export interface ISetConfirmationNotification {
    confirmationType: ConfirmationType;
    confirmationMessage: string;
}

export const setInAppNotification = createAction<ISetInAppNotification>(types.SET_IN_APP_NOTIFICATION);
export const clearInAppNotification = createAction(types.CLEAR_IN_APP_NOTIFICATION);
export const setConfirmationNotification = createAction<ISetConfirmationNotification>(
    types.SET_CONFIRMATION_NOTIFICATION,
);
export const clearConfirmationNotification = createAction(types.CLEAR_CONFIRMATION_NOTIFICATION);
