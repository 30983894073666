import produce from 'immer';

import { IAttendanceInitialState, types } from './types';

const initialState: IAttendanceInitialState = {
    gettingAttendance: false,
    submittingAttendanceForm: false,
    userAttendance: [],
    errorMsg: null,
};

const reducer = (state = initialState, action: any) => {
    const { type, payload } = action;
    switch (type) {
        case types.GET_USER_ATTENDANCE:
            return produce(state, draft => {
                draft.gettingAttendance = true;
            });
        case types.GET_USER_ATTENDANCE_SUCCESS:
            return produce(state, draft => {
                draft.userAttendance = payload;
                draft.gettingAttendance = false;
            });
        case types.GET_USER_ATTENDANCE_FAIL:
            return produce(state, draft => {
                draft.errorMsg = payload;
                draft.gettingAttendance = false;
            });
        case types.SUBMIT_ATTENDANCE_FORM:
            return produce(state, draft => {
                draft.submittingAttendanceForm = true;
            });
        case types.SUBMIT_ATTENDANCE_FORM_SUCCESS:
            return produce(state, draft => {
                draft.submittingAttendanceForm = false;
                draft.userAttendance.push(payload);
            });
        case types.SUBMIT_ATTENDANCE_FORM_FAIL:
            return produce(state, draft => {
                draft.submittingAttendanceForm = false;
                draft.errorMsg = payload;
            });
        case 'LOGOUT_SUCCESS':
            return produce(state, draft => {
                draft.userAttendance = [];
                draft.gettingAttendance = false;
            });
        default:
            return state;
    }
};

export { reducer };
