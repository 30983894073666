import React from 'react';
import { ThemeContext } from '../../module/ThemeManager';
import { Stack, Skeleton, Grid, Box } from '@mui/material';
import { theme } from '../../constants/theme';

const WatchSingleCardShimmer = () => {
    return (
        <ThemeContext.Consumer>
            {({ themeStyle }) => {
                const value = 6;

                return (
                    <Box marginBottom={'40px'}>
                        <Stack marginBottom={5}>
                            <Skeleton
                                variant="text"
                                sx={{
                                    fontSize: '2rem',
                                    width: '100px',
                                    backgroundColor: theme[themeStyle].gray_text,
                                    marginLeft: { xs: '10px' },
                                }}
                            />
                        </Stack>
                        <Grid container spacing={2}>
                            {Array.from(new Array(value)).map((_, index) => (
                                <Grid item xs={12} sm={'auto'} md={'auto'} lg={'auto'} key={index}>
                                    <Stack>
                                        <Skeleton
                                            variant="rounded"
                                            height={200}
                                            sx={{
                                                width: {
                                                    xs: '100%',
                                                    sm: '400px',
                                                    md: '400px',
                                                    lg: '400px',
                                                },
                                                backgroundColor: theme[themeStyle].gray_text,
                                                borderRadius: '15px',
                                            }}
                                        />
                                    </Stack>
                                    <Stack direction={'row'} marginTop={'10px'}>
                                        <Skeleton
                                            variant="circular"
                                            width={50}
                                            height={50}
                                            sx={{
                                                backgroundColor: theme[themeStyle].darkGray60,
                                            }}
                                        />
                                        <Stack direction={'column'} marginLeft={'5px'}>
                                            <Skeleton
                                                variant="text"
                                                sx={{
                                                    fontSize: '1rem',
                                                    width: '120px',
                                                    backgroundColor: theme[themeStyle].darkGray60,
                                                }}
                                            />
                                            <Skeleton
                                                variant="text"
                                                sx={{
                                                    fontSize: '1rem',
                                                    width: '50px',
                                                    backgroundColor: theme[themeStyle].darkGray60,
                                                    marginBottom: 2,
                                                }}
                                            />
                                        </Stack>
                                    </Stack>
                                </Grid>
                            ))}
                        </Grid>
                    </Box>
                );
            }}
        </ThemeContext.Consumer>
    );
};

export { WatchSingleCardShimmer };
