import { call, all, put, takeLatest } from 'redux-saga/effects';
import { Action } from 'redux';
import {
    assignSubmissionToJuryurl,
    juryAssignmentSubmissionsUrl,
    juryAssignmentUrl,
    juryBaseUrl,
    acceptJuryInviteUrl,
    scoreSubmissionsUrl,
    scoreBoardUrl,
} from '../../configurations/api/url';
import {
    acceptJurySuccess,
    acceptJuryFail,
    distributeSubmissionToJuriesFail,
    distributeSubmissionToJuriesSuccess,
    getCampaignSubmissionsFail,
    getCampaignSubmissionsSuccess,
    getJuryCampaignsFail,
    getJuryCampaignsSuccess,
    inviteJuryFail,
    inviteJurySuccess,
    scoreSubmissionFail,
    scoreSubmissionSuccess,
    getJurorForContestSuccess,
    getJurorForContestFail,
    getScoreBoardSuccess,
    getScoreBoardFail,
    getCompletedCampaignSubmissionsSuccess,
    getIncompleteCampaignSubmissionsSuccess,
    searchCampaignSubmissionSuccess,
} from './action';
import {
    IAcceptJuryInvite,
    IAssignJuryDuty,
    IGetJurorForContest,
    IGetJuryCampaignSubmissions,
    IGetScoreBoard,
    IInviteJuryPayload,
    IJuryDuties,
    IScoreSubmission,
    ISubmissionResultsForContest,
    types,
} from './types';
import { api } from '../../configurations/api';
import { httpRequest } from '../types';
import { genericParseSingleDocument, parseGenericCollection } from '../../utils/responseProcessor';
import { destroyOneCache, setCache } from '../cache/action';
import { CACHE_TYPE, CacheValue, NotUniqueCacheValue } from '../cache/types';
import { getCacheByType, getCacheDuration, getNotUniqueCacheByKey, isUseCacheEnabled } from '../cache/saga';
import { showToastMessage } from '../../utils/AppUtils';
import { SentryCapture } from '../../analytics/Sentry';

function* getJuryCampaign(): any {
    const defaultUseCache = yield* isUseCacheEnabled();
    const defaultCacheDuration = yield* getCacheDuration();
    let initialResult: any = null;
    const cache: CacheValue = yield* getCacheByType(CACHE_TYPE.JURY_CAMPAIGNS);
    if (cache && cache.key) {
        initialResult = cache.value;
    }
    if (
        !!initialResult &&
        defaultUseCache &&
        ((Date.now() - Number(cache.key)) as unknown as number) < defaultCacheDuration
    ) {
        yield put(getJuryCampaignsSuccess(initialResult));
    } else {
        try {
            const response = yield call(api, juryAssignmentUrl, httpRequest.GET, null, 2, 1000);
            const { data }: { data: IJuryDuties[] } = response;
            const parsedJuryDuties = parseGenericCollection(
                data,
                genericParseSingleDocument,
                genericParseSingleDocument,
                'contest',
            );

            yield put(getJuryCampaignsSuccess(parsedJuryDuties));
            yield put(
                setCache({
                    key: Date.now(),
                    value: parsedJuryDuties,
                    type: CACHE_TYPE.JURY_CAMPAIGNS,
                    isUnique: true,
                }),
            );
        } catch (error: any) {
            SentryCapture(error, 'error');
            console.log(error);
            yield put(getJuryCampaignsFail(error));
        }
    }
}

function* getJuryCampaignSubmission({ payload }: { payload: IGetJuryCampaignSubmissions }): any {
    const defaultUseCache = yield* isUseCacheEnabled();
    let initialResult: any = null;
    const { contestId, match, searchTerm, category } = payload;
    let param = `${contestId}?page=${payload.page}&limit=${payload.limit}`; // Pagination here
    if (match) {
        param += `&match=${match}`;
    }

    if (category) {
        param += `&category=${category}`;
    }

    if (searchTerm) {
        param += `&searchTerm=${searchTerm}`;
    }
    const request = `${juryAssignmentSubmissionsUrl}${param}`;
    const cache: NotUniqueCacheValue = yield* getNotUniqueCacheByKey(CACHE_TYPE.PROJECT, param);
    if (cache && cache.key) {
        initialResult = cache.value;
    }
    if (initialResult && defaultUseCache) {
        if (match === 'all') {
            yield put(
                getCampaignSubmissionsSuccess({ ...initialResult, page: payload.page, limit: payload.limit, match }),
            );
        }

        if (match === 'judged') {
            yield put(
                getCompletedCampaignSubmissionsSuccess({
                    ...initialResult,
                    page: payload.page,
                    limit: payload.limit,
                    match,
                }),
            );
        }

        if (match === 'not_judged') {
            yield put(
                getIncompleteCampaignSubmissionsSuccess({
                    ...initialResult,
                    page: payload.page,
                    limit: payload.limit,
                    match,
                }),
            );
        }
    } else {
        try {
            const response = yield call(api, request, httpRequest.GET, null, 2, 1000);
            const { data }: { data: ISubmissionResultsForContest } = response;
            const { submissions, total_count } = data;
            if (match === 'all' || !match) {
                yield put(
                    getCampaignSubmissionsSuccess({
                        submissions,
                        total_count,
                        page: payload.page,
                        limit: payload.limit,
                        match,
                    }),
                );
            }

            if (match === 'judged') {
                yield put(
                    getCompletedCampaignSubmissionsSuccess({
                        submissions,
                        total_count,
                        page: payload.page,
                        limit: payload.limit,
                        match,
                    }),
                );
            }

            if (match === 'not_judged') {
                yield put(
                    getIncompleteCampaignSubmissionsSuccess({
                        submissions,
                        total_count,
                        page: payload.page,
                        limit: payload.limit,
                        match,
                    }),
                );
            }
            yield put(
                setCache({
                    key: param,
                    value: { submissions, total_count },
                    type: CACHE_TYPE.CAMPAIGN_SUBMISSIONS,
                    isUnique: false,
                }),
            );
        } catch (error: any) {
            SentryCapture(error, 'error');
            yield put(getCampaignSubmissionsFail(error));
        }
    }
}

function* searchCampaignSubmission({ payload }: { payload: IGetJuryCampaignSubmissions }): any {
    const defaultUseCache = yield* isUseCacheEnabled();
    let initialResult: any = null;
    const { contestId, match, searchTerm, category } = payload;
    let param = `${contestId}?page=${payload.page}&limit=${payload.limit}`; // Pagination here
    if (match) {
        param += `&match=${match}`;
    }

    if (searchTerm) {
        param += `&searchTerm=${searchTerm}`;
    }

    if (category) {
        param += `&category=${category}`;
    }
    const request = `${juryAssignmentSubmissionsUrl}${param}`;
    const cache: NotUniqueCacheValue = yield* getNotUniqueCacheByKey(CACHE_TYPE.PROJECT, param);
    if (cache && cache.key) {
        initialResult = cache.value;
    }
    if (initialResult && defaultUseCache) {
        if (match === 'all') {
            yield put(
                searchCampaignSubmissionSuccess({ ...initialResult, page: payload.page, limit: payload.limit, match }),
            );
        }
    } else {
        try {
            const response = yield call(api, request, httpRequest.GET, null, 2, 1000);
            const { data }: { data: ISubmissionResultsForContest } = response;
            const { submissions, total_count } = data;
            yield put(
                searchCampaignSubmissionSuccess({
                    submissions,
                    total_count,
                    page: payload.page,
                    limit: payload.limit,
                    match,
                }),
            );

            yield put(
                setCache({
                    key: param,
                    value: { submissions, total_count },
                    type: CACHE_TYPE.CAMPAIGN_SUBMISSIONS,
                    isUnique: false,
                }),
            );
        } catch (error: any) {
            SentryCapture(error, 'error');
            yield put(getCampaignSubmissionsFail(error));
        }
    }
}

function* scoreSubmission({ payload }: { payload: IScoreSubmission }): any {
    try {
        const response = yield call(
            api,
            `${scoreSubmissionsUrl}${payload.submissionId}`,
            httpRequest.PATCH,
            payload.payload,
            2,
            1000,
        );
        const { data } = response;
        const parsedSubmission = data;
        yield put(scoreSubmissionSuccess({ submission: parsedSubmission, match: payload.match }));
        yield put(destroyOneCache({ cacheType: CACHE_TYPE.CAMPAIGN_SUBMISSIONS }));
        yield call(showToastMessage, 'Score Successful', 'success');
    } catch (error: any) {
        SentryCapture(error, 'error');
        yield put(scoreSubmissionFail(error));
        yield call(showToastMessage, error, 'error');
    }
}

function* acceptJuryInvitation({ payload }: { payload: IAcceptJuryInvite }): any {
    try {
        yield call(api, `${acceptJuryInviteUrl}${payload.tokem}`, httpRequest.GET, null);
        yield put(
            acceptJurySuccess(
                'Congratulation, you are now officially a juror. Go to your jury duty tab to see assigned submissions',
            ),
        );
    } catch (error: any) {
        SentryCapture(error, 'error');
        yield put(
            acceptJuryFail(
                'There seems to be an issue with this token. The token is either expired or has already been used',
            ),
        );
    }
}

function* distributeSubmissions({ payload }: { payload: IAssignJuryDuty }): any {
    try {
        const response = yield call(
            api,
            `${assignSubmissionToJuryurl}${payload.contest}`,
            httpRequest.POST,
            payload,
            2,
            1000,
        );
        const { data } = response;
        yield put(distributeSubmissionToJuriesSuccess(data));
        yield call(showToastMessage, 'Distributed Successfully', 'success');
    } catch (error: any) {
        SentryCapture(error, 'error');
        yield call(showToastMessage, 'Could not distribute', 'error');
        yield put(distributeSubmissionToJuriesFail(error));
    }
}

function* inviteJury({ payload }: { payload: IInviteJuryPayload }): any {
    try {
        const response = yield call(api, `${juryBaseUrl}`, httpRequest.POST, payload, 2, 1000);
        const { data } = response;
        yield put(inviteJurySuccess(data));

        yield call(showToastMessage, data[0].message, 'success');
        yield put(
            destroyOneCache({
                cacheType: CACHE_TYPE.JURORS,
            }),
        );
        yield* getJurorForContest({
            payload: {
                contestId: payload.contest,
                isRefresh: true,
            },
        });
    } catch (error: any) {
        SentryCapture(error, 'error');
        yield put(inviteJuryFail(error));
        yield call(showToastMessage, 'Invitation could not be sent', 'error');
    }
}

function* getJurorForContest({ payload }: { payload: IGetJurorForContest }): Generator<any, any, any> {
    const defaultUseCache = yield* isUseCacheEnabled();
    let initialResult: any = null;
    const { contestId, isRefresh } = payload;
    const param = contestId;
    const request = `${juryAssignmentUrl}/${param}`;
    const cache: NotUniqueCacheValue = yield* getNotUniqueCacheByKey(CACHE_TYPE.JURORS, param);
    if (cache && cache.key) {
        initialResult = cache.value;
    }

    if (initialResult && defaultUseCache && !isRefresh) {
        yield put(getJurorForContestSuccess(initialResult));
    } else {
        try {
            const response = yield call(api, request, httpRequest.GET, {}, 2);
            const { data } = response;
            yield put(getJurorForContestSuccess(data));
            yield put(
                setCache({
                    key: param,
                    value: data,
                    type: CACHE_TYPE.JURORS,
                    isUnique: false,
                }),
            );
        } catch (error: any) {
            yield put(getJurorForContestFail(error));
        }
    }
}

function* getScoreBoard({ payload }: { payload: IGetScoreBoard }): Generator<any, any, any> {
    const defaultUseCache = yield* isUseCacheEnabled();
    let initialResult: any = null;
    const { contestId, isRefresh, category } = payload;
    let param = contestId;
    if (category) {
        param += `?category=${category}`;
    }
    const request = `${scoreBoardUrl}/${param}`;
    const cache: NotUniqueCacheValue = yield* getNotUniqueCacheByKey(CACHE_TYPE.SCORE_BOARD, param);
    if (cache && cache.key) {
        initialResult = cache.value;
    }
    if (initialResult && defaultUseCache && !isRefresh) {
        yield put(getScoreBoardSuccess(initialResult));
    } else {
        try {
            const response = yield call(api, request, httpRequest.GET, {}, 2);
            const { data } = response;
            yield put(getScoreBoardSuccess(data));
            yield put(
                setCache({
                    key: param,
                    value: data,
                    type: CACHE_TYPE.SCORE_BOARD,
                    isUnique: false,
                }),
            );
        } catch (error: any) {
            yield put(getScoreBoardFail(error));
        }
    }
}

interface TaskAction
    extends Action,
        IInviteJuryPayload,
        IAcceptJuryInvite,
        IScoreSubmission,
        IGetJuryCampaignSubmissions {
    payload: any;
}

function* getJuryCampaignWatcher() {
    yield takeLatest(types.GET_JURY_CAMPAIGNS, getJuryCampaign);
}

function* getJuryCampaignSubmissionWatcher() {
    yield takeLatest<TaskAction>(types.GET_CAMPAIGN_SUBMISSIONS, getJuryCampaignSubmission);
}

function* getIncompleteJuryCampaignSubmissionWatcher() {
    yield takeLatest<TaskAction>(types.GET_INCOMPLETE_CAMPAIGN_SUBMISSIONS, getJuryCampaignSubmission);
}

function* getCompleteJuryCampaignSubmissionWatcher() {
    yield takeLatest<TaskAction>(types.GET_COMPLETED_CAMPAIGN_SUBMISSIONS, getJuryCampaignSubmission);
}

function* scoreSubmissionWatcher(): any {
    yield takeLatest<TaskAction>(types.SCORE_SUBMISSION, scoreSubmission);
}

function* acceptJuryInvitationWatcher() {
    yield takeLatest<TaskAction>(types.ACCEPT_JURY_INVITE, acceptJuryInvitation);
}

function* distributeSubmissionsWatcher() {
    yield takeLatest<TaskAction>(types.DISTRIBUTE_SUBMISSIONS_TO_JURIES, distributeSubmissions);
}

function* inviteJuryWatcher() {
    yield takeLatest<TaskAction>(types.INVITE_JURY, inviteJury);
}

function* getScoreBoardWatcher() {
    yield takeLatest<TaskAction>(types.GET_SCORE_BOARD, getScoreBoard);
}

function* getJurorForContestWatcher() {
    yield takeLatest<TaskAction>(types.GET_JURORS_FOR_CONTEST, getJurorForContest);
}

function* searchSumissionCamapignsWatcher() {
    yield takeLatest<TaskAction>(types.SEARCH_SUBMISSIONS, searchCampaignSubmission);
}

export default function* jurySaga() {
    yield all([
        getJuryCampaignWatcher(),
        getJuryCampaignSubmissionWatcher(),
        scoreSubmissionWatcher(),
        acceptJuryInvitationWatcher(),
        distributeSubmissionsWatcher(),
        inviteJuryWatcher(),
        getScoreBoardWatcher(),
        getJurorForContestWatcher(),
        getIncompleteJuryCampaignSubmissionWatcher(),
        getCompleteJuryCampaignSubmissionWatcher(),
        searchSumissionCamapignsWatcher(),
    ]);
}
