import React from 'react';
import { IconStyled, IIcon } from './Icon.styled';

export interface IProps extends IIcon {
    activeImage: any;
    inActiveImage?: any;
    active?: boolean;
    size?: number;
    color?: string;
    colorDisabled?: boolean;
    onClick?: () => void;
    enableBackground?: boolean;
    customBackgroundColor?: string;
    style?: React.CSSProperties;
}

const Icon = ({ activeImage, inActiveImage, active, size, color, height, width, style, onClick }: IProps) => {
    const image: string = active ? activeImage : inActiveImage;
    const handleOnClick = () => onClick && onClick();
    return <IconStyled src={image} active height={height} width={width} style={style} onClick={handleOnClick} />;
};

export { Icon };
