import produce from 'immer';
import { types } from './types';
import { parseSubscription, parseSubscriptionPlans } from '../../utils/responseProcessor';
import { restoreInitialState } from '../../utils/AppUtils';

const initialState: any = {
    subscriptionPlans: [],
    fetchingSubscriptionPlans: false,
    fetchingSubscriptionPlansError: null,
    loggedInUserPlan: null,
    fetchingLoggedInUserPlan: false,
    fetchingLoggedInUserPlanError: null,
    updatingUserPlan: false,
    updatingUserPlanError: null,
    subscribing: false,
    subscribeError: null,
    subscription: null,
};

const reducer = (state = initialState, action: any) => {
    const { type, payload } = action;

    switch (type) {
        case types.SUBSCRIBE:
            return produce(state, (draftState: typeof initialState) => {
                draftState.subscribing = true;
            });
        case types.SUBSCRIBE_SUCCESS:
            return produce(state, (draftState: typeof initialState) => {
                draftState.subscribing = false;
            });
        case types.SUBSCRIBE_FAIL:
            return produce(state, (draftState: typeof initialState) => {
                draftState.subscribing = false;
                draftState.subscribeError = payload;
            });
        case types.GET_SUBSCRIPTION_PLANS:
            return produce(state, (draftState: typeof initialState) => {
                draftState.fetchingSubscriptionPlans = true;
            });
        case types.GET_SUBSCRIPTION_PLANS_SUCCESS:
            return produce(state, (draftState: typeof initialState) => {
                draftState.fetchingSubscriptionPlans = false;
                const parsePlans = parseSubscriptionPlans(payload);
                draftState.subscriptionPlans = parsePlans;
            });
        case types.GET_SUBSCRIPTION_PLANS_FAIL:
            return produce(state, (draftState: typeof initialState) => {
                draftState.fetchingSubscriptionPlans = false;
                draftState.fetchingSubscriptionPlansError = payload;
            });
        case types.SET_SUBSCRIPTION:
            return produce(state, (draftState: typeof initialState) => {
                draftState.fetchingLoggedInUserPlan = true;
            });
        case types.SET_SUBSCRIPTION_SUCCESS:
            return produce(state, (draftState: typeof initialState) => {
                draftState.fetchingLoggedInUserPlan = false;
                const parsedSubscription = parseSubscription(payload);
                draftState.subscription = parsedSubscription;
            });
        case types.SET_SUBSCRIPTION_FAIL:
            return produce(state, (draftState: typeof initialState) => {
                draftState.fetchingLoggedInUserPlan = false;
                draftState.fetchingLoggedInUserPlanError = payload;
            });
        case types.UPDATE_SUBSCRIPTION:
            return produce(state, (draftState: typeof initialState) => {
                draftState.updatingUserPlan = true;
            });
        case types.UPDATE_SUBSCRIPTION_SUCCESS:
            return produce(state, (draftState: typeof initialState) => {
                draftState.updatingUserPlan = false;
                draftState.subscription = payload;
            });
        case types.UPDATE_SUBSCRIPTION_FAIL:
            return produce(state, (draftState: typeof initialState) => {
                draftState.updatingUserPlan = false;
                draftState.updatingUserPlanError = payload;
            });
        case types.RESET_SUBSCRIPTION_DATA:
            return restoreInitialState(state, initialState);
        default:
            return state;
    }
};

export { reducer };
