export const types = {
    APP_LAUNCH_FLOW: 'APP_LAUNCH_FLOW',
    APP_LAUNCH_FLOW_COMPLETE: 'APP_LAUNCH_FLOW_COMPLETE',
    DELETE_JWT: 'DELETE_JWT',
    DELETE_JWT_SUCCESS: 'DELETE_JWT_SUCCESS',
    DELETE_JWT_FAILED: 'DELETE_JWT_FAILED',
    DELETE_REFRESH_JWT: 'DELETE_REFRESH_JWT',
    DELETE_REFRESH_JWT_SUCCESS: 'DELETE_REFRESH_JWT_SUCCESS',
    DELETE_REFRESH_JWT_FAILED: 'DELETE_REFRESH_JWT_FAILED',
    GET_JWT: 'GET_JWT',
    SET_JWT: 'SET_JWT',
    GET_JWT_SUCCESS: 'GET_JWT_SUCCESS',
    GET_JWT_FAILED: 'GET_JWT_FAILED',

    GET_2FA_CODE_FOR_LOGIN: 'GET_2FA_CODE_FOR_LOGIN',
    GET_2FA_CODE_FAILED: 'GET_2FA_CODE_FAILED',
    GET_2FA_CODE_SUCCESS: 'GET_2FA_CODE_SUCCESS',
    LOGIN: 'LOGIN',
    LOGIN_SUCCESS: 'LOGIN_SUCCESS',
    LOGIN_FAILED: 'LOGIN_FAILED',
    CANCEL_LOGIN: 'CANCEL_LOGIN',
    LOGIN_CANCELLED: 'LOGIN_CANCELLED',
    LOGOUT: 'LOGOUT',
    LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
    LOGOUT_FAILED: 'LOGOUT_FAILED',
    SEND_PASSWORD_RESET_EMAIL: 'SEND_PASSWORD_RESET_EMAIL',
    SEND_PASSWORD_RESET_EMAIL_SUCCESS: 'SEND_PASSWORD_RESET_EMAIL_SUCCESS',
    SEND_PASSWORD_RESET_EMAIL_FAILED: 'SEND_PASSWORD_RESET_EMAIL_FAILED',
    SEND_PASSWORD_RESET_EMAIL_MESSAGE: 'SEND_PASSWORD_RESET_EMAIL_MESSAGE',

    GET_GUEST_TOKEN: 'GET_GUEST_TOKEN',
    RESET_TO_GUEST: 'RESET_TO_GUEST',
    GET_GUEST_TOKEN_SUCCESS: 'GET_GUEST_TOKEN_SUCCESS',
    GET_GUEST_TOKEN_FAILED: 'GET_GUEST_TOKEN_FAILED',
};

export interface IAppLaunchFlowComplete {
    userIsAuthenticated: boolean;
}

export interface IGenericAuthPayload {
    payload: any;
}

export interface ILoginPayload {
    email: string;
    password: string;
    phone?: string;
    resendToken?: boolean;
}

export interface ILoginResponse {}
export interface ISendPasswordReserEmail {
    email: string;
}

export interface ISendPasswordResetEmailMessage {
    message: string;
}

export interface ISetJwt {
    token: string;
    refreshToken: string;
}

export interface IAuthenticationInitialState {
    token: string;
    refreshToken: string;
    appLaunchFlowComplete: boolean;
    logginIn: boolean;
    loginError: any;
    sendPasswordResetEmail: boolean;
    sendingPasswordResetError: any;
    userIsAuthenticated: boolean;
    registrationCancelled: boolean;
    getting2FACode: boolean;
    getting2FACodeError: any;
    getting2FACodeDone: boolean;
    logoutError: any;
    loggingOut: boolean;
    jwtErrorMsg: any;
    isLoggedIn: boolean;
}
