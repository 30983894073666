import { Location, Status } from '../../types/global/generic.types';

export const types = {
    FETCH_FESTIVAL_HOME_ITEMS: 'FETCH_FESTIVAL_HOME_ITEMS',
    FETCH_FESTIVAL_HOME_ITEMS_SUCCESS: 'FETCH_FESTIVAL_HOME_ITEMS_SUCCESS',
    FETCH_FESTIVAL_HOME_ITEMS_FAIL: 'FETCH_FESTIVAL_HOME_ITEMS_FAIL',
    GET_FESTIVAL_DETAILS: 'GET_FESTIVAL_DETAILS',
    GET_FESTIVAL_DETAILS_SUCCESS: 'GET_FESTIVAL_DETAILS_SUCCESS',
    GET_FESTIVAL_DETAILS_FAIL: 'GET_FESTIVAL_DETAILS_FAIL',
    UPDATE_FESTIVAL: 'UPDATE_FESTIVAL',
    UPDATE_FESTIVAL_SUCCESS: 'UPDATE_FESTIVAL_SUCCESS',
    UPDATE_FESTIVAL_FAIL: 'UPDATE_FESTIVAL_FAIL',
    DELETE_FESTIVAL: 'DELETE_FESTIVAL',
    DELETE_FESTIVAL_SUCCESS: 'DELETE_FESTIVAL_SUCCESS',
    DELETE_FESTIVAL_FAIL: 'DELETE_FESTIVAL_FAIL',
    GET_FESTIVALS: 'GET_FESTIVALS',
    GET_FESTIVALS_SUCCESS: 'GET_FESTIVALS_SUCCESS',
    GET_FESTIVALS_FAIL: 'GET_FESTIVALS_FAIL',
    GET_FESTIVAL_AWARD_CATEGORY: 'GET_FESTIVAL_AWARD_CATEGORY',
    GET_FESTIVAL_AWARD_CATEGORY_SUCCESS: 'GET_FESTIVAL_AWARD_CATEGORY_SUCCESS',
    GET_FESTIVAL_AWARD_CATEGORY_FAIL: 'GET_FESTIVAL_AWARD_CATEGORY_FAIL',
    GET_FESTIVAL_AWARD_RECIPIENTS: 'GET_FESTIVAL_AWARD_RECIPIENTS',
    GET_FESTIVAL_AWARD_RECIPIENTS_SUCCESS: 'GET_FESTIVAL_AWARD_RECIPIENTS_SUCCESS',
    GET_FESTIVAL_AWARD_RECIPIENTS_FAIL: 'GET_FESTIVAL_AWARD_RECIPIENTS_FAIL',
};

export interface FestivalSummary {
    fesival_location: Location;
    festival_gallery: string[];
    festival_date: string;
    created_at: string;
    /** Determines if a festival will be featured on the homepage */
    is_feature: boolean;
    status: Status;
    _id: string;
    name: string;
    about: string;
    festival_coodinators: string;
    id: string;
    festival_profile_picture?: string;
}

export interface Festival extends FestivalSummary {
    year_started: string;
    festival_website: string;
    festival_history: string;
    country: string;
}

export type AwardStatus = 'won' | 'nominated';
export type AwardReciepient = {
    created_at: string;
    _id: string;
    fullName: string;
    profile_picture: string;
    id: string;
};

export type AwardCategory = {
    created_at: string;
    _id: string;
    category_title: string;
    year_started: string;
    id: string;
    festival: string;
    contest?: string;
};
export interface Award {
    award_status: AwardStatus;
    created_at: string;
    _id: string;
    award_year: string;
    person_awarded: AwardReciepient;
    award_category: AwardCategory;
    award_project: {
        created_at: string;
        _id: string;
        title: string;
        id: string;
    };
    festival: {
        created_at: string;
        _id: string;
        name: string;
        festival_profile_picture: string;
        year_started: string;
        id: string;
    };
    created_by: string;
    __v: 0;
    id: string;
    feature_image?: string;
    hash?: string;
}

export type FestivalHomeFeature = {
    id: string[];
    posterURI: Array<{ image: string }>;
    title: string[];
    year_started: string[];
    about?: string[];
    content_type: Array<string[]>;
};

export type FestivalHome = {
    categoryTitle: string;
    festival: FestivalHomeFeature[];
};

export interface IFestivalInitialState {
    festivals: Festival[];
    selectedFestival?: Festival;
    fetchingFestivals: boolean;
    fetchingFestivalErrorMsg: any;
    festivalHomeItems: FestivalHome[];
    fetchingFestivalHomeItems: boolean;
    gettingFestivalDetails: boolean;
    selectedFestivalAwardCategories: AwardCategory[];
    selectedFestivalAwardRecipient: Award[];
    fetchingFestivalAwardCategory: boolean;
    fetchingFestivalAwardRecipient: boolean;
    fetchingAwardErrorMsg: any;
}
