import { call, put, all, takeLatest } from 'redux-saga/effects';
import { types } from './types';
import { getProjectGuide as getProjectGuideTrigger, setProjectGuide as setProjectGuideTrigger } from '../project';
import { setProjectGuide as setProjectGuideAction } from './action';

function* getProjectGuide(): Generator<any, any, any> {
    try {
        const response = yield call(getProjectGuideTrigger);
        yield put(setProjectGuideAction(!!response));
    } catch (error) {
        console.log(error);
    }
}

function* setProjectGuide() {
    try {
        yield call(setProjectGuideTrigger);
        yield put(setProjectGuideAction(true));
    } catch (error) {
        console.log(error);
    }
}

function* projectGuideWatcher() {
    yield takeLatest(types.GET_PROJECT_GUIDE, getProjectGuide);
}
function* setProjectGuideWatcher() {
    yield takeLatest(types.SET_PROJECT_GUIDE, setProjectGuide);
}

export default function* appConfiguratorSaga() {
    yield all([projectGuideWatcher(), setProjectGuideWatcher()]);
}
