import axios, { ResponseType } from 'axios';
import axiosRetry from 'axios-retry';

import { store } from '../../redux/store';
import { dataURIToBlob } from '../../utils/mediaHelper';
import { SentryCapture } from '../../analytics/Sentry';
import { isObject } from 'lodash';
axios.defaults.withCredentials = true;

const METHODS_WITH_BODY = ['POST', 'PATCH', 'DELETE'];
export const systemName = navigator.userAgent;
export const platform = navigator.platform;
export const version = '1.0.0';
export const tenantId = '9d96e225-e607-50fa-b6bb-aaf070cc5805'; // Generate This Dyamically
export const deviceIdentifier = `app_name=thspian;platform=${platform};;app_version${version};device_id${systemName}`;
axiosRetry(axios, {
    retries: 0,
    retryDelay: count => count * 1000,
    retryCondition: error => {
        const shouldRetry =
            error.code !== 'ECONNABORTED' &&
            (!error.response || (error.response.status >= 500 && error.response.status <= 599));
        if (shouldRetry) {
            if (process.env.REACT_APP_NODE_ENV === 'development') console.log('Retrying api...', error);
            SentryCapture(error, 'error');
        }
        return shouldRetry;
    },
});

const fetchWithTimeout = (
    url: string,
    requestInfo: any,
    onUploadProgress?: (progressEvent: axios.AxiosProgressEvent) => void,
    onDownloadProgress?: (progressEvent: axios.AxiosProgressEvent) => void,
    responseType?: ResponseType,
): any => {
    return axios({
        method: requestInfo.method,
        url,
        timeout: requestInfo.timeout,
        headers: requestInfo.headers,
        data: requestInfo.body,
        'axios-retry': requestInfo['axios-retry'],
        withCredentials: true,
        onUploadProgress: onUploadProgress,
        onDownloadProgress: onDownloadProgress,
        responseType: responseType,
    });
};

export const api = (
    url: string,
    method: string,
    request: any,
    retryCount: number = 0,
    timeout: any = null,
    upload: boolean = false,
    cb?: (err: any) => void,
    onUploadProgress?: (progressEvent: axios.AxiosProgressEvent) => void,
    onDownloadProgress?: (progressEvent: axios.AxiosProgressEvent) => void,
    responseType?: ResponseType,
) => {
    const { authentication } = store.getState();
    const token = authentication.token && authentication.token;
    const contentType = upload ? 'multipart/form-data' : 'application/json';

    /** Ensure to upload this field with any new POSt or UPDATE fields that has an upload to it */
    const IMAGE_UPLOADS_SINGLE = [
        'movie_poster',
        'imageCover',
        'profile_picture',
        'thumbnail',
        'feature_image',
        'mobile_feature_image',
    ];
    const IMAGE_UPLOAD_MULTI = ['movie_pictures', 'photos', 'gallery', 'project_gallery', 'contest_media'];
    const VIDEO_SINGLE_UPLOAD = ['video'];

    const createFormData = (body: { [key: string]: File[] | string | string[] | any }) => {
        const data = new FormData();
        Object.keys(body).forEach((key: string) => {
            if (IMAGE_UPLOAD_MULTI.includes(key)) {
                const fileBody: File[] = body[key];
                fileBody.forEach((item: File) => {
                    data.append(`${key}`, item);
                });
            } else if (IMAGE_UPLOADS_SINGLE.includes(key)) {
                let blob;
                if (typeof body[key] === 'string') {
                    blob = dataURIToBlob(body[key]);
                } else {
                    blob = body[key];
                }
                data.append(key, blob);
            } else if (
                Array.isArray(body[key]) &&
                !IMAGE_UPLOAD_MULTI.includes(key) &&
                !IMAGE_UPLOADS_SINGLE.includes(key)
            ) {
                body[key].forEach((k: unknown, index: number) => {
                    if (isObject(k)) {
                        for (const s in k) {
                            data.append(`${key}[${index}][${s}]`, body[key][index][s]);
                        }
                    } else {
                        data.append(key, k as any);
                    }
                });
            } else if (isObject(body[key])) {
                for (const k in body[key]) {
                    data.append(`${key}[${k}]`, body[key][k]);
                }
            } else {
                data.append(key, body[key]);
            }
            if (VIDEO_SINGLE_UPLOAD.includes(key)) {
                let blob;
                if (typeof body[key] === 'string') {
                    blob = dataURIToBlob(body[key]);
                } else {
                    blob = body[key];
                }
                data.append(key, blob);
            }
        });

        return data;
    };

    const defaultHeaders: any = {
        'Content-Type': contentType,
        'Access-Control-Allow-Origin': '*',
        credentials: 'same-origin',
        'X-DEVICE-INFO': deviceIdentifier,
        'x-tenant-id': tenantId,
        'x-service-name': 'thspian',
    };

    if (token) defaultHeaders.Authorization = `Bearer ${token}`;

    const _timeout = timeout !== null ? timeout : 0;

    const requestInfo: any = {
        method,
        headers: defaultHeaders,
        timeout: _timeout * 1000,
        'axios-retry': {
            retries: retryCount,
        },
    };

    if (METHODS_WITH_BODY.includes(method) && request) {
        requestInfo.body = JSON.stringify(request) || {};
        if (upload) {
            requestInfo.body = createFormData(request);
        }
    }

    if (METHODS_WITH_BODY.includes(method) && request.params) {
        requestInfo.params = JSON.stringify(request.params);
    }
    if (process.env.REACT_APP_NODE_ENV === 'development') {
        console.log('API URL', url);
        console.log('API REQUEST', requestInfo);
    }

    return fetchWithTimeout(url, requestInfo, onUploadProgress, onDownloadProgress, responseType)
        .then((response: any) => response.data)
        .catch((error: any) => {
            if (cb) cb(error);
            if (process.env.REACT_APP_NODE_ENV === 'development') {
                console.log('API URL', url);
                console.log('API ERROR', error);
            } else {
                SentryCapture(error.message, 'error');
            }
            throw error.response;
        });
};
