import produce from 'immer';
import { types } from './types';
import { types as projectType } from '../project/types';
import { IAdvertInitialState, ITrackVrollAds } from './model';
import { IProject } from '../../types/global/helper';
import { IAdvertisement } from '../../types/global/media.types';

const initialState: IAdvertInitialState = {
    playedAdsId: [],
    videoPlayCount: 0,
    ads: [],
    isAdPlaying: false,
    advertisementInProgress: false,
};

const reducer = (state = initialState, action: any) => {
    const { type, payload } = action;
    switch (type) {
        case types.TRACK_AD:
        case types.SET_PLAYED_ADS:
            return produce(state, draftState => {
                const { id, action } = payload as ITrackVrollAds;
                if (!draftState.playedAdsId.includes(id)) {
                    draftState.playedAdsId = [...draftState.playedAdsId, id];
                }
                draftState.lastEvent = action;
                const currentAd = draftState.ads.find(ad => ad._id === id);
                if (currentAd) {
                    draftState.currentVRollAdInPlay = currentAd as IAdvertisement;
                    draftState.showAdBanner = true;
                }
                // Reset when ad plays
                if (action === 'start') {
                    draftState.videoPlayCount = 0;
                    draftState.isAdPlaying = true;
                    draftState.advertisementInProgress = true;
                }

                if (action === 'skipped' || action === 'completed') {
                    draftState.isAdPlaying = false;
                    draftState.advertisementInProgress = false;
                    draftState.videoPlayCount = 0;
                }
            });
        case types.TRACK_AD_SUCCESS:
            return produce(state, draftState => {});
        case types.TRACK_AD_FAIL:
            return produce(state, draftState => {
                draftState.error = payload;
            });
        case types.SET_PLAYED_VIDEO_COUNT:
            return produce(state, draftState => {
                draftState.videoPlayCount += 1;
                // Reset the video playing count
                if (draftState.videoPlayCount > 20) {
                    draftState.videoPlayCount = 0;
                }
            });
        case projectType.SET_MEDIA:
            return produce(state, draftState => {
                const { media } = payload;

                if (media === null) {
                    draftState.advertisementInProgress = false;
                }
                // Reset the video playing count
                if (draftState.videoPlayCount > 20) {
                    draftState.videoPlayCount = 0;
                }
            });
        case projectType.GET_PROJECT:
            return produce(state, draftState => {
                draftState.playedAdsId = [];
                draftState.advertisementInProgress = false;
                draftState.currentVRollAdInPlay = undefined;
                draftState.showAdBanner = false;
            });
        case projectType.GET_PROJECT_SUCCESS:
            return produce(state, draftState => {
                const { vroll } = payload as IProject;
                if (vroll) {
                    draftState.ads = vroll;
                }
            });
        /** Reset and clear the previos ad */
        case projectType.GET_PROJECT_DETAILS:
            return produce(state, draftState => {
                draftState.currentVRollAdInPlay = undefined;
                draftState.currentVpaidAdInPlay = undefined;
            });
        case types.GET_AD_SUCCESS:
            return produce(state, draftState => {
                draftState.ads = payload;
            });
        case types.GET_AD_FAIL:
            return produce(state, draftState => {
                draftState.error = payload;
            });
        case types.HIDE_AD_BANNER:
            return produce(state, draftState => {
                draftState.showAdBanner = false;
            });
        default:
            return state;
    }
};

export { reducer };
