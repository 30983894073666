import React from 'react';
import { useRef, useState, useEffect } from 'react';
import { StyleSheet } from 'react-native';
import { useThemeContext } from '../../hook/useThemeManager';
import { ColorScheme, theme } from '../../constants/theme';
import { EsResetWrapper } from '../EsResetPassword/EsResetWrapper';
import { FormControl, Box, Button, Typography } from '@mui/material';
import { FONT_ENUM } from '../../types/global/fonts.types';
import { useEsHover } from '../Animation/useEsHover';
import { en_config } from '../../config';
import { CustomTextField } from '../TextInput/CustomTextField';
import { strings } from '../../constants/strings';
import { Spinner } from '@chakra-ui/react';

let currrentOtpIndex: number = 0;

interface IOtpProps {
    onClickBack: () => void;
    onChange: (otp: string[]) => void;
    onResendOtp: () => void | Promise<unknown>;
    otpCodeCount?: number;
    onSubmit?: () => void;
    loading?: boolean;
    resendInProgress?: boolean;
    label?: string;
    buttonString?: string;
}

const EsVerifyCode: React.FC<IOtpProps> = ({
    onClickBack,
    onChange,
    onResendOtp,
    otpCodeCount,
    onSubmit,
    loading,
    resendInProgress,
    label,
    buttonString,
}) => {
    const [otp, setOtp] = useState<string[]>(new Array(otpCodeCount ?? en_config.TWO_FA_LENGTH).fill(''));
    const [activeOTPIndex, setActiveOTPIndex] = useState<number>(0);
    const [seconds, setSeconds] = useState(30); // Set initial countdown value
    const [isActive, setIsActive] = useState(true);

    const inputRef = useRef<HTMLInputElement>(null);

    const handleOnChange = ({ target }: React.ChangeEvent<HTMLInputElement>): void => {
        const { value } = target;
        const newOTP: string[] = [...otp];
        newOTP[currrentOtpIndex] = value.substring(value.length - 1);

        if (!value) setActiveOTPIndex(currrentOtpIndex - 1);
        else setActiveOTPIndex(currrentOtpIndex + 1);

        if (onChange) {
            onChange(newOTP);
        }

        setOtp(newOTP);
    };

    const handleOnKeyDown = ({ key }: React.KeyboardEvent<HTMLInputElement>, index: number) => {
        currrentOtpIndex = index;
        if (key === 'Backspace') setActiveOTPIndex(currrentOtpIndex - 1);
    };
    const { handleMouseEnter, handleMouseLeave, isHover } = useEsHover();

    useEffect(() => {
        inputRef.current?.focus();
    }, [activeOTPIndex]);

    useEffect(() => {
        let interval: string | number | NodeJS.Timer | null | undefined = null;

        if (isActive && seconds > 0) {
            interval = setInterval(() => {
                setSeconds(prevSeconds => prevSeconds - 1);
            }, 1000);
        } else if (seconds === 0) {
            setIsActive(false);
        }

        return () => {
            if (interval) {
                clearInterval(interval);
            }
        };
    }, [isActive, seconds]);

    const handleResendClick = () => {
        if (!resendInProgress) {
            if (onResendOtp) {
                if (onResendOtp instanceof Promise) {
                    onResendOtp()
                        ?.then(() => {
                            setSeconds(30);
                            setIsActive(true);
                        })
                        .catch(e => {});
                } else {
                    onResendOtp();
                    setSeconds(30);
                    setIsActive(true);
                }
            }
        }
    };
    const { themeStyle } = useThemeContext();
    const styles = dynamicStyles(themeStyle, isHover, loading);

    return (
        <EsResetWrapper
            title={label ?? 'Verify Account'}
            subtitle="We just sent you a verification code to your WhatsApp™. Check your phone.">
            <FormControl style={{ width: '100%' }}>
                <Box
                    sx={{
                        marginTop: '40px',
                        marginBottom: '40px',
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: { xs: '12px', sm: '28px' },
                    }}>
                    {otp.map((field, index) => {
                        return (
                            <CustomTextField
                                key={index}
                                onChange={handleOnChange}
                                isOTP={true}
                                textFieldProps={{
                                    InputProps: {
                                        disableUnderline: true,
                                    },
                                    inputProps: {
                                        type: 'number',
                                        inputMode: 'numeric',
                                        autoComplete: 'one-time-code',
                                        ref: index === activeOTPIndex ? inputRef : null,
                                        onKeyDown: (e: any) => handleOnKeyDown(e, index),
                                        required: true,
                                        value: otp[index],
                                        sx: { ...styles.input, width: { xs: 30, sm: 54 }, height: { xs: 50, sm: 72 } },
                                    },
                                }}
                            />
                        );
                    })}
                </Box>
                <Button
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    style={styles.button}
                    onClick={() => {
                        if (onSubmit) {
                            onSubmit();
                        } else {
                            handleMouseLeave();
                        }
                    }}
                    type={onSubmit ? 'button' : 'submit'}
                    disabled={otp.some(value => !value) || loading}>
                    {loading ? (
                        <Spinner h={4} w={4} color={theme[themeStyle].tag_white} />
                    ) : (
                        <Typography style={styles.signup}>{buttonString ?? strings.sign_in}</Typography>
                    )}
                </Button>
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}>
                    <Button style={styles.backButton} onClick={onClickBack} type="button">
                        <Typography style={styles.backText}>{strings.go_back}</Typography>
                    </Button>
                    {!isActive && !loading ? (
                        <Typography component={'span'} style={styles.message}>
                            Didn't get code? {'Click '}
                            {resendInProgress ? (
                                <Spinner h={4} w={4} color={theme[themeStyle].tag_white} />
                            ) : (
                                <span onClick={handleResendClick} style={{ ...styles.countdown, cursor: 'pointer' }}>
                                    here
                                </span>
                            )}{' '}
                            to request a new one.
                        </Typography>
                    ) : (
                        <Typography style={styles.message}>
                            Request new code in?{' '}
                            <span style={styles.countdown}>(00:{seconds.toString().padStart(2, '0')})</span>
                        </Typography>
                    )}
                </Box>
            </FormControl>
        </EsResetWrapper>
    );
};

EsVerifyCode.defaultProps = {
    otpCodeCount: en_config.TWO_FA_LENGTH,
};
//TODO: remove redundant styles
const dynamicStyles = (colorScheme: ColorScheme, isHover: boolean, isLoading?: boolean) =>
    StyleSheet.create({
        text: {
            maxWidth: 400,
            width: '100%',
            textAlign: 'left',
            fontSize: 14,
            fontStyle: 'normal',
            fontWeight: '400',
            color: theme[colorScheme].form_label,
            fontFamily: FONT_ENUM.MAIN_REGULAR,
        },
        input: {
            backgroundColor: theme[colorScheme].gray_text,
            color: theme[colorScheme].lightWhite,
            fontSize: 30,
            textAlign: 'center',
            fontFamily: FONT_ENUM.MAIN_MEDIUM,
        },
        signup: {
            fontSize: 14,
            fontWeight: '600',
            fontFamily: FONT_ENUM.MAIN_BOLD,
            color: isHover ? theme[colorScheme].primary_orange : theme[colorScheme].gray_text,
        },
        button: {
            width: '100%',
            height: 44,
            paddingX: 20,
            paddingY: 12,
            borderRadius: 100,
            maxWidth: 400,
            backgroundColor: isHover || isLoading ? theme[colorScheme].gray_text : theme[colorScheme].primary_orange,
            textTransform: 'none',
            alignSelf: 'center',
        },
        message: {
            maxWidth: 400,
            width: 'fit-content',
            textAlign: 'justify',
            fontSize: 14,
            fontStyle: 'normal',
            fontWeight: '400',
            color: theme[colorScheme].light_gray_text,
            fontFamily: FONT_ENUM.MAIN_REGULAR,
            marginTop: 20,
        },
        countdown: {
            maxWidth: 400,
            width: '100%',
            textAlign: 'justify',
            fontSize: 14,
            fontStyle: 'normal',
            fontWeight: '400',
            fontFamily: FONT_ENUM.MAIN_REGULAR,
            color: theme[colorScheme].primary_orange,
            marginTop: 20,
        },
        backText: {
            textAlign: 'center',
            fontSize: 12,
            fontStyle: 'normal',
            fontWeight: '400',
            fontFamily: FONT_ENUM.MAIN_REGULAR,
            color: theme[colorScheme].lightWhite,
        },
        backButton: {
            width: 'fit-content',
            alignSelf: 'center',
            height: 'fit-content',
            paddingX: 20,
            paddingY: 12,
            backgroundColor: theme[colorScheme].transparent,
            textTransform: 'none',
            marginTop: 20,
        },
    });

export { EsVerifyCode };
