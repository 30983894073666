import produce from 'immer';

export const initialState = {
    title: '',
    project: null,
    description: '',
    scheduled_published_date: '',
    scheduled_published: false,
    tag: [],
    project_type: '',
};

const actions = {
    FIELD: 'FIELD',
    RESET: 'RESET',
};

export function reducer(state: any, action: any) {
    switch (action.type) {
        case actions.FIELD:
            return produce(state, (draftState: { [x: string]: any }) => {
                draftState[action.field] = action.value;
            });
        case actions.RESET:
            return produce(state, () => {
                return initialState;
            });
        default:
            return state;
    }
}
