import {types} from './types';

export const setSubscription = () => ({
    type: types.SET_SUBSCRIPTION,
});

export const setSubscriptionSuccess = (payload: any) => ({
    type: types.SET_SUBSCRIPTION_SUCCESS,
    payload,
});

export const setSubscriptionFail = (payload: any) => ({
    type: types.SET_SUBSCRIPTION_FAIL,
    error: payload,
});

/** Subscription Action, takes an Object of two keys `Plan`, being the subscription plan, and `Authorization`
 * being the ID of the Credit card authorization
 */
export const subscribe = (payload: any) => ({
    type: types.SUBSCRIBE,
    payload,
});

export const subscribeSuccess = (payload: any) => ({
    type: types.SUBSCRIBE_SUCCESS,
    payload,
});

export const subscribeFail = (payload: any) => ({
    type: types.SUBSCRIBE_FAIL,
    error: payload,
});

export const getSubscriptionPlans = () => ({
    type: types.GET_SUBSCRIPTION_PLANS,
});

export const getSubscriptionPlansSuccess = (payload: any) => ({
    type: types.GET_SUBSCRIPTION_PLANS_SUCCESS,
    payload,
});

export const getSubscriptionPlansFail = (payload: any) => ({
    type: types.GET_SUBSCRIPTION_PLANS_FAIL,
    error: payload,
});

export const cancelSubsciption = (payload: any) => ({
    type: types.CANCEL_SUBSCRIPTION,
    payload,
});

export const cancelSubsciptionSuccess = (payload: any) => ({
    type: types.CANCEL_SUBSCRIPTION_SUCCESS,
    payload,
});

export const cancelSubsciptionFail = (payload: any) => ({
    type: types.CANCEL_SUBSCRIPTION_FAIL,
    error: payload,
});

export const updateSubscription = (payload?: any) => ({
    type: types.UPDATE_SUBSCRIPTION,
    payload,
});

export const updateSubscriptionSuccess = (payload: any) => ({
    type: types.UPDATE_SUBSCRIPTION_SUCCESS,
    payload,
});

export const updateSubscriptionFail = (payload: any) => ({
    type: types.UPDATE_SUBSCRIPTION_FAIL,
    error: payload,
});
export const resetSubscriptionData = () => ({
    type: types.RESET_SUBSCRIPTION_DATA,
});
