import checkIsEmpty from 'lodash/isEmpty';
import checkIsEqual from 'lodash/isEqual';

export const isEmpty = (arg: any) => {
    return checkIsEmpty(arg);
};

export const isEqual = (obj: any, obj2: any) => {
    return checkIsEqual(obj, obj2);
};
