import { createSelector } from 'reselect';
import { RootState } from '../../redux/root-reducer';
import { IContest, IProject } from '../../types/global/helper';
import { Selector } from 'react-redux';
import { isEmpty } from '../../utils/lodash';
import { IProjectInitialState } from '../../redux/project/types';

export const projectSelector: Selector<RootState, IProjectInitialState> = createSelector(
    (state: RootState) => state.project,
    project => project,
);

export const userProjectsSelector: Selector<RootState, IProject[]> = createSelector(
    (state: RootState) => state.project,
    project => project.userProjects,
);
export const loadingProjectsSelector: Selector<RootState, boolean> = createSelector(
    (state: RootState) => state.project,
    project => project.gettingProjects,
);

export const creatingProjectsSelector: Selector<RootState, boolean> = createSelector(
    (state: RootState) => state.project,
    project => project.creatingProject,
);

export const userCreatedContests: Selector<RootState, IContest[]> = createSelector(
    (state: RootState) => state.contest,
    contest => contest.userContests,
);

export const isProjectActivelyProcessing: Selector<RootState, boolean> = createSelector(
    [userProjectsSelector],
    projects => projects && !isEmpty(projects) && projects.some(project => !project.processing_complete),
);

export const projectRetrySelector: Selector<RootState, Record<string, string>> = createSelector(
    [projectSelector],
    project => project.retries,
);
