import { createAction } from 'redux-act';
import { IContestRequest, ICreateContestRequest, IGetRequests, IResolveRequests, types } from './types';
import { IErrorType } from '../../constants/types';

export const createContestRequest = createAction<ICreateContestRequest>(types.CREATE_REQUEST);
export const createContestRequestSucces = createAction<IContestRequest>(types.CREATE_REQUEST_SUCCESS);
export const createContestRequestFail = createAction<IErrorType>(types.CREATE_REQUEST_FAIL);

export const getMyRequests = createAction(types.GET_MY_REQUESTS);
export const getMyRequestsSuccess = createAction<IContestRequest[]>(types.GET_MY_REQUESTS_SUCCESS);
export const getMyRequestsFail = createAction<IErrorType>(types.GET_MY_REQUESTS_FAIL);

export const resolveRequest = createAction<IResolveRequests>(types.RESOLVE_REQUEST);
export const resolveRequestSuccess = createAction<IContestRequest>(types.RESOLVE_REQUEST_SUCCESS);
export const resolveRequestFail = createAction<IErrorType>(types.RESOLVE_REQUEST_FAIL);

export const getRequests = createAction<IGetRequests>(types.GET_REQUESTS);
export const getRequestsSuccess = createAction<IContestRequest>(types.GET_REQUESTS_SUCCESS);
export const getRequestsFail = createAction<IErrorType>(types.GET_REQUESTS_FAIL);
