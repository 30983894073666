import { call, put, all, takeLatest } from 'redux-saga/effects';
import {
    getMyNotificationsSuccess,
    getMyNotificationsFail,
    readNotificationSuccess,
    readNotificationFail,
    updateNotificationSuccess,
    updateNotificationFail,
    deleteNotificationSuccess,
    deleteNotificationFail,
} from './action';
import { globalErrorHandler } from '../error/saga';
import { types } from './types';
import { api } from '../../configurations/api';
import { getCurrentUserWebNotificationsUrl, webNotificationsUrl } from '../../configurations/api/url';
import { httpRequest } from '../types';
import { genericParseSingleDocument, parseGenericCollection } from '../../utils/responseProcessor';
import { CACHE_TYPE, CacheValue } from '../cache/types';
import { getCacheByType, getCacheDuration, isUseCacheEnabled } from '../cache/saga';
import { destroyOneCache, setCache } from '../cache/action';
import { WebNotification } from '../account/accountDetailsResponse.type';
import { Action } from 'redux';
import { navigate } from '../navigator/action';
import { ROUTES } from '../../types/global/routes.types';
import { IContest, IGenericDocumentModifier, IProject } from '../../types/global/helper';
import { IUser } from '../../types/global/ListRender.types';
import { getProject } from '../project/actions';
import { SentryCapture } from '../../analytics/Sentry';
import { replaceRouteParam } from '../../utils/routesProcessor';

function* readNotification({ payload }: { payload: WebNotification }): any {
    try {
        /**
         * Update the unread_web_notification number
         * Update the specific notification as seen
         * Clear the notification cache
         * Navigate to the specific screen if the payload is either project, user or contest
         *
         */

        //TODO Consider removing this here and calling the put action to update notification. Check the updateNotification SAGA
        const updateBody = {
            seen: true,
        };
        const response = yield call(
            api,
            `${webNotificationsUrl}/${payload._id}`,
            httpRequest.PATCH,
            updateBody,
            1,
            2000,
        );
        const { data } = response.data;
        const parsedNotification = genericParseSingleDocument(data);
        yield put(
            destroyOneCache({
                cacheType: CACHE_TYPE.USER_NOTIFICATIONS,
            }),
        );
        if (payload.details && typeof payload.details.model !== 'string') {
            let doc = payload.details.model as IProject;
            let routName = `${ROUTES.ESWATCH_VIDEO}?id=${doc.video_id}`;
            let body = {
                project: payload.details.model,
            };
            switch (payload.details.type) {
                case 'Contest':
                    const contest = payload.details.model as IContest;
                    routName = `${ROUTES.ESCONTEST}/${contest._id}`;
                    if (!payload.page) {
                        yield put(
                            navigate({
                                routes: routName,
                                params: body,
                            }),
                        );
                    }
                    break;
                case 'User':
                    const user = payload.details.model as IUser;
                    const profileRoute = replaceRouteParam(ROUTES.ESMY_PROFILE, 'profileId?', user.userName);
                    if (!payload.page) {
                        yield put(
                            navigate({
                                routes: profileRoute,
                                params: body,
                            }),
                        );
                    }
                    break;
                case 'Project':
                    switch (payload.notification_type) {
                        case 'project_activities':
                        case 'application_activity':
                            if (!payload.seen) {
                                routName = `${ROUTES.ESMY_VIDEOS}?isNewEdit=true&project=${doc.video_id}&edit=true&publish_button=true`;
                            } else {
                                routName = `${ROUTES.ESMY_VIDEOS}?isNewEdit=true&project=${doc.video_id}`;
                            }
                            yield put(
                                navigate({
                                    routes: routName,
                                    params: body,
                                }),
                            );
                            break;
                        case 'project_recommendation':
                            yield put(
                                getProject({
                                    id: doc.video_id as string,
                                    withNavigate: true,
                                    fromAnotherScreen: true,
                                }),
                            );
                            break;
                        default:
                            break;
                    }
                    break;
            }
        }
        yield put(readNotificationSuccess(parsedNotification));
    } catch (error: any) {
        SentryCapture(error, 'error');
        yield call(globalErrorHandler, error);
        yield put(readNotificationFail(error));
    }
}
function* getMyNotifications(): any {
    const defaultUseCache = yield* isUseCacheEnabled();
    const defaultCacheDuration = yield* getCacheDuration();
    let initialResult: any = null;
    const cache: CacheValue = yield* getCacheByType(CACHE_TYPE.USER_NOTIFICATIONS);
    if (cache && cache.key) {
        initialResult = cache.value;
    }

    if (
        !!initialResult &&
        defaultUseCache &&
        ((Date.now() - Number(cache.key)) as unknown as number) < defaultCacheDuration
    ) {
        yield put(getMyNotificationsSuccess(initialResult));
    } else {
        try {
            const response = yield call(api, getCurrentUserWebNotificationsUrl, httpRequest.GET, null, 0, 0);
            const { data } = response.data;
            const parsedNotification = parseGenericCollection(data, genericParseSingleDocument);
            yield put(getMyNotificationsSuccess(parsedNotification));
            yield put(
                setCache({
                    key: Date.now(),
                    value: parsedNotification,
                    type: CACHE_TYPE.USER_NOTIFICATIONS,
                    isUnique: true,
                }),
            );
        } catch (error: any) {
            SentryCapture(error, 'error');
            yield put(getMyNotificationsFail(error));
            yield call(globalErrorHandler, error);
        }
    }
}

function* updateNotification({ payload }: { payload: IGenericDocumentModifier }): any {
    try {
        const response = yield call(
            api,
            `${webNotificationsUrl}/${payload.documentId}`,
            httpRequest.PATCH,
            payload.payload,
            1,
            2000,
        );
        const { data } = response.data;
        const parsedNotification = genericParseSingleDocument(data);
        yield put(updateNotificationSuccess(parsedNotification));
        yield put(
            destroyOneCache({
                cacheType: CACHE_TYPE.USER_NOTIFICATIONS,
            }),
        );
    } catch (error: any) {
        SentryCapture(error, 'error');
        yield put(updateNotificationFail(error));
    }
}

function* deleteNotification({ payload }: { payload: IGenericDocumentModifier }): any {
    try {
        yield call(api, `${webNotificationsUrl}/${payload.documentId}`, httpRequest.DELETE, {}, 1, 2000);
        yield put(deleteNotificationSuccess(payload));
        yield put(
            destroyOneCache({
                cacheType: CACHE_TYPE.USER_NOTIFICATIONS,
            }),
        );
    } catch (error: any) {
        SentryCapture(error, 'error');
        yield put(deleteNotificationFail(error));
    }
}

interface TaskAction extends Action {
    payload: WebNotification;
}

interface IUpdateDoc extends Action {
    payload: IGenericDocumentModifier;
}

function* getMyNotificationsWatcher() {
    yield takeLatest(types.GET_MY_NOTIFICATIONS, getMyNotifications);
}

function* readNotificationWatcher() {
    yield takeLatest<TaskAction>(types.READ_NOTIFICATION, readNotification);
}

function* deleteNotificationWatcher() {
    yield takeLatest<IUpdateDoc>(types.DELETE_NOTIFICATION, deleteNotification);
}

function* updateNotificationWatcher() {
    yield takeLatest<IUpdateDoc>(types.UPDATE_NOTIFICATION, updateNotification);
}

export default function* notificationsSaga() {
    yield all([
        getMyNotificationsWatcher(),
        readNotificationWatcher(),
        deleteNotificationWatcher(),
        updateNotificationWatcher(),
    ]);
}
