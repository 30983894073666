import { call, all, put, takeLatest } from 'redux-saga/effects';

import {
    getFestivalDetailsSuccess,
    getFestivalDetailsFail,
    fetchFestivalHomeItemsSuccess,
    fetchFestivalHomeItemsFail,
    getFestivalsSuccess,
    getFestivalsFail,
    getFestivalAwardCategorySuccess,
    getFestivalAwardCategoryFail,
    getFestivalAwardRecipientsSuccess,
    getFestivalAwardRecipientsFail,
    getFestivalAwardCategory as getFestivalAwardCategoryAction,
    getFestivalAwardRecipients as getFestivalAwardRecipientsAction,
} from './action';
import { types } from './types';
import { api } from '../../configurations/api';
import {
    getFestivalHomeItems,
    getFestival,
    getFestivalAwardcategoryUrl,
    awardsUrl,
} from '../../configurations/api/url';
import { httpRequest } from '../types';
import { SentryCapture } from '../../analytics/Sentry';

function* fetchFestivalHome({ resolve, reject }: any): any {
    try {
        const response = yield call(api, getFestivalHomeItems, httpRequest.GET, null, 3, null);
        const { data } = response;
        yield put(fetchFestivalHomeItemsSuccess(data));
        resolve(data);
    } catch (error) {
        SentryCapture(error, 'error');
        yield put(fetchFestivalHomeItemsFail(error));
        reject(error);
    }
}

function* getFestivalDetails({ payload, resolve, reject }: any): any {
    const request = `${getFestival}/${payload}`;
    try {
        const response = yield call(api, request, httpRequest.GET, null, 3, null);
        const { data } = response.data;
        yield put(getFestivalDetailsSuccess(data));
        yield put(getFestivalAwardCategoryAction(payload));
        yield put(getFestivalAwardRecipientsAction(payload));
        resolve(data);
    } catch (error) {
        SentryCapture(error, 'error');
        yield put(getFestivalDetailsFail(error));
        reject(error);
    }
}

function* getFestivals({ resolve, reject }: any): any {
    try {
        const response = yield call(api, getFestival, httpRequest.GET, null, 3, null);
        const { data } = response;
        yield put(getFestivalsSuccess(data));
        resolve(data);
    } catch (error) {
        SentryCapture(error, 'error');
        yield put(getFestivalsFail(error));
        reject(error);
    }
}

function* getFestivalAwardCategory({ payload }: any): any {
    const request = `${getFestivalAwardcategoryUrl}?festival=${payload}`;

    try {
        const response = yield call(api, request, httpRequest.GET, null, 3, null);
        const { data } = response.data;
        yield put(getFestivalAwardCategorySuccess(data));
    } catch (error) {
        yield put(getFestivalAwardCategoryFail(error));
    }
}

function* getFestivalAwardRecipient({ payload }: any): any {
    const request = `${awardsUrl}?festival=${payload}`;

    try {
        const response = yield call(api, request, httpRequest.GET, null, 3, null);
        const { data } = response.data;
        yield put(getFestivalAwardRecipientsSuccess(data));
    } catch (error) {
        SentryCapture(error, 'error');
        yield put(getFestivalAwardRecipientsFail(error));
    }
}

function* fetchFestivalHomeWatcher() {
    yield takeLatest(types.FETCH_FESTIVAL_HOME_ITEMS, fetchFestivalHome);
}

function* getFestivalsWatcher() {
    yield takeLatest(types.GET_FESTIVALS, getFestivals);
}

function* getFestivalsDetails() {
    yield takeLatest(types.GET_FESTIVAL_DETAILS, getFestivalDetails);
}

function* getFestivalAwardCategoryWatcher() {
    yield takeLatest(types.GET_FESTIVAL_AWARD_CATEGORY, getFestivalAwardCategory);
}

function* getFestivalAwardRecipientWatcher() {
    yield takeLatest(types.GET_FESTIVAL_AWARD_RECIPIENTS, getFestivalAwardRecipient);
}

export default function* festivalSaga() {
    yield all([
        fetchFestivalHomeWatcher(),
        getFestivalsWatcher(),
        getFestivalsDetails(),
        getFestivalAwardCategoryWatcher(),
        getFestivalAwardRecipientWatcher(),
    ]);
}
