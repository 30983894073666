import { all, call, put, takeLatest } from 'redux-saga/effects';
import { api } from '../../configurations/api';
import { adopterUrl } from '../../configurations/api/url';
import { saveEmailSuccess, saveEmailFail } from './actions';
import { types } from './types';
import { globalErrorHandler } from '../error/saga';
import { SentryCapture } from '../../analytics/Sentry';
import { showToastMessage } from '../../utils/AppUtils';

function* saveAdopter({ payload, resolve, reject }: any): any {
    try {
        const response = yield call(api, adopterUrl, 'POST', payload);
        const { data } = response;
        yield put(saveEmailSuccess(data.data));
        yield call(showToastMessage, 'Email saved successfully', 'success');
        if (resolve) {
            resolve(response);
        }
    } catch (error: any) {
        SentryCapture(error, 'error');
        yield put(saveEmailFail(error));
        yield call(globalErrorHandler, error);
        if (reject) {
            reject(error);
        }
    }
}

function* saveAdopterWatcher() {
    yield takeLatest(types.SAVE_EMAIL, saveAdopter);
}

export default function* adopterSaga() {
    yield all([saveAdopterWatcher()]);
}
