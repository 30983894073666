import React from 'react';
import { ThemeContext } from '../../module/ThemeManager';
import { Container } from '@mui/material';
import { GenreTagShimmer } from '../GenreTagShimmer';
import { JuryCardShimmer } from '../JuryCardShimmer';

const JuryPageShimmer = () => {
    return (
        <ThemeContext.Consumer>
            {() => {
                return (
                    <Container
                        disableGutters
                        sx={{
                            width: '100%',
                            minHeight: '100vh',
                            marginTop: { xs: '40px', sm: '40px' },
                            marginLeft: { xs: '0px', sm: '65px' },
                            paddingLeft: { xs: '15px', sm: '40px' },
                            paddingRight: { xs: '15px', sm: '40px' },
                            overflowX: 'hidden',
                        }}
                        maxWidth={false}>
                        <GenreTagShimmer />
                        <JuryCardShimmer />
                    </Container>
                );
            }}
        </ThemeContext.Consumer>
    );
};

export { JuryPageShimmer };
