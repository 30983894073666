import produce from 'immer';
import { SplashInitialState, types } from './types';
import { parseSplashImages } from '../../utils/responseProcessor';

const initialState: SplashInitialState = {
    splashImages: [],
    fetching: true,
};

const shuffle = (array: any) => {
    const temp: any = [];
    const originalLength = array.length;
    for (let i = 0; i < originalLength; i++) {
        temp.push([...array.splice(Math.floor(Math.random() * array.length), 1)]);
    }
    return temp.flat();
};

const reducer = (state = initialState, action: any) => {
    const { type, payload } = action;
    switch (type) {
        case types.GET_SPLASH_IMAGES:
            return produce(state, draftState => {
                draftState.fetching = true;
            });
        case types.GET_SPLASH_IMAGES_SUCCESS: {
            const _arrayImages = [];
            for (let i = 0; i < payload.data.length; i++) {
                const parsedImages = parseSplashImages(payload.data[i]);
                _arrayImages.push(parsedImages);
            }
            const scrambledArrayImages = shuffle(_arrayImages);
            return produce(state, draftState => {
                draftState.splashImages = scrambledArrayImages;
                draftState.fetching = false;
            });
        }
        case types.GET_SPLASH_IMAGES_FAILED:
            return produce(state, draftState => {
                draftState.fetching = false;
            });
        default:
            return state;
    }
};

export { reducer };
