import React from 'react';
import { SxProps, Theme } from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';

interface IEsPlayButton {
    onClick?: (...args: any) => void;
    disabled?: boolean;
    sx?: SxProps<Theme>;
    imageStyle?: React.CSSProperties;
    size?: 'large' | 'small';
}
const EsPlayButton: React.FC<IEsPlayButton> = ({ onClick, disabled, sx, imageStyle }) => {
    return (
        <PlayArrowIcon
            onClick={onClick}
            sx={sx}
            style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                zIndex: 10,
                cursor: 'pointer',
                width: '60px',
                height: '60px',
                borderRadius: '50%',
                backgroundColor: `rgba(0,0,0,0.5)`,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                color: 'white',
                padding: '10px',
                border: `1px solid grey`,
                ...imageStyle,
            }}
        />
    );
};

export { EsPlayButton };
