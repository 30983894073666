import React from 'react';
import { StyleSheet } from 'react-native';
import { Container } from '@mui/system';
import { Box, Typography } from '@mui/material';
import { theme } from '../../constants/theme';
import { help } from './data';
import { FONT_ENUM } from '../../types/global/fonts.types';
import { FooterContainer } from '../../components/ScreenContainer/FooterScreenContainer/FooterContainer';
import { useThemeContext } from '../../hook/useThemeManager';
import FAQItem from '../../components/Collapsible/Collapsible';

const styles = StyleSheet.create({
    container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
    },
    header: {
        fontFamily: FONT_ENUM.BOLD_HEADER,
        fontSize: 20,
    },
    paragraphs: {
        fontFamily: FONT_ENUM.MEDIUM_TEXT,
    },
});

const HelpCenter = () => {
    const { themeStyle } = useThemeContext();
    return (
        <Container disableGutters style={styles.container} maxWidth={false}>
            <FooterContainer>
                <Box sx={{ width: '100%' }}>
                    <Typography
                        sx={{
                            mb: 2,
                            fontSize: {
                                sm: 24,
                                xs: 16,
                                lg: 24,
                            },
                        }}
                        style={{
                            fontFamily: FONT_ENUM.REGULAR_HEADER,
                            color: theme[themeStyle].lightWhite,
                        }}>
                        Help Center
                    </Typography>
                    {help.map((faq, index) => (
                        <FAQItem key={index} question={faq.question} answer={faq.answer} details={faq.details} />
                    ))}
                </Box>
            </FooterContainer>
        </Container>
    );
};

export { HelpCenter };
