import { createAction } from 'redux-act';
import { types } from './types';

export const getCurrentUserTalentProfile = () => ({
    type: types.GET_CURRENT_USER_TALENT_PROFILE,
});
export const getCurrentUserTalentProfileSuccess = (payload: any) => ({
    type: types.GET_CURRENT_USER_TALENT_PROFILE_SUCCESS,
    payload,
});

export const getCurrentUserTalentProfileFail = (payload: any) => ({
    type: types.GET_CURRENT_USER_TALENT_PROFILE_FAIL,
    error: payload,
});

export const getTalentProfileDetails = (payload: any) => ({
    type: types.GET_TALENT_PROFILE_DETAILS,
    payload,
});

export const getTalentProfileDetailsSuccess = (payload: any) => ({
    type: types.GET_TALENT_PROFILE_DETAILS_SUCCESS,
    payload,
});

export const getTalentProfileDetailsFail = (payload: any) => ({
    type: types.GET_TALENT_PROFILE_DETAILS_FAIL,
    error: payload,
});

/** We can use this for searching */
export const getAllTalentProfile = (payload?: any) => ({
    type: types.GET_ALL_TALENT_PROFILES,
    payload,
});

export const getAllTalentProfileSuccess = (payload: any) => ({
    type: types.GET_ALL_TALENT_PROFILES_SUCCESS,
    payload,
});

export const getAllTalentProfileFail = (payload: any) => ({
    type: types.GET_ALL_TALENT_PROFILES_FAIL,
    error: payload,
});

export const createTalentProfile = (payload: any) => ({
    type: types.CREATE_TALENT_PROFILE,
    payload,
});

export const createTalentProfileSuccess = (payload: any) => ({
    type: types.CREATE_TALENT_PROFILE_SUCCESS,
    payload,
});

export const createTalentProfileFail = (payload: any) => ({
    type: types.CREATE_TALENT_PROFILE_FAIL,
    error: payload,
});

export const updateTalentProfile = (payload: any) => ({
    type: types.UPDATE_TALENT_PROFILE,
    payload,
});

export const updateTalentProfileSuccess = (payload: any) => ({
    type: types.UPDATE_TALENT_PROFILE_SUCCESS,
    payload,
});

export const updateTalentProfileFail = (payload: any) => ({
    type: types.UPDATE_TALENT_PROFILE_FAIL,
    error: payload,
});

export const publishTalentProfile = (payload: any) => ({
    type: types.PUBLISH_TALENT_PROFILE,
    payload,
});

export const publishTalentProfileSuccess = (payload: any) => ({
    type: types.PUBLISH_TALENT_PROFILE_SUCCESS,
    payload,
});

export const publishTalentProfileFail = (payload: any) => ({
    type: types.PUBLISH_TALENT_PROFILE_FAIL,
    error: payload,
});

export const unPublishTalentProfile = (payload: any) => ({
    type: types.UNPUBLISH_TALENT_PROFILE,
    payload,
});

export const unPublishTalentProfileSuccess = (payload: any) => ({
    type: types.UNPUBLISH_TALENT_PROFILE_SUCCESS,
    payload,
});

export const unPublishTalentProfileFail = (payload: any) => ({
    type: types.UNPUBLISH_TALENT_PROFILE_FAIL,
    error: payload,
});

export const deleteTalentProfile = () => ({
    type: types.DELETE_TALENT_PROFILE,
});

export const deleteTalentProfileSuccess = (payload: any) => ({
    type: types.DELETE_TALENT_PROFILE_SUCCESS,
    payload,
});

export const deleteTalentProfileFail = (payload: any) => ({
    type: types.DELETE_TALENT_PROFILE_FAIL,
    error: payload,
});

export const deleteSingleImage = (payload: any) => ({
    type: types.DELETE_SINGLE_TALENT_IMAGE,
    payload,
});

export const deleteSingleImageSuccess = (payload: any) => ({
    type: types.DELETE_SINGLE_TALENT_IMAGE_SUCCESS,
    payload,
});

export const deleteSingleImageFail = (payload: any) => ({
    type: types.DELETE_SINGLE_TALENT_IMAGE_FAIL,
    error: payload,
});

export const deleteSingleImageFromGallery = (payload: any) => ({
    type: types.DELETE_SINGLE_IMAGE_FROM_TALENT__GALLERY,
    payload,
});

export const deleteSingleImageFromGallerySuccess = (payload: any) => ({
    type: types.DELETE_SINGLE_IMAGE_FROM_TALENT__GALLERY_SUCCESS,
    payload,
});

export const deleteSingleImageFromGalleryFail = (payload: any) => ({
    type: types.DELETE_SINGLE_IMAGE_FROM_TALENT__GALLERY_FAIL,
    error: payload,
});

export const uploadImage = (payload: any) => ({
    type: types.UPLOAD_IMAGE,
    payload,
});

export const uploadImageSuccess = (payload: any) => ({
    type: types.UPLOAD_IMAGE_SUCCESS,
    payload,
});

export const uploadImageFail = (payload: any) => ({
    type: types.UPLOAD_IMAGE_FAIL,
    error: payload,
});

export const getTalentPastWork = (payload: any) => ({
    type: types.GET_TALENT_PAST_WORK,
    payload,
});

export const getTalentPastWorkSuccess = (payload: any) => ({
    type: types.GET_TALENT_PAST_WORK_SUCCESS,
    payload,
});

export const getTalentPastWorkFail = (payload: any) => ({
    type: types.GET_TALENT_PAST_WORK_FAIL,
    error: payload,
});

export const setProfileForEdit = (payload: any) => ({
    type: types.SET_PROFILE_FOR_EDIT,
    payload,
});

export const addTalentFilter = (payload: any) => ({
    type: types.ADD_TALENT_FILTER,
    payload,
});

export const removeTalentFilter = (payload: any) => ({
    type: types.REMOVE_TALENT_FILTER,
    payload,
});

export const toggleFilter = () => ({
    type: types.TOGGLE_TALENT_FILTER,
});

export const filterTalent = (payload: any) => ({
    type: types.FILTER_TALENT,
    payload,
});

export const getDiscover = createAction(types.GET_DISCOVER);
export const getDiscoverSuccess = createAction<any>(types.GET_DISCOVER_SUCCESS);
export const getDiscoverFail = createAction<any>(types.GET_DISCOVER_FAIL);

export const getActs = createAction(types.GET_ACTS);
export const getActsSuccess = createAction<any>(types.GET_ACTS_SUCCESS);
export const getActsFail = createAction<any>(types.GET_ACTS_FAIL);

export const getSingleAct = createAction(types.GET_SINGLE_ACT);
export const getSingleActSuccess = createAction<any>(types.GET_SINGLE_ACT_SUCCESS);
export const getSingleActFail = createAction<any>(types.GET_SINGLE_ACT_FAIL);

export const getMyActs = createAction(types.GET_MY_ACTS);
export const getMyActsSuccess = createAction<any>(types.GET_MY_ACTS_SUCCESS);
export const getMyActsFail = createAction<any>(types.GET_MY_ACTS_FAIL);

export const createAct = createAction<any>(types.CREATE_ACT);
export const createActSuccess = createAction<any>(types.CREATE_ACT_SUCCESS);
export const createActFail = createAction<any>(types.CREATE_ACT_FAIL);

export const deleteAct = createAction<any>(types.DELETE_ACT);
export const deleteActSuccess = createAction<any>(types.DELETE_ACT_SUCCESS);
export const deleteActFail = createAction<any>(types.DELETE_ACT_FAIL);

export const setTalentDetails = createAction<any>(types.SET_TALENT_DETAILS);
export const resetTalentProfile = createAction(types.RESET_TALENT_PROFILE);
