import { createAction } from 'redux-act';
import { ReportContentType, ReportPayload, types } from './types';
import { IErrorType } from '../../constants/types';

export interface ReportResponse {
    report_type: string;
    resolution_status: string;
    created_at: string;
    _id: string;
    report_title: string;
    report_description: string;
    content: {
        type: string;
        model: string;
    };
    created_by: string;
    user: string;
    createdAt: string;
    updatedAt: string;
    id: string;
}

export interface ISendReport {
    reportType: ReportContentType;
    reportPayload: ReportPayload;
    contentId: string;
}

export const sendReport = createAction<ReportPayload>(types.SEND_REPORT);
export const sendReportSuccess = createAction<ReportResponse>(types.SEND_REPORT_SUCCESS);
export const sendReportFail = createAction<IErrorType>(types.SEND_REPORT_FAIL);

export const openReportModal = createAction<boolean>(types.OPEN_REPORT_MODAL);
