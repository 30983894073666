import React, { useEffect, useState } from 'react';
import { StyleProp, StyleSheet, TextInput as T, TextInputProps, View, ViewStyle, Text } from 'react-native';

import { ColorScheme, theme } from '../../constants/theme';
import { ThemeContext } from '../../module/ThemeManager';
import { Fonts, GenericSize } from '../../module/types';
import { FONT_ENUM } from '../../types/global/fonts.types';
import { LinearGradient } from '../LinearGradient/LinearGradient';
import MaskInput from 'react-native-mask-input';
import { CountrySelector } from '../CountrySelector/CountrySelector';
import { Country } from '../../types/global/generic.types';

type TaskField = { task: string; validator: boolean };

interface ITextInputProps extends TextInputProps {
    style?: StyleProp<ViewStyle>;
    isErrorState?: boolean;
    errorMsg?: string;
    isFilledState?: boolean;
    mainContainerStyle?: StyleProp<ViewStyle>;
    isFocused?: boolean;
    mask?: any;
    isCountrySelector?: boolean;
    fieldTasks?: boolean;
    fieldTaskList?: Array<TaskField>;
    onCountrySelect?: (country: Country) => void;
    onFocus?: () => void;
    onBlur?: () => void;
    selectedCountry?: Country;
}

const TextInput = (props: ITextInputProps) => {
    const {
        style,
        isErrorState,
        errorMsg,
        isFilledState,
        mainContainerStyle,
        mask,
        isCountrySelector,
        fieldTasks,
        fieldTaskList,
        onCountrySelect,
        selectedCountry,
        onFocus,
        onBlur,
    } = props;
    const Input = !!mask ? MaskInput : T;
    const [isFocused, setIsFocused] = useState(false);

    const handleOnFocus = () => {
        setIsFocused(true);
        onFocus && onFocus();
    };

    const handleOnBlur = () => {
        setIsFocused(false);
        onBlur && onBlur();
    };

    useEffect(() => {
        console.log('How many renderes');
    }, []);

    return (
        <ThemeContext.Consumer>
            {({ themeStyle, sizes }) => {
                const { genericSize, font } = sizes;
                const styles = dynamicStyle(themeStyle, genericSize, font);
                const isFilledStateColorStart = isFilledState
                    ? theme[themeStyle].acceptance_gradient.start
                    : theme[themeStyle].placeholderGray50;

                const isFilledStateColorMid = isFilledState
                    ? theme[themeStyle].acceptance_gradient.mid
                    : theme[themeStyle].placeholderGray50;

                const isFilledStateColorMidII = isFilledState
                    ? theme[themeStyle].acceptance_gradient.midII
                    : theme[themeStyle].placeholderGray50;

                const isFilledStateColorEnd = isFilledState
                    ? theme[themeStyle].acceptance_gradient.end
                    : theme[themeStyle].placeholderGray50;

                const isErrorStateColorStart = isErrorState ? theme[themeStyle].secondary_red : isFilledStateColorStart;
                const isErrorStateColorMid = isErrorState ? theme[themeStyle].secondary_red : isFilledStateColorMid;
                const isErrorStateColorMidII = isErrorState ? theme[themeStyle].secondary_red : isFilledStateColorMidII;
                const isErrorStateColorEnd = isErrorState ? theme[themeStyle].secondary_red : isFilledStateColorEnd;

                const fieldTasksValidator = () => {
                    if (isFocused && fieldTasks && fieldTaskList && !isErrorState) {
                        return (
                            <View style={styles.fieldTaskContainer}>
                                {fieldTaskList.map((el, i) => (
                                    <Text
                                        key={i.toString()}
                                        style={el.validator ? styles.taskStyleFilled : styles.taskStyle}>
                                        {el.task} {fieldTaskList[fieldTaskList.length - 1] === el ? '' : ` - `}
                                    </Text>
                                ))}
                            </View>
                        );
                    }
                    return null;
                };
                return (
                    <View style={[mainContainerStyle]}>
                        <LinearGradient
                            gradientColor={[
                                isFocused ? theme[themeStyle].button_gradient.start : isErrorStateColorStart,
                                isFocused ? theme[themeStyle].button_gradient.mid : isErrorStateColorMid,
                                // @ts-ignore
                                isFocused ? theme[themeStyle].button_gradient.midII : isErrorStateColorMidII,
                                isFocused ? theme[themeStyle].button_gradient.end : isErrorStateColorEnd,
                            ]}
                            isFocused={isFocused}
                            start={{ x: 0, y: 0 }}
                            end={{ x: 1, y: 0 }}
                            style={[styles.linearGradient, style]}>
                            <View style={[styles.innerContainer, isCountrySelector && { flexDirection: 'row' }]}>
                                {isCountrySelector && (
                                    <View style={styles.countryPicker}>
                                        <CountrySelector
                                            onCountrySelect={onCountrySelect}
                                            selectedCountry={selectedCountry}
                                        />
                                    </View>
                                )}
                                <Input
                                    {...props}
                                    style={styles.textinput}
                                    onFocus={handleOnFocus}
                                    onBlur={handleOnBlur}
                                />
                            </View>
                        </LinearGradient>
                        {isErrorState && !!errorMsg && (
                            <View style={styles.errHolder}>
                                <Text style={styles.errMsg}>{errorMsg}</Text>
                            </View>
                        )}
                        {fieldTasksValidator()}
                    </View>
                );
            }}
        </ThemeContext.Consumer>
    );
};

TextInput.defaultProps = {
    placeHolder: 'Enter messga here',
    errorMsg: 'Error msg here',
    isErrorState: false,
    isFilledState: false,
};

export { TextInput };

const dynamicStyle = (coloScheme: ColorScheme, genericSize: GenericSize, font: Fonts) =>
    StyleSheet.create({
        linearGradient: {
            minWidth: genericSize._size200,
            borderRadius: 8, // <-- Outer Border Radius
        },
        innerContainer: {
            borderRadius: 8, // <-- Inner Border Radius
            flex: 1,
            margin: 2, // <-- Border Width
            backgroundColor: '#fff',
            justifyContent: 'center',
        },
        textinput: {
            color: theme[coloScheme].secondary_dark,
            height: genericSize._size50,
            fontFamily: FONT_ENUM.MEDIUM_TEXT,
            paddingHorizontal: genericSize._size10,
            // @ts-ignore
            outlineStyle: 'none',
            minWidth: genericSize._size400,
        },
        errMsg: {
            color: theme[coloScheme].secondary_red,
            fontFamily: FONT_ENUM.MEDIUM_TEXT,
            fontSize: font._fontSize12,
        },
        errHolder: {
            paddingHorizontal: genericSize._size10,
        },
        countryPicker: {
            justifyContent: 'center',
        },
        fieldTaskContainer: { marginHorizontal: 2, marginTop: genericSize._size10, flexDirection: 'row' },
        taskStyle: {
            fontSize: 8,
            fontFamily: FONT_ENUM.MEDIUM_TEXT,
            color: theme[coloScheme].grey,
            textTransform: 'uppercase',
        },
        taskStyleFilled: {
            fontSize: 8,
            fontFamily: FONT_ENUM.BOLD_TEXT,
            color: theme[coloScheme].lightWhite,
            textTransform: 'uppercase',
        },
    });
