import { Location } from '../../types/global/generic.types';
import {
    IDisableAccount,
    IGetTopUserPayload,
    IGetUserProfile,
    ILikeActivityPayload,
    IRemoveLikeActivity,
    ISearchUserPayload,
    IUserSearchResult,
    IVerifyEmail,
    types,
} from './types';
import { Audience, ContestActivity, Like, ProjectActivity, TChangePasswordPayload } from './model';
import { IContestAward, IGenericDocumentModifier, IProject, ITopUser, IUserSummary } from '../../types/global/helper';
import { createAction } from 'redux-act';
import { IErrorType } from '../../constants/types';
import { IUpdateUserProfilePayload } from './sagas';
import { ITalentData } from '../talent/types';

export interface IProfile {
    id: '';
    skills: [];
    demo_reel: [];
    audio_reel: [];
    about: '';
    birthDay: '';
    age_from: 0;
    age_to: 0;
    hair_color: '';
    weight: '';
    body_type: '';
    photos: [];
}

export type TUserType =
    | 'actor'
    | 'casting-director'
    | 'producer'
    | 'admin'
    | 'regular'
    | 'critic'
    | 'jury'
    | 'creator'
    | 'editor'
    | 'host';
export interface IUserProfile {
    firstName: string;
    lastName: string;
    email: string;
    _id: string;
    location?: Location;
    role: TUserType;
    userName: string;
    phoneNumber: string;
    phone_verified: boolean;
    email_verified: boolean;
    profile_picture: string;
    feature_image: string;
    user_profile: IProfile;
    permissions: Array<String>;
    user_type?: string[];
    audience: Audience[];
    intro: string;
    job_description: string;
    total_audience_count: number;
    verified: boolean;
    education: string;
    home_town: Location;
    application_limit: number;
    casting_call_limit: number;
    active_subscription: string;
    social?: Social;
    projects: IProject[];
    project_activities: ProjectActivity[];
    contest_activities: ContestActivity[];
    user_follow_interaction?: Audience[];
    user_following?: boolean;
    follows_user?: boolean;
    feature_image_hash?: string;
    profile_picture_hash?: string;
    awards?: IContestAward[];
    talent_saved?: boolean;
    saved_talent?: [ITalentData?];
}

export interface Social {
    boxofix: string;
    tik_tok: string;
    instagram: string;
    twitter: string;
}

export interface IUserInitialState {
    userProfile: IUserProfile;
    /** The below I put in place since I'm not certain about what userProfile is currently used for
     * The below will include all details for teh current user
     */
    currentUserProfile?: IUserProfile;
    verifyingPhoneNumber: boolean;
    phoneCodeSent: boolean;
    phoneCodeFailureSendingErrorMessage: any;
    phoneVerificationComplete: boolean;
    createUserProfileErrorMessage: any;
    creatingUserProfile: boolean;
    phoneVerificationFailedErrorMessage: any;
    userSuccessfullyCreated: boolean;
    validatingFieldValue: boolean;
    isEmailValid: boolean;
    emailValidationMessage: any;
    isPhoneNumberValid: boolean;
    phoneValidationMessage: any;
    emailAndPhoneValidationDone: boolean; //TODO: remove deprecated field
    emailValidationDone: boolean;
    phoneValidationDone: boolean;
    gettingPasswordResetToken: boolean;
    getPasswordResetTokenMessage: string;
    resettingPassword: boolean;
    resetPasswordMessage: string;
    gettingUserProfile: boolean;
    gettingUserProfileErrorMessage: any;
    validatingUsernameValue: boolean;
    usernameAvailable: boolean;
    usernameValidationMessage: string;
    usernameValidationDone: boolean;
    usernameTaken: boolean;
    usernameSearchErr: boolean;
    userSearchResult: IUserSummary[];
    searchingUser?: boolean;
    searchingUserErrMsg?: any;
    externalUserProfile?: IUserProfile;
    gettingExternalUserProfile: boolean;
    gettingExternalUserProfileErrorMessage: any;
    updatingUserProfile: boolean;
    gettingUser?: boolean;
    gettingUserErr?: any;
    topUsers?: ITopUser[];
    gettingTopUser?: boolean;
    gettingTopUserErrMsg?: any;
    changingPassword?: boolean;
    verifyingEmail?: boolean;
    initializingEmailVerification?: boolean;
    emailVerificationSentForSession?: boolean;
    verifyingEmailErrorMsg?: string;
    testUsers: IUserSummary[];
}

export interface IFieldAvailabilityData {
    request: { phoneNumber?: string; email?: string; userName?: string };
    field: 'phoneNumber' | 'email' | 'userName';
}

export type TUserField = 'phoneNumber' | 'email' | 'userName';

export const createUserProfile = (request: any) => ({
    type: types.CREATE_USER_PROFILE,
    payload: request,
});

export const createUserProfileSuccess = (userProfile: IUserProfile) => ({
    type: types.CREATE_USER_PROFILE_SUCCESS,
    payload: userProfile,
});

export const createUserProfileFailed = (error: any) => ({
    type: types.CREATE_USER_PROFILE_FAILED,
    payload: error,
});

export const saveUserDeviceToken = (token: any) => ({
    type: types.SAVE_USER_DEVICE_TOKEN,
    payload: token,
});

export const saveUserDeviceTokenSuccess = () => ({
    type: types.SAVE_USER_DEVICE_TOKEN_SUCCESS,
});

export const saveUserDeviceTokenFailed = (error: any) => ({
    type: types.SAVE_USER_DEVICE_TOKEN_FAILED,
    payload: error,
});

export const startPhoneVerification = (phoneCode: any) => ({
    type: types.PHONE_NUMBER_VERIFICATION,
    payload: phoneCode,
});

export const phoneNumberVerificationSuccess = () => ({
    type: types.PHONE_NUMBER_VERIFICATION_SUCCESS,
});

export const phoneNumberVerificationFailed = (error: any) => ({
    type: types.PHONE_NUMBER_VERIFICATION_FAILED,
    payload: error,
});

export const getPhoneVerificationCode = (phoneNumber: any) => ({
    type: types.GET_PHONE_VERIFICATION_CODE,
    payload: phoneNumber,
});

export const getPhoneVerificationCodeFailed = (error: any) => ({
    type: types.GET_PHONE_VERIFICATION_CODE_FAILED,
    payload: error,
});

export const getPhoneVerificationCodeSuccess = () => ({
    type: types.GET_PHONE_VERIFICATION_CODE_SUCCESS,
});

// TODO: remove deprecated actions
export const startEmailAndPhoneAvailabilitySearch = (payload: any) => ({
    type: types.START_EMAIL_FIELD_VERIFICATION,
    payload,
});

export const startUserDetailsAvailabilitySearch = (payload: IFieldAvailabilityData) => ({
    type:
        payload.field === 'email'
            ? types.START_EMAIL_FIELD_VERIFICATION2
            : payload.field === 'phoneNumber'
            ? types.START_PHONE_FIELD_VERIFICATION2
            : types.START_USERNAME_FIELD_VERIFICATION,
    payload,
});

export const userDetailValidationSuccess = (field: TUserField) => ({
    type:
        field === 'email'
            ? types.EMAIL_FIELD_VERIFICATION_SUCCESS2
            : field === 'phoneNumber'
            ? types.PHONE_FIELD_VERIFICATION_SUCCESS2
            : types.USERNAME_AVAILABLE,
});

export const userDetailValidationFailed = (field: TUserField, error: any) => ({
    type:
        field === 'email'
            ? types.EMAIL_FIELD_VERIFICATION_FAILED2
            : field === 'phoneNumber'
            ? types.PHONE_FIELD_VERIFICATION_FAILED2
            : types.USERNAME_FIELD_VERIFICATION_FAILED,
    payload: error,
});

export const userNameTaken = () => ({
    type: types.USERNAME_TAKEN,
});
export const userNameFieldReset = () => ({
    type: types.USERNAME_FIELD_RESET,
});

export const emailFieldValidationSuccess = () => ({
    type: types.EMAIL_FIELD_VERIFICATION_SUCCESS,
});

export const emailFieldValidationFailed = (error: any) => ({
    type: types.EMAIL_FIELD_VERIFICATION_FAILED,
    payload: error,
});

export const getUserProfile = createAction<IGetUserProfile>(types.GET_USER_PROFILE);

export const getExternalUserProfile = (username: string) => ({
    type: types.GET_A_USER_PROFILE,
    payload: username,
});

export const getExternalUserProfileSuccess = (profile: any) => ({
    type: types.GET_A_USER_PROFILE_SUCCESS,
    payload: profile,
});

export const getExternalUserProfileFailed = (error: any) => ({
    type: types.GET_A_USER_PROFILE_FAIL,
    payload: error,
});

export const getUserProfileSuccess = (profile: any) => ({
    type: types.GET_USER_PROFILE_SUCCESS,
    payload: profile,
});

export const resetUserProfile = () => ({
    type: types.RESET_USER_PROFILE,
});

export const getUserProfileFailed = (error: any) => ({
    type: types.GET_USER_PROFILE_FAILED,
    payload: error,
});

export const getResetPasswordToken = (email: any) => ({
    type: types.GET_RESET_PASSWORD_TOKEN,
    payload: email,
});

export const getResetPasswordTokenSuccess = () => ({
    type: types.GET_RESET_PASSWORD_TOKEN_SUCCESS,
});

export const getResetPasswordTokenFailed = (error: any) => ({
    type: types.GET_RESET_PASSWORD_TOKEN_FAILED,
    payload: error,
});

export const resetPassword = (payload: any) => ({
    type: types.RESET_PASSWORD,
    payload,
});

export const resetPasswordSuccess = () => ({
    type: types.RESET_PASSWORD_SUCCESS,
});

export const resetPasswordFailed = (error: any) => ({
    type: types.RESET_PASSWORD_FAILED,
    payload: error,
});

export const searchUser = createAction<ISearchUserPayload>(types.SEARCH_USER);
export const searchUserSuccess = createAction<IUserSearchResult[]>(types.SEARCH_USER_SUCCESS);
export const seacrhUserFail = createAction<IErrorType>(types.SEARCH_USER_FAIL);
export const updateUserProfile = createAction<any>(types.UPDATE_USER_PROFILE);
export const updateUserProfileSuccess = createAction<IUpdateUserProfilePayload>(types.UPDATE_USER_PROFILE_SUCCESS);
export const updateUserProfileFailed = createAction(types.UPDATE_USER_PROFILE_FAILED);
export const getCurentUserProfile = createAction(types.GET_CURRENT_USER_PROFILE);
export const getCurentUserProfileSuccess = createAction<IUserProfile>(types.GET_CURRENT_USER_PROFILE_SUCCESS);
export const getCurentUserProfileFail = createAction<IErrorType>(types.GET_CURRENT_USER_PROFILE_FAIL);

export const updateActivityLike = createAction<ILikeActivityPayload>(types.UPDATE_ACTIVITY_LIKE);
export const updateActivityLikeSuccess = createAction<{
    activity: ProjectActivity | ContestActivity;
    like: Like;
    isExternalProfile: boolean;
}>(types.UPDATE_ACTIVITY_LIKE_SUCCESS);
export const updateActivityLikeFailed = createAction<{
    activity: ProjectActivity | ContestActivity;
    isExternalProfile: boolean;
}>(types.UPDATE_ACTIVITY_LIKE_FAILED);

export const removeActivityLike = createAction<IRemoveLikeActivity>(types.REMOVE_ACTIVITY_LIKE);
export const removeActivityLikeSuccess = createAction<{
    activity: ProjectActivity | ContestActivity;
    isExternalProfile: boolean;
}>(types.REMOVE_ACTIVITY_LIKE_SUCCESS);
export const removeActivityLikeFailed = createAction<IRemoveLikeActivity>(types.REMOVE_ACTIVITY_LIKE_FAILED);

export const getTopUser = createAction<IGetTopUserPayload>(types.GET_TOP_USER);
export const getTopUserSuccess = createAction<ITopUser[]>(types.GET_TOP_USER_SUCCESS);
export const getTopUserFail = createAction<IErrorType>(types.GET_TOP_USER_FAIL);

export const changePassword = createAction<TChangePasswordPayload>(types.CHANGE_PASSWORD);
export const changePasswordSuccess = createAction(types.CHANGE_PASSWORD_SUCCESS);
export const changePasswordFailed = createAction<IErrorType>(types.CHANGE_PASSWORD_FAILED);

export const initiateEmailVerify = createAction(types.INITIATE_EMAIL_VERIFY);
export const initiateEmailVerifySuccess = createAction(types.INITIATE_EMAIL_VERIFY_SUCCESS);
export const initiateEmailVerifyFail = createAction(types.INITIATE_EMAIL_VERIFY_FAIL);

export const verifyEmail = createAction<IVerifyEmail>(types.VERIFY_EMAIL);
export const verifyEmailSuccess = createAction(types.VERIFY_EMAIL_SUCCESS);
export const verifyEmailFail = createAction<string>(types.VERIFY_EMAIL_FAIL);

export const addTestUser = createAction<IUserSummary>(types.ADD_TEST_USER);
export const removeTestUser = createAction<IGenericDocumentModifier>(types.REMOVE_TEST_USER);

export const disableAccount = createAction<IDisableAccount>(types.DISABLE_ACCOUNT);
export const disableAccountSuccess = createAction(types.DISABLE_ACCOUNT_SUCCESS);
export const disableAccountFail = createAction<IErrorType>(types.DISABLE_ACCOUNT_FAIL);
