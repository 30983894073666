import React from 'react';
import { CategorySuggestionShimmer } from '../CategorySuggestionShimmer';
import { MainCategoryShimmer } from '../MainCategoryShimmer/MainCategoryShimmer';

const SearchContainerShimmer = () => {
    return (
        <>
            <MainCategoryShimmer />
            <CategorySuggestionShimmer />
        </>
    );
};

export { SearchContainerShimmer };
