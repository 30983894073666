export const types = {
    SET_CACHE: 'SET_CACHE',
    CLEAR_CACHE: 'CLEAR_CACHE',
    FETCH_CACHE: 'FETCH_CACHE',
    NUKE: 'NUKE',
    DESTROY_ONE_CACHE: 'DESTROY_ONE_CACHE',
    /** This will check the last time that cache was cleared. If it has been more than 3 hours, clear */
    CHECK_NUKE_TIME: 'CHECK_NUKE_TIME',
};

export type CacheTypes =
    | 'user'
    | 'discovery'
    | 'comments'
    | 'likes'
    | 'dashboard'
    | 'contest'
    | 'project'
    | 'act'
    | 'activity'
    | 'searchSuggestion'
    | 'search'
    | 'filter'
    | 'comingSoon'
    | 'trending'
    | 'userSearch'
    | 'userProjects'
    | 'userAppDetails'
    | 'externalProfile'
    | 'juryCampaigns'
    | 'campaignSubmissions'
    | 'userSubmission'
    | 'userProjectLikes'
    | 'userSingleLike'
    | 'userCommentLikes'
    | 'projectViews'
    | 'userViews'
    | 'voterId'
    | 'userVotes'
    | 'userNotifications'
    | 'userFollowing'
    | 'userAudience'
    | 'bookmark'
    | 'feed'
    | 'userContests'
    | 'host'
    | 'partner'
    | 'hostSearch'
    | 'partnerSearch'
    | 'termsOfUse'
    | 'redeemableCode'
    | 'scoreBoard'
    | 'jurors'
    | 'talent'
    | 'talentSearch'
    | 'projectDetails'
    | 'unsubmittedProjects'
    | 'playlist';

export enum CACHE_TYPE {
    USER = 'user',
    DISCOVERY = 'discovery',
    COMMENTS = 'comments',
    LIKES = 'likes',
    DASHBOARD = 'dashboard',
    CONTEST = 'contest',
    PROJECT = 'project',
    PROJECT_DETAILS = 'projectDetails',
    ACT = 'act',
    ACTIVITY = 'activity',
    SEARCH_SUGGESTION = 'searchSuggestion',
    SEARCH = 'search',
    FILTER = 'filter',
    COMING_SOON = 'comingSoon',
    TRENDING = 'trending',
    USER_SEARCH = 'userSearch',
    USER_PROJECTS = 'userProjects',
    USER_APP_DETAILS = 'userAppDetails',
    EXTERNAL_PROFILE = 'externalProfile',
    JURY_CAMPAIGNS = 'juryCampaigns',
    CAMPAIGN_SUBMISSIONS = 'campaignSubmissions',
    USER_SUBMISSIONS = 'userSubmission',
    USER_PROJECT_LIKES = 'userProjectLikes',
    USER_SINGLE_LIKE = 'userSingleLike',
    USER_COMMENT_LIKES = 'userCommentLikes',
    PROJECT_VIEWS = 'projectViews',
    USER_VIEWS = 'userViews',
    VOTER_ID = 'voterId',
    USER_VOTES = 'userVotes',
    USER_NOTIFICATIONS = 'userNotifications',
    USER_FOLLOWING = 'userFollowing',
    USER_AUDIENCE = 'userAudience',
    BOOKMARK = 'bookmark',
    FEED = 'feed',
    USER_CONTESTS = 'userContests',
    HOST = 'host',
    PARTNER = 'partner',
    HOST_SEARCH = 'hostSearch',
    PARTNER_SEARCH = 'partnerSearch',
    TERMS_OF_USE = 'termsOfUse',
    REDEEMABLE_CODE = 'redeemableCode',
    SCORE_BOARD = 'scoreBoard',
    JURORS = 'jurors',
    TALENT = 'talent',
    TALENT_SEARCH = 'talentSearch',
    UNSUBMITTED_PROJECTS = 'unsubmittedProjects',
    PLAYLIST = 'playlist',
}

export interface ISetCache {
    key: any;
    value: any;
    type: CacheTypes;
    isUnique?: boolean;
}

export interface IClearCache {
    key: string | number;
    cacheType?: CacheTypes;
}

export interface IDestroyCache {
    cacheType: CacheTypes;
}

export interface IGetCache {
    key: string | number;
}

export interface CacheValue {
    key: string | number;
    value: any;
}

export interface NotUniqueCacheValue {
    [key: string]: {
        key: string | number;
        value: any;
    };
}

export type ICacheInitialState = {
    storage?: {
        [key in CacheTypes]: CacheValue;
    };
};
