import styled from 'styled-components';
import { StyleSheet } from 'react-native';
import { ColorScheme, theme } from '../../constants/theme';
import { Fonts, GenericSize } from '../../module/types';
import { FONT_ENUM } from '../../types/global/fonts.types';
import { ScreenTypes } from '../../module/ThemeManager';

interface IStyledCarousel {
    backgroundImage?: string;
}

export const StyledCarousel = styled.image<IStyledCarousel>`
    width: '100%';
    height: 40vh;
    background-image: url(${({ backgroundImage }) => backgroundImage});
    background-size: cover;
    border-bottom-width: 1;
    border-radius: 2rem;
    overflow: hidden;
    border-color: '#fff';
    background-color: 'blue';
    @media (max-width: 768px) {
        height: 30vh;
        border-radius: 0rem;
    }
`;

export const StyledGradientToTransparent = styled.div`
    width: '100%';
    height: 40vh;
    background: rgb(0, 0, 0);
    overflow: hidden;
    background: linear-gradient(
        90deg,
        rgba(0, 0, 0, 1) 0%,
        rgba(0, 0, 0, 0.8) 0%,
        rgba(0, 0, 0, 0.9) 0%,
        rgba(0, 0, 0, 0) 100%
    );
    align-items: center;
    justify-content: center;
    @media (max-width: 768px) {
        height: 30vh;
    }
`;

export const dynamicStyles = (colorScheme: ColorScheme, genericSize: GenericSize, font: Fonts, device: ScreenTypes) =>
    StyleSheet.create({
        container: {
            justifyContent: 'center',
            alignItems: 'flex-start',
            height: '100%',
            width: '100%',
            padding: genericSize._size24,
        },
        headerText: {
            fontFamily: FONT_ENUM.MEDIUM_HEADER,
            fontSize: 32,
            color: theme[colorScheme].lightWhite,
        },
        aboutText: {
            fontFamily: FONT_ENUM.MEDIUM_TEXT,
            fontSize: 16,
            color: theme[colorScheme].lightWhite,
            width: device.isMobile ? '100%' : '60%',
        },
        buttonHolder: {
            flexDirection: !device.isDesktop ? 'column' : 'row',
            justifyContent: 'space-between',
            marginVertical: genericSize._size24,
        },
    });
