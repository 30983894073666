export const types = {
    SEND_REPORT: 'SEND_REPORT',
    SEND_REPORT_SUCCESS: 'SEND_REPORT_SUCCESS',
    SEND_REPORT_FAIL: 'SEND_REPORT_FAIL',

    OPEN_REPORT_MODAL: 'OPEN_REPORT_MODAL',
};

export type ReportType = 'app_feedback' | 'content_related' | 'user';
export type ReportContentType = 'User' | 'Audition' | 'Acts' | 'Profile' | 'Sound' | 'Project';
export type ReportContent = {
    type: ReportContentType;
    model: string;
};

export type ReportPayload = {
    report_title: string;
    report_type: ReportType;
    report_description: string;
    content: ReportContent;
};
