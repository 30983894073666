import produce from 'immer';

import { IFestivalInitialState, types } from './types';
import {
    parseAwardRecipients,
    parseFestivalAwardCategories,
    parseFestivalDetails,
    parseFestivalHome,
} from '../../utils/responseProcessor';

const initialState: IFestivalInitialState = {
    festivals: [],
    selectedFestival: undefined,
    fetchingFestivals: false,
    fetchingFestivalErrorMsg: null,
    festivalHomeItems: [],
    fetchingFestivalHomeItems: false,
    gettingFestivalDetails: false,
    selectedFestivalAwardCategories: [],
    selectedFestivalAwardRecipient: [],
    fetchingFestivalAwardCategory: false,
    fetchingFestivalAwardRecipient: false,
    fetchingAwardErrorMsg: null,
};

const reducer = (state = initialState, action: any) => {
    const { type, payload } = action;

    switch (type) {
        case types.GET_FESTIVALS:
            return produce(state, (draftState: typeof initialState) => {
                draftState.fetchingFestivals = true;
            });
        case types.GET_FESTIVALS_SUCCESS:
            return produce(state, (draftState: typeof initialState) => {
                draftState.festivals = payload;
                draftState.fetchingFestivals = false;
            });
        case types.GET_FESTIVALS_FAIL:
            return produce(state, (draftState: typeof initialState) => {
                draftState.fetchingFestivals = false;
                draftState.fetchingFestivalErrorMsg = payload;
            });
        case types.FETCH_FESTIVAL_HOME_ITEMS:
            return produce(state, (draftState: typeof initialState) => {
                draftState.fetchingFestivalHomeItems = true;
            });
        case types.FETCH_FESTIVAL_HOME_ITEMS_SUCCESS:
            return produce(state, (draftState: typeof initialState) => {
                draftState.fetchingFestivalHomeItems = false;
                draftState.festivalHomeItems = parseFestivalHome(payload.festival[0]);
            });
        case types.FETCH_FESTIVAL_HOME_ITEMS_FAIL:
            return produce(state, (draftState: typeof initialState) => {
                draftState.fetchingFestivalHomeItems = false;
                draftState.fetchingFestivalErrorMsg = payload;
            });
        case types.GET_FESTIVAL_DETAILS:
            return produce(state, (draftState: typeof initialState) => {
                draftState.gettingFestivalDetails = true;
            });
        case types.GET_FESTIVAL_DETAILS_SUCCESS:
            return produce(state, (draftState: typeof initialState) => {
                draftState.selectedFestival = parseFestivalDetails(payload);
                draftState.gettingFestivalDetails = false;
            });
        case types.GET_FESTIVAL_DETAILS_FAIL:
            return produce(state, (draftState: typeof initialState) => {
                draftState.gettingFestivalDetails = false;
                draftState.fetchingFestivalErrorMsg = payload;
            });
        case types.GET_FESTIVAL_AWARD_CATEGORY:
            return produce(state, (draftState: typeof initialState) => {
                draftState.fetchingFestivalAwardCategory = true;
            });
        case types.GET_FESTIVAL_AWARD_CATEGORY_SUCCESS:
            return produce(state, (draftState: typeof initialState) => {
                draftState.fetchingFestivalAwardCategory = false;
                const parsedCategories = parseFestivalAwardCategories(payload);
                draftState.selectedFestivalAwardCategories = parsedCategories as any;
            });
        case types.GET_FESTIVAL_AWARD_CATEGORY_FAIL:
            return produce(state, (draftState: typeof initialState) => {
                draftState.fetchingFestivalAwardCategory = false;
                draftState.fetchingAwardErrorMsg = payload;
            });
        case types.GET_FESTIVAL_AWARD_RECIPIENTS:
            return produce(state, (draftState: typeof initialState) => {
                draftState.fetchingFestivalAwardRecipient = true;
            });
        case types.GET_FESTIVAL_AWARD_RECIPIENTS_SUCCESS:
            return produce(state, (draftState: typeof initialState) => {
                draftState.fetchingFestivalAwardRecipient = false;
                const parsed = parseAwardRecipients(payload);
                draftState.selectedFestivalAwardRecipient = parsed as any;
            });
        case types.GET_FESTIVAL_AWARD_RECIPIENTS_FAIL:
            return produce(state, (draftState: typeof initialState) => {
                draftState.fetchingFestivalAwardRecipient = false;
                draftState.fetchingAwardErrorMsg = payload;
            });
        default:
            return state;
    }
};

export { reducer };
