import { StyleSheet } from 'react-native';
import { useThemeContext } from '../../hook/useThemeManager';
import { ColorScheme, theme } from '../../constants/theme';
import { FormControl, Typography, Box, FormLabel } from '@mui/material';
import { EsFormWrapper } from './EsFormWrapper';
import { FONT_ENUM } from '../../types/global/fonts.types';
import { strings } from '../../constants/strings';

type UserData = {
    email: string;
    password: string;
    firstName: string;
    lastName: string;
    userName: string;
    birth: string;
    gender: string;
    country: string;
    phoneNumber: string;
};

const EsConfirm = ({ email, firstName, lastName, userName, birth, gender, country, phoneNumber }: UserData) => {
    const { themeStyle } = useThemeContext();
    const styles = dynamicStyles(themeStyle);

    return (
        <EsFormWrapper title="Confirm your data">
            <FormControl style={{ width: '100%', marginTop: '20px' }}>
                <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' } }}>
                    <Box style={styles.flexColumn} sx={{ width: '100%' }}>
                        <FormLabel style={styles.title}>{strings.email_address}</FormLabel>
                        <Typography style={styles.text}>{email}</Typography>
                    </Box>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, gap: { sm: '20px' } }}>
                    <Box style={styles.flexColumn} sx={{ width: '100%' }}>
                        <FormLabel style={styles.title}>{strings.firstName}</FormLabel>
                        <Typography style={{ ...styles.text, textTransform: 'capitalize' }}>{firstName}</Typography>
                    </Box>
                    <Box style={styles.flexColumn} sx={{ width: '100%' }}>
                        <FormLabel style={styles.title}>{strings.lastName}</FormLabel>
                        <Typography style={{ ...styles.text, textTransform: 'capitalize' }}>{lastName}</Typography>
                    </Box>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' } }}>
                    <Box style={styles.flexColumn} sx={{ width: '100%' }}>
                        <FormLabel style={styles.title}>{strings.username}</FormLabel>
                        <Typography style={styles.text}>{userName}</Typography>
                    </Box>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, gap: { sm: '20px' } }}>
                    <Box style={styles.flexColumn} sx={{ width: '100%' }}>
                        <FormLabel style={styles.title}>{strings.date_of_birth}</FormLabel>
                        <Typography style={{ ...styles.text, textTransform: 'capitalize' }}>
                            {new Date(birth).toLocaleDateString()}
                        </Typography>
                    </Box>
                    <Box style={styles.flexColumn} sx={{ width: '100%' }}>
                        <FormLabel style={styles.title}>{strings.gender}</FormLabel>
                        <Typography style={{ ...styles.text, textTransform: 'capitalize' }}>{gender}</Typography>
                    </Box>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row', marginBottom: '20px' } }}>
                    <Box style={styles.flexColumn} sx={{ width: '100%' }}>
                        <FormLabel style={styles.title}>{strings.phone_number}</FormLabel>
                        <Typography style={styles.text}>+{phoneNumber}</Typography>
                    </Box>
                </Box>
            </FormControl>
        </EsFormWrapper>
    );
};

const dynamicStyles = (colorScheme: ColorScheme) =>
    StyleSheet.create({
        text: {
            maxWidth: 400,
            width: '100%',
            textAlign: 'left',
            fontSize: 14,
            fontStyle: 'normal',
            fontWeight: '400',
            color: theme[colorScheme].form_label,
            marginBottom: 10,
            fontFamily: FONT_ENUM.MAIN_REGULAR,
        },
        title: {
            maxWidth: 400,
            width: '100%',
            textAlign: 'left',
            fontSize: 18,
            fontStyle: 'normal',
            fontWeight: '600',
            color: theme[colorScheme].lightWhite,
            fontFamily: FONT_ENUM.MAIN_MEDIUM,
        },
        flexColumn: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'center',
            height: 'fit-content',
        },
    });

export { EsConfirm };
