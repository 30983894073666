import React from 'react';
import { ThemeContext } from '../../module/ThemeManager';
import { Box, Stack, Skeleton } from '@mui/material';
import { theme } from '../../constants/theme';

const RequirementsShimmer = () => {
    return (
        <ThemeContext.Consumer>
            {({ themeStyle }) => {
                return (
                    <Stack marginBottom={6} marginTop={7}>
                        <Box position="relative">
                            <Skeleton
                                variant="rounded"
                                width="100%"
                                sx={{
                                    backgroundColor: theme[themeStyle].gray_text,
                                    borderRadius: 5,
                                    height: { xs: '440px', md: '371px', lg: '371px' },
                                }}
                            />
                            <Skeleton
                                variant="text"
                                sx={{
                                    fontSize: '1rem',
                                    width: '120px',
                                    backgroundColor: theme[themeStyle].darkGray60,
                                    position: 'absolute',
                                    left: { xs: 40, md: 40, lg: 40 },
                                    top: 40,
                                }}
                            />
                            <Skeleton
                                variant="text"
                                sx={{
                                    fontSize: '1rem',
                                    width: '170px',
                                    backgroundColor: theme[themeStyle].darkGray60,
                                    position: 'absolute',
                                    top: 60,
                                    left: { xs: 40, md: 40, lg: 40 },
                                }}
                            />
                            <Skeleton
                                variant="text"
                                sx={{
                                    fontSize: '1rem',
                                    width: '200px',
                                    backgroundColor: theme[themeStyle].darkGray60,
                                    position: 'absolute',
                                    top: 80,
                                    left: { xs: 40, md: 40, lg: 40 },
                                }}
                            />
                            <Skeleton
                                variant="text"
                                sx={{
                                    fontSize: '1rem',
                                    width: '200px',
                                    backgroundColor: theme[themeStyle].darkGray60,
                                    position: 'absolute',
                                    bottom: { xs: 140, sm: 85, md: 85, lg: 120 },
                                    left: { xs: 40, md: 40, lg: 40 },
                                }}
                            />
                            <Skeleton
                                variant="text"
                                sx={{
                                    fontSize: '1rem',
                                    width: '170px',
                                    backgroundColor: theme[themeStyle].darkGray60,
                                    position: 'absolute',
                                    bottom: { xs: 120, sm: 65, md: 65, lg: 140 },
                                    left: { xs: 40, md: 40, lg: 40 },
                                }}
                            />
                            <Skeleton
                                variant="text"
                                sx={{
                                    fontSize: '1rem',
                                    width: '120px',
                                    backgroundColor: theme[themeStyle].darkGray60,
                                    position: 'absolute',
                                    bottom: { xs: 100, sm: 45, md: 45, lg: 160 },
                                    left: { xs: 40, md: 40, lg: 40 },
                                }}
                            />
                        </Box>
                    </Stack>
                );
            }}
        </ThemeContext.Consumer>
    );
};

export { RequirementsShimmer };
