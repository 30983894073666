import { createAction } from 'redux-act';
import { ActivityResponse, ISaveSearchActivityPayload, SearchSuggestion, types } from './types';
import { IErrorType } from '../../constants/types';
import { IGenericDocumentModifier } from '../../types/global/helper';

export const saveSearchActivity = createAction<ISaveSearchActivityPayload>(types.SAVE_ACTIVITY);
export const saveSearchActivitySuccess = createAction<ActivityResponse>(types.SAVE_ACTIVITY_SUCCESS);
export const saveSearchActivitFail = createAction<IErrorType>(types.SAVE_ACTIVITY_FAIL);

export const getMyActivities = createAction(types.GET_MY_ACTIVITIES);
export const getMyActivitiesSuccess = createAction<ActivityResponse[]>(types.GET_MY_ACTIVITIES_SUCCESS);
export const getMyActivitiesFail = createAction<IErrorType>(types.GET_MY_ACTIVITIES_FAIL);

export const getSearchSuggestions = createAction(types.GET_SEARCH_SUGGESTIONS);
export const getSearchSuggestionsSuccess = createAction<SearchSuggestion[]>(types.GET_SEARCH_SUGGESTIONS_SUCCESS);
export const getSearchSuggestionsFail = createAction<IErrorType>(types.GET_SEARCH_SUGGESTIONS_FAIL);

export const hideMyActivity = createAction<IGenericDocumentModifier>(types.HIDE_MY_ACTIVITY);
export const hideMyActivitySuccess = createAction<ActivityResponse>(types.HIDE_MY_ACTIVITY_SUCCESS);
export const hideMyActivityFail = createAction<IErrorType>(types.HIDE_MY_ACTIVITY_FAIL);
export const resetActivityData = createAction(types.RESET_ACTIVITY_DATA);
