interface IStringConstants {
    [key: string]: string;
}

export const en_strings: IStringConstants = {
    signin: 'I already have an account',
    signup_text: "I'm new here",
    create_account: 'Create Account',
    sign_in_now: 'Sign-In-now',
    create_an_account: 'Create an Account',
    name: 'Name (Full name)',
    your_email_address: 'Your email address',
    phone_number: 'Phone Number',
    create_a_password: 'Create a password',
    already_have_an_account: 'Already have an account?',
    terms: 'By signing in, you agree to',
    conditions: "Thspian's Conditions of use",
    splashScreenSubText: 'Audition. Movies. Reviews',
    boxofix: 'Boxofix',
    sign_in: 'Sign In',
    password: 'Password',
    new_to_thspian: 'New to Thspian?',
    forgot_password: 'Forgot password?',
    select_country_code: 'Select your country code',
    email_address: 'Email address',
    reset_password: 'Reset Password',
    enter_new_password: 'Enter new password',
    confirm_password: 'Confirm password',
    submit: 'Submit',
    reset_successful: 'Reset Link Sent',
    reset_link: 'We have sent an email to your_email with a link to reset your password',
    if_you_have_not: 'If you have not received an email after 10 minutes, please check your spam folder or try again.',
    confirm: 'Confirm',
    enter_phone_code: 'Enter the six (6) digit verification code sent to your phone',
    email_error: 'email is not valid',
    phone_error: 'phone number is not valid',
    name_error: 'please enter your first and last name',
    password_confirm_error: 'passwords do not match, please try again',
    existing_account:
        'There is already an existing account with that email or phone number, please sign in or did you forget your password?',
    sign_in_with_email: 'Sign In with Email',
    sign_in_with_phone_number: 'Sign In with Phone Number',
    phone_sign_in_error_msg: 'phone number not valid',
    email_sign_in_error_msg: 'email not valid',
    resend_code: 'Resend Code',
    similar_movies: 'Similar Movies',
    watch: 'Watch',
    trailer: 'TRAILER',
    summary: 'Summary',
    release_date: 'Release date',
    director: 'Director',
    writer: 'Writer',
    budget: 'Budget',
    opening_weekend: 'Opening Weekend',
    gross: 'Gross',
    add_review: 'Add a review',
    like_this: 'Add to Watch List',
    share: 'Share',
    see_all_reviews: 'See all user reviews',
    cast: 'Cast',
    user_review: 'User reviews',
    boxofix_rating: 'Boxofix rating',
    your_rating: 'Your rating',
    rate_this: 'Rate this',
    liked: 'Added to Watch List',
    search: 'Search',
    searchPlaceHolder: 'Search Movies, Awards and Actors',
    movies: 'Movies',
    actors: 'Actors & Actresses',
    awards: 'Awards & Events',
    talent: 'Talents',
    search_talents: 'Search Talents',
    type_message: 'Type your message here...',
    contribute: 'Contribute to this page',
    edit_page: 'Edit page',
    select_audition_role: 'Select an audition role e.g Video Vixen...',
    select_audition_location: 'Select a location...',
    age: 'Age',
    required_media: 'Required Media',
    gender: 'Gender',
    character_description: 'Character Description',
    apply_role: 'Apply for Role',
    profile: 'Profile',
    watchlist: 'Watchlist',
    force_signin:
        'Sign in to access BOXOFIX features including, casting roles, latest movie update, review movies and more',
    sign_in_sign_up: 'Sign in / Sign up',
    dismiss: 'Dismiss',
    default_button_name: 'Button Title',
    account: 'Account',
    my_profile: 'Casting Profile',
    productions: 'Productions',
    contributions: 'Contributions',
    trial: '7 days free trial with any plan!',
    unlimited: 'Unlimited casting posting',
    secure: 'Access talents and casts',
    t_four: '24/7 priority support',
    no_ad: 'No application limits',
    preference: 'Preference',
    your_preferences: 'Your preferences',
    filter: 'Filter',
    no_card: 'Oops!!! No card yet',
    add_card_sub: 'Add a new card to enable you transact on Boxofix',
    add_new_card: 'Add new card',
    wallet: 'Wallet',
    delete_card: 'Delete Card',
    new_card: 'New Card Added',
    card_already_exists: 'Card Already Exists',
    action_cancelled: 'Action was cancelled',
    next_billing_date: 'Next Billing Date',
    payment_method: 'Payment Method',
    last_payment: 'Last Payment',
    change: 'Change',
    cancel_subscription: 'Cancel Subscription',
    cancel_warning: 'Your current subscription will be cancelled, if you proceed',
    go_back: 'Go back',
    proceed: 'Proceed',
    subscription_details: 'Subscription details',
    subscription_cancel: 'Your #plan subscription has cancelled successfully. Your plan will run till #date',
    cancelBackButton: 'Back to Accounts',
    subscription_action: 'Subscription action',
    subscription_caution: 'Do you wish to continue?',
    subscription_success:
        'You have successfully subscribed to Boxofix. Any Active subscriptions will be disabled and replaced',
    subscribe: 'Subscribe',
    max_application: 'Applications Maxed Out.',
    max_app_body: 'You have reached the maximum allowed applications with Regular subscription, feel free to upgrade.',
    no_subscription: 'No Subscription',
    no_sub_body: "You don't have any active subscription",
    no_actor_profile_body: 'Please complete your talent profile to apply to auditions',
    no_actor_profile: 'No Talent Profile',
    complete_profile: 'Complete Profile',
    save_role: 'Save Role',
    apply: 'Apply',
    applicants: 'applicants',
    production_description: 'Production Description',
    casting_roles: 'Casting Roles',
    job_details: 'Job Details',
    language: 'Language',
    expires: 'Expires',
    rehearsal_date: 'Rehearsal Date',
    tags: 'Tags',
    compensation: 'Compensation',
    tbd: 'TBD',
    casting_agency: 'Casting Agency',
    attachments: 'Attachments',
    similar_roles: 'Similar Roles',
    saved: 'Saved',
    applied: 'Applied',
    add_new_festival: 'Add new award / festival',
    search_movies: 'Search Movies, TV Shows ...',
    search_actors: 'Search Actors, Actresses, TV Personalities ...',
    search_awards: 'Search Events, Festivals, Awards ...',
    boxofix_page: 'Boxofix page',
    boxofix_page_description:
        'Create a movie bio, including casts and crew, awards, issues and news around the project.',
    casting_call: 'Casting Call',
    casting_call_description:
        'Publish an audition notice. Reach thousands of new talents and get your project off the ground',
    actors_biography: 'Actor biography',
    actors_biography_description:
        "Create an actors or actress's biography, including past work, awards, photos, accomplishments. e.t.c",
    talent_profile: 'Talent profile',
    talent_profile_description: 'Create your talent profile to allow casting agencies, and directors to find you',
    search_crew: 'Search people, crew roles e.g gaffer ...',
    search_award: 'Search award category, people e.g Best picture ...',
    login_or_signup: 'Login / SignUp',
    first_name: 'First Name',
    last_name: 'Last Name',
    firstName: 'First Name',
    lastName: 'Last Name',
    upload_video: 'Upload Video or Picture',
    apply_filter: 'Apply filter',
    clear_filters: 'Clear filters',
    all_filters: 'All filters',
    page_not_found: 'It looks like you are lost in space',
    back: 'Back',
    my_rating: 'My rating',
    continue: 'Continue',
    contest_description: 'Contest description',
    about_event: 'About Event',
    judges: 'Judges',
    create_contest: 'Create contest',
    more: 'More',
    no_submissions: 'No submissions yet',
    intro: 'Intro',
    edit_bio: 'Edit bio',
    joined_this_contest: 'joined this contest',
    recent_activities: 'Recent activities',
    commented_on_this_video: 'commented on this video',
    coming_soon: 'Coming Soon',
    live_contest: 'Live contest',
    my_videos: 'My videos',
    recent_submissions: 'Recent submissions',
    recommendations: 'Recommendations',
    top_performers: 'Top performers',
    recently_watched: 'Recently watched',
    statistics: 'Statistics',
    total_no_of_votes: 'Total no of votes',
    total_no_of_registration: 'Total registrations',
    submissions: 'Submissions',
    duration: 'Time Left',
    edit_profile: 'Edit profile',
    add_cover: 'Add cover',
    create_project: 'Upload Video or Picture',
    comedy: 'Comedy',
    pg_13: 'PG-13',
    friends: 'friends',
    protected_page: "This page is protected by Google reCAPTCHA to ensure you're not a bot.",
    learn_more: 'Learn more.',
    set_new_password: 'Set new password',
    password_change_success: ' Password change successful!',
    password_changed: 'Your password has been changed! You will be redirected to the login screen',
    reset_link_sent: 'We sent you a reset link',
    email_me: 'Email me',
    enter_email_to_reset_password: `Enter the email address associated with your account and we’ll send you a link to reset your
    password`,
    current_password: ' Current password',
    settings: 'Settings',
    change_password: 'Change password',
    use_secure_password: ` When changing your password, we strongly recommend using a secure password you don't use
    anywhere else.`,
    notifications: 'Notifications',
    subscriptions: 'Subscriptions',
    recommended_videos: 'Recommended videos',
    mentions: 'Mentions',
    shared_content: 'Shared content',
    filter_by: 'Filter by',
    reset: 'Reset',
    show_results: 'Show results',
    see_all: 'See all',
    menu: 'MENU',
    personal: 'PERSONAL',
    username: 'Username',
    date_of_birth: 'Date of Birth',
    sign_up: 'Sign up',
    otp_verify_vote: 'An active WhatsApp™ number is required.',
    questions_contact_us: 'Questions? Contact us',
    playlist: 'Playlist',
    cookie_preference: 'Cookie preference',
    drop_to_create_project: 'Drop here to create project',
    faq: 'Frequently Asked Question',
    help_center: 'Help center',
    privacy_policy: 'Privacy policy',
    shop: 'Shop',
    terms_of_use: 'Terms of use',
    recent_projects: 'Recent projects',
    submission_status: 'Submission status',
    my_projects: 'My Projects',
    select_judges: 'Select Judges',
    requirements: 'Requirements',
    prize: 'Prize',
    drop_file_to_upload: 'Drop file here to upload',
    trending: 'Trending',
};

export type IStrings = typeof en_strings;
