import { WebNotification } from '../account/accountDetailsResponse.type';

export const types = {
    SET_NOTIFICATION: 'SET_NOTIFICATIONS',

    READ_NOTIFICATION: 'READ_NOTIFICATIONS',
    READ_NOTIFICATION_SUCCESS: 'READ_NOTIFICATIONS_SUCCESS',
    READ_NOTIFICATION_FAIL: 'READ_NOTIFICATIONS_FAIL',

    DELETE_NOTIFICATION: 'DELETE_NOTIFICATIONS',
    DELETE_NOTIFICATION_SUCCESS: 'DELETE_NOTIFICATIONS_SUCCESS',
    DELETE_NOTIFICATION_FAIL: 'DELETE_NOTIFICATIONS_FAIL',

    GET_MY_NOTIFICATIONS: 'GET_MY_NOTIFICATIONS',
    GET_MY_NOTIFICATIONS_SUUCESS: 'GET_MY_NOTIFICATIONS_SUCCESS',
    GET_MY_NOTIFICATIONS_FAIL: 'GET_MY_NOTIFICATIONS_FAIL',
    RESET_NOTIFICATION_DATA: 'RESET_NOTIFICATION_DATA',

    UPDATE_NOTIFICATION: 'UPDATE_NOTIFICATION',
    UPDATE_NOTIFICATION_SUCCESS: 'UPDATE_NOTIFICATION_SUCCESS',
    UPDATE_NOTIFICATION_FAIL: 'UPDATE_NOTIFICATION_FAIL',

    RECEIEVE_NOTIFICATION: 'RECEIEVE_NOTIFICATION',
};

export interface INotificatonInitialState {
    notificationCount: number;
    notifications: WebNotification[];
    gettingNotifications: boolean;
    gettingNotificationsError: any;
    notification_navigation: boolean;
    navigation_route: any;
    readingNotification?: boolean;
    readingNotificationsError?: any;
}
