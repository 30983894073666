import React, { useCallback, useEffect, useState } from 'react';
import { Fade, Typography, Collapse, Stack } from '@mui/material';
//import { makeStyles } from '@mui/styles';
import { FONT_ENUM } from '../../types/global/fonts.types';
import { useThemeContext } from '../../hook/useThemeManager';
import { theme } from '../../constants/theme';
import { styled } from '@mui/material/styles';
import Badge from '@mui/material/Badge';
import { MediaForgeReport } from '../../redux/project/types';

export const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        backgroundColor: '#44b700',
        color: '#44b700',
        boxShadow: `0 0 0 0.5px ${theme.palette.background.paper}`,
        '&::after': {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            borderRadius: '50%',
            animation: 'ripple 1.2s infinite ease-in-out',
            border: '0.1px solid currentColor',
            content: '""',
        },
    },
    '@keyframes ripple': {
        '0%': {
            transform: 'scale(.8)',
            opacity: 1,
        },
        '100%': {
            transform: 'scale(2.4)',
            opacity: 0,
        },
    },
}));

export interface CustomModalProps {
    text?: string;
    isVisible: boolean;
    onClosePress: () => void;
    isDone?: boolean;
    mediaForge?: MediaForgeReport;
}

export interface IMediaConsolRef {
    current: any;
}

/** The below component will be primarily used to showcase the status of a project
 * when uploading new content. The idea is that we keep this active until mediaForge
 * has completed it's process and then it closes itself.
 */
const MediaReportConsole = React.forwardRef<IMediaConsolRef, CustomModalProps>((props, ref) => {
    // const classes = useStyles();
    const { isVisible, onClosePress, mediaForge } = props;
    const { themeStyle } = useThemeContext();
    const [message, setMessage] = useState('Processing Project ...');

    useEffect(() => {
        const timer = setTimeout(() => {
            onClosePress();
        }, 3000); // Change the duration here (in milliseconds) for how long the modal will display

        return () => clearTimeout(timer);
    }, [onClosePress]);

    useEffect(() => {
        handleSetMessage();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mediaForge?.status]);

    const handleSetMessage = useCallback(() => {
        switch (mediaForge?.status) {
            case 'awaiting processing':
                setMessage('Upload complete...Awaiting processing...🙂');
                break;
            case 'completed':
                setMessage('Processing complete ... Closing this modal ... 🥳');
                break;
            case 'dormant':
                break;
            case 'failed':
                setMessage('Processing Failed ... Try again ❌');
                break;
            case 'initiated':
                setMessage('Upload started ... 🎬 ');
                break;
            case 'upload complete':
                setMessage('Upload Complete ... Awaiting processing ...');
                break;
            default:
                break;
        }
    }, [mediaForge?.status]);

    return (
        <Fade in={isVisible}>
            <Collapse in={isVisible}>
                <Stack direction="row" alignItems="center">
                    <StyledBadge
                        overlap="circular"
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                        variant="dot"
                        sx={{
                            mx: 1,
                        }}
                    />
                    <Typography
                        style={{
                            fontFamily: FONT_ENUM.REGULAR_HEADER,
                            fontSize: 14,
                            color: theme[themeStyle].tag_white,
                            padding: 12,
                            outline: 'none',
                            borderRadius: 10,
                        }}>
                        {message}
                    </Typography>
                </Stack>
            </Collapse>
        </Fade>
    );
});

export { MediaReportConsole };
