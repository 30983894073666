import { Box, Skeleton } from '@mui/material';
import { animated } from '@react-spring/web';
import { View } from 'react-native';
import { Blurhash } from 'react-blurhash';
import styled from 'styled-components';

/** Use the components in this file as the  */
export const AnimatedView = animated(View);
export const AnimatedBox = animated(Box);

export const StyledBlurhash = styled(Blurhash)`
    z-index: 20;
    position: absolute !important;
    top: 0;
    left: 0;
`;
export const StyledSkeleton = styled(Skeleton)`
    z-index: 20;
    position: absolute !important;
    top: 0;
    left: 0;
`;
