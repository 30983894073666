import React from 'react';
import { StyleSheet } from 'react-native';
import { Box, Typography } from '@mui/material';
import { useThemeContext } from '../../hook/useThemeManager';
import { ColorScheme, theme } from '../../constants/theme';
import { FONT_ENUM } from '../../types/global/fonts.types';
import { IMediaCard, MediaCard } from '../EsCards/MediaCard';
import { IProjectSummary } from '../../types/global/media.types';
import { isEmpty } from '../../utils/lodash';

export interface IWatchGrid extends Partial<Omit<IMediaCard, 'media'>> {
    mediaData?: IProjectSummary[];
    categoryTitle?: string;
}

const WatchGrid: React.FC<IWatchGrid> = props => {
    const { mediaData, categoryTitle } = props;
    const { themeStyle } = useThemeContext();
    const styles = dynamicStyles(themeStyle);

    return (
        <Box
            style={{
                width: '100%',
                height: 'fit-content',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                gap: '36px',
            }}>
            <Typography
                style={styles.text}
                sx={{
                    mx: {
                        xs: 2,
                        sm: 0,
                    },
                }}>
                {categoryTitle}
            </Typography>
            <Box
                sx={{
                    gridTemplateColumns: {
                        xs: '100%',
                        sm: 'repeat(2, 1fr)',
                        md: 'repeat(3, 1fr)',
                        lg: 'repeat(4, 1fr)',
                    },
                    width: '100%',
                    height: 'fit-content',
                    display: 'grid',
                    gap: '10px',
                    gridAutoRows: '1fr',
                }}>
                {mediaData &&
                    !isEmpty(mediaData) &&
                    mediaData.map((item, index) => <MediaCard media={item} key={index} {...props} />)}
            </Box>
        </Box>
    );
};

const dynamicStyles = (colorScheme: ColorScheme) =>
    StyleSheet.create({
        text: {
            textAlign: 'left',
            fontSize: 24,
            fontStyle: 'normal',
            fontWeight: '600',
            color: theme[colorScheme].filters_white,
            fontFamily: FONT_ENUM.MAIN_BOLD,
            textTransform: 'capitalize',
        },
    });

export { WatchGrid };
