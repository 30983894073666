import { IUserSummary } from '../../types/global/helper';

export const types = {
    GET_SAVED_TALENTS: 'GET_SAVED_TALENTS',
    GET_SAVED_TALENT_SUCCESS: 'GET_SAVED_TALENT_SUCCESS',
    GET_SAVED_TALENT_FAIL: 'GET_SAVED_TALENT_FAIL',

    SAVE_TALENT: 'SAVE_TALENT',
    SAVE_TALENT_SUCCESS: 'SAVE_TALENT_SUCCESS',
    SAVE_TALENT_FAIL: 'SAVE_TALENT_FAIL',

    UPDATE_TALENT: 'UPDATE_TALENT',
    UPDATE_TALENT_SUCCESS: 'UPDATE_TALENT_SUCCESS',
    UPDATE_TALENT_FAIL: 'UPDATE_TALENT_FAIL',

    SEARCH_TALENT: 'SEARCH_TALENT',
    SEARCH_TALENT_SUCCESS: 'SEARCH_TALENT_SUCCESS',
    SEARCH_TALENT_FAIL: 'SEARCH_TALENT_FAIL',

    DELETE_TALENT: 'DELETE_TALENT',
    DELETE_TALENT_SUCCESS: 'DELETE_TALENT_SUCCESS',
    DELETE_TALENT_FAIL: 'DELETE_TALENT_FAIL',
};

export interface ITalentInitialState {
    talents: ITalentData[];
    loadingTalent: boolean;
    errMsg: string | null;
    talentSearchData: ITalentData[];
    deletingTalent: boolean;
    savingTalent: boolean;
    updatingTalent: boolean;
    searchingTalent: boolean;
}

export interface ILocation {
    address: string;
    description: string;
    coordinates: number[];
    type: string;
}

export interface ITalent {
    _id: string;
    firstName: string;
    lastName: string;
    userName: string;
    gender: string;
    __v: number;
    user_type: string[];
    location: ILocation;
    verified: boolean;
    interest: any[]; // Define more specific type if available
    updatedAt: string;
}

export interface ISaveTalent {
    talent: string;
    tags: string[];
    note: string;
}

export interface ITalentData {
    _id: string;
    talent: IUserSummary;
    created_by: string;
    created_at: string;
    tags: string[];
    note: string;
    createdAt: string;
    updatedAt: string;
    __v: number;
}

export interface IData {
    data: ITalentData[];
}

export interface IApiResponse {
    status: string;
    results: number;
    data: IData;
}
export interface ITalentSearchResponse {
    result: ITalentData[];
    total_result_count: number;
    matched_users: ITalent[];
}
