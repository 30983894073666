import { StyleSheet, View } from 'react-native';
import { EsSignupContainer } from '../../components/ScreenContainer/EsSignupContainer';
import { SignUpdata } from '../../components/EsSignupForm/EsSignupForm';
import { useDispatchPromise } from '../../utils/AppUtils';
import {
    IFieldAvailabilityData,
    TUserField,
    createUserProfile,
    getPhoneVerificationCode,
    startUserDetailsAvailabilitySearch,
} from '../../redux/user/actions';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../types/global/routes.types';
import { userActionSelector } from '../../selectors/selectors';
import { useSelector } from 'react-redux';
import { useState } from 'react';

const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
});

const EsSignup = () => {
    const dispatch = useDispatchPromise();
    const navigate = useNavigate();
    const { usernameTaken } = useSelector(userActionSelector);
    const [loading, setLoading] = useState(false);
    const [otpLoading, setOtpLoading] = useState(false);
    const [resendInProgress, setResendInProgress] = useState(false);

    const onSubmitSignUpOTP = (data: SignUpdata, code?: string[]) => {
        const payload = {
            ...data,
            code: code?.join(''),
            country: 'US',
            passwordConfirmation: data.password,
            birth: new Date(data.birth).toLocaleDateString(),
        };
        setOtpLoading(true);
        dispatch(createUserProfile(payload))
            .then(() => {
                setOtpLoading(false);
                navigate(ROUTES.ESDISCOVERY);
            })
            .catch(e => {
                setOtpLoading(false);
            });
    };

    const checkUserDataAvailability = (data: SignUpdata, next: () => void, field: TUserField) => {
        const payload: IFieldAvailabilityData =
            field === 'email'
                ? { field: 'email', request: { email: data.email } }
                : field === 'phoneNumber'
                ? { field: 'phoneNumber', request: { phoneNumber: data.phoneNumber } }
                : { field: 'userName', request: { userName: data.userName } };
        if (field === 'phoneNumber' && usernameTaken) return;
        setLoading(true);
        dispatch(startUserDetailsAvailabilitySearch(payload))
            .then(() => {
                setLoading(false);
                if (field !== 'userName') next();
            })
            .catch(e => {
                setLoading(false);
            });
    };

    const getSignUpOTP = (data: SignUpdata, next: () => void, resend?: boolean): void | Promise<unknown> => {
        const payload = { phoneNumber: data.phoneNumber, email: data.email, resendToken: resend };
        if (resend) {
            setResendInProgress(true);
            return dispatch(getPhoneVerificationCode(payload))
                .then(() => {
                    setResendInProgress(false);
                })
                .catch(e => {
                    setResendInProgress(false);
                });
        } else {
            setLoading(!loading);
            dispatch(getPhoneVerificationCode(payload))
                .then(() => {
                    setLoading(!loading);
                    next();
                })
                .catch(e => {
                    setLoading(!loading);
                    console.log(e);
                });
        }
    };

    const handleLoadingState = () => setLoading(false);

    const userNameAvailable = (userName: string) => {
        if (userName.replaceAll(' ', '') !== '') {
            const data = { userName: userName } as SignUpdata;
            checkUserDataAvailability(data, handleLoadingState, 'userName');
        }
    };
    return (
        <View style={styles.container}>
            <EsSignupContainer
                getSignUpOTP={getSignUpOTP}
                userNameAvailable={userNameAvailable}
                onSubmitSignUpOTP={onSubmitSignUpOTP}
                checkUserDataAvailability={checkUserDataAvailability}
                otpLoading={otpLoading}
                loading={loading}
                resendInProgress={resendInProgress}
            />
        </View>
    );
};

export { EsSignup };
