import React from 'react';
import { Box, Typography } from '@mui/material';
import { StyleSheet } from 'react-native';
import discovery from '../../data/mock/disovery.json';
import { EsTrendingCard, ITrendingCardProps } from '../EsCards/EsTrendingCard';
import { IContest } from '../../types/global/helper';
import { ColorScheme, theme } from '../../constants/theme';
import { FONT_ENUM } from '../../types/global/fonts.types';
import { useThemeContext } from '../../hook/useThemeManager';
import { isEmpty } from '../../utils/lodash';

export interface ISearchGridProps extends Partial<Omit<ITrendingCardProps, 'trendingItem'>> {
    searchResult?: IContest[];
    isFiltered?: boolean;
    title?: string;
}

const SearchGrid: React.FC<ISearchGridProps> = props => {
    const { searchResult, title } = props;
    const { themeStyle } = useThemeContext();
    const styles = dynamicStyles(themeStyle);

    return (
        <Box
            style={{
                width: '100%',
                height: 'auto',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                justifyContent: 'flex-start',
                gap: '36px',
            }}>
            <Typography style={styles.text}>{title}</Typography>
            <Box
                style={{ width: '100%', height: 'fit-content', display: 'grid', gap: '30px', gridAutoRows: '1fr' }}
                sx={{ gridTemplateColumns: { xs: '100%', sm: 'repeat(2, 1fr)' } }}>
                {searchResult &&
                    !isEmpty(searchResult) &&
                    searchResult.map((item, index) => <EsTrendingCard trendingItem={item} key={index} {...props} />)}
            </Box>
        </Box>
    );
};

SearchGrid.defaultProps = {
    searchResult: discovery.data.contests[0].features as unknown as IContest[],
};

const dynamicStyles = (colorScheme: ColorScheme) =>
    StyleSheet.create({
        text: {
            textAlign: 'left',
            fontSize: 24,
            fontStyle: 'normal',
            fontWeight: '600',
            color: theme[colorScheme].filters_white,
            fontFamily: FONT_ENUM.MAIN_BOLD,
        },
    });

export { SearchGrid };
