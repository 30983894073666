import { call, put, takeLatest, select, all, delay, take, fork, cancel } from 'redux-saga/effects';
import {
    startUpload as startUploadAction,
    completeMultiPartUpload as completdMultiPartUploadAction,
    reportProgress,
    getUploadSessionFail,
    getUploadSessionSuccess,
    completeMultiPartUploadSuccess,
    completeMultiPartUploadFail,
    startUploadFail,
    saveUpload,
    resumeUpload,
    pauseUpload,
    stopNetworkWatch,
} from './action';
import {
    initiateMultipartDownloadUrl,
    getMultiPartSignedUrl,
    completeMultiPartSignedUrl,
} from '../../configurations/api/url';
import { api } from '../../configurations/api';
import {
    ICompleteMultiPartUpload,
    IInitializeUploadSession,
    IUploadInitialState,
    IUploadPayload,
    types,
} from './types';
import { httpRequest } from '../types';
import { Action } from 'redux';
import { RootState } from '../root-reducer';
import { mediaForgeWorker, uploadProjectMedia } from '../project/actions';
import { isOnline, watchNetworkStatus } from '.';
import { SentryCapture } from '../../analytics/Sentry';

const MAX_RETRIES = 3;
const RETRY_DELAY = 2000; // 2 seconds delay before retrying
const PAUSE_MESSAGE = 'Upload paused due to network strength. Will resume shortly';

function* initiateUploadSession({ payload }: { payload: IInitializeUploadSession }): Generator<any, any, any> {
    try {
        /** You must have created a project before doing this as you will need the project ID */
        const { projectId, project_file, initialPayload } = payload;
        /** Initiate the upload and fetch the uploadId & Key */
        /** When successful => Proceed to call uploadFile With Details */
        const response = yield call(api, `${initiateMultipartDownloadUrl}/${projectId}`, httpRequest.GET, null);
        const { data } = response;
        yield put(getUploadSessionSuccess(data));
        if (project_file) {
            yield put(
                startUploadAction({
                    file: project_file!,
                    videoKey: data.videoKey,
                    uploadId: data.uploadId,
                    initialPayload: initialPayload,
                    projectId,
                }),
            );
        }
    } catch (error: any) {
        yield put(getUploadSessionFail(error));
    }
}

function* startUpload({ payload }: { payload: IUploadPayload }): any {
    const networkTask = yield fork(monitorNetwork);
    const { file, uploadId, videoKey, initialPayload, projectId } = payload;
    const chunkSize = 5.5 * 1024 * 1024; // 5.5 MB
    const totalChunks = Math.ceil(file.size / chunkSize);
    let state: RootState = yield select();
    let { currentChunkIndex, uploadParts, paused } = state.upload;

    try {
        /** Initiate MediaForge MessageBoard */
        let chunkIndex = currentChunkIndex || 0; // Start from last saved index if resumed
        let uploadedParts = uploadParts || [];

        while (chunkIndex < totalChunks) {
            const start = chunkIndex * chunkSize;
            const end = Math.min(start + chunkSize, file.size);
            const fileChunk = file.slice(start, end);

            if (paused) {
                while (paused) {
                    yield take(types.RESUME_UPLOAD); // Wait for the RESUME_UPLOAD action
                    const state = yield select(); // Fetch the updated state
                    paused = state.upload.paused; // Check if still paused
                }
            }

            // Check if we're online before requesting the presigned URL
            if (!isOnline()) {
                yield put(pauseUpload('Upload paused due to network strength. Will resume shortly')); // Automatically pause the upload when offline
                yield take(types.RESUME_UPLOAD); // Wait until the resume action is triggered
            }

            const { data } = yield call(
                api,
                `${getMultiPartSignedUrl}/${projectId}?uploadId=${uploadId}&partNumber=${
                    chunkIndex + 1
                }&videoUploadKey=${videoKey}`,
                httpRequest.GET,
                null,
            );

            // Retry logic for uploading the chunk
            let retries = 0;
            let uploadSuccess = false;

            while (retries < MAX_RETRIES && !uploadSuccess) {
                try {
                    // Before uploading, check if we're online
                    if (!isOnline()) {
                        yield put(pauseUpload(PAUSE_MESSAGE)); // Automatically pause the upload
                        yield take(types.RESUME_UPLOAD); // Wait until the resume action is triggered
                    }
                    const res = yield call(uploadChunkToS3, data.url, fileChunk);
                    const eTag = res.headers.get('ETag');
                    // Keep track of the uploaded part
                    uploadedParts = [...uploadedParts, { ETag: eTag, PartNumber: chunkIndex + 1 }];
                    uploadSuccess = true; // Upload succeeded
                } catch (error) {
                    retries++;
                    if (retries < MAX_RETRIES) {
                        yield delay(RETRY_DELAY); // Wait before retrying
                    } else {
                        throw new Error(`Failed to upload chunk ${chunkIndex + 1} after ${MAX_RETRIES} retries`);
                    }
                }
            }

            // Dispatch progress update

            yield put(
                reportProgress({
                    chunk: chunkIndex + 1,
                    progress: ((chunkIndex + 1) / totalChunks) * 100,
                    totalChunk: totalChunks,
                }),
            );

            // Save current chunk index to Redux for resuming
            yield put(
                saveUpload({
                    currentChunkIndex: chunkIndex + 1,
                    uploadedParts,
                }),
            );

            let state: RootState = yield select();
            let { upload }: { upload: IUploadInitialState } = state;
            // Check if upload is paused
            if (upload.paused) {
                yield take(types.RESUME_UPLOAD);
                state = yield select();
                paused = state.upload.paused;
                // return;
            }
            chunkIndex++;
        }

        // Complete the multipart upload after all chunks are uploaded
        yield put(
            completdMultiPartUploadAction({
                filename: videoKey,
                parts: uploadedParts,
                uploadId,
                initialPayload,
                projectId,
            }),
        );
    } catch (error: any) {
        yield put(startUploadFail(error));
        yield put(
            mediaForgeWorker({
                status: 'failed',
                mediaForgeMessage: 'Error Uploading your file',
            }),
        );
        SentryCapture(error, 'error');
    } finally {
        // Stop watching the network once the upload is done or aborted
        yield cancel(networkTask); // Cancels the network monitoring saga
    }
}

function* completdMultiPartUpload({ payload }: { payload: ICompleteMultiPartUpload }): Generator<any, any, any> {
    try {
        const { filename, parts, uploadId, initialPayload, projectId } = payload;
        const requestPayload = {
            file: filename,
            uploadId,
            parts,
        };
        const response = yield call(api, completeMultiPartSignedUrl, httpRequest.POST, requestPayload, 5, 2000);
        const { data } = response;
        yield put(completeMultiPartUploadSuccess(data));
        /** Update the video again and trigger MeiaForge */
        yield put(
            uploadProjectMedia({
                videoKey: filename,
                projectId: projectId!,
                project: initialPayload?.project_file!,
                feature_image: initialPayload?.feature_image,
            }),
        );

        yield put(stopNetworkWatch());
    } catch (error: any) {
        yield put(completeMultiPartUploadFail(error));
        yield put(
            mediaForgeWorker({
                status: 'failed',
                mediaForgeMessage: 'Error Uploading your file',
            }),
        );
        SentryCapture(error, 'error');
    }
}

// Saga to monitor the network state
function* monitorNetwork() {
    // Function to handle network online event
    const onOnline = function* () {
        yield put(resumeUpload(undefined)); // Dispatch action to resume upload
    };

    // Function to handle network offline event
    const onOffline = function* () {
        yield put(pauseUpload(PAUSE_MESSAGE)); // Dispatch action to pause upload
    };

    // Start watching for network status changes (register event listeners)
    const cleanup = watchNetworkStatus(
        () => onOnline(), // When online, dispatch resumeUpload
        () => onOffline(), // When offline, dispatch pauseUpload
    );

    // Optionally, you can wait for a specific action to stop monitoring network status
    yield take(types.STOP_WATCHING_NETWORK); // When STOP_WATCHING_NETWORK action is triggered
    cleanup(); // Cleanup network event listeners
}

async function uploadChunkToS3(presignedUrl: string, fileChunk: Blob) {
    const res = await fetch(presignedUrl, {
        method: 'PUT',
        body: fileChunk,
    });
    if (!res.ok) {
        throw new Error('Chunk upload failed');
    }
    return res;
}

interface StartUploadAction extends Action {
    payload: IUploadPayload;
}

interface InitiateUploadAction extends Action {
    payload: IInitializeUploadSession;
}

interface ICompleteUploadAction extends Action {
    payload: ICompleteMultiPartUpload;
}

// Watch for network status and trigger resume when online
// function* networkWatcher() {
//     while (true) {
//         // Race between detecting online/offline events and waiting for the upload to resume
//         const { online } = yield race({
//             online: take(types.RESUME_UPLOAD),
//             offline: take(types.PAUSE_UPLOAD),
//         });

//         if (online) {
//             yield put(resumeUpload(undefined)); // Dispatch action to resume upload
//         }
//     }
// }

function* startUploadWatch() {
    yield takeLatest<StartUploadAction>(types.START_UPLOAD, startUpload);
    yield takeLatest<StartUploadAction>(types.RESUME_UPLOAD_AFTER_RELOAD, startUpload);
    // yield call(networkWatcher);
    yield takeLatest(types.TERMINATE_UPLOAD, function* () {
        yield cancel(); // Cancels the entire operation if cancel action is dispatched
    });
}

function* initiateUplaodSessionWatcher() {
    yield takeLatest<InitiateUploadAction>(types.GET_UPLOAD_SESSION, initiateUploadSession);
}

function* completeMultipartUploadWatcher() {
    yield takeLatest<ICompleteUploadAction>(types.COMPLETE_MULTIPART_UPLOAD, completdMultiPartUpload);
}

export default function* talentSaga() {
    yield all([startUploadWatch(), initiateUplaodSessionWatcher(), completeMultipartUploadWatcher()]);
}
