import { Selector } from 'react-redux';
import { RootState } from '../../redux/root-reducer';
import { createSelector } from 'reselect';
import { DiscoverySearchUnparsedResponse } from '../../types/global/generic.types';
import { IContest, IProject, UserSummary } from '../../types/global/helper';

export const searchResultSelector: Selector<RootState, DiscoverySearchUnparsedResponse | undefined> = createSelector(
    (state: RootState) => state.discovery,
    search => search.searchResultsInView,
);

export const suggestionsSelector: Selector<
    RootState,
    {
        suggestedContest: IContest[] | undefined;
        suggestedTalents: UserSummary[] | undefined;
        suggestedProjects: IProject[] | undefined;
    }
> = createSelector(
    (state: RootState) => state.discovery,
    search => ({
        suggestedContest: search.suggestedContest,
        suggestedProjects: search.suggestedProjects,
        suggestedTalents: search.suggestedTalents,
    }),
);
