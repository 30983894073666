import { types } from './types';

export const getFestivals = () => ({
    type: types.GET_FESTIVALS,
});

export const getFestivalsSuccess = (payload: any) => ({
    type: types.GET_FESTIVALS_SUCCESS,
    payload,
});

export const getFestivalsFail = (payload: any) => ({
    type: types.GET_FESTIVALS_FAIL,
    error: payload,
});

export const fetchFestivalHomeItems = () => ({
    type: types.FETCH_FESTIVAL_HOME_ITEMS,
});

export const fetchFestivalHomeItemsSuccess = (payload: any) => ({
    type: types.FETCH_FESTIVAL_HOME_ITEMS_SUCCESS,
    payload,
});

export const fetchFestivalHomeItemsFail = (payload: any) => ({
    type: types.FETCH_FESTIVAL_HOME_ITEMS_FAIL,
    error: payload,
});

export const getFestivalDetails = (payload: string) => ({
    type: types.GET_FESTIVAL_DETAILS,
    payload,
});

export const getFestivalDetailsSuccess = (payload: any) => ({
    type: types.GET_FESTIVAL_DETAILS_SUCCESS,
    payload,
});

export const getFestivalDetailsFail = (payload: any) => ({
    type: types.GET_FESTIVAL_DETAILS_FAIL,
    error: payload,
});

export const deleteFestival = (payload: any) => ({
    type: types.DELETE_FESTIVAL,
    payload,
});

export const deleteFestivalSuccess = (payload: any) => ({
    type: types.DELETE_FESTIVAL_SUCCESS,
    payload,
});

export const deleteFestivalFail = (payload: any) => ({
    type: types.DELETE_FESTIVAL_FAIL,
    error: payload,
});

export const updateFestival = (payload: any) => ({
    type: types.UPDATE_FESTIVAL,
    payload,
});

export const updateFestivalSuccess = (payload: any) => ({
    type: types.UPDATE_FESTIVAL_SUCCESS,
    payload,
});

export const updateFestivalFail = (payload: any) => ({
    type: types.UPDATE_FESTIVAL_FAIL,
    error: payload,
});

export const getFestivalAwardCategory = (payload: string) => ({
    type: types.GET_FESTIVAL_AWARD_CATEGORY,
    payload,
});

export const getFestivalAwardCategorySuccess = (payload: any) => ({
    type: types.GET_FESTIVAL_AWARD_CATEGORY_SUCCESS,
    payload,
});

export const getFestivalAwardCategoryFail = (payload: any) => ({
    type: types.GET_FESTIVAL_AWARD_CATEGORY_FAIL,
    error: payload,
});

export const getFestivalAwardRecipients = (payload: string) => ({
    type: types.GET_FESTIVAL_AWARD_RECIPIENTS,
    payload,
});

export const getFestivalAwardRecipientsSuccess = (payload: any) => ({
    type: types.GET_FESTIVAL_AWARD_RECIPIENTS_SUCCESS,
    payload,
});

export const getFestivalAwardRecipientsFail = (payload: any) => ({
    type: types.GET_FESTIVAL_AWARD_RECIPIENTS_FAIL,
    payload,
});
