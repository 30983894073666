import { IContest, INewScore, IProject, IUserSummary } from '../../types/global/helper';
import { IHost, IPartner } from '../discovery/types';

export const types = {
    INVITE_JURY: 'INVITE_JURY',
    INVITE_JURY_SUCCESS: 'INVITE_JURY_SUCCESS',
    INVITE_JURY_FAIL: 'INVITE_JURY_FAIL',

    ACCEPT_JURY_INVITE: 'ACCEPT_JURY_INVITE',
    ACCEPT_JURY_INVITE_SUCCESS: 'ACCEPT_JURY_INVITE_SUCCESS',
    ACCEPT_JURY_INVITE_FAIL: 'ACCEPT_JURY_INVITE_FAIL',

    GET_JURY_CAMPAIGNS: 'GET_JURY_CAMPAIGNS',
    GET_JURY_CAMPAIGNS_SUCCESS: 'GET_JURY_CAMPAIGNS_SUCCESS',
    GET_JURY_CAMPAIGNS_FAIL: 'GET_JURY_CAMPAIGNS_FAIL',

    DELETE_JURY: 'DELETE_JURY',
    DELETE_JURY_SUCCESS: 'DELETE_JURY_SUCCESS',
    DELETE_JURY_FAIL: 'DELETE_JURY_FAIL',

    GET_CAMPAIGN_SUBMISSIONS: 'GET_CAMPAIGN_SUBMISSIONS',
    GET_CAMPAIGN_SUBMISSIONS_SUCCESS: 'GET_CAMPAIGN_SUBMISSIONS_SUCCESS',
    GET_CAMPAIGN_SUBMISSIONS_FAIL: 'GET_CAMPAIGN_SUBMISSIONS_FAIL',

    GET_COMPLETED_CAMPAIGN_SUBMISSIONS: 'GET_COMPLETED_CAMPAIGN_SUBMISSIONS',
    GET_COMPLETED_CAMPAIGN_SUBMISSIONS_SUCCESS: 'GET_COMPLETED_CAMPAIGN_SUBMISSIONS_SUCCESS',
    GET_COMPLETED_CAMPAIGN_SUBMISSIONS_FAIL: 'GET_COMPLETED_CAMPAIGN_SUBMISSIONS_FAIL',

    GET_INCOMPLETE_CAMPAIGN_SUBMISSIONS: 'GET_INCOMPLETE_CAMPAIGN_SUBMISSIONS',
    GET_INCOMPLETE_CAMPAIGN_SUBMISSIONS_SUCCESS: 'GET_INCOMPLETE_CAMPAIGN_SUBMISSIONS_SUCCESS',
    GET_INCOMPLETE_CAMPAIGN_SUBMISSIONS_FAIL: 'GET_INCOMPLETE_CAMPAIGN_SUBMISSIONS_FAIL',

    SCORE_SUBMISSION: 'SCORE_SUBMISSION',
    SCORE_SUBMISSION_SUCCESS: 'SCORE_SUBMISSION_SUCCESS',
    SCORE_SUBMISSION_FAIL: 'SCORE_SUBMISSION_FAIL',

    DISTRIBUTE_SUBMISSIONS_TO_JURIES: 'DISTRIBUTE_SUBMISSIONS_TO_JURIES',
    DISTRIBUTE_SUBMISSIONS_TO_JURIES_SUCCESS: 'DISTRIBUTE_SUBMISSIONS_TO_JURIES_SUCCESS',
    DISTRIBUTE_SUBMISSIONS_TO_JURIES_FAIL: 'DISTRIBUTE_SUBMISSIONS_TO_JURIES_FAIL',

    GET_JURORS_FOR_CONTEST: 'GET_JURORS_FOR_CONTEST',
    GET_JURORS_FOR_CONTEST_SUCCESS: 'GET_JURORS_FOR_CONTEST_SUCCESS',
    GET_JURORS_FOR_CONTEST_FAIL: 'GET_JURORS_FOR_CONTEST_FAIL',

    ASSIGN_SUBMISSION_TO_JUROR: 'ASSIGN_SUBMISSION_TO_JUROR',
    ASSIGN_SUBMISSION_TO_JUROR_SUCCESS: 'ASSIGN_SUBMISSION_TO_JUROR_SUCCESS',
    ASSIGN_SUBMISSION_TO_JUROR_FAIL: 'ASSIGN_SUBMISSION_TO_JUROR_FAIL',

    GET_SCORE_BOARD: 'GET_SCORE_BOARD',
    GET_SCORE_BOARD_SUCCESS: 'GET_SCORE_BOARD_SUCCESS',
    GET_SCORE_BOARD_FAIL: 'GET_SCORE_BOARD_FAIL',

    SEARCH_SUBMISSIONS: 'SEARCH_SUBMISSIONS',
    SEARCH_SUBMISSIONS_SUCCESS: 'SEARCH_SUBMISSIONS_SUCCESS',
    SEARCH_SUBMISSIONS_FAIL: 'SEARCH_SUBMISSIONS_FAIL',
};

export interface IJuryDuties {
    created_at: string;
    accepted_invite: boolean;
    invite_sent: boolean;
    _id: string;
    contest: IContest;
    jury: IUserSummary;
    createdAt: string;
    updatedAt: string;
    __v: number;
    expire_date: string;
    submissions: ISubmission[];
    id: string;
}

export type JudgingStatus =
    | 'winner'
    | 'semi-finalist'
    | 'finalist'
    | 'selected'
    | 'submitted'
    | 'second'
    | 'third'
    | 'fourth'
    | 'judged';

export interface IScoreCategory {
    quality: number;
    originality: number;
    compliance: number;
    aesthetics: number;
    cinematography: number;
    delivery: number;
    total?: number;
}

export interface IOtherJuryScore {
    jury: string | IUserSummary;
    jury_score?: IScoreCategory;
    judged?: boolean;
    judge_note?: string;
}
export interface ISubmission {
    _id: string;
    project: IProject;
    contest: IContest;
    assigned_judge: string;
    createdAt: string;
    jury: string;
    id: string;
    jury_score: IScoreCategory;
    judging_status?: JudgingStatus;
    trending_marker?: number;
    updatedAt?: string;
    created_by?: IUserSummary | string;
    host?: IHost;
    partner?: IPartner;
    judge_note?: string;
    other_juries?: string[];
    other_judges?: string[];
    other_jury_scores?: IOtherJuryScore[];
    /** The below is used in jury view */
    score?: INewScore[];
    /** These are used in admin dashboard view */
    all_scores?: number[];
    total_average: number;
    other_jury_details?: IUserSummary[];
}

export interface ISubmissionResultsForContest {
    submissions: ISubmission[];
    total_count: number;
    page: number;
    limit?: number;
    match?: SubmissionMatch;
    searchTerm?: string;
}

export interface ISubmissionSummary {
    _id: string;
    project: IProject;
    contest: string;
    createdAt: string;
    jury: string;
}

export interface IJuryInitialState {
    campaigns: IJuryDuties[];
    campaignSubmisisons: ISubmission[];
    completedCampaignSubmissions: ISubmission[];
    incompleteCampaignSubmisisons: ISubmission[];
    completedCampaignSubmissionCount: number;
    incompleteCampaignSubmissionCount: number;
    totalCampaignSubmissionCount: number;
    currentSubmissionPage: number;
    canLoadMoreJurySubmissions?: boolean;
    loadingMoreJurySubmissions?: boolean;
    invites?: IJuryDuties[];
    loading: boolean;
    gettingCampaignSubmissions: boolean;
    errorMsg: any;
    scoreBoard?: IScoreBoardResponse;
    selectedJuries: IJuryDuties[];
    gettingJurorForContest?: boolean;
    assigningJurorSubmission?: boolean;
    gettingScoreBoard?: boolean;
    juryMessage?: string;
    searchResults: ISubmission[];
    searching?: boolean;
}

export interface IInviteJuryPayload {
    /** Array of user ids to be invited */
    juries: string[];
    /** The contest in question */
    contest: string;
    bypass?: boolean;
}

export interface IAcceptJuryInvite {
    tokem: string;
}

export interface IGetJuryCampaignSubmissions {
    contestId: string;
    limit?: number;
    page: number;
    match?: SubmissionMatch;
    skipRefresh?: boolean;
    searchTerm?: string;
    category?: string;
}

export type SubmissionMatch = 'all' | 'judged' | 'not_judged';

export interface IAssignJuryDutyBase {
    auto_populate: boolean;
    jury?: string;
    submission?: string;
    user?: string;
    contest: string;
    enable_other_jurors?: boolean;
    single_assign_all?: boolean;
}

export interface IAssignJuryDutyWithSingleAssignAll extends IAssignJuryDutyBase {
    single_assign_all: true;
    single_jury: string; // This is mandatory when single_assign_all is true
}

export interface IAssignJuryDutyWithoutSingleAssignAll extends IAssignJuryDutyBase {
    single_assign_all?: false;
    single_jury?: string; // This is optional when single_assign_all is false or undefined
}

export type IAssignJuryDuty = IAssignJuryDutyWithSingleAssignAll | IAssignJuryDutyWithoutSingleAssignAll;

export interface IScoreSubmission {
    submissionId: string;
    payload: {
        score?: IScoreCategory;
        judge_note?: string;
        other_jury_scores?: IOtherJuryScore;
    };
    match?: SubmissionMatch;
}

export interface IScoreSubmissionResponse {
    match?: SubmissionMatch;
    submission: ISubmission;
}

export interface TAudienceVote extends ISubmission {
    total_votes: number;
}
export interface IScoreBoardResponse {
    audience_votes: Partial<TAudienceVote[]>;
    jury_score: Partial<ISubmission[]>;
}

export interface IGetJurorForContest {
    contestId: string;
    page?: number;
    limit?: number;
    isRefresh?: boolean;
}

export interface IGetScoreBoard {
    contestId: string;
    isRefresh?: boolean;
    category?: string;
}

export interface IAssignedSubmissionToJudge {
    /** This will distribute the submissions to all he judges */
    auto_populate?: boolean;
    /** contestId  - Important if you are auto populating*/
    contest?: string;
    /**user id */
    user?: string;
    /** Jury Id */
    jury?: string;
    /**Submission Id */
    submission: string;
}

export interface ISearchSubmissions {
    searchTerm: string;
}
