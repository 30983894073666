import produce from 'immer';
import { ITalentData, ITalentInitialState, types } from './types';
import { IGenericDocumentModifier } from '../../types/global/helper';

const initialState: ITalentInitialState = {
    talents: [],
    loadingTalent: false,
    errMsg: null,
    talentSearchData: [],
    deletingTalent: false,
    savingTalent: false,
    updatingTalent: false,
    searchingTalent: false,
};

const reducer = (state = initialState, action: any) => {
    const { type, payload } = action;
    switch (type) {
        case types.GET_SAVED_TALENTS:
            return produce(state, draftState => {
                draftState.loadingTalent = true;
            });
        case types.GET_SAVED_TALENT_SUCCESS:
            return produce(state, draftState => {
                draftState.loadingTalent = false;
                draftState.talents = payload;
            });
        case types.GET_SAVED_TALENT_FAIL:
            return produce(state, draftState => {
                draftState.errMsg = payload;
                draftState.loadingTalent = false;
            });
        case types.SAVE_TALENT:
            return produce(state, draftState => {
                draftState.savingTalent = true;
            });
        case types.SAVE_TALENT_SUCCESS:
            return produce(state, draftState => {
                draftState.talents.push(payload);
                draftState.savingTalent = false;
            });
        case types.SAVE_TALENT_FAIL:
            return produce(state, draftState => {
                draftState.errMsg = payload;
                draftState.savingTalent = false;
            });
        case types.UPDATE_TALENT:
            return produce(state, draftState => {
                draftState.updatingTalent = true;
            });
        case types.UPDATE_TALENT_SUCCESS:
            return produce(state, draftState => {
                draftState.updatingTalent = false;
                const { _id } = payload as ITalentData;
                const docIndex = draftState.talents.findIndex(t => t._id === _id);
                const searchDocIndex = draftState.talentSearchData.findIndex(t => t._id === _id);
                if (docIndex > -1) {
                    draftState.talents[docIndex] = payload;
                }
                if (searchDocIndex > -1) {
                    draftState.talentSearchData[searchDocIndex] = payload;
                }
            });
        case types.UPDATE_TALENT_FAIL:
            return produce(state, draftState => {
                draftState.updatingTalent = false;
                draftState.errMsg = payload;
            });

        case types.SEARCH_TALENT:
            return produce(state, draftState => {
                draftState.searchingTalent = true;
            });
        case types.SEARCH_TALENT_SUCCESS:
            return produce(state, draftState => {
                draftState.talentSearchData = payload;
                draftState.searchingTalent = false;
            });
        case types.SEARCH_TALENT_FAIL:
            return produce(state, draftState => {
                draftState.searchingTalent = false;
                draftState.errMsg = payload;
            });
        case types.DELETE_TALENT:
            return produce(state, draftState => {
                draftState.deletingTalent = true;
            });
        case types.DELETE_TALENT_SUCCESS:
            return produce(state, draftState => {
                const { documentId } = payload as IGenericDocumentModifier;
                draftState.talents = draftState.talents.filter(talent => talent._id !== documentId);
                draftState.deletingTalent = false;
            });
        case types.DELETE_TALENT_FAIL:
            return produce(state, draftState => {
                draftState.deletingTalent = false;
                draftState.errMsg = payload;
            });

        default:
            return state;
    }
};
export { reducer };
