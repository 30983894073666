import { createAction } from 'redux-act';
import { IVoterCard, types } from './types';
import { IVote } from '../contest/types';
import { IErrorType } from '../../constants/types';

export const getUserVotes = createAction(types.GET_USER_VOTES);
export const getUserVotesSuccess = createAction<IVote[]>(types.GET_USER_VOTES_SUCCESS);
export const getUserVotesFail = createAction<IErrorType>(types.GET_USER_VOTES_FAIL);

export const getVoterId = createAction(types.GET_USER_VOTER_ID);
export const getVoterIdSuccess = createAction<IVoterCard>(types.GET_USER_VOTER_ID_SUCCESS);
export const getVoterIdFail = createAction<IErrorType>(types.GET_USER_VOTER_ID_FAIL);
