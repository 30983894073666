import React from 'react';
import { StyleSheet } from 'react-native';
import { Box, Skeleton, Stack } from '@mui/material';
import { useThemeContext } from '../../hook/useThemeManager';
import { ColorScheme, theme } from '../../constants/theme';
import { MOBILE_MQ } from '../../constants/global';

const EsMyProfileHeroLoadingState = () => {
    // Detect when window width changes
    const [innerWidth, setInnerWidth] = React.useState<number>(window.innerWidth);
    const getSize = () => {
        setInnerWidth(window.innerWidth);
    };
    React.useEffect(() => {
        window.addEventListener('resize', getSize);

        return () => {
            window.removeEventListener('resize', getSize);
        };
    }, [innerWidth]);

    const { themeStyle } = useThemeContext();
    const styles = dynamicStyles(themeStyle);

    return (
        <Box
            sx={{
                width: '100%',
                height: 'auto',
                minHeight: { xs: '346px', sm: '274px' },
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-end',
                alignItems: 'center',
                borderRadius: '20px',
            }}>
            <Box
                style={styles.topContainer}
                sx={{
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    height: { xs: '112px', sm: '125px' },
                    width: '100%',
                    borderTopRightRadius: '20px',
                    borderTopLeftRadius: '20px',
                }}></Box>
            <Box
                style={styles.bottomContainer}
                sx={{
                    height: { xs: '235px', sm: '150px' },
                    flexDirection: { xs: 'column', sm: 'column', md: 'row' },
                    alignItems: { xs: 'center', sm: 'center' },
                    justifyContent: { xs: 'center', sm: 'space-around' },
                    borderBottomLeftRadius: '20px',
                    borderBottomRightRadius: '20px',
                    gap: { xs: '16px', sm: '0px' },
                }}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: { xs: 'column', sm: 'row' },
                        alignItems: { xs: 'center', sm: 'flex-end' },
                        justifyContent: { xs: 'center', sm: 'center' },
                        marginTop: { xs: '-25%', sm: '-70px' },
                        gap: { xs: '0px', sm: '22px' },
                    }}>
                    <Skeleton
                        variant="circular"
                        animation="wave"
                        style={{ ...styles.avatar, backgroundColor: 'slategrey' }}
                    />

                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: { xs: 'center', sm: 'flex-start' },
                            justifyContent: { xs: 'center', sm: 'space-around' },
                            gap: '12px',
                        }}>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'center',
                                gap: '8px',
                            }}>
                            <Stack direction="row" gap={1} sx={{ marginBottom: 5 }}>
                                <Stack>
                                    <Skeleton
                                        variant="text"
                                        width={200}
                                        sx={{ fontSize: '1rem', backgroundColor: theme[themeStyle].darkGray60 }}
                                    />
                                    <Skeleton
                                        variant="text"
                                        width={'50%'}
                                        sx={{ fontSize: '1rem', backgroundColor: theme[themeStyle].darkGray60 }}
                                    />
                                </Stack>
                            </Stack>
                        </Box>
                    </Box>
                </Box>

                <Box
                    sx={{
                        gap: '12px',
                        width: 'fit-content',
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginBottom: { sm: '6px', md: '0px' },
                    }}>
                    <Skeleton
                        variant="rounded"
                        sx={{ backgroundColor: theme[themeStyle].primary_orange, borderRadius: 100 }}
                        width={180}
                        height={40}
                    />
                </Box>
            </Box>
        </Box>
    );
};

const dynamicStyles = (colorScheme: ColorScheme) =>
    StyleSheet.create({
        bottomContainer: {
            width: '100%',
            backgroundColor: theme[colorScheme].gray_text,
            display: 'flex',
        },
        topContainer: {
            width: '100%',
            backgroundColor: theme[colorScheme].accountGray,
            display: 'flex',
        },
        avatar: {
            // eslint-disable-next-line no-restricted-globals
            width: innerWidth < MOBILE_MQ ? '94px' : '140px',
            // eslint-disable-next-line no-restricted-globals
            height: innerWidth < MOBILE_MQ ? '94px' : '140px',
            borderRadius: 100,
        },
    });

export { EsMyProfileHeroLoadingState };
