import React, { FormEvent, useState } from 'react';
import Button from '@mui/material/Button';
import { Box, Typography } from '@mui/material';
import { StyleSheet } from 'react-native';
import { useThemeContext } from '../../hook/useThemeManager';
import { ColorScheme, theme } from '../../constants/theme';
import { useMultistepForm } from './useMultistepForm';
import { useEsHover } from '../Animation/useEsHover';
import { EsPassword } from './EsPassword';
import { EsPersonalDetail } from './EsPersonalDetail';
import { EsConfirm } from './EsConfirm';
import { FONT_ENUM } from '../../types/global/fonts.types';
import { EsVerifyCode } from '../EsVerify/EsVerifyCode';
import { TUserField } from '../../redux/user/actions';
import { ScreenTypes } from '../../module/ThemeManager';
import { validate } from '../../utils/validators';
import { strings } from '../../constants';
import { isEmpty } from '../../utils/lodash';
import { Spinner } from '@chakra-ui/react';
import { CountryData } from 'react-phone-input-2';

export type SignUpdata = {
    email: string;
    password: string;
    firstName: string;
    lastName: string;
    userName: string;
    birth: string;
    gender: string;
    country: string;
    phoneNumber: string;
};

const SIGNUP_DATA: SignUpdata = {
    email: '',
    password: '',
    firstName: '',
    lastName: '',
    userName: '',
    birth: '',
    gender: '',
    country: '',
    phoneNumber: '',
};

interface ISignUpProps {
    onSubmitSignUpOTP: (data: SignUpdata, code?: string[]) => void;
    checkUserDataAvailability: (data: SignUpdata, callBack: () => void, field: TUserField) => void;
    userNameAvailable: (userName: string) => void;
    getSignUpOTP: (data: SignUpdata, callBack: () => void, resend?: boolean) => void | Promise<unknown>;
    loading?: boolean;
    otpLoading?: boolean;
    resendInProgress?: boolean;
}

const EsSignupForm: React.FC<ISignUpProps> = ({
    onSubmitSignUpOTP,
    checkUserDataAvailability,
    userNameAvailable,
    getSignUpOTP,
    loading,
    otpLoading,
    resendInProgress,
}) => {
    const [data, setData] = useState(SIGNUP_DATA);
    const [otp, setOtp] = useState<string[]>();
    const [countryCode, setCountryCode] = useState<CountryData>();
    const [phoneNumberValidity, setPhonenumberValidity] = useState({
        valid: false,
        errorMessage: '',
        isFocused: false,
    });

    function updateFields(fields: Partial<SignUpdata>) {
        setData(prev => {
            return { ...prev, ...fields };
        });
    }

    const { themeStyle, device } = useThemeContext();

    const { handleMouseEnter, handleMouseLeave, isHover } = useEsHover();

    const styles = dynamicStyles(themeStyle, isHover, device);

    const handlePhoneNumberCountryChange = (d?: CountryData, isFocused?: boolean) => {
        setCountryCode(d);
    };

    const { steps, currentStepIndex, step, isFirstStep, isLastStep, back, next } = useMultistepForm([
        <EsPassword {...data} updateFields={updateFields} />,
        <EsPersonalDetail
            userNameAvailable={userNameAvailable}
            {...data}
            updateFields={updateFields}
            onCountryChange={handlePhoneNumberCountryChange}
            isErrorStatePhone={!phoneNumberValidity.valid}
            errMsg={phoneNumberValidity.errorMessage}
            isPhoneFieldInFocus={phoneNumberValidity.isFocused}
            onPhoneFieldFocus={() => {
                setPhonenumberValidity({
                    valid: false,
                    errorMessage: '',
                    isFocused: true,
                });
            }}
            onPhoneFieldBlue={() => {
                setPhonenumberValidity({
                    valid: validate.validatePhoneNumber(data.phoneNumber, countryCode?.countryCode),
                    errorMessage: !validate.validatePhoneNumber(data.phoneNumber, countryCode?.countryCode)
                        ? `Invalid phone number for ${countryCode?.name}`
                        : '',
                    isFocused: false,
                });
            }}
        />,
        <EsConfirm {...data} />,
        <EsVerifyCode
            {...data}
            onResendOtp={() => getSignUpOTP(data, () => {}, true)}
            onClickBack={() => back()}
            onChange={(otp: string[]) => setOtp(otp)}
            loading={otpLoading}
            resendInProgress={resendInProgress}
        />,
    ]);

    function onSubmit(e: FormEvent) {
        e.preventDefault();
        switch (currentStepIndex) {
            case 0:
                checkUserDataAvailability(data, next, 'email');
                break;
            case 1:
                checkUserDataAvailability(data, next, 'phoneNumber');
                break;
            case 2:
                getSignUpOTP(data, next);
                break;
            case 3:
                onSubmitSignUpOTP(data, otp);
                break;
        }
    }
    const _validatePasswordCharacters = (pass: any) => {
        const passwordLength = validate.validatePasswordLength(pass);
        const passwordhasNumber = validate.validatePasswordNumber(pass);
        const passwordHascase = validate.validatePasswordCase(pass);
        return passwordLength && passwordhasNumber && passwordHascase;
    };
    const enableButton = () => {
        if (currentStepIndex === 0) {
            return (
                !isEmpty(data.email) &&
                validate.validateEmail(data.email) &&
                !isEmpty(data.password) &&
                _validatePasswordCharacters(data.password)
            );
        }

        if (currentStepIndex === 1) {
            return (
                !isEmpty(data.gender) &&
                !isEmpty(data.birth) &&
                !isEmpty(data.userName) &&
                !isEmpty(data.phoneNumber) &&
                validate.validatePhoneNumber(data.phoneNumber, countryCode?.countryCode) &&
                !isEmpty(data.firstName) &&
                !isEmpty(data.lastName) &&
                validate.validateUsernameCharacters(data.userName) &&
                validate.validateUsernameLength(data.userName)
            );
        }

        if (currentStepIndex === 2) {
            return true;
        }
    };

    return (
        <form style={styles.form} onSubmit={onSubmit}>
            <Box>
                <Typography style={styles.steps}>
                    STEP {currentStepIndex + 1} OF {steps.length}
                </Typography>
            </Box>

            {step}

            {isFirstStep && (
                <Button
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    style={styles.button}
                    disabled={!enableButton() || loading}
                    type="submit">
                    {loading ? (
                        <Spinner h={4} w={4} color={theme[themeStyle].tag_white} />
                    ) : (
                        <Typography style={styles.signup}>{strings.sign_up}</Typography>
                    )}
                </Button>
            )}
            {isFirstStep && (
                <Typography style={styles.text}>
                    By clicking Sign Up, you agree to our Terms, Privacy Policy and Cookies Policy. You may receive SMS
                    notifications [and or emails] from us and can opt out at any time. Thspian uses WhatsApp™ for
                    verification.
                </Typography>
            )}
            {!isFirstStep && !isLastStep && (
                <Button
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    style={styles.button}
                    disabled={!enableButton()}
                    type="submit">
                    <Typography style={styles.signup}>{isLastStep ? 'Finish' : 'Next'}</Typography>
                </Button>
            )}
            {!isFirstStep && !isLastStep && (
                <Button style={styles.backButton} onClick={back} type="button">
                    <Typography style={styles.backText}>{strings.go_back}</Typography>
                </Button>
            )}
        </form>
    );
};

const dynamicStyles = (colorScheme: ColorScheme, isHover: boolean, device: ScreenTypes, disabled?: boolean) =>
    StyleSheet.create({
        form: {
            width: '100%',
            padding: device.isMobile ? 30 : 50,
            maxWidth: 500,
            height: 'auto',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            backgroundColor: theme[colorScheme].form_black,
            borderRadius: 20,
        },
        steps: {
            fontSize: 11,
            letterSpacing: 1.98,
            fontWeight: '500',
            color: theme[colorScheme].primary_orange,
            fontFamily: FONT_ENUM.MAIN_REGULAR,
        },
        signup: {
            fontSize: 14,
            fontWeight: '600',
            fontFamily: FONT_ENUM.MAIN_BOLD,
            color: isHover ? theme[colorScheme].gray_text : theme[colorScheme].primary_orange,
        },
        button: {
            width: '100%',
            height: 44,
            paddingHorizontal: 20,
            paddingVertical: 12,
            borderRadius: 100,
            maxWidth: 400,
            backgroundColor: isHover && !disabled ? theme[colorScheme].primary_orange : theme[colorScheme].gray_text,
            textTransform: 'none',
            alignSelf: 'center',
        },
        text: {
            maxWidth: 400,
            width: '100%',
            fontSize: 14,
            fontStyle: 'normal',
            fontWeight: '400',
            color: theme[colorScheme].light_gray_text,
            marginTop: 20,
            fontFamily: FONT_ENUM.MAIN_REGULAR,
        },
        backText: {
            textAlign: 'center',
            fontSize: 12,
            fontStyle: 'normal',
            fontWeight: '400',
            color: theme[colorScheme].lightWhite,
            fontFamily: FONT_ENUM.MAIN_REGULAR,
        },
        backButton: {
            width: 'fit-content',
            alignSelf: 'center',
            height: 'fit-content',
            paddingHorizontal: 20,
            paddingVertical: 12,
            backgroundColor: theme[colorScheme].transparent,
            textTransform: 'none',
            marginTop: 20,
        },
    });

export { EsSignupForm };
