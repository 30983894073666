import produce from 'immer';
import { types } from './types';
import { IInitialAdopterState } from './actions';

const initialState: IInitialAdopterState = {
    savingEmail: false,
    savingEmailError: null,
};

const reducer = (state = initialState, action: any) => {
    const { type } = action;
    switch (type) {
        case types.SAVE_EMAIL:
            return produce(state, (draftState: typeof initialState) => {
                draftState.savingEmail = true;
            });
        case types.SAVE_EMAIL_SUCCESS:
        case types.SAVE_EMAIL_FAIL:
            return produce(state, (draftState: typeof initialState) => {
                draftState.savingEmail = false;
            });
        default:
            return state;
    }
};

export { reducer };
