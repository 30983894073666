export default class Manager {
    rootIds: any;

    map: any;

    childIdsMap: any;

    constructor() {
        this.rootIds = [];
        this.map = {};
        this.childIdsMap = {};
    }

    get(effectId: string | number) {
        return this.map[effectId];
    }

    set(effectId: string | number, desc: { parentEffectId: string | number }) {
        this.map[effectId] = desc;
        if (this.childIdsMap[desc.parentEffectId] === null) {
            this.childIdsMap[desc.parentEffectId].push(effectId);
        }
    }

    setRootEffect(effectId: any, desc: any) {
        this.rootIds.push(effectId);
        this.set(effectId, { root: true, ...desc });
    }

    getRootIds() {
        return this.rootIds;
    }

    getChildIds(parentEffectId: string | number) {
        return this.childIdsMap[parentEffectId] || [];
    }
}
