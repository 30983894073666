/* eslint-disable global-require */
export const logo = require('./Logo/thspian1.png');
export const logoWithIcon = require('./Logo/thspianLogo-color.png');
export const icon = require('./Logo/thspianLogo-color.png');
export const playIcon = require('./icons/PlayButton.png');
export const placeHolder = require('./placeholder/placeholderImage.png');
export const profile_pic = require('./placeholder/default_pic.png');
export const trailerPlaceHolder = require('./placeholder/trailerPl.png');
export const yellowCheck = require('./image/checkmark-yellow.png');
export const blueCheck = require('./image/checkmark-blue.png');
export const sunset = require('./image/sunset.jpeg');
export const watcher = require('./image/filmwatcher.jpeg');
export const contribute = require('./image/typewriter.jpg');
export const spaces = require('./image/new/apartment.jpg');
export const family = require('./image/new/family_watching.jpg');
export const reviewer = require('./image/review.jpg');
export const vote = require('./image/new/vote_.png');
export const category_bg = require('./image/bg.jpeg');
export const acting = require('./image/new/male_actor.jpg');
export const documentary = require('./image/reviews.jpg');
export const wreathe = require('./image/wreathe.png');
export const wreathLeft = require('./image/wreathe1.png');
export const wreathRight = require('./image/wreathe2.png');
export const avatar = require('./image/avatar.png');
export const avatar_female = require('./image/avatar_female.WEBP');
export const avatar_male = require('./image/avatar_male.WEBP');

export const join_contest_button = require('./image/join_now.png');
export const create_project_button = require('./image/create.png');
export const create_project_button_mobile = require('./image/create_mobile.png');

export const DEFAULT_FESTIVAL_AWS_IMAGE =
    'https://boxofix-bucket-01.s3.us-east-2.amazonaws.com/default_images/default_festival_image.jpeg';

export const THSPIAN_LOGO = require('./Logo/thspian_logo_primary.png');
export const THSPIAN_LOGO_SMALL = require('./Logo/rounded_32.png');

/** account icons */
export const account_icons = {
    reviews: require('./icons/account/reviews_icon.png'),
    subscription: require('./icons/account/subscription_icon.png'),
    my_prodduction: require('./icons/account/production_icon.png'),
    movie_tickets: require('./icons/account/movie_ticket_icon.png'),
    fav_movies: require('./icons/account/movies_icon.png'),
    settings: require('./icons/account/settings_icon.png'),
    profile: require('./icons/account/cinema_icon.png'),
    people: require('./icons/account/reviews_icon.png'),
    contributions: require('./icons/account/map_icon.png'),
    likes: require('./icons/account/casting_call_icon.png'),
    bScore: require('./icons/account/reviews.png'),
    watchlist: require('./icons/account/watchlist_icon.png'),
};

export const filter_icons = {
    skill: require('./icons/audition/folder.png'),
    location: require('./icons/audition/map.png'),
    paid: require('./icons/audition/print.png'),
    shooting_date: require('./icons/audition/calendar.png'),
    audition_type: require('./icons/audition/camera.png'),
    required_media: require('./icons/audition/pin.png'),
    rehearsal_date: require('./icons/audition/clock.png'),
};

/** Account Top Boxes */
export const account_box = {
    applications: require('./image/social_bg.jpeg'),
    reviews: require('./image/social_bg.jpeg'),
    productions: require('./image/social_bg.jpeg'),
};

/** Subscription Plans */
export const subscription_plans = {
    gold: require('./image/Gold.png'),
    bronze: require('./image/Bronze.png'),
    silver: require('./image/Silver.png'),
    regular: require('./image/Silver.png'),
};

export const gps_icons = {
    gps_light: require('./image/gps_light.png'),
    gps_dark: require('./image/gps_dark.png'),
    sampleImage: require('./image/production.jpeg'),
};

export const thspian_logo = require('./Logo/bgWithLogo.png');
