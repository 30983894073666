import { call, put, all, takeLatest, select } from 'redux-saga/effects';
import { IVoteInititalState, types } from './types';
import { api } from '../../configurations/api';
import { getUserVotesFail, getUserVotesSuccess, getVoterIdFail, getVoterIdSuccess } from './action';
import { voteUrl, voterUrl } from '../../configurations/api/url';
import { httpRequest } from '../types';
import { setCache } from '../cache/action';
import { CACHE_TYPE, CacheValue, NotUniqueCacheValue } from '../cache/types';
import { getCacheByType, getCacheDuration, getNotUniqueCacheByKey, isUseCacheEnabled } from '../cache/saga';
import { isEmpty } from '../../utils/lodash';
import { SentryCapture } from '../../analytics/Sentry';

function* getUserVotes(): any {
    const { vote }: { vote: IVoteInititalState } = yield select();
    const defaultUseCache = yield* isUseCacheEnabled();
    const url = `${voteUrl}?voter=${vote.userVoterId?._id}`;
    let initialResult: any = null;
    const cache: NotUniqueCacheValue = yield* getNotUniqueCacheByKey(
        CACHE_TYPE.USER_VOTES,
        vote.userVoterId?._id as string,
    );
    if (cache && cache.key) {
        initialResult = cache.value;
    }
    if (initialResult && defaultUseCache) {
        yield put(getUserVotesSuccess(initialResult));
    } else {
        try {
            const response = yield call(api, url, httpRequest.GET, null, 2, 2000);
            const { data } = response.data;
            yield put(getUserVotesSuccess(data));
            yield put(
                setCache({
                    key: vote.userVoterId?._id as string,
                    value: data,
                    type: CACHE_TYPE.USER_VOTES,
                    isUnique: false,
                }),
            );
        } catch (error: any) {
            SentryCapture(error, 'error');
            yield put(getUserVotesFail(error));
        }
    }
}

/** The below will fetch the voter card of the currently logged in user */
function* getUserVoterId(): any {
    const defaultUseCache = yield* isUseCacheEnabled();
    const defaultCacheDuration = yield* getCacheDuration();
    let initialResult: any = null;
    const cache: CacheValue = yield* getCacheByType(CACHE_TYPE.VOTER_ID);
    if (
        cache &&
        cache.key &&
        !isEmpty(cache.value) &&
        defaultUseCache &&
        ((Date.now() - Number(cache.key)) as unknown as number) < defaultCacheDuration // To Temporarily fetch from server directly add `&& false` here to override cache
    ) {
        initialResult = cache.value;
    }
    if (initialResult && initialResult.length > 0 && defaultUseCache) {
        yield put(getVoterIdSuccess(initialResult));
    } else {
        try {
            const response = yield call(api, `${voterUrl}/user`, httpRequest.GET, null, 2, 2000);
            const { data } = response.data;
            yield put(getVoterIdSuccess(data[0]));
            yield put(
                setCache({
                    key: Date.now(),
                    value: data[0],
                    type: CACHE_TYPE.VOTER_ID,
                    isUnique: true,
                }),
            );
        } catch (error: any) {
            yield call(SentryCapture, error, 'error');
            yield put(getVoterIdFail(error));
        }
    }
}

function* getUserVotesWatcher() {
    yield takeLatest(types.GET_USER_VOTES, getUserVotes);
}

function* getUserVoterIdWatcher() {
    yield takeLatest(types.GET_USER_VOTER_ID, getUserVoterId);
}

export default function* voteSaga() {
    yield all([getUserVoterIdWatcher(), getUserVotesWatcher()]);
}
