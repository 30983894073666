import { createAction } from 'redux-act';
import { ApplicationSort } from '../../constants/types/api/application';
import { IErrorType } from '../../constants/types/error/error.types';
import { types } from './types';
import { IRole } from '../../types/global/AuditionDetailsEditor.types';
import { IAudition } from '../../types/global/helper';

interface IGetAuditions {
    url?: string;
}
interface IGetAuditionsResponse {
    data: any;
    isLoadMore: boolean;
}

export const getAllAuditions = (payload?: IGetAuditions) => ({
    type: types.GET_ALL_AUDITIONS,
    payload,
});

export const getAllAuditionsSuccess = (payload: IGetAuditionsResponse) => ({
    type: types.GET_ALL_AUDITION_SUCCESS,
    payload,
});

export const getAllAuditionsFailed = (error: any) => ({
    type: types.GET_ALL_AUDITION_FAILED,
    payload: error,
});

export const getAuditionsDetails = (payload: any) => ({
    type: types.GET_AUDITION_DETAIL,
    payload,
});

export const getAuditionsDetailsSuccess = (payload: any) => ({
    type: types.GET_AUDITION_DETAIL_SUCCESS,
    payload,
});

export const getAuditionsDetailsFailed = (error: any) => ({
    type: types.GET_AUDITION_DETAIL_FAILED,
    payload: error,
});

export const applyToAudition = (roleId: string) => ({
    type: types.APPLY_TO_AUDITION,
    payload: roleId,
});

export const applyToAuditionSuccess = (payload: any) => ({
    type: types.APPLY_TO_AUDITION_SUCCESS,
    payload,
});

export const applyToAuditionFailed = (error: any) => ({
    type: types.APPLY_TO_AUDITION_FAILED,
    payload: error,
});

export const addAuditionToWatchList = () => ({
    type: types.ADD_AUDITION_TO_WATCHLIST,
});

export const addAuditionToWatchListSuccess = (payload: any) => ({
    type: types.ADD_AUDITION_TO_WATCHLIST_SUCCESS,
    payload,
});

export const addAuditionToWatchListFailed = (error: any) => ({
    type: types.ADD_AUDITION_TO_WATCHLIST_FAILED,
    payload: error,
});

export const getAuditionRoles = (payload: any) => ({
    type: types.GET_ROLES,
    payload,
});

export const getAuditionRolesSuccess = (payload: any) => ({
    type: types.GET_ROLES_SUCCESS,
    payload,
});

export const getAuditionRolesFailed = (error: any) => ({
    type: types.GET_ROLES_FAILED,
    payload: error,
});

export const getMyApplications = () => ({
    type: types.GET_MY_APPLICATIONS,
});

export const getMyApplicationsSuccess = (payload: any) => ({
    type: types.GET_MY_APPLICATIONS_SUCCESS,
    payload,
});

export const getMyApplicationsFailed = (error: any) => ({
    type: types.GET_MY_APPLICATIONS_FAILED,
    payload: error,
});

export const toggleFilter = () => ({
    type: types.TOGGLE_FILTER,
});

export const addFilter = (payload: any) => ({
    type: types.ADD_FILTER,
    payload,
});

export const removeFilter = () => ({
    type: types.REMOVE_FILTER,
});

export const saveCollection = (payload: any) => ({
    type: types.SAVE_COLLECTION,
    payload,
});

export const saveCollectionSuccess = (payload: any) => ({
    type: types.SAVE_COLLECTION_SUCCESS,
    payload,
});

export const saveCollectionFail = (payload: any) => ({
    type: types.SAVE_COLLECTION_FAIL,
    error: payload,
});

export const getSavedCollection = () => ({
    type: types.GET_SAVED_COLLECTION,
});
export const getSavedCollectionSuccess = (payload: any) => ({
    type: types.GET_SAVED_COLLECTION_SUCCESS,
    payload,
});

export const getSavedCollectionFail = (payload: any) => ({
    type: types.GET_SAVED_COLLECTION_FAIL,
    error: payload,
});

export const deleteSavedCollection = (savedCollectionId: string) => ({
    type: types.DELETE_SAVED_COLLECTION,
    payload: savedCollectionId,
});

export const deleteSavedCollectionSuccess = (savedCollectionId: string) => ({
    type: types.DELETE_SAVED_COLLECTION_SUCCESS,
    payload: savedCollectionId,
});

export const deleteSavedCollectionFail = (payload: any) => ({
    type: types.DELETE_SAVED_COLLECTION_FAIL,
    error: payload,
});

export const updateSavedCollection = (payload: any) => ({
    type: types.UPDATE_SAVED_COLLECTION,
    payload,
});

export const updateSavedCollectionSuccess = (payload: any) => ({
    type: types.UPDATE_SAVED_COLLECTION_SUCCESS,
    payload,
});

export const updateSavedCollectionFail = (payload: any) => ({
    type: types.UPDATE_SAVED_COLLECTION_FAIL,
    payload,
});

export const createAudition = (payload: any) => ({
    type: types.CREATE_AUDITION,
    payload,
});
export const createAuditionSuccess = (payload: any) => ({
    type: types.CREATE_AUDITION_SUCCESS,
    payload,
});

export const createAuditionFail = (payload: any) => ({
    type: types.CREATE_AUDITION_FAIL,
    error: payload,
});

/** */
export const createRole = (payload: { auditionId: string; body: IRole }) => ({
    type: types.CREATE_ROLE,
    payload,
});

export const createRoleSuccess = (payload: any) => ({
    type: types.CREATE_ROLE_SUCCESS,
    payload,
});

export const createRoleFail = (payload: any) => ({
    type: types.CREATE_ROLE_FAIL,
    error: payload,
});

export const updateAudition = (payload: {
    isImageUpload: boolean;
    id: string;
    body: { [key: string]: any };
    uploadStyle?: string;
}) => ({
    type: types.UPDATE_AUDITION,
    payload,
});

export const updateAuditionSuccess = (payload: any) => ({
    type: types.UPDATE_AUDITION_SUCCESS,
    payload,
});

export const updateAuditionFail = (payload: any) => ({
    type: types.UPDATE_AUDITION_FAIL,
    error: payload,
});

export const deleteRole = (payload: any) => ({
    type: types.DELETE_ROLE,
    payload,
});

export const deleteRoleSuccess = (payload: any) => ({
    type: types.DELETE_ROLE_SUCCESS,
    payload,
});

export const deleteRoleFail = (payload: any) => ({
    type: types.DELETE_ROLE_FAIL,
    error: payload,
});

export const publishAuditon = (payload: any) => ({
    type: types.PUBLISH_AUDITION,
    payload,
});

export const publishAuditonSuccess = (payload: any) => ({
    type: types.PUBLISH_AUDITION_SUCCESS,
    payload,
});

export const publishAuditonFail = (payload: any) => ({
    type: types.PUBLISH_AUDITION_FAIL,
    error: payload,
});

export const unPublishAuditon = (payload: any) => ({
    type: types.UNPUBLISH_AUDITION,
    payload,
});

export const unPublishAuditonSuccess = (payload: any) => ({
    type: types.UNPUBLISH_AUDITION_SUCCESS,
    payload,
});

export const unPublishAuditonFail = (payload: any) => ({
    type: types.UNPUBLISH_AUDITION_FAIL,
    error: payload,
});

export const deleteAudition = (payload: any) => ({
    type: types.DELETE_AUDITION,
    payload,
});

export const deleteAuditionSuccess = (payload: any) => ({
    type: types.DELETE_AUDITION_SUCCESS,
    payload,
});

export const deleteAuditionFail = (payload: any) => ({
    type: types.DELETE_AUDITION_FAIL,
    payload,
});

export const setAuditionForEdit = (payload: any) => ({
    type: types.SET_AUDITION_FOR_EDIT,
    payload,
});

export const setAuditionToManage = (payload: any) => ({
    type: types.SET_AUDITION_TO_MANAGE,
    payload,
});

export const setApplicationsToManage = (payload: any) => ({
    type: types.SET_APPLICATIONS_TO_MANAGE,
    payload,
});

export const getAllApplicationForAudition = (payload: any) => ({
    type: types.GET_ALL_APPLICATION_FOR_AUDITION,
    payload,
});

export const getAllApplicationForAuditionSuccess = (payload: any) => ({
    type: types.GET_ALL_APPLICATION_FOR_AUDITION_SUCCESS,
    payload,
});

export const getAllApplicationForAuditionFail = (payload: any) => ({
    type: types.GET_ALL_APPLICATION_FOR_AUDITION_FAIL,
    payload,
});

export const sortApplication = (payload: ApplicationSort) => ({
    type: types.SORT_APPLICATION,
    payload,
});

export const sortApplicationSuccess = (payload: ApplicationSort) => ({
    type: types.SORT_APPLICATION_SUCCESS,
    payload,
});

export const sortApplicationFail = (payload: any) => ({
    type: types.SORT_APPLICATION_FAIL,
    error: payload,
});

interface IFilterAuditions {
    distance?: number;
    lat?: number;
    lng?: number;
    unit?: string;
    rehearsal_date_start?: Date;
    rehearsal_date_end?: Date;
    tags?: string;
    audition_type?: string;
    required_skill?: string;
    compensation?: string;
    employment_type?: string;
    min_age?: number;
    max_age?: number;
}

interface ISearchAudition {
    searchTerm: string;
}

export interface LoadMoreAction {
    limit: number;
    page: number;
}

type Query = {
    limit: number;
    page: number;
};

export interface ILoadMoreResponse {
    data: Array<IAudition>;
    query: Query;
}

export interface IWithdrawApplication {
    applicationId: string;
}

export const setAuditionDetails = createAction<any>(types.SET_AUDITION_DETAILS);

export const filterAudition = createAction<IFilterAuditions>(types.FILTER_AUDITION);
export const filterAuditionSuccess = createAction<Array<IAudition>>(types.FILTER_AUDITION_SUCCESS);
export const filterAuditionFail = createAction<IErrorType>(types.FILTER_AUDITION_FAIL);

export const auditionAdvancedSearch = createAction<ISearchAudition>(types.ADVANCED_AUDITION_SEARCH);
export const auditionAdvancedSearchSuccess = createAction<Array<IAudition>>(types.ADVANCED_AUDITION_SEARCH_SUCCESS);
export const auditionAdvancedSearchFail = createAction<IErrorType>(types.ADVANCED_AUDITION_SEARCH_FAIL);

export const loadMoreAudition = createAction<LoadMoreAction>(types.LOAD_MORE_AUDITIONS);
export const loadMoreAuditionSuccess = createAction<ILoadMoreResponse>(types.LOAD_MORE_AUDITIONS_SUCCESS);
export const loadMoreAuditionFail = createAction<IErrorType>(types.LOAD_MORE_AUDITIONS_FAIL);

export const withdrawApplication = createAction<IWithdrawApplication>(types.WITHDRAW_APPLICATION);
export const withdrawApplicationSuccess = createAction<IWithdrawApplication>(types.WITHDRAW_APPLICATION_SUCCESS);
export const withdrawApplicationFail = createAction<IErrorType>(types.WITHDRAW_APPLICATION_FAIL);
export const resetAuditionData = createAction(types.RESET_AUDTION_DATA);
