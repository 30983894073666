export type TDisplay = 'text' | 'peopleModal' | 'tabs' | 'action button';

type ActionButton = {
    position: string;
    negative: string;
};

export interface IIntroductionType {
    displayType: TDisplay;
    displayTitle?: string;
    displaySubtitle?: string;
    actionText?: string;
    canSkip?: boolean;
    options?: string[];
    actionButtons?: ActionButton[];
    field_name?: string;
    instruction?: string;
}

export const introductionSteps: IIntroductionType[] = [
    {
        displayType: 'text',
        displayTitle: `Hi, #{user}#`,
        displaySubtitle: 'Welcome to Thspian, where creativity meets collaboration.',
        actionText: "Let's get you situated ...",
        canSkip: false,
    },
    {
        displayType: 'tabs',
        displayTitle: 'How can we address you?',
        options: [
            'actor',
            'actress',
            'animator',
            'art director',
            'artist',
            'assistant director',
            'best boy',
            'blogger',
            'brand',
            'cartoonist',
            'casting director',
            'child actor',
            'cinematographer',
            'company',
            'composer',
            'concept artist',
            'content creator (youtube, tiktok, etc.)',
            'content creator',
            'costume designer',
            'director',
            'dolly grip',
            'editor',
            'filmmaker',
            'gaffer',
            'gallery curator',
            'graphic designer',
            'grip',
            'illustrator',
            'key grip',
            'location manager',
            'make-up artist',
            'motion graphics designer',
            'multimedia artist',
            'musician',
            'others',
            'photographer',
            'podcast host',
            'poet',
            'printmaker',
            'producer',
            'production assistant',
            'production designer',
            'prop master',
            'rapper',
            'screenwriter',
            'script supervisor',
            'sculptor',
            'singer',
            'skit maker',
            'sound designer',
            'sound mixer',
            'special effects artist',
            'stunt coordinator',
            'tattoo artist',
            'ui/ux designer',
            'vfx artist',
            'video editor',
            'voice actor',
            'web designer',
            'film festival',
            'event coordinator',
            'concert organizer',
        ],
        canSkip: false,
        field_name: 'user_type',
        instruction: 'Select at least one.',
    },
    {
        displayType: 'tabs',
        displayTitle: 'What interests you?',
        options: [
            'acting',
            'advertising',
            'animation',
            'blogging',
            'branding',
            'casting',
            'cinematography',
            'content creation',
            'costume design',
            'directing',
            'editing',
            'filmmaking',
            'gig searching',
            'graphic design',
            'illustration',
            'location management',
            'motion graphics',
            'multimedia art',
            'music',
            'others',
            'photography',
            'poetry',
            'producing',
            'production design',
            'prop making',
            'screenwriting',
            'skit making',
            'sound design',
            'sound mixing',
            'special effects',
            'spoken word',
            'sports',
            'stunt coordination',
            'traveling',
            'vfx',
            'voice acting',
            'watch contents',
            'host event',
            'host film festival',
            'host online contest',
            'host physical contest',
            'organize events',
            'attend festivals',
            'attend concerts',
            'networking',
            'find creative jobs',
        ],
        canSkip: false,
        field_name: 'interest',
        instruction: 'Select at least one',
    },
    {
        displayType: 'peopleModal',
        displayTitle: 'Based on your selection',
        displaySubtitle: 'Here are some thspians you might want to keep an eye on',
        canSkip: false,
    },
    {
        displayType: 'action button',
        displayTitle: 'Finally, we would like to show you around',
        displaySubtitle: 'It would only take a few minutes 😊',
        actionButtons: [
            {
                negative: 'No, I know my way around',
                position: 'Yes, please show me around',
            },
        ],
        field_name: 'show_app_guide',
    },
];

export const projectCreationSeps: IIntroductionType[] = [
    {
        displayType: 'text',
        displayTitle: `So, What's the fuzz about publishing anyway...😎 ?`,
        displaySubtitle: "Here's a quick rundown...",
        canSkip: false,
    },
    {
        displayType: 'text',
        displayTitle: `Once you publish, 🚀`,
        displaySubtitle: 'Your project will now appear in feeds and searches',
        actionText: 'Folks will be able to catch your latest creation 😃',
        canSkip: false,
    },
    {
        displayType: 'text',
        displayTitle: `Contests...🗳️`,
        displaySubtitle: 'Published projects are eligible to be submitted to contests',
        actionText: "Otherwise, you won't see the project as an option while submitting 🥶",
        canSkip: false,
    },
    {
        displayType: 'text',
        displayTitle: `Change your mind?`,
        displaySubtitle: 'You can always change the publish settings on your project.',
        actionText: 'Go to project -> select the said project -> settings -> toggle off and click save.',
        canSkip: false,
    },
    {
        displayType: 'action button',
        displayTitle: 'Now that you are all caught up,',
        displaySubtitle: 'Would you still like to publish? 😊',
        actionButtons: [
            {
                negative: 'No, just not yet',
                position: 'Yes, please go ahead',
            },
        ],
        field_name: 'show_project_guide',
    },
];
