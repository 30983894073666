import React, {  useState } from 'react';
import { View, StyleSheet, Text,  ScrollView } from 'react-native';
import { Avatar, Stack } from '@mui/material';
import { ColorScheme, theme } from '../../../constants/theme';
import { FONT_ENUM } from '../../../types/global/fonts.types';
import { IUserProfile } from '../../../redux/user/actions';
import { IContest, IMetrics, IProject, ISubmission } from '../../../types/global/helper';
import { useThemeContext } from '../../../hook/useThemeManager';
import { IconWithText } from '../../Icon/IconWithText';
import { icon_curved_pencil } from '../../../assets/icons';
import { styled } from '@mui/material/styles';
import { TextField } from '../../TextInput/TextField';
import { ChipGroup } from '../../Chip/ChipGroup';

const SPACING = 20;

const INTERESTS = ['acting', 'script writing', 'filmmaking'];

const StyledAvatar = styled(Avatar)`
    border: 1px solid white;
`;

interface IDashboardProfile {
    userProfile: IUserProfile;
    contestWatchList?: IContest[];
    recentProject?: IProject;
    userSubmissions?: ISubmission[];
    metrics: IMetrics;
    contest?: any;
}

const ProfileContainer = ({ userProfile }: IDashboardProfile) => {
    const { themeStyle, rowStyle, flexStyle } = useThemeContext();
    const { firstName, lastName, profile_picture, location, email, phoneNumber, user_type } = userProfile;
    const [readOnlySummary, setSummaryReadOnly] = useState(true);
    const [readOnlyPersonal, setPersonlyReadOnly] = useState(true);
    const [readOnlyAddress, setAddressReadOnly] = useState(true);
    const styles = dynamicStyle(themeStyle);
    return (
        <View style={styles.control}>
            <Text style={styles.header}>Profile</Text>
            <ScrollView showsVerticalScrollIndicator={false}>
                <View style={styles.mainHeader}>
                    <View style={[rowStyle, { flex: 1 }]}>
                        <StyledAvatar
                            imgProps={{ alt: `${firstName} profile`, src: profile_picture }}
                            src={userProfile.profile_picture}
                            sx={{ width: 100, height: 100 }}
                        />
                        <View style={{ marginHorizontal: 20 }}>
                            <View style={[styles.details]}>
                                <TextField defaultValue={firstName} isReadOnlyMode={readOnlySummary} />
                                <TextField defaultValue={lastName} isReadOnlyMode={readOnlySummary} />
                            </View>
                            <View>
                                <TextField
                                    defaultValue={location?.address}
                                    isReadOnlyMode={readOnlySummary}
                                    textStyle={styles.subDetails}
                                />
                            </View>
                        </View>
                    </View>
                    <IconWithText
                        icon={icon_curved_pencil}
                        label="Edit"
                        onPress={() => setSummaryReadOnly(!readOnlySummary)}
                        iconStyle={{ width: 10, heigth: 10 }}
                    />
                </View>
                <View style={styles.midSection}>
                    <Stack direction="row" justifyContent="space-between" alignItems="center">
                        <Text style={styles.header}>Personal Information</Text>
                        <IconWithText
                            icon={icon_curved_pencil}
                            label="Edit"
                            onPress={() => setPersonlyReadOnly(!readOnlyPersonal)}
                            iconStyle={{ width: 10, heigth: 10 }}
                        />
                    </Stack>
                    <Stack>
                        <Stack direction="row" marginBottom={2}>
                            <TextField
                                label="First Name"
                                defaultValue={firstName}
                                isReadOnlyMode={readOnlyPersonal}
                                disableLabel={false}
                                containerStyle={flexStyle}
                            />
                            <TextField
                                label="Last Name"
                                defaultValue={lastName}
                                isReadOnlyMode={readOnlyPersonal}
                                disableLabel={false}
                                containerStyle={flexStyle}
                            />
                        </Stack>
                        <Stack direction="row" marginBottom={2}>
                            <TextField
                                label="Email"
                                defaultValue={email}
                                isReadOnlyMode={readOnlyPersonal}
                                disableLabel={false}
                                containerStyle={flexStyle}
                            />
                            <TextField
                                label="Phone Number"
                                defaultValue={phoneNumber}
                                isReadOnlyMode={readOnlyPersonal}
                                disableLabel={false}
                                containerStyle={flexStyle}
                            />
                        </Stack>
                        <Stack direction="row">
                            <TextField
                                label="Bio"
                                customInput={<ChipGroup data={user_type} editMode={!readOnlyPersonal} />}
                                isReadOnlyMode={readOnlyPersonal}
                                disableLabel={false}
                            />
                        </Stack>
                    </Stack>
                </View>
                <View style={styles.bottomSection}>
                    <Stack direction="row" justifyContent="space-between" alignItems="center">
                        <Text style={styles.header}>Address</Text>
                        <IconWithText
                            icon={icon_curved_pencil}
                            label="Edit"
                            onPress={() => setAddressReadOnly(!readOnlyAddress)}
                            iconStyle={{ width: 10, heigth: 10 }}
                        />
                    </Stack>
                    <Stack>
                        <Stack direction="row" marginBottom={2}>
                            <TextField
                                label="Country"
                                defaultValue="Nigeria"
                                isReadOnlyMode={readOnlyAddress}
                                disableLabel={false}
                                containerStyle={flexStyle}
                            />
                            <TextField
                                label="City"
                                defaultValue="Abuja"
                                isReadOnlyMode={readOnlyAddress}
                                disableLabel={false}
                                containerStyle={flexStyle}
                            />
                        </Stack>
                        <Stack direction="row" marginBottom={2}>
                            <TextField
                                label="Address Line 1"
                                defaultValue="123 Main street"
                                isReadOnlyMode={readOnlyAddress}
                                disableLabel={false}
                                containerStyle={flexStyle}
                            />
                            <TextField
                                label="Address line 2"
                                defaultValue="Wuse II"
                                isReadOnlyMode={readOnlyAddress}
                                disableLabel={false}
                                containerStyle={flexStyle}
                            />
                        </Stack>
                        <Stack direction="row">
                            <TextField
                                label="Interests"
                                customInput={<ChipGroup data={INTERESTS} editMode={!readOnlyAddress} />}
                                isReadOnlyMode={readOnlyAddress}
                                disableLabel={false}
                            />
                        </Stack>
                    </Stack>
                </View>
            </ScrollView>
        </View>
    );
};

export { ProfileContainer };

const dynamicStyle = (colorScheme: ColorScheme) =>
    StyleSheet.create({
        control: {
            flex: 1,
            backgroundColor: theme[colorScheme].zeus_dark,
            borderRadius: 24,
            marginLeft: SPACING,
            padding: 40,
        },
        header: {
            fontFamily: FONT_ENUM.REGULAR_HEADER,
            fontSize: 18,
            color: theme[colorScheme].lightWhite,
            marginBottom: 20,
        },
        mainHeader: {
            borderWidth: 1,
            borderColor: theme[colorScheme].darkGray60,
            minHeight: 150,
            borderRadius: 20,
            marginBottom: 20,
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: 20,
        },
        midSection: {
            borderWidth: 1,
            borderColor: theme[colorScheme].darkGray60,
            minHeight: 250,
            borderRadius: 20,
            marginBottom: 20,
            padding: 20,
        },
        bottomSection: {
            borderWidth: 1,
            borderColor: theme[colorScheme].darkGray60,
            minHeight: 250,
            borderRadius: 20,
            padding: 20,
        },
        details: {
            flex: 1,
            flexDirection: 'row',
            justifyContent: 'flex-start',
        },
        subDetails: {
            fontFamily: FONT_ENUM.MEDIUM_TEXT,
            fontSize: 12,
            marginVertical: 5,
        },
    });
