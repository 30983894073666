import React from 'react';
import { StyleSheet } from 'react-native';
import { Box, IconButton } from '@mui/material';
import { useThemeContext } from '../../hook/useThemeManager';
import { ColorScheme } from '../../constants/theme';
import plus from '../../asset/images/icons/es_plus_button.svg';
import CustomLazyLoadImage from '../Image/CustomLazyLoadImage';

interface IEsUserButtonProfile {
    onClickHandle: () => void;
    widthSize?: number;
    heightSize?: number;
    image?: any;
}

const EsUserButtonPlus: React.FC<IEsUserButtonProfile> = ({ onClickHandle, widthSize, heightSize, image }) => {
    const { themeStyle } = useThemeContext();
    const styles = dynamicStyles(themeStyle);

    return (
        <Box
            onClick={onClickHandle}
            style={styles.shareBox}
            sx={{ position: 'relative', width: widthSize, height: heightSize, flexShrink: 0 }}>
            <CustomLazyLoadImage
                effect="blur"
                src={image}
                alt="user profile"
                style={{
                    width: '40px',
                    height: '40px',
                    borderRadius: 30,
                    overflow: 'hidden',
                    border: '1px solid white',
                    objectFit: 'cover',
                }}
            />
            <IconButton sx={{ width: '16px', height: '16px', position: 'absolute', bottom: '-6px' }}>
                <CustomLazyLoadImage src={plus} alt="plus button" />
            </IconButton>
        </Box>
    );
};

const dynamicStyles = (colorScheme: ColorScheme) =>
    StyleSheet.create({
        shareBox: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
        },
    });

export { EsUserButtonPlus };
