import { Selector } from 'react-redux';
import { RootState } from '../../redux/root-reducer';
import { WatchContent } from '../../redux/project/types';
import { createSelector } from 'reselect';

export const watchContentSelector: Selector<RootState, WatchContent[] | undefined> = createSelector(
    (state: RootState) => state.project,
    project => project.watchContent,
);
export const loadingWatchContentSelector: Selector<RootState, boolean | undefined> = createSelector(
    (state: RootState) => state.project,
    project => project.gettingWatchContent,
);

export const playlistLoadingContentSelector: Selector<RootState, boolean | undefined> = createSelector(
    (state: RootState) => state.project,
    project => project.gettingPlaylist,
);
