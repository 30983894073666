import React from 'react';
import { ThemeContext } from '../../module/ThemeManager';
import { Stack, Skeleton, Grid, Box } from '@mui/material';
import { theme } from '../../constants/theme';

const SearchGridShimmer = () => {
    return (
        <ThemeContext.Consumer>
            {({ themeStyle }) => {
                const value = 4;
                return (
                    <Box>
                        <Skeleton
                            variant="text"
                            sx={{
                                fontSize: '2rem',
                                width: '150px',
                                marginBottom: '10px',
                                backgroundColor: theme[themeStyle].gray_text,
                            }}
                        />
                        <Box
                            style={{
                                width: '100%',
                                height: 'auto',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                                justifyContent: 'flex-start',
                                gap: '36px',
                            }}>
                            <Box
                                style={{
                                    width: '100%',
                                    height: 'auto',
                                    display: 'grid',
                                    gap: '30px',
                                    gridAutoRows: '1fr',
                                }}
                                sx={{
                                    gridTemplateColumns: { xs: '100%', sm: 'repeat(2, 1fr)', md: 'repeat(2, 1fr)' },
                                }}>
                                {Array.from(new Array(value)).map((_, index) => (
                                    <Grid item key={index}>
                                        <Stack direction="column">
                                            <Skeleton
                                                variant="rounded"
                                                height={245}
                                                sx={{
                                                    width: '100%',
                                                    backgroundColor: theme[themeStyle].gray_text,
                                                    marginTop: '40px',
                                                    marginBottom: '20px',
                                                    borderRadius: '10px',
                                                }}
                                            />
                                            <Stack direction={'column'}>
                                                <Skeleton
                                                    variant="text"
                                                    sx={{
                                                        fontSize: '1rem',
                                                        width: { xs: '200px', sm: '100px', md: '150px', lg: '200px' },

                                                        backgroundColor: theme[themeStyle].darkGray60,
                                                    }}
                                                />
                                                <Skeleton
                                                    variant="text"
                                                    sx={{
                                                        fontSize: '1rem',
                                                        width: { xs: '220px', sm: '120px', md: '170px', lg: '220px' },
                                                        backgroundColor: theme[themeStyle].darkGray60,
                                                    }}
                                                />
                                                <Skeleton
                                                    variant="text"
                                                    sx={{
                                                        fontSize: '1rem',
                                                        width: { xs: '230px', sm: '130px', md: '180px', lg: '230px' },
                                                        backgroundColor: theme[themeStyle].darkGray60,
                                                    }}
                                                />
                                            </Stack>
                                        </Stack>
                                    </Grid>
                                ))}
                            </Box>
                        </Box>
                    </Box>
                );
            }}
        </ThemeContext.Consumer>
    );
};

export { SearchGridShimmer };
