import { ROUTES } from '../types/global/routes.types';
import SpaceDashboardIcon from '@mui/icons-material/SpaceDashboard';
import { AccountCircle } from '@mui/icons-material';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import LoginIcon from '@mui/icons-material/Login';
import { SvgIconTypeMap } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';

export type AuthorizedMenu = 'dashboard' | 'profile' | 'account' | 'settings' | 'logout' | 'divider';
export type UnAuthorized = 'signin';

type MenuItem = {
    [key in AuthorizedMenu]: {
        to: string;
        title: string;
        style: string;
        icon?: OverridableComponent<SvgIconTypeMap<{}, 'svg'>>;
    };
};

type UnAuthorizedMenuItem = {
    [key in UnAuthorized]: {
        to: string;
        title: string;
        style: string;
        icon?: OverridableComponent<SvgIconTypeMap<{}, 'svg'>>;
    };
};

export interface IMenuItem {
    isSignedIn: MenuItem;
    notSignedIn?: UnAuthorizedMenuItem;
}
export const profileMenu: IMenuItem = {
    isSignedIn: {
        dashboard: {
            to: ROUTES.DASHBOARD,
            title: 'Dashboard',
            style: 'text',
            icon: SpaceDashboardIcon,
        },
        profile: {
            to: ROUTES.PROFILE,
            title: 'Profile',
            style: 'text',
            icon: AccountCircle,
        },
        account: {
            to: ROUTES.ACCOUNT,
            title: 'My Account',
            style: 'text',
            icon: ManageAccountsIcon,
        },
        divider: {
            to: '',
            title: '',
            style: '',
        },
        settings: {
            to: ROUTES.SETTINGS,
            title: 'Settings',
            style: 'text',
            icon: Settings,
        },
        logout: {
            to: ROUTES.HOME,
            title: 'Logout',
            style: 'text',
            icon: Logout,
        },
    },
    notSignedIn: {
        signin: {
            to: ROUTES.LOGIN_IN,
            title: 'Login',
            style: 'text',
            icon: LoginIcon,
        },
    },
};
