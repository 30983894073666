import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { RouterProvider } from 'react-router-dom';
import './App.css';
import { GradientBackground } from './components/index';
import { router } from './Routes';
import { AuthContext, LoggedInState } from './module/AuthenticationModule';
import GlobalFonts from './asset/fonts/font';
import { getJWtDetails } from './utils/AppUtils';
import { useThemeContext } from './hook/useThemeManager';
import { ColorScheme, theme } from './constants/theme';
import { toggleTheme } from './redux/appConfigurator/action';
import { useDispatch } from 'react-redux';

function App() {
    const [loggedInStatus, setLoggedInStatus] = useState({
        token: '',
        isSignedIn: false,
        tokenExists: false,
        user_id: '',
    });
    const { isSignedIn, token, tokenExists, user_id } = getJWtDetails();
    const { themeStyle } = useThemeContext();
    const styles = dynamicStyles(themeStyle);
    const setLoginStatus = (token: string, isSignedIn: boolean, tokenExists: boolean, user_id: string) =>
        setLoggedInStatus({ isSignedIn: isSignedIn, token: token, tokenExists: tokenExists, user_id: user_id });

    const providerValues: any = useMemo<LoggedInState>(
        () => ({
            token: loggedInStatus.token,
            isSignedIn: loggedInStatus.isSignedIn,
            tokenExists: loggedInStatus.tokenExists,
            user_id: loggedInStatus.user_id,
            setLoginStatus,
        }),
        [loggedInStatus.isSignedIn, loggedInStatus.token, loggedInStatus.tokenExists, loggedInStatus.user_id],
    );

    useEffect(() => {
        setLoginStatus(token, isSignedIn, tokenExists, user_id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSignedIn, token]);

    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    const dispatch = useDispatch();

    const handleSetTheme = useCallback(() => {
        const isSet = localStorage.getItem('theme_toggled');
        if (mediaQuery.matches && !isSet) {
            dispatch(toggleTheme(mediaQuery.matches ? 'dark' : 'light'));
        }
    }, [mediaQuery.matches, dispatch]);

    useEffect(() => {
        setLoginStatus(token, isSignedIn, tokenExists, user_id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSignedIn, token]);

    useEffect(() => {
        handleSetTheme();
    }, [handleSetTheme]);

    return (
        <GradientBackground style={styles.container}>
            <AuthContext.Provider value={providerValues}>
                <View style={styles.wrapper}>
                    <GlobalFonts />
                    <RouterProvider router={router} />
                </View>
            </AuthContext.Provider>
        </GradientBackground>
    );
}

const dynamicStyles = (colorScheme: ColorScheme) =>
    StyleSheet.create({
        container: {
            flex: 1,
            alignItems: 'center',
            width: '100%',
            justifyContent: 'center',
            height: '100%',
            backgroundColor: theme[colorScheme].pure_black,
        },
        wrapper: {
            alignContent: 'center',
            flex: 1,
            overflow: 'hidden',
        },
    });

export default App;
