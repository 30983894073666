import { IContest, IUserSummary } from '../../types/global/helper';

export const types = {
    GET_USER_ATTENDANCE: 'GET_USER_ATTENDANCE',
    GET_USER_ATTENDANCE_SUCCESS: 'GET_USER_ATTENDANCE_SUCCESS',
    GET_USER_ATTENDANCE_FAIL: 'GET_USER_ATTENDANCE_FAIL',

    SUBMIT_ATTENDANCE_FORM: 'SUBMIT_ATTENDANCE_FORM',
    SUBMIT_ATTENDANCE_FORM_SUCCESS: 'SUBMIT_ATTENDANCE_FORM_SUCCESS',
    SUBMIT_ATTENDANCE_FORM_FAIL: 'SUBMIT_ATTENDANCE_FORM_FAIL',
};

export interface ISubmitAttendanceForm {
    additional_metadata: Record<any, any>;
    /** Contest Id */
    event: string;
}

export interface IGetUserAttendance {
    params?: string;
}

export interface IAttendance {
    user?: IUserSummary;
    created_by?: IUserSummary;
    deleted?: boolean;
    additional_metadata?: Record<any, any>;
    event?: IContest;
    createdAt?: string;
    updatedAt?: string;
}

export interface IAttendanceInitialState {
    userAttendance: IAttendance[];
    gettingAttendance: boolean;
    submittingAttendanceForm: boolean;
    errorMsg: any;
}
