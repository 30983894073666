/* eslint-disable import/no-cycle */
import { call, put, all, takeLatest } from 'redux-saga/effects';
import { types } from './types';
import {
    getAllAuditionsSuccess,
    getAuditionsDetailsFailed,
    getAuditionsDetailsSuccess,
    getAllAuditionsFailed,
    applyToAuditionSuccess,
    applyToAuditionFailed,
    getAuditionRolesSuccess,
    getAuditionRolesFailed,
    getMyApplicationsSuccess,
    getMyApplicationsFailed,
    getSavedCollectionSuccess,
    getSavedCollectionFail,
    saveCollectionSuccess,
    saveCollectionFail,
    deleteSavedCollectionSuccess,
    deleteSavedCollectionFail,
    updateSavedCollectionSuccess,
    updateSavedCollectionFail,
    getSavedCollection as getSavedCollectionAction,
    createAuditionSuccess,
    createAuditionFail,
    createRoleSuccess,
    createRoleFail,
    updateAuditionSuccess,
    updateAuditionFail,
    publishAuditonSuccess,
    publishAuditonFail,
    deleteAuditionSuccess,
    deleteAuditionFail,
    deleteRoleSuccess,
    deleteRoleFail,
    getAllApplicationForAuditionSuccess,
    getAllApplicationForAuditionFail,
    sortApplicationSuccess,
    sortApplicationFail,
    unPublishAuditonFail,
    unPublishAuditonSuccess,
    filterAuditionSuccess,
    filterAuditionFail,
    auditionAdvancedSearchSuccess,
    auditionAdvancedSearchFail,
    LoadMoreAction,
    ILoadMoreResponse,
    loadMoreAuditionSuccess,
    loadMoreAuditionFail,
    withdrawApplicationSuccess,
    withdrawApplicationFail,
    IWithdrawApplication,
} from './actions';
import { Action } from 'redux';
import { api } from '../../configurations/api';
import {
    getAuditionUrl,
    getApplicationsForOneAuditionUrl,
    applyToAuditionUrl,
    getAuditionRoleUrl,
    getMyApplicationsUrl,
    getSavedCollectionUrl,
    saveCollectionUrl,
} from '../../configurations/api/url';
import { getCurrentUsersAuditions } from '../account/actions';
import { globalErrorHandler } from '../error/saga';
import { httpRequest } from '../types';
import { SentryCapture } from '../../analytics/Sentry';

function* loadingMoreAuditions({ payload }: { payload: LoadMoreAction }): any {
    try {
        const request = `${getAuditionUrl}?limit=${payload.limit}&page=${payload.page}`;
        const response = yield call(api, request, httpRequest.GET, null, 0, 0);
        const { data } = response.data;
        console.log(data);
        const result: ILoadMoreResponse = {
            data,
            query: payload,
        };
        if (data.length > 0) {
            yield put(loadMoreAuditionSuccess(result));
        } else {
            const errMsg = {
                data: {
                    message: 'No more content to load',
                    status: 'last index',
                },
                statusText: 'No more content',
                status: 200,
            };
            yield put(loadMoreAuditionFail(errMsg));
        }
    } catch (error: any) {
        SentryCapture(error, 'error');
        yield put(loadMoreAuditionFail(error));
    }
}

function* filterAudition({ payload }: any): any {
    try {
        const request = `${getAuditionUrl}/filter`;
        const response = yield call(api, request, httpRequest.POST, payload, 3, null);
        const { data } = response;
        yield put(filterAuditionSuccess(data));
    } catch (error: any) {
        SentryCapture(error, 'error');
        console.log(error);
        yield put(filterAuditionFail(error));
    }
}

function* advancedSearchAudition({ payload, resolve, reject }: any): any {
    try {
        const request = `${getAuditionUrl}/filter`;
        const response = yield call(api, request, httpRequest.POST, payload, 3, null);
        const { data } = response;
        yield put(auditionAdvancedSearchSuccess(data));
        resolve(data);
    } catch (error: any) {
        SentryCapture(error, 'error');
        yield put(auditionAdvancedSearchFail(error));
        reject(error);
    }
}

function* getAllApplicationsForAudition({ payload, resolve, reject }: any): any {
    const request = getApplicationsForOneAuditionUrl.replace('#id#', payload);

    try {
        const response = yield call(api, request, httpRequest.GET, null, 3, null);
        const { data } = response;
        yield put(getAllApplicationForAuditionSuccess(data));
        resolve(data);
    } catch (error) {
        SentryCapture(error, 'error');
        yield put(getAllApplicationForAuditionFail(error));
        reject(error);
    }
}

function* sortApplication({ payload, resolve, reject }: any): any {
    const { id, sortType } = payload;
    const request = `${applyToAuditionUrl}/${id}`;
    try {
        const response = yield call(api, request, httpRequest.PATCH, { filing_status: sortType }, 0, 0);
        const { data } = response.data;
        yield put(sortApplicationSuccess(data));
        resolve(data);
    } catch (error) {
        SentryCapture(error, 'error');
        yield put(sortApplicationFail(error));
        reject(error);
    }
}

function* withdrawApplication({
    payload,
    resolve,
    reject,
}: {
    payload: IWithdrawApplication;
    resolve: any;
    reject: any;
}): any {
    const { applicationId } = payload;
    const request = `${applyToAuditionUrl}${applicationId}`;
    try {
        yield call(api, request, httpRequest.DELETE, {}, 0, 0);
        yield put(withdrawApplicationSuccess(payload));
        resolve();
    } catch (error: any) {
        SentryCapture(error, 'error');
        yield put(withdrawApplicationFail(error));
        reject(error);
    }
}

function* createAudition({ payload, resolve, reject }: any): any {
    try {
        const response = yield call(api, getAuditionUrl, httpRequest.POST, payload, 0, 0);
        const { data } = response;
        yield put(createAuditionSuccess(data));
        resolve(data);
    } catch (error) {
        SentryCapture(error, 'error');
        yield put(createAuditionFail(error));
        reject(error);
    }
}

function* createAuditionRole({ payload, resolve, reject }: any): any {
    const requestUrl = `${getAuditionUrl}/${payload.auditionId}/roles`;
    try {
        const response = yield call(api, requestUrl, httpRequest.POST, payload.body, 0, 0);
        const { data } = response;
        yield put(createRoleSuccess(data));
        resolve(data);
    } catch (error) {
        SentryCapture(error, 'error');
        yield put(createRoleFail(error));
        reject(error);
    }
}

function* updateAudition({ payload, resolve, reject }: any): any {
    const { isImageUpload, id, body, uploadStyle } = payload;
    let requestUrl = `${getAuditionUrl}/${id}`;
    if (uploadStyle === 'imagePicker') {
        requestUrl = `${getAuditionUrl}/image/${id}`;
    }
    try {
        const response = yield call(api, requestUrl, httpRequest.PATCH, body, 0, 0, !!isImageUpload);
        const { data } = response;
        yield put(updateAuditionSuccess(data));
        resolve(data);
    } catch (error) {
        SentryCapture(error, 'error');
        yield put(updateAuditionFail(error));
        reject(error);
    }
}

function* publishAudition({ payload, resolve, reject }: any): any {
    const requestUrl = `${getAuditionUrl}/publish/${payload}`;
    try {
        const response = yield call(api, requestUrl, httpRequest.PATCH, {}, 0, 0);
        const { data } = response;
        yield put(publishAuditonSuccess(data));
        yield put(getCurrentUsersAuditions());
        resolve(data);
    } catch (error) {
        SentryCapture(error, 'error');
        yield put(publishAuditonFail(error));
        reject(error);
    }
}

function* unPublishAudition({ payload, resolve, reject }: any): any {
    const requestUrl = `${getAuditionUrl}/unpublish/${payload}`;
    try {
        const response = yield call(api, requestUrl, httpRequest.PATCH, {}, 0, 0);
        const { data } = response;
        yield put(unPublishAuditonSuccess(data));
        yield put(getCurrentUsersAuditions());
        resolve(data);
    } catch (error) {
        SentryCapture(error, 'error');
        yield put(unPublishAuditonFail(error));
        reject(error);
    }
}

function* deleteAudition({ payload, resolve, reject }: any): any {
    const requestUrl = `${getAuditionUrl}/${payload}`;
    try {
        const response = yield call(api, requestUrl, httpRequest.DELETE, {}, 0, 0);
        const { data } = response;
        yield put(deleteAuditionSuccess(payload));
        yield put(getCurrentUsersAuditions());
        resolve(data);
    } catch (error) {
        SentryCapture(error, 'error');
        yield put(deleteAuditionFail(error));
        reject(error);
    }
}

function* deleteAuditionRole({ payload, resolve, reject }: any): any {
    const requestUrl = `${getAuditionRoleUrl}/${payload}`;
    try {
        const response = yield call(api, requestUrl, httpRequest.DELETE, {}, 0, 0);
        const { data } = response;
        yield put(deleteRoleSuccess(payload));
        resolve(data);
    } catch (error) {
        SentryCapture(error, 'error');
        yield put(deleteRoleFail(error));
        reject(error);
    }
}

function* getAllAuditions({ payload, resolve, reject }: any): any {
    let requestUrl = getAuditionUrl;
    try {
        const { url } = payload;
        // Incase we have a filter
        if (url) {
            requestUrl = `${getAuditionUrl}?${url}`;
        }
        // const { audition } = yield select();
        const response = yield call(api, requestUrl, httpRequest.GET, null, 0, 0);
        const { data } = response;
        yield put(getAllAuditionsSuccess(data));
        resolve(data);
    } catch (error) {
        SentryCapture(error, 'error');
        yield put(getAllAuditionsFailed(error));
        reject(error);
    }
}

function* getAuditionsDetails({ payload, resolve, reject }: any): any {
    const requestUrl = `${getAuditionUrl}/${payload}`;
    try {
        const response = yield call(api, requestUrl, httpRequest.GET, null, 0, 0);
        const { data } = response;
        yield put(getAuditionsDetailsSuccess(data));
        resolve(data);
    } catch (error: any) {
        SentryCapture(error, 'error');
        const { message } = error.data;
        yield put(getAuditionsDetailsFailed(message));
        reject(error);
    }
}

function* applyToAudition({ payload, resolve, reject }: any): any {
    const requestUrl = `${applyToAuditionUrl}/${payload}`;
    try {
        const response = yield call(api, requestUrl, httpRequest.POST, {}, 0, 0);
        const { data } = response;
        yield put(applyToAuditionSuccess(data));
        resolve(data);
    } catch (error: any) {
        SentryCapture(error, 'error');
        const { message } = error.data || {};
        yield call(globalErrorHandler, error);
        yield put(applyToAuditionFailed(message || error));
        reject(error);
    }
}

function* getAuditionRoles({ payload, resolve, reject }: any): any {
    const requestUrl = `${getAuditionRoleUrl}?audition=${payload}`;
    try {
        const response = yield call(api, requestUrl, httpRequest.GET, null, 0, 0);
        const { data } = response;
        yield put(getAuditionRolesSuccess(data));
        resolve(data);
    } catch (error: any) {
        SentryCapture(error, 'error');
        const { message } = error.data;
        yield put(getAuditionRolesFailed(message || error));
        reject(error);
    }
}

function* getMyApplications({ resolve, reject }: any): any {
    try {
        const response = yield call(api, getMyApplicationsUrl, httpRequest.GET, null, 0, 0);
        const { data } = response;
        yield put(getMyApplicationsSuccess(data));
        resolve(data);
    } catch (error: any) {
        SentryCapture(error, 'error');
        yield put(getMyApplicationsFailed(error));
        yield call(globalErrorHandler, error);
        reject(error);
    }
}

function* getSavedCollection(): any {
    try {
        const response = yield call(api, getSavedCollectionUrl, httpRequest.GET, null, 0, 0);
        const { data } = response.data;
        yield put(getSavedCollectionSuccess(data));
    } catch (error) {
        SentryCapture(error, 'error');
        yield put(getSavedCollectionFail(error));
    }
}

// INFO: GET SAVED COLLECTION
function* saveCollection({ payload }: any): any {
    try {
        const response = yield call(api, saveCollectionUrl, httpRequest.POST, payload, 0, 0);
        const { data } = response.data;
        yield put(saveCollectionSuccess(data));
        yield put(getSavedCollectionAction());
    } catch (error) {
        SentryCapture(error, 'error');
        yield put(saveCollectionFail(error));
    }
}

function* deleteSavedCollection({ payload, resolve, reject }: any): any {
    const request = `${saveCollectionUrl}/${payload}`;
    try {
        const response = yield call(api, request, httpRequest.DELETE, {}, 0, 0);
        const { data } = response;
        yield put(deleteSavedCollectionSuccess(payload));
        resolve(data);
    } catch (error) {
        SentryCapture(error, 'error');
        yield put(deleteSavedCollectionFail(error));
        reject(error);
    }
}

// Ad ID here for payload
function* updateSavedCollection({ payload, resolve, reject }: any): any {
    const request = `${saveCollectionUrl}/${payload.id}`;
    try {
        const response = yield call(api, request, httpRequest.PATCH, payload.body, 0, 0);
        const { data } = response;
        yield put(updateSavedCollectionSuccess(data));
        resolve(data);
    } catch (error) {
        SentryCapture(error, 'error');
        yield put(updateSavedCollectionFail(error));
        reject(error);
    }
}

/** Interfaces */
interface TaskAction extends Action, IWithdrawApplication {
    type: string;
    payload: any;
    resolve: any;
    reject: any;
}

/** **************** WATCHERS ************************** */

function* getAllAuditionsWatcher() {
    yield takeLatest(types.GET_ALL_AUDITIONS, getAllAuditions);
}

function* getAuditionsDetailsWatcher() {
    yield takeLatest(types.GET_AUDITION_DETAIL, getAuditionsDetails);
}

function* applyToAuditionWatcher() {
    yield takeLatest(types.APPLY_TO_AUDITION, applyToAudition);
}

function* getAuditionrolesWatcher() {
    yield takeLatest(types.GET_ROLES, getAuditionRoles);
}

function* getMyApplicationsWatcher() {
    yield takeLatest(types.GET_MY_APPLICATIONS, getMyApplications);
}

function* getSavedCollectionWatcher() {
    yield takeLatest(types.GET_SAVED_COLLECTION, getSavedCollection);
}

function* saveCollectionWatcher() {
    yield takeLatest(types.SAVE_COLLECTION, saveCollection);
}

function* deleteSavedCollectionWatcher() {
    yield takeLatest(types.DELETE_SAVED_COLLECTION, deleteSavedCollection);
}

function* updateSavedCollectionWatcher() {
    yield takeLatest(types.UPDATE_SAVED_COLLECTION, updateSavedCollection);
}

function* createAuditionWatcher() {
    yield takeLatest(types.CREATE_AUDITION, createAudition);
}

function* createAuditionRoleWatcher() {
    yield takeLatest(types.CREATE_ROLE, createAuditionRole);
}

function* updateAuditionWatcher() {
    yield takeLatest(types.UPDATE_AUDITION, updateAudition);
}

function* publishAuditionWatcher() {
    yield takeLatest(types.PUBLISH_AUDITION, publishAudition);
}
function* unPublishAuditonWatcher() {
    yield takeLatest(types.UNPUBLISH_AUDITION, unPublishAudition);
}

function* deleteAuditionWatcher() {
    yield takeLatest(types.DELETE_AUDITION, deleteAudition);
}

function* deleteAuditionRoleWatcher() {
    yield takeLatest(types.DELETE_ROLE, deleteAuditionRole);
}

function* getAllAuditionsApplicationWatcher() {
    yield takeLatest(types.GET_ALL_APPLICATION_FOR_AUDITION, getAllApplicationsForAudition);
}

function* sortApplicationWatcher() {
    yield takeLatest(types.SORT_APPLICATION, sortApplication);
}

function* filterAuditionWatcher() {
    yield takeLatest(types.FILTER_AUDITION, filterAudition);
}

function* advancedSearchAuditionWatcher() {
    yield takeLatest(types.ADVANCED_AUDITION_SEARCH, advancedSearchAudition);
}

function* loadingMoreAuditionsWatcher() {
    yield takeLatest(types.LOAD_MORE_AUDITIONS as any, loadingMoreAuditions);
}

function* withdrawApplicationWatcher() {
    yield takeLatest<TaskAction>(types.WITHDRAW_APPLICATION, withdrawApplication);
}

export default function* auditionSaga() {
    yield all([
        getAllAuditionsWatcher(),
        getAuditionsDetailsWatcher(),
        applyToAuditionWatcher(),
        getAuditionrolesWatcher(),
        getMyApplicationsWatcher(),
        getSavedCollectionWatcher(),
        saveCollectionWatcher(),
        deleteSavedCollectionWatcher(),
        updateSavedCollectionWatcher(),
        createAuditionWatcher(),
        createAuditionRoleWatcher(),
        updateAuditionWatcher(),
        publishAuditionWatcher(),
        unPublishAuditonWatcher(),
        deleteAuditionWatcher(),
        deleteAuditionRoleWatcher(),
        getAllAuditionsApplicationWatcher(),
        sortApplicationWatcher(),
        filterAuditionWatcher(),
        advancedSearchAuditionWatcher(),
        loadingMoreAuditionsWatcher(),
        withdrawApplicationWatcher(),
    ]);
}
