import { createAction } from 'redux-act';
import {
    ICompleteMultiPartUpload,
    ICompleteMultipartUploadResponse,
    IInitializeUploadSession,
    IProgressReporter,
    ISaveUpload,
    IUploadPayload,
    IUploadSession,
    types,
} from './types';
import { IErrorType } from '../../constants/types';

export const startUpload = createAction<IUploadPayload>(types.START_UPLOAD);
export const startUploadSuccess = createAction(types.START_UPLOAD_SUCCESS);
export const startUploadFail = createAction<IErrorType>(types.START_UPLOAD_FAIL);

export const getUploadSession = createAction<IInitializeUploadSession>(types.GET_UPLOAD_SESSION);
export const getUploadSessionSuccess = createAction<IUploadSession>(types.GET_UPLOAD_SESSION_SUCCESS);
export const getUploadSessionFail = createAction<IErrorType>(types.GET_UPLOAD_SESSION_FAIL);

export const completeMultiPartUpload = createAction<ICompleteMultiPartUpload>(types.COMPLETE_MULTIPART_UPLOAD);
export const completeMultiPartUploadSuccess = createAction<ICompleteMultipartUploadResponse>(
    types.COMPLETE_MULTIPART_UPLOAD_SUCCESS,
);
export const completeMultiPartUploadFail = createAction<IErrorType>(types.COMPLETE_MULTIPART_UPLOAD_FAIL);

export const reportProgress = createAction<IProgressReporter>(types.PROGRESS_REPORT);
export const pauseUpload = createAction<string | undefined>(types.PAUSE_UPLOAD);
export const resumeUpload = createAction<string | undefined>(types.RESUME_UPLOAD);
export const uploadComplete = createAction(types.UPLOAD_COMPLETE);
export const uploadFailed = createAction(types.UPLOAD_FAILED);

export const saveUpload = createAction<ISaveUpload>(types.SAVE_UPLOAD);

export const stopNetworkWatch = createAction(types.STOP_WATCHING_NETWORK);

export const terminateUplaod = createAction(types.TERMINATE_UPLOAD);

export const clearMessage = createAction(types.CLEAR_MESSAGE);

export const restartUploadAfterReload = createAction<IUploadPayload | undefined>(types.RESUME_UPLOAD_AFTER_RELOAD);
