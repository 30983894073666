import React from 'react';
import Typography from '@mui/material/Typography';
import { Box, SxProps, Theme } from '@mui/material';
import { useLottie } from 'lottie-react';

interface IReplaceCharacterWithLottie {
    sentence: string;
    targetWord: string;
    charToReplace: string;
    animationData: any;
    style: React.CSSProperties;
    replacementStyle: SxProps<Theme>;
}

const ReplaceCharacterWithLottie: React.FC<IReplaceCharacterWithLottie> = ({
    sentence,
    targetWord,
    charToReplace,
    animationData,
    style,
    replacementStyle,
}) => {
    const words = sentence.split(' ');
    const options = {
        animationData: animationData,
        loop: true,
    };
    const { View } = useLottie(options);

    const modifiedSentence = words.map((word, index) => {
        if (word.toLowerCase() === targetWord.toLowerCase()) {
            const charIndex = word.toLowerCase().indexOf(charToReplace.toLowerCase());

            if (charIndex !== -1) {
                const before = word.slice(0, charIndex);
                const after = word.slice(charIndex + 1);

                return (
                    <span key={index} style={{ display: 'inline-flex', alignItems: 'center' }}>
                        {before} <Box sx={replacementStyle}>{View}</Box> {after}
                    </span>
                );
            }
        }

        return <span key={index}>{word} </span>;
    });

    return <Typography style={style}>{modifiedSentence}</Typography>;
};

export { ReplaceCharacterWithLottie };
