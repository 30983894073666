import { all, call, put, takeLatest } from 'redux-saga/effects';
import { api } from '../../configurations/api';
import { attendanceUrl } from '../../configurations/api/url';
import {
    getUserAttendanceFail,
    getUserAttendanceSuccess,
    submitAttendanceFromFail,
    submitAttendanceFromSuccess,
} from './actions';
import { IGetUserAttendance, ISubmitAttendanceForm, types } from './types';
import { globalErrorHandler } from '../error/saga';
import { SentryCapture } from '../../analytics/Sentry';
import { showToastMessage } from '../../utils/AppUtils';
import { httpRequest } from '../types';

function* submitForm({ payload, resolve, reject }: { payload: ISubmitAttendanceForm; resolve: any; reject: any }): any {
    const { additional_metadata, event } = payload;
    try {
        const response = yield call(api, attendanceUrl, httpRequest.POST, { additional_metadata, event });
        const { data } = response;
        yield put(submitAttendanceFromSuccess(data.data));
        yield call(showToastMessage, 'Saved Successfully', 'success');
        if (resolve) {
            resolve(response);
        }
    } catch (error: any) {
        SentryCapture(error, 'error');
        yield put(submitAttendanceFromFail(error));
        yield call(globalErrorHandler, error);
        if (reject) {
            reject(error);
        }
    }
}

function* getUsersAttendance({ payload }: { payload: IGetUserAttendance }): Generator<any, any, any> {
    const { params } = payload;
    let url = attendanceUrl;
    if (params) {
        url += `?${params}`;
    }
    try {
        const response = yield call(api, url, httpRequest.GET, {});
        const { data } = response;
        yield put(getUserAttendanceSuccess(data.data));
    } catch (error: any) {
        SentryCapture(error, 'error');
        yield put(getUserAttendanceFail(error));
    }
}

function* submitFormWatcher() {
    yield takeLatest<any>(types.SUBMIT_ATTENDANCE_FORM, submitForm);
}

function* getUsersAttendanceWatcher() {
    yield takeLatest<any>(types.GET_USER_ATTENDANCE, getUsersAttendance);
}

export default function* attendanceSaga() {
    yield all([submitFormWatcher(), getUsersAttendanceWatcher()]);
}
