export const types = {
    GET_CURRENT_USER_TALENT_PROFILE: 'GET_CURRENT_USER_TALENT_PROFILE',
    GET_CURRENT_USER_TALENT_PROFILE_SUCCESS: 'GET_CURRENT_USER_TALENT_PROFILE_SUCCESS',
    GET_CURRENT_USER_TALENT_PROFILE_FAIL: 'GET_CURRENT_USER_TALENT_PROFILE_FAIL',

    GET_TALENT_PROFILE_DETAILS: 'GET_TALENT_PROFILE_DETAILS',
    GET_TALENT_PROFILE_DETAILS_SUCCESS: 'GET_TALENT_PROFILE_DETAILS_SUCCESS',
    GET_TALENT_PROFILE_DETAILS_FAIL: 'GET_TALENT_PROFILE_DETAILS_FAIL',

    GET_ALL_TALENT_PROFILES: 'GET_ALL_TALENT_PROFILES',
    GET_ALL_TALENT_PROFILES_SUCCESS: 'GET_ALL_TALENT_PROFILES_SUCCESS',
    GET_ALL_TALENT_PROFILES_FAIL: 'GET_ALL_TALENT_PROFILES_FAIL',
    RESET_TALENT_PROFILE: 'RESET_TALENT_PROFILE',
    CREATE_TALENT_PROFILE: 'CREATE_TALENT_PROFILE',
    CREATE_TALENT_PROFILE_SUCCESS: 'CREATE_TALENT_PROFILE_SUCCESS',
    CREATE_TALENT_PROFILE_FAIL: 'CREATE_TALENT_PROFILE_FAIL',

    UPDATE_TALENT_PROFILE: 'UPDATE_TALENT_PROFILE',
    UPDATE_TALENT_PROFILE_SUCCESS: 'UPDATE_TALENT_PROFILE_SUCCESS',
    UPDATE_TALENT_PROFILE_FAIL: 'UPDATE_TALENT_PROFILE_FAIL',

    PUBLISH_TALENT_PROFILE: 'PUBLISH_TALENT_PROFILE',
    PUBLISH_TALENT_PROFILE_SUCCESS: 'PUBLISH_TALENT_PROFILE_SUCCESS',
    PUBLISH_TALENT_PROFILE_FAIL: 'PUBLISH_TALENT_PROFILE_FAIL',

    UNPUBLISH_TALENT_PROFILE: 'UNPUBLISH_TALENT_PROFILE',
    UNPUBLISH_TALENT_PROFILE_SUCCESS: 'UNPUBLISH_TALENT_PROFILE_SUCCESS',
    UNPUBLISH_TALENT_PROFILE_FAIL: 'UNPUBLISH_TALENT_PROFILE_FAIL',

    DELETE_TALENT_PROFILE: 'DELETE_TALENT_PROFILE',
    DELETE_TALENT_PROFILE_SUCCESS: 'DELETE_TALENT_PROFILE_SUCCESS',
    DELETE_TALENT_PROFILE_FAIL: 'DELETE_TALENT_PROFILE_FAIL',

    DELETE_SINGLE_TALENT_IMAGE: 'DELETE_SINGLE_TALENT_IMAGE',
    DELETE_SINGLE_TALENT_IMAGE_SUCCESS: 'DELETE_SINGLE_TALENT_IMAGE_SUCCESS',
    DELETE_SINGLE_TALENT_IMAGE_FAIL: 'DELETE_SINGLE_TALENT_IMAGE_FAIL',

    DELETE_SINGLE_IMAGE_FROM_TALENT__GALLERY: 'DELETE_SINGLE_IMAGE_FROM_TALENT__GALLERY',
    DELETE_SINGLE_IMAGE_FROM_TALENT__GALLERY_SUCCESS: 'DELETE_SINGLE_IMAGE_FROM_TALENT__GALLERY_SUCCESS',
    DELETE_SINGLE_IMAGE_FROM_TALENT__GALLERY_FAIL: 'DELETE_SINGLE_IMAGE_FROM_TALENT__GALLERY_FAIL',

    UPLOAD_IMAGE: 'UPLOAD_IMAGE',
    UPLOAD_IMAGE_SUCCESS: 'UPLOAD_IMAGE_SUCCESS',
    UPLOAD_IMAGE_FAIL: 'UPLOAD_IMAGE_FAIL',

    GET_TALENT_PAST_WORK: 'GET_TALENT_PAST_WORK',
    GET_TALENT_PAST_WORK_SUCCESS: 'GET_TALENT_PAST_WORK_SUCCESS',
    GET_TALENT_PAST_WORK_FAIL: 'GET_TALENT_PAST_WORK_FAIL',

    SET_PROFILE_FOR_EDIT: 'SET_PROFILE_FOR_EDIT',

    ADD_TALENT_FILTER: 'ADD_TALENT_FILTER',
    REMOVE_TALENT_FILTER: 'REMOVE_TALENT_FILTER',

    TOGGLE_TALENT_FILTER: 'TOGGLE_TALENT_FILTER',

    FILTER_TALENT: 'FILTER_TALENT',
    FILTER_TALENT_SUCCESS: 'FILTER_TALENT_SUCCESS',
    FILTER_TALENT_FAIL: 'FILTER_TALENT_FAIL',

    GET_DISCOVER: 'GET_DISCOVER',
    GET_DISCOVER_SUCCESS: 'GET_DISCOVER_SUCCESS',
    GET_DISCOVER_FAIL: 'GET_DISCOVER_FAIL',

    GET_ACTS: 'GET_TALENT_ACTS',
    GET_ACTS_SUCCESS: 'GET_TALENT_ACTS_SUCCESS',
    GET_ACTS_FAIL: 'GET_TALENT_ACTS_FAIL',

    GET_SINGLE_ACT: 'GET_SINGLE_ACT',
    GET_SINGLE_ACT_SUCCESS: 'GET_SINGLE_ACT_SUCCESS',
    GET_SINGLE_ACT_FAIL: 'GET_SINGLE_ACT_FAIL',

    CREATE_ACT: 'CREATE_ACT_INITIAL',
    CREATE_ACT_SUCCESS: 'CREATE_ACT_CREATE_ACT_INITIAL_SUCCESS',
    CREATE_ACT_FAIL: 'CREATE_ACT_CREATE_ACT_INITIAL_FAIL',

    DELETE_ACT: 'DELETE_INITIAL_ACT',
    DELETE_ACT_SUCCESS: 'DELETE_ACT_INITIAL_SUCCESS',
    DELETE_ACT_FAIL: 'DELETE_ACT_INITIAL_FAIL',

    UPDATE_ACT: 'UPDATE_INITIAL_ACT',
    UPDATE_ACT_SUCCESS: 'UPDATE_ACT_INITIAL_SUCCESS',
    UPDATE_ACT_FAIL: 'UPDATE_ACT_INITIAL_FAIL',

    GET_MY_ACTS: 'GET_MY_INITIAL_ACTS',
    GET_MY_ACTS_SUCCESS: 'GET_MY_ACTS_INITIAL_SUCCESS',
    GET_MY_ACTS_FAIL: 'GET_MY_ACTS_INITIAL_FAIL',
    SET_TALENT_DETAILS: 'SET_TALENT_DETAILS',
};
