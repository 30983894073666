import { restoreInitialState } from '../../utils/AppUtils';
import { ActivityResponse, IActivityInitialState, types } from './types';
import produce from 'immer';

const initialState: IActivityInitialState = {
    myActivities: [],
    gettingActivity: false,
    savingActivity: false,
    errMsg: null,
    searchSuggestions: [],
};

const reducer = (state = initialState, action: any) => {
    const { type, payload } = action;

    switch (type) {
        case types.GET_MY_ACTIVITIES:
            return produce(state, draft => {
                draft.gettingActivity = true;
            });
        case types.GET_MY_ACTIVITIES_SUCCESS:
            return produce(state, draft => {
                draft.gettingActivity = false;
                draft.myActivities = payload; // Parse this response
            });
        case types.GET_MY_ACTIVITIES_FAIL:
            return produce(state, draft => {
                draft.gettingActivity = false;
                draft.errMsg = payload;
            });
        case types.SAVE_ACTIVITY:
            return produce(state, draft => {
                draft.savingActivity = true;
            });
        case types.SAVE_ACTIVITY_SUCCESS:
            return produce(state, draft => {
                draft.savingActivity = false;
            });
        case types.SAVE_ACTIVITY_FAIL:
            return produce(state, draft => {
                draft.savingActivity = false;
            });
        case types.GET_SEARCH_SUGGESTIONS:
            return produce(state, draft => {
                draft.gettingActivity = true;
            });
        case types.GET_SEARCH_SUGGESTIONS_SUCCESS:
            return produce(state, draft => {
                draft.searchSuggestions = payload;
                draft.gettingActivity = false;
            });
        case types.GET_SEARCH_SUGGESTIONS_FAIL:
            return produce(state, draft => {
                draft.errMsg = payload;
                draft.gettingActivity = false;
            });
        case types.HIDE_MY_ACTIVITY:
            return produce(state, draft => {
                draft.processing = true;
            });
        case types.HIDE_MY_ACTIVITY_SUCCESS:
            return produce(state, draft => {
                const { _id } = payload as ActivityResponse;
                const filteredActivity = draft.myActivities.filter(aty => _id !== aty._id);
                draft.myActivities = filteredActivity;
                draft.processing = false;
            });
        case types.HIDE_MY_ACTIVITY_FAIL:
            return produce(state, draft => {
                draft.processing = false;
            });
        case types.RESET_ACTIVITY_DATA:
            return restoreInitialState(state, initialState);
        default:
            return state;
    }
};

export { reducer };
