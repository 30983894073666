import produce from 'immer';
import { ILiveInitialState, types } from './types';

const initialState: ILiveInitialState = {
    connected: false,
    socket: null,
};

const reducer = (state = initialState, action: any) => {
    const { type, payload } = action;
    switch (type) {
        case types.CONNECT_SUCCESS:
            return produce(state, (draftState: typeof initialState) => {
                draftState.connected = payload.connected;
                draftState.socket = payload;
            });
        case types.DISCONNECT_SUCCESS:
            return produce(state, (draftState: typeof initialState) => {
                draftState.connected = false;
                draftState.socket = null;
            });
        default:
            return state;
    }
};

export { reducer };
