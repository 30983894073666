import React from 'react';
import { StyleSheet } from 'react-native';
import { Container } from '@mui/system';
import { Typography } from '@mui/material';
import { FooterContainer } from '../../components/ScreenContainer/FooterScreenContainer/FooterContainer';
import { strings } from '../../constants/strings';

const styles = StyleSheet.create({
    container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
    },
});

const Shop = () => {
    return (
        <Container disableGutters style={styles.container} maxWidth={false}>
            <FooterContainer>
                <Typography>{strings.shop}</Typography>
            </FooterContainer>
        </Container>
    );
};

export { Shop };
