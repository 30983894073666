import React from 'react';
import { StyleSheet } from 'react-native';
import { Container } from '@mui/system';
import { SearchContainer } from '../../components/ScreenContainer/SearchContainer/SearchContainer';
import { useDispatch, useSelector } from 'react-redux';
import { searchResultSelector, suggestionsSelector } from './selector';
import { DiscoverySearchUnparsedResponse } from '../../types/global/generic.types';
import { replaceRouteParam } from '../../utils/routesProcessor';
import { ROUTES } from '../../types/global/routes.types';
import { IFeature } from '../../redux/discovery/types';
import { navigate } from '../../redux/navigator/action';
import { IActSummary, IProjectSummary } from '../../types/global/media.types';
import { getProject } from '../../redux/project/actions';
import { UserSummary } from '../../types/global/helper';
import { EsEmptyContentCard } from '../../components/EsCards/EsEmptyContentCard';
import cameraOutline from '../../asset/images/icons/es_outline_camera.svg';
import arrowBack from '../../asset/images/icons/es_arrow_back.svg';
import { hasNonEmptyArrayField } from '../../utils/AppUtils';
import { setMute } from '../../redux/player/action';

const styles = StyleSheet.create({
    container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
    },
});

const Search = () => {
    const searchResults = useSelector(searchResultSelector);
    const { suggestedContest, suggestedProjects, suggestedTalents } = useSelector(suggestionsSelector);
    const dispatch = useDispatch();
    const handleOnContestPress = (data: IFeature) => {
        let route = replaceRouteParam(ROUTES.ESVIEW_CONTEST, 'contestId', data._id);
        if (data && data.kind === 'physical' && data.contest_id) {
            route = replaceRouteParam(ROUTES.EVENT, 'contestId', data.contest_id!);
            if (data.preferred_name) {
                route = replaceRouteParam(ROUTES.EVENT_CUSTOM, 'hostName', data.preferred_name);
                route = replaceRouteParam(route, 'contestId', data.contest_id!);
            }
        }
        dispatch(
            navigate({
                routes: `${route}search=true`,
                skipAuthentication: true,
            }),
        );
    };

    const handleProjectPress = (project: IProjectSummary) => {
        dispatch(getProject({ id: project.video_id as string }));
        dispatch(setMute(false));
    };

    const handleOnUserPress = (user: UserSummary) => {
        const route = replaceRouteParam(ROUTES.ESMY_PROFILE, 'profileId?', user.userName);
        dispatch(navigate({ routes: route }));
    };

    const handleBackNavigation = () => {
        dispatch(navigate({ routes: -1 }));
    };

    const handleOnClickPlayForActsAndSorts = (project: IActSummary) => {
        dispatch(getProject({ id: project.video_id as string, mediaType: 'act' }));
        dispatch(setMute(false));
    };

    return (
        <Container disableGutters style={styles.container} maxWidth={false}>
            {hasNonEmptyArrayField(searchResults) ? (
                <SearchContainer
                    data={searchResults as DiscoverySearchUnparsedResponse}
                    suggestedContest={suggestedContest}
                    suggestedProjects={suggestedProjects}
                    suggestedTalent={suggestedTalents}
                    onActPressed={handleOnClickPlayForActsAndSorts}
                    onClickTrendingCard={handleOnContestPress}
                    onClickUserCard={handleOnUserPress}
                    onMediaCardPressed={handleProjectPress}
                    onClickActUserIcon={handleOnUserPress}
                    handleContestSuggestionCLick={handleOnContestPress}
                    handleProjectSuggestionClick={handleProjectPress}
                    handleUserSuggestionClick={handleOnUserPress}
                />
            ) : (
                <EsEmptyContentCard
                    outlineIcon={cameraOutline}
                    outlineIconAlt="camera icon"
                    filledIcon={arrowBack}
                    filledIconAlt="upload video icon button"
                    title="No results found"
                    description="Change your parameters and search again"
                    onClickHandle={handleBackNavigation}
                    buttonText="Go Home"
                />
            )}
        </Container>
    );
};

export { Search };
