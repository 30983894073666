export const errorMessages = {
    unauthorized: {
        header: 'Unathorized Access',
        message: 'You do not have permission to access this resource',
    },
    wrong_param: {
        header: 'Invalid Request',
        message: 'You must have provided a wrong id, please check and try again',
    },
    generic: {
        header: 'Something went wrong',
        message: 'We are unsure of what happened, please go back and try again',
    },
    server: {
        header: 'Our Server is down',
        message: 'Please forgive us, our server is currently down, try again in a few.',
    },
    non_existent: {
        header: 'This page does not exist',
        message: 'Please Check and Try again',
    },
};
