import { ChangeEvent, useCallback, useState } from 'react';
import { StyleSheet } from 'react-native';
import { useThemeContext } from '../../hook/useThemeManager';
import { ColorScheme, theme } from '../../constants/theme';
import { FormLabel, FormControl, Box, CircularProgress, InputAdornment, SelectChangeEvent } from '@mui/material';
import { EsFormWrapper } from './EsFormWrapper';
import { FONT_ENUM } from '../../types/global/fonts.types';
import { CustomTextField } from '../TextInput/CustomTextField';
import { debounce } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { userActionSelector } from '../../selectors/selectors';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { userNameFieldReset } from '../../redux/user/actions';
import { CustomDatePicker } from '../DatePicker/CustomDatePicker';
import { CustomSelect } from '../Select/CustomSelect';
import { CustomPhoneTextField } from '../TextInput/CustomPhoneTextField';
import dayjs from 'dayjs';
import { isEmpty } from '../../utils/lodash';
import { CountryData } from 'react-phone-input-2';
import { validate } from '../../utils/validators';

type UserPersonalDetails = {
    firstName: string;
    lastName: string;
    userName: string;
    birth: string;
    gender: string;
    country: string;
    phoneNumber: string;
};

type UserFormPersonalDetails = UserPersonalDetails & {
    updateFields: (fields: Partial<UserPersonalDetails>) => void;
    userNameAvailable: (userName: string) => void;
    onCountryChange?: (countryCode: CountryData) => void;
    onPhoneFieldFocus?: () => void;
    onPhoneFieldBlue?: () => void;
    isErrorStatePhone?: boolean;
    errMsg?: string;
    isPhoneFieldInFocus?: boolean;
};

const EsPersonalDetail = ({
    firstName,
    lastName,
    userName,
    birth,
    gender,
    phoneNumber,
    updateFields,
    userNameAvailable,
    onCountryChange,
    onPhoneFieldFocus,
    onPhoneFieldBlue,
    isErrorStatePhone,
    errMsg,
    isPhoneFieldInFocus,
}: UserFormPersonalDetails) => {
    const dispatch = useDispatch();
    const { themeStyle } = useThemeContext();
    const styles = dynamicStyles(themeStyle);

    const { validatingUsernameValue: isSearching, usernameAvailable, usernameTaken } = useSelector(userActionSelector);

    const debouncedSearch = debounce((userName: string) => userNameAvailable(userName), 1000);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const search = useCallback((userName: string) => debouncedSearch(userName), []);

    const handleUsernameSearch = (e: ChangeEvent<HTMLInputElement>) => {
        const usernamePattern = /^[a-zA-Z0-9_]+$/;
        if (usernamePattern.test(e.target.value) || e.target.value === '') {
            dispatch(userNameFieldReset());
            updateFields({ userName: e.target.value });
            if (e.target.value.length > 4) search(e.target.value);
            validateUsername(e.target.value);
        }
    };
    const genderOptions = ['Female', 'Male', 'Others'];
    const handlePhoneChange = (e: any, data: CountryData) => {
        updateFields({ phoneNumber: e });
        if (onCountryChange) {
            onCountryChange(data);
        }
    };

    const [usernameValid, setUsernameValid] = useState({
        usernameHasLength: false,
        usenameHasCharacter: false,
        errorMessage: '',
    });
    const ageLimit = dayjs().subtract(13, 'year').format('YYYY-MM-DD');

    const usernameRequirements = [
        { task: 'At least 5 characters', validator: usernameValid.usernameHasLength },
        {
            task: 'Characters Only',
            validator: usernameValid.usenameHasCharacter,
        },
    ];

    const validateUsername = (text: string) => {
        const userNameLength = validate.validateUsernameLength(text);
        setUsernameValid(prevState => ({
            ...prevState,
            usernameHasLength: userNameLength,
        }));

        const userNameHasCharacter = validate.validateUsernameCharacters(text);
        setUsernameValid(prevState => ({
            ...prevState,
            usenameHasCharacter: userNameHasCharacter,
        }));
    };

    const handleOnUsernameBlur = () => {
        const isUsrNameValid = usernameValid.usenameHasCharacter && usernameValid.usernameHasLength;
        setUsernameValid(prevState => ({
            ...prevState,
            errorMessage: !isUsrNameValid
                ? 'Incorect username, please choose another'
                : usernameTaken
                ? 'This username already exists.'
                : '',
        }));
    };

    const handlOnUsernameFocus = () => {
        setUsernameValid(prevstate => ({
            ...prevstate,
            errorMessage: '',
        }));
    };

    return (
        <EsFormWrapper title="Personal details">
            <FormControl style={{ width: '100%' }}>
                <Box
                    sx={{
                        marginTop: '40px',
                        display: 'flex',
                        flexDirection: { xs: 'column', sm: 'row' },
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '20px',
                    }}>
                    <Box style={styles.flexColumn} sx={{ width: '100%', height: '50px', gap: '8px' }}>
                        <CustomTextField
                            label="First name"
                            onChange={e => {
                                updateFields({ firstName: e.target.value });
                            }}
                            textFieldProps={{
                                InputProps: {
                                    disableUnderline: true,
                                    name: 'firstName',
                                },
                                required: true,
                                value: firstName,
                            }}
                        />
                    </Box>

                    <Box style={styles.flexColumn} sx={{ width: '100%', height: '50px', gap: '8px' }}>
                        <CustomTextField
                            label="Last name"
                            onChange={e => updateFields({ lastName: e.target.value })}
                            textFieldProps={{
                                InputProps: {
                                    disableUnderline: true,
                                },
                                required: true,
                                value: lastName,
                            }}
                        />
                    </Box>
                </Box>

                {/* Username */}
                <Box sx={{ marginTop: '20px' }}>
                    <CustomTextField
                        label="Username"
                        onChange={handleUsernameSearch}
                        onBlur={handleOnUsernameBlur}
                        onFocus={handlOnUsernameFocus}
                        fieldTasks
                        fieldTaskList={usernameRequirements}
                        instruction="Only Letters, Numbers and Underscores"
                        isErrorState={(usernameTaken && !isEmpty(userName)) || !!usernameValid.errorMessage}
                        errorMsg={usernameValid.errorMessage ?? 'This username already exists.'}
                        textFieldProps={{
                            InputProps: {
                                disableUnderline: true,
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {isSearching ? (
                                            <CircularProgress size={20} variant="indeterminate" />
                                        ) : usernameAvailable && !isEmpty(userName) ? (
                                            <CheckCircleIcon color="success" />
                                        ) : null}
                                    </InputAdornment>
                                ),
                            },
                            required: true,
                            value: userName,
                        }}
                    />
                </Box>

                {/* Birth and gender */}
                <Box
                    sx={{
                        marginTop: '20px',
                        display: 'flex',
                        flexDirection: { xs: 'column', sm: 'row' },
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '20px',
                    }}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: 'fit-content',
                            width: '100%',
                            gap: '8px',
                        }}>
                        <FormLabel style={styles.text}>Date of birth</FormLabel>
                        <Box style={styles.flexColumn} sx={{ width: '100%', height: '50px', gap: '8px' }}>
                            <CustomDatePicker
                                maxDate={dayjs(ageLimit)}
                                value={birth}
                                onChange={(e: any) => updateFields({ birth: e.$d.toString() })}
                                sx={{
                                    backgroundColor: theme[themeStyle].form_gray,
                                    '&:focus-within': { border: `1px solid ${theme[themeStyle].highlight}` },
                                }}
                            />
                        </Box>
                    </Box>

                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: 'fit-content',
                            width: '100%',
                            gap: '8px',
                        }}>
                        <FormLabel style={styles.text}>Gender</FormLabel>

                        <CustomSelect
                            onChange={(e: SelectChangeEvent) => updateFields({ gender: e.target.value })}
                            options={genderOptions}
                            value={gender}
                        />
                    </Box>
                </Box>

                <Box sx={{ marginTop: '20px', marginBottom: '40px' }}>
                    <FormLabel style={styles.text}>Phone number</FormLabel>
                    <Box style={styles.wrapper}>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'start',
                                height: '50px',
                                width: '100%',
                            }}>
                            <CustomPhoneTextField
                                onChange={handlePhoneChange}
                                value={phoneNumber}
                                isErrorState={isErrorStatePhone}
                                onFocus={onPhoneFieldFocus}
                                onBlur={onPhoneFieldBlue}
                                instruction="An active WhatsApp™ account is required."
                                errorMsg={errMsg}
                                isFocused={isPhoneFieldInFocus}
                            />
                        </Box>
                    </Box>
                </Box>
            </FormControl>
        </EsFormWrapper>
    );
};

const dynamicStyles = (colorScheme: ColorScheme) =>
    StyleSheet.create({
        wrapper: {
            width: '100%',
            height: '50px',
            borderRadius: 8,
            marginTop: 8,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
        },
        text: {
            maxWidth: 400,
            width: '100%',
            textAlign: 'left',
            fontSize: 14,
            fontStyle: 'normal',
            fontWeight: '400',
            color: theme[colorScheme].form_label,
            fontFamily: FONT_ENUM.MAIN_REGULAR,
            lineHeight: 2.5,
        },
        whatapp: {
            maxWidth: 400,
            width: '100%',
            textAlign: 'left',
            fontSize: 12,
            fontStyle: 'normal',
            fontWeight: '400',
            color: theme[colorScheme].form_label,
            fontFamily: FONT_ENUM.MAIN_REGULAR,
            marginTop: 20,
        },
        flexColumn: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'center',
            height: 'fit-content',
        },
        dropdown: {
            backgroundColor: theme[colorScheme].gray_text,
            color: theme[colorScheme].lightWhite,
            width: '200px',
        },
    });

export { EsPersonalDetail };
