import { ISplashImage } from '../../utils/responseProcessor';

export const types = {
    GET_SPLASH_IMAGES: 'GET_SPLASH_IMAGES',
    GET_SPLASH_IMAGES_SUCCESS: 'GET_SPLASH_IMAGES_SUCCESS',
    GET_SPLASH_IMAGES_FAILED: 'GET_SPLASH_IMAGES_FAILED',
};

export type FilmPoster = {
    film_posters: string[];
    created_at: string;
    _id: string;
    film_name: string;
    ceated_by: string;
    id: string;
};

export interface IGetSplashImages {
    data: FilmPoster[];
}

export interface SplashInitialState {
    splashImages: ISplashImage[];
    fetching: boolean;
}
