import produce from 'immer';

import { IAuthenticationInitialState, types } from './types';

export const initialState: IAuthenticationInitialState = {
    token: '',
    refreshToken: '',
    appLaunchFlowComplete: false,
    logginIn: false,
    loginError: null,
    sendPasswordResetEmail: false,
    sendingPasswordResetError: null,
    userIsAuthenticated: false,
    registrationCancelled: false,
    getting2FACode: false,
    getting2FACodeError: null,
    getting2FACodeDone: false,
    logoutError: null,
    loggingOut: false,
    jwtErrorMsg: null,
    isLoggedIn: false,
};

const authentication = (state = initialState, action: any) => {
    const { type, payload } = action;

    switch (type) {
        case types.APP_LAUNCH_FLOW:
            return produce(state, (draftState: any) => {
                draftState.appLaunchFlowComplete = false;
            });
        case types.APP_LAUNCH_FLOW_COMPLETE:
            return produce(state, draftState => {
                draftState.appLaunchFlowComplete = payload;
            });
        case types.SET_JWT:
            return produce(state, draftState => {
                const { token, refreshToken } = payload;
                draftState.token = token;
                draftState.refreshToken = refreshToken;
            });
        case types.GET_2FA_CODE_FOR_LOGIN:
            return produce(state, draftState => {
                draftState.getting2FACode = true;
                draftState.getting2FACodeError = null;
            });
        case types.GET_2FA_CODE_SUCCESS:
            return produce(state, draftState => {
                draftState.getting2FACodeDone = true;
                draftState.getting2FACode = false;
                draftState.getting2FACodeError = null;
            });
        case types.GET_2FA_CODE_FAILED:
            return produce(state, draftState => {
                draftState.getting2FACodeDone = false;
                draftState.getting2FACode = false;
                draftState.getting2FACodeError = payload;
            });
        case types.LOGIN:
            return produce(state, draftState => {
                draftState.logginIn = true;
                draftState.loginError = null;
                draftState.isLoggedIn = false;
            });
        case types.LOGIN_SUCCESS:
            return produce(state, draftState => {
                draftState.token = payload;
                draftState.loginError = null;
                draftState.logginIn = false;
                draftState.isLoggedIn = true;
            });
        case types.LOGIN_FAILED:
            return produce(state, draftState => {
                draftState.loginError = payload;
                draftState.logginIn = false;
                draftState.isLoggedIn = false;
            });
        case types.LOGOUT:
            return produce(state, draftState => {
                draftState.loggingOut = true;
                draftState.isLoggedIn = false;
            });
        case types.LOGOUT_SUCCESS:
            return produce(state, draftState => {
                draftState.loggingOut = false;
                draftState.token = '';
                draftState.refreshToken = '';
                draftState.isLoggedIn = false;
            });
        case types.LOGOUT_FAILED:
            return produce(state, draftState => {
                draftState.logoutError = payload;
                draftState.loggingOut = false;
            });
        case types.DELETE_JWT_SUCCESS:
            return produce(state, draftState => {
                draftState.token = '';
                draftState.refreshToken = '';
                draftState.isLoggedIn = false;
            });
        case types.GET_JWT_FAILED:
            return produce(state, draftState => {
                draftState.jwtErrorMsg = payload;
                draftState.isLoggedIn = false;
            });
        default:
            return state;
    }
};

export { authentication };
