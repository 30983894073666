import produce from 'immer';
import { INotificatonInitialState, types } from './types';
import { restoreInitialState } from '../../utils/AppUtils';
import { WebNotification } from '../account/accountDetailsResponse.type';
import { IGenericDocumentModifier } from '../../types/global/helper';
import { IUserConfiguration, types as configTypes } from '../configuration/types';

const initialState: INotificatonInitialState = {
    notificationCount: 0,
    notifications: [],
    gettingNotifications: false,
    gettingNotificationsError: null,
    notification_navigation: false,
    navigation_route: null,
};

const reducer = (state = initialState, action: any) => {
    const { type, payload } = action;

    switch (type) {
        case types.SET_NOTIFICATION:
            return produce(state, draftState => {
                draftState.notificationCount = payload;
            });
        case types.RECEIEVE_NOTIFICATION:
            return produce(state, draftState => {
                draftState.notifications.push(payload);
                draftState.notificationCount++;
            });
        case types.READ_NOTIFICATION:
        case types.UPDATE_NOTIFICATION:
        case types.DELETE_NOTIFICATION:
            return produce(state, draftState => {
                draftState.readingNotification = true;
            });
        case types.READ_NOTIFICATION_SUCCESS:
        case types.UPDATE_NOTIFICATION_SUCCESS:
            return produce(state, draftState => {
                const { _id } = payload as WebNotification;
                const index = draftState.notifications.findIndex(e => e._id === _id);
                if (index !== -1) {
                    draftState.notifications[index] = payload;
                }
                /**
                 * Set count to 0 as soon as user opens up the notification
                 *
                 */
                draftState.notificationCount = 0;
                draftState.readingNotification = false;
            });
        case types.DELETE_NOTIFICATION_SUCCESS:
            return produce(state, draft => {
                const { documentId } = payload as IGenericDocumentModifier;
                draft.notifications = draft.notifications.filter(notification => notification._id !== documentId);
            });
        case types.READ_NOTIFICATION_FAIL:
        case types.UPDATE_NOTIFICATION_FAIL:
        case types.DELETE_NOTIFICATION_FAIL:
            return produce(state, draftState => {
                draftState.readingNotification = false;
                draftState.readingNotificationsError = payload;
            });
        case types.GET_MY_NOTIFICATIONS:
            return produce(state, draftState => {
                draftState.gettingNotifications = true;
            });
        case types.GET_MY_NOTIFICATIONS_SUUCESS:
            return produce(state, draftState => {
                draftState.gettingNotifications = false;
                draftState.notifications = payload;
                // Removed because we wish to set the notification count based on
                // user_cofig
                // draftState.notificationCount = draftState.notifications.filter(
                //     notification => !notification.seen,
                // ).length;
            });
        case types.GET_MY_NOTIFICATIONS_FAIL:
            return produce(state, draftState => {
                draftState.gettingNotifications = false;
                draftState.gettingNotificationsError = payload;
            });
        case 'NOTIFICATION_NAVIGATION':
            return produce(state, draftState => {
                draftState.notification_navigation = true;
                draftState.navigation_route = payload;
            });
        case 'NOTIFICATION_NAVIGATION_RESET':
            return produce(state, draftState => {
                draftState.notification_navigation = false;
                draftState.navigation_route = null;
            });
        case types.RESET_NOTIFICATION_DATA:
            return restoreInitialState(state, initialState);
        case configTypes.GET_CONFIGURATION_SUCCESS:
        case configTypes.UPDATE_USER_CONFIG_SUCCESS:
            return produce(state, draftState => {
                const { unread_web_notifications } = (payload as IUserConfiguration) || {};
                draftState.notificationCount = unread_web_notifications;
            });
        default:
            return state;
    }
};

export { reducer };
