import produce from 'immer';
import { CacheTypes, CacheValue, ICacheInitialState, IClearCache, IDestroyCache, ISetCache, types } from './types';
import { restoreInitialState } from '../../utils/AppUtils';

const initialState: ICacheInitialState = {};

const reducer = (state = initialState, action: any) => {
    const { type, payload } = action;
    switch (type) {
        case types.SET_CACHE:
            return produce(state, draftState => {
                const { key, value, type, isUnique } = payload as ISetCache;
                const newObject: any = {};
                if (!isUnique) {
                    newObject[type] = {
                        ...(draftState.storage && draftState.storage[type as CacheTypes]),
                        [key]: {
                            key,
                            value,
                        },
                    };
                } else {
                    newObject[type] = {
                        key,
                        value,
                    };
                }
                draftState.storage = {
                    ...draftState.storage,
                    ...newObject,
                };
            });
        case types.CLEAR_CACHE:
            return produce(state, draftState => {
                const { cacheType } = payload as IClearCache;
                const newObject: any = {};
                if (cacheType && payload.key && draftState.storage && draftState.storage[cacheType as CacheTypes]) {
                    const keys = Object.keys(draftState.storage[cacheType as CacheTypes]);
                    keys.forEach(key => {
                        if (key.startsWith(payload.key) && draftState.storage) {
                            delete draftState.storage[cacheType as CacheTypes][key as keyof CacheValue];
                        }
                    });
                } else {
                    if (draftState.storage && draftState.storage[payload.key as CacheTypes]) {
                        draftState.storage[payload.key as CacheTypes] = {
                            ...draftState.storage,
                            ...newObject,
                        };
                    }
                }
            });
        case types.DESTROY_ONE_CACHE:
            return produce(state, draftState => {
                const { cacheType } = payload as IDestroyCache;
                if (draftState.storage) draftState.storage[cacheType] = { key: '', value: undefined };
            });
        case types.NUKE:
            return restoreInitialState(state, initialState);
        default:
            return state;
    }
};

export { reducer };
