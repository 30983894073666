import { ICreateProject } from '../project/types';

export const types = {
    START_UPLOAD: 'START_UPLOAD',
    START_UPLOAD_SUCCESS: 'START_UPLOAD_SUCCESS',
    START_UPLOAD_FAIL: 'START_UPLOAD_FAIL',

    GET_UPLOAD_SESSION: 'GET_UPLOAD_SESSION',
    GET_UPLOAD_SESSION_SUCCESS: 'GET_UPLOAD_SESSION_SUCCESS',
    GET_UPLOAD_SESSION_FAIL: 'GET_UPLOAD_SESSION_FAIL',

    PAUSE_UPLOAD: 'PAUSE_UPLOAD',

    RESUME_UPLOAD: 'RESUME_UPLOAD',

    UPLOAD_PROGRESS: 'UPLOAD_PROGRESS',

    UPLOAD_COMPLETE: 'UPLOAD_COMPLETE',

    UPLOAD_FAILED: 'UPLOAD_FAILED',

    PROGRESS_REPORT: 'PROGRESS_REPORT',

    COMPLETE_MULTIPART_UPLOAD: 'COMPLETE_MULTIPART_UPLOAD',
    COMPLETE_MULTIPART_UPLOAD_SUCCESS: 'COMPLETE_MULTIPART_UPLOAD_SUCCESS',
    COMPLETE_MULTIPART_UPLOAD_FAIL: 'COMPLETE_MULTIPART_UPLOAD_FAIL',

    SAVE_UPLOAD: 'SAVE_UPLOAD',

    STOP_WATCHING_NETWORK: 'STOP_WATCHING_NETWORK',

    TERMINATE_UPLOAD: 'TERMINATE_UPLOAD',

    CLEAR_MESSAGE: 'CLEAR_MESSAGE',

    RESUME_UPLOAD_AFTER_RELOAD: 'RESUME_UPLOAD_AFTER_RELOAD',
};

export interface IUploadInitialState {
    progress: number;
    isUploadInProgress: boolean;
    paused: boolean;
    currentChunkIndex: number;
    currentFile?: BinaryData;
    currentSessionData?: IUploadSession;
    errorMsg?: any;
    totalChunk?: number;
    uploadParts?: any[];
    uploadMessage?: string;
    currentUploadPayload?: IUploadPayload;
}

export interface IUploadPayload {
    file: File;
    uploadId: string;
    videoKey: string;
    initialPayload?: ICreateProject;
    projectId?: string;
}

export interface IProgressReporter {
    chunk: number;
    progress: number;
    totalChunk: number;
}

export interface IUploadSession {
    videoKey: string;
    uploadId: string;
}

export interface ICompleteMultiPartUpload {
    filename: string;
    uploadId: string;
    parts: any;
    initialPayload?: ICreateProject;
    projectId?: string;
}

export interface ICompleteMultipartUploadResponse {
    location: string;
}

export interface ISaveUpload {
    currentChunkIndex: number;
    uploadedParts: any[];
}

export interface IInitializeUploadSession {
    /** The Id of the project */
    projectId: string;
    project_file: File;
    initialPayload: ICreateProject;
}
