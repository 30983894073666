import React from 'react';
import { StyleSheet } from 'react-native';
import { Typography, Box, Skeleton } from '@mui/material';
import { useThemeContext } from '../../hook/useThemeManager';
import { ColorScheme, theme } from '../../constants/theme';
import { FONT_ENUM } from '../../types/global/fonts.types';
import { MOBILE_MQ } from '../../constants/global';

const ProjectActivityCardLoadingState = () => {
    // Detect when window width changes
    const [innerWidth, setInnerWidth] = React.useState<number>(window.innerWidth);
    const getSize = () => {
        setInnerWidth(window.innerWidth);
    };
    React.useEffect(() => {
        window.addEventListener('resize', getSize);

        return () => {
            window.removeEventListener('resize', getSize);
        };
    }, [innerWidth]);

    const { themeStyle } = useThemeContext();
    const styles = dynamicStyles(themeStyle);

    return (
        <Box
            style={styles.flexColumn}
            sx={{
                width: '100%',
                gap: '25px',
                backgroundColor: theme[themeStyle].gray_text,
                height: 'fit-content',
                padding: { xs: '15px', sm: '30px' },
                borderRadius: '10px',
                position: 'relative',
            }}>
            <>
                <Typography style={styles.title}>Recent activities</Typography>
                <hr
                    style={{
                        width: innerWidth < MOBILE_MQ ? '480px' : '100%',
                        borderBottom: `1px solid ${theme[themeStyle].gray_text}`,
                        marginLeft: innerWidth < MOBILE_MQ ? '-25px' : '0px',
                    }}
                />
            </>
            <Box sx={{ display: 'block', width: '100%' }}>
                <Skeleton
                    variant="rounded"
                    sx={{
                        fontSize: '1rem',
                        backgroundColor: theme[themeStyle].darkGray60,
                        width: '100%',
                        height: 414,
                        marginBottom: 2,
                    }}
                />
                <Skeleton
                    variant="text"
                    sx={{ fontSize: '1rem', backgroundColor: theme[themeStyle].darkGray60, width: '50%' }}
                />
                <Skeleton
                    variant="text"
                    sx={{ fontSize: '1rem', backgroundColor: theme[themeStyle].darkGray60, width: '40%' }}
                />
            </Box>
        </Box>
    );
};

const dynamicStyles = (colorScheme: ColorScheme) =>
    StyleSheet.create({
        flexColumn: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'center',
        },
        title: {
            fontSize: 24,
            fontWeight: '600',
            fontFamily: FONT_ENUM.MAIN_BOLD,
            textAlign: 'left',
            color: theme[colorScheme].filters_white,
        },
    });

export { ProjectActivityCardLoadingState };
