import produce from 'immer';
import { types } from './types';
import { ActData } from '../../types/global/Cards.types';
import { TalentProfile, TalentSearchFilter } from '../../types/global/helper';
import { parseProfiles,  parseBiographyDetails } from '../../utils/responseProcessor';
import { restoreInitialState } from '../../utils/AppUtils';

interface IInitialState {
    gettingTalentProfile: boolean;
    selectedTalentProfile?: TalentProfile | null;
    talentProfiles: Array<TalentProfile>;
    curentProfileInCreation: TalentProfile | undefined | null;
    creatingProfileInProgress: boolean;
    creatingProfileErrorMsg: any;
    currentUserTalentProfile: TalentProfile | null;
    gettingProfileErrorMsg: any;
    deletingProfile: boolean;
    deletingProfileErrorMsg: any;
    currentSelectionPastWork: any;
    currentPastWorkErrMsg: any;
    gettingPastWork: boolean;
    searchResult: Array<TalentProfile>;
    searchingInProgress: boolean;
    talentFilter?: TalentSearchFilter;
    filterMode: boolean;
    showFilter: boolean;
    discover: {};
    gettingDiscover: boolean;
    discoverErrMsg: any;
    acts: Array<ActData>;
    gettingActs: boolean;
    actsErrMsg: null;
    userActs: Array<ActData>;
    gettingUserActs: boolean;
    userActsErrMsg: any;
    currentAct: Array<ActData>;
    gettingCurrentAct: boolean;
    creatingAct: boolean;
    createActErrMsg: any;
    updatingAct: boolean;
    deletingAct: boolean;
    deleteActErrMsg: any;
}

const initialState: IInitialState = {
    gettingTalentProfile: false,
    talentProfiles: [],
    creatingProfileInProgress: false,
    creatingProfileErrorMsg: null,
    gettingProfileErrorMsg: null,
    deletingProfile: false,
    deletingProfileErrorMsg: null,
    currentSelectionPastWork: null,
    currentPastWorkErrMsg: null,
    gettingPastWork: false,
    searchResult: [],
    searchingInProgress: false,
    filterMode: false,
    showFilter: false,
    discover: {},
    gettingDiscover: false,
    discoverErrMsg: null,
    acts: [],
    gettingActs: false,
    actsErrMsg: null,
    userActs: [],
    gettingUserActs: false,
    userActsErrMsg: null,
    currentAct: [],
    gettingCurrentAct: false,
    creatingAct: false,
    createActErrMsg: null,
    updatingAct: false,
    deletingAct: false,
    deleteActErrMsg: null,
    curentProfileInCreation: undefined,
    currentUserTalentProfile: null,
    talentFilter: undefined,
};

const reducer = (state = initialState, action: any) => {
    const { type, payload } = action;

    switch (type) {
        case types.CREATE_TALENT_PROFILE:
        case types.UPDATE_TALENT_PROFILE:
        case types.PUBLISH_TALENT_PROFILE:
        case types.UNPUBLISH_TALENT_PROFILE:
        case types.UPLOAD_IMAGE:
            return produce(state, (draftState: typeof initialState) => {
                draftState.creatingProfileInProgress = true;
            });
        case types.CREATE_TALENT_PROFILE_SUCCESS:
        case types.UPDATE_TALENT_PROFILE_SUCCESS:
        case types.UNPUBLISH_TALENT_PROFILE_SUCCESS:
        case types.PUBLISH_TALENT_PROFILE_SUCCESS:
        case types.UPLOAD_IMAGE_SUCCESS:
            return produce(state, (draftState: typeof initialState) => {
                draftState.creatingProfileInProgress = false;
                draftState.curentProfileInCreation = payload;
            });
        case types.CREATE_TALENT_PROFILE_FAIL:
        case types.UPDATE_TALENT_PROFILE_FAIL:
        case types.UNPUBLISH_TALENT_PROFILE_FAIL:
        case types.PUBLISH_TALENT_PROFILE_FAIL:
        case types.UPLOAD_IMAGE_FAIL:
            return produce(state, (draftState: typeof initialState) => {
                draftState.creatingProfileInProgress = false;
                draftState.creatingProfileErrorMsg = payload;
            });
        case types.GET_ALL_TALENT_PROFILES:
        case types.GET_TALENT_PROFILE_DETAILS:
        case types.GET_CURRENT_USER_TALENT_PROFILE:
            return produce(state, (draftState: typeof initialState) => {
                draftState.gettingTalentProfile = true;
                draftState.selectedTalentProfile = null;
            });
        case types.GET_ALL_TALENT_PROFILES_SUCCESS:
            return produce(state, (draftState: typeof initialState) => {
                draftState.gettingTalentProfile = false;
                // Parse This payload
                draftState.talentProfiles = parseProfiles(payload) as any;
                draftState.searchResult = parseProfiles(payload) as any;
            });
        case types.GET_TALENT_PROFILE_DETAILS_SUCCESS:
            return produce(state, (draftState: typeof initialState) => {
                draftState.gettingTalentProfile = false;
                draftState.selectedTalentProfile = payload;
                draftState.curentProfileInCreation = payload; // TODO FIX
            });
        case types.GET_TALENT_PROFILE_DETAILS_FAIL:
        case types.GET_ALL_TALENT_PROFILES_FAIL:
        case types.GET_CURRENT_USER_TALENT_PROFILE_FAIL:
        case types.FILTER_TALENT:
            return produce(state, (draftState: typeof initialState) => {
                draftState.gettingTalentProfile = true;
                draftState.gettingProfileErrorMsg = payload;
            });
        case types.GET_CURRENT_USER_TALENT_PROFILE_SUCCESS:
            return produce(state, (draftState: typeof initialState) => {
                draftState.gettingTalentProfile = false;
                draftState.currentUserTalentProfile = payload;
                draftState.curentProfileInCreation = payload;
            });
        case types.DELETE_TALENT_PROFILE:
            return produce(state, (draftState: typeof initialState) => {
                draftState.deletingProfile = true;
            });
        case types.DELETE_TALENT_PROFILE_SUCCESS:
            return produce(state, (draftState: typeof initialState) => {
                draftState.deletingProfile = false;
            });
        case types.DELETE_TALENT_PROFILE_FAIL:
            return produce(state, (draftState: typeof initialState) => {
                draftState.deletingProfileErrorMsg = payload;
            });
        case types.GET_TALENT_PAST_WORK:
            return produce(state, (draftState: typeof initialState) => {
                draftState.gettingPastWork = true;
            });
        case types.GET_TALENT_PAST_WORK_SUCCESS:
            return produce(state, (draftState: typeof initialState) => {
                draftState.gettingPastWork = false;
                draftState.currentSelectionPastWork = parseBiographyDetails(payload);
            });
        case types.GET_TALENT_PAST_WORK_FAIL:
            return produce(state, (draftState: typeof initialState) => {
                draftState.gettingPastWork = false;
                draftState.currentPastWorkErrMsg = payload;
            });
        case types.SET_PROFILE_FOR_EDIT:
            return produce(state, (draftState: typeof initialState) => {
                draftState.curentProfileInCreation = payload;
            });
        case types.TOGGLE_TALENT_FILTER:
            return produce(state, (draftState: any) => {
                draftState.showFilter = !state.showFilter;
            });
        case types.GET_DISCOVER:
            return produce(state, (draftState: typeof initialState) => {
                draftState.gettingDiscover = true;
            });
        case types.GET_DISCOVER_FAIL:
            return produce(state, (draftState: typeof initialState) => {
                draftState.gettingDiscover = false;
                draftState.discoverErrMsg = payload;
            });
        case types.CREATE_ACT_FAIL:
            return produce(state, (draftState: typeof initialState) => {
                draftState.creatingAct = false;
                draftState.createActErrMsg = payload;
            });
        case types.DELETE_ACT:
            return produce(state, (draftState: typeof initialState) => {
                draftState.deletingAct = true;
            });
        case types.DELETE_ACT_SUCCESS:
            return produce(state, (draftState: typeof initialState) => {
                draftState.deletingAct = false;
                const filteredActs = draftState.acts.filter((act: any) => act.id !== payload);
                draftState.acts = filteredActs;
            });
        case types.DELETE_ACT_FAIL:
            return produce(state, (draftState: typeof initialState) => {
                draftState.deletingAct = false;
                draftState.deleteActErrMsg = payload;
            });
        case types.GET_MY_ACTS:
            return produce(state, (draftState: typeof initialState) => {
                draftState.gettingActs = true;
            });
        case types.GET_MY_ACTS_SUCCESS:
            return produce(state, (draftState: typeof initialState) => {
                draftState.gettingActs = false;
                draftState.userActs = payload;
            });
        case types.GET_MY_ACTS_FAIL:
            return produce(state, (draftState: typeof initialState) => {
                draftState.gettingActs = false;
                draftState.userActsErrMsg = payload;
            });
        case types.SET_TALENT_DETAILS:
            return produce(state, (draftState: typeof initialState) => {
                draftState.selectedTalentProfile = payload;
            });
        case types.RESET_TALENT_PROFILE:
            return restoreInitialState(state, initialState);
        default:
            return state;
    }
};

export { reducer };
