import { StyleSheet, Text, View } from 'react-native';
import React from 'react';
import { ColorScheme, theme } from '../../constants/theme';
import { useThemeContext } from '../../hook/useThemeManager';
import { Icon } from './Icon';
import { Pressable } from '..';
import { FONT_ENUM } from '../../types/global/fonts.types';
import { IPressableProps } from '../Pressable/Pressable';
import { icon_curved_edit } from '../../assets/icons';

interface IIconWithText extends Partial<Omit<IPressableProps, 'children'>> {
    icon: any;
    onPress: () => void;
    label: string;
    disableIcon?: boolean;
    iconStyle?: any;
}

const IconWithText: React.FC<IIconWithText> = ({ icon, onPress, label, disableIcon, iconStyle, ...props }) => {
    const { themeStyle } = useThemeContext();
    const styles = dynamicStyle(themeStyle);
    return (
        <Pressable defaultOpacity={1} onPress={onPress} {...props}>
            <View style={styles.container}>
                <Text style={styles.text}>{label}</Text>
                {!disableIcon && <Icon activeImage={icon} inActiveImage={icon} style={iconStyle} />}
            </View>
        </Pressable>
    );
};

IconWithText.displayName = 'IconWithText';

IconWithText.defaultProps = {
    icon: icon_curved_edit,
    label: 'Edit',
    disableIcon: false,
    onPress: () => {},
};

export { IconWithText };

const dynamicStyle = (colorScheme: ColorScheme) =>
    StyleSheet.create({
        container: {
            borderWidth: 1,
            borderColor: theme[colorScheme].lightWhite,
            borderRadius: 20,
            flexDirection: 'row',
            alignItems: 'center',
            padding: 10,
        },
        text: {
            fontFamily: FONT_ENUM.REGULAR_HEADER,
            fontSize: 10,
            color: theme[colorScheme].lightWhite,
            marginRight: 5,
        },
    });
