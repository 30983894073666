import { IUser } from '../../types/global/ListRender.types';
import { IContest, IProject } from '../../types/global/helper';

export const types = {
    GET_MY_ACTIVITIES: 'GET_MY_ACTIVITIES',
    GET_MY_ACTIVITIES_SUCCESS: 'GET_MY_ACTIVITIES_SUCCESS',
    GET_MY_ACTIVITIES_FAIL: 'GET_MY_ACTIVITIES_FAIL',

    SAVE_ACTIVITY: 'SAVE_ACTIVITY',
    SAVE_ACTIVITY_SUCCESS: 'SAVE_ACTIVITY_SUCCESS',
    SAVE_ACTIVITY_FAIL: 'SAVE_ACTIVITY_FAIL',

    GET_SEARCH_SUGGESTIONS: 'GET_SEARCH_SUGGESTIONS',
    GET_SEARCH_SUGGESTIONS_SUCCESS: 'GET_SEARCH_SUGGESTIONS_SUCCESS',
    GET_SEARCH_SUGGESTIONS_FAIL: 'GET_SEARCH_SUGGESTIONS_FAIL',

    HIDE_MY_ACTIVITY: 'HIDE_MY_ACTIVITY',
    HIDE_MY_ACTIVITY_SUCCESS: 'HIDE_MY_ACTIVITY_SUCCESS',
    HIDE_MY_ACTIVITY_FAIL: 'HIDE_MY_ACTIVITY_FAIL',
    RESET_ACTIVITY_DATA: 'RESET_ACTIVITY_DATA',
};

export interface ISaveSearchActivityPayload {
    activity_type: Activity;
    metadata: {
        search_term: string;
    };
}

export interface IActivityInitialState {
    myActivities: ActivityResponse[];
    savingActivity: boolean;
    gettingActivity: boolean;
    errMsg: any;
    searchSuggestions: SearchSuggestion[];
    processing?: boolean;
}

export type ActivityType =
    | 'commented on project'
    | 'commented on contest'
    | 'joined a contest'
    | 'voted for project'
    | 'joined thspian'
    | 'initiated a search'
    | 'viewed a project'
    | 'created a project'
    | 'added team member'
    | 'liked a project'
    | 'liked a review'
    | 'liked a movie'
    | 'liked an act'
    | 'liked a comment'
    | 'liked a contest'
    | 'initiated a search'
    | 'created a contest';

export enum ACTIVITY_TYPE {
    /** Add more activity types here. The service usually handles these behind the scene but in some instances we need to initiare them */
    INITIATED_A_SEARCH = 'initiated a search',
}

export interface ActivityRootResponse {
    status: string;
    results: number;
    data: Data;
}

export interface Data {
    data: ActivityResponse[];
}

export interface ActivityResponse {
    activity?: Activity;
    _id: string;
    user: string;
    activity_type: ActivityType;
    created_by: string;
    createdAt: string;
    updatedAt: string;
    __v: number;
    id: string;
    metadata?: Metadata;
}

export interface ActivityItem {
    project_type?: string;
    total_likes?: number;
    total_comments?: number;
    view_count?: number;
    _id: string;
    title?: string;
    description?: string;
    project: any;
    id?: string;
    feature_image?: string;
    profile_picture?: string;
    role?: string;
}

export interface Metadata {
    search_term: string;
}

export interface SearchSuggestionResponse {
    status: string;
    data: SearchSuggestion[];
}

export interface SearchSuggestion {
    _id: string;
    count?: number;
    documents?: ActivityDocument;
    details?: SuggestionDetails;
    suggestionType?: SuggestionDetailsTypes;
    suggestion?: IContest | IProject | IUser;
}

export interface ActivityDocument {
    _id?: string;
    user?: string;
    activity_type?: ActivityType | string;
    activity?: Activity;
    metadata?: Metadata;
    created_by?: string;
    createdAt?: string;
    updatedAt?: string;
    __v?: number;
}

export interface Activity {
    type: 'User' | 'Project' | 'Like' | 'Contest' | string;
    model: ActivityItem;
}

export interface Metadata {
    search_term: string;
}

export type SuggestionDetailsTypes = 'contest' | 'user' | 'projects' | 'talent';

export interface SuggestionDetails {
    projects: Project[];
    user: User[];
    contest: Contest[];
}

export interface Project {
    _id: string;
    project_type: string;
    project_gallery: any[];
    visible: boolean;
    team: any[];
    status: string;
    trending_marker: number;
    total_likes: number;
    total_anger: number;
    total_love: number;
    scheduled_published_date: string;
    mentions: any[];
    total_comments: number;
    view_count: number;
    last_seen: string;
    title: string;
    description: string;
    created_by: string;
    createdAt: string;
    updatedAt: string;
    __v: number;
    project: string;
}

export interface User {
    _id: string;
    location: Location;
    role: string;
    active: boolean;
    phone_verified: boolean;
    email_verified: boolean;
    firstName: string;
    lastName: string;
    email: string;
    password: string;
    phoneNumber: number;
    stripeCustomerId: string;
    __v: number;
    email_verify_token: string;
    passwordResetExpires: string;
    passwordResetToken: string;
    profile_picture: string;
    userName: string;
    paystack_customer_code: string;
    current_subscription: string;
    application_limit: number;
    casting_call_limit: number;
    audme_profile: string;
    permissions: string[];
    verified?: boolean;
    user_type: string[];
}

export interface Location {
    type: string;
    description: string;
    address: string;
    coordinates: number[];
}

export interface Contest {
    _id: string;
    requirements: string[];
    contest_media: any[];
    voting_required: boolean;
    voting_type: string;
    tag: string[];
    date_start: string;
    expiration_date: string;
    contest_phases_enabled: boolean;
    premium: boolean;
    contest_phase: string;
    status: string;
    trending_marker: number;
    title: string;
    description: string;
    created_by: string;
    contest_phase_dates: any[];
    __v: number;
    feature_image: string;
    total_likes: number;
    total_comments: number;
}
