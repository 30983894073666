export const types = {
    REFRESH_TOKEN: 'REFRESH_TOKEN',
    REFRESH_TOKEN_COMPLETE: 'REFRESH_TOKEN_COMPLETE',
    REFRESH_TOKEN_FAIL: 'REFRESH_TOKEN_FAIL',
    ENQUEUE_FAILED_ACTIONS: 'ENQUEUE_FAILED_ACTIONS',
    DISPATCH_QUEUED_ACTIONS: 'DISPATCH_QUEUED_ACTIONS',
    RESET_REFRESH_TOKEN: 'RESET_REFRESH_TOKEN',
};

export type RefreshTokenPayload = {
    payload?: string;
    isNavigate?: boolean;
    from?: string;
};

export type GenericPayload = {
    payload: any;
};
