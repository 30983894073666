import { call, put } from 'redux-saga/effects';

import { setError } from './actions';
import { IErrorType } from '../../constants/types';
import { showToastMessage } from '../../utils/AppUtils';
import { SentryCapture } from '../../analytics/Sentry';

export function* globalErrorHandler(payload: IErrorType) {
    const { data, statusText, status } = payload || {};

    let errorHeader = statusText;
    if (status === 401) errorHeader = 'UnAuthorized';
    if (data && (statusText || data.status)) {
        yield put(setError({ errorHeader, errorBody: data.message }));
    } else {
        yield put(setError({ errorHeader: 'Something went wrong', errorBody: 'Please try again' }));
    }
    if (data && data.message) {
        yield call(showToastMessage, data.message, 'error');
    } else {
        console.log(payload);
        yield call(showToastMessage, 'Something went wrong', 'error');
    }
    yield call(SentryCapture, data, 'error');
}
