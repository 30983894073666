import { createAction } from 'redux-act';
import { IErrorType } from '../../constants/types';
import { IGenericDocumentModifier } from '../../types/global/helper';
import { WebNotification } from '../account/accountDetailsResponse.type';
import { types } from './types';

export const setNotification = (payload: WebNotification) => ({
    type: types.SET_NOTIFICATION,
    payload,
});

export const readNotification = (payload: WebNotification) => ({
    type: types.READ_NOTIFICATION,
    payload,
});

export const getMyNotifications = () => ({
    type: types.GET_MY_NOTIFICATIONS,
});

export const resetNotificationData = () => ({
    type: types.RESET_NOTIFICATION_DATA,
});

export const getMyNotificationsSuccess = (payload: WebNotification[]) => ({
    type: types.GET_MY_NOTIFICATIONS_SUUCESS,
    payload,
});

export const getMyNotificationsFail = (payload: IErrorType) => ({
    type: types.GET_MY_NOTIFICATIONS_FAIL,
    error: payload,
});

export const readNotificationSuccess = createAction<WebNotification>(types.READ_NOTIFICATION_SUCCESS);
export const readNotificationFail = createAction<IErrorType>(types.READ_NOTIFICATION_FAIL);

export const updateNotification = createAction<IGenericDocumentModifier>(types.UPDATE_NOTIFICATION);
export const updateNotificationSuccess = createAction<WebNotification>(types.UPDATE_NOTIFICATION_SUCCESS);
export const updateNotificationFail = createAction<IErrorType>(types.UPDATE_NOTIFICATION_FAIL);

export const deleteNotification = createAction<IGenericDocumentModifier>(types.DELETE_NOTIFICATION);
export const deleteNotificationSuccess = createAction<IGenericDocumentModifier>(types.DELETE_NOTIFICATION_SUCCESS);
export const deleteNotificationFail = createAction<IErrorType>(types.DELETE_NOTIFICATION_FAIL);

export const recieveNotification = createAction<WebNotification>(types.RECEIEVE_NOTIFICATION);
