import React from 'react';
import { StyleProp, StyleSheet, Text, TextStyle, TouchableOpacity, View, ViewStyle } from 'react-native';
import Lottie, { Options } from 'react-lottie';

import { colors } from '../../constants/theme';
import { LinearGradient } from '../LinearGradient/LinearGradient';
import { loading as d_loading } from '../../assets/animations';

interface IButton {
    onPress: () => void;
    buttonText: string;
    style?: StyleProp<ViewStyle>;
    buttonColor?: string;
    disableGradiant?: boolean;
    textStyle?: StyleProp<TextStyle>;
    isDisabled?: boolean;
    loading?: boolean;
}

const Button = ({
    onPress,
    buttonText,
    style,
    buttonColor,
    disableGradiant,
    textStyle,
    isDisabled,
    loading,
}: IButton) => {
    const Container = disableGradiant ? View : LinearGradient;

    const defaultOptions: Options = {
        loop: true,
        autoplay: true,
        animationData: d_loading,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
            hideOnTransparent: true,
        },
    };

    const renderLoader = <Lottie options={defaultOptions} height={50} width={50} />;
    return (
        <TouchableOpacity onPress={onPress} style={[style]} disabled={isDisabled || loading}>
            <Container
                gradientColor={[colors.button.start, colors.button.mid, colors.button.midII, colors.button.end]}
                isFocused>
                <View style={[styles.innerContainer, { backgroundColor: isDisabled ? 'grey' : buttonColor }]}>
                    {loading ? renderLoader : <Text style={[styles.buttonText, textStyle]}>{buttonText}</Text>}
                </View>
            </Container>
        </TouchableOpacity>
    );
};

Button.defaultProps = {
    buttonText: 'Button',
    onPress: () => console.log('Button pressed'),
};

export { Button };

const styles = StyleSheet.create({
    linearGradient: {
        minWidth: 200,
        borderRadius: 8, // <-- Outer Border Radius
    },
    innerContainer: {
        borderRadius: 8, // <-- Inner Border Radius
        flex: 1,
        margin: 2, // <-- Border Width
        backgroundColor: '#000',
        justifyContent: 'center',
    },
    buttonText: {
        fontSize: 18,
        fontWeight: 'bold',
        textAlign: 'center',
        margin: 10,
        color: '#fff',
        backgroundColor: 'transparent',
    },
});
