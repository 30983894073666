import React from 'react';
import { ThemeContext } from '../../module/ThemeManager';
import { Container, Stack, Skeleton, Grid } from '@mui/material';
import { theme } from '../../constants/theme';

const ComingSoonShimmer = () => {
    return (
        <ThemeContext.Consumer>
            {({ themeStyle, device }) => {
                const value = device.isDesktop ? 6 : 4;

                return (
                    <Container
                        disableGutters
                        sx={{
                            width: '100%',
                            minHeight: '100vh',
                            marginTop: { xs: '0px', sm: '0px', md: '30px', lg: '30px' },
                            marginLeft: { xs: '0px', sm: '65px' },
                            paddingLeft: { xs: '15px', sm: '40px' },
                            paddingRight: { xs: '15px', sm: '40px' },
                            overflowX: 'hidden',
                        }}
                        maxWidth={false}>
                        <Stack marginBottom={{ xs: 3, lg: 4 }}>
                            <Skeleton
                                variant="text"
                                sx={{
                                    fontSize: '2rem',
                                    width: '100px',
                                    backgroundColor: theme[themeStyle].gray_text,
                                    marginLeft: '5px',
                                }}
                            />
                        </Stack>
                        <Grid container spacing={3}>
                            {Array.from(new Array(value)).map((_, index) => (
                                <Grid item xs={12} sm={6} md={6} lg={4} key={index}>
                                    <Stack
                                        direction={{ xs: 'column', sm: 'row', md: 'row', lg: 'row' }}
                                        position={'relative'}>
                                        <Skeleton
                                            variant="rounded"
                                            height={245}
                                            sx={{
                                                width: {
                                                    xs: '100%',
                                                    sm: '400px',
                                                    md: '450px',
                                                    lg: '450px',
                                                },
                                                backgroundColor: theme[themeStyle].gray_text,
                                                borderRadius: '15px',
                                            }}
                                        />
                                        <Skeleton
                                            variant="text"
                                            sx={{
                                                fontSize: '1rem',
                                                width: '120px',
                                                backgroundColor: theme[themeStyle].darkGray60,
                                                position: 'absolute',
                                                left: { xs: 30, md: 30, lg: 30 },
                                                bottom: 50,
                                            }}
                                        />
                                        <Skeleton
                                            variant="text"
                                            sx={{
                                                fontSize: '1rem',
                                                width: '50px',
                                                backgroundColor: theme[themeStyle].darkGray60,
                                                position: 'absolute',
                                                left: { xs: 30, md: 30, lg: 30 },
                                                bottom: 30,
                                            }}
                                        />
                                    </Stack>
                                </Grid>
                            ))}
                        </Grid>
                    </Container>
                );
            }}
        </ThemeContext.Consumer>
    );
};

export { ComingSoonShimmer };
