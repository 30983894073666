import { Spinner } from '@chakra-ui/react';
import { Box } from '@mui/material';
import { useThemeContext } from '../../hook/useThemeManager';

const Loading = () => {
    const { device } = useThemeContext();
    return (
        <Box
            sx={{
                display: 'flex',
                backgroundColor: 'rgba(0,0,0,0.8)',
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: 99999,
                position: 'fixed',
                height: '100%',
                width: '100%',
            }}>
            <Spinner
                pos="absolute"
                color="white"
                size="xl"
                right={device.isMobile ? '40%' : '50%'}
                bottom={'50%'}
                h={20}
                w={20}
            />
        </Box>
    );
};

export { Loading };
