import { createAction } from 'redux-act';
import { IComingSoonResponse, types } from './types';
import { IErrorType } from '../../constants/types';
import { ISearchPayload, ISearchResponse } from '../discovery/types';

export const getComingSoon = createAction(types.GET_COMING_SOON);
export const getComingSoonSuccess = createAction<IComingSoonResponse>(types.GET_COMING_SOON_SUCCESS);
export const getComingSoonFail = createAction<IErrorType>(types.GET_COMING_SOON_FAIL);

export const searchComingSoon = createAction<ISearchPayload>(types.SEARCH_COMING_SOON);
export const searchComingSoonSuccess = createAction<ISearchResponse>(types.SEARCH_COMING_SOON_SUCCESS);
export const searchComingSoonFail = createAction<IErrorType>(types.SEARCH_COMING_SOON_FAIL);
