import React from 'react';
import { ThemeContext } from '../../module/ThemeManager';
import { Container } from '@mui/material';
import { WatchSingleCardShimmer } from '../WatchSingleCardShimmer';
import { VerticalCardShimmer } from '../VerticalCardShimmer';

const WatchGridShimmer = () => {
    return (
        <ThemeContext.Consumer>
            {() => {
                return (
                    <Container
                        sx={{
                            width: '100%',
                            overflowX: 'hidden',
                        }}
                        maxWidth={false}>
                        <VerticalCardShimmer />
                        <WatchSingleCardShimmer />
                    </Container>
                );
            }}
        </ThemeContext.Consumer>
    );
};

export { WatchGridShimmer };
