import produce from 'immer';
import { ILoadMoreResponse, IWithdrawApplication } from './actions';
import { types } from './types';
import { IRole } from '../../types/global/AuditionDetailsEditor.types';
import { FilingStatus, IApplication } from '../../types/global/ListRender.types';
import { IAudition, IContestWatchList, ISavedAudition } from '../../types/global/helper';
import {
    parseAuditions,
    parseSavedItems,
    parseAuditionRole,
    parseAuditionApplications,
    parseSingleAuditionApplication,
} from '../../utils/responseProcessor';
import { restoreInitialState } from '../../utils/AppUtils';

type Sorter = { [status in FilingStatus]: Array<IApplication> };
export type AuditionDetails = {
    selectedAudition?: IAudition;
    roles: Array<IRole>;
    gettingAuditionRoles: boolean;
    gettingAuditionRolesErrorMsg: any;
};

export type Location = {
    type?: string;
    coordinates?: Array<number>;
    address?: string;
    description?: string;
};
export type AuditionFilter = {
    required_skill: string;
    required_media: string;
    age_range: string;
    filming_location: Location;
    rehearsal_date: string;
    compensation: string;
    audition_type: string;
    employment_type: string;
    tags: string;
};

type CurrentAuditionInCreation = {
    id: string;
    roles: Array<IAudition>;
};

type IAuditionInitialState = {
    gettingAuditions: boolean;
    gettingAudtionDetails: boolean;
    auditionDetails: AuditionDetails;
    gettingAuditionError: null;
    /** Currently logged in user applications */
    applications: Array<IApplication>;
    gettingMyApplications: boolean;
    gettingMyApplicationsErrorMsg: null;
    withdrawingApplication: boolean;
    withdrawApplicationError: any;
    auditions: Array<IAudition>;
    auditionFilters: AuditionFilter;
    filterMode: boolean;
    showFilter: boolean;
    savedRoles: IRole[];
    savedAuditions: ISavedAudition[];
    savedContests: IContestWatchList[];
    gettingSavedRoles: boolean;
    savingRole: boolean;
    gettingSavedRolesError: any;
    savingRoleErrorMsg: any;
    deletingSavedCollection: boolean;
    updatingSavedCollection: boolean;
    userAuditions: Array<IAudition>;
    gettingUserAuditions: boolean;
    creatingAuditionMode: boolean;
    currentAuditionInCreation: CurrentAuditionInCreation;
    currentAuditionRolesInCreation: Array<IRole>;
    creatingAuditionError: null;
    creatingRolesError: null;
    creatingInProcess: boolean;
    unPublishing: boolean;
    deletingAudition: boolean;
    deletingAuditionErrorMsg: null;
    uploadProgress: string;
    currentManagedAudition?: IAudition | undefined;
    currentManagedApplications: Array<IApplication>;
    gettingAuditionApplications: boolean;
    sortingApplication: boolean;
    sorter: Sorter;
    searchResults: Array<IAudition>;
    currentAuditionPage: number;
    currentAuditionLimit: number;
    loadingMore?: boolean;
    loadingMoreErr?: any;
};

const initialState: IAuditionInitialState = {
    gettingAuditions: false,
    gettingAudtionDetails: false,
    auditionDetails: {
        roles: [],
        gettingAuditionRoles: false,
        gettingAuditionRolesErrorMsg: null,
    },
    gettingAuditionError: null,
    applications: [],
    withdrawingApplication: false,
    withdrawApplicationError: null,
    gettingMyApplications: false,
    gettingMyApplicationsErrorMsg: null,
    auditions: [],
    auditionFilters: {
        required_skill: 'none',
        required_media: 'none',
        age_range: '1-100',
        filming_location: {},
        rehearsal_date: 'none',
        compensation: 'none',
        audition_type: 'none',
        employment_type: 'none',
        tags: 'none',
    },
    filterMode: false,
    showFilter: false,
    savedRoles: [],
    savedAuditions: [],
    savedContests: [],
    gettingSavedRoles: false,
    savingRole: false,
    gettingSavedRolesError: null,
    savingRoleErrorMsg: null,
    deletingSavedCollection: false,
    updatingSavedCollection: false,
    userAuditions: [],
    gettingUserAuditions: false,
    creatingAuditionMode: false,
    currentAuditionInCreation: { id: '', roles: [] },
    currentAuditionRolesInCreation: [],
    creatingAuditionError: null,
    creatingRolesError: null,
    creatingInProcess: false,
    unPublishing: false,
    deletingAudition: false,
    deletingAuditionErrorMsg: null,
    uploadProgress: '',
    currentManagedApplications: [],
    gettingAuditionApplications: false,
    sortingApplication: false,
    sorter: {
        invited: [],
        unfiled: [],
        yes: [],
        no: [],
        cast: [],
        archived: [],
    },
    searchResults: [],
    currentAuditionPage: 1,
    currentAuditionLimit: 5,
    loadingMore: false,
    loadingMoreErr: null,
};

const reducer = (state = initialState, action: any) => {
    const { type, payload } = action;

    switch (type) {
        case types.GET_ALL_AUDITIONS:
        case types.ADVANCED_AUDITION_SEARCH:
        case types.FILTER_AUDITION:
            return produce(state, (draftState: any) => {
                draftState.gettingAuditions = true;
            });
        case types.GET_ALL_AUDITION_SUCCESS:
        case types.FILTER_AUDITION_SUCCESS:
            return produce(state, draftState => {
                draftState.auditions = payload;
                draftState.currentAuditionLimit = 5;
                draftState.currentAuditionPage = 1;
                draftState.gettingAuditions = false;
            });
        case types.ADVANCED_AUDITION_SEARCH_SUCCESS:
            return produce(state, draftState => {
                draftState.searchResults = payload;
                draftState.gettingAuditions = false;
            });
        case types.GET_ALL_AUDITION_FAILED:
        case types.ADVANCED_AUDITION_SEARCH_FAIL:
        case types.FILTER_AUDITION_FAIL:
            return produce(state, (draftState: any) => {
                draftState.gettingAuditionError = payload;
                draftState.gettingAuditions = false;
                draftState.currentAuditionLimit = 5;
                draftState.currentAuditionPage = 1;
            });
        case types.GET_AUDITION_DETAIL:
            return produce(state, (draftState: any) => {
                draftState.gettingAudtionDetails = true;
            });
        case types.GET_AUDITION_DETAIL_SUCCESS:
            return produce(state, (draftState: any) => {
                draftState.auditionDetails.selectedAudition = payload;
                draftState.gettingAudtionDetails = false;
            });
        case types.GET_AUDITION_DETAIL_FAILED:
            return produce(state, (draftState: any) => {
                draftState.gettingAuditionError = payload;
                draftState.gettingAudtionDetails = false;
            });
        case types.GET_ROLES:
            return produce(state, (draftState: any) => {
                draftState.auditionDetails.gettingAuditionRoles = true;
            });
        case types.GET_ROLES_SUCCESS:
            return produce(state, (draftState: any) => {
                draftState.auditionDetails.roles = payload;
                draftState.auditionDetails.gettingAuditionRoles = false;
            });
        case types.GET_ROLES_FAILED:
            return produce(state, (draftState: any) => {
                draftState.auditionDetails.gettingAuditionRolesErrorMsg = payload;
                draftState.auditionDetails.gettingAuditionRoles = false;
            });
        case types.GET_MY_APPLICATIONS:
            return produce(state, (draftState: any) => {
                draftState.gettingMyApplications = true;
            });
        case types.GET_MY_APPLICATIONS_SUCCESS:
            return produce(state, (draftState: any) => {
                draftState.gettingMyApplications = false;
                draftState.applications = payload;
            });
        case types.GET_MY_APPLICATIONS_FAILED:
            return produce(state, (draftState: any) => {
                draftState.gettingMyApplications = false;
                draftState.gettingMyApplicationsErrorMsg = payload;
            });
        case types.TOGGLE_FILTER:
            return produce(state, (draftState: any) => {
                draftState.showFilter = !state.showFilter;
            });
        case types.ADD_FILTER:
            return produce(state, (draftState: any) => {
                draftState.auditionFilters = payload.state;
                draftState.filterMode = true;
            });
        case types.REMOVE_FILTER:
            return produce(state, draftState => {
                draftState.auditionFilters = {
                    required_skill: 'none',
                    required_media: 'none',
                    age_range: '1-100',
                    filming_location: {},
                    rehearsal_date: 'none',
                    compensation: 'none',
                    audition_type: 'none',
                    employment_type: 'none',
                    tags: 'none',
                };
                draftState.filterMode = false;
            });
        case types.GET_SAVED_COLLECTION:
            return produce(state, (draftState: any) => {
                draftState.gettingSavedRoles = true;
            });
        case types.GET_SAVED_COLLECTION_SUCCESS:
            return produce(state, (draftState: typeof initialState) => {
                draftState.gettingSavedRoles = false;
                const { audition, role, contest } = parseSavedItems(payload);
                draftState.savedRoles = role;
                draftState.savedAuditions = audition;
                draftState.savedContests = contest;
            });
        case types.GET_SAVED_COLLECTION_FAIL:
            return produce(state, (draftState: any) => {
                draftState.gettingSavedRoles = false;
                draftState.gettingSavedRolesError = payload;
            });
        case types.SAVE_COLLECTION:
            return produce(state, (draftState: typeof initialState) => {
                draftState.savingRole = true;
            });
        case types.SAVE_COLLECTION_SUCCESS:
            return produce(state, (draftState: any) => {
                draftState.savingRole = false;
            });
        case types.SAVE_COLLECTION_FAIL:
            return produce(state, (draftState: any) => {
                draftState.savingRole = false;
                draftState.savingRoleErrorMsg = payload;
            });
        case types.DELETE_SAVED_COLLECTION:
            return produce(state, (draftState: any) => {
                draftState.deletingSavedCollection = true;
            });
        case types.DELETE_SAVED_COLLECTION_SUCCESS:
            return produce(state, (draftState: typeof initialState) => {
                draftState.savedAuditions = draftState.savedAuditions.filter(col => col.id !== payload);
                draftState.deletingSavedCollection = false;
            });
        case types.DELETE_SAVED_COLLECTION_FAIL:
            return produce(state, (draftState: typeof initialState) => {
                draftState.deletingSavedCollection = false;
            });
        case types.UPDATE_SAVED_COLLECTION:
            return produce(state, (draftState: any) => {
                draftState.updatingSavedCollection = true;
            });
        case (types.UPDATE_SAVED_COLLECTION_SUCCESS, types.UPDATE_SAVED_COLLECTION_FAIL):
            return produce(state, (draftState: any) => {
                draftState.updatingSavedCollection = false;
            });
        case types.CREATE_AUDITION:
        case types.UPDATE_AUDITION:
            return produce(state, (draftState: typeof initialState) => {
                draftState.creatingAuditionMode = true;
                draftState.creatingInProcess = true;
            });
        case types.CREATE_AUDITION_SUCCESS:
        case types.PUBLISH_AUDITION_SUCCESS:
        case types.UPDATE_AUDITION_SUCCESS:
            return produce(state, (draftState: typeof initialState) => {
                draftState.currentAuditionInCreation = payload;
                draftState.creatingInProcess = false;
                draftState.unPublishing = false;
            });
        case (types.CREATE_AUDITION_FAIL, types.UPDATE_AUDITION_FAIL):
            return produce(state, (draftState: typeof initialState) => {
                draftState.creatingAuditionError = payload;
                draftState.creatingInProcess = false;
            });
        case types.CREATE_ROLE:
            return produce(state, (draftState: typeof initialState) => {
                draftState.creatingInProcess = true;
            });
        case types.CREATE_ROLE_SUCCESS:
            return produce(state, (draftState: typeof initialState) => {
                const { data } = payload;
                draftState.creatingInProcess = false;
                const parsedRole = parseAuditionRole(data);
                const newItem: any = [...draftState.currentAuditionRolesInCreation, parsedRole];
                draftState.currentAuditionRolesInCreation = newItem;
                draftState.currentAuditionInCreation.roles = newItem;
            });
        case types.CREATE_ROLE_FAIL:
            return produce(state, (draftState: typeof initialState) => {
                draftState.creatingInProcess = false;
                draftState.creatingRolesError = payload;
            });
        case types.PUBLISH_AUDITION:
            return produce(state, (draftState: typeof initialState) => {
                draftState.creatingInProcess = true;
            });
        case types.PUBLISH_AUDITION_FAIL:
            return produce(state, (draftState: typeof initialState) => {
                draftState.creatingInProcess = false;
                draftState.creatingAuditionError = payload;
            });
        case types.UNPUBLISH_AUDITION:
            return produce(state, (draftState: typeof initialState) => {
                draftState.unPublishing = true;
            });
        case types.DELETE_AUDITION:
            return produce(state, (draftState: typeof initialState) => {
                draftState.deletingAudition = true;
            });
        case types.DELETE_AUDITION_SUCCESS:
            return produce(state, (draftState: typeof initialState) => {
                draftState.deletingAudition = false;
            });
        case types.DELETE_AUDITION_FAIL:
            return produce(state, (draftState: typeof initialState) => {
                draftState.deletingAudition = false;
                draftState.deletingAuditionErrorMsg = payload;
            });
        case types.UPLOAD_PROGRESS:
            return produce(state, (draftState: typeof initialState) => {
                draftState.uploadProgress = payload;
            });
        case types.SET_AUDITION_FOR_EDIT:
            return produce(state, (draftState: typeof initialState) => {
                draftState.currentAuditionInCreation = payload;
                draftState.currentAuditionRolesInCreation = payload.roles;
            });
        case types.DELETE_ROLE_SUCCESS:
            return produce(state, (draftState: typeof initialState) => {
                const updatedRolesInCreation = draftState.currentAuditionRolesInCreation.filter(
                    (roles: any) => roles.id !== payload,
                );
                const updatedRoles = draftState.currentAuditionInCreation.roles.filter(roles => roles.id !== payload);
                draftState.currentAuditionRolesInCreation = updatedRolesInCreation;
                draftState.currentAuditionInCreation.roles = updatedRoles;
            });
        case types.SET_AUDITION_TO_MANAGE:
            return produce(state, (draftState: typeof initialState) => {
                draftState.currentManagedAudition = payload;
            });
        case types.SET_APPLICATIONS_TO_MANAGE:
            return produce(state, (draftState: typeof initialState) => {
                draftState.sorter = {
                    invited: [],
                    unfiled: [],
                    yes: [],
                    no: [],
                    cast: [],
                    archived: [],
                };
                const parsedApplication = payload;
                draftState.currentManagedApplications = parsedApplication as any;
                parsedApplication.forEach((application: IApplication) => {
                    draftState.sorter[application.filing_status] = [
                        ...draftState.sorter[application.filing_status],
                        application,
                    ];
                });
            });
        case types.GET_ALL_APPLICATION_FOR_AUDITION:
            return produce(state, (draftState: typeof initialState) => {
                draftState.gettingAuditionApplications = true;
                draftState.sorter = {
                    invited: [],
                    unfiled: [],
                    yes: [],
                    no: [],
                    cast: [],
                    archived: [],
                };
            });
        case types.GET_ALL_APPLICATION_FOR_AUDITION_SUCCESS:
            return produce(state, (draftState: typeof initialState) => {
                const parsedApplication = parseAuditionApplications(payload);
                draftState.currentManagedApplications = parsedApplication as any;
                parsedApplication.forEach((application: IApplication) => {
                    draftState.sorter[application.filing_status] = [
                        ...draftState.sorter[application.filing_status],
                        application,
                    ];
                });
                draftState.gettingAuditionApplications = false;
            });
        case types.GET_ALL_APPLICATION_FOR_AUDITION_FAIL:
            return produce(state, (draftState: typeof initialState) => {
                draftState.gettingAuditionApplications = false;
            });
        case types.SORT_APPLICATION:
            return produce(state, (draftState: typeof initialState) => {
                draftState.sortingApplication = true;
                draftState.sorter = {
                    invited: [],
                    unfiled: [],
                    yes: [],
                    no: [],
                    cast: [],
                    archived: [],
                };
            });
        case types.SORT_APPLICATION_SUCCESS:
            return produce(state, (draftState: typeof initialState) => {
                const currentApplicationsClone: any = [...draftState.currentManagedApplications];
                const currentIndex = draftState.currentManagedApplications.findIndex(
                    (value: any) => value.id === payload.id,
                );
                currentApplicationsClone[currentIndex] = parseSingleAuditionApplication(payload);
                currentApplicationsClone.forEach((application: IApplication) => {
                    draftState.sorter[application.filing_status] = [
                        ...draftState.sorter[application.filing_status],
                        application,
                    ];
                });
                draftState.currentManagedApplications = currentApplicationsClone;
                draftState.sortingApplication = false;
            });
        case types.SORT_APPLICATION_FAIL:
            return produce(state, (draftState: typeof initialState) => {
                draftState.sortingApplication = false;
            });
        case types.SET_AUDITION_DETAILS:
            return produce(state, (draftState: typeof initialState) => {
                draftState.auditionDetails.selectedAudition = payload;
                draftState.gettingAudtionDetails = false;
            });
        case types.LOAD_MORE_AUDITIONS:
            return produce(state, draftState => {
                draftState.loadingMore = true;
            });
        case types.LOAD_MORE_AUDITIONS_SUCCESS:
            return produce(state, draftState => {
                const { data, query } = payload as ILoadMoreResponse;
                draftState.loadingMore = false;
                const parsedAuditions = parseAuditions(data, draftState.applications);
                draftState.auditions = [...draftState.auditions, ...parsedAuditions];
                draftState.currentAuditionLimit = query.limit;
                draftState.currentAuditionPage = query.page;
            });
        case types.LOAD_MORE_AUDITIONS_FAIL:
            return produce(state, draftState => {
                draftState.loadingMore = false;
                draftState.loadingMoreErr = payload;
            });
        case types.WITHDRAW_APPLICATION:
            return produce(state, draftState => {
                draftState.withdrawingApplication = true;
            });
        case types.WITHDRAW_APPLICATION_SUCCESS:
            const { applicationId } = payload as IWithdrawApplication;
            return produce(state, draftState => {
                draftState.applications = draftState.applications.filter(
                    application => application.id !== applicationId,
                );
                draftState.withdrawingApplication = false;
            });
        case types.WITHDRAW_APPLICATION_FAIL:
            return produce(state, draftState => {
                draftState.withdrawApplicationError = payload;
                draftState.withdrawingApplication = false;
            });
        case types.RESET_AUDTION_DATA:
            return restoreInitialState(state, initialState);
        default:
            return state;
    }
};

export { reducer };
