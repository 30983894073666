import { Box } from '@mui/material';
import React from 'react';
import { StyleSheet } from 'react-native';
import { ColorScheme, theme } from '../../../constants/theme';
import { EsBackground } from '../../EsBackground';
import { EsSignupHeader } from '../../Header/EsSignupHeader';
import { ThemeContext } from '../../../module/ThemeManager';
import { useNavigate } from 'react-router-dom';
import { ArrowAnimation } from '../../AnimatedComponents/ArrowDown';
import { useScrollDetection } from '../../../hook/useScrollDetection';

export interface IFooterProps {
    children: any;
    noScroll?: boolean;
}

const FooterContainer: React.FC<IFooterProps> = ({ children, noScroll }) => {
    const navigate = useNavigate();
    const { onScrollEndReached, containerRef } = useScrollDetection();

    return (
        <ThemeContext.Consumer>
            {({ themeStyle }) => {
                const styles = dynamicStyles(themeStyle);
                return (
                    <Box sx={styles.container}>
                        <EsBackground />
                        <EsSignupHeader label="Go back" onClick={() => navigate(-1)} />

                        <Box
                            ref={containerRef}
                            sx={{
                                zIndex: 1,
                                alignItems: 'center',
                                justifyContent: 'center',
                                backgroundColor: theme[themeStyle].form_black,
                                width: {
                                    xs: '90vw',
                                    lg: '70vw',
                                    sm: '70vw',
                                },
                                maxWidth: '800px',
                                maxHeight: '700px',
                                height: '80vh',
                                overflow: 'scroll',
                                p: 4,
                                position: 'relative',
                            }}>
                            {!noScroll && !onScrollEndReached && (
                                <ArrowAnimation
                                    containerStyle={{
                                        position: 'fixed',
                                        right: 0,
                                        bottom: 0,
                                        zIndex: 999,
                                    }}
                                />
                            )}
                            {children}
                        </Box>
                    </Box>
                );
            }}
        </ThemeContext.Consumer>
    );
};

const dynamicStyles = (colorScheme: ColorScheme) =>
    StyleSheet.create({
        container: {
            width: '100vw',
            height: 'auto',
            minHeight: '100vh',
            justifyContent: 'center',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
        },
    });
export { FooterContainer };
