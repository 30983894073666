/* eslint-disable import/no-cycle */
import { all, takeLatest, select, call, put } from 'redux-saga/effects';

import { types, CacheTypes, CacheValue } from './types';
import { RootState } from '../root-reducer';
import { IGlobalConfiguration } from '../../types/global/helper';
import { en_config } from '../../config';
import dayjs from 'dayjs';
import { retrieveNukeTime, setNukeTime } from '.';
import { nuke } from './action';

function* checkAndNuke(): any {
    const lastNukeTime = yield call(retrieveNukeTime);
    const now = dayjs();
    if (lastNukeTime) {
        const lastTime = dayjs(lastNukeTime);
        if (now.diff(lastTime, 'hours') > 3) {
            yield put(nuke());
            yield call(setNukeTime, now.toISOString());
        }
    } else {
        yield put(nuke());
        yield call(setNukeTime, now.toISOString());
    }
}

function* nukeAction(): any {
    const now = dayjs();
    yield call(setNukeTime, now.toISOString());
}

function* nukeWatcher(): any {
    yield takeLatest(types.NUKE, nukeAction);
}

function* checkAndNukeWatcher(): any {
    yield takeLatest(types.CHECK_NUKE_TIME, checkAndNuke);
}

export function* isUseCacheEnabled(): Generator<any, boolean, RootState> {
    const state = yield select();
    const { globalConfiguration } = state.configuration || {};
    const { use_cache } = (globalConfiguration as IGlobalConfiguration) || {};
    const defaultUseCache: boolean = use_cache !== undefined ? use_cache : true;
    return defaultUseCache;
}

export function* getCacheDuration(): Generator<any, number, RootState> {
    const state = yield select();
    const { globalConfiguration } = state.configuration || {};
    const { cache_duration } = (globalConfiguration as IGlobalConfiguration) || {};
    const defaultDuration: number = cache_duration !== undefined ? cache_duration : en_config.CACHED_DURATION;
    return defaultDuration;
}
export function* getNotUniqueCacheByKey(cacheType: string, cacheKey: string) {
    const state: RootState = yield select();
    const { storage } = state.cache;
    let initialResult: any = null;
    if (storage && storage.hasOwnProperty(cacheType)) {
        const store = storage[cacheType as CacheTypes];
        if (store.hasOwnProperty(cacheKey)) {
            initialResult = store[cacheKey as keyof CacheValue];
        }
    }

    return initialResult;
}

export function* getCacheByKey(cacheKey: string, cacheType: string) {
    const state: RootState = yield select();
    const { storage } = state.cache;
    let initialResult: any = null;
    if (storage && storage.hasOwnProperty(cacheKey)) {
        Object.keys(storage[cacheType as CacheTypes]).forEach(key => {
            if (storage[cacheType as CacheTypes][key as keyof CacheValue] === cacheKey) {
                initialResult = storage[cacheType as CacheTypes].value;
            }
        });
    }
    return initialResult;
}

export function* getCacheByType(cacheType: string) {
    const state: RootState = yield select();
    const { storage } = state.cache;
    let initialResult: any = null;
    if (storage && storage.hasOwnProperty(cacheType)) {
        initialResult = storage[cacheType as CacheTypes];
    }
    return initialResult;
}

export default function* cacheSaga() {
    yield all([nukeWatcher(), checkAndNukeWatcher()]);
}
